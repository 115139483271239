import { useApi } from '@/core/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect } from 'react';
import Button from '../../UI/Button';
import styles from './NpsSurveyCard.module.scss';import ANALYTICS_EVENTS from '@/core/constants/analytics_events';
import { useAnalyticsContext } from '@/core/contexts/analytics.context';
;

type NpsDeclinedStateProps = {
  onResult(): void;
}

const NpsDeclinedState = (props: NpsDeclinedStateProps) => {
  const { analytics, logEvent } = useAnalyticsContext();
  const { mutateApi } = useApi();

  const cancelNpsSurvey = useMutation(
    (data: { decline: boolean }) =>
      mutateApi(
        ['surveys', 'close'],
        {
          survey_type: 'nps',
          decline: data.decline,
        },
        {
          method: 'POST',
        },
      ),
    {
      onSuccess: () => {
        logEvent(analytics, ANALYTICS_EVENTS.RECHAZO_NPS, {
          nps: -1,
        });
      },
    },
  );

  const cancelSurvey = () => {
    cancelNpsSurvey.mutate({ decline: true });
  }

  const confirmSurvey = () => {
    cancelNpsSurvey.mutate({ decline: false });
  }

  useEffect(() => {
    if (cancelNpsSurvey.isSuccess) {
      props.onResult();
    }
  }, [cancelNpsSurvey.isSuccess]);

  return (
    <>
      <div className={clsx(styles.surveyContent, styles.declined)}>
        <div className={styles.title}>Respetamos tus preferencias</div>
        <div className={styles.content}>
          <span>¿Quieres que te volvamos a preguntar en otro momento?</span>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="secondary" size="default" title="NO" onClick={cancelSurvey}>
          NO
        </Button>
        <Button variant="primary" size="default" title="SI" onClick={confirmSurvey}>
          SI
        </Button>
      </div>
    </>
  );
}

export default NpsDeclinedState;