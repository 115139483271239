export const END = 'end';
export const CLOSE = 'close';
export const WANT_CLOSE = 'wantClose';
export const CHECK_WANT_CLOSE = 'check-want-close';
export const CONFIRM_CLOSE = 'confirm-close';
export const PAUSE = 'pause';
export const PLAY = 'play';
export const SGAE = 'sgae';
export const ANNOTATE = 'annotate';
export const ADD_TRAINING_DATA = 'addTrainingData';
export const SET_GRAPH_TYPE = 'setGraph';
export const SCHEDULER = 'scheduler';
export const SECOND = 'second';
export const POWER_TARGET = 'power-target';
export const RESISTANCE_TARGET = 'resistance-target';
export const POWER_AUTO_MODE = 'setAutoMode';
export const CHANGE_QUALITY_VIDEO = 'changeQualityVideo';
export const SHOW_EXTERNAL = 'externalScreen';
export const TOGGLE_FULLSCREEN = 'toggleFullScreen';
export const CURRENT_TIME = 'currentTime';
export const HIDE_RESUME = 'hideResume';
export const SHOW_MONITOR_CENTERED = 'showMonitorCentered';
export const TOGGLE_CARDIO = 'toggleCardio';
export const SHOW_CARDIO = 'showCardio';
export const HIDE_CARDIO = 'hideCardio';
