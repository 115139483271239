import { GraphType } from '@/core/constants/graphFactory';
import GraphImage from '@/core/components/TrainingClassDetails/GraphImage';
import GraphImages from '@/core/components/TrainingClassDetails/GraphImages';
import { usePreferences } from '@/core/contexts/app.context';
import styles from './TrainingClassContainer.module.scss';

type TrainingClassCover2Props = {
  trainingClass: TrainingClass;
  displayOverlay: boolean;
  children?: React.ReactNode;
};

// TODO: This component should be generic in order to use TrainingClassContainer.module.scss
// It has the Training Class Card css rules decoupled only for the cover and not for the card.
// => TrainigClassContainer, TrainingClassCover, "TrainingClassCoverItems"... etc.
const TrainingClassCover2 = ({
  trainingClass,
  displayOverlay,
  children,
}: TrainingClassCover2Props) => {
  const { state: preferences } = usePreferences();

  const getGraph = () => {
    const { official, graph, graph_watts, has_graph, category_nr } = trainingClass;

    if (official || category_nr !== 1) return graph;
    if (preferences.graph_type === GraphType.Watts && graph_watts) return graph_watts;

    return has_graph ? graph : graph_watts;
  };

  return (
    <div className={styles.cover}>
      {trainingClass.official ? (
        <>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${trainingClass.cover})`,
            }}
          >
            {displayOverlay && <div className={styles.white_overlay}></div>}
          </div>
          <div className={styles.graphs}>
            <GraphImages trainingClass={trainingClass} />
          </div>
        </>
      ) : (
        <div className={styles.image}>
          <GraphImage graph={getGraph() as string} />
        </div>
      )}
      {children}
    </div>
  );
};

export default TrainingClassCover2;
