import { useApi } from '@/core/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';

const useAcceptCommunityRules = () => {
  const { mutateApi, client } = useApi();
  const acceptCommunityRulesMutation = useMutation(
    () => mutateApi(['user/accept_community_rules'], null, { method: 'PUT' }),
    {
      onSuccess: () => {
        client.invalidateQueries(['user']);
      },
    },
  );

  const acceptCommunityRules = async () => {
    // TODO: mostrar modal aquí?
    acceptCommunityRulesMutation.mutate();
  };

  return acceptCommunityRules;
};

export default useAcceptCommunityRules;
