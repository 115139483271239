import training_graphMin from '@/img/training_graph-min.jpg';

import { useLocation, useNavigate } from 'react-router';
import { usePreferences, useProfile } from '../../contexts/app.context';
import { useShell } from '../../contexts/shell.context';
import VideoImage from './VideoImage';
import GraphImage from './GraphImage';
import AspectRatio from '../AspectRatio';
import Category from '../../constants/category';

import styles from './MediaAsset.module.scss';
import cloudDownloadIcon from '@/img/download-class-icon.svg';
import downloadOffIcon from '@/img/icon-cancel-downloadclass.svg';
import playIcon from '@/img/play-rounded-button-o@1x.png';
import { useDownload, useDownloads, useDownloadStatus } from '../../contexts/downloads.context';
import MinutesSelector from './MinutesSelector';
import { GraphType } from '@/core/constants/graphFactory';
import { MediaType } from '@/constants/Download/MediaTypes';
import { useModal } from '@/contexts/modal.context';
import { MediaDownloadStatus } from './DownloadModal';
import ClassImage from './ClassImage';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { BluetoothDeviceTypes } from '@/constants/bluetooth';
import useTrainingClassNavigation from '@/hooks/useTrainingClassNavigation';

export type MediaAssetState = 'play' | 'download' | 'downloaded' | 'downloading' | 'queued' | null;

interface MediaAssetProps {
  trainingClass: TrainingClass;
  media: Media;
  isMind: boolean;
  state?: MediaAssetState;
  action?: 'play' | 'download';
  onKnowingFtp?: () => void;
  showTimeSelector?: boolean;
}
export default function MediaAsset({
  trainingClass,
  media,
  isMind,
  state = 'play',
  action = 'play',
  onKnowingFtp,
  showTimeSelector = true,
}: MediaAssetProps) {
  const playerNavigation = useTrainingClassNavigation();

  const { show: showModal } = useModal();
  const { state: preferences, setPreferences } = usePreferences();
  const { removeMedia } = useDownloads();
  const { state: shell, actions: shellActions } = useShell();
  const download = useDownload(trainingClass.id);
  const { state: profile } = useProfile();
  const { actions } = useBTContext();

  const isGraphWatts =
    preferences.graph_type == GraphType.Watts &&
    trainingClass.graph_watts &&
    trainingClass.category_nr == Category.Cycling;

  const graphImage =
    trainingClass.category_nr == Category.Training
      ? training_graphMin
      : isGraphWatts
      ? trainingClass.graph_watts
      : !trainingClass.has_graph && trainingClass.graph_watts
      ? trainingClass.graph_watts
      : trainingClass.graph;

  let title;
  if (media.type.match(/^(video)/) || media.type == MediaType.hls) {
    title = isMind ? 'Vídeo de introducción a la práctica' : 'Clase con vídeo';
  } else if (media.type == 'music') {
    title = 'Gráfica sin voz';
  } else if (media.type == 'audio') {
    title = isMind ? 'Audio de la práctica guiada' : 'Gráfica con voz';
  } else {
    title = 'Formato desconocido';
  }

  const size = media.size ? Math.ceil(media.size / 1024.0 / 1024.0) : null;

  const { pathname } = useLocation();

  const isBestProPath = pathname.includes('bestpro');

  const handlePlayAction = () => {
    if (shell.nwjs) {
      shellActions.playTrainingClassMedia(trainingClass, media);
    } else {
      const downloadedMedia = download?.downloadedMedia;

      playerNavigation.navigateToPlayer(trainingClass, {
        offline:
          !!downloadedMedia?.find(m => m.type == media.type) &&
          downloadedMedia?.find(m => m.type == media.type)!.downloaded,
        media: media,
      });
    }
  };
  const downloadStatus = useDownloadStatus(trainingClass.id, media.type);

  const handleDownloadAction = () => {
    if (downloadStatus === 'downloading' || downloadStatus === 'queued') {
      showModal(
        '¿Deseas cancelar esta descarga?',
        'Sí, cancelar',
        'No, continuar descargando',
        () => {
          removeMedia(trainingClass.id, media.type);
        },
      );
    } else if (downloadStatus === 'downloaded') {
      showModal('¿Deseas eliminar esta descarga?', 'Sí, eliminar', 'Cancelar', () => {
        removeMedia(trainingClass.id, media.type);
      });
    } else {
      shellActions.downloadTrainingClassMedia(trainingClass, media);
    }
  };

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    if (action === 'play') {
      if (
        actions.getConnectedDevices(BluetoothDeviceTypes.Bike).length > 0 &&
        profile?.ftp == null &&
        onKnowingFtp
      )
        onKnowingFtp();
      else handlePlayAction();
    } else if (action === 'download') {
      handleDownloadAction();
    }
  };

  const changeMinutes = (minutes: number) => setPreferences({ defaultDuration: minutes });

  const hasMinuteSelector = () => {
    return isMind && media.type == 'audio';
  };

  return (
    <>
      <div
        className={styles.media}
        onClick={evt => {
          evt.stopPropagation();
        }}
      >
        <div className={styles.media_thumbnail}>
          <AspectRatio className={styles.graph_image}>
            {media.type.match(/audio|music/) ? (
              isMind ? (
                <ClassImage image={graphImage} title={title} />
              ) : (
                <GraphImage graph={graphImage} title={title} />
              )
            ) : (
              <VideoImage url={trainingClass.cover} title={title} />
            )}
          </AspectRatio>

          {state == 'play' ? (
            <a
              href=""
              className={styles.media_play_icon}
              onClick={handleClick}
              tabIndex={0}
              role="button"
              aria-label={`Reproducir ${title}`}
            >
              <img src={playIcon} alt="Reproducir clase" />
            </a>
          ) : state == 'download' ? (
            <a
              href=""
              className={styles.media_play_icon}
              onClick={handleClick}
              tabIndex={0}
              role="button"
              aria-label={`Descargar ${title}`}
            >
              <div
                style={{
                  color: 'white',
                  background: 'rgba(38, 38, 38, 0.75)',
                  borderRadius: '100%',
                }}
              >
                <img
                  style={{ padding: '5px', opacity: 1 }}
                  alt="Descargar clase"
                  src={cloudDownloadIcon}
                />
              </div>
            </a>
          ) : state == 'queued' ? (
            <a
              href=""
              className={styles.media_play_icon}
              onClick={handleClick}
              tabIndex={0}
              role="button"
              aria-label="Descargar clase"
            >
              <div
                style={{
                  color: 'white',
                  background: 'rgba(38, 38, 38, 0.75)',
                  borderRadius: '100%',
                }}
              >
                <img
                  style={{ padding: '3x 3px 0px', opacity: 1 }}
                  alt="Eliminar descarga"
                  src={downloadOffIcon}
                ></img>
              </div>
            </a>
          ) : state == 'downloading' ? (
            <a
              href=""
              className={styles.media_play_icon}
              onClick={handleClick}
              tabIndex={0}
              role="button"
              aria-label="Cancelar descarga"
            >
              <div
                style={{
                  color: 'white',
                  background: 'rgba(38, 38, 38, 0.75)',
                  borderRadius: '100%',
                }}
              >
                <img
                  style={{ padding: '3px 3px 0px', opacity: 1 }}
                  alt="Cancelar descarga"
                  src={downloadOffIcon}
                />
              </div>
            </a>
          ) : state == 'downloaded' ? (
            <a
              href=""
              className={styles.media_play_icon}
              onClick={handleClick}
              tabIndex={0}
              role="button"
              aria-label="Eliminar descarga"
            >
              <div
                style={{
                  color: 'white',
                  background: 'rgba(38, 38, 38, 0.75)',
                  borderRadius: '100%',
                }}
              >
                <img
                  style={{ padding: '3px 3px 0px', opacity: 1 }}
                  alt="Eliminar descarga"
                  src={downloadOffIcon}
                />
              </div>
            </a>
          ) : null}
        </div>
        <div className={styles.media_info}>
          <div className={styles.media_title}>{title}</div>
          <div className={styles.media_size}>{size ? `${size}mb` : '-'}</div>
          <MediaDownloadStatus id={trainingClass.id} mediaType={media.type} />
        </div>
      </div>

      {showTimeSelector && hasMinuteSelector() ? (
        <div>
          <MinutesSelector trainingClass={trainingClass} onChange={changeMinutes} />
        </div>
      ) : null}
    </>
  );
}
