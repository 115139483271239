const key = 'urlok';

/**
 * API in order to save and recover the current path
 *
 * savePath: by default, it saves the path in the session storage using pathname + hash. However you can pass through params the a path to save
 *
 * getPath: is usually used for the backend urlok, so it requires the whole path (pathname + hash).
 *
 * consumePath: returns only the hash part and deletes the path from the session storage
 *
 * @returns getPath consumePath savePath
 */
const usePathRecovery = () => {
  const getPath = () => sessionStorage.getItem(key);

  const consumePath = () => {
    const path = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    return path?.replaceAll(window.location.pathname + '#', '');
  };

  const savePath = (path?: string) => {
    const hasPath = !!path ?? false;

    if (hasPath) {
      sessionStorage.setItem(key, path!);
    } else {
      const _path = window.location.pathname + window.location.hash;
      sessionStorage.setItem(key, _path);
    }
  };

  return {
    getPath,
    consumePath,
    savePath,
  };
};

export default usePathRecovery;
