import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './LoginForm.module.scss';
// @ts-ignore: useForm not typed
import { FieldError, useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/auth.context';
import { useShell } from '@/contexts/shell.context';
import Modal from '../Modal';
import Button from '../UI/Button';
import { FormInput } from '../FormControls';
import eyeOpen from '../../../../img/iconEye-open.svg';
import eyeClose from '../../../../img/iconEye-close.svg';
import LoadingIndicator from '../LoadingIndicator';
import LoginWithGoogle from '../AuthProviders/LoginWithGoogle';
import LoginWithFacebook from '../AuthProviders/LoginWithFacebook';
import LoginWithApple from '../AuthProviders/LoginWithApple';

export default function LoginForm() {
  const navigate = useNavigate();
  const { state: authState, login, testConflict } = useAuth();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  useEffect(() => {
    if (authState.error) {
      switch (authState.error) {
        case 'conflict':
          navigate('/conflict');
          break;
        case 'network':
          setError('No hay conexión a internet. Por favor, conéctate a internet para continuar.');
          break;
        case 'unauthorized':
          setError('Usuario o contraseña incorrectos. Revisa que tus datos sean correctos.');
          break;
        default:
          setError('Error desconocido');
      }
      setLoading(false);
    }
  }, [authState]);

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(({ email, password }: { email: string; password: string }) => {
    setLoading(true);
    login(email, password);
  });

  const _onEyeButtonClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="form_login" data-cy="form_login">
      <div className={styles.containerLogin}>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <h1 className={styles.titleLogin}>Inicia sesión en Bestcycling</h1>

          <ErrorPopup errorMessage={error} />

          <div className={styles.field}>
            <FormInput
              data-cy="login_email"
              defaultValue={''}
              title="Email"
              placeholder="Email"
              error={errors.email ? 'true' : ''}
              {...register('email', {
                required: true,
                maxLength: 50,
                minLength: 5,
                pattern: /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
              })}
            />
            <ErrorEmail errors={errors.email} />
          </div>

          <div className={styles.field}>
            <FormInput
              data-cy="login_password"
              icon={showPassword ? eyeClose : eyeOpen}
              onClick={_onEyeButtonClick}
              type={showPassword ? 'text' : 'password'}
              defaultValue={''}
              title="Password"
              placeholder="Contraseña"
              error={errors.password ? 'true' : ''}
              {...register('password', { required: true, maxLength: 30, minLength: 4 })}
            />
            <ErrorPassword errors={errors.password} />
          </div>

          <div className={styles.submit}>
            <Button
              data-cy="login_submit"
              id="user_doLogin"
              className="button_web button_web_extra"
              value={loading ? 'Accediendo...' : 'Iniciar sesión'}
              // disabled={!formState.isValid}
              type="submit"
            >
              Iniciar sesión
            </Button>
          </div>
          <div>
            <a
              href={`${config.MAIN}/store/users/recuperapassword`}
              className="fs13"
              target="_blank"
              rel="noreferrer"
            >
              ¿Has olvidado tu contraseña?
            </a>
            <br />
            <hr className={styles.line} />
            <br />
            <a
              href={`${config.MAIN}/users/new?url_ok=%2F`}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: 'pointer' }}
              className="fs13 user_goRegister"
            >
              ¿Eres nuevo en Bestcycling?{' '}
              <span className={styles.orangeText}>Date de alta aquí</span>.
            </a>
            <br />
          </div>
          {/* <button onClick={testConflict}>Test Conflict</button> */}
          <ShowLoginProviders setError={setError} />
        </form>
      </div>
      <ResetSettingsElectron />

      {loading ? (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      ) : null}
    </div>
  );
}

type ShowLoginProvidersProps = {
  setError: React.Dispatch<React.SetStateAction<string>>;
};
const ShowLoginProviders: React.FC<ShowLoginProvidersProps> = ({ setError }) => {
  // Condición que controla si se renderiza el contenido
  if (!window.electronAPI || window.electronAPI?.appVersion > '4.0.19') {
    return (
      <div className={styles.containerProviders}>
        <div className={styles.lineContainer}>
          <hr className={styles.line} />
          <span className={styles.text}>O</span>
          <hr className={styles.line} />
        </div>
        <LoginWithFacebook handleError={setError} />
        <LoginWithGoogle handleError={setError} />
        <LoginWithApple handleError={setError} />
      </div>
    );
  }

  // Si no se cumple la condición, no se renderiza nada
  return null;
};
const ResetSettingsElectron = () => {
  const { state: shell, actions } = useShell();
  const [isOpenModal, setIsOpenModal] = useState(false);

  if (!shell.electron) return null;

  return (
    <div id="resetDefaultElectron" className={styles.resetDefaultElectron}>
      <button onClick={() => setIsOpenModal(true)}>Restablecer los ajustes por defecto</button>
      <ModalReset
        isOpen={isOpenModal}
        onClickOk={actions.restoreDefaultSettings}
        onClickCancel={() => setIsOpenModal(false)}
      />
    </div>
  );
};

interface ModalResetProps {
  isOpen: boolean;
  onClickOk: () => void;
  onClickCancel: () => void;
}
const ModalReset = ({ isOpen, onClickOk, onClickCancel }: ModalResetProps) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={styles.container}>
        <h1 className={styles.titleModal}>¡Importante!</h1>
        <p className={styles.description}>
          ¿Estás seguro de que quieres reestablecer los ajustes por defecto? Se eliminarán todos los
          datos locales.
        </p>
        <Button
          style={{ marginBottom: '10px' }}
          variant="primary"
          onClick={() => {
            onClickOk();
            onClickCancel();
          }}
        >
          Sí, restablecer
        </Button>
        <Button variant="terciary" onClick={onClickCancel}>
          Cancelar
        </Button>
      </div>
    </Modal>
  );
};

const ErrorEmail = ({ errors }: { errors: FieldError | undefined }) => {
  if (!errors) return null;

  return (
    <>
      {errors.type === 'pattern' && (
        <p className={styles.errorMessage}>Introduce un email válido</p>
      )}
      {errors.type === 'minLength' && <p className={styles.errorMessage}>Email demasiado corto</p>}
    </>
  );
};

const ErrorPassword = ({ errors }: { errors: FieldError | undefined }) => {
  if (!errors) return null;

  return (
    <>
      {errors.type === 'minLength' && (
        <p className={styles.errorMessage}>{'La contraseña debe contener al menos 4 carácteres'}</p>
      )}
    </>
  );
};

export const ErrorPopup = ({ errorMessage }: { errorMessage: string }) => {
  return errorMessage ? (
    <div className={styles.errorPopup}>
      <p className={styles.errorMessage}>{errorMessage}</p>
    </div>
  ) : (
    <></>
  );
};
