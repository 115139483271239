import calculateFtp from '@/core/classes/Ftms';
import { useShell } from '@/core/contexts/shell.context';
import usePatchProfile from '@/core/hooks/apiHooks/usePatchProfile';
import preferencesModel from '@/core/models/preferences.model';
import useMainLoaded from '@/hooks/useMainLoaded';
import { useEffect, createContext, useReducer } from 'react';
import useSyncPreferences from '../hooks/useSyncPreferences';

type State = {
  isLoaded: boolean;
  preferences: any;
};

type Action =
  | {
      type: 'init';
      payload: {
        user?: IUser | null;
        profile?: IProfile | null;
      };
    }
  | {
      type: 'set';
      preferences: any;
    };

type Context = {
  state: any;
  setPreferences(preferences: any): void;
  savePreferences(preferences: any): void;
  updateAppPreferences(preferences: any): void;
};

export const PreferencesContext = createContext<Context>({
  state: null,
  setPreferences: function (_: any) {},
  savePreferences: function (_: any) {},
  updateAppPreferences: function (_: any) {},
});

type PreferencesProviderProps = {
  user: IUser | null;
  profile: IProfile | null;
  children: React.ReactNode;
};

const PreferencesProvider = ({ user, profile, children }: PreferencesProviderProps) => {
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'init':
        return {
          isLoaded: true,
          preferences: preferencesModel({
            user: action.payload.user,
            profile: action.payload.profile,
          }),
        };
      case 'set':
        return {
          ...state,
          preferences: preferencesModel({
            user,
            profile,
            preferences: {
              ...state.preferences,
              ...action.preferences,
            },
          }),
        };
      default:
        throw new Error('app.context prefsReducer unknown action');
    }
  };

  const initialState: State = {
    isLoaded: false,
    preferences: preferencesModel({ user, profile }),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { actions: shellActions } = useShell();

  useMainLoaded();

  const { updateProfile } = usePatchProfile();

  const { canSyncPreferences, syncPreferences, updateAppPreferences } = useSyncPreferences();

  const init = (user: IUser, profile: IProfile) => {
    dispatch({ type: 'init', payload: { user, profile } });
  };

  const setPreferences = (preferences: any) => {
    dispatch({ type: 'set', preferences });
  };

  const savePreferences = (preferences: any) => {
    Object.keys(preferences).forEach(key => {
      shellActions.saveDesktopPreference(key, preferences[key]);
    });

    setPreferences(preferences);
  };

  useEffect(() => {
    if (state.preferences.C1) return;

    const getSetting = window.electronAPI?.getSetting;
    if (!getSetting) return;

    const downloadsPath = getSetting('C1');
    setPreferences({ C1: downloadsPath });
  }, []);

  useEffect(() => {
    const handleSettingChange = window.electronAPI?.handleSettingChange;
    if (!handleSettingChange) return;

    handleSettingChange((_: any, settingCode: string, value: any) => {
      setPreferences({ [settingCode]: value });
    });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!profile) return;

    init(user, profile);
  }, [user, profile]);

  useEffect(() => {
    if (!user) return;
    if (!profile) return;
    if (!state.isLoaded) return;

    const { profile: oldProfile, user: oldUser } = state.preferences;

    const weight = oldProfile?.weight ?? profile.weight;
    const fitStatus = oldProfile?.fit_status ?? profile.fit_status;
    const sex = oldUser?.sex ?? user.sex;

    const hasCustomFtp = profile.ftp !== calculateFtp.get(weight, fitStatus, sex);

    if (state.preferences.hasCustomZonesSettings || hasCustomFtp) {
      return;
    }

    const ftp = calculateFtp.fromConfig(user, profile);

    if (profile.ftp !== ftp) {
      updateProfile({ ftp });
    }
  }, [user, profile, state.isLoaded, state.preferences]);

  useEffect(() => {
    if (!state.isLoaded) return;
    if (!canSyncPreferences) return;

    syncPreferences(state.preferences, savePreferences);
  }, [state, canSyncPreferences]);

  return (
    <PreferencesContext.Provider
      value={{
        state: state.preferences,
        setPreferences,
        savePreferences,
        updateAppPreferences,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
