import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = ({ color = '#ff7900', variant = '' }) => {
  const classNameContainer = `${styles.container} ${variant ? styles[variant] : ''} `;
  const className = `${styles.spinner} ${variant ? styles[variant] : ''} `;

  return (
    <div className={classNameContainer} data-cy="loading_indicator">
      <div
        className={className}
        style={
          color
            ? {
                borderColor: color,
              }
            : {}
        }
      ></div>
    </div>
  );
};

export default LoadingIndicator;
