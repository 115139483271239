import iconIdea from '@/img/icon-idea.svg';
import iconHelp from '@/img/icon-dropdown-help.svg';
import iconContacta from '@/img/icon-contacta.svg';
import iconError from '@/img/icon-error.svg';
import { useEffect, useRef, useState } from 'react';
import styles from './AyudaYContacto.module.scss';
import clsx from 'clsx';
import Divider from './Divider';
import TriangleSch from '@/img/scheduler/triangle';
import { ErrorReportModal } from '../Preferences/MiscPreferences';
import { useNav } from '@/contexts/nav.context';
import { createPortal } from 'react-dom';
import Chevron from '@/img/chevron';
import { Link } from 'react-router-dom';
import { useAuth } from '@/core/contexts/auth.context';

export const AyudaYContacto = () => {
  const {
    state: { showContactSubmenu },
    dispatch,
  } = useNav();
  const textRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const dispatchNav = useNav().dispatch;

  useEffect(() => {
    if (window.electronAPI?.handleErrorReportModal) {
      window.electronAPI?.handleErrorReportModal(() => setShow(true));
    }
  }, []);

  return (
    <>
      <ErrorReportModal
        onClose={() => {
          dispatchNav({ type: 'nav-close' });
          setShow(false);
        }}
        isOpen={show}
      />
      <div className={styles.container}>
        <button
          className={clsx(['menu-item-text', styles.dropdownMenuButton])}
          onClick={() => dispatch({ type: 'nav-contact-submenu-toggle' })}
        >
          <img
            src={iconHelp}
            className={styles.icon}
            alt="Ayuda y Contacto"
            style={{ width: '20px', height: '20px' }}
          />
          <div className={styles.text} ref={textRef}>
            Ayuda y Contacto
          </div>
          <div className={styles.chevron}>
            <Chevron isDown={showContactSubmenu} color={showContactSubmenu ? '#fff' : '#aaa'} />
          </div>
        </button>
        {showContactSubmenu &&
          window.innerWidth > 768 &&
          createPortal(
            <DropdownMenu
              left={textRef.current!.getBoundingClientRect().right}
              top={textRef.current!.getBoundingClientRect().top}
              setShowErrorReport={setShow}
            />,
            document.body,
          )}
        {showContactSubmenu && window.innerWidth <= 768 && (
          <DropdownMenuMobile setShowErrorReport={setShow} />
        )}
      </div>
    </>
  );
};

type DropdownMenuProps = {
  left: number;
  top: number;
  setShowErrorReport: (show: boolean) => void;
};

const DropdownMenu = ({ left, top, setShowErrorReport }: DropdownMenuProps) => {
  const { state: authState } = useAuth();
  const { dispatch: dispatchNav } = useNav();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeNav = () => dispatchNav({ type: 'nav-close' });

  const [ownHeight, setOwnHeight] = useState(0);

  useEffect(() => {
    setOwnHeight(dropdownRef.current?.getBoundingClientRect().height ?? 0);
  }, [dropdownRef.current]);

  return (
    <>
      <div
        id="help-dropdown-menu"
        className={styles.dropdownMenu}
        style={{ left: left + 30, top: top - ownHeight / 2 + 5 }}
        ref={dropdownRef}
      >
        <ul className={styles.dropdownMenuItems}>
          <li className={styles.dropdownMenuItem}>
            <button
              onClick={e => {
                e.preventDefault();
                setShowErrorReport(true);
              }}
            >
              <img src={iconError} className={styles.icon} />
              <span className={styles.text}>Reporta un error</span>
            </button>
          </li>
          <Divider />
          <li className={styles.dropdownMenuItem}>
            <a
              href={`https://community.bestcycling.com/direct_auth?&redirect_to=categories/11/topics&access_token=${authState.accessToken}`}
              target="_blank"
              onClick={closeNav}
              rel="noreferrer"
            >
              <img src={iconIdea} className={styles.icon} />
              <span className={styles.text}>Envía una sugerencia</span>
            </a>
          </li>
          <Divider />
          <li className={styles.dropdownMenuItem}>
            <a
              href={`https://bestcycling.com/direct_auth?access_token=${authState.accessToken}&redirect_to=contacto`}
              target="_blank"
              onClick={closeNav}
              rel="noreferrer"
            >
              <img src={iconContacta} className={styles.icon} />
              <span className={styles.text}>Contacta con nosotros</span>
            </a>
          </li>
        </ul>
        <div className={styles.dropdownMenuArrow} style={{ left: -10, top: ownHeight / 2 - 10 }}>
          <TriangleSch size={20} />
        </div>
      </div>
    </>
  );
};

type DropdownMenuMobileProps = {
  setShowErrorReport: (show: boolean) => void;
};
const DropdownMenuMobile = ({ setShowErrorReport }: DropdownMenuMobileProps) => {
  const { state: authState } = useAuth();
  const { dispatch: dispatchNav } = useNav();

  const closeNav = () => dispatchNav({ type: 'nav-close' });

  return (
    <ul className={styles.dropdownMenuItems}>
      <li className={styles.dropdownMenuItem}>
        <button
          onClick={() => {
            setShowErrorReport(true);
          }}
        >
          <img src={iconError} className={styles.icon} />
          <span className={styles.text}>Reporta un error</span>
        </button>
      </li>
      <li className={styles.dropdownMenuItem}>
        <a
              href={`https://community.bestcycling.com/direct_auth?&redirect_to=categories/11/topics&access_token=${authState.accessToken}`}
              target="_blank"
          onClick={closeNav}
          rel="noreferrer"
        >
          <img src={iconIdea} className={styles.icon} />
          <span className={styles.text}>Envía una sugerencia</span>
        </a>
      </li>
      <li className={styles.dropdownMenuItem}>
        <a
          href={`https://bestcycling.com/direct_auth?access_token=${authState.accessToken}&redirect_to=contacto`}
          target="_blank"
          onClick={closeNav}
          rel="noreferrer"
        >
          <img src={iconContacta} className={styles.icon} />
          <span className={styles.text}>Contacta con nosotros</span>
        </a>
      </li>
    </ul>
  );
};
