import {
  formatVelocity,
  secondsToString,
  getPower,
  calculateAverage,
  getNormalizedPower,
  getIntensityFactor,
  getTss,
} from './utils';
import { GraphTypeNum } from '@/core/constants/graphFactory';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import styles from './ResumeModal.module.scss';

type DisplayInfoProps = {
  value: string;
  label: string;
  units?: string;
  variant?: string;
};

type CommonDisplayProps = {
  training?: Training;
  variant?: string;
};

type DefaultCyclingDisplayProps = {
  trainingClass?: TrainingClass;
  ftp: number;
} & CommonDisplayProps;

type DefaultRunningDisplayProps = {
  velocity: 'km/h' | 'min/km';
} & CommonDisplayProps;

const DisplayInfo = ({ value, label, units, variant }: DisplayInfoProps) => {
  return (
    <div className={`${styles.display} ${variant != null ? styles[variant] : ''}`}>
      <div className={styles.value}>
        {value}
        {units != null ? <div className={styles.units}>{units}</div> : null}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

const SecondDisplay = ({ training, variant }: CommonDisplayProps) => {
  let seconds = secondsToString(training?.seconds || 0);
  return <DisplayInfo value={seconds} label={'Duración entrenamiento'} variant={variant} />;
};

const KcalDisplay = ({ training, variant }: CommonDisplayProps) => {
  let kcal = training?.kcal || '-';
  const unit = training?.kcal ? 'kcal' : '';

  return (
    <DisplayInfo
      value={kcal.toString()}
      label={'Calorías consumidas'}
      units={unit}
      variant={variant}
    />
  );
};

const AvgPpmDisplay = ({ training }: CommonDisplayProps) => {
  const label = 'Media de pulsaciones';

  if (
    training?.bestcycling_training?.has_heart_rate &&
    training.training_data?.heart_rate != null
  ) {
    let heartRate = JSON.parse(training.training_data.heart_rate);
    if (heartRate.progression != null && heartRate.progression.length > 0) {
      let total = 0;
      heartRate.progression.forEach((item: any) => {
        total += item.quantity;
      });
      if (total != null && total > 0) {
        const result = Math.floor(total / heartRate.progression.length);
        return <DisplayInfo value={result.toString()} label={label} />;
      }
    }
  }

  return <DisplayInfo value={(training?.mean_frequency || '-').toString()} label={label} />;
};

const MaxPpmDisplay = ({ training }: CommonDisplayProps) => {
  const label = 'Pulsaciones máximas';

  if (
    training?.bestcycling_training?.has_heart_rate &&
    training.training_data?.heart_rate != null
  ) {
    let heartRate = JSON.parse(training.training_data.heart_rate);
    if (heartRate.progression != null && heartRate.progression.length > 0) {
      const result = Math.max.apply(
        Math,
        heartRate.progression.map((o: any) => {
          return o.quantity;
        }),
      );

      return <DisplayInfo value={result.toString()} label={label} />;
    }
  }

  return <DisplayInfo value={'-'} label={label} />;
};

const DistanceDisplay = ({ training, variant }: CommonDisplayProps) => {
  let distance = '-';
  if (training?.km != null) {
    distance = (training.km / 1000).toFixed(2) + ' km';
  }

  return <DisplayInfo value={distance} label={'Distancia recorrida'} variant={variant} />;
};

const AvgSpeedDisplay = ({ training, velocity = 'km/h' }: DefaultRunningDisplayProps) => {
  if (!training) return null;

  const totalAvg = training.km != null && training.seconds > 0 ? training.km / training.seconds : 0;
  const result = formatVelocity(totalAvg, velocity);

  return <DisplayInfo value={result} label={'Velocidad media'} />;
};

const MaxSpeedDisplay = ({ training, velocity }: DefaultRunningDisplayProps) => {
  if (!training) return null;

  let maxSpeed = '-';
  let maxValue = 0;
  if (training.bestcycling_training?.has_gps && training.training_data?.gps != null) {
    let runningValues = JSON.parse(training.training_data.gps);

    if (runningValues.gps != null && runningValues.gps.length > 0) {
      runningValues.gps.forEach((attributes: any) => {
        let avg =
          attributes.avg_speed != null && attributes.avg_speed > 0
            ? attributes.avg_speed
            : attributes.speed;
        if (avg != null && parseFloat(avg) < 7) {
          // Max allowed 25km/h

          maxValue = Math.max(maxValue, parseFloat(avg));
        }
      });
    } else if (runningValues.running_stats != null && runningValues.running_stats.length > 0) {
      runningValues.running_stats.forEach((attributes: any) => {
        let avg = attributes.avg != null ? parseFloat(attributes.avg) : 0;
        maxValue = Math.max(maxValue, avg);
      });
    }
  }

  if (maxValue > 0) {
    maxSpeed = formatVelocity(maxValue, velocity);
  }

  return <DisplayInfo value={maxSpeed} label={'Velocidad máxima'} />;
};

const getFtms = (training: Training) => {
  return training.bestcycling_training?.has_ftms && training.training_data
    ? JSON.parse(training.training_data?.ftms)
    : null;
};

const MaxWatts = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (!training || !trainingClass) return null;

  const progressionFtms = getFtms(training);
  const power = getPower({
    ftmsValues: progressionFtms,
    progressionWatts: trainingClass.progression_watts ?? [],
    ftp: progressionFtms?.['ftp'] ?? ftp ?? 100,
  });

  const maxPower = power?.length > 0 ? `${Math.max(...power)}W` : '-W';

  return (
    <DisplayInfo value={`${maxPower}${!progressionFtms ? '*' : ''}`} label={'Potencia máxima'} />
  );
};

const AvgWatts = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (!training || !trainingClass) return null;

  const progressionFtms = getFtms(training);
  const power = getPower({
    ftmsValues: progressionFtms,
    progressionWatts: trainingClass.progression_watts ?? [],
    ftp: progressionFtms?.['ftp'] ?? ftp ?? 100,
  });

  const maxPower = power?.length > 0 ? `${Math.floor(calculateAverage(power))}W` : '-W';
  return (
    <DisplayInfo value={`${maxPower}${!progressionFtms ? '*' : ''}`} label={'Potencia media'} />
  );
};

const NormalizedWatts = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (!training || !trainingClass) return null;

  const progressionFtms = getFtms(training);
  const normalized = Math.round(
    getNormalizedPower({
      ftmsValues: progressionFtms,
      progressionWatts: trainingClass.progression_watts ?? [],
      ftp: progressionFtms?.['ftp'] ?? ftp ?? 100,
    }),
  );

  return (
    <DisplayInfo
      value={`${normalized + 'W'}${!progressionFtms ? '*' : ''}`}
      label={'Potencia normalizada'}
    />
  );
};

const IntensityFactor = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (!training || !trainingClass) return null;

  console.log(trainingClass);

  const progressionFtms = getFtms(training);
  const intensity = getIntensityFactor({
    ftmsValues: progressionFtms,
    progressionWatts: trainingClass.progression_watts ?? [],
    ftp: progressionFtms?.['ftp'] ?? ftp ?? 100,
  });

  return (
    <DisplayInfo
      value={`${intensity}${!progressionFtms ? '*' : ''}`}
      label={'Factor de intensidad'}
    />
  );
};

const Tss = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (!training || !trainingClass) return null;

  const progressionFtms = getFtms(training);

  const seconds =
    training.seconds || trainingClass.duration_training || trainingClass.duration_seconds;

  const tss = getTss({
    seconds: seconds,
    ftmsValues: progressionFtms,
    progressionWatts: trainingClass.progression_watts ?? [],
    ftp: progressionFtms?.['ftp'] ?? ftp ?? 100,
  });

  return (
    <DisplayInfo
      value={`${tss}${!progressionFtms ? '*' : ''}`}
      label={'Puntuación de estrés de entrenamiento (TSS)'}
    />
  );
};

const CyclingDisplays = ({ training, trainingClass, ftp }: DefaultCyclingDisplayProps) => {
  if (
    training?.type_graph == GraphTypeNum.Watts &&
    trainingClass != null &&
    trainingClass.progression_watts != null &&
    ftp != null
  ) {
    const ftms = getFtms(training);

    return (
      <>
        <div className={styles.displaysInfo}>
          <SecondDisplay training={training} />
          <KcalDisplay training={training} />
          <AvgPpmDisplay training={training} />
          <MaxPpmDisplay training={training} />
          <MaxWatts training={training} ftp={ftp} trainingClass={trainingClass} />
          <AvgWatts training={training} ftp={ftp} trainingClass={trainingClass} />
          <NormalizedWatts training={training} ftp={ftp} trainingClass={trainingClass} />
          <IntensityFactor training={training} ftp={ftp} trainingClass={trainingClass} />
          <Tss training={training} ftp={ftp} trainingClass={trainingClass} />
        </div>
        {!ftms ? (
          <div className={styles.details}>
            *Estos datos no son reales, son datos teóricos de entrenamiento. Para tener datos reales
            debes conectar dispositivos de medición Bluetooth FTMS, como una bicicleta con FTMS,
            para leer los datos.
          </div>
        ) : null}
      </>
    );
  }
  return <CommonDisplays training={training} />;
};

const TrainingDisplays = ({ training }: CommonDisplayProps) => {
  return <CommonDisplays training={training} />;
};

const BalanceDisplays = ({ training }: CommonDisplayProps) => {
  return <CommonDisplays training={training} />;
};

const RunningDisplays = ({ training }: CommonDisplayProps) => {
  return (
    <div className={`${styles.displaysInfo}`}>
      <SecondDisplay training={training} variant={'large'} />
      <KcalDisplay training={training} variant={'large'} />
      <DistanceDisplay training={training} variant={'large'} />
      <MaxSpeedDisplay training={training} velocity="km/h" />
      <AvgSpeedDisplay training={training} velocity="km/h" />
      <AvgPpmDisplay training={training} />
      <MaxPpmDisplay training={training} />
    </div>
  );
};

const CommonDisplays = ({ training }: CommonDisplayProps) => {
  return (
    <div className={styles.displaysInfo}>
      <SecondDisplay training={training} />
      <KcalDisplay training={training} />
      <AvgPpmDisplay training={training} />
      <MaxPpmDisplay training={training} />
    </div>
  );
};

const LineExercise = ({
  exercise,
  showTotals,
  showPrevious,
}: {
  exercise: Exercise;
  showTotals: Boolean;
  showPrevious: Boolean;
}) => {
  return (
    <div className={styles.lineExercise}>
      <div className={styles.description}>
        {exercise.time} seg <span className={styles.method}>{exercise.method} </span>
      </div>
      <div className={showPrevious ? styles.rounds : ''}>
        {showPrevious && <div className={styles.previous}>{exercise.previous_count}</div>}
        {showTotals && (
          <div className={showPrevious ? styles.today : styles.totals}>{exercise.count}</div>
        )}
      </div>
    </div>
  );
};

const DisplayRound = ({
  round,
  index,
  total,
  showTotals,
  showPrevious,
}: {
  round: Round;
  index: number;
  total: number;
  showTotals: Boolean;
  showPrevious: Boolean;
}) => {
  return (
    <div className={styles.displayRound}>
      <div className={styles.roundTitle}>
        <div className={styles.titleNumber}>
          RONDA {index + 1} de {total}
        </div>
        <div className={styles.columnsRound}>
          {showPrevious && (
            <>
              <div className={styles.previous}>ANTERIOR</div>
              <div className={styles.today}>HOY</div>
            </>
          )}
          {!showPrevious && showTotals && <div className={styles.totals}>TOTALES</div>}
        </div>
      </div>
      <div className={styles.roundExercises}>
        {round.map((exercise, i) => (
          <LineExercise
            key={i}
            exercise={exercise}
            showTotals={showTotals}
            showPrevious={showPrevious}
          />
        ))}
      </div>
    </div>
  );
};

const CyclingRound = ({ time }: { time: number }) => {
  const timeStr = useMemo(() => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const remainingSeconds = time % 60;

    if (hours > 0) return `${hours} horas`;
    if (minutes > 0) return `${minutes} min`;

    return `${remainingSeconds} seg`;
  }, [time]);

  return (
    <div className={styles.displayRound}>
      <div className={styles.roundExercises}>
        <div className={clsx(styles.lineExercise, styles.bigger)}>
          <div className={styles.description}>
            {timeStr} <span className={styles.cyclingMethod}>Bloque cardiovascular</span>
          </div>
        </div>
      </div>
    </div>
  );
};

type resultsBlock = {
  progressionBlocks: ProgressionBlock[];
  showTotals: Boolean;
  showPrevious: Boolean;
};

export const ResultsByBlock = (props: resultsBlock) => {
  return (
    <div className={styles.blocks}>
      {props.progressionBlocks &&
        props.progressionBlocks.map((progressionBlock, index) => {
          return (
            <>
              <span className={styles.blockNumber}>BLOQUE {index + 1}</span>
              <div className={styles.displayRounds}>
                <ResultBlock
                  progressionBlock={progressionBlock}
                  showTotals={props.showTotals}
                  showPrevious={props.showPrevious}
                />
              </div>
            </>
          );
        })}
    </div>
  );
};

type resultBlock = {
  progressionBlock: ProgressionBlock;
  showTotals: Boolean;
  showPrevious: Boolean;
};

const ResultBlock = (props: resultBlock) => {
  const [cyclingBlockTime, setCyclingBlockTime] = useState(0);
  const progressionBlock = props.progressionBlock;

  const calculateCyclingTime = (cyclingProgression: ProgressionBlock) => {
    if (!cyclingProgression.length) {
      return 0;
    }

    return cyclingProgression
      .map((round: ProgressionRound) => {
        return round.progressions
          .map((point: Exercise) => {
            const time = point['time'];
            return parseInt(time.replace(/[a-zA-Z\s+]/, '')) ?? 0;
          })
          .reduce((accum, current) => accum + current);
      })
      .reduce((accum, current) => accum + current);
  };

  useEffect(() => {
    if (progressionBlock && progressionBlock.length > 0 && progressionBlock[0].cyclingRound)
      setCyclingBlockTime(calculateCyclingTime(progressionBlock));
  }, [progressionBlock]);

  if (!progressionBlock || !progressionBlock.length) return null;
  return (
    <>
      {progressionBlock[0].cyclingRound && (
        <div className={styles.displayRounds}>
          <CyclingRound time={cyclingBlockTime} />
        </div>
      )}

      {!progressionBlock[0].cyclingRound &&
        progressionBlock.map((round, index) => (
          <div key={index} className={styles.round}>
            <div className={styles.roundMethods}>
              <DisplayRound
                key={index}
                round={round.progressions}
                index={index}
                total={progressionBlock.length}
                showTotals={props.showTotals}
                showPrevious={props.showPrevious}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export { TrainingDisplays, BalanceDisplays, RunningDisplays, CyclingDisplays, CommonDisplays };
