type PlayIconProps = {
  size: number;
  color: string;
}

const PlayIcon = (props: PlayIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 27"><path d="M12.628,1.549a1,1,0,0,1,1.743,0l11.79,20.96A1,1,0,0,1,25.29,24H1.71a1,1,0,0,1-.872-1.49Z" transform="translate(24) rotate(90)" fill={props.color} /></svg>
  )
}

export default PlayIcon;