import { NameCategory } from '@/constants/analytics_events';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { toInteger } from 'lodash';

export default function useEventTracking() {
  const analytics = getAnalytics();
  const playStart = (eventName, trainingClass) => {
    logEvent(analytics, eventName, {
      content_type: trainingClass.mediaType,
      item_category: NameCategory[trainingClass.category],
      item_id: trainingClass.id,
      item_name: trainingClass.title,
    });
  };

  const playEnd = (eventName, trainingClass, dataHash, shouldAnnotate) => {
    let array = dataHash.split('seconds');

    logEvent(analytics, eventName, {
      content_type: trainingClass.mediaType,
      item_category: NameCategory[trainingClass.category],
      item_id: trainingClass.id,
      item_name: trainingClass.title,
      item_done: shouldAnnotate,
      value: toInteger(array[1]?.substring(1)),
      virtual_currency_name: 'Seconds',
    });
  };

  return { playStart, playEnd };
}
