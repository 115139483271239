import { AsyncFilter } from './Filter';

export function TrainingGenreFilter() {
  return (
    <AsyncFilter
      filterType="genres"
      filterKey="genres"
      label="name"
      tag="Género musical"
    />
  );
}
