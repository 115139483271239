import { useState } from 'react';
import { useLocation } from 'react-router';

import LifePoint from '../LifePoint/LifePoint';
import styles from './Training.module.scss';
import Category from '../../constants/category';
import ResumeModal from './ResumeModal';

import clsx from 'clsx';
import dayjs from 'dayjs';

export default function Training({
  training,
  onClose,
}: {
  training: Training;
  onClose?: () => void;
}) {
  const [resumeModal, setResumeModal] = useState(false);

  const openResumeModal = () => setResumeModal(true);

  const closeResumeModal = () => setResumeModal(false);
  const { pathname } = useLocation();

  const isPathBestPro = pathname.includes('bestpro');

  const category = training.training_class?.category_nr;
  const hasTrainingData = !!training.training_class?.id;
  const className =
    category == Category.Cycling
      ? styles.bestcycling
      : category == Category.Walking
        ? styles.bestwalking
        : category == Category.Balance
          ? styles.bestbalance
          : category == Category.Running
            ? styles.bestrunning
            : category == Category.Training
              ? styles.besttraining
              : category == Category.Mind
                ? styles.bestmind
                : '';

  const classes = clsx(styles.training, className, hasTrainingData && styles.hasTrainingData);

  return (
    <>
      {hasTrainingData && !onClose && (
        <ResumeModal training={training} isOpen={resumeModal} onClose={closeResumeModal} />
      )}

      <div
        className={classes}
        onClick={onClose || openResumeModal}
        tabIndex={hasTrainingData ? 0 : -1}
      >
        <div className={styles.header}>
          <div className={styles.category}></div>
          <div className={styles.date}>
            {dayjs(training.training_date).locale('es').format('D MMM YYYY HH:mm')}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.title}>
              {training.training_class?.short_title} / {training.training_class?.subtitle} /{' '}
            </div>
            <div className={styles.trainer}>
              {(training.training_class?.sharing_user ?? training.trainer)?.full_name}
            </div>
            {isPathBestPro && <div className={styles.trainer}>{training.location_name}</div>}
            {/* <div className={styles.location}>{training.location_name}</div> */}
          </div>

          {training.training_class?.official && (
            <div className={styles.points}>
              <LifePoint type="resistance" value={training.resistance_points} />
              <LifePoint type="force" value={training.force_points} />
              <LifePoint type="flexibility" value={training.flexibility_points} />
              <LifePoint type="mind" value={training.mind_points} />
            </div>
          )}
          <div className={`${styles.trianglecategory} ${className}`}></div>
          {/* {training.bestcycling_training?.has_heart_rate ? <div>Datos de pulsómetor</div> : null} */}
        </div>
      </div>
    </>
  );
}
