import dayjs from 'dayjs';
import styles from './PostCard.module';
import { Colors, Category } from '../../constants/category';

const parseDate = date => dayjs(date).format('DD MMM');

export default function PostCard({ post, onClick, ...props }) {
  const coverStyles = post.image ? { backgroundImage: `url(${post.image})` } : {};

  const handleClick = evt => {
    evt.preventDefault();
    onClick(post);
  };

  let categoryNr;
  if (post.blog_kind == 'cycling') {
    categoryNr = Category.Cycling;
  } else if (post.blog_kind == 'walking') {
    categoryNr = Category.Walking;
  } else if (post.blog_kind == 'training') {
    categoryNr = Category.Training;
  } else if (post.blog_kind == 'running') {
    categoryNr = Category.Running;
  } else if (post.blog_kind == 'balance') {
    categoryNr = Category.Balance;
  } else if (post.blog_kind == 'mind') {
    categoryNr = Category.Mind;
  }

  const triangleStyles = categoryNr ? { backgroundColor: Colors[categoryNr] } : {};

  return (
    <a href="#" onClick={handleClick} role="link" {...props}>
      <div className={styles.postCard}>
        <div className={styles.cover} style={coverStyles}></div>
        <div className={styles.extra}>
          <h1 className={styles.title}>{post.title}</h1>
          <div className={styles.date}>{parseDate(post.updated_at)}</div>
        </div>
        <div className={styles.trianglecategory} style={triangleStyles}></div>
      </div>
    </a>
  );
}
