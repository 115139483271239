import { useState } from 'react';
import dayjs from 'dayjs';

import { setItemToSave, hashToParams } from '../Trainings/AnnotateFunctions';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/api/ApiProvider';

export default function useAnnotate(trainingClass, media) {
  const [trainingDate] = useState(dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'));

  const [shouldAnnotate, setShouldAnnotate] = useState(
    trainingClass.category === 51 ? true : false,
  );

  const [annotateProgression, setAnnotateProgression] = useState([]);

  const avoidAnnotate =
    trainingClass.category == 21 &&
    (media == 'video' || media == 'video_hd' || media == 'video_sd' || media == 'hls');

  if (avoidAnnotate) {
    return {
      shouldAnnotate: false,
      setShouldAnnotate: () => {},
      annotateTrainingClass: () => {},
      addAnnotateProgression: () => {},
    };
  }

  const addAnnotateProgression = hash => {
    const values = hashToParams(decodeURIComponent(hash));
    setAnnotateProgression([...annotateProgression, values]);
    setShouldAnnotate(true);
  };

  //
  const annotateTrainingClass = hash => {
    let annotationDataTraining = {
      trainingClass,
      trainingDate,
      hash,
    };

    if (annotateProgression.length) {
      annotationDataTraining.annotation = annotateProgression;
    }
    setItemToSave(annotationDataTraining);
  };

  return {
    shouldAnnotate,
    setShouldAnnotate,
    annotateTrainingClass,
    addAnnotateProgression,
  };
}

const useAnnotateMutation = () => {
  const { mutateApi, client } = useApi();

  const annotateMutation = useMutation(
    async data => {
      const res = await mutateApi(['trainings'], data, {
        method: 'POST',
      });
      if (res.error) {
        throw new Error(res.error);
      }
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['trainings', 'training_classes', 'training_class', 'profile']);
        deleteItemToSave();
        // TODO: do the optimistic update
      },
      onError: () => {
        // TODO: Save that in localStorage
      },
    },
  );

  return annotateMutation;
};

export { useAnnotateMutation };
