import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Modal from '../Modal';

import { useApi } from '../../api/ApiProvider';
import { useQuery } from '@tanstack/react-query';

import styles from './ResumeModal.module.scss';
import LoadingIndicator from '../LoadingIndicator';
import Training from './Training';
import Category from '../../constants/category';
import Graph from '../Player/Graph';

import { usePreferences } from '../../contexts/app.context';
import {
  CyclingDisplays,
  RunningDisplays,
  BalanceDisplays,
  TrainingDisplays,
  CommonDisplays,
  ResultsByBlock,
} from './DisplaysInfo';

import { completeAnnotation, getTrainingByBlock } from './utils';
import Button from '../UI/Button/Button';

interface ResumeModalProps {
  isOpen: boolean;
  onClose?: () => void;
  training: Training;
  isAnnotate?: boolean;
  goObtainedPoints: () => void;
}

export default function ResumeModal({
  isOpen,
  onClose = () => {},
  training,
  isAnnotate = false,
  goObtainedPoints = () => {},
}: ResumeModalProps) {
  const { state: preferences } = usePreferences();

  const { fetchApi } = useApi();

  const [store, setStore] = useState<any | null>(null);
  const [rounds, setRounds] = useState<ProgressionBlock[] | null>(null);

  const { data, isLoading, refetch } = useQuery<Training>(
    ['trainings', training.guid],
    async () => {
      const response = await fetchApi(['trainings', training.guid]);
      return response.data;
    },
    { enabled: false },
  );

  const {
    data: trainingClass,
    isLoading: isLoadingClass,
    refetch: refetchTrainingClass,
  } = useQuery<TrainingClass>(
    ['training_classes', training.training_class!.id],
    async () => {
      const response = await fetchApi(['training_classes', training.training_class!.id]);
      return response.data;
    },
    { enabled: false },
  );

  const isTabletDimension = () => window.innerWidth > 900;

  const [isTablet, setIsTablet] = useState(isTabletDimension());

  const category = training.training_class!.category_nr;
  const isTraining = category == Category.Training;
  const isMind = category == Category.Mind;
  const isRunning = category == Category.Running;

  const hasGraph =
    category == Category.Cycling || category == Category.Walking || category == Category.Running;

  const showGraph =
    trainingClass && (hasGraph || (store && store.trainingClass.progression_training)) && !isMind;

  const handleResize = () => {
    const isNewTabletDimension = isTabletDimension();

    // if (isNewTabletDimension != isTablet) {
    setIsTablet(isNewTabletDimension);
    // }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    refetch();
    refetchTrainingClass();
  }, [isOpen]);

  const getTrainingData = (trc: Training) => {
    const typeGraph = !trc.type_graph || trc.type_graph == 0 ? 'fc' : 'watt';
    const showFcGraph =
      typeGraph === 'fc' && trainingClass?.graph && !trc.bestcycling_training?.has_ftms;

    if (showFcGraph) {
      return {
        mode: 'fc',
        progression: trainingClass.progression,
        progression_watts: [],
      };
    }

    if (trainingClass?.progression_watts) {
      return {
        mode: 'watt',
        progression: trainingClass.progression,
        progression_watts: trainingClass.progression_watts,
      };
    }

    return {
      mode: 'fc',
      progression: [],
      progression_watts: [],
    };
  };

  useEffect(() => {
    if (!data) return;
    if (!trainingClass) return;

    const trainingComplete = data;
    const userSettings = preferences;

    const trd = getTrainingData(trainingComplete);

    const trainingData = {
      mode: trd.mode,
      progression: trd.progression,
      progression_watts: trd.progression_watts,
      progression_training: trainingComplete.bestcycling_training?.has_heart_rate
        ? JSON.parse(trainingComplete.training_data?.heart_rate || '')
        : null,
      progression_gps: trainingComplete.bestcycling_training?.has_gps
        ? JSON.parse(trainingComplete.training_data?.gps || '')
        : null,
      progression_ftms: trainingComplete.bestcycling_training?.has_ftms
        ? JSON.parse(trainingComplete.training_data?.ftms || '')
        : null,
      category: trainingClass.category_nr,
      category_nr: trainingClass.category_nr,
      duration_seconds: trainingClass.duration_seconds,
    };

    let options = userSettings.playerPreferences;
    options = {
      ...options,
      is_tablet: isTablet,
      web_app: true,
      graph: {
        ...options.graph,
      },
    };

    const trainingClassComplete = {
      ...trainingClass,
      ...trainingData,
    };

    setStore({
      trainingClass: trainingClassComplete,
      options: { ...options },
    });
  }, [data, trainingClass, isTablet]);

  const getDisplays = (
    dataValues: Training | undefined,
    trainingClassComplete: TrainingClass | undefined,
  ) => {
    if (category == Category.Cycling) {
      return (
        <CyclingDisplays
          training={dataValues}
          trainingClass={trainingClassComplete}
          ftp={preferences.getFtp()}
        />
      );
    }

    if (category == Category.Walking) {
      return <CommonDisplays training={dataValues} />;
    }

    if (category == Category.Training) {
      return <TrainingDisplays training={dataValues} />;
    }

    if (category == Category.Balance) {
      return <BalanceDisplays training={dataValues} />;
    }

    if (category == Category.Running) {
      return (
        <RunningDisplays training={dataValues} /> // velocity={preferences.running_format_velocity}
      );
    }

    return <CommonDisplays training={dataValues} />;
  };

  useEffect(() => {
    if (store != null && isTraining && store.round == null && data) {
      const trainingByBlock = getTrainingByBlock(trainingClass);
      let results = trainingByBlock;

      if (data.training_data != null || data.previous_annotation != null) {
        results = completeAnnotation(trainingByBlock, data.training_data, data.previous_annotation);
      }

      if (results != null) {
        setRounds(results);
      }
    }
  }, [store?.trainingClass]);

  const getGraphHeight = () => {
    const size = isTablet ? 430 : 740;
    //Mostramos las dos gráficas solo si has realizado la clase con potencia (con o sin ftms) y tienes pulsometro, o tienes datos de ftms y pulsómetro a la vez. O es running y tenemos pulsómetro
    if (
      (data?.type_graph == 1 && data?.bestcycling_training?.has_heart_rate) ||
      (data?.bestcycling_training?.has_ftms && data?.bestcycling_training?.has_heart_rate) ||
      (isRunning && data?.bestcycling_training?.has_heart_rate)
    ) {
      return (size + 50) * 2;
    }

    return size;
  };

  if (!document.getElementById('modal-portal')) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Resumen de entrenamiento</div>
      </div>
      <div className={styles.trainingCard}>
        <NavLink to={`/trainingclass/${training.training_class!.id}`}>
          <Training training={training} onClose={onClose} />
        </NavLink>
      </div>

      {isLoading || isLoadingClass ? (
        <LoadingIndicator />
      ) : (
        <div className={styles.content}>
          <div className={styles.displaysContainer}>
            <div className={styles.titleGraph}>DATOS GENERALES</div>
            {getDisplays(data, trainingClass)}
            {isTraining && rounds ? (
              <>
                <div className={styles.titleGraph}>RESULTADOS POR RONDA</div>
                <ResultsByBlock
                  progressionBlocks={rounds}
                  showTotals={true}
                  showPrevious={!!data?.previous_annotation}
                />
              </>
            ) : null}

            {showGraph ? (
              <>
                <div className={styles.titleGraph}>GRÁFICA DE ENTRENAMIENTO</div>
                <div className={styles.graphContainer}>
                  {store != null ? (
                    <Graph
                      trainingClass={store.trainingClass}
                      options={store.options}
                      height={getGraphHeight()}
                      mode={store.trainingClass.mode}
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={isAnnotate ? goObtainedPoints : onClose}
              size="normal"
              variant="primary"
            >
              {isAnnotate ? 'SIGUIENTE' : 'CERRAR'}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
