import musicIcon from './../../../../../img/bestpro/myclasses/music-icon.svg';

export default function MusicIcon() {
  return (
    <img
      src={musicIcon}
      alt=""
      aria-hidden="true"
      style={{ height: '17px', marginRight: '10px', marginLeft: '3px', marginBottom: '-3px' }}
    />
  );
}
