import iconDelete from '../../../../../src/img/icon-delete.svg';

export default function DeleteIcon() {
  return (
    <img
      src={iconDelete}
      alt="Eliminar"
      style={{
        height: '15px',
        marginRight: '7px',
        marginBottom: '-3px',
      }}
      aria-hidden="true"
    />
  );
}
