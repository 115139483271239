import { useNav } from '@/contexts/nav.context';
import { useToast } from '@/contexts/toast.context';
import clsx from 'clsx';
import React, { createRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './Toast.module.scss';

type ToastProps = {
  text?: string;
  variant?: 'none' | 'success' | 'error' | 'warn' | 'info';
  id: string;
  nodeRef?: React.RefObject<HTMLDivElement>;
};
const Toast = ({ text = '', variant = 'none', id, nodeRef }: ToastProps) => {
  const { hideToast } = useToast();
  const {
    state: { show: navShowing },
  } = useNav();

  return (
    <>
      {navShowing && (
        <div className={clsx(styles.toast, styles[variant])} ref={nodeRef}>
          <p>{text}</p>
          <button
            className={styles.close}
            aria-label="close notification"
            onClick={() => hideToast(id)}
          />
        </div>
      )}
    </>
  );
};

export const Toasts = () => {
  const { state } = useToast();

  // Show toasts
  return (
    <div className={styles.toastsContainer}>
      <TransitionGroup className="">
        {state.map(({ text, variant, id }) => {
          const toastRef = createRef<HTMLDivElement>();

          return (
            <CSSTransition key={id} classNames={styles.toast} nodeRef={toastRef} timeout={500}>
              <Toast key={id} text={text ?? ''} variant={variant} id={id} nodeRef={toastRef} />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};
