import { useState } from 'react';
import Slider from '../Slider';
import VisualTraining from './VisualTraining';
import VisualTrainingModal from './VisualTrainingModal';
import styles from './VisualTrainings.module.scss';

type VisualTrainingsProps = {
  exercises: TrainingClassExercise[];
};

const VisualTrainings = (props: VisualTrainingsProps) => {
  const [currentTraining, setCurrentTraining] = useState<number | null>(null);

  const showCurrentTraining = currentTraining !== null;

  const openCurrentTraining = (index: number) => setCurrentTraining(index);
  const closeCurrentTraining = () => setCurrentTraining(null);

  const prevTraining = () => {
    if (currentTraining === null) return;

    if (currentTraining > 0) {
      setCurrentTraining(currentTraining - 1);
    }
  }

  const nextTraining = () => {
    if (currentTraining === null) return;

    if (currentTraining < props.exercises.length - 1) {
      setCurrentTraining(currentTraining + 1);
    }
  }

  return (
    <div className={styles.container}>
      <Slider automaticSlideSize={false}>
        {props.exercises!.map((visualTraining, index) => (
          <VisualTraining key={index} training={visualTraining} onPlay={() => openCurrentTraining(index)} />
        ))}
      </Slider>

      {showCurrentTraining && (
        <VisualTrainingModal
          isOpen={showCurrentTraining}
          onClose={closeCurrentTraining}
          training={props.exercises[currentTraining!]}
          onPrevClick={prevTraining}
          onNextClick={nextTraining}
        />
      )}
    </div>
  );
};

export default VisualTrainings;
