import { useApi } from '@/api/ApiProvider';
import { useQuery } from '@tanstack/react-query';

const queryKey = 'app_preferences';

const useFetchAppPreferences = () => {
  const { fetchApi } = useApi();

  return useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchApi([queryKey]),
  });
};

export default useFetchAppPreferences;
