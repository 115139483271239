import GraphImage from './GraphImage';
import Category from '../../constants/category';
import AspectRatio from '../AspectRatio';

import styles from './GraphImages.module.scss';

export default function GraphImages({ trainingClass }) {
  const showProgressions =
    [Category.Cycling, Category.Walking, Category.Running].includes(trainingClass.category_nr) &&
    ((trainingClass.is_new && trainingClass.official) ||
      (trainingClass.graph_watts && trainingClass.graph));

  return showProgressions ? (
    <>
      {trainingClass.graph_watts && trainingClass.category_nr == Category.Cycling ? (
        <AspectRatio ratio={120 / 240} className={styles.graphContainer}>
          <GraphImage graph={trainingClass.graph_watts} title="Gráfica de potencia" />
        </AspectRatio>
      ) : null}
      {trainingClass.graph ? (
        <AspectRatio ratio={120 / 240} className={styles.graphContainer}>
          <GraphImage graph={trainingClass.graph} title="Gráfica de frecuencia cardíaca" />
        </AspectRatio>
      ) : null}
    </>
  ) : null;
}
