export default {
  type: 'nutrition_profile_recipes',
  fields: {
    completed: 'boolean',
    created_at: 'string',
    day: 'string',
    id: 'string',
    nutrients: 'array',
    portions: 'number',
    position: 'number',
    profile_id: 'string',
    recipe_id: 'string',
    recommendated_for: 'string',
    recommendated_type: 'number',
    updated_at: 'string',
  },
  relationships: {
    recipe: {
      type: 'recipe',
    },
  },
};
