import dayjs from 'dayjs';
import defaultAvatar from '@/img/avatar_default.jpg';

export default function userModel(user: User): IUser {
  const isSubscribed = () => {
    const { current_membership } = user;

    if (!current_membership) return false;

    const { member_until } = current_membership;

    return dayjs(member_until).isAfter(dayjs());
  };

  const canPlay = (trainingClass: TrainingClass) => {
    return trainingClass.free || isSubscribed();
  };

  const getAvatar = () => {
    const { avatar_url } = user;

    return avatar_url?.match(/^https?:\/\//) ? avatar_url : defaultAvatar;
  };

  const getAge = () => {
    const { birthday } = user;

    if (!birthday) return null;

    return dayjs().diff(dayjs(birthday), 'years');
  };

  const isGymsMembership = () => {
    const { current_membership } = user;

    if (!current_membership) return false;
    const { plan } = current_membership;
    return plan === 'gimnasios';
  };

  return {
    ...user,
    isSubscribed,
    canPlay,
    getAvatar,
    getAge,
    isGymsMembership,
  };
}
