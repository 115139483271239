import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';

const useRelatedTrainingClasses = (id: string | number) => {
  const { fetchApi, getNextPageParam } = useApi();

  return useQuery<TrainingClass[]>(['training_classes', id, 'related'], async ({ pageParam }) => {
    const dataRaw = await fetchApi([
      'training_classes',
      id,
      'related',
      {
        include: ['trainer', 'training_materials'],
        limit: 10,
      },
    ]);

    return dataRaw.data;
  });
};

export default useRelatedTrainingClasses;
