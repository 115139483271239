import Tag from '../Tag';
import { useUser } from '@/contexts/app.context';

export default function FreeIndicator({ trainingClass }) {
  const { state } = useUser();
  return (
    !state.isSubscribed() &&
    trainingClass.free && (
      <div style={{ width: 'fit-content' }}>
        <Tag color="blue">GRATIS</Tag>
      </div>
    )
  );
}
