import Button from '@/core/components/UI/Button';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Cookies } from '../constants/cookies';
import CookiesConfigurationModal from './CookiesConfigurationModal';

import styles from './CookiesModal.module.scss';

type CookiesModalProps = {
  acceptAll(): void;
  accept(cookies: string[]): void;
  decline(): void;
};

/**
 * Button ids are used with tag manager for tracking
 *
 * @returns
 */
const CookiesModal = (props: CookiesModalProps) => {
  const [showConfiguration, setShowConfiguration] = useState(false);

  const openConfiguration = () => setShowConfiguration(true);
  const closeConfiguration = () => setShowConfiguration(false);

  const accept = () => {
    props.acceptAll();
    closeConfiguration();
  };

  const configure = (cookies: string[]) => {
    props.accept(cookies);
    closeConfiguration();
  };

  const decline = () => {
    props.decline();
    closeConfiguration();
  };

  return ReactDOM.createPortal(
    <div className={styles.container} id="cookies">
      <p className={styles.description}>
        Utilizamos cookies propias y de terceros para recopilar información sobre las actividades de
        navegación, mejorar nuestros servicios y adaptar la publicidad a tus preferencias. Para más
        información acude a nuestra{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://bestcycling.com/pages/politica-de-cookies"
          className={styles.link}
        >
          Política de cookies
        </a>
        . Puedes aceptar, rechazar o personalizar tus preferencias de cookies haciendo click en
        “Configurar”.
      </p>
      <div className={styles.buttons}>
        <Button
          id="COOKIE_ACCEPT_ALL_BUTTON"
          onClick={accept}
          className={styles.primary}
          variant="primary"
        >
          ACEPTAR TODO
        </Button>
        <Button id="COOKIE_DECLINE_BUTTON" onClick={decline} variant="terciary">
          RECHAZAR
        </Button>
        <Button id="COOKIE_CONFIGURE_BUTTON" onClick={openConfiguration} variant="terciary">
          CONFIGURAR
        </Button>
      </div>
      {showConfiguration && (
        <CookiesConfigurationModal
          cookies={Cookies.bestcycling}
          isOpen={showConfiguration}
          onAccept={configure}
          onDecline={decline}
          onClose={closeConfiguration}
        />
      )}
    </div>,
    document.getElementById('modal-portal')!,
  );
};

export default CookiesModal;
