import iconPlay from '../../../../../img/constructor/reproductor/icon-play.svg';

export default function Play() {
  return (
    <img
      src={iconPlay}
      alt=""
      style={{ marginTop: '3px', width: '26px', height: '26px' }}
    />
  );
}
