import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Colors as colors } from '@/constants/category';
import TrainingClassCover from './TrainingClassCover';
import TrainingClassHeader from './TrainingClassHeader';
import LifePoint from '../../LifePoint/LifePoint';
import UserProgressBar from './UserProgressBar';

import style from './TrainingClass.module.scss';
import TrainingClassMaterials from './TrainingClassMaterials';
import EditPencilIcon from '@/components/Icons/EditPencilIcon';

import TrainingClassExtra from './TrainingClassExtra';
import SubscribeModal from '../../SubscribeModal';
import { useUser } from '@/core/contexts/app.context';
import { useRemoteConfig } from '@/core/contexts/remote.context';
import { useState } from 'react';

interface TrainingClassProps {
  trainingClass: TrainingClass;
  showTagList: boolean;
  onSelect?: (id: string | number) => void;
  isBestPro?: boolean;
  editModeEnabled?: boolean;
  needEditClassOptions?: boolean;
  genreIdFiltered?: number;
}
export default function TrainingClass({
  trainingClass,
  showTagList = true,
  onSelect,
  isBestPro,
  editModeEnabled = true,
  needEditClassOptions,
  genreIdFiltered,
}: TrainingClassProps) {
  const { pathname } = useLocation();
  const { state: user } = useUser();
  const { isRestrictedCreateClasses } = useRemoteConfig();
  const navigate = useNavigate();
  const [isSubscriptionVerificationOpen, setIsSubscriptionVerificationOpen] = useState(false);

  const { id, official, category_nr, is_new, is_black } = trainingClass;

  const trainingClassUrl = isBestPro ? `/bestpro/trainingclass/${id}` : `/trainingclass/${id}`;

  const whiteBackground = (category_nr == 1 || category_nr == 2) && is_new && !is_black;

  const trainingClassUrlEdit = `/bestpro/constructor/${id}`;

  const closeSubscriptionVerificationModal = () => setIsSubscriptionVerificationOpen(false);

  const openModal = () => {
    if (user.isSubscribed() || !isRestrictedCreateClasses()) {
      navigate(trainingClassUrlEdit);
    } else {
      setIsSubscriptionVerificationOpen(true);
    }
  };

  const handleClick = (evt: React.MouseEvent) => {
    if (typeof onSelect == 'function') {
      evt.preventDefault();
      onSelect(id);
    }
  };

  const isFavoritePath = pathname.split('/').slice(2, 3).includes('favourites');

  const classes = clsx(style.trainingClass, [
    whiteBackground && style.bgWhite,
    !official && style.custom,
  ]);

  const classesEditPencil = clsx(style.editButtonContainer, isFavoritePath && style.favoriteStyle);

  return (
    <div data-cy="training_class" className={style.container}>
      <SubscribeModal
        isOpen={isSubscriptionVerificationOpen}
        onClose={closeSubscriptionVerificationModal}
        onSubscribe={openModal}
        user={user}
      />
      <Link
        to={trainingClassUrl}
        className={classes}
        onClick={handleClick}
        role="link"
        tabIndex={0}
        aria-label={
          trainingClass.official
            ? `${trainingClass.short_title} ${trainingClass.subtitle}, ${trainingClass.trainer?.full_name}`
            : trainingClass.title
        }
      >
        <div className={style.selectedClassOverlay}></div>
        <div className={style.cover}>
          <TrainingClassCover trainingClass={trainingClass} />

          <div className={style.title}>
            <TrainingClassHeader
              trainingClass={trainingClass}
              isModeSelectMultiple={!editModeEnabled}
              needEditClassOptions={needEditClassOptions}
            />
          </div>

          <div className={style.info}>
            {trainingClass.official ? (
              <div className={style.points}>
                <LifePoint type="resistance" value={trainingClass.life_resistance_points} />
                <LifePoint type="force" value={trainingClass.life_force_points} />
                <LifePoint type="flexibility" value={trainingClass.life_flexibility_points} />
                <LifePoint type="mind" value={trainingClass.life_mind_points} />
              </div>
            ) : null}
            <div className={style.materials}>
              <TrainingClassMaterials trainingClass={trainingClass} />
            </div>
          </div>
          <UserProgressBar
            seconds={trainingClass?.training?.seconds}
            total={
              trainingClass.official
                ? trainingClass.duration_training
                : trainingClass.duration_seconds
            }
            color={colors[trainingClass.category_nr as 1 | 2 | 11 | 21 | 31 | 41]}
          />
        </div>

        <TrainingClassExtra
          showTagList={showTagList}
          trainingClass={trainingClass}
          genreIdFiltered={genreIdFiltered}
        />
      </Link>

      {!official && editModeEnabled && (
        <div className={classesEditPencil}>
          <Link
            style={{ backgroundColor: 'transparent' }}
            to={trainingClassUrlEdit}
            role="link"
            tabIndex={0}
          >
            <div className={style.editButton}>
              <EditPencilIcon />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
