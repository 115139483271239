import { meal } from '../contexts/nutrition.types';

const NutritionKeys = {
  all: 'all',
  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'dinner',
  snack: 'snack',
  dessert: 'dessert',
  dinner_or_lunch: 'dinner_or_lunch',
  only_snack: 'only_snack',
};

const AllowedNutritionKeys = [
  NutritionKeys.breakfast,
  NutritionKeys.lunch,
  NutritionKeys.dinner,
  NutritionKeys.snack,
  NutritionKeys.dessert,
];

const Difficulty = Object.freeze({
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
});

const DifficultyName = Object.freeze({
  [Difficulty.EASY]: 'Fácil',
  [Difficulty.MEDIUM]: 'Media',
  [Difficulty.HARD]: 'Difícil',
});

const Duration = Object.freeze({
  SHORT: 1,
  MEDIUM: 2,
  LONGTH: 3,
});

const RecomendedTitle: RecomendedHeader = {
  [NutritionKeys.all]: 'Todas las recetas',
  [NutritionKeys.breakfast]: 'Desayuno',
  [NutritionKeys.lunch]: 'Comida',
  [NutritionKeys.dinner]: 'Cena',
  [NutritionKeys.snack]: 'Snack',
  [NutritionKeys.dessert]: 'Postre',
};

type RecomendedHeader = {
  [key: string]: string;
};

const RecomendedHeader: RecomendedHeader = {
  [NutritionKeys.all]: 'TODAS LAS RECETAS',
  [NutritionKeys.breakfast]: 'DESAYUNOS',
  [NutritionKeys.lunch]: 'COMIDAS',
  [NutritionKeys.dinner]: 'CENAS',
  [NutritionKeys.dinner_or_lunch]: 'COMIDAS Y CENAS',
  [NutritionKeys.snack]: 'SNACKS',
  [NutritionKeys.dessert]: 'POSTRES',
};

type MealByPosition = {
  [key: number]: { [key: number]: string };
};

type PositionByMeal = {
  [key: number]: { [key: string]: number };
};

const MealByPosition: MealByPosition = {
  2: { 0: 'lunch', 1: 'dinner' },
  3: { 0: 'breakfast', 1: 'lunch', 2: 'dinner' },
  4: { 0: 'breakfast', 1: 'snack', 2: 'lunch', 3: 'dinner' },
  5: { 0: 'breakfast', 1: 'snack', 2: 'lunch', 3: 'snack2', 4: 'dinner' },
};

const PositionByMeal: PositionByMeal = {
  2: { lunch: 0, dinner: 1 },
  3: { breakfast: 0, lunch: 1, dinner: 2 },
  4: { breakfast: 0, snack: 1, lunch: 2, dinner: 3 },
  5: { breakfast: 0, snack: 1, lunch: 2, snack2: 3, dinner: 4 },
};

const DictDays: { [key in meal]: string } = {
  breakfast: 'Desayuno',
  snack: 'Snack',
  snack1: 'Snack',
  snack2: 'Snack',
  lunch: 'Comida',
  dinner: 'Cena',
};
const Schedules: { [key: number]: meal[] } = {
  1: ['lunch'],
  2: ['lunch', 'dinner'],
  3: ['breakfast', 'lunch', 'dinner'],
  4: ['breakfast', 'snack1', 'lunch', 'dinner'],
  5: ['breakfast', 'snack1', 'lunch', 'snack2', 'dinner'],
};

export {
  NutritionKeys,
  AllowedNutritionKeys,
  Difficulty,
  DifficultyName,
  Duration,
  RecomendedTitle,
  RecomendedHeader,
  MealByPosition,
  PositionByMeal,
  DictDays,
  Schedules,
};
