import clsx from 'clsx';
import { useEffect, useState } from 'react';

import NpsConfirmedState from './NpsConfirmedState';
import NpsDeclinedState from './NpsDeclinedState';
import NpsInitialState from './NpsInitialState';

import styles from './NpsSurveyCard.module.scss';
import dismissible from '../../Slider/DismissibleSlide.module.scss';

import { useApi } from '@/core/api/ApiProvider';

type DismissibleState = 'visible' | 'hide';

type NpsState = 'initial' | 'confirmed' | 'declined';

const NpsSurveyCard = () => {
  const { client } = useApi();
  const [status, setStatus] = useState<NpsState>('initial');
  const [slideState, setSlideState] = useState<DismissibleState>('visible');

  const confirm = () => setStatus('confirmed');
  const decline = () => setStatus('declined');

  const close = () => setSlideState('hide');

  const hide = async () => {
    client.invalidateQueries(['front'])
    client.setQueryData(['front'], (old: any) => {
      return {
        ...old,
        data: {
          ...old.data,
          resources: old.data.resources.filter((resource: any) => resource.key !== 'nps_app'),
        },
      };
    });
  }

  useEffect(() => {
    return () => {
      if (slideState === 'hide') hide();
    }
  }, [slideState]);

  return (
    <div className={clsx(styles.container, slideState === 'hide' && dismissible.hide)} >
      {status === 'initial' && <NpsInitialState onDecline={decline} onConfirm={close} />}
      {status === 'confirmed' && <NpsConfirmedState onClose={close} />}
      {status === 'declined' && <NpsDeclinedState onResult={confirm} />}
    </div>
  );
}

export default NpsSurveyCard;