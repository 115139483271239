import { useApi } from '@/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';

const usePostUserTag = ({ onSuccess }: { onSuccess: (result: UserTag) => void }) => {
  const { mutateApi, client } = useApi();

  const createNewTag = useMutation<UserTag, ErrorConstructor, UserTag>(
    async data => {
      const res = await mutateApi(['user_tags'], data, {
        method: 'POST',
      });
      if (res.error) {
        throw new Error(res.error);
      }

      console.log('res', res.data);
      return res.data;
    },
    {
      onSettled: () => {
        client.invalidateQueries(['user_tags']);
      },
      onSuccess: result => {
        console.log('OnSuccess, res', result);
        onSuccess(result);
      },
    },
  );

  return createNewTag;
};

export default usePostUserTag;
