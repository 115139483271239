import { useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { useUser } from '@/core/contexts/app.context';

const createPlayAdapter = ({
  trainingClassId,
  mediaType,
  playDate,
}: {
  trainingClassId: string;
  mediaType: string;
  playDate: Date;
}): Plays => {
  return {
    download_type: mediaType,
    play_date: playDate.toISOString(),
    training_class_id: trainingClassId,
  };
};

export default function usePostPlays(trainingClass?: TrainingClass, mediaType?: string) {
  const LOCAL_STORAGE_KEY = 'playsSaved';
  const { mutateApi } = useApi();
  const {
    state: { id: userId },
  } = useUser();

  const playDate = useMemo<Date>(() => new Date(), []);

  const savePlaysLocally = (plays: Plays[]) => {
    const playsLocal: SavedPlays = loadFromLocalStorage();

    // Check if they already exist
    plays = plays.filter(play => {
      return !playsLocal[userId]?.some(
        p => p.play_date == play.play_date && p.training_class_id == play.training_class_id,
      );
    });

    playsLocal[userId] = playsLocal[userId] || [];
    playsLocal[userId].push(...plays);

    saveToLocalStorage(playsLocal);
  };

  const getDeferredPlaysForCurrentUser = (): Plays[] => {
    const savedPlays: SavedPlays = loadFromLocalStorage();
    return savedPlays[userId] || [];
  };

  /////////////////////////////////////////////
  // Operations to save or load in localStorage ensuring correct type
  /////////////////////////////////////////////
  const saveToLocalStorage = (playsToSave: SavedPlays) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(playsToSave));
  };

  const loadFromLocalStorage = (): SavedPlays => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  };

  /////////////////////////////////////////////
  // Mutation
  /////////////////////////////////////////////

  const createPlayMutation = useMutation(
    async (data: { plays: Plays[] }) => {
      const res = await mutateApi(['plays'], data, {
        method: 'POST',
      });
      if (res.error) {
        console.error('Error saving play', res.error);

        savePlaysLocally(data.plays);
        throw new Error(res.error);
      }
    },
    {
      onSuccess: (_, variables) => {
        const localPlays = loadFromLocalStorage();
        const deferredPlays = getDeferredPlaysForCurrentUser();

        const plays = deferredPlays.filter(play => {
          return !variables.plays.some(
            p => p.play_date === play.play_date && p.training_class_id === play.training_class_id,
          );
        });

        saveToLocalStorage({ ...localPlays, [userId]: plays });
      },
      onError: (err, variables) => {
        console.error('Error saving play', err);
        savePlaysLocally(variables.plays);
      },
      networkMode: 'always',
    },
  );

  /////////////////////////////////////////////
  // Public API
  /////////////////////////////////////////////

  /**
   * Post play to the server, if it fails, save it locally
   */
  const savePlay = () => {
    if (!trainingClass || !mediaType || !trainingClass.id) {
      console.error('Training class or media type is missing', trainingClass, mediaType);
      return;
    }

    const play = createPlayAdapter({
      trainingClassId: trainingClass.id.toString(),
      playDate,
      mediaType,
    });

    createPlayMutation.mutate({ plays: [play] });
  };

  /**
   * Post all deferred plays to the server
   */
  const postDeferredPlays = () => {
    const localPlays = loadFromLocalStorage();
    const plays = getDeferredPlaysForCurrentUser();

    localPlays[userId] = [];
    saveToLocalStorage(localPlays);

    if (plays.length === 0) return;
    createPlayMutation.mutate({ plays });
  };

  return {
    savePlay,
    postDeferredPlays,
  };
}
