import styles from './ProfileDisplay.module';

export default function ProfileDisplay({ label, value }) {
  return (
    <div className={styles.profileDisplay}>
      <main className={styles.number}>{value}</main>
      <footer className={styles.txt}>{label}</footer>
    </div>
  );
}
