import { useUser } from '@/contexts/app.context';

import { Outlet } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';

export default function GymsProtectedRoute() {
  const { state: user } = useUser();
  const isGymsMembership = user.current_membership.plan === 'gimnasios';

  if (isGymsMembership) return <Outlet />;

  if (window.electronAPI) {
    window.open('/gimnasios', '_blank');
  } else {
    window.location.replace(`/gimnasios`);
  }
  return <LoadingIndicator />;
}
