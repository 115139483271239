import { forwardRef, LegacyRef } from 'react';

import styles from './FormInput.module.scss';

const FormInput = forwardRef(
  (
    { icon, onClick, ...props }: { icon?: string; onClick?: () => void; [key: string]: any },
    ref: LegacyRef<HTMLInputElement>,
    type: 'text' | 'password' = 'text',
  ) => {
    return (
      <div className={styles.inputContainer}>
        <input
          ref={ref}
          type={type}
          className={`${styles.formInput} ${props.icon && styles.paddingRight}`}
          {...props}
        />
        {onClick && (
          <button type="button" onClick={onClick} className={styles.iconButton}>
            <img src={icon} />
          </button>
        )}
      </div>
    );
  },
);

FormInput.displayName = 'FormInput';

export default FormInput;
