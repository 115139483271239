import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/api/ApiProvider';

type useFetchFavouriteProps = {
  id: string;
  type: 'Nutrition::Recipe' | 'TrainingClass' | 'Playlist';
};

const useFetchFavourite = ({ id, type }: useFetchFavouriteProps) => {
  const { fetchApi } = useApi();

  return useQuery(['favourites', id, type], () =>
    fetchApi([
      'favourites',
      {
        filter: { target_type: type, target_id: id },
      },
    ]),
  );
};

export default useFetchFavourite;
