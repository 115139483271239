import { useEffect, useState } from "react";

type useMediaProps = {
  media: HTMLVideoElement | HTMLAudioElement;
  onTogglePlaying(): void;
  onTimeUpdate(second: number): void;
}

const useMedia = ({ media, onTogglePlaying, onTimeUpdate }: useMediaProps) => {
  const [currentDuration, setCurrentDuration] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const asPlaying = () => setIsPlaying(true);
  const asPaused = () => setIsPlaying(false);

  const asWaiting = () => setIsWaiting(true);
  const asReady = () => setIsWaiting(false);

  const play = () => {
    media.play();
  }

  const pause = () => {
    media.pause();
  }

  const togglePlaying = () => {
    onTogglePlaying();

    if (media.paused) {
      play();
    } else {
      pause();
    }
  }

  const seekTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const second = parseInt(event.target.value);
    media.currentTime = second;

    onTimeUpdate(second);
  }

  const updateDuration = () => {
    const currentTime = Math.trunc(media.currentTime);
    setCurrentDuration(currentTime);

    onTimeUpdate(currentTime);
  }

  useEffect(() => {
    media.addEventListener('timeupdate', updateDuration);
    media.addEventListener('play', asPlaying);
    media.addEventListener('pause', asPaused);
    media.addEventListener('waiting', asWaiting);
    media.addEventListener('playing', asReady);

    return () => {
      media.removeEventListener('timeupdate', updateDuration);
      media.removeEventListener('play', asPlaying);
      media.removeEventListener('pause', asPaused);
      media.removeEventListener('waiting', asWaiting);
      media.removeEventListener('waiting', asReady);
    }
  }, []);

  return {
    currentDuration,
    isPlaying, 
    isWaiting,
    togglePlaying,
    seekTo,
  }
}

export default useMedia;