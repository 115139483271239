import { useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import BTConnectModal from '../Preferences/BTConnectModal';
import Button from '../UI/Button';
import styles from './ConnectingBikeModal.module.scss';
import zycleImg from '../../../../../src/img/img-zycle.png';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { BluetoothDeviceState, BluetoothDeviceTypes, BTStatus } from '@/constants/bluetooth';
import BikeConnectedModal from './BikeConnectedModal';
import KnowingFtpModal from './KnowingFtpModal';
import { useProfile, useUser } from '@/contexts/app.context';
import useSetting from '@/components/Preferences/useSetting';
import { GraphType } from '@/constants/graphFactory';
import { useRemoteConfig } from '@/contexts/remote.context';

const TAG_CONNECTABLE = 'connectable';
const ZYCLE_TAG = 'zycle';

export default function ConnectingBikeModal() {
  const [connectingBike, setConnectionBike] = useState({
    initStep: false,
    searchingStep: false,
    bikeConnectedStep: false,
    knowingFtpStep: false,
  });

  const { initStep, searchingStep, bikeConnectedStep, knowingFtpStep } = connectingBike;
  const { state, actions } = useBTContext();
  const { state: user } = useUser();
  const { isRestrictedConnectivity } = useRemoteConfig();
  const hasTagConnectble = user?.tag_list.includes(TAG_CONNECTABLE);
  const bluetoothOn = state.status == BTStatus.poweredOn;
  const bikeConnectedPrev = localStorage.getItem('bikeConnected');
  const bikesAvaiable = actions.getAvaiableDevices(BluetoothDeviceTypes.Bike);
  const bikesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.Bike);
  const bikeDevices = actions.getBluetoothDevices(BluetoothDeviceTypes.Bike);

  const { state: profile } = useProfile();
  const [_, setGraphType] = useSetting('graph_type');

  const [noDevices, setNoDevices] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);

  const btDevicesList = useRef<BTDevice[] | BluetoothDevice[]>([]);
  const zycleNative = bikeDevices.filter(bike => bike.name === 'ZycleZBike');
  const zycleMixed = (btDevicesList.current as BTDevice[]).filter(b => b.name === 'ZycleZBike');

  const searchDevices = () => {
    if (state.typeBluetooth == 'native') {
      actions.startScan();
      setLoadingDevices(true);
    } else if (state.typeBluetooth == 'mixed') {
      setLoadingDevices(true);
      const gattServices = actions.getGattServices();
      navigator.bluetooth
        .requestDevice({ filters: gattServices })
        .then(device => {
          console.log('SELECTED DEVICE ', device);
          actions.connectDeviceChromium(device);
          // setDeviceSelectedChromium(device);
        })
        .catch(e => {
          console.log('ERROR ', e);
        });
    }

    setConnectionBike({ ...connectingBike, initStep: false, searchingStep: true });

    setTimeout(() => {
      if (bikeDevices.length == 0) {
        setNoDevices(true);
        setLoadingDevices(false);
      }
      setLoadingDevices(false);
    }, 10000);
  };

  const onClickDevice = (dev: BTDevice) => {
    if (dev.state === BluetoothDeviceState.disconnected) {
      actions.connectDevice(dev);
    } else if (
      dev.state === BluetoothDeviceState.connected ||
      dev.state === BluetoothDeviceState.connecting
    ) {
      actions.disconnectDevice(dev.id);
    }
  };

  const onCancel = () => {
    localStorage.setItem('bikeConnected', 'true');
    setConnectionBike({
      initStep: false,
      searchingStep: false,
      bikeConnectedStep: false,
      knowingFtpStep: false,
    });
  };

  useEffect(() => {
    if (bikesConnected.length > 0 && searchingStep) {
      setGraphType(GraphType.Watts);
      localStorage.setItem('wattsSetOnConnectedBike', 'true');
      setConnectionBike({ ...connectingBike, searchingStep: false, bikeConnectedStep: true });
    }
  }, [bikesConnected.length]);

  useEffect(() => {
    if (bikeDevices.length > 0) {
      btDevicesList.current = actions.getBluetoothDevices(BluetoothDeviceTypes.Bike);
      if (zycleNative || zycleMixed) {
        setLoadingDevices(false);
      }
    }
  }, [bikeDevices.length]);

  const checkBluetoothAssistant = () => {
    if (user?.isSubscribed() || !isRestrictedConnectivity()) {
      if ((bluetoothOn || navigator.bluetooth) && hasTagConnectble && !bikeConnectedPrev) {
        if (state.typeBluetooth == 'native' && !bikesConnected.length) {
          return true;
        } else if (state.typeBluetooth == 'mixed') {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (searchingStep) return;
    if (checkBluetoothAssistant()) {
      setConnectionBike({ ...connectingBike, initStep: true });
    } else setConnectionBike({ ...connectingBike, initStep: false });
  }, [state.typeBluetooth, state.status, hasTagConnectble, bikesAvaiable.length]);

  return (
    <>
      <Modal isOpen={initStep} className={styles.modalSmartBike}>
        <div className={styles.container}>
          <button className={styles.close} onClick={onCancel}></button>
          <div className={styles.containerHelp}>
            <h1 className={styles.title}>TE AYUDAMOS A CONECTAR TU SMARTBIKE</h1>
            <p className={styles.description}>
              Sabemos que tienes muchas ganas de empezar a <br /> entrenar con tu nueva bicicleta.
              Por eso, queremos <br /> ayudarte en su configuración y que el proceso te <br />
              resulte lo más sencillo posible.
            </p>
            <br />
            <p className={styles.description}>
              Asegúrate que tu dispositivo está enchufado a la corriente antes de continuar.
            </p>
            <br />

            <footer>
              <Button onClick={searchDevices} size="small" className={styles.button}>
                BUSCAR DISPOSITIVOS
              </Button>
            </footer>
          </div>
          <img src={zycleImg} alt=""></img>
        </div>
      </Modal>
      {searchingStep && (
        <BTConnectModal
          isOpen={searchingStep}
          isLoading={loadingDevices}
          selectDevice={dev => onClickDevice(dev)}
          onCancel={onCancel}
          btDevicesList={state.typeBluetooth === 'native' ? zycleNative : zycleMixed}
        />
      )}
      {bikeConnectedStep && (
        <BikeConnectedModal
          isOpen={bikeConnectedStep}
          onCancel={onCancel}
          devices={actions.getConnectedDevices(BluetoothDeviceTypes.Bike)}
          onConfirm={
            profile?.ftp
              ? () => onCancel()
              : () =>
                  setConnectionBike({
                    ...connectingBike,
                    bikeConnectedStep: false,
                    knowingFtpStep: true,
                  })
          }
        />
      )}
      {knowingFtpStep && <KnowingFtpModal isOpen={knowingFtpStep} onCancel={onCancel} />}
    </>
  );
}
