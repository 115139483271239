import React, { Ref, useEffect } from "react";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";

import ExternalDismissibleBox from "../ExternalDismissibleBlox/ExternalDismissibleBox";
import EditableMenuItem, { DropdownItem } from "../TrainingClasses/EditableMenuItem";

import styles from "./Dropdown.module.scss";
import Positioned from "../Positioned/Positioned";
import { BLOCK_SCROLL } from "@/core/constants/behaviour";

type DropdownProps = {
  parentRef: Ref<unknown>,
  position: "left" | "right",
  items: DropdownItem[],
  requestClose?(): void,
}

const Dropdown = React.forwardRef((props: DropdownProps, ref: any) => {
  const classNames = clsx(styles[props.position], styles.dropdown, BLOCK_SCROLL);

  const close = () => {
    if (props.requestClose) {
      props.requestClose();
    }
  }

  useEffect(() => {
    window.addEventListener('resize', close);

    return () => {
      window.removeEventListener('resize', close);
    }
  }, []);

  return (
    <ExternalDismissibleBox requestClose={props.requestClose}>
      <Positioned parentRef={props.parentRef}>
        <FocusTrap>
            <ul ref={ref} className={`${classNames}`}>
            {
              props.items.map((item, index) => {
                return <EditableMenuItem key={index} {...item} />;
              })
            }
            </ul>
        </FocusTrap>
      </Positioned>
    </ExternalDismissibleBox>
  );
});

Dropdown.displayName = "Dropdown";

export default Dropdown;