import { useParams } from 'react-router';

import { FooterMin } from '../components/Footer';
import LoadingIndicator from '../components/LoadingIndicator';
import Page from '../components/Page';
import useFetchTrainingClass from '../hooks/apiHooks/useFetchTrainingClass';
import VideoProgress from '../components/sensei/VideoProgress/VideoProgress';

const mediaType = 'video_hd';

const VideoProgressPage = () => {
  const { id } = useParams();

  const { data, isLoading } = useFetchTrainingClass(id);

  return (
    <Page footer={<FooterMin />}>
      {isLoading && <LoadingIndicator />}
      {data && <VideoProgress trainingClass={data!} media={mediaType} />}
    </Page>
  );
};

export default VideoProgressPage;
