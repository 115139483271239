import { useEffect } from 'react';
import Button from '../UI/Button';
import styles from './ToastDialog.module.scss';

interface TotastDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  description?: string;
  confirmText?: string;
}

export default function ToastDialog({
  isOpen,
  onConfirm,
  onClose,
  description,
  confirmText,
}: TotastDialogProps) {
  const keyboardListener = (evt: any) => {
    if (evt.key == 'Escape') {
      onClose?.();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyboardListener);

    return () => {
      window.removeEventListener('keyup', keyboardListener);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <p className={styles.title}>{description}</p>
        <div className={styles.buttons}>
          <Button variant="primary" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
        <button title="Cerrar modal" className={styles.close} onClick={onClose} />
      </div>
    </div>
  );
}
