import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { v4 } from 'uuid';
import { IToastContext, ToastState, toastVariant } from './toast.types';

const ToastContext = createContext<IToastContext>({
  state: [],
  showToast: () => '',
  hideToast: () => {},
});

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<ToastState[]>([]);

  const hideToast = (id: string) => {
    setState(prev => prev.filter(t => t.id != id));
  };

  // Push a toast to be rendered by Toasts component
  const showToast = (text: string, variant: toastVariant = 'none', seconds = 10) => {
    const id = v4();
    const newToast = { id, text, variant };
    setState(prev => [newToast, ...prev]);

    // Remove toast after seconds
    setTimeout(() => {
      hideToast(id);
    }, seconds * 1000);
    return id;
  };

  useEffect(() => {
    const cbShowToast = (_: any, text: string, variant: toastVariant = 'none', duration = 10) =>
      showToast(text, variant, duration);

    window.electronAPI?.handleToast(cbShowToast);
  }, []);

  return (
    <ToastContext.Provider value={{ state, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
