import { useEffect, useMemo, useState } from 'react';
import TrainingExercises from '../TrainingExercices/TrainingExercises';
import ToggeableContent from './ToggeableContent';
import styles from './TrainingSections.module.scss';
import clsx from 'clsx';

type TrainingSectionsProps = {
  trainingClass: TrainingClass;
};

const TrainingSections = ({ trainingClass }: TrainingSectionsProps) => {
  const roundBlocks = useMemo(() => {
    return trainingClass.progression_blocks;
  }, [trainingClass]);
  return (
    <>
      <section>
        <TrainingExercises trainingClass={trainingClass} rounds={roundBlocks} />
      </section>
      <section>
        <ToggeableContent header="Entrenamiento" canToggle show>
          {roundBlocks && (
            <>
              {roundBlocks.map((block, index) => (
                <ProgressionBlockDisplay
                  key={index}
                  progressionBlock={block}
                  blockNum={index + 1}
                  totalBlock={trainingClass.progression_blocks?.length ?? 1}
                />
              ))}
            </>
          )}
        </ToggeableContent>
      </section>
    </>
  );
};

export default TrainingSections;

const ProgressionBlockDisplay = ({
  progressionBlock,
  blockNum,
  totalBlock,
}: {
  progressionBlock: ProgressionBlock;
  blockNum: number;
  totalBlock: number;
}) => {
  const [cyclingBlockTime, setCyclingBlockTime] = useState(0);

  const calculateCyclingTime = (cyclingProgression: ProgressionBlock) => {
    if (!cyclingProgression.length) {
      return 0;
    }

    return cyclingProgression
      .map((round: ProgressionRound) => {
        return round.progressions
          .map((point: Exercise) => {
            const time = point['time'];
            return parseInt(time.replace(/[a-zA-Z\s+]/, '')) ?? 0;
          })
          .reduce((accum, current) => accum + current);
      })
      .reduce((accum, current) => accum + current);
  };

  useEffect(() => {
    if (progressionBlock && progressionBlock.length > 0 && progressionBlock[0].cyclingRound)
      setCyclingBlockTime(calculateCyclingTime(progressionBlock));
  }, [progressionBlock]);

  if (!progressionBlock || !progressionBlock.length) return null;
  return (
    <div className={styles.trainingFlex}>
      {totalBlock > 1 && (
        <div className={styles.titleCenter}>
          <h3>BLOQUE {blockNum}</h3>
        </div>
      )}
      {progressionBlock[0].cyclingRound && (
        <div className={styles.displayRounds}>
          <CyclingRound time={cyclingBlockTime} />
        </div>
      )}

      {!progressionBlock[0].cyclingRound &&
        progressionBlock.map((round, index) => (
          <div key={index} className={styles.round}>
            <h4>
              RONDA {index + 1} de {progressionBlock.length}{' '}
            </h4>
            <div className={styles.roundMethods}>
              <RoundDisplay round={round} />
            </div>
          </div>
        ))}
    </div>
  );
};

const RoundDisplay = ({ round }: { round: ProgressionRound }) => {
  const capitalize = (s: string) => {
    return s
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      {round.progressions.map((progression, index) => (
        <div key={index} className={styles.roundMethod}>
          <span className={styles.methodSeconds}>{`${progression.time} seg`}</span>
          {capitalize(progression.method)}
        </div>
      ))}
    </>
  );
};

const CyclingRound = ({ time }: { time: number }) => {
  const timeStr = useMemo(() => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const remainingSeconds = time % 60;

    if (hours > 0) return `${hours} horas`;
    if (minutes > 0) return `${minutes} min`;

    return `${remainingSeconds} seg`;
  }, [time]);

  return (
    <div className={styles.displayRound}>
      <div className={styles.roundExercises}>
        <div className={clsx(styles.lineExercise, styles.bigger)}>
          <div className={styles.description}>
            {timeStr} <span className={styles.cyclingMethod}>Bloque cardiovascular</span>
          </div>
        </div>
      </div>
    </div>
  );
};
