import { ReactNode } from 'react';
import { IModalContext, ModalState } from './modal.types';

import { createContext, useContext, useEffect, useState } from 'react';

const initialState = {
  show: false,
  title: '',
  text: null,
  textOk: '',
  textCancel: '',
  isElectron: false,
  cbOk: () => {},
  cbCancel: () => {},
};

const ModalContext = createContext<IModalContext>({
  state: initialState,
  close: () => {},
  show: () => {},
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<ModalState>(initialState);

  useEffect(() => {
    window.electronAPI?.handleModal(
      (ev: Event, text: string, textOk: string | undefined, textCancel: string | undefined) => {
        setState(prev => {
          return {
            ...prev,
            show: true,
            text,
            textOk: textOk ?? '',
            textCancel: textCancel ?? '',
            isElectron: true,
          };
        });
      },
    );
  });

  const close = () => setState(initialState);
  const show = (
    text: string,
    textOk: string,
    textCancel: string,
    cbOk: () => any,
    cbCancel = () => {},
  ) => {
    setState(prev => ({ ...prev, show: true, text, textOk, textCancel, cbOk, cbCancel }));
  };

  return <ModalContext.Provider value={{ state, close, show }}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);
