import { useEffect } from 'react';

import { useAuth } from '../contexts/auth.context';
import LoadingIndicator from '../components/LoadingIndicator';
import useDocumentTitle from '@rehooks/document-title';
import { useApi } from '../api/ApiProvider';

export default function LogoutPage() {
  useDocumentTitle('Cerrando sesión... | Bestcycling');
  const { state, logout } = useAuth();
  const { client } = useApi();

  useEffect(() => {
    if (state.isLoggedIn) {
      logout();
      client.clear();
    }
  }, [state.isLoggedIn, logout]);

  return <LoadingIndicator />;
}
