import FindClassesPage from './FindClassesPage';

const SearchClassesPage = () => {
  return (
    <>
      <FindClassesPage section="search" official={true} />
    </>
  );
};

export default SearchClassesPage;
