import { useApi } from '@/core/api/ApiProvider';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const CommentableTypes: { [key: string]: string } = {
  'Nutrition::Recipe': 'Nutrition::Recipe',
  TrainingClass: 'TrainingClass',
  Default: 'TrainingClass',
};

const InvalidateTypes = {
  'Nutrition::Recipe': 'nutrition_recipes',
  TrainingClass: 'training_classes',
  Default: 'training_classes',
};

export default function useCreateComment(
  {
    id,
    parentId,
    type,
  }: {
    id: string;
    parentId: string | null;
    type: string;
  },
  onCreate: () => void,
): [(data: { comment: string }) => void, UseMutationResult<any, unknown, void, unknown>] {
  const commentableId = id;
  const commentableType = CommentableTypes[type] || CommentableTypes.Default;
  // const invalidate = InvalidateTypes[type] || InvalidateTypes.Default;
  const { mutateApi, client } = useApi();

  const createCommentMutation = useMutation(
    async data =>
      await mutateApi(['comments'], data, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['comments']);
        client.invalidateQueries(['feeds','feeds/notifications']);
        onCreate();
      },
    },
  );

  const createComment = async (data: any) => {
    createCommentMutation.mutateAsync({
      ...data,
      parent_id: parentId,
      commentable_id: commentableId,
      commentable_type: commentableType,
    });
  };

  return [createComment, createCommentMutation];
}
