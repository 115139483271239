import { Cookie } from "../types";

const CookieTypes = {
  COOKIE_ESSENTIALS: 'ESSENTIALS',
  COOKIE_ADS: 'AD_STORAGE',
  COOKIE_ANALYTICS: 'ANALYTICS_STORAGE',
}

const BESTCYCLING_COOKIES = [CookieTypes.COOKIE_ESSENTIALS, CookieTypes.COOKIE_ADS, CookieTypes.COOKIE_ANALYTICS,];

const cookies: { [key: string]: Cookie } = {
  [CookieTypes.COOKIE_ESSENTIALS]: {
    label: 'Cookies necesarias',
    isToggable: false,
    description:
      'Son esenciales para que el sitio web funcione correctamente. No requieren el consentimiento del usuario. Por ejemplo, cookies que permiten la navegación por la página, acceder a áreas seguras, etc.',
  },
  [CookieTypes.COOKIE_ADS]: {
    label: 'Cookies publicitarias',
    isToggable: true,
    description:
      'Estas cookies se utilizan para rastrear la actividad del usuario en el sitio web y en otros sitios, con el fin de mostrar anuncios personalizados. Pueden ser establecidas por el propio sitio web o por terceros, como redes de publicidad.',
  },
  [CookieTypes.COOKIE_ANALYTICS]: {
    label: 'Cookies de analítica',
    isToggable: true,
    description:
      'Estas cookies recopilan información sobre cómo los usuarios interactúan con el sitio web, con el fin de comprender y mejorar su funcionamiento.',
  },
};

export const Cookies = {
  types: CookieTypes,
  data: cookies,
  bestcycling: BESTCYCLING_COOKIES
}