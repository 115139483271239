/**
 * Returns string with number and singular or plural depending on quantity.
 *
 * @param {number} quantity
 * @param {string} singular
 * @param {string} plural
 * @returns String with number and singular or plural depending on quantity.
 */
export default function pluralize(quantity = 0, singular = '', plural = '') {
  return `${quantity} ${quantity === 1 ? singular : plural}`;
}
