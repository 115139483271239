import { Filter } from './Filter';

export function TrainingSortFilter() {
  const order = [
    { value: '-published_at', label: 'Fecha desc', type: 'sort' },
    { value: 'published_at', label: 'Fecha asc', type: 'sort' },
  ];

  return <Filter options={order} type="sort" tag="ORDENAR POR" needsAll={false} />;
}
