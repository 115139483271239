import React from 'react';
import styles from './AuthProviders.module.scss';
import appleIcon from '@/img/apple-icon.svg'; // Añade un icono de Apple si lo tienes
import { useAuth } from '@/core/contexts/auth.context';
import AppleSignInButton from 'react-apple-signin-auth';
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth';

const CLIENT_ID = config?.APPLE_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/oauth/auth/apple/callback`;

type LoginWithAppleProps = {
  handleError: React.Dispatch<React.SetStateAction<string>>;
};

type SuccessAppleResponse = {
  authorization: {
    code: string;
    id_token: string;
  };
};

const LoginWithApple: React.FC<LoginWithAppleProps> = ({ handleError }) => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const { loginProvider } = useAuth();

  const handleSuccess = ({ authorization }: SuccessAppleResponse) => {
    const { code, id_token } = authorization;
    loginProvider('apple', { code, identity_token: id_token });
  };

  const handleFailure = (error: any) => {
    handleError('Error al realizar la autenticación con Apple');
  };

  // const onClick = async evt => {
  //   evt.preventDefault();
  //   const authOptions = {
  //     clientId: CLIENT_ID,
  //     scope: 'email name',
  //     redirectURI: REDIRECT_URI,
  //     // state: 'state',
  //     // nonce: 'nonce',
  //     usePopup: true,
  //   };
  //   console.info(authOptions);
  //   const response = await appleAuthHelpers.signIn({
  //     authOptions,
  //     onSuccess: handleSuccess,
  //     onError: handleError,
  //   });

  //   if (response) {
  //     console.log(response);
  //   } else {
  //     console.error('Error performing apple signin.');
  //   }
  // };

  return (
    <div className={`${styles.buttonContainer} button_web button_web_extra`}>
      <AppleSignInButton
        authOptions={{
          clientId: CLIENT_ID,
          scope: 'email name',
          redirectURI: REDIRECT_URI,
          // state: 'state',
          // nonce: 'nonce',
          usePopup: true,
        }}
        uiType="dark"
        onSuccess={handleSuccess}
        onError={handleFailure}
        render={(props: any) => (
          <button
            {...props}
            className={`${styles.socialButton} ${styles.btnApple}`} // Clase btnApple con fondo negro
          >
            <img src={appleIcon} alt="Apple" className={styles.icon} />
            Continuar con Apple
          </button>
        )}
      />
    </div>
  );
};

export default LoginWithApple;
