import clsx from 'clsx';
import { useState } from 'react';
import useFetchUserTags from '../../hooks/apiHooks/useFetchUserTags';
import AddTagModal from '../MyClasses/AddTagModal';

import styles from './MyCollectionButton.module.scss';

import myCollection from '@/img/playlist-class.svg';

type MyCollectionButtonProps = {
  trainingClass: TrainingClass;
};

const MyCollectionButton = ({ trainingClass }: MyCollectionButtonProps) => {
  const { data, isLoading } = useFetchUserTags();

  const [showCollectionsModal, setShowCollectionsModal] = useState(false);

  const openCollectionsModal = () => setShowCollectionsModal(true);
  const closeCollectionsModal = () => setShowCollectionsModal(false);

  const classNames = clsx(styles.collection);

  return (
    <>
      <button
        onClick={openCollectionsModal}
        disabled={isLoading}
        className={classNames}
        title={showCollectionsModal ? '' : 'Añadir o quitar de colecciones'}
      >
        <div className={styles.border}>
          <img src={myCollection} />
        </div>
      </button>
      {data && (
        <AddTagModal
          isOpen={showCollectionsModal}
          tagList={data}
          trainingClasses={[trainingClass]}
          onClose={closeCollectionsModal}
          onSelectTag={() => {}}
        />
      )}
    </>
  );
};

export default MyCollectionButton;
