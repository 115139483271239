import useDetectConnection from '@/core/hooks/useDetectConnection';
import { useEffect } from 'react';

export default function useUpdateTrainingClassLocal(
  trainingClass: TrainingClass,
  trainingClassLocal: TrainingClass | undefined,
) {
  const connectionStatus = useDetectConnection();
  const isOffline = connectionStatus === 'offline';

  useEffect(() => {
    if (!trainingClassLocal || !trainingClass || isOffline) return;

    const { training_class, ...training } = trainingClass.training ?? {};

    window.downloadsAPI?.updateTrainingClass?.({
      ...trainingClass,
      downloadedMedia: trainingClassLocal?.downloadedMedia,
      training: {
        ...training,
        seconds: trainingClass.training?.seconds ?? 0,
      }
    });
  }, [trainingClass]);
}
