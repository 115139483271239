// import { useEffect, useState } from 'react';

import { Filter, SearchFilter } from '@/components/Filters/Filter';
import Page from '@/components/Page';
import TrainingClassesList from '@/components/TrainingClasses/TrainingClassesList';

import EmptyFilteredSection from '@/components/EmptyFilteredSection/EmptyFilteredSection';
import EmptySection from '@/components/EmptySection/EmptySection';
import Materials from '@/constants/materials';
import { useDownloads } from '@/contexts/downloads.context';
import FiltersContext from '@/contexts/filters.context';
import iconSearch from '@/img/icon-explore@2x.png';
import useDocumentTitle from '@rehooks/document-title';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import offlineDownloadsIcon from '../../../img/icon-offline.svg';
import styles from '../components/Filters/Filters.module.scss';
import FiltersIcon from '../components/Icons/FiltersIcon';
import Category from '../constants/category';
import TrainingDoneFilter from '../components/Filters/TrainingDoneFilter';
import useParamsFilters from '../hooks/useParamsFilters';

const defaultFilters = {
  category_nr: 'all',
  year: 'all',
  trainer_id: 'all',
  level_nr: 'all',
  duration: 'all',
  sort: 'published_at',
  materials: 'all',
  search: '',
  genres: '',
  done_by_user: '',
};

interface IDefaultFilters {
  value: number;
  label: string;
  type: string;
}

type ITrainers = IDefaultFilters[];
type IGenres = IDefaultFilters[];

// TODO: this page needs to use the DownloadsContext to be used in desktop
// now it's using an unused Downloadeer

const FiltersOffline = ({ trainers, genres }: { trainers: ITrainers; genres: IGenres }) => {
  const [visible, setVisible] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const onButtonFiltersClick = () => setVisible(!visible);

  const yearOptions = [
    ...[...Array(new Date().getFullYear() + 1 - 2012)]
      .map((_, i) => ({ value: `${2012 + i}`, label: (2012 + i).toString(), type: 'year' }))
      .reverse(),
  ];

  const trainingTypes = [
    { value: Category.Cycling, label: 'Bestcycling', type: 'category_nr' },
    { value: Category.Walking, label: 'Bestwalking', type: 'category_nr' },
    { value: Category.Balance, label: 'Bestbalance', type: 'category_nr' },
    { value: Category.Running, label: 'Bestrunning', type: 'category_nr' },
    { value: Category.Training, label: 'Besttraining', type: 'category_nr' },
  ];

  const duration = [
    { value: 'short', label: 'Cortas, menos de 30 minutos', type: 'duration' },
    { value: 'long', label: 'Largas, más de 30 minutos', type: 'duration' },
  ];

  const level = [
    { value: '10', label: 'Principiante', type: 'level_nr' },
    { value: '20', label: 'Medio', type: 'level_nr' },
    { value: '30', label: 'Avanzado', type: 'level_nr' },
  ];

  const order = [
    { value: '-published_at', label: 'Fecha desc', type: 'sort' },
    { value: 'published_at', label: 'Fecha asc', type: 'sort' },
  ];

  const materials = [ {value: '-1', label: 'Sin material', type: 'materials'}, ...Materials.map(m => ({ value: m.id, label: m.name, type: 'materials' }))];

  return (
    <div className={styles.wrapcontainer}>
      <div className={`${styles.filterscontainer} ${visible ? styles.mobile_visible : ''}`}>
        <button className={styles.button_filters} onClick={onButtonFiltersClick}>
          <FiltersIcon />
          <p>Filtros</p>
        </button>

        <div className={styles.group_left}>
          <div className={styles.mobile_topbar}>
            <div className={styles.mobile_topbar_back}>
              <button onClick={onButtonFiltersClick}></button>
              <span className={styles.title}>Filtros</span>
            </div>
          </div>
          <Filter options={trainingTypes} type="category_nr" tag="TIPO" />
          <Filter options={yearOptions} type="year" tag="AÑO" />
          <Filter options={trainers} type="trainer_id" tag="INSTRUCTOR" />
          <Filter options={level} type="level_nr" tag="NIVEL" />
          <Filter options={duration} type="duration" tag="DURACIÓN" />
          <Filter options={materials} type="materials" tag="MATERIALES" />
          <Filter options={genres} type="genres" tag="Género musical" />
          <TrainingDoneFilter />
        </div>
        <div className={styles.group_right}>
          <a
            className={styles.button_search}
            onClick={() => {
              setMobileSearch(!mobileSearch);
            }}
          >
            <img src={iconSearch} />
          </a>
          {/* @ts-ignore */}
          <SearchFilter />
          <div className={styles.order}>
            <Filter options={order} type="sort" tag="ORDENAR POR" needsAll={false} />
          </div>
        </div>
      </div>
      <div className={`${styles.searchMobileFilter} ${mobileSearch ? styles.search_visible : ''}`}>
        {/* @ts-ignore */}
        <SearchFilter />
      </div>
    </div>
  );
};

const OfflineTrainings = ({ trainings }: { trainings: TrainingClass[] }) => {
  const navigate = useNavigate();
  const navigateTo = (trainingId: string | number) => {
    navigate(`/offline/trainingclass/${trainingId}`);
  };

  return (
    <>
      <TrainingClassesList data={trainings} onSelect={navigateTo} />
    </>
  );
};

export default function OfflinePage() {
  useDocumentTitle('Clases offline | Bestcycling');

  const { state: downloads } = useDownloads();

  const [trainings, setTrainings] = useState<TrainingClass[]>(
    downloads.trainingClasses.map(t => t.trainingClass).filter(t => t !== null),
  );

  const { defaultFilters: finalFilters, updateParams } = useParamsFilters(defaultFilters);
  const [filters, setFilters] = useState(finalFilters);

  const filterBySearch = (list: TrainingClass[]) => {
    return !filters.search
      ? list
      : list.filter(
          v =>
            v.title.toLowerCase().includes(filters.search.toLowerCase()) ||
            v.subtitle?.toLowerCase().includes(filters.search.toLowerCase()),
        );
  };

  const filterByPublished = (list: TrainingClass[]) => {
    return filters.sort === 'published_at'
      ? list.sort((a, b) => a.published_at_timestamp - b.published_at_timestamp)
      : list.sort((a, b) => b.published_at_timestamp - a.published_at_timestamp);
  };

  const filterByCategory = (list: TrainingClass[]) => {
    return filters.category_nr && filters.category_nr !== 'all'
      ? list.filter(v => v.category_nr == parseInt(filters.category_nr))
      : list;
  };

  const filterByYear = (list: TrainingClass[]) => {
    if (!filters.year || filters.year === 'all') return list;

    return list.filter(v => {
      const year = v && v.published_at?.length ? v.published_at : '';
      return year.includes(filters.year);
    });
  };

  const filterByLevel = (list: TrainingClass[]) => {
    return filters.level_nr && filters.level_nr !== 'all'
      ? list.filter(v => v.level_nr == parseInt(filters.level_nr))
      : list;
  };

  const filterBySeconds = (list: TrainingClass[]) => {
    if (!filters.duration || filters.duration === 'all') return list;

    return filters.duration === 'short'
      ? list.filter(v => v.duration_seconds / 60 < 30)
      : list.filter(v => v.duration_seconds / 60 > 30);
  };

  const filterByTrainer = (list: TrainingClass[]) => {
    if (!filters.trainer_id || filters.trainer_id === 'all') return list;

    return list.filter(v => v.trainer_id == parseInt(filters.trainer_id)) || list;
  };

  const filterByMaterials = (list: TrainingClass[]) => {
    if (!filters.materials || filters.materials === 'all') return list;
    if(filters.materials == '-1'){
      return list.filter(v => {
        // Filtramos las categorías que no sean ni 1 ni 2
        const notCategory1Or2 = v.category_nr !== 1 && v.category_nr !== 2;

        // Hay una estructura que está mal con trainingMaterials: data. Lo controlamos por si acaso procede de la app antigua
        const trainingMat =
          v.training_materials &&
          typeof v.training_materials === 'object' &&
          'data' in v.training_materials
            ? Array(v.training_materials.data)
            : v.training_materials;

        // Filtramos los elementos que no tienen training_materials o que sean nulos
        const noTrainingMaterials = !trainingMat || trainingMat.length === 0 || trainingMat.every(mat => mat == null);

        return notCategory1Or2 && noTrainingMaterials;
      });

    }else{
      return list.filter(v => {
        //Si es bicicleta o eliptica , seleccionamos la categoría ciclo o walking
        const materialCategory = v.category_nr.toString() == filters.materials;
        //Hay una estructura que está mal con trainingMaterials: data. Lo controlamos por si acaso procede de la app antigua
        const trainingMat =
          v.training_materials &&
          typeof v.training_materials === 'object' &&
          'data' in v.training_materials
            ? Array(v.training_materials.data)
            : v.training_materials;

        const filtroMaterials = trainingMat?.some(
          mat => mat != null && mat['id']?.toString().includes(filters.materials),
        );
        return materialCategory || filtroMaterials;
      });
    }
  };

  const filterByGenres = (list: TrainingClass[]) => {
    if (!filters.genres || filters.genres === 'all') return list;

    const filteredList = list.filter(v => {
      return v.training_genres?.some(gen => gen.id.toString().includes(filters.genres));
    });

    return filteredList;
  };

  const filterByDone = (list: TrainingClass[]) => {
    const { done_by_user } = filters;

    if (!done_by_user) return list;

    return list.filter(trainingClass => {
      const seconds = trainingClass.training?.seconds ?? 0;

      return done_by_user === 'true' ? seconds > 0 : !seconds;
    });
  };

  const isDefaultFilters = useMemo(() => {
    const defaultValues = Object.values(defaultFilters);
    const values = Object.values(filters)
      .filter(element => element)
      .filter(element => !defaultValues.includes(element));

    return !values.length;
  }, [filters]);

  const trainers: ITrainers = useMemo(() => {
    if (!downloads) return [];

    let trainingClasses = downloads.trainingClasses
      .map(t => t.trainingClass)
      .filter(t => t !== null);

    // Al cambiar catgoría sólo mostramos trainers que tengan estas clases.
    if (!!parseInt(filters.category_nr, 10)) {
      trainingClasses = trainingClasses.filter(
        tc => filters.category_nr == tc.category_nr.toString(),
      );
    }

    const _trainers = trainingClasses.map(tc => {
      const trainer = tc?.trainer;
      const title = tc?.title;
      const trainerId = trainer?.id || trainer?.data?.id || tc.trainer_id;

      let fullName = trainer?.full_name;

      if (!fullName) {
        const titleParts = title.split('-');
        fullName = titleParts.length > 1 ? titleParts[1].trim() : title;
      }

      return {
        value: trainerId,
        label: fullName,
        type: 'trainer_id',
      };
    });

    const uniqueTrainersOptions = _trainers.filter((element, index, self) => {
      return index === self.findIndex(e => e.value === element.value);
    });

    return uniqueTrainersOptions;
  }, [downloads, filters]);

  const genres: IGenres = useMemo(() => {
    if (!downloads) return [];

    const trainingClasses = downloads.trainingClasses
      .map(t => t.trainingClass)
      .filter(t => t !== null);

    const genresArray = trainingClasses.flatMap(tc =>
      (tc.training_genres || []).map(gr => ({
        value: gr.id,
        label: gr.name,
        type: 'genres',
      })),
    );

    return genresArray;
  }, [downloads, filters]);

  useEffect(() => {
    let newTrs = downloads.trainingClasses.map(t => t.trainingClass).filter(tr => tr != null);

    newTrs = filterBySearch(newTrs);
    newTrs = filterByPublished(newTrs);
    newTrs = filterByCategory(newTrs);
    newTrs = filterByYear(newTrs);
    newTrs = filterByLevel(newTrs);
    newTrs = filterBySeconds(newTrs);
    newTrs = filterByTrainer(newTrs);
    newTrs = filterByMaterials(newTrs);
    newTrs = filterByGenres(newTrs);
    newTrs = filterByDone(newTrs);

    setTrainings(newTrs);
  }, [filters, downloads]);

  useEffect(() => {
    updateParams(filters);
  }, [filters]);

  return (
    <>
      <Page>
        <FiltersContext.Provider value={{ filters, setFilters }}>
          <div id="list_classes_filter">
            <FiltersOffline trainers={trainers} genres={genres} />
          </div>

          {!trainings.length ? (
            isDefaultFilters ? (
              <EmptySection
                icon={offlineDownloadsIcon}
                headerText="Todavía no tienes clases offline"
                bodyText="Para poder reproducir offline una clase debes entrar previamente en su ficha, pulsar en la nube y completar su descarga."
              />
            ) : (
              <EmptyFilteredSection text="No hay clases disponibles con los perfiles seleccionados." />
            )
          ) : (
            <OfflineTrainings trainings={trainings} />
          )}
        </FiltersContext.Provider>
      </Page>
    </>
  );
}
