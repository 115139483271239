import { useDownload } from '@/contexts/downloads.context';
import downloadIconActive from '@/img/icon-offline-active.svg';
import downloadIconCompleted from '@/img/icon-offline-completed.svg';

export default function OfflineIndicator({ trainingClass, ...props }) {
  const downloads = useDownload(trainingClass.id);
  const offline = downloads?.downloadedMedia.find(m => m.downloaded || m.downloading || m.queued);
  const completed = downloads?.downloadedMedia.find(m => m.downloaded);

  if (!offline) return null;

  return completed ? (
    <img src={downloadIconCompleted} alt="Descarga completada" {...props} />
  ) : (
    <img src={downloadIconActive} alt="Descarga pendiente" {...props} />
  );
}
