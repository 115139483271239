export enum BluetoothDeviceTypes {
  Unknown = 'unknown',
  HeartRate = 'heartrate',
  Bike = 'bike',
}

export enum BluetoothDeviceState {
  unknown = 'unknown',
  connecting = 'connecting',
  connected = 'connected',
  disconnecting = 'disconnecting',
  disconnected = 'disconnected',
  error = 'error',
}

export enum BTStatus {
  unknown = 'unknown',
  poweredOff = 'poweredOff',
  poweredOn = 'poweredOn',
  resetting = 'resetting',
  unauthorized = 'unauthorized',
  unsupported = 'unsupported',
}

export const TranslateBluetoothDeviceState = Object.freeze({
  unknown: 'desconocido',
  connecting: 'conectando',
  connected: 'conectado',
  disconnecting: 'desconectando',
  disconnected: 'desconectado',
  error: 'error',
});

export enum BikeDataFeatures {
  spedd = 'Velocidad',
  avgSpeed = 'Velocidad media',
  cadence = 'Cadencia',
  avgCadence = 'Cadencía media',
  distance = 'Distancia',
  resistance = 'Nivel de resistencia',
  power = 'Potencia',
  energy = 'Calorías',
  totalEnergy = 'Calorías totales',
  energyPerHours = 'Calorías por hora',
  energyPerMinutes = 'Calorías por minuto',
  heartRate = 'Heart Rate',
  meatbolic = 'Metabolic',
  elapsedTime = 'Elapsed time',
  remainingTime = 'Remaining time',
  powerTarget = 'Modo automático',
}

export const GattSpecification = {
  heartRate: {
    service: 'heart_rate',
    measurements: {
      heartRate: 'heart_rate_measurement',
    },
    allowedNames: ['polar h9', 'polar h10', 'polar oh1', 'heartbeat', 'hrm30', 'h6m 54210'],
  },
  ftms: {
    service: 'fitness_machine',
    measurements: {
      bikeData: 'indoor_bike_data',
      feature: 'fitness_machine_feature',
      powerRange: '2ad8',
      controlPoint: 'fitness_machine_control_point',
      trainingStatus: '2ad3',
      status: '2ada',
      resitanceRange: 'supported_resistance_level_range',
    },
    controlPoint: {
      requestControl: [0x00],
      reset: [0x01],
      stop: [0x08, 0x01],
      pause: [0x08, 0x02],
      start: [0x07],
      setResistance: [0x04],
      setPower: [0x05],
    },
  },
  keiser: {
    allowedNames: ['M3'],
  },
  power: {
    service: 'cycling_power',
    measurements: {
      features: 'cycling_power_feature',
      bikeData: 'cycling_power_measurement',
    },
  },
  zycleButton: {
    service: 'beefee02-4910-473c-be46-960948c2f59c',
    measurements: {
      buttonControl: 'beefe004-4910-473c-be46-960948c2f59c',
    },
  },
  bhCustom: {
    service: '72d70001-501f-46f7-95f9-23846ee1aba3',
    measurements: {
      rx: '72d70003-501f-46f7-95f9-23846ee1aba3',
      tx: '72d70002-501f-46f7-95f9-23846ee1aba3',
    },
  },
};
