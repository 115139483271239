import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDownload } from '../contexts/downloads.context';
import { useShell } from '@/contexts/shell.context';
import { useToast } from '@/contexts/toast.context';
import Page from '../components/Page';
import TrainingClassDetails from '../components/TrainingClassDetails/TrainingClassDetails';

export default function OfflineClassPage() {
  const { id } = useParams();
  const download = useDownload(id!);
  const { state: shell } = useShell();
  const { showToast } = useToast();

  const navigate = useNavigate();

  const goToTrainingClass = () => navigate(`/trainingclass/${id}`, { replace: true });

  const goToOffline = () => navigate('/offline');

  useEffect(() => {
    if (!download || !download?.trainingClass) {
      showToast('Eliminadas todas las descargas de esta clase', 'none', 5);
      goToOffline();
    }
  }, [download]);

  useEffect(() => {
    if (shell.online) goToTrainingClass();
  }, [shell]);

  return (
    <Page>
      {download?.trainingClass && (
        <TrainingClassDetails
          trainingClass={download.trainingClass}
          hideMedia={false}
          hasMediaClass={download.trainingClass?.media.length > 0}
          isRetriesMediaFailed={false}
        />
      )}
    </Page>
  );
}
