import Modal from '../Modal';
import Button from '../UI/Button';
import BluetoothListDevices from './BluetoothListDevices';
import styles from './ConnectingBikeModal.module.scss';
import checkboxChecked from '../../../../../src/img/nutrition/checkbox-checked.svg';

interface BikeConnectedModalProps {
  isOpen: boolean;
  onCancel: () => void;
  devices: BTDevice[];
  onConfirm: () => void;
}

export default function BikeConnectedModal({
  isOpen,
  onCancel,
  devices,
  onConfirm,
}: BikeConnectedModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modalBikeConnected} onRequestClose={onCancel}>
      <img className={styles.checkboxChecked} src={checkboxChecked} alt="" />
      <h1 className={styles.title}> ¡TU BICICLETA YA ESTÁ LISTA PARA USARSE! </h1>
      <p className={styles.description}>
        Te damos la bienvenida a Bestcycling. Ya puedes empezar a entrenar con tu smartbike Zycle.
        Descubre todo lo que hemos preparado para ti.
      </p>
      <BluetoothListDevices devices={devices} selectDevice={() => {}} />
      <footer>
        <Button onClick={onConfirm} size="small" className={styles.button}>
          ¡A ENTRENAR!
        </Button>
      </footer>
    </Modal>
  );
}
