import { useState } from 'react';
import CommentBox from './CommentBox';

const ToggleResponsesButton = ({ visible, count, onClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick(!visible);
  };

  return count > 2 ? (
    <div style={{ margin: '1em 0' }}>
      <a href="#" onClick={handleClick} style={{ textTransform: 'uppercase', color: '#FF7700' }}>
        {visible ? <>Ocultar las {count} respuestas</> : <>Ver las {count} respuestas</>}
      </a>
    </div>
  ) : null;
};

const Replies = ({ visible, replies, isFreeTc }) => {
  const visibleReplies = visible ? replies : replies.slice(0, 2);

  return replies.length ? (
    <div style={{ marginLeft: 50, paddingRight: '1em' }}>
      {visibleReplies.map((r, i) => (
        <CommentBox key={i} comment={r} isFreeTc={isFreeTc} />
      ))}
    </div>
  ) : null;
};

export default function CommentWithReplies({ comment, replies, expanded = false, isFreeTc }) {
  const [repliesOpened, setRepliesOpened] = useState(expanded);

  return (
    <div>
      <CommentBox comment={comment} isFreeTc={isFreeTc} />
      <ToggleResponsesButton
        visible={repliesOpened}
        count={comment.replies_count}
        onClick={setRepliesOpened}
      />
      <Replies visible={repliesOpened} replies={replies} isFreeTc={isFreeTc} />
    </div>
  );
}
