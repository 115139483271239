import { useState } from 'react';
import { useAuth } from '@/contexts/auth.context';
import Button from '@/components/UI/Button/Button';
import LoadingIndicator from '@/components/LoadingIndicator';
import MusicIcon from '@/components/Icons/MyClasses/MusicIcon';

const DownloadMusic = ({ trainingClass }) => {
  const { state: auth } = useAuth();

  const getMusic = () => {
    const media = trainingClass?.media.find(m => m.type == 'music');
    if (media != null) {
      return `${media?.url}&access_token=${auth.accessToken}`;
    }
    return null;
  };

  const getTitle = () => {
    return trainingClass.title + ' ' + trainingClass.subtitle + '.mp3';
  };
  const [downloaded, setDownloaded] = useState(false);
  const [progress, setProgress] = useState(0);

  const saveFile = async () => {
    try {
      const handle = await window.showSaveFilePicker({
        suggestedName: getTitle(),
        types: [
          {
            accept: { 'audio/mp3': ['.mp3'] },
          },
        ],
      });

      const writable = await handle.createWritable();
      return writable;
    } catch (err) {
      console.error(err.name, err.message);
    }
    return null;
  };
  const handleDownload = () => {
    if (downloaded) return;
    var url = getMusic();
    if (url == null) return;
    setDownloaded(true);
    fetch(url, {
      method: 'GET',
    }).then(async response => {
      //const writable = await saveFile();
      const contentLength = response.headers.get('Content-Length');
      const total = parseInt(contentLength, 10);
      let loaded = 0;
      let newProgress;
      const res = new Response(
        new ReadableStream({
          async start(controller) {
            const reader = response.body.getReader();
            for (;;) {
              const { done, value } = await reader.read();
              if (done) break;
              loaded += value.byteLength;
              newProgress = Math.round((loaded / total) * 100);
              if (newProgress % 10 == 0) setProgress(newProgress);
              controller.enqueue(value);
            }
            controller.close();
          },
        })
      );
      res
        .blob()
        .then(async blob => {
          if (false) {
          } else {
            const newUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = newUrl;
            link.setAttribute('download', getTitle());

            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
          setDownloaded(false);
        })
        .finally(() => {
          setDownloaded(false);
          setProgress(0);
        });
    });
  };
  return !downloaded ? (
    <Button
      variant="secondary"
      size="default"
      title="EXPORTAR"
      leftIcon={<MusicIcon></MusicIcon>}
      onClick={handleDownload}
      style={{ width: '14.2rem' }}
    >
      EXPORTAR MÚSICA
    </Button>
  ) : (
    <Button
      variant="secondary"
      size="default"
      title="DESCARGANDO"
      variantLeftIcon="miniIcon"
      leftIcon={<LoadingIndicator variant={'icon'} />}
      style={{ width: '14.2rem' }}
    >
      DESCARGANDO... {progress}%
    </Button>
  );
};
export default DownloadMusic;
