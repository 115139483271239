import { useEffect, createContext, useContext, useReducer, ReactNode } from 'react';
import { INavContext, NavAction, NavState } from './nav.types';

const initialState = { show: true, opened: false, showContactSubmenu: false, showRooms: false };
const NavContext = createContext<INavContext>({ state: initialState, dispatch: () => {} });

const reducer = (state: NavState, action: NavAction) => {
  switch (action.type) {
    case 'nav-open':
      return { ...state, opened: true };
    case 'nav-close':
      return { ...state, opened: false, showContactSubmenu: false };
    case 'nav-toggle':
      return { ...state, opened: !state.opened, showContactSubmenu: false };
    case 'nav-hide':
      return { ...state, show: false, showContactSubmenu: false };
    case 'nav-show':
      return { ...state, show: true };
    case 'nav-contact-submenu-open':
      return { ...state, showContactSubmenu: true };
    case 'nav-contact-submenu-close':
      return { ...state, showContactSubmenu: false };
    case 'nav-contact-submenu-toggle':
      return { ...state, showContactSubmenu: !state.showContactSubmenu };
    case 'nav-show-rooms-open':
      return { ...state, showRooms: true };
    case 'nav-show-rooms-close':
      return { ...state, showRooms: false };
    case 'nav-show-rooms-toggle':
      return { ...state, showRooms: !state.showRooms };
    default:
      // @ts-expect-error
      throw new Error(`Nav.Context action not found: ${action.type}`);
  }
};

export const NavProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <NavContext.Provider value={{ state, dispatch }}>{children}</NavContext.Provider>;
};

export const useNav = () => useContext(NavContext);

export const useHideNavigation = () => {
  const { dispatch } = useContext(NavContext);
  useEffect(() => {
    dispatch({ type: 'nav-hide' });
    return () => {
      dispatch({ type: 'nav-show' });
    };
  }, []);
  return useContext(NavContext);
};
