import { useContext, useEffect } from 'react';
import FiltersContext from '@/contexts/filters.context';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';

export const useQueryFilters = (filterType, official, includes) => {
  const { fetchApi } = useApi();
  const { filters } = useContext(FiltersContext);

  const getFilterOption = () => {
    if (filters && filters.category_nr) {
      return {
        category_nr: filters.category_nr,
        official: official,
      };
    }

    return {
      official: official,
    };
  };

  return useQuery([filterType, getFilterOption()], () =>
    fetchApi([
      [filterType],
      {
        include: includes,
        filter: getFilterOption(),
      },
    ])
  );
};
