import styles from './TagsList.module.scss';
import Tag from './Tag';

export default function TagsList({ tagList = [], onSelect }) {
  return (
    <div className={styles.containerInput}>
      <ul className={styles.containerTags}>
        {tagList.map((element, index) => {
          return (
            <li key={index} onClick={() => onSelect(element)} className={styles.Tag}>
              <Tag
                style={{ pointerEvents: 'none' }}
                key={index}
                variant="w-color"
                colorButton={element?.properties?.color}
              >
                {' '}
                {element.name}
              </Tag>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
