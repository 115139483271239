import { useContext, createContext, useReducer, ReactNode, useEffect } from 'react';

import userModel from '../models/user.model';
import profileModel from '../models/profile.model';
import { AppAction, AppState, IAppContext } from './app.types';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';
import PreferencesProvider, {
  PreferencesContext,
} from '../features/preferences/context/prefs.context';
import * as Sentry from '@sentry/react';

const initialState: AppState = {
  user: null,
  profile: null,
  preferences: null,
  navigation: true,
};

const AppContext = createContext<IAppContext>({ state: initialState, dispatch: () => {} });

const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'user':
      return { ...state, user: userModel(action.payload) };
    case 'profile':
      return { ...state, profile: profileModel(action.payload) };
    default:
      // @ts-ignore-next-line
      throw new Error(`app.context reducer unknown action ${action.type}`);
  }
};

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    refetch: refetchUser,
    ...rest
  } = useQueryUser();
  const {
    data: profileData,
    isError: profileError,
    isLoading: profileLoading,
    refetch: refetchProfile,
  } = useQueryProfile();

  useEffect(() => {
    const onChange = () => {
      refetchUser();
      refetchProfile();
    };

    window.addEventListener('focus', onChange);
    window.addEventListener('online', onChange);

    return () => {
      window.removeEventListener('focus', onChange);
      window.removeEventListener('online', onChange);
    };
  }, []);

  useEffect(() => {
    if (!userLoading && !userError && userData) {
      dispatch({ type: 'user', payload: userData });
      // TODO: a Shell
      window.electronAPI?.setUser({
        id: userData.id,
        email: userData.login,
        sex: userData.sex,
        name: userData.full_name,
        membership: userData.current_membership.plan,
        tags: userData.tag_list,
      });

      Sentry.setUser({
        id: userData?.id,
        email: userData?.login,
      });
    }
  }, [userData, userLoading]);

  useEffect(() => {
    if (!profileLoading && !profileError && profileData) {
      dispatch({ type: 'profile', payload: profileData });
    }
  }, [profileData, profileLoading]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <PreferencesProvider user={state.user} profile={state.profile}>
        {children}
      </PreferencesProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export const useUser = () => {
  const { state } = useAppContext();
  const actions = {}; // TODO: move user actions here
  return { state: state.user!, actions };
};

export const useIsGymUser = () => {
  const { state } = useAppContext();
  return state.user?.current_membership.plan === 'gimnasios';
};

export const useProfile = () => {
  const { state } = useAppContext();
  const actions = {}; // TODO: move profile actions here
  return { state: state.profile, actions };
};
export const usePreferences = () => {
  return useContext(PreferencesContext);
};

export const useZoneColors = () => {
  const { state: preferences } = usePreferences();

  return [preferences.Z1, preferences.Z2, preferences.Z3, preferences.Z4, preferences.Z5];
};

const useQueryUser = () => {
  const { fetchApi } = useApi();

  return useQuery<User>(
    ['user'],
    async () => {
      const userRes = await fetchApi(['user']);
      return userRes.data;
    },
    { cacheTime: 0 },
  );
};

const useQueryProfile = () => {
  const { fetchApi } = useApi();

  return useQuery<Profile>(
    ['profile'],
    async () => {
      const res = await fetchApi([
        'profile',
        {
          include:
            'training_motivations,training_activities,training_materials,nutrition_profile_preferences,profile_genres',
        },
      ]);
      return res.data;
    },
    { cacheTime: 0 },
  );
};
