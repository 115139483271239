import { NavLink } from 'react-router-dom';
import styles from './TopbarNav.module.scss';

export const TopbarItem = ({ to = '', active = false, external, children }) => {
  const opts = {};
  if (to.startsWith('http')) opts.target = '_blank';
  return (
    <li className={`${styles.topbarItem} ${active ? styles.active : ''}`}>
      {external ? (
        <a className={styles.topbarLink} href={to} {...opts}>
          {children}
        </a>
      ) : (
        <NavLink className={styles.topbarLink} to={to} {...opts}>
          {children}
        </NavLink>
      )}
    </li>
  );
};

export default function TopbarNav({ children }) {
  return <ul className={styles.topbarNav}>{children}</ul>;
}
