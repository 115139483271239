import usePageName from '@/hooks/usePageName';
import useDocumentTitle from '@rehooks/document-title';
import LifePoint from '../components/LifePoint/LifePoint';
import Page from '../components/Page';
import ProfileDisplay from '../components/ProfilePage/ProfileDisplay';
import ProfileHeader from '../components/ProfilePage/ProfileHeader';
import ProfileTrainings from '../components/ProfilePage/ProfileTrainings';
import SectionSubtitle from '../components/SectionSubtitle';
import { FooterMin } from '@/components/Footer';

import { useAppContext } from '../contexts/app.context';

import styles from './ProfilePage.module';

export default function ProfilePage() {
  useDocumentTitle('Mi perfil | Bestcycling', false);
  usePageName('profile');

  const {
    state: { user, profile },
  } = useAppContext();

  const twoDigits = value => (value < 10 ? `0${value}` : value);

  const formatDate = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secs = seconds - hours * 3600 - minutes * 60;

    return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(secs)}`;
  };

  const formatDistance = distance => (Math.round(distance / 100) / 10).toFixed(2);

  return (
    <Page footer={<FooterMin />} header={<ProfileHeader user={user} profile={profile} />}>
      <div className={styles.containerProfile}></div>
      <section className={styles.points}>
        <div className={styles.profile}>
          <ProfileDisplay label="Nivel" value={profile.level || 0} />
          <ProfileDisplay label="Constancia" value={profile.perseverance || 0} />
          <ProfileDisplay label="Puntos" value={profile.points || 0} />
        </div>

        <div className={styles.life}>
          <div className={styles.ability}>
            <LifePoint
              style={{ width: '4em', height: '4em' }}
              type="resistance"
              value={profile.resistance_points}
              showOnEmpty={true}
            />
            <label>Resistencia</label>
          </div>
          <div className={styles.ability}>
            <LifePoint
              style={{ width: '4em', height: '4em' }}
              type="force"
              value={profile.force_points}
              showOnEmpty={true}
            />
            <label>Fuerza</label>
          </div>
          <div className={styles.ability}>
            <LifePoint
              style={{ width: '4em', height: '4em' }}
              type="flexibility"
              value={profile.flexibility_points}
              showOnEmpty={true}
            />
            <label>Flexibilidad</label>
          </div>
          <div className={styles.ability}>
            <LifePoint
              style={{ width: '4em', height: '4em' }}
              type="mind"
              value={profile.mind_points}
              showOnEmpty={true}
            />
            <label>Mente</label>
          </div>
        </div>
      </section>
      <section>
        <SectionSubtitle className={styles.profileSubtitle}>Estadísticas</SectionSubtitle>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <main>{profile.trainings_done || 0}</main>
            <footer>
              Entrenamientos
              <br />
              completos
            </footer>
          </div>
          <div className={styles.stat}>
            <main>{profile.days_in_a_row || 0}</main>
            <footer>
              Récord de
              <br />
              días consecutivos
            </footer>
          </div>
          <div className={styles.stat}>
            <main>{formatDate(profile.total_seconds || 0)}</main>
            <footer>
              Tiempo
              <br />
              entrenado
            </footer>
          </div>
          <div className={styles.stat}>
            <main>
              {formatDistance(profile.total_metres || 0)} <span className={styles.units}>km</span>
            </main>
            <footer>
              Distancia
              <br />
              recorrida
            </footer>
          </div>
          <div className={styles.stat}>
            <main>
              {profile.total_kcal || 0} <span className={styles.units}>kcal</span>
            </main>
            <footer>
              Calorias
              <br />
              consumidas
            </footer>
          </div>
        </div>
      </section>
      {profile.nutrition_profile_preferences && (
        <section>
          <SectionSubtitle className={styles.profileSubtitle}>Nutrición</SectionSubtitle>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <main>
                {profile.nutrition_kcal_objective || 0} <span className={styles.units}>kcal</span>
              </main>
              <footer>
                Objetivo calórico (
                <span style={{ whiteSpace: 'nowrap' }}>
                  {profile.nutrition_profile_preferences.objective_name?.toLowerCase()})
                </span>
              </footer>
            </div>
            <div className={styles.stat}>
              <main>
                {profile.nutrition_kcal_basal || 0} <span className={styles.units}>kcal</span>
              </main>
              <footer>Gasto calórico basal</footer>
            </div>
            <div className={styles.stat}>
              <main>
                {profile.nutrition_kcal_base || 0} <span className={styles.units}>kcal</span>
              </main>
              <footer>Gasto calórico diario estimado</footer>
            </div>
          </div>
        </section>
      )}
      <section>
        <SectionSubtitle className={styles.profileSubtitle}>
          Historial de entrenamientos
        </SectionSubtitle>
        <ProfileTrainings />
      </section>
    </Page>
  );
}
