import iconBike from '/src/img/sensei/iconBike.svg';

import iconEliptica from '/src/img/sensei/iconEliptica.svg';

import iconBandas from '/src/img/sensei/iconBandas.svg';

import iconMancuernas from '/src/img/sensei/iconMancuernas.svg';

const Materials = [
  { id: 1, name: 'Bicicleta de ciclismo indoor', image: iconBike },
  { id: 2, name: 'Elítpica', image: iconEliptica },
  { id: 3, name: 'Bandas elásticas', image: iconBandas },
  { id: 4, name: 'Mancuernas', image: iconMancuernas },
];
export default Materials;
