import Category from '@/constants/category';
import { Filter } from './Filter';

export function TrainingCategoryFilter() {
  const trainingTypes = [
    { value: Category.Cycling, label: 'Bestcycling', type: 'category_nr' },
    { value: Category.Walking, label: 'Bestwalking', type: 'category_nr' },
    { value: Category.Balance, label: 'Bestbalance', type: 'category_nr' },
    { value: Category.Running, label: 'Bestrunning', type: 'category_nr' },
    { value: Category.Training, label: 'Besttraining', type: 'category_nr' },
  ];

  return (
    <Filter
      dataCy="training_categories_filter"
      options={trainingTypes}
      type="category_nr"
      tag="TIPO"
    />
  );
}
