import { Duration } from '@/core/constants/nutrition';
import { TrainingClassDuration } from '@/core/features/training_classes/filters';
import { Filter } from './Filter';

interface DurationOption {
  value: string | number;
  label: string;
  type: string;
}
interface DurationOptions {
  [filterType: string]: DurationOption[];
}

const duration: DurationOptions = {
  training: [
    { value: TrainingClassDuration.light, label: "Clases de 30' o menos", type: 'duration' },
    { value: TrainingClassDuration.moderate, label: "Clases de 31' a 39'", type: 'duration' },
    { value: TrainingClassDuration.intense, label: "Clases de 40' a 50'", type: 'duration' },
    {
      value: TrainingClassDuration.extended,
      label: "Clases de más de 50'",
      type: 'duration',
    },
  ],
  nutrition: [
    { value: Duration.SHORT, label: 'Rápidas, menos de 10 minutos', type: 'duration' },
    { value: Duration.MEDIUM, label: 'Medias, entre 10 y 20 minutos', type: 'duration' },
    { value: Duration.LONGTH, label: 'Largas, más de 20 minutos', type: 'duration' },
  ],
};

interface DurationFilterProps {
  filterType: 'training' | 'nutrition';
}

export function DurationFilter({ filterType }: DurationFilterProps) {
  return (
    <Filter
      dataCy="duration_filter"
      options={duration[filterType]}
      type="duration"
      tag="DURACIÓN"
    />
  );
}
