import { AuthState } from '@/contexts/auth.types';
import dayjs from 'dayjs';

const isLoggedIn = (currentSession: any = {}) => {
  const { accessToken, expiresAt } = currentSession;

  if (!accessToken || !expiresAt) return false;

  return dayjs().isBefore(expiresAt);
};

const isExpiredToken = (currentSession: any) => {
  const now = dayjs();
  const { expiresAt } = currentSession;

  return now.isAfter(dayjs(expiresAt));
};

const isFreshToken = (currentSession: any) => {
  const now = dayjs();
  const { expiresAt } = currentSession;
  const diff = dayjs(expiresAt).diff(now, 'days');

  return diff > 10;
};

export const initialState: AuthState = {
  accessToken: '',
  tokenType: '',
  refreshToken: '',
  expiresAt: null,
  isLoggedIn: false,
  isFreshToken: false,
  isExpiredToken: true,
  hydrated: false,
  error: null,
};

export default function sessionModel(session: Auth | AuthState | {} = initialState): AuthState {
  return {
    ...initialState,
    ...session,
    isLoggedIn: isLoggedIn(session),
    isFreshToken: isFreshToken(session),
    isExpiredToken: isExpiredToken(session),
  };
}
