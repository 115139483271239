import FistExperienceStatus from '../constants/FirstExperienceStatus';
import { GraphType } from '@/core/constants/graphFactory';
import Backbone from 'backbone';
import HeartRate from '../classes/HeartRate.js';
import { FormatVelocity } from '../constants/running';
import { VideoQualities } from '@/constants/videoQualities';
const heartRate = new HeartRate();

const SettingsModel = Backbone.Model.extend({
  prefix: 'settings',

  defaults: {
    A1: '1', // Mostrar RPM
    A2: '1', // Mostrar Resistencia
    A3: '1', // Mostrar Tiempo para descanso
    A4: '1', // Mostrar Método actual
    A5: '1', // Mostrar Zona
    A6: '1', // Mostrar Tiempo restante
    A7: '1', // Mostrar Descripción Método
    A8: '0', // Mostrar Display secundario y pausar al inicio
    A9: '0', // Tema gráficas: 0 plano, 1 círculos, 2 clásico, etc...
    B1: '1', // Mostrar Mis clases
    B2: '1', // Mostrar Mezclador de clases
    B3: '1', // Mostrar Planificador
    B4: '1', // Mostrar Entrenador virtual
    B6: '1', // Mostrar Club social
    B7: '1', // Mostrar Calculadoras
    B8: '1', // Mostrar Constructor gráficas
    B9: '1', // Mostrar Organizador a instructores
    updated_to_life: '0',
    first_experience_status: FistExperienceStatus.Idle, // Ocultar primera experiencia
    apps_sensei_enabled: '1',
    C1: '', // Directorio de descarga de clases
    C2: '1', // Mostrar exención responsabilidad
    C3: '0', // Ocultar logo en clases
    C4: '0', // Mostrar lateral club social
    C5: true, // Centrar monitor en pantalla
    C6: '0', // Mostrar formato clásico de vídeos
    C7: 1.0, // Volumen reproductor
    C8: '', // Archivo logo gimnasios
    C9: '1', // Calidad videos al descargar offline
    C10: '0', // Calidad HD al generar mp4,
    C11: true, // Iniciar planificador de gimnasios automáticamente,
    waiting_music_file: '', // Archivo música en espera,
    C13: 50, // Espacio máximo en GB ocupado por descargas
    C14: true,
    play_only_offline_classes: true, // Siempre se trata de reproducir una clase offline desde el planificador
    download_scheduled_training_classes: true,
    auto_redirect_to_gyms: true, // Redirigir automáticamente a la página de gimnasios
    C15: 0, // Offset segundos UTC
    C16: '0', // Aceleración generador MP4
    hide_intro_video: '0', // Mostrar video previo particulares
    videoHD: true,
    C19: null, // Tema colores zona: 0 Personalizado, 1 Tema 1, 2 Tema 2, etc...
    C20: '0', // Ajuste de gráfica vertical
    C21: '1', // Ajuste de gráfica horizontal
    C22: '0', // Mostrar vista monitor
    C23: '0', // Mostrar video de ajuste en planificador
    show_external_setup_video: '0', // Mostrar video de ajuste en pantalla externa
    ask_graph_intro_video: '0', // Mostrar popup de ajuste bicicleta en pantalla externa
    D1: '30', // Edad cardio
    D2: '80', // Basal cardio
    D3: '0', // Updated_at cardio
    D4: '1', // Show six months dialog
    D5: '1', // Mostrar pulsómetro en vista externa
    E1: '0', // Enable progression animations
    E2: '1', // Mostrar resumen de entrenamiento al finalizar
    Z1: '#b9b9c7', // Color Zona 1
    Z2: '#278eb8', // Color Zona 2
    Z3: '#79bb32', // Color Zona 3
    Z4: '#fcbf2d', // Color Zona 4
    Z5: '#f33745', // Color Zona 5
    Z6: '#b53a39', // Color Zona 5
    Z7: '#973332', // Color Zona 5
    gender: 1, // Opciones pulsómetro. Genero. 1 hombre , 2 mujer
    condition: 2, // Opciones pulsómetro. Condicion física.  1 Baja , 2 Media, 3 Alta
    optimal_range: false, // Opciones pulsómetro. Rango optimo de entrenamiento activado
    custom_range: false, // Indíca si ha sido personalizada o se usa el valor calculado
    custom_range_bottom: 0, // rango de pulsaciones mínima en caso de que lo personalize el usuario
    custom_range_top: 0, // rango de pulsaciones máxima en caso de que lo personalize el usuario
    custom_range_bottom_percent: 0, // porcentaje dentro de la gráfica de pulsaciones mínimas en caso de que lo personalize el usuario
    custom_range_top_percent: 0, //  porcentaje dentro de la gráfica de pulsaciones máximas en caso de que lo personalize el usuario
    disable_empty_disk_msg: 0, // Desabilitar mostrar mensaje de disco duro lleno
    graph_type: GraphType.HeartRate,
    running_format_velocity: FormatVelocity['min/km'],
    default_room: 1,
    show_adjust_cycle_help: false,
    never_ask_planner_help: false,
    heart_rate_enabled: false, // TODO: estaba en desktop
    customFtp: null,
    customPPM: null,
    quality_video: VideoQualities.hls,
    annotateResults: true,
    hasShownMusicalPreferencesModal: false,
    constructorBackup: null,
    fcMax: null,
  },

  ranges: {
    fcr: [
      [0.4, 0.75],
      [0.6, 0.85],
      [0.7, 0.9],
    ],
    fcm: [
      [0.5, 0.8],
      [0.7, 0.9],
      [0.75, 0.93],
    ],
  },

  approximationFTP: {
    m: {
      50: [98, 115, 141, 167, 196],
      55: [99, 127, 155, 184, 217],
      60: [108, 139, 170, 201, 237],
      65: [117, 151, 185, 219, 258],
      70: [126, 163, 199, 263, 278],
      75: [136, 175, 214, 253, 299],
      80: [145, 187, 229, 271, 319],
      85: [154, 199, 243, 288, 339],
      90: [164, 211, 258, 305, 360],
      95: [173, 233, 272, 323, 380],
      100: [182, 235, 287, 340, 401],
    },
    f: {
      50: [72, 96, 120, 143, 170],
      55: [80, 105, 132, 158, 188],
      60: [87, 115, 144, 173, 206],
      65: [95, 125, 157, 188, 244],
      70: [102, 135, 169, 203, 241],
      75: [110, 145, 182, 218, 259],
      80: [117, 155, 194, 232, 277],
      85: [125, 165, 207, 247, 295],
      90: [132, 175, 219, 262, 312],
      95: [140, 185, 232, 277, 330],
      100: [147, 195, 244, 292, 348],
    },
  },

  colorThemes: {
    0: [
      // Custom
      '', // Zone 1
      '', // Zone 2
      '', // Zone 3
      '', // Zone 4
      '', // Zone 5
    ] /*    '1': [ // Bestcycling iteración 7
      '#b9b9c7', // Zone 1
      '#278eb8', // Zone 2
      '#79bb32', // Zone 3
      '#fcbf2d', // Zone 4
      '#f33745'  // Zone 5
    ], */,
    1: [
      // Bestcycling iteración 8
      '#1E8FBD', // Zone 1
      '#77BD21', // Zone 2
      '#F9D700', // Zone 3
      '#FB8300', // Zone 4
      '#F6333F', // Zone 5
      '#DE2B3A', // Zone 6
      '#BB2532', // Zone 7
    ],
    2: [
      // Bestcycling 2
      '#B9B9C8', // Zone 1
      '#77BD21', // Zone 2
      '#F9D700', // Zone 3
      '#FB8300', // Zone 4
      '#F6333F', // Zone 5
      '#DE2B3A', // Zone 6
      '#BB2532', // Zone 7
    ],
    3: [
      // Garmin
      '#929292', // Zone 1
      '#1E8FBD', // Zone 2
      '#77BD21', // Zone 3
      '#FB8300', // Zone 4
      '#F6333F', // Zone 5
      '#DB2A39', // Zone 6
      '#B92531', // Zone 7
    ],
    4: [
      // Polar
      '#b9b9c7', // Zone 1
      '#278eb8', // Zone 2. 1E8DBA
      '#79bb32', // Zone 3
      '#fcbf2d', // Zone 4
      '#f33745', // Zone 5
      '#D73441', // Zone 6
      '#B42D37', // Zone 7
    ],
    5: [
      // Bestcycling 4 zonas
      '#7CBE00', // Zone 1
      '#FEDB00', // Zone 2
      '#F78500', // Zone 3
      '#F1373A', // Zone 4
      '#F1373A', // Zone 5
      '#F1373A', // Zone 5
      '#F1373A', // Zone 5
    ],
    6: [
      // All Black
      '#000000', // Zone 1
      '#000000', // Zone 2
      '#000000', // Zone 3
      '#000000', // Zone 4
      '#000000', // Zone 5
      '#000000', // Zone 6
      '#000000', // Zone 7
    ],
    7: [
      // Temperatura
      '#feeb8f', // Zone 1
      '#fedb00', // Zone 2
      '#fb8300', // Zone 3
      '#f35623', // Zone 4
      '#f6333f', // Zone 5
      '#b53a39', // Zone 6
      '#973332', // Zone 7
    ],
    8: [
      // Bestcycling 4 zonas mejorado
      '#7CBE00', // Zone 1
      '#FEDB00', // Zone 2
      '#F78500', // Zone 3
      '#F1373A', // Zone 4
      '#CC2527', // Zone 5
      '#CC2527', // Zone 6
      '#CC2527', // Zone 7
    ],
  },

  initialize: function (user, profile) {
    this.user = user;
    this.profile = profile;
  },

  setup: function () {
    const ftp = this.profile?.ftp;
    const basal = this.profile?.fcb;
    const fcMax = this.profile?.fcm;

    this.set('ftp', ftp);
    this.set('D2', basal);
    this.set('fcMax', fcMax);

    const mode = basal && fcMax ? 'fcr' : 'fcm';
    this.set('mode', mode);
  },

  loadLocal: function () {
    if (localStorage.getItem(this.prefix) !== null) {
      const data = JSON.parse(localStorage.getItem(this.prefix));
      Object.entries(data).forEach(([key, value]) => this.set(key, value));
    }
    this.checkDefaultValues();
  },

  saveLocal: function () {
    localStorage.setItem(this.prefix, JSON.stringify(this));
  },

  // deprecated? commit: eaf09e03781365fda694700b69d648f613dbe255
  setCurrentThemeColor: function (index) {
    this.set('C19', index);
    this.set('Z1', this.colorThemes[index][0]);
    this.set('Z2', this.colorThemes[index][1]);
    this.set('Z3', this.colorThemes[index][2]);
    this.set('Z4', this.colorThemes[index][3]);
    this.set('Z5', this.colorThemes[index][4]);
    this.set('Z6', this.colorThemes[index][5]);
    this.set('Z7', this.colorThemes[index][6]);

    this.saveLocal();
  },

  checkDefaultValues: function () {
    if (!this.get('C19')) {
      let theme = '1';

      if (this.user && this.user.getTypeString && this.user.getTypeString() == 'gimnasio') {
        theme = '4';
      }

      this.setCurrentThemeColor(theme);
    }
  },

  getFcMax: function () {
    if (!this.user) {
      return null;
    }

    const fcMax = this.get('fcMax');

    if (fcMax) return parseInt(fcMax, 10);

    const gender = this.user.sex;
    const age = this.user.getAge();

    if (!gender || !age) return null;

    return gender === 'm' ? Math.floor(209 - 0.7 * age) : Math.floor(214 - 0.8 * age);
  },

  /**
   * Obtiene los valores correspondientes a las pulsaciones a cada una de las zonas
   * @return {number[][]} Array de arrays con los valores de pulsaciones min y max de cada zona
   */
  getPPM: function () {
    const customPPM = this.get('customPPM');

    if (customPPM) {
      return customPPM;
    }

    const basal = parseInt(this.getBasal());
    const fcMax = parseInt(this.get('fcMax')) || this.getFcMax();
    const mode = basal && fcMax ? 'fcr' : 'fcm';

    const fcr = function (intensidad) {
      const fcReserve = fcMax - basal;
      const _fcr = basal + (fcReserve * intensidad) / 100;
      return Math.round(_fcr, 0);
    };

    const fcm = function (intensidad) {
      const _fcm = (fcMax * intensidad) / 100;
      return Math.round(_fcm, 0);
    };

    const ppm = [
      ['???', '???'],
      ['???', '???'],
      ['???', '???'],
      ['???', '???'],
      ['???', '???'],
    ];

    if (mode == 'fcr') {
      ppm[4] = [fcr(90), fcr(100)];
      ppm[3] = [fcr(80), fcr(90)];
      ppm[2] = [fcr(70), fcr(80)];
      ppm[1] = [fcr(60), fcr(70)];
      ppm[0] = [fcr(50), fcr(60)];
    } else if (fcMax) {
      ppm[4] = [fcm(90), fcm(100)];
      ppm[3] = [fcm(80), fcm(90)];
      ppm[2] = [fcm(70), fcm(80)];
      ppm[1] = [fcm(60), fcm(70)];
      ppm[0] = [fcm(50), fcm(60)];
    }

    return ppm;
  },

  getWattZones: function () {
    const Ftms = {
      approximationFTP: this.approximationFTP,

      calculatePredeterminatedFtp: (weight, fitStatus, gender) => {
        let parsedWeight = 75;

        if (weight != null) {
          parsedWeight = Math.ceil(weight);
          if (parsedWeight < 50) {
            parsedWeight = 50;
          } else if (parsedWeight > 100) {
            parsedWeight = 100;
          }
        }

        let fitStatusIndex = fitStatus != null ? fitStatus - 1 : 0;
        let weightString = parsedWeight.toString();
        let lastNumberPosition = weightString.length - 1;
        let rest = Number(weightString[lastNumberPosition]);

        if (rest == 0) {
          return Ftms.approximationFTP[gender][parsedWeight][fitStatusIndex];
        }

        let denominator = rest > 5 ? 10 : 5;
        let weightIndex = parsedWeight + (denominator - rest);

        return Ftms.approximationFTP[gender][weightIndex][fitStatusIndex];
      },
    };

    const getValueWatts = (ftp, percent) => Math.floor(ftp * percent);

    const nullValues = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ];

    const weight = this.profile?.weight;
    const fitStatus = this.profile?.fit_status;
    const gender = this.user?.sex;
    let customFtp = this.profile?.ftp;

    if (!customFtp && weight && fitStatus && gender) {
      customFtp = Ftms.calculatePredeterminatedFtp(weight, fitStatus, gender);
    }

    if (customFtp) {
      return [
        [getValueWatts(customFtp, 1.51), getValueWatts(customFtp, 2.0)],
        [getValueWatts(customFtp, 1.21), getValueWatts(customFtp, 1.5)],
        [getValueWatts(customFtp, 1.06), getValueWatts(customFtp, 1.2)],
        [getValueWatts(customFtp, 0.91), getValueWatts(customFtp, 1.05)],
        [getValueWatts(customFtp, 0.76), getValueWatts(customFtp, 0.9)],
        [getValueWatts(customFtp, 0.56), getValueWatts(customFtp, 0.75)],
        [0, getValueWatts(customFtp, 0.55)],
      ];
    }

    return nullValues;
  },

  /**
   * Devuelve los porcentages del rango óptimo de entrenamiento basado en
   * la condición seleccionada.
   * @return {number[]} Array con porcentaje mínimo y máximo.
   */
  getConditionRangePercentages: function (conditionValue) {
    const condition = Math.max(1, Math.min(3, parseInt(conditionValue || 1, 10)));
    const mode = this.get('mode');

    return this.ranges[mode][condition - 1];
  },

  /**
   * Returns custom range if defined or optimal range (false)
   *
   * @returns {boolean}
   */
  optimalRange: function () {
    return this.optimal_range;
  },

  /**
   * Devuelve las pulsaciones del rango mínimo óptimo de entrenamiento.
   * @return {number}
   */
  optimalMin: function (condition) {
    if (this.get('custom_range_bottom')) {
      return this.get('custom_range_bottom');
    }

    const basal = parseInt(this.getBasal() ?? 0);
    const fcMax = this.getFcMax();

    return Math.floor(basal + (fcMax - basal) * this.getConditionRangePercentages(condition)[0]);
  },

  /**
   * Devuelve las pulsaciones del rango superior óptimo de entrenamiento.
   * @return {number}
   */
  optimalMax: function (condition) {
    if (this.get('custom_range_top')) {
      return this.get('custom_range_top');
    }

    const basal = parseInt(this.getBasal() ?? 0);
    const fcMax = this.getFcMax();

    return Math.floor(basal + (fcMax - basal) * this.getConditionRangePercentages(condition)[1]);
  },

  /**
   * Devuelve el porcentaje de las pulsaciones mínimas del rango de entrenamiento óptimo . Sino está personalizado se toman valores fijos
   * @return {number}
   */
  optimalMinPercent: function (conditionValue) {
    const ppm = this.getPPM();
    const optimalMin = this.optimalMin(conditionValue);

    return this.getPercentPPMValue(ppm, optimalMin);
  },

  /**
   * Devuelve el porcentaje de las pulsaciones máximo del rango de entrenamiento óptimo . Sino esta personalizado se toman valores fijos
   * @return {number}
   */
  optimalMaxPercent: function (conditionValue) {
    const ppm = this.getPPM();
    const optimalMax = this.optimalMax(conditionValue);

    return this.getPercentPPMValue(ppm, optimalMax);
  },

  /**
   * actualizamos los valores custom_range_top_percent y custom_range_bottom_percent en función a los ppm y a el rango definido
   * @pre {number} custom_range_bottom. Valor de la linea de inicio del rango de entrenamiento tiene que estar definida anteriormente
   * @pre {number} custom_range_top. Valor de la linea final del rango de entrenamiento tiene que estar definida anteriormente
   * @pre {number} ppm. Valor de las pulsaciones en cada zona tiene que estar definida anteriorment
   * return {boolean}. Si todo está correcto true, en caso de falta de datos false.
   */
  updateCustomRange: function () {
    const ppm = this.getPPM();
    const bottom = this.get('custom_range_bottom');
    const top = this.get('custom_range_top');

    if (bottom && top && ppm && parseInt(ppm[0][1])) {
      var bottomPercent = this.getPercentPPMValue(ppm, bottom);
      var topPercent = this.getPercentPPMValue(ppm, top);
      this.set('custom_range_top_percent', topPercent);
      this.set('custom_range_bottom_percent', bottomPercent);
      this.saveLocal();

      return true;
    }

    this.set('custom_range_bottom_percent', null);
    this.set('custom_range_top_percent', null);

    return false;
  },

  /**
   * Funcion que calcula el porcentaje que le corresponde a un valor dentro de su zona , teniendo en cuenta las pulsaciones
   * @param {number [][]} arrayPPM, array de array con las pulsaciones máximas y mínimas de cada zona
   * @param {number} value, valor de la pulsación a calcular
   * @return {number} ,valor en porcentaje de la zona donde correponde value
   */
  getPercentPPMValue: function (arrayPPM, value) {
    if (value < arrayPPM[0][0]) {
      return 0;
    }

    if (value > arrayPPM[4][1]) {
      return 100;
    }

    let percent = 0;

    arrayPPM.forEach((zoneValues, zone) => {
      if (zoneValues[0] <= value && zoneValues[1] >= value) {
        const relativePercent = (value - zoneValues[0]) / (zoneValues[1] - zoneValues[0]);
        percent = (zone + relativePercent) * 0.2 * 100;
      }
    });

    return percent;
  },

  isAppEnabled: function (app) {
    switch (app) {
      case 'my-classes':
        return this.get('B1') == 1;
      case 'express':
        return this.get('B2') == 1;
      case 'planner':
        return this.get('B3') == 1;
      case 'virtual-trainer':
        return this.get('B4') == 1;
      case 'calculators':
        return this.get('B7') == 1;
      case 'constructor':
        return this.get('B8') == 1;
      case 'organizer':
        return this.get('B9') == 1;
      case 'sensei':
        return this.get('apps_sensei_enabled') == 1;
      default:
        return false;
    }
  },

  /* El monitor está centrado en la pantalla */
  isSetCenterVideoMode: function () {
    if (this.get('C5') === 0) {
      this.set('C5', true);
    }
    return this.get('C5') == true;
  },

  isSetResistanceDisplay: function () {
    return true; // Options disabled
  },

  isSetTimeToRestDisplay: function () {
    return true; // Options disabled
  },

  isSetRpmDisplay: function () {
    return true; // Options disabled
    // return this.get('A1') == '1' ? true : false;
  },

  isSetNextTimeDisplay: function () {
    return true; // Options disabled
  },

  isSetZoneDisplay: function () {
    return true; // Options disabled
  },

  isSetCurrentMethodDisplay: function () {
    return true; // Options disabled
  },

  isSetDescriptionMethodDisplay: function () {
    return true; // Options disabled
  },

  isSetThemeClasic: function () {
    return false;
  },

  isSetThemeCircle: function () {
    return false;
  },

  isSetThemePlain: function () {
    return true;
  },

  isSetShowResponsabilityMsg: function () {
    return this.get('C2') == '1';
  },

  isSetMonitorView: function () {
    return this.get('C22') == '1';
  },

  isSetVerticalZoom: function () {
    return this.get('C20') == '1';
  },

  isSetHorizontalZoom: function () {
    return this.get('C21') == '1';
  },

  isSetHideLogo: function () {
    return this.get('C3') == '1';
  },

  isSetShowLogo: function () {
    return !this.isSetHideLogo();
  },

  isSetShowResume: function () {
    return this.get('E2') == '1';
  },

  isSetPauseOnExternal: function () {
    return this.get('A8') == '1';
  },

  isSetAnimation: function () {
    return this.get('E1') == '1';
  },

  isSetExternalHeartRate: function () {
    return this.get('D5') == '1';
  },

  isSetConfigBluetooth: function () {
    return this.get('D3') != '0';
  },

  isSetDownloadSchedule: function () {
    return this.get('download_scheduled_training_classes') == true;
  },

  isSetPlayOffline: function () {
    return this.get('play_only_offline_classes') || this.get('C14') == 1;
  },

  isSetHDMp4: function () {
    return this.get('C10') == '1';
  },

  /** Comprobamos si es negra la franja de abajo de la gráfica */
  isBlackZoneGraph: function () {
    return this.get('Z5') == '#000000';
  },

  getZonesArray: function () {
    return this.colorThemes[this.get('C19')];
  },

  getPlayerVolume: function () {
    return this.get('C7');
  },

  getPathLogo: function () {
    return this.get('C8');
  },

  getCacheDir: function () {
    return this.get('C1');
  },

  getBasal: function () {
    return this.get('D2');
  },

  getLastCardioConfig: function () {
    return this.get('D3');
  },

  getMaxDiskSpace: function () {
    return this.get('C13');
  },

  getPreferredGraphType: function () {
    return this.get('graph_type') == GraphType.Watts ? 'watt' : 'fc';
  },

  getConstructorBackup: function () {
    return this.get('constructorBackup');
  },

  setCenterVideoMode: function (value) {
    this.set('C5', value);
  },

  setVerticalZoom: function (value) {
    this.set('C20', value);
  },

  setHorizontalZoom: function (value) {
    this.set('C21', value);
  },

  setPlayerVolume: function (value) {
    this.set('C7', value);
  },

  setPathLogo: function (value) {
    this.set('C8', value);
  },

  setMonitorView: function (value) {
    this.set('C22', value);
  },

  setLastCardioConfig: function () {
    this.set('D3', Date.now());
  },

  setBasal: function (value) {
    this.set('D2', value);
    this.saveLocal();
  },

  setFcMax: function (value) {
    this.set('fcMax', value);
    this.saveLocal();
  },

  setDownloadSchedule: function (value) {
    this.set('download_scheduled_training_classes', value);
  },

  setPlayOffline: function (value) {
    this.set('play_only_offline_classes', value);
    this.set('C14', value ? '1' : '0');
  },

  setMaxSizeOffline: function (value) {
    this.set('C13', parseInt(value, 10));
  },

  sethasShownMusicalPreferencesModal: function (value) {
    this.set('hasShownMusicalPreferencesModal', value);
  },

  setConstructorBackup: function (backup) {
    this.set('constructorBackup', backup);
  },

  getPlayerOptions: function () {
    return {
      audio: this.getPlayerAudioOptions(),
      video: this.getPlayerVideoOptions(),
      graph: this.getPlayerGraphOptions(),
      cardio: this.getPlayerCardioOptions(),
      ftms: this.getPlayerFtmsOptions(),
      range: this.getPlayerRangeOptions(),
      running: this.getPlayerRunningOptions(),
    };
  },

  getPlayerAudioOptions: function () {
    return {
      showinfo_audio_remaining: true,
      showinfo_audio_logo: this.isSetShowLogo(),
      showinfo_audio_effects: false,
      show_external_videos: false,
      auto_play_external: false,
      external_display: false,
      vista_monitor: this.isSetMonitorView(),
      resume_view: this.isSetShowResume(),
      show_end_of_interval: true,
    };
  },

  getPlayerVideoOptions: function () {
    return {
      video_theme: 'theme-bestangular',
      showmonitor_centered: this.isSetCenterVideoMode(),
      show_intro_video: this.get('show_external_setup_video') == '1',
      show_planner_intro_video: false,
      showinfo_rpm: this.isSetRpmDisplay(),
      showinfo_resistance: this.isSetResistanceDisplay(),
      showinfo_timeto: this.isSetTimeToRestDisplay(),
      showinfo_zone: this.isSetZoneDisplay(),
      showinfo_method_description: this.isSetDescriptionMethodDisplay(),
      showinfo_method: this.isSetCurrentMethodDisplay(),
      resume_view: false,
      showinfo_logo: this.isSetShowLogo(),
      show_end_of_interval: false,
    };
  },

  getPlayerGraphOptions: function () {
    const zonesArray = this.getZonesArray();

    return {
      zone_color_5: zonesArray[4],
      zone_color_4: zonesArray[3],
      zone_color_3: zonesArray[2],
      zone_color_2: zonesArray[1],
      zone_color_1: zonesArray[0],
      zoom_graph: this.isSetVerticalZoom(),
      preferred_graph_type: this.getPreferredGraphType(),
      heart_rate_box_height_percentage: 0.3,
    };
  },

  getPlayerCardioOptions: function () {
    return {
      mode: this.get('mode'),
      enabled: !!this.get('heart_rate_enabled'),
      external: true,
      fcm: this.getFcMax(),
      fcb: this.getBasal(),
      fcr: 0,
      customPPM: this.get('customPPM'),
    };
  },

  getPlayerFtmsOptions: function () {
    return {
      enabled: false,
      ftp: null,
      features: {
        RESISTENCE_LEVEL_RANGE: false,
        'Nivel de resistencia': false,
        Cadencia: false,
        Potencia: false,
        'Velocidad media': false,
        'Potencia objetivo': false,
      },
    };
  },

  getPlayerRangeOptions: function () {
    const show_range = this.get('optimal_range');
    const fit_status = this.profile?.fit_status;
    const ppm = this.getPPM();

    const percents = [
      this.getPercentPPMValue(ppm, this.optimalMin(fit_status)),
      this.getPercentPPMValue(ppm, this.optimalMax(fit_status)),
    ];

    return {
      show_range,
      percents,
      condition: fit_status,

      // not required
      custom_range: false,
      custom_range_bottom: 45,
      custom_range_top: 72,
      custom_range_bottom_percent: 0.5,
      custom_range_top_percent: 0.8,
    };
  },

  getPlayerRunningOptions: function () {
    return {
      showVelocity: false,
      show_velocity: this.get('running_format_velocity') == FormatVelocity['km/h'],
      show_graph: true,
    };
  },

  getThemesColor: function () {
    return this.colorThemes;
  },

  heartRate: function () {
    return heartRate;
  },

  getLogoGyms: function () {
    return this.get('C8');
  },

  getDefaultRoom: function () {
    return this.get('default_room');
  },

  getCurrentThemeColor: function () {
    return this.get('C19');
  },

  isHearst: function () {
    return false;
  },

  hasCustomZonesSettings: function () {
    return !!(
      this.get('customPPM') ||
      (this.get('optimal_range') &&
        (this.get('custom_range_bottom') || this.get('custom_range_top')))
    );
  },

  getLogoForCategory: function (category_nr) {
    /*
    if (!this.isHearst()) {
      return "";
    }

    if (category_nr == Category.Running) {
      return require('../../../img/home/logo-runnersworld--blanco.svg');
    } else if (category_nr == Category.Training && this.user.get('sex') == 'm') {
      return require('../../../img/home/logo-menshealth--blanco.svg');
    } else if (category_nr == Category.Training && this.user.get('sex') == 'f') {
      return require('../../../img/home/logo-womenshealth--blanco.svg');
    }
    return;
    */
  },
});

export default SettingsModel;
