import TrainingCategoryCard from './TrainingCategoryCard';

import { defaultCategories, Types } from '../../constants/category';
import Slider from '@/components/Slider';

export default function TrainingCategoriesCarousel({ categories }) {
  const trainingCategories = categories || defaultCategories;

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Slider dataCy="training_categories_slider">
        {trainingCategories
          .filter(id => defaultCategories.includes(id))
          .map((id, i) => (
            <TrainingCategoryCard key={i} type={Types[id]} categoryNr={id} />
          ))}
      </Slider>
    </div>
  );
}
