import {
  LocalGraphTheme,
  LocalGraphType,
  LocalPPM,
  LocalSyncablePreferences,
  LocalSpeedDisplay,
  RemoteGraphTheme,
  RemoteGraphType,
  RemoteCustomRange,
  RemotePPM,
  RemoteSyncablePreferences,
  RemoteSpeedDisplay,
  LocalCustomRangeBottom,
  RemoteCustomRangeBottom,
  RemoteCustomRangeTop,
  LocalCustomRangeTop,
  LocalOptimalRange,
} from '@/core/features/preferences/types/preferences';
import { RemoteGraphThemes } from '../constants/graph_themes';

const GraphMode = {
  toRemote: (data: LocalGraphType): RemoteGraphType => (data === 'HeartRate' ? 'hr' : 'watts'),
  toLocal: (data: RemoteGraphType): LocalGraphType => (data === 'hr' ? 'HeartRate' : 'Watts'),
};

const GraphTheme = {
  toRemote: (data: LocalGraphTheme): RemoteGraphTheme => {
    const index = parseInt(data.toString());
    return RemoteGraphThemes[index - 1] || RemoteGraphThemes[0];
  },
  toLocal: (data: RemoteGraphTheme): LocalGraphTheme => {
    const index = RemoteGraphThemes.indexOf(data);
    return index === -1 ? 1 : index + 1;
  },
};

const SpeedDisplay = {
  toRemote: (data: LocalSpeedDisplay): RemoteSpeedDisplay => data,
  toLocal: (data: RemoteSpeedDisplay): LocalSpeedDisplay => data,
};

const PPM = {
  toRemote: (data: LocalPPM): RemotePPM => {
    if (!data) return null;

    return Object.fromEntries(
      data.map((value, index) => [
        index.toString(),
        {
          min: value[0],
          max: value[1],
        },
      ]),
    );
  },
  toLocal: (data: RemotePPM): LocalPPM => {
    if (!data) return null;

    return Object.entries(data)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(([_, { min, max }]) => [min, max]);
  },
};

const OptimalRange = {
  toRemote: (data: LocalOptimalRange): RemoteCustomRange => data,
  toLocal: (data: RemoteCustomRange): LocalOptimalRange => data,
};

const OptimalMin = {
  toRemote: (data: LocalCustomRangeBottom): RemoteCustomRangeBottom => data,
  toLocal: (data: RemoteCustomRangeBottom): LocalCustomRangeBottom => data,
};

const OptimalMax = {
  toRemote: (data: LocalCustomRangeTop): RemoteCustomRangeTop => data,
  toLocal: (data: RemoteCustomRangeTop): LocalCustomRangeTop => data,
};

const PreferencesAdapter = {
  toRemote: (data: LocalSyncablePreferences): RemoteSyncablePreferences => {
    const preferences: RemoteSyncablePreferences = {};

    if (data.graph_type) {
      preferences['graph_mode'] = GraphMode.toRemote(data.graph_type);
    }

    if (data.C19) {
      preferences['hr_graph_theme'] = GraphTheme.toRemote(data.C19);
    }

    if (data.running_format_velocity) {
      preferences['running_pace_units'] = SpeedDisplay.toRemote(data.running_format_velocity);
    }

    if (data.customPPM !== undefined) {
      preferences['hr_zones'] = PPM.toRemote(data.customPPM);
    }

    if (data.optimal_range !== undefined) {
      preferences['hr_optim_range_visible'] = OptimalRange.toRemote(data.optimal_range!);
    }

    if (data.custom_range_bottom !== undefined) {
      preferences['hr_optim_range_min'] = OptimalMin.toRemote(data.custom_range_bottom);
    }

    if (data.custom_range_top !== undefined) {
      preferences['hr_optim_range_max'] = OptimalMax.toRemote(data.custom_range_top);
    }

    return preferences;
  },
  toLocal: (data: RemoteSyncablePreferences): LocalSyncablePreferences => {
    const preferences: LocalSyncablePreferences = {};

    if (data.graph_mode) {
      preferences['graph_type'] = GraphMode.toLocal(data.graph_mode);
    }

    if (data.hr_graph_theme) {
      preferences['C19'] = GraphTheme.toLocal(data.hr_graph_theme);
    }

    if (data.running_pace_units) {
      preferences['running_format_velocity'] = SpeedDisplay.toLocal(data.running_pace_units);
    }

    if (data.hr_zones !== undefined) {
      preferences['customPPM'] = PPM.toLocal(data.hr_zones);
    }

    if (data.hr_optim_range_visible !== undefined) {
      preferences['optimal_range'] = OptimalRange.toLocal(data.hr_optim_range_visible);
    }

    if (data.hr_optim_range_min !== undefined) {
      preferences['custom_range_bottom'] = OptimalMin.toLocal(data.hr_optim_range_min);
    }

    if (data.hr_optim_range_max !== undefined) {
      preferences['custom_range_top'] = OptimalMax.toLocal(data.hr_optim_range_max);
    }

    return preferences;
  },
};

export default PreferencesAdapter;
