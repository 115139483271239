import MyCollectionsCarousel from '../MyCollections/MyCollectionsCarousel';
import SectionTitle from '../SectionTitle';
import useFetchUserTags from '../../hooks/apiHooks/useFetchUserTags';
import LoadingIndicator from '../LoadingIndicator';

export default function MyCollectionsSection({ title = 'Mis colecciones' }) {
  const { data, isLoading } = useFetchUserTags();

  if (isLoading) return <LoadingIndicator />;
  if (!data?.length) return;

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <MyCollectionsCarousel data={data} />
    </>
  );
}
