import { RemoteGraphTheme } from "../types/preferences";

export const RemoteGraphThemes: RemoteGraphTheme[] = [
  "bestcycling",
  "bestcycling_2",
  "garmin",
  "polar",
  "bestcycling_4_zones",
  "4_zones_improved",
  "temperature",
  "all_black",
];
