import { useState } from 'react';
import styles from './ClassImage.module.scss';

interface IClassImage {
  image: string,
  title: string
}

const ClassImage = ({ image, title }: IClassImage) =>  {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={`${styles.image} ${loaded ? styles.loaded : ''}`}>
      <img src={image} alt={title} onLoad={setLoaded.bind(null, true)} />
    </div>
  );
}

export default ClassImage;