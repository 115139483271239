import { getAnalytics, logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

const PAGE_NAMES = {
  favourites: 'favorite',
  category: 'search',
};

export default function usePageName(pageName, ...params) {
  const analytics = getAnalytics();

  useEffect(() => {
    const isEnabled = process.env.NODE_ENV == 'production';

    const normalizedPageName = PAGE_NAMES[pageName] || pageName;
    const eventName = ['view', normalizedPageName, ...params].filter(i => i).join('_');

    console.info('Firebase event:', eventName);

    if (isEnabled) {
      logEvent(analytics, eventName);
    }
  }, [pageName]);
}
