import clsx from 'clsx';
import styles from './Modal.module.scss';

type ModalHeaderProps = {
  className?: string;
  children: React.ReactNode;
  center?: boolean;
};
export default function ModalBody({ className, children, center = true }: ModalHeaderProps) {
  return (
    <main
      className={clsx([styles.modalBody, className])}
      style={{ textAlign: center ? 'center' : 'inherit' }}
    >
      {children}
    </main>
  );
}
