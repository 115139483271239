import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { HRAction, HRState, IHRContext } from './heartRate.types';
import { useToast } from './toast.context';
import EventEmitter from 'events';
import useSetting from '@/components/Preferences/useSetting';

export class HeartRateEmitter extends EventEmitter {
  constructor() {
    super();
  }

  emitHeartRate(heartRate: number) {
    this.emit('characteristicvaluechanged', heartRate);
  }
}

const initialState: HRState = {
  connected: false,
  heartRate: 0,
  heartRateEmitter: new HeartRateEmitter(),
  device: null,
  server: null,
};

const reducer = (state: HRState, action: HRAction): HRState => {
  switch (action.type) {
    case 'connect_device':
      return {
        ...state,
        connected: true,
        ...action.payload,
      };
    case 'disconnect_device':
      return {
        ...state,
        connected: false,
        device: null,
        server: null,
      };
    case 'update_heart_rate':
      return {
        ...state,
        ...action.payload,
      };
    case 'fake_device':
      return {
        ...state,
        connected: true,
      };
    default:
      return state;
  }
};

const HRContext = createContext<IHRContext>({
  state: initialState,
  // dispatch: () => {},
  actions: {
    connectDevice: () => {},
    disconnectDevice: () => {},
  },
});

export const HRProvider = ({ children }: { children: React.ReactNode }) => {
  const [_, setHeartRateEnabled] = useSetting('heart_rate_enabled'); // Player logic will look in to this setting to change the display of the playerView
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast } = useToast();

  const connectDevice = async (device: BluetoothDevice) => {
    console.log('Connecting to device 💙', device);
    const server = await device.gatt?.connect();
    if (!server) {
      showToast('Lo sentimos, este dispositivo no es compatible con la aplicación', 'error');
      return;
    }

    dispatch({ type: 'connect_device', payload: { device, server } });
    setHeartRateEnabled(true);
    showToast('Dispositivo conectado correctamente', 'success');
    // TODO: Se puede dejar más bonito, pero hará lo mismo
    server.getPrimaryService('heart_rate').then(service => {
      service.getCharacteristic('heart_rate_measurement').then(characteristic => {
        characteristic.startNotifications().then(() => {
          characteristic.addEventListener('characteristicvaluechanged', (event: any) => {
            const value = event.target.value;
            const flags = value.getUint8(0);
            // eslint-disable-next-line no-bitwise
            const rate16Bits = flags & 0x1;
            let heartRate;
            if (rate16Bits) {
              heartRate = value.getUint16(1, /* littleEndian= */ true);
            } else {
              heartRate = value.getUint8(1);
            }
            state.heartRateEmitter.emitHeartRate(heartRate);
            dispatch({ type: 'update_heart_rate', payload: { heartRate } });
          });
        });
      });
    });
  };

  const reconnectDevice = useCallback(async () => {
    if (!state.server?.disconnect) {
      const server = await state.server?.connect();
      if (!server) return;

      dispatch({ type: 'reconnect_device', payload: { server } });
    }
  }, [state.server]);

  const disconnectDevice = useCallback(() => {
    setHeartRateEnabled(false);
    if (state.connected) {
      state.server?.disconnect();
      state.device?.gatt?.disconnect();
      dispatch({ type: 'disconnect_device' });
    }
  }, [state.connected, state.device, state.server]);

  useEffect(() => {
    if (!state.server?.connected) {
      disconnectDevice();
    }
  }, [state.server]);

  return (
    <HRContext.Provider
      value={{
        state,
        actions: {
          connectDevice,
          disconnectDevice,
        },
      }}
    >
      {children}
    </HRContext.Provider>
  );
};

export const useHR = () => useContext(HRContext);
