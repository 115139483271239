import { useSearchParams } from 'react-router-dom';

interface Filters {
  [key: string]: string;
}

const useParamsFilters = (defaultFilters: Filters) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  const updateParams = (filters: Filters) => {
    let search = {};

    Object.keys(defaultFilters).forEach(filter => {
      const value = filters[filter];

      if (value) {
        search = { ...search, [filter]: value };
      }
    });

    setSearchParams(search, { replace: true });
  };

  return {
    defaultFilters: {
      ...defaultFilters,
      ...params,
    },
    updateParams,
  };
};

export default useParamsFilters;
