import { useQuery } from '@tanstack/react-query';
import styles from './TrainingExercices.module.scss';
import VisualTrainings from './VisualTrainings';
import { useApi } from '@/core/api/ApiProvider';
import LoadingIndicator from '../LoadingIndicator';
import { useMemo } from 'react';
import ToggeableContent from '../TrainingClassDetails/ToggeableContent';
import { getExercisesOrderedByRounds } from '../Trainings/utils';

type TrainingExercisesProps = {
  trainingClass: TrainingClass;
  rounds: ProgressionBlock[] | null | undefined;
};

const TrainingExercises = (props: TrainingExercisesProps) => {
  const { fetchApi } = useApi();

  const filters = { training_class_id: props.trainingClass.id };

  const { data, isLoading, isSuccess, isError } = useQuery<TrainingClassExercise[]>(
    ['training_class_exercises', filters],
    async () => {
      const tc = await fetchApi([
        'training_class_exercises',
        {
          filters,
          include: ['training_exercises_media_asset'],
        },
      ]);
      return tc.data;
    },
  );

  const fixedData = useMemo(() => {
    const fixedArray = (data || []).filter(exercise => {
      return (
        exercise !== null &&
        !!exercise.image &&
        !!exercise.method &&
        !!exercise.name &&
        !!exercise.media_assets?.length
      );
    });

    return !fixedArray.length
      ? []
      : props.rounds
        ? getExercisesOrderedByRounds(fixedArray, props.rounds)
        : [];
  }, [data, props.rounds]);

  if (!fixedData.length) return null;

  return (
    <ToggeableContent header="Ejercicios" canToggle show>
      <div className={styles.container}>
        <div className={styles.visualTrainings}>
          {isLoading && <LoadingIndicator />}
          {isError && <div>Ha ocurrido un error</div>}
          {isSuccess && <VisualTrainings exercises={fixedData} />}
        </div>
      </div>
    </ToggeableContent>
  );
};

export default TrainingExercises;
