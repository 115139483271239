import { useAuth } from '@/core/contexts/auth.context';
import FacebookLogin, { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import styles from './AuthProviders.module.scss';
import facebookIcon from '@/img/facebook-icon.svg'; // Ajusta la ruta según la ubicación de tu archivo SVG

const CLIENT_ID = config?.FACEBOOK_CLIENT_ID;
type LoginWithFacebookProps = {
  handleError: React.Dispatch<React.SetStateAction<string>>; // Tipo de la función de setError
};
const LoginWithFacebook = ({handleError}: LoginWithFacebookProps) => {
  const {  loginProvider } = useAuth();

  const handleSuccess = (response: ReactFacebookLoginInfo) => {
    const token = response.accessToken;
    const clientId = response.userID;
    if (token) {


      console.log(' Facebook client',clientId, 'Google Token:', token);

      loginProvider('facebook', {'clientId':clientId , 'token':token});
      // Aquí puedes enviar el token al servidor
     // sendTokenToServer('google', token);
    }
  };

  const handleFailure = (error: ReactFacebookFailureResponse) => {
    if(error.status == 'unknown'){
      handleError("Acción cancelada por el usuario");
    }else{
      handleError("Error al realizar la autenticación con Facebook");
    }
    console.error('Login Failed:', error);
  };

  return (

    <div className={`${styles.buttonContainer} button_web button_web_extra` }>
   <FacebookLogin
    cssClass={ `${styles.socialButton} ${styles.btnFacebook}`}
    icon={<img src={facebookIcon} alt="Facebook" className={styles.icon} />} // Usa el SVG aquí
    appId={CLIENT_ID}  // we need to get this from facebook developer console by setting the app.
      autoLoad={false}
      textButton={"Continuar con Facebook"}
      fields="name,email,picture"
      callback={handleSuccess}
      onFailure={handleFailure}/>

    </div>
  );
};

export default LoginWithFacebook;