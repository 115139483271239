import { useShell } from '@/contexts/shell.context';
import { useEffect, useRef, useState } from 'react';

export default function useFullscreen(startFullscreen = true, target = document.documentElement) {
  const { state: nwjs, actions: shellActions } = useShell();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullScreenRef = useRef(isFullScreen);

  const exitFullscreen = () => {
    setIsFullScreen(false);
    fullScreenRef.current = false;

    if (nwjs.desktop) {
      shellActions.requestExitFullscreen();
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const enterFullscreen = () => {
    setIsFullScreen(true);
    fullScreenRef.current = true;

    // if (nwjs.desktop) {
    //   shellActions.requestFullscreen();
    // }

    if (target.requestFullscreen) {
      target.requestFullscreen().catch(err => {
        console.warn('attemting to fullscreen', err);
      });
    }

    // @ts-ignore-next-line
    else if (document.webkitRequestFullscreen) {
      // @ts-ignore-next-line
      target.webkitRequestFullscreen().catch(err => {
        console.warn('attemting to fullscreen', err);
      });
    }
  };

  const toggleFullScreen = () => {
    if (!!document.fullscreenElement) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  // Used by document.addEventListener
  const handleKeyEvents = (e: KeyboardEvent) => {
    handleKey(e.key);
  };

  // Used when we recieve a key code
  const handleKey = (key: string) => {
    switch (key || 'F') {
      case 'Escape':
        exitFullscreen();
        break;
      case 'f':
      case 'F':
        toggleFullScreen();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (startFullscreen) enterFullscreen();
    document.addEventListener('keydown', handleKeyEvents);

    return () => {
      document.removeEventListener('keydown', handleKeyEvents);
      exitFullscreen();
    };
  }, []);

  return { isFullScreen, enterFullscreen, exitFullscreen, toggleFullScreen, handleKey };
}
