import { useEffect, useContext, createContext, useReducer, ReactNode } from 'react';

import { FileManagerProvider } from './desktop/filemanager.context';
import FileManagerSync from './desktop/FileManagerSync';
import { MediaServerProvider } from './desktop/mediaserver.context';
import {
  DownloadsAction,
  DownloadsPostMessage,
  DownloadsState,
  IDownloadsContext,
} from './downloads.types';
import { useUser } from './app.context';
import { useShell } from './shell.context';
import { useApi } from '../api/ApiProvider';
import { training_class } from '@/api/schema';

const initialState: DownloadsState = {
  user: null,
  // Estado de las descargas (true|false)
  isDownloading: false,
  // Clases encoladas (['285303-music'])
  queue: [],
  // Descarga en curso ({ id: 285303, mediaType: 'audio', progress: 20, status: 'error'|'downloading'... })
  downloading: null,
  // Listado de clases descargadas [{id: 1, ...}, {id: 2, ...}]
  trainingClasses: [],
  // Listado de descargs de clases {"12345": { id: "12345", ... }, {...}}
  offlineTrainingClasses: {},
  // List of training classes ids not cached
  unregisteredDownloads: [],
};

const DownloadsContext = createContext<IDownloadsContext>({
  state: initialState,
  dispatch: () => {},
  startServer: () => {},
  stopServer: () => {},
  removeMedia: () => {},
  updateTrainingClass: () => {},
});

const downloadsReducer = (state: DownloadsState, action: DownloadsAction): DownloadsState => {
  switch (action.type) {
    case 'current-user': {
      const { user } = action.payload;
      return { ...state, user };
    }

    case 'downloads-electron':
      const trainingClasses: WebappDownload[] = Object.values(action.payload.trainingClasses)
        .filter((tc: TrainingClass) => {
          return tc.official || tc.trainer_id == state.user?.id;
        })
        .map(tc => ({
          id: tc.id,
          trainingClass: {
            ...tc,
            media: tc.media || [],
            downloadedMedia: (tc.media || [])
              .map(m => action.payload.offlineTrainingClasses[`${tc.id}-${m.type}`])
              .filter(m => m)
              .map(m => ({
                url: m.url,
                size: m.size,
                type: m.mediaType,
                progress:
                  m.status === 'downloading' ? m.progress : m.status === 'downloaded' ? 100 : 0,
                downloaded: m.status === 'downloaded',
                downloading: m.status === 'downloading',
                queued: m.status === 'queued',
              })),
          },
          downloadedMedia: (tc.media || [])
            .map(m => action.payload.offlineTrainingClasses[`${tc.id}-${m.type}`])
            .filter(m => m)
            .map(m => ({
              url: m.url,
              size: m.size,
              type: m.mediaType,
              progress:
                m.status === 'downloading' ? m.progress : m.status === 'downloaded' ? 100 : 0,
              downloaded: m.status === 'downloaded',
              downloading: m.status === 'downloading',
              queued: m.status === 'queued',
            })),
        }));

      return { ...state, ...action.payload, trainingClasses: trainingClasses };
    case 'download-electron': {
      return { ...state, downloading: action.payload };
    }

    default:
      throw new Error('Downloads reducer message error');
  }
};

const postMessage = (msg: DownloadsPostMessage) => {
  if (!window.top) return;

  window.top.postMessage(msg, '*');
};

export const DownloadsProvider = ({ children }: { children: ReactNode }) => {
  const { state: user } = useUser();
  const { state: shell } = useShell();

  const [state, dispatch] = useReducer(downloadsReducer, {
    ...initialState,
    user,
  });

  /**
   * Inicia el servidor local de reproducción.
   */
  const startServer = () => postMessage({ type: 'server-start' });

  /**
   * Detiene el servidor local de reproducción.
   */
  const stopServer = () => postMessage({ type: 'server-stop' });

  const removeMedia = (trainingClassId: number | string, media: mediaType) => {
    postMessage({
      type: 'remove-training-class',
      trainingClassId,
      media,
    });

    if (shell.electron) {
      window.downloadsAPI?.deleteDownload(trainingClassId, media);
    }
  };

  useEffect(() => {
    dispatch({ type: 'current-user', payload: { user } });
  }, [user]);

  /**
   * Envia mensaje para obtener estado del storage.
   */
  useEffect(() => postMessage({ type: 'download-storage' }), []);

  const { fetchApi } = useApi();

  /**
   *
   * Obtiene las clases del servidor.
   */
  const fetchTrainingClasses = async (ids: string[]): Promise<ElectronTrainingClass[]> => {
    const trainingClasses: ElectronTrainingClass[] = [];

    for (const id of ids) {
      const response = await fetchApi([
        'training_classes',
        id,
        {
          include: ['trainer', 'training', 'training_materials', 'favourite', 'user_tags'],
          fields: { training: 'seconds' },
        },
      ]);

      if (response?.data) {
        const { training_class, ...training } = response?.data;

        trainingClasses.push({
          ...response?.data,
          training: {
            ...training,
            seconds: response?.data.training?.seconds ?? 0,
          },
        });
      }
    }

    return trainingClasses;
  };

  const updateTrainingSeconds = async (trainingClasses: TrainingClass[]) => {
    const limit = 20;
    const pages = Math.ceil(trainingClasses.length / limit);

    for (let i = 0; i < pages; i++) {
      const tcs = trainingClasses.slice(i * limit, i * limit + limit);

      fetchApi([
        'training_classes',
        {
          include: ['training'],
          filters: {
            id: tcs.map(tc => tc.id),
          },
          fields: { training: 'seconds' },
        },
      ]).then((response: any) => {
        if (response?.data) {
          const data = tcs.map(tc => {
            const trainingClass: TrainingClass = response.data.find(
              (_tc: TrainingClass) => _tc.id === tc.id,
            );

            if (!trainingClass) {
              return tc;
            }

            const { training_class, ...training } = trainingClass.training ?? {};

            return {
              ...tc,
              training: {
                ...training,
                seconds: trainingClass.training?.seconds ?? 0,
              },
            };
          });

          sendTrainingClassesToElectron(data);
        }
      });
    }
  };

  /**
   * Envía datos de clase a electron
   */

  const updateTrainingClass = (trainingClass: ElectronTrainingClass) => {
    const isOffline = state.trainingClasses.some(tc => tc.id === trainingClass.id);

    if (isOffline) {
      window.downloadsAPI?.updateTrainingClass(trainingClass);
    }
  };

  const sendTrainingClassesToElectron = (trainingClasses: ElectronTrainingClass[]) => {
    trainingClasses.forEach(tc => window.downloadsAPI?.updateTrainingClass(tc));
  };

  // DOWNLOADS ELECTRON

  useEffect(() => {
    if (!shell.electron) return;
    if (!state.user) return;

    // Cuando recibamos cambios de estado de downloads ejecutamos callback
    window.downloadsAPI?.handleDownloadsState((_, downloadsState) => {
      dispatch({ type: 'downloads-electron', payload: downloadsState });
    });

    // Pedimos stado de descargas
    const requestDownloadsStateFromElectron = async () => {
      const downloadsState = await window.downloadsAPI?.requestDownloadsState();

      if (!downloadsState) return;

      // Si recibimos ids pendientes de registro los descargamos
      if (downloadsState.unregisteredDownloads) {
        fetchTrainingClasses(downloadsState.unregisteredDownloads).then(
          sendTrainingClassesToElectron,
        );
      }

      const trainingClasses = Object.values(downloadsState.trainingClasses).filter(
        (trainingClass: TrainingClass) => {
          const seconds = trainingClass.training?.seconds;
          if (seconds == 0) return seconds;

          return !seconds;
        },
      );

      updateTrainingSeconds(trainingClasses);

      // actualizamos estado
      dispatch({ type: 'downloads-electron', payload: downloadsState });
    };

    requestDownloadsStateFromElectron();
  }, [state.user]);

  useEffect(() => {
    if (!shell.electron) return;
    if (!state.user) return;

    window.downloadsAPI?.handleDownloadState((_, downloadState) => {
      dispatch({ type: 'download-electron', payload: downloadState });
    });
  }, [state.user]);

  // DOWNLOAD ELECTRON
  useEffect(() => {
    if (!state.user) return;

    postMessage({
      type: '@nwjs/max-number-rooms',
      payload: { maxRooms: user.quotas?.max_scheduler_rooms },
    });
  }, [state.user]);

  return (
    <FileManagerProvider>
      <MediaServerProvider>
        <DownloadsContext.Provider
          value={{
            state,
            startServer,
            stopServer,
            dispatch,
            removeMedia,
            updateTrainingClass,
          }}
        >
          <FileManagerSync />
          {children}
        </DownloadsContext.Provider>
      </MediaServerProvider>
    </FileManagerProvider>
  );
};

// Contexto de descargas
export const useDownloads = () => useContext(DownloadsContext);

// Downloads de una clase específica
export const useDownload = (id: string | number) => {
  if (!id) return null;
  const { state } = useContext(DownloadsContext);
  const { trainingClasses } = state;

  return trainingClasses.find(tc => tc.id == id);
};

/**
 * Returns the trainingClass being downloaded
 */
export const useDownloading = () => {
  const { state } = useContext(DownloadsContext);
  return state.downloading;
};

/**
 * Returns the download status of a trainingClass
 */
export const useDownloadStatus = (id: string | number, mediaType: mediaType): DownloadStatus => {
  const downloading = useDownloading();
  const download = useDownload(id);

  if (downloading?.id == id && downloading?.mediaType == mediaType) {
    return 'downloading';
  }

  if (!download) {
    return 'none';
  }

  const downloadedMedia = download?.downloadedMedia?.find(dm => dm.type == mediaType);

  if (!downloadedMedia) return 'none';
  if (downloadedMedia.queued) return 'queued';
  if (downloadedMedia.downloading) return 'downloading';
  if (downloadedMedia.downloaded) return 'downloaded';

  return 'none';
};

// TODO: debugear este método
export const useDownloadMediaUrl = (id: number | string | null, mediaType: mediaType) => {
  const { state: shell } = useShell();

  if (!id || !mediaType) return null;

  if (shell.electron && window.downloadsAPI) {
    return window.downloadsAPI.getMediaUrl(id, mediaType);
  }

  return null;
};

export const useHasDownloadedVideo = (id: string | number) => {
  if (!id) return null;
  const { state } = useContext(DownloadsContext);
  const { trainingClasses } = state;

  return !!trainingClasses
    .find(tr => tr.id == id)
    ?.downloadedMedia?.some(v => v.type == 'video_hd' && v.downloaded);
};
