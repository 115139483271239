import styles from './RepliesIcon.module.scss';

import replyIcon from '../../../../img/comment.png';

export default function RepliesIcon({ comment }) {
  return (
    <button className={styles.button}>
      <div className={styles.icon}>
        <img className={styles.icon} src={replyIcon} />
      </div>{' '}
      <div className={styles.count}>{comment.replies_count}</div>
    </button>
  );
}
