import { SwitchCheckBox } from '@/core/components/FormControls';
import Chevron from '@/img/chevron';
import clsx from 'clsx';
import { useState } from 'react';
import { Cookie } from '../types';
import styles from './CookieSection.module.scss';

type CookieSectionProps = {
  cookieKey: string;
  isFirst: boolean;
  isActive: boolean;
  onToggle(): void;
} & Cookie;

const CookieSection = (props: CookieSectionProps) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => setShowContent(show => !show);

  return (
    <div
      className={clsx(
        styles.container,
        props.isFirst && styles.isFirst,
        showContent && styles.showContent,
      )}
    >
      <div className={styles.header}>
        <button onClick={toggleContent} className={styles.containerButton}>
          <span className={styles.chevron}>
            <Chevron />
          </span>
          <span className={styles.label}>{props.label}</span>
        </button>
        {props.isToggable ? (
          <label>
            <SwitchCheckBox
              id={`COOKIE_SWITCH_${props.cookieKey.toUpperCase()}`}
              onChange={props.onToggle}
              defaultChecked={props.isActive}
            />
          </label>
        ) : (
          <span className={styles.default}>Siempre activas</span>
        )}
      </div>
      <div className={styles.content}>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default CookieSection;
