import iconPlus from '../../../../img/icon-plus.svg';

export default function IconPlus() {
  return (
    <img
      src={iconPlus}
      alt=""
      aria-hidden="true"
      style={{ height: '20px', marginRight: '5px' }}
    />
  );
}
