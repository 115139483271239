import { Category, Logos, Names } from '../../constants/category';
import styles from './CategoryLogo.module';

export default function CategoryLogo({ categoryNr }) {
  if (!Object.values(Category).includes(categoryNr)) return null;

  return (
    <div className={styles.categoryLogo}>
      <img src={Logos[categoryNr]} alt={Names[categoryNr]} />
    </div>
  );
}
