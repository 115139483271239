import { useState } from 'react';
import { Cookies } from '../constants/cookies';

const cookie_local_key = 'ACEPTA_COOKIES';

// Cookie expiration cannot be greater than 400 days => https://stackoverflow.com/questions/532635/javascript-cookie-with-no-expiration-date
// I set the expiration date 10 years in the future, but the browser automaticaly adds the 400 days instead
const markAsConfigured = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 10);

  document.cookie = `${cookie_local_key}=1; path=/; expires=${date.toUTCString()}`;
};

const isConfigured = () => {
  const cookies = Object.fromEntries(
    (document.cookie ?? '').split('; ').map(cookie => cookie.split('=')),
  );

  return Object.keys(cookies).includes(cookie_local_key);
};

// Injected in index.html
const gtag = (...args: any) => window.dataLayer.push(args);

const useCookies = () => {
  const [hasConfigured, setHasConfigured] = useState(isConfigured());

  const configure = (cookies: string[]) => {
    gtag('consent', 'update', {
      ad_storage: cookies.includes(Cookies.types.COOKIE_ADS) ? 'granted' : 'denied',
      analytics_storage: cookies.includes(Cookies.types.COOKIE_ANALYTICS) ? 'granted' : 'denied',
    });

    setHasConfigured(true);
  };

  const accept = (cookies: string[]) => {
    configure(cookies);
    markAsConfigured();
  };

  const acceptAll = () => {
    configure(Cookies.bestcycling);
    markAsConfigured();
  };

  const decline = () => {
    configure([]);
    markAsConfigured();
  };

  return {
    hasConfigured,
    accept,
    acceptAll,
    decline,
  };
};

export default useCookies;
