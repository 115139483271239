import { useState, useEffect } from 'react';
import TagColor from '@/constants/TagsColor';

export const useCustomUserTags = ({ tagList = [] }: { tagList: UserTag[] }) => {
  const [allTags, setTags] = useState(tagList);
  const [addedTags, setAddedTags] = useState<UserTag[]>([]);
  const [noAddedTags, setNoAddedTags] = useState<UserTag[]>([]);

  const getNoAddedTags = () =>
    allTags.filter(tag => !addedTags.map(addedTag => addedTag.id).includes(tag.id));

  const removeTag = (tag: UserTag) =>
    setAddedTags([...addedTags.filter(element => element.id != tag.id)]);

  const addTags = (tag: UserTag) => setAddedTags([...addedTags, tag]);

  const onChangeFilterTags = (searchText: string) => {
    const search = searchText.toLowerCase();
    const noIncludedTags = getNoAddedTags();

    if (search) {
      if (noIncludedTags && noIncludedTags.length) {
        const validTags = noIncludedTags.filter(item => item.name.toLowerCase().includes(search));
        setNoAddedTags(validTags);
      }
    } else setNoAddedTags(noIncludedTags);
  };

  const getTagByName = (tags: UserTag[], name: string) => {
    return tags && tags.length
      ? tags.find(tag => tag.name.toLowerCase() == name.toLowerCase())
      : false;
  };

  const createTag = (name: string) => {
    if (!name) return null;

    const tagName = name;
    const found = getTagByName(allTags, tagName);

    if (getTagByName(addedTags, tagName)) return null;
    if (!found) {
      const colorIndex = (addedTags.length + 1) % TagColor.length;
      const tag = {
        name: tagName,
        properties: { color: TagColor[colorIndex] },
        id: null,
      };

      addTags(tag); // missing tag id since it's not yet on remote FIXME:
      return tag;
    }

    addTags(found);
    return found;
  };

  const existsTagByName = (name: string) => {
    if (!name) return false;
    if (getTagByName(noAddedTags, name)) return false;
    if (getTagByName(addedTags, name)) return false;

    return true;
  };

  useEffect(() => {
    if (!allTags) return;

    const noTags = getNoAddedTags();
    setNoAddedTags(noTags);
  }, [addedTags]);

  return {
    allTags,
    addedTags,
    noAddedTags,
    setTags,
    setAddedTags,
    removeTag,
    addTags,
    onChangeFilterTags,
    createTag,
    existsTagByName,
  };
};

export default useCustomUserTags;
