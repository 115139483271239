import LoadingIndicator from "@/core/components/LoadingIndicator";
import clsx from "clsx";
import { useRef, useState } from "react";
import CustomControls from "./CustomControls";
import styles from './IntegratedVideo.module.scss';

type IntegratedVideoProps = {
  url: string;
  autoplay?: boolean;
}

const IntegratedVideo = ({ url, autoplay = false }: IntegratedVideoProps) => {
  const [totalDuration, setTotalDuration] = useState(0);

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onLoadedMetadata = () => {
    if (!videoRef.current) return;

    const duration = Math.trunc(videoRef.current?.duration);
    setTotalDuration(duration);
  }

  return (
    <div ref={videoContainerRef} className={styles.container}>
      <video 
        ref={videoRef} 
        controlsList="nodownload" 
        className={styles.video} 
        autoPlay={autoplay}
        onLoadedMetadata={onLoadedMetadata}>
        <source src={url} />
      </video>
        <div className={styles.controls}>
          {
            videoRef.current ? 
              <CustomControls 
                totalDuration={totalDuration}
                videoContainer={videoContainerRef.current!}
                video={videoRef.current!} /> :
              <LoadingIndicator />
          }
        </div>
    </div>
  )
};

export default IntegratedVideo;