import LoadingIndicator from '../../LoadingIndicator';
import styles from './LoadingRelatedTrainingClasses.module.scss';

const LoadingRelatedTrainingClasses = () => {
  return (
    <div className={styles.container}>
      <LoadingIndicator />
    </div>
  );
}

export default LoadingRelatedTrainingClasses;