import { useCallback, useEffect, useRef} from 'react';
import { useShell } from '../contexts/shell.context';

// Will turn on the WakeLock while this component is mounted if the browser supports it
const useWakeLock = (args?: { onlyForDesktop?: boolean }) => {
  const { onlyForDesktop = false } = args ?? {};
  const { state: isDesktop } = useShell();
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  useEffect(() => {
    lock();
    return () => {
      if (wakeLockRef.current?.released === false) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }
    };
  }, []);

  const lock = useCallback(() => {
    if (
      document.visibilityState === 'visible' &&
      'wakeLock' in navigator &&
      (onlyForDesktop ? isDesktop : true)
    ) {
      navigator.wakeLock.request('screen').then((wakeLock: WakeLockSentinel) => {
        console.log('Wake Lock is active');
        if (wakeLockRef.current) wakeLockRef.current.release();
        wakeLockRef.current = wakeLock;
        wakeLockRef.current.addEventListener('release', () => {
          console.log('Wake Lock is released');
        });
      }).catch(err => console.error('wakeLock error', err));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => lock());

    return () => {
      document.removeEventListener('visibilitychange', () => lock());
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }
    };
  }, []);
};

export default useWakeLock;
