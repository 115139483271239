import { useAuth } from '@/core/contexts/auth.context';
import Chevron from '@/img/chevron';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import styles from './VisualTrainingModal.module.scss';

const videoTypes: (mediaType | 'video')[] = ['hls', 'video', 'video_sd', 'video_hd', 'video_extra'];

type VisualTrainingModalProps = {
  isOpen: boolean;
  training: TrainingClassExercise;
  onClose(): void;
  onPrevClick(): void;
  onNextClick(): void;
};

const VisualTrainingModal = (props: VisualTrainingModalProps) => {
  const { state: auth } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isDataCollapsed, setIsDataCollapsed] = useState<boolean>(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const exerciseVideo = useMemo(() => {
    const { media_assets } = props.training;

    if (!media_assets.length) return;

    return media_assets.find(mediaAsset => videoTypes.includes(mediaAsset.type)) || media_assets[0];
  }, [props.training.media_assets]);

  const exerciseWithToken = `${exerciseVideo?.url}/?access_token=${auth.accessToken}`;

  useEffect(() => {
    if (!videoRef?.current) return;

    setIsLoading(true);
    videoRef.current.load();

    const handleStatus = () => {
      if (videoRef?.current?.readyState || 0 >= 3) {
        setIsLoading(false);
      }
    };

    videoRef.current.addEventListener('loadeddata', handleStatus);

    return () => {
      videoRef.current?.removeEventListener('loadeddata', handleStatus);
    };
  }, [exerciseWithToken, videoRef?.current]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      onPrevRequest={props.onPrevClick}
      onNextRequest={props.onNextClick}
      className={styles.modalContainer}
    >
      {!props.training.description && <div className={styles.emptySpace} />}
      <div className={styles.videoContainer}>
        {isLoading && (
          <div className={styles.loading}>
            <LoadingIndicator />
          </div>
        )}

        <video
          ref={videoRef}
          className={clsx(styles.video, isLoading && styles.hidden)}
          muted
          autoPlay
          loop
          src={exerciseWithToken}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.scrollable}>
          <div className={styles.name}>{props.training.name}</div>
          {props.training.description && (
            <p className={styles.description}>{props.training.description}</p>
          )}

          {props.training.steps?.length && (
            <>
              <h2 className={styles.stepsTitle}>PASOS</h2>
              <div className={styles.steps}>
                {props.training.steps.map((step, index) => {
                  return (
                    <div className={styles.step} key={index}>
                      <span className={styles.stepIndex}>{index + 1}</span>
                      <span className={styles.text}>{step}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VisualTrainingModal;
