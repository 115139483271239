import { useInfiniteQuery } from '@tanstack/react-query';
import LoadingIndicator from '../components/LoadingIndicator';
import PlaylistCard from '@/components/Playlists/PlaylistCard';
import styles from './ParentPlaylistPage.module';
import { useApi } from '../api/ApiProvider';
import InfiniteQueryList from '@/components/InfiniteQueryList';

const PlaylistChildren = ({ playlist }) => {
  const { fetchApi, getNextPageParam } = useApi();

  const { data, isLoading, isSuccess, isError, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ['playlists', { filters: { parent_id: playlist.id } }],
      ({ pageParam }) =>
        fetchApi(['playlists', { filters: { parent_id: playlist.id }, cursor: pageParam }]),
      {
        getNextPageParam,
      },
    );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <InfiniteQueryList
      isSuccess={isSuccess}
      isError={isError}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      errorLoadingMessage="No es posible cargar más colecciones dado que no tienes conexión a internet. Comprueba tu conexión y vuelve a intentarlo."
    >
      <div className={styles.collectionList}>
        {data.pages
          .map(page => page.data)
          .flat()
          .filter(item => item.has_items || item.has_children)
          .map(item => (
            <div key={item.id} className={styles.playlistCard}>
              <PlaylistCard playlist={item} />
            </div>
          ))}
      </div>
    </InfiniteQueryList>
  );
};

export default PlaylistChildren;
