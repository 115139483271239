import { useEffect } from 'react';

/**
 * Just tells electron the main contexts are loaded
 */
export default function useMainLoaded() {
  useEffect(() => {
    const mainLoaded = window.electronAPI?.mainLoaded;
    if (!mainLoaded) return;
    mainLoaded();
  }, []);
}
