import Slider from '@/components/Slider';

import PostCard from './PostCard';

export default function PostsCarousel({ posts, onClick }) {
  return (
    <Slider>
      {posts.map(post => (
        <PostCard key={post.id} post={post} onClick={onClick} />
      ))}
    </Slider>
  );
}
