import { useFeeds } from '../../contexts/feeds.context';
import './NotificationsIcon.scss';

const ActiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.95 21.914">
    <path
      fill="white"
      d="M18.773,0H3.141A3.273,3.273,0,0,0,0,3.4V14.828a3.273,3.273,0,0,0,3.141,3.4H12.2l4.273,3.543a.549.549,0,0,0,.4.146.86.86,0,0,0,.292-.073.724.724,0,0,0,.365-.657l.037-2.995h1.242a3.3,3.3,0,0,0,3.141-3.4V3.4A3.326,3.326,0,0,0,18.773,0ZM3.1,5.99A1.059,1.059,0,1,1,4.164,7.049,1.037,1.037,0,0,1,3.1,5.99Zm7.852,8.692c-6.209,0-6.793-5.15-6.793-5.15H17.75S17.093,14.682,10.957,14.682Zm6.793-7.6a1.059,1.059,0,1,1,1.059-1.059A1.06,1.06,0,0,1,17.75,7.085Z"
    />
  </svg>
);

const NormalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28.799 28.752">
    <g transform="translate(0.25 0.25)">
      <path
        d="M24.2.565a3.649,3.649,0,0,1,3.484,3.814V19.117A3.678,3.678,0,0,1,24.2,22.931H22.6a.544.544,0,0,0-.565.565V27.31a.4.4,0,0,1-.188.33.164.164,0,0,0-.094.047.356.356,0,0,1-.188-.047l-5.509-4.567a.535.535,0,0,0-.377-.141H4.049A3.649,3.649,0,0,1,.565,19.117V4.379A3.678,3.678,0,0,1,4.049.565H24.2M24.2,0H4.049A4.219,4.219,0,0,0,0,4.379V19.117A4.219,4.219,0,0,0,4.049,23.5H15.727l5.509,4.567a.708.708,0,0,0,.518.188,1.108,1.108,0,0,0,.377-.094.933.933,0,0,0,.471-.848l.047-3.861h1.6A4.25,4.25,0,0,0,28.3,19.07V4.379A4.289,4.289,0,0,0,24.2,0Z"
        fill="#9c9d9d"
        stroke="#9c9d9d"
        strokeWidth="0.5"
      />
      <circle
        cx="1.366"
        cy="1.366"
        r="1.366"
        transform="translate(4.002 6.357)"
        fill="#9c9d9d"
        stroke="#9c9d9d"
        strokeWidth="0.5"
      />
      <circle
        cx="1.366"
        cy="1.366"
        r="1.366"
        transform="translate(21.519 6.357)"
        fill="#9c9d9d"
        stroke="#9c9d9d"
        strokeWidth="0.5"
      />
      <path
        d="M11.4,26.1s.753,6.639,8.758,6.639c7.911,0,8.758-6.639,8.758-6.639"
        transform="translate(-6.032 -13.81)"
        fill="#9c9d9d"
        stroke="#9c9d9d"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default function NotificationsIcon({ active = false }) {
  const { state } = useFeeds();

  return (
    <div className="iconNotification">
      {active ? <ActiveIcon /> : <NormalIcon />}
      {state.unseen > 0 && <div className="iconNotificationIndicator"></div>}
    </div>
  );
}
