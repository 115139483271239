import { cloneElement, useState, useEffect, useContext, useRef, useMemo } from 'react';
import useFetch from '../../api/useFetch';
import { AsyncSearchFilterTypes } from './AsyncSearchFilter';
import FiltersContext from '@/contexts/filters.context';
import { useQueryFilters } from '../../hooks/useQueryFilters';

import styles from './Filters.module';
import pluralize from '../../utils/pluralize';
import { useSearchParams } from 'react-router-dom';

export function Filter({ options, type, tag, needsAll = true, dataCy = '' }) {
  const { filters, setFilters } = useContext(FiltersContext);

  const saveFilters = ({ value }) => {
    if (!value) return;
    const newfilters = { ...filters };

    if (value === 'todos') {
      delete newfilters[type];
    } else {
      newfilters[type] = value;
    }
    //Resetamos los filtros de instructor y tipo  al setear una nueva categoría
    if (type === 'category_nr') {
      delete newfilters['training_type_nr'];
      delete newfilters['trainer_id'];
      delete newfilters['training_materials'];
    }
    setFilters(newfilters);
  };

  return (
    <div className={styles.filter}>
      <label>{tag}</label>
      <select
        data-cy={dataCy}
        id={type}
        name={type}
        value={filters[type] || 'todos'}
        onChange={({ target }) => saveFilters(target)}
      >
        {needsAll && (
          <option key="todos" value="todos">
            Todos
          </option>
        )}
        {options.map(optionItem => (
          <option key={optionItem.value || optionItem.id} value={optionItem.value || optionItem.id}>
            {optionItem.label || optionItem.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export function FilterByOptionsTypes({ type, options, tag }) {
  const { filters, setFilters } = useContext(FiltersContext);

  const getDefaultValue = () => {
    const optionsKeys = options.map(option => option.key);
    const filtersKeys = Object.keys(filters);

    const selectedFilter = filtersKeys.find(filterKey => optionsKeys.includes(filterKey));

    return selectedFilter ?? 'todos';
  };

  const saveFilters = opt => {
    const { value } = opt.target;

    if (!value) return;
    const newfilters = { ...filters };

    options.forEach(option => {
      delete newfilters[option.key];
    });

    const option = options.find(option => option.key === value);
    if (option) {
      newfilters[option.key] = option.value;
    }

    setFilters(newfilters);
  };

  return (
    <div className={styles.filter}>
      <label>{tag}</label>
      <select id={type} name={type} value={getDefaultValue()} onChange={t => saveFilters(t)}>
        <option key="todos" value="todos">
          Todos
        </option>
        {options.map(optionItem => (
          <option key={optionItem.value} value={optionItem.key}>
            {optionItem.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export function OrganizeFilter({ options, type, tag }) {
  const { filters, setFilters } = useContext(FiltersContext);

  function saveFilters({ value }) {
    if (!value) return;
    const newfilters = { ...filters };

    const isUsed = value === 'true';
    newfilters[type] = !!isUsed;
    setFilters(newfilters);
  }

  return (
    <div className={styles.filter}>
      <label>{tag}</label>
      <select
        id={type}
        name={type}
        value={filters[type]}
        onChange={({ target }) => saveFilters(target)}
      >
        {options.map(optionItem => (
          <option key={optionItem.value} value={optionItem.value}>
            {optionItem.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export function AsyncFilter({
  filterType,
  filterKey,
  label,
  official = '1',
  tag,
  dataModify,
  includes,
}) {
  const { filters, setFilters } = useContext(FiltersContext);

  const { data } = useQueryFilters(filterType, official, includes);

  const parseData = dataToParse => {
    if (dataToParse) {
      let option = dataToParse.map(item => ({
        value: item.id,
        label: item[label],
      }));

      return option;
    }
    return [];
  };

  const options = useMemo(() => {
    if (!data || !data.data) return [];

    const dataToParse = dataModify ? dataModify(data.data) : data.data;

    // Si el id del instructor no-oficial es igual al id del instructor oficial, lo eliminamos
    const filterData = dataToParse.filter((item, index, self) => {
      return index === self.findIndex(t => t.id === item.id);
    });

    return parseData(filterData);
  }, [data]);

  const saveFilters = ({ value }) => {
    const copy_filter = { ...filters };

    if (value === 'todos') delete copy_filter[filterKey];
    if (value !== 'todos') copy_filter[filterKey] = value;

    setFilters(copy_filter);
  };

  return (
    <>
      {data && (
        <div className={styles.filter}>
          <label>{tag}</label>
          <select
            id={filterType}
            name={filterType}
            value={filters[filterKey] || 'todos'}
            onChange={({ target }) => saveFilters(target)}
            className={styles.select}
          >
            <option key="todos" value="todos">
              Todos
            </option>
            {filterType == 'training_materials' && (
              <option key="Sin material" value="-1">
                Sin material
              </option>
            )}
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

export function AsyncFilterLocation({ filterType, filterKey, label, official = '0', tag }) {
  const { filters, setFilters } = useContext(FiltersContext);
  const url =
    filters && filters.category_nr
      ? `${config.API_V2_BASE}/${filterType}?filters[category_nr]=${filters.category_nr}&filters[official]=${official}`
      : `${config.API_V2_BASE}/${filterType}?filters[official]=${official}`;
  const { status, data } = useFetch(url);
  const [auxOption, setAuxOption] = useState({});

  const parseData = rawData => {
    if (!rawData || status === 'fetching' || status === 'idle') return [];

    const auxData = [];
    let needAddAuxOption = true;
    for (let i = 0; i < rawData.length; i += 1) {
      const item = rawData[i];
      if (needAddAuxOption && auxOption && item.id === auxOption.id) {
        needAddAuxOption = false;
      }

      auxData.push({
        value: item.attributes[label] ?? '-',
        label: item.attributes[label],
        type: item.type,
        id: item.id,
      });
    }

    if (needAddAuxOption && auxOption.id) {
      auxData.push({
        value: auxOption.attributes[label] ?? '-',
        label: auxOption.attributes[label],
        type: auxOption.type,
        id: auxOption.id,
      });
    }

    return auxData;
  };

  const saveFilters = ({ value }) => {
    if (!value) return;

    const newfilters = { ...filters };
    if (value === '') {
      delete newfilters[filterKey];
    } else {
      newfilters[filterKey] = value;
    }

    const aux = data.find(item => item.id === value || item.id == parseInt(value, 10));
    if (aux) {
      setAuxOption(aux);
    }

    setFilters(newfilters);
  };

  return (
    <div className={styles.filter}>
      <label>{tag}</label>
      <select
        id={filterType}
        name={filterType}
        value={filters[filterKey] || '-'}
        onChange={({ target }) => saveFilters(target)}
      >
        {parseData(data).map(optionItem => (
          <option key={optionItem.id || optionItem.value} value={optionItem.label || '-'}>
            {!optionItem.label ? 'Todos los lugares' : optionItem.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export function SearchFilter({
  className,
  placeHolder,
  onBlur,
  onFocus,
  onSearch,
  reset,
  styled = true,
}) {
  var textPlaceHolder = placeHolder != null ? placeHolder : 'Buscar clase...';
  let typingTimer = null;
  const doneTypingInterval = 500;
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const [search, setSearch] = useState(params.search || '');
  const { filters, setFilters } = useContext(FiltersContext);

  const saveFilters = newFilters => {
    if (!filters) return;
    setFilters(newFilters);
  };

  const handleEvent = () => {
    if (onSearch != null) {
      onSearch(search);
    } else {
      const auxFilters = { ...filters };
      if (!search || !search.length) {
        delete auxFilters.search;
      } else {
        auxFilters.search = search;
      }
      saveFilters(auxFilters);
    }
  };

  const handleOnKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleEvent, doneTypingInterval);
  };

  const handleOnKeyDown = () => {
    clearTimeout(typingTimer);
  };

  useEffect(() => {
    if (reset) {
      setSearch('');
      handleEvent();
    }
  }, []);

  useEffect(() => {
    if (onSearch != null) {
      onSearch(search);
    }
  }, [search]);

  return (
    <div className={className}>
      <input
        className={styled ? styles.searchInput : null}
        type="input"
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        onKeyDown={handleOnKeyDown}
        onKeyUp={handleOnKeyUp}
        placeholder={textPlaceHolder}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

export function DynamicFilter({
  children,
  filterType,
  filterSubType,
  filterKey,
  optionsTitle,
  tag,
}) {
  const { filters, setFilters } = useContext(FiltersContext);
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  let refInside;

  const setListRef = listRef => {
    refInside = listRef;
  };

  const handleHideDropdown = event => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleClick = event => {
    if (ref.current) {
      if (ref.current == event.target) {
        setOpen(!open);
      } else if (
        !ref.current.contains(event.target) ||
        (!!refInside && refInside.current && !refInside.current.contains(event.target))
      ) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const selectedObjects = !!filters && filters[filterKey] ? filters[filterKey] : [];

  const filter = {
    id: filterType,
    title: optionsTitle,
    filters:
      filterSubType != null && filterSubType.length > 0
        ? { type_of_food: filterSubType, parents: 1 }
        : { parents: 1 },
    selectedObjects,
    selectedFirst: true,
    defaultOption: true,
  };

  const onClose = objects => {
    setFilters({ ...filters, [filterKey]: objects.map(object => object) });
    setOpen(false);
  };

  return (
    <div className={styles.dynamicFilter}>
      <div className={styles.filter}>
        <label>{tag}</label>
        <div
          key="todos"
          value="todos"
          className={styles.dynamicSelect}
          ref={ref}
          onClick={handleClick}
        >
          {selectedObjects.length
            ? pluralize(selectedObjects.length, 'ingrediente', 'ingredientes')
            : 'Todos'}
          {open && (
            <div className={styles.dropdown}>
              {cloneElement(children, {
                reset: open,
                onClose: onClose,
                filter,
                type: AsyncSearchFilterTypes.select,
                setListRef: setListRef,
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
