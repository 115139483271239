import styles from './Playlists.module.scss';

import logoCycling from '../../../../img/bestcycling-logo.png';
import logoTraining from '../../../../img/besttraining-logo.png';
import logoRunning from '../../../../img/bestrunning-logo.png';
import logoWalking from '../../../../img/bestwalking-logo.png';
import logoMind from '../../../../img/bestmind-logo.png';
import logoBalance from '../../../../img/bestbalance-logo.png';

import logoMenshealth from '../../../../img/home/logo-menshealth--blanco.svg';
import logoWomenshealth from '../../../../img/home/logo-womenshealth--blanco.svg';
import logoRunnersworld from '../../../../img/home/logo-runnersworld--blanco.svg';
import PlaylistBreadcrumbs from './PlaylistBreadcrumbs';

const colors = {
  combinadas: 'white',
  bestcycling: '#ff7900',
  bestrunning: '#fcd900',
  besttraining: '#f13b46',
  bestbalance: '#69ae00',
  bestwalking: '#8d88c5',
  bestmind: '#1D8CB9',
};

const logos = {
  combinadas: 'white',
  bestcycling: logoCycling,
  besttraining: logoTraining,
  bestbalance: logoBalance,
  bestrunning: logoRunning,
  bestwalking: logoWalking,
  bestmind: logoMind,
  menshealth: logoMenshealth,
  womenshealth: logoWomenshealth,
  runnersworld: logoRunnersworld,
};

const alt = {
  bestcycling: 'bestcycling',
  besttraining: 'besttraining',
  bestbalance: 'bestbalance',
  bestrunning: 'bestrunning',
  bestwalking: 'bestwalking',
  bestmind: 'bestmind',
  menshealth: "Men's Health",
  womenshealth: "Women's Health",
  runnersworld: "Runner's World",
};

export default function PlaylistHeaderCategory({ playlist }) {
  if (!playlist) return null;

  let title = (playlist && playlist.title.toLowerCase()) || '';

  if (title.includes("Men's Health")) {
    title = 'menshealth';
  } else if (title.includes("Women's Health")) {
    title = 'womenshealth';
  } else if (title.includes("Runner's World")) {
    title = 'runnersworld';
  } else {
    title = title.split(' ').pop();
    title = title.includes('best') ? title : `best${title}`;
  }

  const backgroundImage = colors[title]
    ? `linear-gradient(${colors[title]}, transparent)`
    : `linear-gradient(to bottom, rgba(0,0,0,0.1), #161616), url(${
        playlist.image2 || playlist.image
      })`;

  return (
    <div
      className={styles.header}
      style={{
        backgroundImage,
      }}
    >
      <div className={styles.header_playlist_category}>
        <PlaylistBreadcrumbs playlist={playlist} />
        {logos[title.toLowerCase()] ? (
          <img alt={alt[title]} src={logos[title]} />
        ) : (
          <p className={styles.header_playlist_title}>
            {(playlist.title && playlist.title) || ''}
          </p>
        )}
      </div>
    </div>
  );
}
