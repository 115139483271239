import clsx from 'clsx';
import styles from './Modal.module.scss';

/// ModalActions is used to style the buttons in the modal footer
type ModalHeaderProps = {
  className?: string;
  children: React.ReactNode;
};
export default function ModalActions({ className, children }: ModalHeaderProps) {
  return <footer className={clsx([styles.modalActions, className])}>{children}</footer>;
}
