import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FooterMin } from '@/components/Footer';

import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
import NotFound from '@/components/TrainingClassDetails/NotFound';
import Page from '@/components/Page';
import TrainingClassDetails from '@/components/TrainingClassDetails/TrainingClassDetails';
import useInterval from '@/hooks/useInterval';
import useFetchTrainingClass from '../hooks/apiHooks/useFetchTrainingClass';

export default function TrainingClassPage(props: any) {
  const { id } = useParams();

  const [retriesMedia, setRetriesMedia] = useState(0);

  const { data, isLoading, isError, refetch } = useFetchTrainingClass(id, [
    'trainer',
    'training',
    'training_materials',
    'favourite',
    'user_tags',
    'sharing_user',
    'training_genres',
  ]);

  // TODO: Revisar esto. Está feo. supongo que se hacía para el rato en el que se están procesando los medias.
  const condition = useRef(!!data?.media.length);
  useInterval(
    () => {
      if (retriesMedia === 12) condition.current = true;

      if (!data?.media || (data?.media && data?.media.length === 0)) {
        refetch({ cancelRefetch: false });
        setRetriesMedia(retriesMedia + 1);
      }
    },
    !condition.current ? 5000 : null,
  );

  return (
    <Page footer={<FooterMin />}>
      {isLoading && <LoadingIndicator />}
      {isError && <NotFound />}
      {data && (
        <>
          <TrainingClassDetails
            trainingClass={data}
            hasMediaClass={data?.media.length > 0}
            isRetriesMediaFailed={retriesMedia >= 12}
            {...props}
          />
        </>
      )}
    </Page>
  );
}
