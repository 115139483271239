import Modal from '../Modal';
import { useEffect, useRef, useState } from 'react';
import styles from './AddTagModal.module.scss';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import Button from '../UI/Button/Button';

import LoadingIndicator from '../LoadingIndicator';
import useCustomUserTags from '@/hooks/useCustomUserTags';
import AddTags from '../AddTags/AddTags';
import usePutUserTaggings from '@/core/hooks/apiHooks/usePutUserTaggings';
import { useToast } from '@/core/contexts/toast.context';
import usePostUserTag from '@/core/hooks/apiHooks/usePostUserTag';

export default function AddTagModal({
  tagList = [],
  trainingClasses = [],
  isOpen,
  onClose,
  onSelectTag,
}: {
  tagList: UserTag[];
  trainingClasses: TrainingClass[];
  isOpen: boolean;
  onClose: () => void;
  onSelectTag: () => void;
}) {
  const {
    addedTags,
    noAddedTags,
    setTags,
    removeTag,
    addTags,
    setAddedTags,
    createTag,
    existsTagByName,
    onChangeFilterTags,
  } = useCustomUserTags({ tagList });

  const tagsToBeAdded = useRef<UserTag[]>([]);

  const [tagsFilterSearch, setTagsFilterSearch] = useState('');
  const { showToast } = useToast();

  const close = () => {
    onSelectTag();
    onClose();
  };

  const createNewTag = usePostUserTag({
    onSuccess: (result: UserTag) => {
      showToast(`Creado tag: ${result.name}`, 'success');
      tagsToBeAdded.current = [...tagsToBeAdded.current, result];
    },
  });

  const updateTrainingClassesTags = usePutUserTaggings();

  const changeTagsFilter = (value: any) => {
    setTagsFilterSearch(value);
    onChangeFilterTags(value);
  };

  const joinTrainingClassesTags = () =>
    trainingClasses.map(trainingClass => trainingClass.user_tags).flat();

  // No one of these is needed on context, but the should´ve using variables from there.
  const submitChanges = async () => {
    const createdTag = createTag(tagsFilterSearch);
    const auxAddedTags = createdTag ? [...addedTags, createdTag] : [...addedTags];

    const invalidTags = auxAddedTags.filter(tag => !tag.id);
    tagsToBeAdded.current = auxAddedTags.filter(tag => tag.id);

    try {
      for (let i = 0; i < invalidTags.length; i++) {
        await createNewTag.mutateAsync(invalidTags[i]);
      }
    } catch (e) {
      showToast('Error al crear los tags, vuelve a intentarlo más tarde', e);
      return;
    }

    if (tagsToBeAdded.current.length != addedTags.length) return;

    updateTrainingClassesTags.mutate({
      training_class_ids: trainingClasses.map(t => t.id),
      user_tags: tagsToBeAdded.current,
    });

    onClose();
  };

  useEffect(() => {
    if (!isOpen) return;
    if (!trainingClasses) return;

    const joinTags = joinTrainingClassesTags();
    const uniqueTags = [...new Map(joinTags.map(tag => [tag.id, tag])).values()];
    setAddedTags([...uniqueTags]);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    setTags(tagList);
  }, [tagList, isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={close} className={styles.modal}>
      <div className={styles.content}>
        <div className={styles.header}>Añadir o quitar de colección</div>
        <div className={styles.description}>
          <p>
            Selecciona las colecciones que quieras añadir a tus clases. Puedes crear nuevas
            colecciones introduciendo su nombre seguido de Enter.
          </p>
        </div>

        <AddTags
          autoFocus
          addedTags={addedTags}
          noAddedTags={noAddedTags}
          existsTagByName={existsTagByName(tagsFilterSearch)}
          addTags={addTags}
          removeAddedTag={removeTag}
          onChangeFilter={changeTagsFilter}
          createTag={() => createTag(tagsFilterSearch)}
          onEnter={submitChanges}
        />

        <div className={styles.buttons}>
          <Button variant="terciary" onClick={onClose} style={{ marginRight: '15px' }}>
            CANCELAR
          </Button>

          <Button variant="primary" onClick={submitChanges}>
            CONTINUAR
          </Button>
        </div>
      </div>
    </Modal>
  );
}
