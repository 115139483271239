import BluetoothListDevices from '../Bluetooth/BluetoothListDevices';
import Modal from '../Modal';
import styles from './BluetoothDevicesModal.module.scss';
import iconBack from '../../../../../src/img/chevron-left.svg';

interface BluetoothDevicesModalProps {
  isOpen: boolean;
  selectDevice: (id: BTDevice) => void;
  onCancel: () => void;
  btDevicesList: BTDevice[];
  noDevices?: boolean;
}

export default function BluetoothDevicesModal({
  isOpen,
  selectDevice,
  onCancel,
  btDevicesList,
}: BluetoothDevicesModalProps) {
  const noDevices = btDevicesList.length === 0;
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <div className={styles.header}>
        <img src={iconBack} className={styles.back} onClick={onCancel} />
        <h1 className={styles.title}>DISPOSITIVOS BLUETOOTH</h1>
      </div>
      {noDevices ? (
        <>
          <span className={styles.divider} />
          <p className={styles.descriptionNoDevices}>No se han encontrado dispositivos </p>
          <span className={styles.divider} />
        </>
      ) : (
        <BluetoothListDevices devices={btDevicesList} selectDevice={selectDevice} />
      )}

      <p className={styles.footerTitle}> ¿No encuentras tu dispostivo?</p>
      <p className={styles.footerDescription}>
        {' '}
        Comprueba que tu dispositivo esté encendido y <br /> suficientemente próximo al PC. <br />{' '}
        Ten en cuenta también que algunas bicicletas solo pueden
        <br />
        conectarse mientras se está pedaleando en ellas.
      </p>
    </Modal>
  );
}
