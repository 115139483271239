import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { usePreferences, useProfile } from '../contexts/app.context';
import Category from '../constants/category';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Player from '../components/Player';
import { useDownload, useDownloadMediaUrl } from '../contexts/downloads.context';
import { useMediaServer } from '../contexts/desktop/mediaserver.context';
import { GraphType } from '@/core/constants/graphFactory';
import { useHideNavigation } from '@/contexts/nav.context';
import { useToast } from '@/contexts/toast.context';
import { BluetoothDeviceTypes } from '@/constants/bluetooth';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import useSetting from '@/components/Preferences/useSetting';
import { useFtms } from '@/hooks/useFtms';
import { getPreferredGraph } from '../utils/playerUtils';
import { useHR } from '@/contexts/heartRate.context';
import useDocumentTitle from '@rehooks/document-title';

const LoadingError = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

const OfflinePlayerPage = () => {
  useHideNavigation();

  const { id: idParam, mediaType: mediaTypeParam } = useParams();
  const id = idParam as string | number;
  const mediaType: mediaType = mediaTypeParam as mediaType;
  const electronMediaUrl = useDownloadMediaUrl(id, mediaType);
  const [_, setGraphType] = useSetting('graph_type');

  const { state: preferences } = usePreferences();
  const { state: mediaServer } = useMediaServer();

  const offlineTrainingClass = useDownload(id);
  useDocumentTitle(
    `${
      offlineTrainingClass?.trainingClass?.title ??
      offlineTrainingClass?.trainingClass?.subtitle ??
      'Clase offline'
    } | Bestcycling`,
  );

  const {
    state: { connected },
  } = useHR();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<WebappDownload | null>(null);
  const [error] = useState(null);
  const [store, setStore] = useState<any | null>(null); // TODO: add type for the training class that the player accepts
  const { state: profile } = useProfile();
  const { actions } = useBTContext();
  const { enabled, features, levelRange, forceAutoMode } = useFtms();
  const bikesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.Bike);
  const heartRatesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.HeartRate);
  if (bikesConnected.length > 0) {
    if (localStorage.getItem('wattsSetOnConnectedBike') == undefined) {
      setGraphType(GraphType.Watts);
      localStorage.setItem('wattsSetOnConnectedBike', 'true');
    }
  }

  useEffect(() => {
    setData(offlineTrainingClass ?? null);
    setIsLoading(false);
  }, [offlineTrainingClass]);

  // TODO: this can be done in a better way
  useEffect(() => {
    if (!data) return;
    const { trainingClass } = data;

    const _mediaType = mediaType;
    const media = data.downloadedMedia.find(m => m.type === _mediaType);
    const defaultDuration = preferences.defaultDuration;

    const mediaUrl = electronMediaUrl ?? media?.url;

    const parsedTrainingClass: TrainingClassParsed = {
      id: parseInt(trainingClass.id),
      title: trainingClass.title,
      trainer: trainingClass.trainer,
      progression: trainingClass.progression,
      progression_watts: trainingClass.progression_watts ? trainingClass.progression_watts : null,
      delay_seconds:
        trainingClass.delay_seconds && _mediaType != 'music' ? trainingClass.delay_seconds : 0,
      duration_seconds: trainingClass.duration_seconds,
      duration_training: trainingClass.duration_training,
      official: trainingClass.official,
      url: mediaUrl!,
      isNew: Boolean(trainingClass.is_new),
      duration_class:
        trainingClass.category_nr == Category.Mind && defaultDuration != null
          ? defaultDuration * 60
          : null,
      hasBackground: Boolean(trainingClass.has_background),
      isNewBlack: Boolean(trainingClass.is_black),
      category: trainingClass.category_nr,
      need_progression: true,
      mediaType: (_mediaType.match(/video/) ? 'video' : _mediaType) as mediaType,
      image: trainingClass.cover!,
      image_graph: trainingClass.graph ?? '',
      // show_effort:
      //   trainingClassProps?.category_nr == Category.Cycling && trainingClassProps?.show_effort,
    };
    const userSettings = preferences;

    let options = preferences.playerPreferences;

    options = {
      ...options,
      video: {
        ...options.video,
        logo: userSettings.getLogoForCategory(trainingClass.category_nr),
      },
      graph: {
        ...options.graph,
        preferred_graph_type: getPreferredGraph(preferences, data.trainingClass),
      },
      cardio: {
        ...options.cardio,
        enabled: !connected ? heartRatesConnected.length > 0 : connected,
      },
      ftms: {
        ...options.ftms,
        features: features,
        resistance_level_range: levelRange,
        ftp: profile?.ftp,
        enabled: enabled,
        autoMode: forceAutoMode(),
      },
    };

    if (trainingClass.category_nr == Category.Training && preferences.annotateResults) {
      options = {
        ...options,
        annotate_results: preferences.annotateResults,
      };
    }

    setStore({
      trainingClass: parsedTrainingClass,
      options: options,
    });
  }, [data?.id, features, mediaType]);

  useRedirectNotFound(store?.url);

  if (isLoading) return <LoadingIndicator />;

  if (error) return <LoadingError>No se puede acceder a la clase.</LoadingError>;

  if (store?.trainingClass && mediaServer.running) {
    return (
      <Player
        trainingClass={store.trainingClass}
        mediaType={mediaType}
        options={store.options}
        heartRate={preferences.heartRate}
      />
    );
  }
  return null;
};

export default OfflinePlayerPage;

const useRedirectNotFound = (url: string | null) => {
  // const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    if (!url) return;
    // On 404 error, redirect to onlinePlayer
    fetch(url).then(res => {
      if (res.status === 404) {
        showToast('No se ha encontrado el fichero de la clase', 'error');
        location.href = location.href.replace('offline/', '');
      }
    });
  }, [url]);
};
