import { NavLink } from 'react-router-dom';
import styles from './RoundedButton.module.scss';

interface ButtonProps {
  to?: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large' | 'normal';
  [key: string]: any;
}
export default function RoundedButton({
  to,
  onClick = () => {},
  variant = 'primary',
  size = 'normal',
  children,
  ...props
}: ButtonProps) {
  const className = `${styles.rounded_button} ${variant ? styles[variant] : ''} ${
    size ? styles[size] : ''
  }`;

  return to ? (
    <NavLink to={to} className={className} {...props}>
      {children}
    </NavLink>
  ) : (
    <button className={className} onClick={onClick} {...props}>
      {children}
    </button>
  );
}
