import { useMemo } from 'react';
import style from './UserProgressBar.module';

export default function ProgressBar({ seconds, total, color = 'transparent' }) {
  const userProgress = useMemo(() => {
    if (total == 0) return 0;

    if (seconds) {
      const progress = Math.min((seconds / total) * 100, 100);
      const nearestProgress = Math.ceil(progress / 10) * 10;
      return nearestProgress;
    }
    return 0;
  }, [seconds, total]);

  return (
    <div className={`${style.userProgressBar} ${style[`progress${userProgress}`]}`}>
      <div className={style.progress} style={{ backgroundColor: color }}></div>
    </div>
  );
}
