import Modal from '../Modal';

import styles from './AppsDownloadModal.module';

export default function AppsDownloadModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <div className={styles.appdownload}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.logo}></div>
          </div>
          <div className={styles.body}>
            <p>
              Descarga nuestra aplicación <span lang="en">Bestcycling</span> para obtener una
              experiencia mucho más amplia.
            </p>
            <p>
              Podrás disfrutar de nuestros planes de entrenamiento personalizados, configurar tu
              plan de alimentación ¡y mucho más!
            </p>
          </div>

          <div className={styles.footer}>
            <a
              className={`${styles.mobile_app_link} ${styles.google_play}`}
              href={config.STORE_ANDROID_URL}
              target="_blank"
              rel="noreferrer"
            ></a>
            <a
              className={`${styles.mobile_app_link} ${styles.app_store}`}
              href={config.STORE_IOS_URL}
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
        </div>
        <div className={styles.image}></div>
      </div>
    </Modal>
  );
}
