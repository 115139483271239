import { HumanLifePoints, LifePointType } from '@/core/constants/LifePoints';
import styles from './LifePoint.module.scss';

export interface LifePointOptions {
  type: LifePointType;
  value: number;
  showOnEmpty?: boolean;
  size?: "small" | "normal",
}

const LifePoint = ({
  type,
  value,
  showOnEmpty = false,
  size = "normal",
  ...props
}: LifePointOptions) => {
  if (!value && !showOnEmpty) return null;

  return (
    <div
      className={`${styles.point} ${styles[type]} ${styles[size]}`}
      {...props}
      title={`${HumanLifePoints[type]} ${value}`}
    >
      <div className={styles.value}>{value}</div>
    </div>
  );
}

export default LifePoint;