import { useMutation } from '@tanstack/react-query';
import { queryClient, useApi } from '@/api/ApiProvider';
import { useToast } from '@/core/contexts/toast.context';
import { useRef } from 'react';

const useBulkDeleteMutation = () => {
  const { mutateApi } = useApi();
  const { showToast, hideToast } = useToast();

  const toastIdRef = useRef<string | null>(null);
  const latestData = useRef<any[]>([]);

  return useMutation(
    async (ids: string[]) => {
      if (!ids.length) return;

      const res = await mutateApi(
        ['training_classes', 'bulk_delete'],
        ids.map(e => ({ id: e })),
        {
          method: 'DELETE',
        },
      );

      if (res.error) throw new Error(res.error);
    },
    {
      onMutate: async (trainingClassIds: (string | number)[]) => {
        if (!trainingClassIds || !trainingClassIds.length) {
          showToast('Error desconocido', 'error');
          return;
        }

        // OPTIMISTIC UPDATE
        await queryClient.cancelQueries(['training_classes', { official: false }]);

        const dataFromQueries = queryClient.getQueriesData([
          'trianing_classes',
          { official: false },
        ]);

        latestData.current = [];
        latestData.current = structuredClone(dataFromQueries);

        dataFromQueries.forEach((query: any) => {
          // FIRST ELEMENT IS THE QUERY KEYS
          const [key, data] = query as QueryData<TrainingClass>;

          if ('pages' in data) {
            data.pages.forEach((page: any) => {
              page.data = page.data.filter(
                (tc: TrainingClass) => !trainingClassIds.includes(tc.id),
              );
            });
          } else if ('data' in data) {
            data.data = data.data.filter((tc: TrainingClass) => !trainingClassIds.includes(tc.id));
          }

          queryClient.setQueryData(key, data);
        });

        toastIdRef.current = showToast(
          trainingClassIds.length > 1 ? 'Clases eliminadas' : 'Clase eliminada',
          'success',
        );
      },
      onError: async err => {
        await queryClient.cancelQueries(['my_training_classes']);

        latestData.current.forEach(([key, data]) => {
          console.log('key', key);
          console.log('data', data);
          queryClient.setQueryData(key, data);
        });

        hideToast(toastIdRef.current!);
        showToast('Ha ocurrido un error al eliminar clases', 'error');
      },
    },
  );
};

export default useBulkDeleteMutation;
