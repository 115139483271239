import { AsyncFilter } from './Filter';

export function TrainingMaterialFilter() {
  return (
    <AsyncFilter
      filterType="training_materials"
      filterKey="training_materials"
      label="name"
      tag="MATERIALES"
    />
  );
}
