import { useState } from 'react';
import styles from './GraphImage.module.scss';

import { useZoneColors } from '../../contexts/app.context';

type GraphImageProps = {
  graph: string;
  title?: string;
}

const GraphImage = ({ graph, title }: GraphImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const colors = useZoneColors();

  return (
    <div className={`${styles.graph} ${loaded ? styles.loaded : ''}`}>
      <div className={styles.zone} style={{ backgroundColor: colors[4] }}></div>
      <div className={styles.zone} style={{ backgroundColor: colors[3] }}></div>
      <div className={styles.zone} style={{ backgroundColor: colors[2] }}></div>
      <div className={styles.zone} style={{ backgroundColor: colors[1] }}></div>
      <div className={styles.zone} style={{ backgroundColor: colors[0] }}></div>
      <img src={graph} alt={title} onLoad={setLoaded.bind(null, true)} />
    </div>
  );
}

export default GraphImage;