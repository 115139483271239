import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import TrainingsList from '../Trainings/TrainingsList';
import { useFetchLast10Trainings } from '@/core/hooks/apiHooks/useFetchTrainings';
import ToggeableContent from './ToggeableContent';
import { useLocation } from 'react-router';

export default function Trainings({ trainingClass }: { trainingClass: TrainingClass }) {
  const { isLoading, isError, data, isSuccess } = useFetchLast10Trainings(trainingClass.id);
  const { pathname } = useLocation();
  const isPathBestPro = pathname.includes('bestpro');

  if (isLoading) return <LoadingIndicator />;
  if (isError) return null;
  const trainings = data || [];

  return (
    <ToggeableContent header={isPathBestPro ? 'USOS' :'RESULTADOS'} canToggle show={true}>
      {
        isSuccess && (
          <div>
            <TrainingsList trainings={trainings} />
          </div>
        )
      }
    </ToggeableContent>
  )
}
