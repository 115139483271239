import { forwardRef } from 'react';
import styles from './FormSelect.module';

const FormSelect = forwardRef((props, ref) => {
  return <select ref={ref} className={styles.formSelect} {...props}></select>;
});

FormSelect.displayName = 'FormSelect';

export default FormSelect;
