import Modal from '../Modal';

import MediaAsset, { MediaAssetState } from './MediaAsset';
import { SwitchCheckBox } from '../FormControls';

import styles from './PlayModal.module.scss';
import Category from '../../constants/category';
import { useDownload, useDownloading, useDownloadStatus } from '../../contexts/downloads.context';
import useSetting from '../Preferences/useSetting';
import { useMemo, useState } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';

const getMediaState = (media: DownloadedMedia | undefined): MediaAssetState => {
  if (!media) return 'download';

  const { progress } = media;

  if (progress == undefined) {
    return 'queued';
  }

  if (progress >= 0 && progress < 100) {
    return 'downloading';
  }

  if (progress == 100) {
    return 'downloaded';
  }

  return null;
};

interface MediaDownloadStatusProps {
  id: string | number;
  mediaType: mediaType;
}
export const MediaDownloadStatus = ({ id, mediaType }: MediaDownloadStatusProps) => {
  const downloading = useDownloading();
  // const state = getMediaState(media) as MediaAssetState;
  const downloadStatus = useDownloadStatus(id, mediaType);

  if (downloadStatus === 'downloading') {
    return (
      <div className={styles.stateoffline}>
        <ProgressBar
          percent={downloading?.progress?.toFixed(0) ?? 0}
          color="#ff7900"
          showPercent={true}
          styles={{
            marginTop: '10px',
            color: 'white',
            width: '90%',
          }}
        />
      </div>
    );
  }

  if (downloadStatus === 'downloaded') {
    return (
      <div className={`${styles.stateoffline}`}>
        <span className={`${styles.download}`}>Descargada</span>
      </div>
    );
  }

  if (downloadStatus === 'queued') {
    return (
      <div className={`${styles.stateoffline}`}>
        <span className={`${styles.cola}`}>En cola</span>
      </div>
    );
  }

  return null;
};

interface DownloadModalProps {
  trainingClass: TrainingClass;
  isOpen: boolean;
  onClose: () => void;
}

export default function DownloadModal({ trainingClass, isOpen, onClose }: DownloadModalProps) {
  const download = useDownload(trainingClass.id);

  const downloadStatusHD = useDownloadStatus(trainingClass.id, 'video_hd');
  const downloadStatusSD = useDownloadStatus(trainingClass.id, 'video_sd');
  const [videoHDSetting] = useSetting<boolean>('videoHD');

  const isVideoDownloaded =
    downloadStatusHD === 'downloaded' ||
    downloadStatusSD === 'downloaded' ||
    downloadStatusHD === 'downloading' ||
    downloadStatusSD === 'downloading';

  const videoDownload = download?.downloadedMedia?.find(
    d => d.type === 'video_hd' || d.type === 'video_sd',
  );
  const canSetVideoQuality = !videoDownload;

  const [videoHD, setVideoHD] = useState<boolean>(
    videoDownload ? (videoDownload.type === 'video_hd' ? true : false) : videoHDSetting,
  );

  const { media } = trainingClass;
  const isMind = trainingClass.category_nr === Category.Mind;

  const videoMedia = useMemo(() => {
    return media?.find(ma => ma.type === (videoHD ? 'video_hd' : 'video_sd'));
  }, [videoDownload, media, videoHD]);

  const musicMedia = useMemo(() => {
    return media?.find(ma => ma.type === 'music');
  }, [media]);

  const audioMedia = useMemo(() => {
    return media?.find(ma => ma.type === 'audio');
  }, [media]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <div className={styles.title}>Descargar clase</div>

      <div className={styles.lineDivider}></div>

      <label className={styles.switch_container}>
        <div className={styles.switch_label}>
          <div className={styles.span0}>Calidad HD</div>
          <div className={styles.span1}>Calidad de descarga de vídeos</div>
        </div>

        <div className={styles.switch_switch}>
          <SwitchCheckBox
            label="Seleccionar calidad de vídeo"
            defaultChecked={videoHD}
            disabled={!canSetVideoQuality}
            onChange={() => setVideoHD(!videoHD)}
          />
        </div>
      </label>

      <div className={styles.medias_container}>
        {videoMedia ? (
          <div className={styles.media}>
            <MediaAsset
              trainingClass={trainingClass}
              media={videoMedia}
              isMind={isMind}
              state={getMediaState(download?.downloadedMedia?.find(ma => ma.type.match(/video_/)))}
              action="download"
            />
          </div>
        ) : null}
        {!isMind && musicMedia ? (
          <div className={styles.media}>
            <MediaAsset
              trainingClass={trainingClass}
              media={musicMedia}
              isMind={isMind}
              state={getMediaState(download?.downloadedMedia?.find(ma => ma.type.match(/music/)))}
              action="download"
            />
          </div>
        ) : null}

        {audioMedia ? (
          <div className={styles.media}>
            <MediaAsset
              trainingClass={trainingClass}
              media={audioMedia}
              isMind={isMind}
              state={getMediaState(download?.downloadedMedia?.find(ma => ma.type.match(/audio/)))}
              action="download"
              showTimeSelector={false}
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
