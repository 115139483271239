import styles from './TagsList.module.scss';
import Tag from './Tag';

export default function TagsListAdded({ tagList = [], onSelect }) {
  return (
    <div className={styles.tagsAdded}>
      {tagList.map((element, index) => {
        return (
          <div key={index} className={`${styles.Tag} ${styles.tagList}`}>
            <Tag
              type="button"
              nohover="true"
              key={index}
              onClick={() => onSelect(element)}
              variant="w-color"
              closeTag={true}
              colorButton={
                element.properties && element?.properties?.color ? element?.properties?.color : ''
              }
            >
              {' '}
              {element.name}
            </Tag>
          </div>
        );
      })}
    </div>
  );
}
