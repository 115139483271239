import { useQuery } from '@tanstack/react-query';
import FindClassesPage from './FindClassesPage';
import { useApi } from '../api/ApiProvider';
import { useRemoteConfig } from '../contexts/remote.context';
import { useUser } from '../contexts/app.context';
import { useEffect, useState } from 'react';

const WeeklyClassesPage = () => {
  const { fetchApi } = useApi();
  const { isFreeAccessEnabled } = useRemoteConfig();
  const { state: user } = useUser();
  const [weeklyClasses, setWeeklyClasses] = useState<number[]>();

  const { data, isSuccess } = useQuery(['front'], () =>
    fetchApi([
      'front',
      {
        free_enabled: !user.isSubscribed() && isFreeAccessEnabled() ? '1' : '0',
        video_progress: '1',
      },
    ]),
  );

  useEffect(() => {
    if (!isSuccess) return;
    if (data) {
      const resources = data.data.resources;
      const weeklyClassesResource = resources.find((r: any) => r.type == 'training_classes');
      if (weeklyClassesResource) {
        setWeeklyClasses(weeklyClassesResource.filters.id);
      }
    }
  }, [data, isSuccess]);

  return (
    <>
      {weeklyClasses && (
        <FindClassesPage
          section="weekly"
          official={true}
          weeklyClasses={weeklyClasses.join(', ')}
        />
      )}
    </>
  );
};

export default WeeklyClassesPage;
