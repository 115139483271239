import Modal from '../Modal';
import LoadingIndicator from '../LoadingIndicator';
import stylesBt from './BluetoothPrefs.module.scss';
import Button from '../UI/Button';
import BluetoothListDevices from '../Bluetooth/BluetoothListDevices';

interface BTConnectModalProps {
  isOpen: boolean;
  selectDevice: (id: BTDevice | BluetoothDevice) => void;
  onCancel: () => void;
  btDevicesList: BTDevice[] | BluetoothDevice[];
  noDevices?: boolean;
  isLoading?: boolean;
  onRetry: () => void;
}

export default function BTConnectModal({
  isOpen,
  selectDevice,
  onCancel,
  btDevicesList,
  noDevices,
  isLoading,
  onRetry,
}: BTConnectModalProps) {
  console.log(isLoading, btDevicesList);
  return (
    <Modal isOpen={isOpen} className={stylesBt.modal} onRequestClose={onCancel}>
      <h1 className={stylesBt.title}>
        {noDevices
          ? 'No se han encontrado dispositivos'
          : isLoading
          ? 'Buscando dispositivos...'
          : 'Dispositivos encontrados'}
      </h1>
      <p className={stylesBt.description}>
        {noDevices
          ? 'No hemos encontrado ningún dispositivo Bluetooth que puedas usar con Bestcycling. Comprueba lo siguiente y vuelve a intentar la búsqueda: '
          : !isLoading
          ? 'Estos son los dispositivos que hemos encontrado. Selecciona el que deseas usar.'
          : ''}
      </p>
      <div className={stylesBt.deviceList}>
        {isLoading && !noDevices && <LoadingIndicator />}

        <>
          {noDevices && (
            <>
              <p className={stylesBt.description}>
                1. Tu dispositivo Bluetooth tiene batería, está encendido y es compatible.
              </p>
              <p className={stylesBt.description}>
                2. Tu dispositivo Bluetooth no está conectado a otro dispositivo que impide la
                conexión con el PC.
              </p>
            </>
          )}
          {!!btDevicesList.length && !noDevices && (
            <BluetoothListDevices
              devices={btDevicesList as BTDevice[]}
              selectDevice={selectDevice}
              isPrefs={true}
            />
          )}
        </>
      </div>
      {noDevices && (
        <footer>
          <Button onClick={onRetry} size="small" style={{ height: '38px' }}>
            REINTENTAR
          </Button>
        </footer>
      )}
    </Modal>
  );
}
