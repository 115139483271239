const LevelClass = Object.freeze({
  beginner: '10',
  medium: '20',
  high: '30',
});

export default Object.freeze({
  cycling: [
    {
      title: 'TODOS LOS NIVELES',
      levelClass: null,
      subtitle: 'TODOS LOS NIVELES',
      description: 'Si estás buscando una clase y no conoces el nivel, aquí las tienes todas.',
    },
    {
      title: 'NIVEL 1',
      levelClass: LevelClass.beginner,
      subtitle: 'INICIACIÓN Y RECUPERACIÓN',
      description:
        'Clases perfectas para iniciarse, si estás bajo de forma, o para días de recuperación.',
    },
    {
      title: 'NIVEL 2',
      levelClass: LevelClass.medium,
      subtitle: 'ENTRENAMIENTO Y DIVERSIÓN',
      description:
        'Nuestras preferidas, mezcla de entrenamientos controlados y diversión con buena música.',
    },
    {
      title: 'NIVEL 3',
      levelClass: LevelClass.high,
      subtitle: 'INTENSIDAD E INTERVALOS',
      description:
        'Para los días en los que buscas trabajar duro y hacer entrenamientos interválicos.',
    },
  ],
  walking: [
    {
      title: 'TODOS LOS NIVELES',
      levelClass: null,
      subtitle: 'TODOS LOS NIVELES',
      description: 'Si estás buscando una clase y no conoces el nivel, aquí las tienes todas.',
    },
    {
      title: 'NIVEL 1',
      levelClass: LevelClass.beginner,
      subtitle: 'INICIACIÓN Y RECUPERACIÓN',
      description:
        'Clases perfectas para iniciarse, si estás bajo de forma, o para días de recuperación.',
    },
    {
      title: 'NIVEL 2',
      levelClass: LevelClass.medium,
      subtitle: 'ENTRENAMIENTO Y DIVERSIÓN',
      description:
        'Nuestras preferidas, mezcla de entrenamientos controlados y diversión con buena música.',
    },
    {
      title: 'NIVEL 3',
      levelClass: LevelClass.high,
      subtitle: 'INTENSIDAD E INTERVALOS',
      description:
        'Para los días en los que buscas trabajar duro y hacer entrenamientos interválicos.',
    },
  ],
  running: [
    {
      title: 'TODOS LOS NIVELES',
      levelClass: null,
      subtitle: 'TODOS LOS NIVELES',
      description: 'Si estás buscando una clase y no conoces el nivel, aquí las tienes todas.',
    },
    {
      title: 'NIVEL 1',
      levelClass: LevelClass.beginner,
      subtitle: 'ANDAR Y TROTAR',
      description:
        'Clases donde únicamente andamos y trotamos. Para días donde quieres desconectar o entrenar suave.',
    },
    {
      title: 'NIVEL 2',
      levelClass: LevelClass.medium,
      subtitle: 'TROTAR Y CORRER',
      description:
        'Entrenamientos variados y divertidos. Perfectos para todos los días y para iniciarse en el running.',
    },
    {
      title: 'NIVEL 3',
      levelClass: LevelClass.high,
      subtitle: 'CORRER Y ESPRINTAR',
      description:
        'Si buscas alta intensidad, esforzarte al máximo, entrenamientos interválicos, estas son tus clases.',
    },
  ],
  training: [
    {
      title: 'TODOS LOS NIVELES',
      levelClass: null,
      subtitle: 'TODOS LOS NIVELES',
      description: 'Si estás buscando una clase y no conoces el nivel, aquí las tienes todas.',
    },
    {
      title: 'NIVEL 1',
      levelClass: LevelClass.beginner,
      subtitle: 'GIMNASIA DE MANTENIMIENTO',
      description:
        'Entrenamientos pensados para cuidar tu salud, aptos para cualquier persona, da igual edad o estado de forma.',
    },
    {
      title: 'NIVEL 2',
      levelClass: LevelClass.medium,
      subtitle: 'TONIFICACIÓN Y FUERZA',
      description:
        'Entrenamientos para potenciar tu musculatura, tonificar todo tu cuerpo, sin impacto ni cardio intenso.',
    },
    {
      title: 'NIVEL 3',
      levelClass: LevelClass.high,
      subtitle: 'ALTA INTENSIDAD HIIT',
      description:
        'Los entrenamientos más intensos para quemar grasa, tonificar tu cuerpo y mejorar tu resistencia.',
    },
  ],
  balance: [
    {
      title: 'TODOS LOS NIVELES',
      levelClass: null,
      subtitle: 'TODOS LOS NIVELES',
      description: 'Si estás buscando una clase y no conoces el nivel, aquí las tienes todas.',
    },
    {
      title: 'NIVEL 1',
      levelClass: LevelClass.beginner,
      subtitle: 'ESPALDA SANA Y MOVILIDAD',
      description:
        'Clases pensadas para cuidar la espalda, mejorar la movilidad y ganar flexibilidad. Aptas para todos.',
    },
    {
      title: 'NIVEL 2',
      levelClass: LevelClass.medium,
      subtitle: 'FLEXIBILIDAD INTERMEDIA',
      description:
        'Entrenamientos de core y flexibilidad basados en Yoga y Pilates perfectos para fortalecer tu centro y ganar flexibilidad.',
    },
    {
      title: 'NIVEL 3',
      levelClass: LevelClass.high,
      subtitle: 'FLEXIBILIDAD AVANZADA',
      description:
        'Entrenamientos de core y flexibilidad basados en Yoga y Pilates que buscan potenciar al máximo la flexibilidad.',
    },
  ],
  mind: [],
});
