import { getAnalytics, logEvent } from 'firebase/analytics';
import ANALYTICS_EVENTS, { getCategoryClassSharedEvent } from '../constants/analytics_events';

const source = 'Particular';
const medium = 'Clase';
const campaign = 'compartirClase';

const ShareTrainingClassModel = (trainingClass: TrainingClass, username: string): ShareModel => {
  const getMessage = (link: string) => {
    return `¡Hola! ${username} ha pensado que esta clase te puede gustar, la clase se llama ${
      trainingClass.subtitle || trainingClass.title
    } de ${trainingClass.trainer?.full_name}. Puedes hacerla pinchando este enlace: ${link}`;
  };

  const getTitle = () => {
    return trainingClass.subtitle || trainingClass.title;
  };

  const getDescription = () => {
    return `${username} te recomienda esta clase`;
  };

  const getImage = () => {
    return trainingClass.cover || '';
  };

  const getLongLink = () => {
    return `https://bestcycling.com/app/#/trainingclass/${trainingClass.id}?training_class_id=${trainingClass.id}&utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;
  };

  const getShortLink = () => {
    return `https://bestcycling.com/app#/trainingclass/${trainingClass.id}`;
  };

  const sendFirebaseEvents = () => {
    const analytics = getAnalytics();
    logEvent(analytics, ANALYTICS_EVENTS.CLASS_SHARED);
    logEvent(analytics, getCategoryClassSharedEvent(trainingClass.category_nr), {
      item_id: trainingClass.id,
      item_name: trainingClass.title,
    });
  };

  return {
    getMessage,
    getImage,
    getTitle,
    getDescription,
    getLongLink,
    getShortLink,
    sendFirebaseEvents,
  };
};

export default ShareTrainingClassModel;
