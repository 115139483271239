import MoveIcon from '../Icons/MoveIcon';
import styles from './ButtonMoveTag.module.scss';

export default function ButtonMoveTag({
  handleEnterMouse,
  handleLeaveMouse,
  width = 18,
  height = 18,
}) {
  return (
    <div
      className={styles.buttonMoveTag}
      onMouseEnter={handleEnterMouse}
      onMouseLeave={handleLeaveMouse}
    >
      <MoveIcon width={width} height={height} />
    </div>
  );
}
