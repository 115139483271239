import styles from './VideoImage.module';

export default function VideoImage({ url, title }) {
  return (
    <div
      className={styles.video_image}
      style={{
        backgroundImage: `url(${url})`,
      }}
      alt={title}
    ></div>
  );
}
