import styles from './ConnectingBikeModal.module.scss';
import checkronRight from '../../../../../src/img/chevron-right.svg';

interface ConfigFtpRowProps {
  label: string;
  value: string | number;
  onClick: (evt: React.MouseEvent<HTMLButtonElement>, label: string) => void;
}

export default function ConfigFtpRow({ label, value, onClick }: ConfigFtpRowProps) {
  return (
    <button className={styles.containerRow} onClick={evt => onClick(evt, label)}>
      <p className={styles.label}> {label}</p>
      <div className={styles.containerValue}>
        <p className={styles.value}>{value}</p>
        <img src={checkronRight} className={styles.back} />
      </div>
    </button>
  );
}
