import { useApi } from '@/core/api/ApiProvider';
import Button from '@/core/components/UI/Button';
import HeartEmoji from '@/img/surveys/HeartEmoji';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import styles from './NpsSurveyModal.module.scss';
import { useAnalyticsContext } from '@/core/contexts/analytics.context';
import ANALYTICS_EVENTS from '@/core/constants/analytics_events';

const numbers = new Array(11).fill(0);

type NpsSurveyModalInitialStateProps = {
  onConfirm(): void;
};

const NpsSurveyModalInitialState = (props: NpsSurveyModalInitialStateProps) => {
  const { mutateApi } = useApi();
  const { analytics, logEvent } = useAnalyticsContext();

  const [score, setScore] = useState<number>();
  const ref = useRef<HTMLTextAreaElement>(null);

  const sendNpsResponse = useMutation(
    (data: { score: number; feedback: string }) =>
      mutateApi(
        ['nps_surveys'],
        {
          score: data.score,
          feedback: data.feedback,
        },
        {
          method: 'POST',
        },
      ),
    {
      onSuccess: () => {
        logEvent(analytics, ANALYTICS_EVENTS.COMPLETO_NPS, {
          score: score,
          feedBack: ref.current?.value || '',
        });
      },
    },
  );

  const getScoreReason = () => {
    if (score! < 7)
      return 'Lamentamos saber que tu experiencia no fue la esperada. ¿Nos cuentas más para poder arreglarlo y mejorar?';
    if (score! < 9)
      return 'Nos encantaría mejorar tu experiencia con nosotros. ¿En qué aspectos crees que podríamos mejorar?';

    return 'Muchas gracias por tu valoración. ¿Hay algo que te gustaría que hiciéramos para que Bestcycling fuera un poco mejor?';
  };

  const onNpsRate = () => {
    sendNpsResponse.mutate({ score: score!, feedback: ref.current?.value || '' });
    props.onConfirm();
  };

  return (
    <>
      <HeartEmoji width={50} />
      <span className={styles.title}>
        En una escala del 0 al 10, ¿qué probabilidades hay de que nos recomiendes a un amigo?
      </span>
      <div className={styles.numbers}>
        {numbers.map((_, index) => {
          return (
            <button
              key={index}
              onClick={() => setScore(index)}
              className={clsx(styles.number, score === index ? styles.active : null)}
            >
              <span className={styles.score}>{index}</span>
            </button>
          );
        })}
      </div>
      {score !== undefined && (
        <>
          <div className={styles.feedbackContainer}>
            <span className={styles.question}>{getScoreReason()}</span>
            <textarea
              ref={ref}
              className={styles.feedback}
              placeholder="Escribe tu respuesta..."
              rows={4}
            ></textarea>
          </div>
          <Button variant="primary" size="default" title="ENVIAR" onClick={onNpsRate}>
            ENVIAR
          </Button>
        </>
      )}
    </>
  );
};

export default NpsSurveyModalInitialState;
