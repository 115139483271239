import SharedTrainerHeader from '@/components/Bestpro/TrainingClasses/TrainingClass/SharedTrainerHeader';
import FreeIndicator from '../FreeIndicator';
import OfflineIndicator from '../OfflineIndicator';
import style from './TrainingClassHeader.module.scss';

export default function TrainingClassHeader({
  trainingClass,
  isModeSelectMultiple = false,
  needEditClassOptions,
}: {
  trainingClass: TrainingClass;
  isModeSelectMultiple: boolean;
  needEditClassOptions?: boolean;
}) {
  const { official, title, subtitle, short_title, trainer, sharing_user } = trainingClass;

  return (
    <div className={style.container}>
      <div className={style.title}>
        <h3>{official ? `${short_title} / ${subtitle}` : title}</h3>
        {sharing_user && !official ? (
          <SharedTrainerHeader user={sharing_user} />
        ) : (
          <h1>{trainer?.full_name || ''}</h1>
        )}
      </div>
      <OfflineIndicator
        trainingClass={trainingClass}
        className={style.offlineIndicator}
        style={{ marginRight: isModeSelectMultiple || needEditClassOptions ? 30 : 0 }}
      />
      <FreeIndicator trainingClass={trainingClass} />
    </div>
  );
}
