import { useRef, useEffect, useCallback } from 'react';

export default function Graph({ trainingClass, options, height, mode }) {
  const graphRef = useRef(null);

  const postMessage = useCallback(
    message => {
      const graph = graphRef.current;
      graph.contentWindow.postMessage(message, '*');
    },
    [graphRef],
  );

  useEffect(() => {
    const traininClassToGraph = { ...trainingClass, mode: mode };
    const graph = graphRef.current;

    // callback que envía mensaje datos de clase y opciones
    const onLoad = () =>
      postMessage({ type: 'trainingClass', trainingClass: traininClassToGraph, options }, '*');

    // Iniciamos iframe
    graph.onload = onLoad;
    graph.src = 'graph.html';
  }, [trainingClass, options]);

  return (
    <iframe
      id="graph"
      ref={graphRef}
      allow="autoplay 'self'; fullscreen"
      style={{ width: '100%', height: height, border: 'none' }}
    ></iframe>
  );
}
