import settingsSensei from '../../../../img/preferences/settings-sensei.svg';

import settingsNutrition from '../../../../img/preferences/settings-nutrition.svg';

import { useAppContext } from '@/contexts/app.context';
import { useForm } from 'react-hook-form';
import { FormGroup, FormInput, FormLabel, FormSelect } from '../FormControls';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import Divider from '../Navigation/Divider';
import AvatarUpload from '../Preferences/AvatarUpload';
import SectionSubtitle from '../SectionSubtitle';
import SectionTitle from '../SectionTitle';
import Button from '../UI/Button';
import profileStyles from '../Preferences/ProfilePreferences.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { COUNTRIES } from '@/constants/countries';

const EditProfileModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const {
    state: { user, profile },
  } = useAppContext();
  const { mutateApi } = useApi();
  const client = useQueryClient();

  const getCountryCode = (country: string | undefined) => {
    if (!country) return;
    const searchCountry = COUNTRIES.find(c => c.name == country);
    return searchCountry ? searchCountry.iso3 : 'ESP';
  };

  const userMutation = useMutation({
    mutationFn: data => mutateApi(['user'], data, { method: 'PATCH' }),
    onSuccess: () => {
      client.invalidateQueries(['user']);
    },
  });

  const profileMutation = useMutation({
    mutationFn: data => mutateApi(['profile'], data, { method: 'PATCH' }),
    onSuccess: () => {
      client.invalidateQueries(['profile']);
    },
  });

  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    const { country } = data.profile;
    const profileToUpdate = { ...data.profile, country_code: getCountryCode(country) };
    userMutation.mutateAsync(data.user);
    profileMutation.mutateAsync(profileToUpdate);
    onRequestClose();
  };

  const isSubmitting = userMutation.isLoading || userMutation.isLoading;

  return (
    <Modal
      isOpen={isOpen}
      style={{ maxHeight: 'calc(100vh - 40px)', marginTop: '20px' }}
      onRequestClose={onRequestClose}
    >
      <div className={profileStyles.container}>
        <div className={profileStyles.header}>
          <SectionTitle style={{ marginTop: 0 }}>
            <center>Editar perfil</center>
          </SectionTitle>

          <center>
            <AvatarUpload user={user} />
          </center>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={profileStyles.form}>
            <SectionSubtitle style={{ marginTop: '45px', marginBottom: '15px' }}>
              Tus datos
              <Divider />
            </SectionSubtitle>

            <div style={{ overflowY: 'scroll' }}>
              <div className={profileStyles.grid}>
                <FormGroup>
                  <FormLabel>Nombre</FormLabel>
                  <FormInput
                    type="text"
                    defaultValue={user?.full_name || ''}
                    {...register('user[full_name]')}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Email</FormLabel>
                  <FormInput
                    type="email"
                    defaultValue={user?.login || ''}
                    {...register('user[login]')}
                    readOnly
                    disabled
                    style={{ color: '#747474' }}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Sexo</FormLabel>
                  <FormSelect
                    defaultValue={user?.sex || ''}
                    {...register('user[sex]')}
                    disabled={isSubmitting}
                  >
                    <option></option>
                    <option value="m">Hombre</option>
                    <option value="f">Mujer</option>
                  </FormSelect>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Fecha de nacimiento</FormLabel>
                  <FormInput
                    type="date"
                    defaultValue={user?.birthday || ''}
                    title="Fecha de nacimiento"
                    {...register('user[birthday]')}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </div>

              <SectionSubtitle style={{ marginTop: '45px', marginBottom: '15px' }}>
                Lugar de entrenamiento
                <Divider />
              </SectionSubtitle>

              <div className={profileStyles.grid}>
                <FormGroup>
                  <FormLabel>País</FormLabel>
                  <FormSelect
                    defaultValue={profile?.country || ''}
                    title="País"
                    {...register('profile[country]')}
                  >
                    <option value="España"> España</option>
                    <option disabled={true} value="---------------">
                      ---------------
                    </option>
                    {COUNTRIES.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Ciudad</FormLabel>
                  <FormInput
                    type="text"
                    defaultValue={profile?.city || ''}
                    title="Ciudad"
                    {...register('profile[city]')}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Dirección</FormLabel>
                  <FormInput
                    type="text"
                    defaultValue={profile?.address || ''}
                    title="Dirección"
                    {...register('profile[address]')}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Código postal</FormLabel>
                  <FormInput
                    type="text"
                    defaultValue={profile?.postal_code || ''}
                    title="Código postal"
                    {...register('profile[postal_code]')}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </div>
            </div>
          </div>

          <SectionSubtitle style={{ marginTop: '45px', marginBottom: '15px' }}>
            Configuración
            <Divider />
          </SectionSubtitle>

          <div
            className={profileStyles.grid}
            style={{
              margin: '1em 0',
            }}
          >
            <Button
              variant="secondary"
              size="small"
              to={'/sensei/config/0'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={settingsSensei}
                alt="Configurar sensei"
                style={{ height: '15px', margin: '0 10px' }}
              />
              CONFIGURAR SENSEI
            </Button>
            <Button
              variant="secondary"
              size="small"
              to={'/nutrition/config'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={settingsNutrition}
                alt="Nutrición"
                style={{ height: '15px', margin: '0 10px' }}
              />{' '}
              CONFIGURAR NUTRICIÓN
            </Button>
          </div>

          <center style={{ marginTop: '30px' }}>
            {userMutation.isLoading || profileMutation.isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button type="submit">GUARDAR CAMBIOS</Button>
            )}
          </center>
        </form>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
