const key = 'firstExperience';

const useFirstExperience = () => {
  const deleteExperience = () => {
    sessionStorage.removeItem(key);
  };

  const checkAsFirstExperience = () => sessionStorage.setItem(key, 'true');

  const hasFirstExperience = !!sessionStorage.getItem(key);

  return {
    hasFirstExperience,
    deleteExperience,
    checkAsFirstExperience,
  };
};

export default useFirstExperience;
