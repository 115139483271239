import { useApi } from '@/core/api/ApiProvider';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const CommentableTypes = {
  'Nutrition::Recipe': 'Nutrition::Recipe',
  TrainingClass: 'TrainingClass',
  Default: 'TrainingClass',
};

type useCommentsProps = {
  id: number | string;
  type: string;
}

const useComments = ({ id, type }: useCommentsProps) => {
  const commentableId = id;
  const commentableType = CommentableTypes[type] || CommentableTypes.Default;
  const { fetchApi, getNextPageParam } = useApi();

  const filters = {
    commentable_id: commentableId,
    commentable_type: commentableType,
  };

  const query = useInfiniteQuery({
    queryKey: ['comments', filters],
    queryFn: async ({ pageParam }) => {
      return fetchApi([
        'comments',
        {
          filters,
          cursor: pageParam,
        },
      ]);
    },
    getNextPageParam: lastPage => getNextPageParam(lastPage),
  });

  const dupData = query.data?.pages?.map(page => page.data).flat();

  const nonDupData = dupData?.filter(
    (item, index) => dupData.map(v => v.id).indexOf(item.id) === index,
  );

  useEffect(() => {
    if (!query.isFetching && !query.isLoading && query.data) {
      if (query.data.pages[query.data.pages.length - 1].data.length != 0) query.fetchNextPage();
    }
  }, [query.isFetching]);

  return {
    ...query,
    comments: nonDupData,
    query: query,
  };
}

export default useComments;