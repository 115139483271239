import useDetectConnection from '@/hooks/useDetectConnection';

import { useEffect, useContext, createContext, useReducer, ReactNode } from 'react';

import { IShellContext, ShellAction, ShellState } from './shell.types';
import useServiceWorkerUpdater from '../hooks/useServiceWorkerUpdater';

const initialState = {
  desktop: false,
  electron: false,
  nwjs: false,
  followLinks: false,
  online: navigator.onLine,
  offline: !navigator.onLine,
};

const ShellContext = createContext<IShellContext>({ state: initialState, dispatch: () => {} });

const shellReducer = (state: ShellState, action: ShellAction) => {
  switch (action.type) {
    case 'shell-electron':
      return { ...state, desktop: true, electron: true };
    case 'shell-desktop':
      return { ...state, desktop: true, nwjs: false };
    case 'shell-nwjs':
      return { ...state, desktop: true, nwjs: true };
    case 'shell-web':
      return { ...state, desktop: false, nwjs: false };
    case 'offline':
      return { ...state, online: false, offline: true };
    case 'online':
      return { ...state, online: true, offline: false };
    default:
      throw new Error(`Shell.Context action not found`);
  }
};

export const ShellProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(shellReducer, initialState);
  const connection = useDetectConnection();

  useEffect(() => {
    if (connection === 'online') dispatch({ type: 'online' });
    if (connection === 'offline') dispatch({ type: 'offline' });
  }, [connection]);

  const { dialog } = useServiceWorkerUpdater();

  useEffect(() => {
    const receivedMessage = (event: any) => {
      if (!event.data) return;
      const { type, value } = event.data;

      if (type == 'shell' && value === 'desktop') {
        dispatch({ type: 'shell-desktop' });
      }
      if (type == 'shell' && value === 'nwjs') {
        dispatch({ type: 'shell-nwjs' });
      } else if (type == 'shell' && value === 'web') {
        dispatch({ type: 'shell-web' });
      }
    };

    window.addEventListener('message', receivedMessage);

    return () => {
      window.removeEventListener('message', receivedMessage);
    };
  }, []);

  useEffect(() => {
    if (window.electronAPI) {
      dispatch({ type: 'shell-electron' });
    }
  }, []);

  useEffect(() => {
    if (window.electronAPI) {
      const settingsStr = localStorage.getItem('settings');
      if (!settingsStr) return;
      const settings = JSON.parse(settingsStr);
      Object.keys(settings).forEach(key => {
        const value = settings[key];
        window.electronAPI?.saveSetting(key, value);
      });
    }
  }, []);

  return (
    <ShellContext.Provider value={{ state, dispatch }}>
      {children}
      {dialog}
    </ShellContext.Provider>
  );
};

export const useShellContext = () => useContext(ShellContext);

export const useShell = () => {
  const { state } = useShellContext();

  const postMessage = (message: any) => window.top!.postMessage(message, '*');

  const toTrainingClass = (trainingClass: TrainingClass | object = {}) => {
    postMessage({ type: '@nwjs/training-class', payload: { trainingClass } });
  };

  const playTrainingClassMedia = (trainingClass = {}, media = {}) => {
    postMessage({
      type: '@nwjs/play-training-class',
      payload: { trainingClass, media },
    });
  };

  // Descarga una clase
  const downloadTrainingClassMedia = (
    trainingClass: TrainingClass,
    mediaType: { type: mediaType },
  ) => {
    const { training_class, ...training } = trainingClass.training ?? {};

    const electronTrainingClass = {
      ...trainingClass,
      training: {
        ...training,
        seconds: trainingClass.training?.seconds ?? 0,
      },
    };

    window.downloadsAPI?.downloadTrainingClass(electronTrainingClass, mediaType.type);
  };

  // Descarga un listado de clases planificadas
  const downloadScheduledTrainingClasses = (scheduledTrainingClasses: downloadRequest[]) => {
    if (state.nwjs) {
      postMessage({
        type: '@nwjs/download-scheduled-training-classes',
        payload: { scheduledTrainingClasses },
      });
    }
    window.downloadsAPI?.downloadScheduledTrainingClasses(scheduledTrainingClasses);
  };

  const removeMyOfflineTrainingClasses = (data: ElectronTrainingClass[]) => {
    window.downloadsAPI?.removeMyTrainingClasses(data);
  };

  const removeMyOfflineTrainingClass = (tc: ElectronTrainingClass) => {
    window.downloadsAPI?.removeMyTrainingClass(tc);
  };

  const restoreDefaultSettings = () => {
    window.electronAPI?.restoreDefaults();
  };

  const playScheduler = (roomId = 1) => {
    postMessage({
      type: '@nwjs/start-scheduler',
      payload: { roomId: roomId },
    });
  };

  const maxNumberRooms = (maxRooms: number) => {
    postMessage({ type: '@nwjs/max-number-rooms', payload: { maxRooms } });
  };

  const requestTrainingClasses = () => {
    postMessage({ type: '@nwjs/training-classes-request' });
  };

  const requestFullscreen = () => {
    postMessage({ type: '@nwjs/request-fullscreen' });
  };

  const requestExitFullscreen = () => {
    postMessage({ type: '@nwjs/request-exit-fullscreen' });
  };

  /// Saves in the desktop LocalStorage
  const saveDesktopLocalStorage = (key: any, value: any) => {
    postMessage({ type: '@nwjs/save-localstorage', payload: { key, value } });
  };

  const saveDesktopPreference = (setting: string, value: any) => {
    window.electronAPI?.saveSetting(setting, value);
  };

  return {
    state,
    actions: {
      toTrainingClass,
      playScheduler,
      playTrainingClassMedia,
      downloadTrainingClassMedia,
      downloadScheduledTrainingClasses,
      removeMyOfflineTrainingClasses,
      removeMyOfflineTrainingClass,
      maxNumberRooms,
      requestTrainingClasses,
      requestFullscreen,
      requestExitFullscreen,
      saveDesktopLocalStorage,
      restoreDefaultSettings,
      saveDesktopPreference,
    },
  };
};

export const saveInDesktopLocalStorage = (key: any, value: any) => {
  window.parent.postMessage({ type: '@nwjs/save-localstorage', payload: { key, value } }, '*');
};
