type IconCloseProps = {
  size?: number;
}

const IconClose = ({ size = 30 }: IconCloseProps) => {
  return (
    <svg height={size} viewBox="0 0 25.813 25.813" width={size} xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"><path d="m1.414 1.414 22.985 22.985"/><path d="m1.414 24.399 22.985-22.985"/></g></svg>
  )
}

export default IconClose;