import { createContext, useContext, useEffect, useReducer } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';

import useInterval from '../hooks/useInterval';

const initialState = {
  unread: 0,
  unseen: 0,
};

const FeedsContext = createContext(initialState);

const feedsReducer = (state, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case '@feeds/update-meta':
      return { ...state, ...payload };
    default:
      throw new Error('Feeds reducer message error');
  }
};

export const FeedsProvider = ({ children }) => {
  const { client, fetchApi, mutateApi } = useApi();

  const [state, dispatch] = useReducer(feedsReducer, initialState);

  // feeds cache key already exists
  const { data, refetch } = useQuery(['feeds/notifications'], () => fetchApi(['feeds']));

  useInterval(() => refetch(), 60000);

  const clearCache = () => {
    client.invalidateQueries(['feeds']);
    client.invalidateQueries(['feeds/notifications']);
  };

  const mutateSee = useMutation(
    () =>
      mutateApi(['feeds/seen_all'], null, {
        method: 'POST',
      }),
    {
      onSuccess: clearCache,
    },
  );

  const mutateRead = useMutation(
    feed =>
      mutateApi(['feeds', feed.id], feed, {
        method: 'PUT',
      }),
    {
      onSuccess: clearCache,
      onMutate: async feed => {
        const queryKey = ['feeds'];

        await client.cancelQueries({ queryKey });
        const previousFeeds = client.getQueryData(queryKey);

        client.setQueryData(queryKey, old => {
          return {
            ...old,
            pages: old.pages.map(page => {
              return {
                ...page,
                data: page.data.map(data => {
                  if (data.id !== feed.id) {
                    return data;
                  }

                  return {
                    ...data,
                    is_read: true,
                  };
                }),
              };
            }),
          };
        });

        return { previousFeeds };
      },
    },
  );

  const seeFeeds = () => {
    mutateSee.mutate();
  };

  const readFeed = id => {
    mutateRead.mutate({ id, is_read: true });
  };

  useEffect(() => {
    if (!data) return;
    if (!data.meta) return;

    dispatch({ type: '@feeds/update-meta', ...data.meta });
  }, [data]);

  return (
    <FeedsContext.Provider value={{ state, seeFeeds, readFeed, dispatch }}>
      {children}
    </FeedsContext.Provider>
  );
};

// Contexto de descargas
export const useFeeds = () => useContext(FeedsContext);
