import NewestTrainingClass from './NewestTrainingClass';
import Slider from '@/components/Slider';

type TrainingClassesCarouselPrpos = {
  children?: React.ReactNode;
  trainingClasses: TrainingClass[];
  onClick?(): void;
  dataCy?: string;
};

const TrainingClassesCarousel = ({
  children,
  trainingClasses = [],
  onClick,
  dataCy,
}: TrainingClassesCarouselPrpos) => {
  return (
    <Slider dataCy={dataCy}>
      {children}
      {trainingClasses &&
        trainingClasses.map(item => (
          <NewestTrainingClass key={item.id} trainingClass={item} onClick={onClick} />
        ))}
    </Slider>
  );
};

export default TrainingClassesCarousel;
