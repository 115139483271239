export default function profileModel(profile: Profile): IProfile {
  const needSenseiConfig = () => {
    const { preference_activities } = profile;

    return !preference_activities || !preference_activities.length;
  };

  const needNutritionConfig = () => {
    const { nutrition_profile_preferences } = profile;
    return !nutrition_profile_preferences;
  };

  const needInitialConfig = () => {
    const { fit_status, training_motivations } = profile;
    return !fit_status && !training_motivations.length;
  };

  return {
    ...profile,
    needSenseiConfig,
    needNutritionConfig,
    needInitialConfig,
  };
}
