/* eslint-disable no-param-reassign */
import { SearchFilter } from './Filter';
import { useState, useRef, useCallback, useEffect, useContext, useMemo } from 'react';
import FiltersContext from '../../contexts/filters.context';
import styles from './AsyncSearchFilter.module.scss';
import Button from '../UI/Button/Button';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import iconSearch from '@/img/icon-search-filter.svg';
import iconSearchFocus from '@/img/icon-search-filter-focus.svg';
import CheckIcon from '@/img/check-filter.svg';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import InfiniteQueryList from '../InfiniteQueryList';

export const AsyncSearchFilterTypes = { default: 'default', select: 'select' };

const CustomCheckBox = ({ onCheck, value, label, isChecked, type }) => {
  return (
    <div className={styles.customCheckBoxContainer}>
      <div className={styles.modern_radio_container} onClick={onCheck}>
        {type === AsyncSearchFilterTypes.default && (
          <input
            type="checkbox"
            title={label}
            className={styles.square}
            checked={isChecked}
            value={value}
            onChange={onCheck}
            disabled={false}
          />
        )}
        {type === AsyncSearchFilterTypes.default && (
          <div
            className={`${styles.square} ${isChecked ? styles.checked : styles.unchecked}`}
          ></div>
        )}
        <div
          className={`${styles.labelCheckbox} ${
            isChecked ? styles.labelChecked : styles.labelUnchecked
          }`}
        >
          {label}
        </div>
        {type === AsyncSearchFilterTypes.select && (
          <div className={styles.check}>{isChecked && <img alt="Check" src={CheckIcon} />}</div>
        )}
      </div>
    </div>
  );
};

const ListItem = ({ label, value, onCheck, isChecked, type }) => {
  return (
    <div className={styles.foodRow}>
      <CustomCheckBox
        label={label}
        value={value}
        isChecked={isChecked}
        onCheck={onCheck}
        type={type}
      />
    </div>
  );
};

const DEFAULT_OPTION = { id: 0, name: 'Todos', isDefault: true };
const List = ({
  isLoading,
  onListItemClick,
  listData,
  selectedData,
  search,
  onSelected,
  type,
  isSuccess,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}) => {
  const listReference = useRef(null);

  const [selected, setSelected] = useState(selectedData);

  const isSelected = itemId => {
    if (itemId !== DEFAULT_OPTION.id || selected.length) {
      return selected.map(_item => _item.id).includes(itemId);
    }

    return true;
  };

  const getSelectedIndex = itemId => selected.map(_item => _item.id).indexOf(itemId);

  const onDefaultOptionClick = () => setSelected([DEFAULT_OPTION]);

  const isDefaultOptionPresent = () => isSelected(DEFAULT_OPTION.id);

  const addItem = item => {
    const _selected = isDefaultOptionPresent()
      ? selected.filter(option => option != DEFAULT_OPTION)
      : selected;

    setSelected([..._selected, item]);
  };

  const removeItem = indexFound => {
    const _selected = [
      ...selected.slice(0, indexFound),
      ...selected.slice(indexFound + 1, selected.length),
    ];

    setSelected(_selected.length ? _selected : [DEFAULT_OPTION]);
  };

  const onClickItem = item => {
    if (item.isDefault) {
      onDefaultOptionClick();
      return;
    }

    const selectedIndex = getSelectedIndex(item.id);

    if (selectedIndex === -1) {
      addItem(item);
    } else {
      removeItem(selectedIndex);
    }
  };

  useEffect(() => {
    const scrollEl = listReference.current;

    if (scrollEl) {
      scrollEl.scrollTop = 0.0;
    }
  }, [search]);

  useEffect(() => {
    if (!selected.length) return;

    onSelected(selected);
  }, [selected]);

  if (!listData.filter(item => !!item).length) {
    return (
      <div>
        <div className={styles.emptyItems}>No se han encontrado resultados</div>
      </div>
    );
  }

  if (isLoading) <LoadingIndicator />;

  return (
    <div className={styles.listDataScroll} ref={listReference}>
      <div className={styles.trainingClassList}>
        <InfiniteQueryList
          isSuccess={isSuccess}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        >
          {listData.map((item, index) => (
            <div key={index}>
              <ListItem
                key={index}
                onCheck={() => (onListItemClick ? onListItemClick(item) : onClickItem(item))}
                label={item.name}
                value={item.id}
                isChecked={isSelected(item.id)}
                type={type}
              />
            </div>
          ))}
        </InfiniteQueryList>
      </div>
    </div>
  );
};

export const AsyncSearchFilter = ({
  onClose,
  filter,
  type = AsyncSearchFilterTypes.default,
  buttonText = 'GUARDAR',
  onListItemClick = undefined,
}) => {
  const listRef = useRef(null);

  const [searchFocus, setSearchFocus] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [selected, setSelected] = useState([]);

  const { fetchApi, getNextPageParam } = useApi();
  const { filters, setFilters } = useContext(FiltersContext);

  const nutritionFoodsFilters = searchText
    ? { ...filters, [filter.filterKey]: undefined, search: searchText }
    : { ...filters };

  const query = useInfiniteQuery(
    ['nutrition_foods', nutritionFoodsFilters],
    ({ pageParam }) =>
      fetchApi([
        'nutrition_foods',
        {
          filters: nutritionFoodsFilters,
          limit: 15,
          cursor: pageParam,
        },
      ]),
    {
      getNextPageParam,
    },
  );

  const handleSearch = _search => setSearchText(_search);

  const handleSelected = _selected => setSelected(_selected);

  const handleSearchInputFocus = () => setSearchFocus(true);

  const handleSearchInputBlur = () => setSearchFocus(false);

  const _onClose = () => {
    onClose(selected.filter(item => item.id));
  };

  const data = useMemo(() => {
    const _data = filter.selectedObjects?.length
      ? [DEFAULT_OPTION, ...filter.selectedObjects]
      : [DEFAULT_OPTION];

    if (query.data?.pages) {
      const pages = query.data.pages;
      const pagesData = pages.map(page => page.data);

      const dataFromPages = pagesData
        .flat()
        .filter(d => _data.findIndex(object => object.id === d.id) < 0);

      return [..._data, ...dataFromPages];
    }

    return _data;
  }, [query.data]);

  return (
    <div className={styles[type]}>
      <FiltersContext.Provider value={{ filters, setFilters }}>
        {!!filter.title && <div className={styles.header}>{filter.title}</div>}
        <div ref={listRef}>
          <div className={styles.search}>
            <a className={styles.button_search} onClick={() => null}>
              <img src={searchFocus ? iconSearchFocus : iconSearch} />
            </a>
            <SearchFilter
              placeHolder="Buscar ingrediente"
              className={styles.searchInput}
              onFocus={handleSearchInputFocus}
              onBlur={handleSearchInputBlur}
              onSearch={handleSearch}
              reset={true}
              styled={false}
            />
          </div>

          <div className={styles.listData} id="listData">
            <List
              listData={data}
              isLoading={query.isLoading}
              onListItemClick={onListItemClick}
              selectedData={filter.selectedObjects || []}
              onSelected={handleSelected}
              search={searchText}
              type={type}
              isSuccess={query.isSuccess}
              fetchNextPage={query.fetchNextPage}
              isFetchingNextPage={query.isFetchingNextPage}
              hasNextPage={query.hasNextPage}
            />
          </div>
        </div>
        <div className={styles.confirmation}>
          <Button onClick={_onClose}>{buttonText}</Button>
        </div>
      </FiltersContext.Provider>
    </div>
  );
};
