import TagsColor from '@/constants/TagsColor';
import { TagCircleColor } from '@/contexts/tagscolorlist.types';
import FocusTrap from 'focus-trap-react';
import React, { useEffect } from 'react';
import Positioned from '../Positioned/Positioned';
import ColorCircle from '../TagsList/ColorCircle';
import styles from './TagsColorList.module.scss';

type TagsColorListProps = {
  parentRef: any,
  currentColor: TagCircleColor,
  onClick(color: TagCircleColor): void,
  requestClose(): void,
}

const TagsColorList = React.forwardRef((props: TagsColorListProps, ref: any) => {
  useEffect(() => {
    window.addEventListener('resize', props.requestClose);

    return () => {
      window.removeEventListener('resize', props.requestClose);
    }
  }, []);

  return (
    <Positioned parentRef={props.parentRef} veritcalMargin={25}>
      <FocusTrap>
        <div ref={ref} className={styles.dropdownMenu}>
          <div className={styles.row}>
            {
              TagsColor.map((element: TagCircleColor, index: number) => {
                return (
                  <ColorCircle
                    onClick={() => {
                      props.onClick(element)
                      props.requestClose();
                    }}
                    variant="big"
                    key={index}
                    color={element}
                    selected={props.currentColor == element}>
                  </ColorCircle>
                );
              })
            }
          </div>
        </div>
      </FocusTrap>
    </Positioned>
  );
});

TagsColorList.displayName = "TagsColorList";

export default TagsColorList;
