import './app/index';

if ('serviceWorker' in navigator) {
  const swSrc = window.electronAPI ? 'swe.js' : 'sw.js';

  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(swSrc)
      .then(registration => {
        console.log(`SW registered (${swSrc}):`, registration);
        registration.update();
      })
      .catch(registrationError => {
        console.log(`SW registration failed (${swSrc}):`, registrationError);
      });
  });
} else {
  console.info('no service worker');
}
