import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FooterMin } from '@/components/Footer';
import { useUser } from '@/contexts/app.context';
import { useRemoteConfig } from '@/contexts/remote.context';
import useHandleRedirectHome from '@/hooks/useHandleRedirectHome';
import usePageName from '@/hooks/usePageName';
import useDocumentTitle from '@rehooks/document-title';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';
import AppsDownloadModal from '../components/AppsDownloadModal/AppsDownloadModal';
import LoadingIndicator from '../components/LoadingIndicator';
import Page from '../components/Page';

import MyCollectionsSection from '@/components/HomePage/MyCollectionsSection';
import {
  CategoriesSection,
  CollectionsSection,
  NutritionSection,
  PostsSection,
  TrainingClassesSection,
} from '../components/HomePage';
import MyTrainingClassesSection from '../components/HomePage/MyTrainingClassesSection';
import useFirstExperience from '../hooks/useFirstExperience';

const HomePage = () => {
  useHandleRedirectHome();
  useDocumentTitle('Explorar | Bestcycling');
  usePageName('home');

  const navigate = useNavigate();
  const { isFreeAccessEnabled } = useRemoteConfig();
  const { state: user } = useUser();

  const { fetchApi } = useApi();

  const { hasFirstExperience, deleteExperience } = useFirstExperience();
  const [isDownloadOpen, setIsDownloadOpen] = useState(hasFirstExperience);

  const { data, isSuccess, isLoading } = useQuery(['front'], () =>
    fetchApi([
      'front',
      {
        free_enabled: !user.isSubscribed() && isFreeAccessEnabled() ? '1' : '0',
        video_progress: '1',
      },
    ]),
  );

  const closeDownloadModal = () => {
    deleteExperience();
    setIsDownloadOpen(false);
    navigate('');
  };

  return (
    <Page footer={<FooterMin />}>
      <AppsDownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} />
      {isLoading && <LoadingIndicator />}
      {isSuccess && (
        <>
          {data.data.resources.map((resource, i) => {
            if (!resource) return null;
            const { type } = resource;

            switch (type) {
              case 'categories':
                return (
                  <CategoriesSection key={i} title={resource.title} categories={resource.ids} />
                );
              case 'recipes':
                return <NutritionSection key={i} title={resource.title} />;
              case 'playlists':
                return (
                  <CollectionsSection key={i} title={resource.title} collections={resource.ids} />
                );
              case 'training_classes':
                return (
                  <TrainingClassesSection
                    key={i}
                    title={resource.title}
                    filters={resource.filters}
                    showNps={data.data.resources.some(resource => resource.key === 'nps_app')}
                  />
                );
              case 'my_training_classes':
                return <MyTrainingClassesSection key={i} title={resource.title} />;

              case 'own_collections':
                return <MyCollectionsSection key={i} title={resource.title} />;
              case 'news':
                return <PostsSection key={i} title={resource.title} filters={resource.filters} />;
              default:
                return null;
            }
          })}
        </>
      )}
    </Page>
  );
};

export default HomePage;
