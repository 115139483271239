import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import styles from './ProfileConfig.module.scss';

dayjs.extend(localeData);

export default function ProfileConfig1({ user, onSubmit }) {
  const [gender, setGender] = useState(user.sex);
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [birthday, setBirthday] = useState(user.birthday);

  useEffect(() => {
    const date = dayjs(birthday);

    setDay(date.get('date') || '');
    setMonth(date.get('month') + 1 || '');
    setYear(date.get('year') || '');
  }, []);

  const onBirthdayDayChanged = evt => setDay(evt.target.value);
  const onBirthdayMonthChanged = evt => setMonth(evt.target.value);
  const onBirthdayYearChanged = evt => setYear(evt.target.value);

  useEffect(() => {
    if (day && month && year) {
      const string = `${year}-${month}-${day}`;
      const date = dayjs(string);

      if (date.isValid()) {
        const dateString = date.format('YYYY-MM-DD');
        setBirthday(dateString);
      } else {
        setBirthday(null);
      }
    } else {
      setBirthday(null);
    }
  }, [day, month, year]);

  /* Funciones auxiliares para el desplegable de fecha */
  const daysArray = () => {
    if (!month) return Array.from(Array(31).keys());
    if (!year)
      return Array.from(Array(dayjs(`${dayjs().year()}-${month}-01`).daysInMonth()).keys());

    return Array.from(Array(dayjs(`${year}-${month}-01`).daysInMonth()).keys());
  };
  const monthsArray = () => dayjs.months();
  const yearsArray = () => Array.from(Array(100).keys());

  const onGenderChange = evt => {
    const value = evt.target.value;
    setGender(value);
  };

  const onSubmitForm = evt => {
    evt.preventDefault();

    onSubmit({ user: { sex: gender, birthday } });
  };

  const isFormValid = birthday && gender;

  return (
    <div>
      <div className={styles.title}>CONFIGURACIÓN DEL PERFIL</div>
      <p className={styles.description}>
        Para recomendarte los entrenamientos ideales para ti, necesitamos que nos facilites
        información básica. Rellena los siguientes datos.
      </p>

      <form onSubmit={onSubmitForm} className={styles.form}>
        <div className={styles.formContainer}>
          <fieldset>
            <label>Fecha de nacimiento</label>
            <div className={styles.birthday}>
              <select id="day" defaultValue={day} value={day} onChange={onBirthdayDayChanged}>
                <option value="">Día</option>
                <option disabled>---</option>
                {daysArray().map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <select
                id="month"
                defaultValue={month}
                value={month}
                onChange={onBirthdayMonthChanged}
              >
                <option value="">Mes</option>
                <option disabled>---</option>
                {monthsArray().map((monthItem, i) => (
                  <option key={i} value={i + 1}>
                    {monthItem}
                  </option>
                ))}
              </select>
              <select id="year" defaultValue={year} value={year} onChange={onBirthdayYearChanged}>
                <option value="">Año</option>
                <option disabled>---</option>
                {yearsArray().map((_, i) => (
                  <option key={i} value={dayjs().year() - i}>
                    {dayjs().year() - i}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>

          <fieldset>
            <label>Género</label>
            <select id="day" onChange={onGenderChange} defaultValue={gender}>
              <option value="">Selecciona tu género</option>
              <option disabled>---</option>
              <option value="m">Soy hombre</option>
              <option value="f">Soy mujer</option>
            </select>
          </fieldset>
        </div>
        <button className={styles.button} disabled={!isFormValid}>
          Continuar
        </button>
      </form>
    </div>
  );
}
