import { Children, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './Slider.module.scss';

import Slide from './Slide';
import arrowLeft from '@/img/chevron-left.svg';
import arrowRight from '@/img/chevron-right.svg';

import useResizeObserver from './useResizeObserver';

const Slider = ({
  children,
  className = '',
  showPeek = true,
  margin = true,
  automaticSlideSize = true,
  dataCy = '',
}) => {
  // SLider container reference
  const sliderRef = useRef(null);

  // Mask container reference
  const sliderMaskRef = useRef(null);

  // Slider movement container reference
  const sliderTrackRef = useRef(null);

  // Next Button reference
  const nextButtonRef = useRef(null);

  // Prev Button reference
  const prevButtonRef = useRef(null);

  // Reference to animating timeout
  const animatingTimeoutRef = useRef(null);

  // Resize Observer
  const { ref, width, height } = useResizeObserver(sliderRef);

  // Current first visible slide
  const [currentSlide, setCurrentSlide] = useState(0);

  // Current total visible slides
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);

  // TotalSlides
  const [totalSlidesCount, setTotalSlidesCount] = useState(0);

  // The target slide index we want to move
  const [targetSlide, setTargetSlide] = useState(0);

  // show pref button
  const [prevButtonVisible, setPrevButtonVisible] = useState(false);

  // show next button
  const [nextButtonVisible, setNextButtonVisible] = useState(false);

  const [scrolling, setScrolling] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });

  const isAnimating = targetSlide !== currentSlide;

  const prevAction = () => {
    setTargetSlide(Math.max(0, currentSlide - visibleSlidesCount));
  };

  const nextAction = () => {
    setTargetSlide(Math.min(totalSlidesCount - 1, currentSlide + visibleSlidesCount));
  };

  /**
   * Get Slides
   * @returns {[Node]} Slider slide's.
   */
  const getSlides = () => {
    return sliderTrackRef.current.querySelectorAll("[id^='slide-item-']");
  };

  /**
   * Returns the width of the first slide.
   * @returns {Number} First Slide Width or 0 if there are not slides.
   */
  const getSlideWidth = () => {
    const slides = getSlides();

    if (!slides || !slides[0]) {
      return 0;
    }

    const slideWidth = slides[0].offsetWidth;

    return slideWidth;
  };

  const onMouseDown = e => {
    setScrolling({
      ...scrolling,
      isScrolling: true,
      clientX: e.touches[0].clientX,
      scrollX: parseFloat(sliderTrackRef.current.style.left),
    });
  };

  const onMouseUp = () => {
    setScrolling({
      ...scrolling,
      isScrolling: false,
    });

    const { scrollX } = scrolling;
    const slideWidth = getSlideWidth();
    const slides = getSlides();

    let slideNumber = Math.round(-scrollX / slideWidth);
    slideNumber = Math.max(0, slideNumber); // Not less than slide 0
    slideNumber = Math.min(slides.length - 1, slideNumber); // Not more than slide n - 1

    setTargetSlide(slideNumber);
  };

  const onMouseMove = e => {
    if (!scrolling.isScrolling) return;

    const track = sliderTrackRef.current;
    const clientX = e.touches[0].clientX;

    const toMove = scrolling.scrollX + clientX - scrolling.clientX;
    track.style.left = `${toMove}px`;

    setScrolling({
      ...scrolling,
      scrollX: toMove,
      clientX,
    });
    setTargetSlide(null);
    setCurrentSlide(null);
  };

  /**
   * Moves Slider track to the current slide position.
   */
  const moveSlider = () => {
    const slideWidth = getSlideWidth();
    const delta = -1 * slideWidth * targetSlide;

    sliderTrackRef.current.style.left = `${delta}px`;
  };

  const setButtonsVisibility = () => {
    const showPrevButton = currentSlide > 0;
    setPrevButtonVisible(showPrevButton);

    const showNextButton = currentSlide + visibleSlidesCount < totalSlidesCount;
    setNextButtonVisible(showNextButton);
  };

  /** Effect for Children changed */
  useEffect(() => {
    const elements = Children.toArray(children);

    setTotalSlidesCount(elements.length);
  }, [children, sliderTrackRef]);

  useEffect(() => {
    setButtonsVisibility();
  }, [totalSlidesCount]);

  /**
   * Effect for slider width changed.
   */
  useEffect(() => {
    const sliderWidth = width;
    const slideWidth = getSlideWidth();

    const newVisibleSlidesCount = Math.floor(sliderWidth / slideWidth);
    setVisibleSlidesCount(newVisibleSlidesCount);

    moveSlider();
  }, [ref, width, height]);

  /**
   * Effect for setting tab index for visible slides.
   */
  useEffect(() => {
    const slides = getSlides();
    slides.forEach((slide, index) => {
      const visible = index >= currentSlide && index < currentSlide + visibleSlidesCount;
      const tabIndex = visible ? 0 : -1;

      slide.querySelectorAll("[role='link']").forEach(item => {
        let node = item;
        node.tabIndex = tabIndex;
      });
    });

    const items = sliderRef.current.querySelectorAll('[tabindex="0"]');
    if (items.length && document.activeElement === prevButtonRef.current) {
      items[items.length - 1].focus();
    }
    if (items.length && document.activeElement === nextButtonRef.current) {
      items[0].focus();
    }

    setButtonsVisibility();
  }, [currentSlide, visibleSlidesCount]);

  /**
   * Enables animation and moves to targetSlide.
   */
  useEffect(() => {
    if (animatingTimeoutRef.current) {
      clearTimeout(animatingTimeoutRef.current);
    }
    if (targetSlide !== null) {
      moveSlider();
      animatingTimeoutRef.current = setTimeout(() => {
        setCurrentSlide(targetSlide);
      }, 500);
    }
    return () => {
      if (animatingTimeoutRef.current) {
        clearTimeout(animatingTimeoutRef.current);
      }
    };
  }, [targetSlide, setCurrentSlide, animatingTimeoutRef]);

  const sliderClasses = clsx(styles.slider, [
    className && styles[className],
    isAnimating && styles.animating,
    !margin && styles.noMargin,
  ]);

  return (
    <div
      ref={sliderRef}
      className={sliderClasses}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      onTouchMove={onMouseMove}
    >
      {prevButtonVisible && (
        <button
          ref={prevButtonRef}
          aria-label="Anterior"
          id="button-prev"
          className={styles.arrowleft}
          onClick={prevAction}
        >
          <img alt="Anterior" src={arrowLeft} />
        </button>
      )}

      <div
        ref={sliderMaskRef}
        className={`${styles.sliderMask} ${showPeek ? styles.showPeek : ''}`}
      >
        <div data-cy={dataCy} ref={sliderTrackRef} className={styles.track}>
          {Children.toArray(children).map((child, i) => (
            <Slide key={i} index={i} automaticSize={automaticSlideSize}>
              {child}
            </Slide>
          ))}
        </div>
      </div>

      {nextButtonVisible && (
        <button
          ref={nextButtonRef}
          aria-label="Siguiente"
          id="button-next"
          className={styles.arrowright}
          onClick={nextAction}
        >
          <img alt="Siguiente" src={arrowRight} />
        </button>
      )}
    </div>
  );
};

export default Slider;
