import { useParams } from "react-router";
import FindClassesPage from "./FindClassesPage";

const MyCollectionClassesPage = () => {
  const { collection } = useParams();

  return (
    <>
      <FindClassesPage collection={collection} section="collection" official={true} />
    </>
  )
}

export default MyCollectionClassesPage;