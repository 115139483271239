import dayjs from 'dayjs';

const SubscribeButton = () => {
  return (
    <a href="https://bestcycling.com/subscription/new" className="button_web">
      Pincha aquí para suscribirte o ampliar tu suscripción
    </a>
  );
};

const TrialButton = ({ user }) => {
  const isReferred = user.is_referred;

  return (
    <a href="/subscription/trial/new" className="button_web">
      Prueba gratuita de {isReferred ? 60 : 30} días
    </a>
  );
};

const SubscribedMessage = ({ membership }) => {
  const { plan, member_until } = membership;

  return (
    <>
      <p style={{ margin: '1em 0', fontSize: '15px' }}>
        Tu suscripción {plan} está activa hasta el
        <span> {dayjs(member_until).format('LL')}</span>.
      </p>
      <p style={{ margin: '1em 0' }}>
        <SubscribeButton />
      </p>
    </>
  );
};

const SubscribeMessage = ({ user }) => {
  const isTrialPeriodUsed = user.used_trial_period;
  const isReferred = user.is_referred;

  return (
    <>
      <h3>No tienes una suscripción activa.</h3>

      {isReferred && !isTrialPeriodUsed ? (
        <>
          <p style={{ margin: '1em 0', lineHeight: '1.2em' }}>
            Gracias a que has sido invitado por un amigo tienes{' '}
            <b style={{ fontWeight: 'bold' }}>
              un 20% de descuento en tu primera suscripción anual.
            </b>{' '}
            Suscríbete para tener acceso a toda la cartera de clases y funcionalidades exclusivas
            como un plan de entrenamiento personalizado que te ayudará a conseguir tus objetivos.
          </p>
          <p style={{ margin: '1em 0' }}>
            <SubscribeButton />
          </p>
        </>
      ) : (
        <>
          <p style={{ margin: '1em 0', lineHeight: '1.2em' }}>
            Suscríbete a cualquiera de nuestros planes para poder disfrutar de{' '}
            <b style={{ fontWeight: 'bold' }}>
              toda la cartera de clases y funcionalidades exclusivas
            </b>{' '}
            como un plan de entrenamiento personalizado que te ayudará a conseguir tus objetivos.
          </p>
          <p style={{ margin: '1em 0' }}>
            <SubscribeButton />
          </p>
        </>
      )}
    </>
  );
};

export default function Subscription({ user }) {
  const currentMembership = user.current_membership;
  const isSubscribed = user.isSubscribed();

  return (
    <>
      {isSubscribed ? (
        <SubscribedMessage membership={currentMembership} />
      ) : (
        <SubscribeMessage user={user} />
      )}
    </>
  );
}
