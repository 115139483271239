import LoadingIndicator from "./LoadingIndicator";

type SizedLoadingIndicatorProps = {
  height?: number,
}

const SizedLoadingIndicator = (props: SizedLoadingIndicatorProps) => {
  return (
    <div style={{ height: props.height }}>
      <LoadingIndicator />
    </div>
  )
}

export default SizedLoadingIndicator;