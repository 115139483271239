import TrainingMaterial from '@/components/TrainingMaterial';
import Category from '@/constants/category';
export default function TrainingClassMaterials({ trainingClass }) {
  const { category_nr, training_materials } = trainingClass;

  if (category_nr != Category.Training) {
    return null;
  }

  if (!training_materials?.length) {
    return null;
  }

  return (
    <>
      {training_materials.map(m => (
        <TrainingMaterial key={m.id} material={m} style={{ marginLeft: '5px', height: '16px' }} />
      ))}
    </>
  );
}
