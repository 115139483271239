import { useState, useEffect, useContext } from 'react';
import styles from './Levels.module';
import FiltersContext from '../../contexts/filters.context';
import LevelsByCategory from '../../constants/levelsByCategory';

import logoCycling from '../../../../img/bestcycling-logo.png';
import logoTraining from '../../../../img/besttraining-logo.png';
import logoRunning from '../../../../img/bestrunning-logo.png';
import logoWalking from '../../../../img/bestwalking-logo.png';
import logoMind from '../../../../img/bestmind-logo.png';
import logoBalance from '../../../../img/bestbalance-logo.png';

export default function Levels({ params = {} }) {
  const { filters, setFilters } = useContext(FiltersContext);

  const [selectedLevel, setSelectedLevel] = useState({});
  const Category = Object.freeze({
    1: 'cycling',
    2: 'walking',
    11: 'balance',
    21: 'mind',
    31: 'running',
    41: 'training',
  });

  const Colors = Object.freeze({
    1: '#ff7900',
    2: '#8d88c5',
    11: '#69ae00',
    21: '#1d8cb9',
    31: '#fcd900',
    41: '#f13b46',
  });

  const logos = {
    1: logoCycling,
    41: logoTraining,
    11: logoBalance,
    31: logoRunning,
    2: logoWalking,
    21: logoMind,
  };

  const alt = Object.freeze({
    1: 'bestcycling',
    41: 'besttraining',
    11: 'bestbalance',
    31: 'bestrunning',
    2: 'bestwalking',
    21: 'bestmind',
  });

  useEffect(() => {
    if (!LevelsByCategory[Category[params.category_nr]]) {
      return;
    }

    let setLevel = LevelsByCategory[Category[params.category_nr]][0];
    if (filters && filters.level_nr) {
      setLevel = LevelsByCategory[Category[params.category_nr]].find(
        level => level.levelClass == filters.level_nr
      );
    }

    setSelectedLevel(setLevel);
  }, []);

  function setNewLevel(level) {
    const newfilters = { ...filters };
    if (!level.levelClass) {
      delete newfilters.level_nr;
    } else {
      newfilters.level_nr = level.levelClass;
    }

    setSelectedLevel(level);
    setFilters(newfilters);
  }

  if (!params.category_nr) return <div></div>;

  // TODO: pillar para mind la lista por api
  // https://apiv2.bestcycling.es/api/v2/playlists?filters[parent_id]=128&limit=10&include=favourite
  return (
    <div className={styles.levelscontainer}>
      <div className={`${styles.levelsheader} ${styles[Category[params.category_nr]]}`}>
        <img lang="en" alt={alt[filters.category_nr]} src={logos[filters.category_nr]} />
      </div>
      <>
        <div className={styles.levels}>
          {LevelsByCategory[Category[params.category_nr]] &&
            LevelsByCategory[Category[params.category_nr]].map(level => (
              <div
                key={level.levelClass}
                className={styles.level}
                style={{
                  borderBottom: `3px solid ${
                    selectedLevel && selectedLevel.levelClass == level.levelClass
                      ? Colors[filters.category_nr]
                      : 'transparent'
                  }`,
                  color: ` ${
                    selectedLevel && selectedLevel.levelClass == level.levelClass
                      ? 'white'
                      : '#747474'
                  }`,
                }}
                onClick={() => {
                  setNewLevel(level);
                }}
              >
                {level.subtitle}
              </div>
            ))}
        </div>
        <div className={styles.smalllevels}>
          <select
            id="level"
            name="level"
            value={selectedLevel.levelClass || 'todos'}
            onChange={({ target }) => {
              setNewLevel(
                LevelsByCategory[Category[params.category_nr]].find(
                  level => level.levelClass === target.value
                )
              );
            }}
          >
            {LevelsByCategory[Category[params.category_nr]] &&
              LevelsByCategory[Category[params.category_nr]].map(level => (
                <option key={level.levelClass} value={level.levelClass}>
                  {level.subtitle}
                </option>
              ))}
          </select>
        </div>
      </>
      <div className={styles.levelinfo}>
        <div style={{ color: Colors[filters.category_nr] }} className={styles.leveltitle}>
          {selectedLevel.levelClass ? selectedLevel.title : ''}
        </div>
        <div>
          <h3 className={styles.subtitle}>{selectedLevel.subtitle}</h3>
        </div>
        <div className={styles.description}>{selectedLevel.description}</div>
      </div>
    </div>
  );
}
