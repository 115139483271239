export default {
  type: 'virtual_training_schedule',
  fields: {
    time_string_utc: 'string',
    training_class_id: 'string',
    training_class_category_nr: 'number',
    training_class_level_nr: 'number',
    min_duration_minutes: 'number',
    max_duration_minutes: 'number',
    monday: 'boolean',
    tuesday: 'boolean',
    wednesday: 'boolean',
    thursday: 'boolean',
    friday: 'boolean',
    saturday: 'boolean',
    sunday: 'boolean',
    trainer_id: 'integer',
  },
  relationships: {
    trainer: {
      type: 'training_class',
    },
  },
};
