import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { usePreferences, useProfile } from '../contexts/app.context';
import Category from '../constants/category';
import { useShell } from '../contexts/shell.context';

import { useHideNavigation } from '../contexts/nav.context';

import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Player from '../components/Player';
import { useAuth } from '../contexts/auth.context';
import { GraphType } from '@/core/constants/graphFactory';
import useFetchTrainingClass from '@/core/hooks/apiHooks/useFetchTrainingClass';
import { useHR } from '@/contexts/heartRate.context';
import { getQualitiesVariants } from '@/components/Scheduler/schedulerHelpers';
import { useMediaServer } from '@/contexts/desktop/mediaserver.context';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { BluetoothDeviceTypes } from '@/constants/bluetooth';
import useSetting from '@/components/Preferences/useSetting';
import { useFtms } from '@/hooks/useFtms';
import { getPreferredGraph } from '../utils/playerUtils';
import { RunningDefault } from '@/constants/RunningDefault';
import useDocumentTitle from '@rehooks/document-title';

type LoadingErrorProps = {
  children: React.ReactNode;
};

const LoadingError = (props: LoadingErrorProps) => {
  return <div>{props.children}</div>;
};

const OfflinePlayer = ({ trainingClass, mediaType, options, heartRate }) => {
  const { state: mediaServer } = useMediaServer();

  return mediaServer.running ? (
    <Player
      trainingClass={trainingClass}
      mediaType={mediaType}
      options={options}
      heartRate={heartRate}
    />
  ) : (
    <div>Waiting for media server to run</div>
  );
};

const TrainingClassPlayerPage = () => {
  useHideNavigation();

  const { state: shell } = useShell();
  const { state: auth } = useAuth();
  const { state: profile } = useProfile();
  const { id, mediaType } = useParams();
  const [_, setGraphType] = useSetting('graph_type');
  const { state: preferences } = usePreferences();

  const [store, setStore] = useState<Store | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string>();
  const { data, isError, isLoading } = useFetchTrainingClass(id, ['trainer']);

  const title = data
    ? data.official
      ? `${data.short_title} / ${data.subtitle}`
      : data.short_title
    : 'Cargando clase...';

  useDocumentTitle(`${title} | Bestcycling`);

  const {
    state: { connected, heartRateEmitter },
  } = useHR();
  const { actions } = useBTContext();
  // eslint-disable-next-line object-curly-newline
  const { enabled, features, levelRange, forceAutoMode } = useFtms();
  const heartRateConencted = actions.getConnectedDevices(BluetoothDeviceTypes.HeartRate);
  const bikesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.Bike);
  // Si es la primera bici conectada, cambiamos a modo potencia la primera vez
  if (bikesConnected.length > 0) {
    if (localStorage.getItem('wattsSetOnConnectedBike') == undefined) {
      setGraphType(GraphType.Watts);
      localStorage.setItem('wattsSetOnConnectedBike', 'true');
    }
  }

  const getMediaUrl = (mediaAssets: Media[], type: string) => {
    if (!data) return '';

    const media = mediaAssets.find(m => m.type === type);

    if (media?.url.match('127.0.0.1')) {
      return media?.url;
    }

    return `${media?.url}&access_token=${auth.accessToken}`;
  };

  useEffect(() => {
    if (!data || isLoading || !auth.accessToken) return;

    const _mediaType = mediaType as 'video' | 'audio' | 'music' | 'hls';
    const _mediaUrl = getMediaUrl(data.media, _mediaType);

    const defaultDuration = preferences.defaultDuration;

    const delaySeconds = _mediaType != 'music' ? data.delay_seconds : 0;
    const isDesktop =
      shell.desktop &&
      (data.category_nr == Category.Cycling || data.category_nr == Category.Walking) &&
      (mediaType == 'audio' || mediaType == 'music');

    const progressionWatts =
      data.category_nr == Category.Cycling || data.category_nr == Category.Training
        ? data.progression_watts
        : null;

    const trainingClass: TrainingClassParsed = {
      // @ts-ignore-next-line
      id: parseInt(data.id, 10),
      title: data.title,
      trainer: data.trainer,
      progression: data.progression,
      progression_watts: progressionWatts,
      delay_seconds: delaySeconds,
      duration_seconds: data.duration_seconds,
      duration_training: data.duration_training,
      duration_class:
        data.category_nr == Category.Mind && defaultDuration != null ? defaultDuration * 60 : null,
      official: data.official,
      url: _mediaUrl,
      filename: _mediaUrl,
      variants:
        data.media &&
        mediaType == 'hls' &&
        getQualitiesVariants(data.media, preferences.quality_video, auth.accessToken),
      category: data.category_nr,
      need_progression: true,
      mediaType: mediaType?.match(/^(video)/) ? 'video' : _mediaType,
      isNew: !!data.is_new,
      hasBackground: !!data.has_background,
      level: data.level_nr,
      isNewBlack: !!data.is_black,
      image: data.cover!,
      image_graph: data.graph!,
      show_effort: data.category_nr == Category.Cycling && data.show_effort,
    };

    const userSettings = preferences;

    let options = preferences.playerPreferences;
    console.log('OPTIONS', options);
    options = {
      isDesktop: isDesktop,
      ...options,
      video: {
        ...options.video,
        preferQualityVideo: preferences.quality_video || 'hls',
        logo:
          userSettings.C8 !== ''
            ? userSettings.C8
            : userSettings.getLogoForCategory(data.category_nr),
      },
      graph: {
        ...options.graph,
        preferred_graph_type: getPreferredGraph(preferences, data),
      },
      cardio: {
        ...options.cardio,
        enabled: !connected ? heartRateConencted.length > 0 : connected,
      },
      ftms: {
        ...options.ftms,
        features: features,
        resistance_level_range: levelRange,
        ftp: profile?.ftp,
        enabled: enabled,
        autoMode: forceAutoMode(),
      },
      stats: {
        running: RunningDefault,
      },
    };

    if (data.category_nr == Category.Training && preferences.annotateResults) {
      options = {
        ...options,
        annotate_results: preferences.annotateResults,
      };
    }

    if (['audio', 'music'].includes(_mediaType)) {
      options['audio'] = {
        ...options['audio'],
        external_display: preferences.externalOpen == 1,
      };
    }

    setStore({
      trainingClass: trainingClass,
      options: options,
    });
  }, [data, isLoading, auth.accessToken]);

  useEffect(() => {
    if (!data || isLoading || !auth.accessToken) return;

    const url = getMediaUrl(data.media, mediaType as 'video' | 'audio' | 'music');
    setMediaUrl(url);
  }, [mediaType, data, auth.accessToken]);

  if (isLoading) return <LoadingIndicator />;

  if (isError) return <LoadingError>No se puede acceder a la clase.</LoadingError>;

  if (!id || !mediaType) return <LoadingError>Clase no encontrada.</LoadingError>;

  const isOffline = mediaUrl?.includes('//127.0.0.1:');

  return store && mediaUrl ? (
    isOffline ? (
      <OfflinePlayer
        trainingClass={store.trainingClass}
        mediaType={mediaType}
        options={store.options}
        heartRate={shell.nwjs ? preferences.heartRate : heartRateEmitter}
      />
    ) : (
      <Player
        trainingClass={store.trainingClass}
        mediaType={mediaType}
        options={store.options}
        heartRate={shell.nwjs ? preferences.heartRate : heartRateEmitter}
      />
    )
  ) : null;
};

export default TrainingClassPlayerPage;
