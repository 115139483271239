import { useState } from 'react';
import Modal from '../Modal';
import Button from '../UI/Button';
import AllOkModal from './AllOkModal';
import ConfigFtpModal from './ConfigFtpModal';
import styles from './ConnectingBikeModal.module.scss';
import NotKnowedFtpModal from './NotKnowedFtpModal';
import { FTP_ATTRIBUTES } from '@/constants/ftpAttributes';

interface KnowingFtpModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export default function KnowingFtpModal({ isOpen, onCancel }: KnowingFtpModalProps) {
  const [knowingFtp, setKnowingFtp] = useState({
    knowingFtpStep: isOpen,
    configFtpStep: false,
    notKnowedFtpStep: false,
    allOkStep: false,
  });
  const { knowingFtpStep, configFtpStep, notKnowedFtpStep, allOkStep } = knowingFtp;

  const onKnew = () => setKnowingFtp({ ...knowingFtp, knowingFtpStep: false, configFtpStep: true });

  const onNotKnew = () =>
    setKnowingFtp({ ...knowingFtp, knowingFtpStep: false, notKnowedFtpStep: true });

  return (
    <>
      <Modal
        isOpen={knowingFtpStep}
        className={styles.modalBikeConnected}
        onRequestClose={onCancel}
      >
        <h1 className={styles.title}> NECESITAMOS CONFIGURAR TUS ZONAS DE ENTRENAMIENTO </h1>
        <p className={styles.description}>
          Para poder entrenar con potencia conectado a tu bicicleta y que nuestras clases se adapten
          100% a ti, necesitamos saber cuál es tu FTP y así configurar tus zonas de entrenamiento.
          Si no lo conoces, no te preocupes, podemos estimarlas y que no tengas ningún problema para
          empezar a entrenar.
        </p>
        <p className={styles.descriptionKnowingFtp}>¿Conoces cuál es tu FTP?</p>
        <footer className={styles.containerButtons}>
          <Button
            onClick={onNotKnew}
            size="small"
            className={styles.buttonKnowingFtp}
            variant="terciary"
          >
            NO, NO SÉ CUÁL ES MI FTP
          </Button>
          <Button onClick={onKnew} size="small" className={styles.buttonKnowingFtp}>
            SI, CONOZCO MI FTP
          </Button>
        </footer>
      </Modal>

      {configFtpStep && (
        <ConfigFtpModal
          isOpen={configFtpStep}
          onCancel={onCancel}
          onBack={() =>
            setKnowingFtp({ ...knowingFtp, configFtpStep: false, knowingFtpStep: true })
          }
          onConfirm={data => {
            setKnowingFtp({ ...knowingFtp, configFtpStep: false, allOkStep: data });
          }}
          attributeOpen={FTP_ATTRIBUTES.FTP}
        />
      )}
      {notKnowedFtpStep && (
        <NotKnowedFtpModal
          isOpen={notKnowedFtpStep}
          onCancel={onCancel}
          onBack={() =>
            setKnowingFtp({ ...knowingFtp, notKnowedFtpStep: false, knowingFtpStep: true })
          }
          onConfirm={() => {
            setKnowingFtp({ ...knowingFtp, notKnowedFtpStep: false, allOkStep: true });
          }}
        />
      )}
      {allOkStep && <AllOkModal isOpen={allOkStep} onCancel={onCancel} />}
    </>
  );
}
