import { Filter } from './Filter';

export default function TrainingDoneFilter() {
  const options = [
    { value: 'false', label: 'No realizadas', type: 'done_by_user' },
    { value: 'true', label: 'Realizadas', type: 'done_by_user' },
  ];

  return (
    <Filter dataCy="training_done_filter" options={options} type="done_by_user" tag="Realizadas" />
  );
}
