import { useEffect, useState } from 'react';

interface UseIsMobileScreenProps {
  mobileSize?: number;
}

export default function useIsMobileScreen({ mobileSize = 576 }: UseIsMobileScreenProps = {}) {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < mobileSize);

  useEffect(() => {
    const onResize = () => setIsMobileScreen(window.innerWidth < mobileSize);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return isMobileScreen;
}
