import style from './SharedTrainerHeader.module.scss';
import sharedIcon from '@/img/share-arrow.svg';

export type SharedTrainerHeaderProps = {
  user: User;
};

function SharedTrainerHeader({ user }: { user: Trainer }) {
  return (
    <div className={style.card}>
      <div className={style.cardRow}>
        <img className={style.iconShared} src={sharedIcon}></img>
        <p className={style.trainer}>{user?.full_name || ''}</p>
      </div>
    </div>
  );
}

export default SharedTrainerHeader;
