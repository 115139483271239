import { AsyncFilter } from './Filter';

interface TrainingTrainerFilterProps {
  unofficialInstructorData: IUser
  isFavorites: boolean;
}

export function TrainingTrainerFilter({ unofficialInstructorData, isFavorites }: TrainingTrainerFilterProps) {
  return (
    <AsyncFilter
      filterType="trainers"
      filterKey="trainer_id"
      label="full_name"
      tag="INSTRUCTOR"
      includes={['trainer']}
      dataModify={
        isFavorites &&
        ((data: any) =>
          [
            ...data,
            {
              id: unofficialInstructorData.id,
              full_name: unofficialInstructorData.full_name,
            },
          ].sort((a, b) => a.full_name.localeCompare(b.full_name)))
      }
    />
  );
}
