import React from 'react';
import ReactDOM from 'react-dom';

type PlayerRefProps = {};

const PlayerRef = React.forwardRef((_: PlayerRefProps, ref: any) => {
  return ReactDOM.createPortal(
    <iframe
      id="player"
      ref={ref}
      allow="autoplay 'self'; fullscreen"
      style={{
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100%',
        border: 'none',
        zIndex: 1000,
      }}
    ></iframe>,
    document.getElementById('player-container')!,
  );
});

PlayerRef.displayName = 'PlayerRef';

export default PlayerRef;
