import clsx from "clsx";
import { Link } from "react-router-dom";

import styles from "./MyCollectionsCard.module.scss";

type MyCollectionsCardProps = {
  collection: Tag,
}

const MyCollectionsCard = ({ collection }: MyCollectionsCardProps) => {

  const path = `/collection/${collection.id}`;

  return (
    <Link aria-label={`Colección ${collection.name}`} to={path} className={styles.myCollectionCard}>
      <div className={clsx(styles.overlay, styles[collection?.properties?.color])} />
      <div className={clsx(styles.overlay, styles.shadow)} />
      <div className={styles.content}>
        <span className={styles.collectionName} title={collection.name}>{collection.name}</span>
      </div>
    </Link>
  );
}

export default MyCollectionsCard;