import dayjs from 'dayjs';
import ProgressBar from '../ProgressBar/ProgressBar';
import TrainingClassZones from './TrainingClassZones';
import { usePreferences } from '@/core/contexts/app.context';
import { GraphType } from '@/core/constants/graphFactory';
import { getTss } from '../Trainings/utils';
import { useMemo } from 'react';

import styles from './TrainingClassMoreInfo.module.scss';

export type TrainingClassMoreInfoProps = {
  trainingClass: TrainingClass;
};

export default function TrainingClassMoreInfo({ trainingClass }: TrainingClassMoreInfoProps) {
  const { training_class_info } = trainingClass;
  const { state: prefs } = usePreferences();
  const {
    cadence_info,
    duration_training,
    percent_in_zone,
    percent_in_zone_watts,
    time_in_method,
  } = training_class_info;

  const timeSitting = dayjs.utc(time_in_method.sitting * 1000).format('mm:ss');
  const timeStanding = dayjs.utc(time_in_method.standing * 1000).format('mm:ss');

  const cadenceLow = dayjs.utc(cadence_info.percent_cadence.second_low * 1000).format('mm:ss');
  const cadenceMedium = dayjs
    .utc(cadence_info.percent_cadence.second_medium * 1000)
    .format('mm:ss');
  const cadenceHigh = dayjs.utc(cadence_info.percent_cadence.second_high * 1000).format('mm:ss');

  const maxTimeCadence = dayjs.utc(cadence_info.max_cadence.time * 1000).format('mm:ss');
  const maxTimeSitting = dayjs.utc(time_in_method.max_time_sitting * 1000).format('mm:ss');

  const ftp = prefs.getFtp();
  const tss = getTss({
    seconds: trainingClass.duration_training || trainingClass.duration_seconds,
    ftmsValues: null,
    progressionWatts: trainingClass.progression_watts,
    ftp: ftp != null ? ftp : 100,
  });

  const timeStandingHighCadence = dayjs.utc(cadence_info.standing.time * 1000).format('mm:ss');

  const zonesPercentages = () => {
    if (prefs.graph_type == GraphType.HeartRate && trainingClass.progression?.length) {
      return percent_in_zone;
    }

    if (trainingClass.progression_watts?.length) {
      return percent_in_zone_watts;
    }

    return percent_in_zone;
  };

  const formatStandingValue = useMemo(() => {
    const { min_value, value } = cadence_info.standing;

    if (!min_value && !value) return '';

    return `${min_value === value ? value : `${min_value}-${value}`} rpm`;
  }, [cadence_info]);

  return (
    <div className={styles.moreInfoContainer}>
      {tss > 0 && (
        <div className={styles.tssContainer}>
          <div className={styles.tss}>
            <p>TSS</p>
            <span>{tss}*</span>
          </div>
          <div className={styles.dividerHor}></div>
        </div>
      )}

      <div className={styles.timesContainer}>
        <div className={styles.header}>TIEMPOS</div>
        <div className={styles.statsContainer}>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>Sentado</p>
              <p className={styles.value}>{Math.round(time_in_method.percent_sitting)}%</p>
              <p className={styles.value}>{timeSitting}</p>
            </div>

            <ProgressBar
              percent={Math.round(time_in_method.percent_sitting)}
              styles={{ height: '3px' }}
              color="#599CB7"
            />
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>De pie</p>
              <p className={styles.value}>{Math.round(time_in_method.percent_standing)}%</p>
              <p className={styles.value}>{timeStanding}</p>
            </div>
            <ProgressBar
              percent={Math.round(time_in_method.percent_standing)}
              styles={{ height: '3px' }}
              color="#2D50C8"
            />
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>T. máx. consecutivo sentado</p>
              <p />
              <p className={styles.value}>{maxTimeSitting}</p>
            </div>
            <div className={styles.dividerHor}></div>
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>T. de pie a cadencias altas</p>
              <p className={styles.value}>{formatStandingValue}</p>
              <p className={styles.value}>{timeStandingHighCadence}</p>
            </div>
            <div className={styles.dividerHor}></div>
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.cadences}>
        <div className={styles.header}>CADENCIAS DE PEDALEO</div>
        <div className={styles.statsContainer}>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>{'Baja (<64)'}</p>
              <p className={styles.value}>{cadenceLow}</p>
              <p className={styles.value}>{Math.round(cadence_info.percent_cadence.low)}%</p>
            </div>

            <ProgressBar
              percent={Math.round(cadence_info.percent_cadence.low)}
              styles={{ height: '3px' }}
              color="#809D36"
            />
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>{'Media (64-74)'}</p>
              <p className={styles.value}>{cadenceMedium}</p>
              <p className={styles.value}>{Math.round(cadence_info.percent_cadence.medium)}%</p>
            </div>
            <ProgressBar
              percent={Math.round(cadence_info.percent_cadence.medium)}
              styles={{ height: '3px' }}
              color="#D1B40A"
            />
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>{'Alta (>74)'}</p>
              <p className={styles.value}>{cadenceHigh}</p>
              <p className={styles.value}>{Math.round(cadence_info.percent_cadence.high)}%</p>
            </div>
            <ProgressBar
              percent={Math.round(cadence_info.percent_cadence.high)}
              styles={{ height: '3px' }}
              color="#F13C46"
            />
          </div>
          <div className={styles.rowStats}>
            <div className={styles.data}>
              <p className={styles.name}>Cadencia máxima</p>
              <p className={styles.value}>{maxTimeCadence}</p>
              <p className={styles.value}>{cadence_info.max_cadence.value} rpm</p>
            </div>
            <div className={styles.dividerHor}></div>
          </div>
        </div>
      </div>
      <div className={styles.divider2}></div>
      <div className={styles.zones}>
        <div className={styles.header}>ZONAS</div>
        <TrainingClassZones zonesPercentages={zonesPercentages()} />
      </div>
    </div>
  );
}
