import Cookies from 'js-cookie';
import dayjs from 'dayjs';

const Storage = {
  localstorage: { load: _loadFromStorage, save: _saveToStorage, clear: _clearStorage },
  cookies: { load: _loadFromCookie, save: _saveToCookie, clear: _clearCookies },
};

export async function loadAuth() {
  const data = Storage.localstorage.load() || Storage.cookies.load();

  return data
    ? {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        expiresAt: dayjs(data.expiresAt),
        tokenType: data.tokenType,
      }
    : {};
}

export async function saveAuth(jsonData) {
  const expiresAt = jsonData.expires_in
    ? dayjs().add(jsonData.expires_in, 'second').toJSON()
    : dayjs(jsonData.expires_at);

  const data = {
    v2_access_token: jsonData.access_token,
    v2_refresh_token: jsonData.refresh_token,
    v2_token_type: jsonData.token_type,
    v2_expires_at: expiresAt,
  };

  Storage.localstorage.save({
    v2_access_token: jsonData.access_token,
    v2_refresh_token: jsonData.refresh_token,
    v2_token_type: jsonData.token_type,
    v2_expires_at: expiresAt,
  });

  Storage.cookies.save({
    access_token: jsonData.access_token,
    refresh_token: jsonData.refresh_token,
    token_type: jsonData.token_type,
    access_token_expires_at: expiresAt,
  });

  return {
    accessToken: data.v2_access_token,
    tokenType: data.v2_token_type,
    refreshToken: data.v2_refresh_token,
    expiresAt: dayjs(data.v2_expires_at),
  };
}

export function clearAuth() {
  Storage.localstorage.clear();
  Storage.cookies.clear();

  return {};
}

function _loadFromStorage() {
  const session = localStorage.getItem('session');
  const jsonObject = session ? JSON.parse(session) : null;

  return (
    jsonObject && {
      accessToken: jsonObject.v2_access_token,
      tokenType: jsonObject.v2_token_type,
      refreshToken: jsonObject.v2_refresh_token,
      expiresAt: dayjs(jsonObject.v2_expires_at),
    }
  );
}

function _loadFromCookie() {
  const session = Cookies.get('_bc');
  const jsonObject = session ? JSON.parse(session) : null;

  return (
    jsonObject && {
      accessToken: jsonObject.access_token,
      tokenType: 'bearer',
      refreshToken: jsonObject.refresh_token,
      expiresAt: dayjs(jsonObject.access_token_expires_at),
    }
  );
}

function _saveToStorage(data) {
  localStorage.setItem('session', JSON.stringify(data));
}

function _saveToCookie(data) {
  Cookies.set('_bc', JSON.stringify(data));
}

function _clearStorage() {
  localStorage.removeItem('session');
}

function _clearCookies() {
  Cookies.remove('_bc');
}
