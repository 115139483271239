import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNav } from '../contexts/nav.context';
import ProfileConfig from '../components/ProfileConfig';
import useDocumentTitle from '@rehooks/document-title';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ANALYTICS_EVENTS from '@/constants/analytics_events';

const ProfileConfigPage = () => {
  useDocumentTitle('Bestcycling');

  const { pathname } = useLocation();
  const { dispatch } = useNav();
  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, ANALYTICS_EVENTS.CONFIGURE_USER_START);
    dispatch({ type: 'nav-hide' });
    return () => {
      dispatch({ type: 'nav-show' });
    };
  }, []);

  const step = Number(pathname.match(/\d$/)?.[0]);
  const totalSteps = 5;

  return <ProfileConfig step={step} totalSteps={totalSteps} />;
};

export default ProfileConfigPage;
