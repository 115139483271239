import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import { LoginForm } from '../components/LoginPage';
import useDocumentTitle from '@rehooks/document-title';

export default function LoginPage() {
  useDocumentTitle('Acceso | Bestcycling');
  const {
    state: { isLoggedIn },
  } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" replace={true} />;
  }

  return <LoginForm />;
}
