import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import Page from '@/components/Page';
import Button from '@/components/UI/Button';
import styles from './ForbiddenPage.module.scss';
import { FooterMin } from '@/components/Footer';

export default function ForbiddenPage() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const close = () => {
    logout();
    navigate('/');
  };
  console.log('justo antes del return');
  return (
    <>
      <Page footer={<FooterMin />}>
        <div className={styles.container}>
          <div className={styles.title}></div>

          <div className={styles.body}>
            <div className={styles.bodyText}>
              Este usuario ha sido bloqueado por violar las condiciones de uso del portal. Si cree
              que es un error envíe un mensaje a{' '}
              <a className={styles.link} href="mailto:info@bestcycling.es">
                {' '}
                info@bestcycling.es
              </a>{' '}
              con sus datos de usuario.
            </div>
            <Button
              variant="primary"
              size="medium"
              title="CERRAR"
              style={{ margin: 'auto' }}
              onClick={() => close()}
            >
              CERRAR
            </Button>
          </div>
        </div>
      </Page>
    </>
  );
}
