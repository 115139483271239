import styles from './TrainingClassContainer.module.scss';

type TrainingClassContainerProps = {
  children?: React.ReactNode;
}

const TrainingClassContainer = ({ children }: TrainingClassContainerProps) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default TrainingClassContainer;