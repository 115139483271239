export const FTP_ATTRIBUTES = {
  GENDER: 'SEXO',
  WEIGHT: 'PESO',
  FITNESS: 'ESTADO DE FORMA',
  FTP: 'FTP',
};

export enum FtpUpdateTypes {
  decrease = 'decrease',
  increase = 'increase',
}
