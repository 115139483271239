import { getTrainingClassDuration } from './../filters';

// TODO: temporal solution for 1 duration filter with 2 values (min and max)
// Every filter must be typed in order to remove this

/**
 * Maps filters if a filter may return +1 value with the same filter
 * 
 * @param filters mapped filters
 * @returns 
 */
const mapTrainingClassesFilters = (filters: any) => {
  const mapDurationFilter = (filter: any) => {
    const duration = getTrainingClassDuration(filter);
    if (!duration) return {};

    return {
      min_duration_training_minutes: duration?.minDuration,
      max_duration_training_minutes: duration?.maxDuration,
    }
  }

  const mapFilter = (key: String, filter: any) => {
    switch (key) {
      case 'duration':
        return mapDurationFilter(filter);
      default:
        return filter;
    }
  }

  const mapFilters = (filters: any): any => {
    return Object.keys(filters).reduce((acc, key) => {
      const filter = mapFilter(key, filters[key]);
      if (!filter) return acc;

      return typeof filter == 'object' ? 
        { ...acc, ...mapFilters(filter) } : 
        { ...acc, [key]: filter };
    }, {});
  }

  return mapFilters(filters);
}

export default mapTrainingClassesFilters;