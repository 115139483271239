import closeIcon from '../../../../img/icon-close.svg';
import styles from './Tag.module.scss';
import styleColor from './ColorCircle.module.scss';
import clsx from 'clsx';

type TagProps = {
  onClick?: () => void;
  isSelected?: boolean;
  variant?: 'all' | 'selected' | 'unselected' | 'invisible' | 'rectangle' | 'normal-style';
  colorButton?: string;
  children: React.ReactNode;
  onInvisible?: () => void;
  bgColor?: string;
  closeTag?: () => void;
}

const Tag = ({
  onClick,
  isSelected,
  variant = 'all',
  colorButton = '',
  children,
  bgColor,
  closeTag,
  ...props
}: TagProps) => {
  const containerClassNames = clsx([
    variant === 'normal-style' ? styles.genre : styles.tag,
    variant && styles[variant],
    bgColor && styleColor[bgColor],
    bgColor == null && styles.$bgcolor,
    isSelected && styles.selected,
  ]);

  const colorClassNames = clsx([styles.color, colorButton && styleColor[colorButton]]);

  return (
    variant === 'normal-style'
      ? (
        <div className={containerClassNames} tabIndex={-1} {...props}>
          {children}
        </div >
      ) : (
        <button className={containerClassNames} onClick={onClick} tabIndex={-1} {...props}>
          <div className={colorClassNames}></div>
          {children}
          {closeTag && (
            <img
              src={closeIcon}
              alt=""
              style={{ height: '10px', marginRight: '0px', marginLeft: '10px', marginBottom: '-0px' }}
            />
          )}
        </button>

      )
  );
}

export default Tag;