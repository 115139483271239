import { useContext } from 'react';
import { useLocation } from 'react-router';
import TrainingClass from './TrainingClass';
import styles from './TrainingClassesList.module.scss';
import FiltersContext from '@/core/contexts/filters.context';

interface TrainingClassesListProps {
  data: TrainingClass[];
  showTagList?: boolean;
  onSelect?: (id: string | number) => void;
  emptyElement?: React.ReactNode;
}

export default function ListTrainingClasses({
  data,
  showTagList = true,
  onSelect,
}: TrainingClassesListProps) {
  const { pathname } = useLocation();
  const { filters } = useContext(FiltersContext);

  const isBestPro = pathname.includes('bestpro');

  const genreIdFiltered = filters?.genres ? parseInt(filters?.genres) : undefined;

  return (
    <div data-cy="training_classes_list" className={styles.trainingClassList}>
      {data.map((item, index) => (
        <div className={styles.trainingClassListItem} key={index}>
          <TrainingClass
            trainingClass={item}
            showTagList={showTagList}
            onSelect={typeof onSelect == 'function' ? () => onSelect(item.id) : undefined}
            key={index}
            isBestPro={isBestPro}
            genreIdFiltered={genreIdFiltered}
          />
        </div>
      ))}
    </div>
  );
}
