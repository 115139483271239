import FindClassesPage from './FindClassesPage';

const FavouriteClassesPage = () => {
  return (
    <>
      <FindClassesPage section="favourites" official={true} />
    </>
  );
};

export default FavouriteClassesPage;
