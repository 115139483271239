import { useShellContext } from '../../contexts/shell.context';
import styles from './Footer.module.scss';

export default function Footer() {
  const { state: shell } = useShellContext();

  if (shell.desktop) return null;

  return (
    <div className={styles.container} id="footer">
      <div className={styles.footerMenu}>
        <div className={styles.colLg15}>
          <h3>PRODUCTOS</h3>
          <ul>
            <li>
              <a lang="en" href="/life">
                Bestcycling
              </a>
            </li>
            <li>
              <a href="/bestpro">BestPro</a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.cicloindoor.com/pages/cursos-y-escuela"
              >
                Cursos y eventos
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.cicloindoor.com/20555/tienda-online-bestcycling"
              >
                Tienda online
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.colLg15}>
          <h3>AYUDA Y CONTACTO</h3>
          <ul>
            <li>
              <a href="/page/beneficios">Beneficios y afiliados</a>
            </li>
            <li>
              <a href="/page/ayuda">Ayuda</a>
            </li>
            <li>
              <a href="/page/blog">Blog</a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href="//community.bestcycling.es">
                Foros
              </a>
            </li>
            <li>
              <a href="/page/contacto">Contacta</a>
            </li>
            <li>
              <a href="/page/trabaja-con-nosotros">Trabaja con nosotros</a>
            </li>
            <li>
              <a href="/page/covid19">Afectados COVID19</a>
            </li>
          </ul>
        </div>
        <div className={styles.colLg15}>
          <h3>COMPAÑIA</h3>
          <ul>
            <li>
              <a href="/page/info">Historia</a>
            </li>
            <li>
              <a href="/page/info">Prensa</a>
            </li>
          </ul>
        </div>
        <div className={styles.colLg15}>
          <h3>LEGAL</h3>
          <ul>
            <li>
              <a href="/pages/condiciones-de-uso">Condiciones</a>
            </li>
            <li>
              <a href="/pages/politica-de-privacidad">Privacidad</a>
            </li>
            <li>
              <a href="/pages/aviso-legal">Aviso legal</a>
            </li>
          </ul>
        </div>
        <div className={styles.colLg15}>
          <h3>SOCIAL</h3>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/bestcycling/">
                Instagram
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/bestcycling">
                Facebook
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/user/BestcyclingTV1"
              >
                Youtube
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/bestcycling">
                Twitter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.legal}>
        <span>© Bestcycling S.L. All Rights Reserved</span>
        <span>SGAE. Nº de licencia: SGAERRDD/1/103/0113</span>
      </div>
    </div>
  );
}
