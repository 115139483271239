import useFetchAppPreferences from '@/core/hooks/apiHooks/useFetchAppPreferences';
import usePatchAppPreferences from '@/core/hooks/apiHooks/usePatchAppPreferences';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import PreferencesAdapter from '../adapters/preferences_adapter';

const localKey = 'preferences_updated_at';

const getUpdatedAt = () => {
  return localStorage.getItem(localKey) ?? dayjs().subtract(1, 'year').toISOString();
};

const saveUpdatedAt = (updatedAt: string) => {
  localStorage.setItem(localKey, updatedAt);
};

/**
 * This hook fetches and patches the remote preferences.
 * Should not be reused.
 *
 * Syncs local and remote preferences based on the updated_at property.
 * The hook tries to update remote or local preferences depending on which update_at is bigger
 *
 * Ensures to sync preferences if needed on window focus and
 * network connection recovery.
 *
 * @returns {canSyncPreferences, syncPreferences, updateAppPreferences}
 */
const useSyncPreferences = () => {
  const { data, isLoading, isSuccess, isRefetching, refetch } = useFetchAppPreferences();
  const updatePreferences = usePatchAppPreferences();

  const updateAppPreferences = (preferences: any) => {
    saveUpdatedAt(new Date().toISOString());
    updatePreferences.mutate({
      updated_at: getUpdatedAt(),
      ...PreferencesAdapter.toRemote({ ...preferences }),
    });
  };

  const syncPreferences = (
    currentPreferences: any,
    savePreferences: (preferences: any) => void,
  ) => {
    const { data: preferences } = data;

    if (!preferences) {
      updateAppPreferences(currentPreferences);
      return;
    }

    const remoteUpdatedAt = dayjs(new Date(preferences.updated_at));
    const remoteCreatedAt = dayjs(new Date(preferences.created_at));

    if (remoteCreatedAt.isSame(remoteUpdatedAt)) {
      updateAppPreferences(currentPreferences);
      return;
    }

    const localUpdatedAt = dayjs(new Date(getUpdatedAt()));

    if (remoteUpdatedAt.isBefore(localUpdatedAt)) {
      updateAppPreferences(currentPreferences);
    } else if (remoteUpdatedAt.isAfter(localUpdatedAt)) {
      saveUpdatedAt(preferences.updated_at);
      savePreferences(PreferencesAdapter.toLocal(preferences));
    }
  };

  useEffect(() => {
    const onChange = () => refetch();

    window.addEventListener('focus', onChange);
    window.addEventListener('online', onChange);

    return () => {
      window.removeEventListener('focus', onChange);
      window.removeEventListener('online', onChange);
    };
  }, []);

  useEffect(() => {
    if (!updatePreferences.isSuccess) {
      return;
    }

    const preferences = updatePreferences.data.data;
    const updatedAt = new Date(preferences.updated_at).toISOString();

    saveUpdatedAt(updatedAt);
    refetch();
  }, [updatePreferences.data, updatePreferences.isSuccess]);

  return {
    canSyncPreferences: !isLoading && !isRefetching && isSuccess && !!data,
    syncPreferences,
    updateAppPreferences,
  };
};

export default useSyncPreferences;
