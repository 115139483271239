import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import SectionTitle from '../SectionTitle';
import LoadingIndicator from '../LoadingIndicator';
import HomePlaylistsSlider from '../Playlists/HomePlaylistsSlider';

export default function CollectionsSection({ title = 'Colecciones', collections }) {
  const params = { filters: { id: collections } };
  const { fetchApi, getNextPageParam } = useApi();
  const { data, isLoading, isSuccess, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['playlists', params],
      ({ pageParam }) => fetchApi(['playlists', { ...params, cursor: pageParam }]),
      {
        getNextPageParam,
      },
    );

  const playlists = data?.pages?.map(page => page.data).flat();

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      {isLoading && <LoadingIndicator />}
      {isSuccess && (
        <HomePlaylistsSlider
          playlists={playlists}
          isFetchingNextPage={isFetchingNextPage}
          isSuccess={isSuccess}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      )}
    </>
  );
}
