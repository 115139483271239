import { useEffect } from 'react';
import Button from '../UI/Button';
import styles from './ConfirmDialog.module.scss';

interface ConfirmDialogProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  onConfirm?: () => void;
  className?: string;
  description?: string;
  confirmText?: string;
}

export default function ConfirmDialog({
  isOpen,
  onRequestClose,
  onConfirm,
  className,
  description,
  confirmText,
}: ConfirmDialogProps) {
  const closeModal = () => {
    if (onRequestClose) onRequestClose();
  };

  const confirmModal = () => {
    if (onConfirm) onConfirm();
    closeModal();
  };

  const keyboardListener = (evt: any) => {
    if (evt.key == 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyboardListener);
    return () => {
      window.removeEventListener('keyup', keyboardListener);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleClose = (evt: any) => {
    evt.preventDefault();
    closeModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.shadow} onClick={handleClose}></div>
      <div className={`${styles.modal} ${className || ''}`}>
        <p className={styles.title}>{description}</p>
        <div className={styles.buttons}>
          <Button variant="terciary" onClick={closeModal} style={{ marginRight: 15 }}>
            Cancelar
          </Button>

          <Button variant="primary" onClick={confirmModal}>
            {confirmText || 'Sí, eliminar'}
          </Button>
        </div>
      </div>
    </div>
  );
}
