import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import SectionTitle from '../SectionTitle';
import LoadingIndicator from '../LoadingIndicator';
import TrainingClassesCarousel from '../TrainingClasses/TrainingClassesCarousel';
import { useUser } from '@/contexts/app.context';
import { useRemoteConfig } from '@/contexts/remote.context';
import NpsSurveyCard from '../Surveys/nps/NpsSurveyCard';

type TrainingClassesSectionProps = {
  title: string;
  filters: any;
  showNps: boolean;
};

const TrainingClassesSection = ({
  title = 'Nuevo esta semana',
  filters,
  showNps,
}: TrainingClassesSectionProps) => {
  const { state: user } = useUser();
  const { isFreeAccessEnabled } = useRemoteConfig();

  const sort =
    !user.isSubscribed() && isFreeAccessEnabled() ? ['-free', '-published_at'] : ['-published_at'];

  const { fetchApi } = useApi();
  const { data, isSuccess, isLoading } = useQuery(['recent_training_classes', filters], () =>
    fetchApi([
      'training_classes',
      {
        include: ['trainer', 'training'],
        fields: { training: 'seconds' },
        filters: { official: true, ...filters },
        sort,
      },
    ]),
  );

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      {isLoading && (
        <div style={{ height: 250 }}>
          <LoadingIndicator />
        </div>
      )}
      {isSuccess && (
        <TrainingClassesCarousel dataCy="training_classes_slider" trainingClasses={data.data}>
          {showNps && <NpsSurveyCard />}
        </TrainingClassesCarousel>
      )}
    </>
  );
};

export default TrainingClassesSection;
