export enum TrainingClassDuration {
  light = "light",

  moderate = "moderate",

  intense = "intense",

  extended = "extended",
}

/**
 * Maps [TrainingClassDuration] enum to duration object which includes
 * min and max duration
 * 
 * @param duration object with [minDuration] and [maxDuration] if required
 * @returns 
 */
export const getTrainingClassDuration = (duration: String) => {
  switch (duration) {
    case TrainingClassDuration.light:
      return { maxDuration: 30, };
    case TrainingClassDuration.moderate:
      return { minDuration: 31, maxDuration: 39, };
    case TrainingClassDuration.intense:
      return { minDuration: 40, maxDuration: 50, };
    case TrainingClassDuration.extended:
      return { minDuration: 50, };
    default: return null;
  }
}