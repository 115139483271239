import { createContext, useContext, useEffect } from 'react';
import { useClient, useQuery } from 'jsonapi-react';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';

import usePageName from '@/hooks/usePageName';
import { useAppContext } from './app.context';
import { useApi } from '@/api/ApiProvider';
import { useQuery as useReactQuery } from '@tanstack/react-query';

const AnalyticsContext = createContext({});

export const AnalyticsProvider = ({ children }) => {
  const analytics = getAnalytics();
  const { user } = useAppContext().state;
  const { fetchApi } = useApi();

  const client = useClient();
  const { data: firebase_events } = useQuery(['firebase_events']);
  const userAnalyticsPropierties = useReactQuery(['user_analytics_properties'], () =>
    fetchApi(['user_analytics_properties']),
  );

  const dispatchEvent = async event => {
    let eventToUpdate = {
      ...event,
      locked_at: new Date().toJSON(),
      locked_by: window.navigator.userAgent,
    };

    await client.mutate(['firebase_events', event.id], eventToUpdate);
    if (eventToUpdate.params == null) {
      logEvent(analytics, event.name);
    } else {
      logEvent(analytics, event.name, event.params);
    }
    await client.delete(['firebase_events', event.id]);
  };

  const retrieveEvents = () => {
    if (firebase_events)
      firebase_events.forEach(event => {
        dispatchEvent(event);
      });
  };

  const checkPropertiesHasChanged = (localData, remoteData) =>
    JSON.stringify(localData) !== JSON.stringify(remoteData);

  const savedLocalProperties = remoteUserAnalytics =>
    localStorage.setItem('userAnalyticsProperties', JSON.stringify(remoteUserAnalytics));

  const getLocalProperties = () => JSON.parse(localStorage.getItem('userAnalyticsProperties'));

  const updateFirebaseUserProperties = () => {
    const localUserAnalytics = getLocalProperties();
    const remoteUserAnalytics = userAnalyticsPropierties.data.data;
    const propertiesHasChanged = checkPropertiesHasChanged(localUserAnalytics, remoteUserAnalytics);

    if (propertiesHasChanged || localUserAnalytics === null) {
      console.log('User properties has changed, updating...');
      setUserProperties(analytics, remoteUserAnalytics);
      savedLocalProperties(remoteUserAnalytics);
    }
  };

  const startUserAnalytics = () => {
    const localUserAnalytics = getLocalProperties();

    if (localUserAnalytics === null) {
      console.log("Starting user's analytics...")
      setUserId(analytics, user.id);
    }
  };

  useEffect(() => {
    if (user && user.id !== null && userAnalyticsPropierties.data) {
      startUserAnalytics();
      updateFirebaseUserProperties();
    }
  }, [userAnalyticsPropierties, user]);

  useEffect(() => {
    if (firebase_events && firebase_events.length > 0) retrieveEvents();
  }, [firebase_events]);

  usePageName('main_loaded');

  return (
    <AnalyticsContext.Provider value={{ analytics, logEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => useContext(AnalyticsContext);
