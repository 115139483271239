import { useEffect, useState, useRef } from 'react';

export default function useResizeObserver(targetRef) {
  const [contentRect, setContentRect] = useState({});
  const resizeObserver = useRef(null);

  const disconnect = () => {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
  };

  useEffect(() => {
    const observe = ResizeObserver => {
      resizeObserver.current = new ResizeObserver(entries => {
        const { width, height, top, right, bottom, left } = entries[0].contentRect;
        setContentRect({ width, height, top, right, bottom, left });
      });
      if (targetRef.current) {
        resizeObserver.current.observe(targetRef.current);
      }
    };

    if ('ResizeObserver' in window) {
      observe(ResizeObserver);
    } else {
      import('resize-observer-polyfill').then(observe);
    }

    return disconnect;
  }, [targetRef]);

  return { ref: targetRef, ...contentRect };
}
