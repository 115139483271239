import { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useUser, useProfile } from '@/contexts/app.context';

import Header from './Header';

import ProfileConfig1 from './ProfileConfig1';
import ProfileConfig2 from './ProfileConfig2';
import ProfileConfig3 from './ProfileConfig3';
import ProfileConfig4 from './ProfileConfig4';
import ProfileConfig5 from './ProfileConfig5';

import styles from './ProfileConfig.module.scss';
import usePathRecovery from '@/core/hooks/usePathRecovery';
import useFirstExperience from '@/core/hooks/useFirstExperience';

const ProfileConfigForm = ({ step, totalSteps }) => {
  const navigate = useNavigate();

  const { state: user } = useUser();
  const { state: profile } = useProfile();

  const { sex, birthday } = user;
  const { fit_status, training_motivations, training_activities } = profile;

  const { consumePath } = usePathRecovery();
  const { checkAsFirstExperience } = useFirstExperience();

  const [formData, setFormData] = useState({
    user: {
      sex,
      birthday,
    },
    profile: {
      fit_status,
      training_motivations: training_motivations.map(m => +m.id),
      training_activities: training_activities.map(a => +a.id),
    },
  });

  const onFinish = firstExperience => {
    const path = consumePath();
    const url = `${path ?? '/'}`;

    checkAsFirstExperience();
    navigate(url);
  };

  const onSubmit = data => {
    if (data.user) {
      setFormData({ ...formData, user: { ...formData.user, ...data.user } });
    }
    if (data.profile) {
      setFormData({ ...formData, profile: { ...formData.profile, ...data.profile } });
    }

    if (data.user || data.profile) {
      navigate(`${step + 1}`);
    }
  };

  return (
    <div className={styles.container}>
      <span className={styles.steps}>
        Paso {step} de {totalSteps}
      </span>

      <Routes>
        <Route path="1" element={<ProfileConfig1 user={formData.user} onSubmit={onSubmit} />} />
        <Route
          path="2"
          element={<ProfileConfig2 profile={formData.profile} onSubmit={onSubmit} />}
        />
        <Route
          path="3"
          element={<ProfileConfig3 profile={formData.profile} onSubmit={onSubmit} />}
        />
        <Route
          path="4"
          element={<ProfileConfig4 profile={formData.profile} onSubmit={onSubmit} />}
        />
        <Route path="5" element={<ProfileConfig5 formData={formData} onFinish={onFinish} />} />
        <Route path="*" element={<Navigate to="1" />} />
      </Routes>
    </div>
  );
};

export default function ProfileConfig({ step, totalSteps }) {
  return (
    <div className={styles.profile}>
      <Header />
      <ProfileConfigForm step={step} totalSteps={totalSteps} />
    </div>
  );
}
