import { Link } from 'react-router-dom';
import LifePoint from '@/core/components/LifePoint/LifePoint';
import TriangleClip from '@/core/components/TriangleClip/TriangleClip';
import TrainingClassContainer from '../../TrainingClass/TrainingClassContainer/TrainingClassContainer';
import TrainingClassCover2 from '../../TrainingClass/TrainingClassContainer/TrainingClassCover2';
import TrainingClassMaterials from '../../TrainingClass/TrainingClassMaterials';
import { MindContent, DefaultContent } from './index.jsx';
import styles from './RelatedTrainingClassCard.module.scss';

type RelatedTrainingClassCardProps = {
  trainingClass: TrainingClass;
};

const RelatedTrainingClassCard = ({ trainingClass }: RelatedTrainingClassCardProps) => {
  const toPath = `/trainingclass/${trainingClass.id}`;
  const isMind = trainingClass.category_nr === 21;

  const getTitle = () => {
    const title = trainingClass.title;
    const arr = title.split('-');

    return arr.length ? arr[0] : title;
  }


  return (
    <TrainingClassContainer>
      <Link to={toPath} className={styles.container}>
        <TriangleClip categoryNr={trainingClass.category_nr} />
        <div className={styles.main}>
          <TrainingClassCover2 displayOverlay={false} trainingClass={trainingClass}>
            <div className={styles.points}>
              <LifePoint size='small' type="resistance" value={trainingClass.life_resistance_points} />
              <LifePoint size='small' type="force" value={trainingClass.life_force_points} />
              <LifePoint size='small' type="flexibility" value={trainingClass.life_flexibility_points} />
              <LifePoint size='small' type="mind" value={trainingClass.life_mind_points} />
            </div>
            <div className={styles.materials}>
              <TrainingClassMaterials trainingClass={trainingClass} />
            </div>
          </TrainingClassCover2>
        </div>

        <div className={styles.content}>
          <div className={styles.primaryContent}>
            {!isMind && (<DefaultContent getTitle={getTitle} trainingClass={trainingClass} />)}
            {isMind && (<MindContent getTitle={getTitle} trainingClass={trainingClass} />)}
          </div>
        </div>


      </Link>
    </TrainingClassContainer>
  );
}

export default RelatedTrainingClassCard;