import SectionTitle from '../SectionTitle';
import LoadingIndicator from '../LoadingIndicator';
import PostsCarousel from '../Posts/PostsCarousel';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';

export default function PostsSection({ title = 'Últimas noticias', filters }) {
  const { fetchApi } = useApi();

  const { data, isLoading, isError } = useQuery(['posts', filters], () =>
    fetchApi(['posts', { filter: { ...filters } }])
  );

  if (!isLoading && isError) {
    return null;
  }

  const onClick = post => {
    window.open(post.url, '_blank').focus();
  };

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      {isLoading ? <LoadingIndicator /> : <PostsCarousel posts={data.data} onClick={onClick} />}
    </>
  );
}
