import style from './TrainingClass.module.scss';
import { useMemo } from 'react';
import { formatDuration, formatPublishedAt } from '../utils';
import TrainingClassTagsList from '@/components/TagsList/TrainingClassTagsList';
import clsx from 'clsx';
import TrainingClassGenreTagsList from '../../Genres/TrainingClassGenreTagsList';
import TriangleClip from '../../TriangleClip/TriangleClip';

type TrainingClassExtraProps = {
  trainingClass: TrainingClass;
  showTagList: boolean;
  genreIdFiltered?: number;
};

export default function TrainingClassExtra({
  trainingClass,
  showTagList = true,
  genreIdFiltered,
}: TrainingClassExtraProps) {
  const publishedAt = useMemo(
    () => formatPublishedAt(trainingClass.published_at),
    [trainingClass.published_at],
  );

  const duration = useMemo(
    () =>
      formatDuration(
        trainingClass.official ? trainingClass.duration_training : trainingClass.duration_seconds,
      ),
    [trainingClass.duration_training, trainingClass.duration_seconds],
  );

  const isOfficial = trainingClass.official;

  const classNames = clsx(style.extra);

  return (
    <div className={classNames}>
      <TriangleClip categoryNr={trainingClass.category_nr} />

      <div className={style.row}>
        {isOfficial && trainingClass.category_nr !== 21 && (
          <div className={style.levelnr}>
            Nivel
            {[10, 20, 30].map(item => (
              <div
                key={`${trainingClass.id}-${item}`}
                className={item <= trainingClass.level_nr ? style.on : style.off}
              />
            ))}
          </div>
        )}
        <div className={style.year}>{publishedAt}</div>
        <div className={style.duration}>Duración: {duration}&apos;</div>
      </div>

      {trainingClass.official && (
        <div className={style.row}>
          <div className={style.trainingtype}>{trainingClass.training_type}</div>
        </div>
      )}

      {genreIdFiltered && (
        <TrainingClassGenreTagsList
          genreTagsList={trainingClass?.training_genres || []}
          genreIdFiltered={genreIdFiltered}
        />
      )}

      {showTagList && (
        <div className={style.row}>
          <TrainingClassTagsList tagList={trainingClass.user_tags} />
        </div>
      )}
    </div>
  );
}
