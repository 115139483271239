import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from '@/api/ApiProvider';

export default function useFetchUserTags() {
  const { fetchApi, getNextPageParam } = useApi();

  const { data, isLoading, isFetching, fetchNextPage } = useInfiniteQuery<any, any, UserTag[]>(
    ['user_tags'],
    async ({ pageParam }) => {
      const res = await fetchApi([
        'user_tags',
        {
          limit: 50,
          sort: 'position',
          cursor: pageParam,
        },
      ]);

      return res.data;
    },
    {
      getNextPageParam: lastPage => getNextPageParam(lastPage),
    },
  );

  const dupData = data?.pages?.map(page => page).flat();

  const nonDupData = dupData?.filter(
    (item, index) => dupData.map(v => v.id).indexOf(item.id) === index,
  );

  useEffect(() => {
    if (!isFetching && !isLoading && data) {
      if (data.pages[data.pages.length - 1].length != 0) fetchNextPage();
    }
  }, [isFetching]);

  return {
    data: nonDupData,
    isLoading,
    isFetching,
  };
}
