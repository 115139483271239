import clsx from 'clsx';
import React, { MouseEvent } from 'react';
import styles from './EditableMenuItem.module.scss';

export type DropdownItem = {
  title: string,
  icon?: React.ReactNode | any, // what is this
  onClick(): void
}

const EditableMenuItem = (props: DropdownItem) => {
  const buttonIconClasses = clsx("menu-item-icon", styles.icon);
  const buttonTextClasses = clsx("menu-item-text lato-normal-pink-swan-14px", styles.text);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  }

  return (
    <li className={styles.editableMenuItem} >
      <button
        aria-label={props.title}
        onClick={onClick} >
        <div className={buttonIconClasses}>
          {
            React.isValidElement(props.icon) ?
              <span className={styles.img}>
                {
                  props.icon
                }
              </span> :
              <img className="icon" src={props.icon} />
          }
        </div>
        <div className={buttonTextClasses}>{props.title}</div>
      </button>
    </li>
  );
}

export default EditableMenuItem