import useSetting from '@/components/Preferences/useSetting';
import { useProfile, useUser } from '@/contexts/app.context';
import { useShell, useShellContext } from '@/contexts/shell.context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePathRecovery from './usePathRecovery';

/**
 * After the login it will redirect the user depending on its membership and prefs
 */
export default function useHandleRedirectHome() {
  const shell = useShell();
  const { state: shellState } = useShellContext();
  const isDesktop = shellState.desktop;

  const { state: profile } = useProfile();
  const { state: user } = useUser();
  const [autoStartScheduler] = useSetting('C11');
  const [autoRedirectToGyms] = useSetting('auto_redirect_to_gyms');
  const [defaultRoom] = useSetting('default_room');

  const { savePath, consumePath } = usePathRecovery();

  const navigate = useNavigate();

  const isMemberGyms = user.current_membership.plan === 'gimnasios';

  useEffect(() => {
    const path = consumePath();

    const autoStartSched =
      autoStartScheduler == 'true' || autoStartScheduler == '1' || autoStartScheduler == true;

    if (isMemberGyms) {
      if (sessionStorage.getItem('gymRedirected') == 'true') {
        return;
      }
      if (isDesktop && autoStartSched) {
        navigate(`/gyms/rooms/${defaultRoom}/play`);
        sessionStorage.setItem('gymRedirected', 'true');
        return;
      }
      if (autoRedirectToGyms) {
        navigate('/gyms');
        sessionStorage.setItem('gymRedirected', 'true');
      }
    } else if (profile?.needInitialConfig()) {
      if (path) savePath(path);
      navigate('/profile/config');
    }
  }, [profile, shell.state]);
}
