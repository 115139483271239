const htmlUtils = () => {
  const sanitize = (data: string) => {
    // If necessary, add the sanitize logic to avoid javascript injection

    return data;
  }

  const format = (data: string) => {
    return data.replace(/(?<!<\/?[a-zA-Z]+>)\n/g, '<br />');
  }

  return {
    sanitize,
    format,
  }
}

export default htmlUtils();