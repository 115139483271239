import { Filter } from './Filter';

export function TrainingLevelFilter() {
  const level = [
    { value: '10', label: 'Principiante', type: 'level_nr' },
    { value: '20', label: 'Medio', type: 'level_nr' },
    { value: '30', label: 'Avanzado', type: 'level_nr' },
  ];

  return <Filter dataCy="training_level_filter" options={level} type="level_nr" tag="NIVEL" />;
}
