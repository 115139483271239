import HeartEmoji from '@/img/surveys/HeartEmoji';
import styles from './NpsSurveyModal.module.scss';

const NpsSurveyModalFinishedState = () => {
  return (
    <>
      <HeartEmoji width={50} />
      <span className={styles.title}>¡Muchas gracias!</span>
      <span className={styles.subtitle}>Tus comentarios nos motivan a seguir mejorando.</span>
    </>
  )
}

export default NpsSurveyModalFinishedState;