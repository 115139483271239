import s from './OfflineErrorPopup.module.scss';
import offlineIcon from '@/img/offlineIndicator-icon.svg';
import clsx from 'clsx';

type OfflineErrorPopupProps = {
  isBig?: boolean;
  textH?: string;
  textP?: string;
  shadow?: boolean;
  textButton?: string;
  onClick?: () => void;
};

const OfflineErrorPopup = ({
  isBig = true,
  textH,
  textP,
  shadow = false,
  textButton,
  onClick = () => {},
}: OfflineErrorPopupProps) => {
  const reload = () => window.location.reload();

  if (isBig) {
    return (
      <div className={clsx(s.container_big, shadow && s.shadow)}>
        <div className={s.icon}>
          <img src={offlineIcon} alt="Icono no-conexión" />
        </div>

        <h2>
          {textH ||
            'No podemos mostrar el contenido de esta página. Comprueba tu conexión e inténtalo de nuevo.'}
        </h2>
        <div>
          <p>
            {textP == undefined
              ? 'Una vez visites esta página con conexión a internet podrás acceder a ella en modo offline.'
              : textP}
          </p>
        </div>
        <button onClick={onClick ?? reload}>{textButton ?? 'REINTENTAR'}</button>
      </div>
    );
  }

  return (
    <div className={clsx(s.container, shadow && s.shadow)}>
      <div className={s.icon}>
        <img src={offlineIcon} alt="Icono no-conexión" />
      </div>
      <div className={s.infoText}>
        <h2>No se ha podido establecer la conexión</h2>
        <button onClick={onClick ?? reload}>REINTENTAR</button>
      </div>
    </div>
  );
};

export default OfflineErrorPopup;
