import Page from '../components/Page';

import Title from '../components/Title/Title';
import Subscription from '../components/Subscription';
import useDocumentTitle from '@rehooks/document-title';
import { useUser } from '@/contexts/app.context';
import usePageName from '@/hooks/usePageName';
import { FooterMin } from '@/components/Footer';

const SubscriptionPage = () => {
  useDocumentTitle('Suscripción | Bestcycling');
  usePageName('subscription');
  const { state } = useUser();

  return (
    <Page footer={<FooterMin />}>
      <Title>Estado de tu suscripción</Title>
      <Subscription user={state} />
    </Page>
  );
};

export default SubscriptionPage;
