import logo1 from '../../../../img/logo-1@1x.png';

import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useResolvedPath } from 'react-router-dom';

import './Navigation.scss';
import { useNav } from '../../contexts/nav.context';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { useShell, useShellContext } from '../../contexts/shell.context';
import { useUser } from '@/contexts/app.context';
import Divider from './Divider';
import DownloadsLink from './DownloadsLink';
import NavigationLink from './NavigationLink';
import NotificationsIcon from './NotificationsIcon';
import SubscribeButton from './SubscribeButton';
import useSetting from '../Preferences/useSetting';
import { AyudaYContacto } from './AyudaYContacto';
import Chevron from '@/img/chevron';

const Logo = () => {
  return (
    <Link to="/" title="Home">
      <img lang="en" alt="Bestcycling" className="logo" src={logo1} />
    </Link>
  );
};

const CloseButton = () => {
  const { dispatch } = useNav();

  const handleClose = (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    dispatch({ type: 'nav-close' });
  };
  return <a href="#" className="menu-mobile-close" onClick={handleClose}></a>;
};

const BestcyclingMenu = () => {
  const { state: shell } = useShellContext();
  const [open, setOpen] = useState(false);

  const handleExpand = () => {
    setOpen(!open);
  };

  if (shell.desktop) return null;

  return (
    <div className={`menu-main-items`}>
      <NavigationLink to="/" external={false} variant="main">
        Bestcycling
      </NavigationLink>
      <Divider id="dividerMenuMainItems" />
    </div>
  );
};

const MainMenu = () => {
  const location = useLocation();

  const { state: shell } = useShell();
  const { state: nav, dispatch } = useNav();
  const { state: user } = useUser();

  const onClick = () => {
    dispatch({ type: 'nav-close' });
    dispatch({ type: 'nav-show-rooms-close' });
  };

  const onDownloadApp = () => {
    window.open('https://bestcycling.com/download-life', '_blank', 'noopener');
  };

  const [defaultRoom] = useSetting('default_room');
  const [linkScheduler, setLinkScheduler] = useState(`/gyms/rooms/${defaultRoom || 1}`);

  useEffect(() => {
    setLinkScheduler(`/gyms/rooms/${defaultRoom || 1}`);
  }, [defaultRoom, nav.showRooms]);

  return (
    <>
      <div className="sidebar-menu">
        <NavigationLink to="/" icon="home" onClick={onClick}>
          Explorar
        </NavigationLink>
        <NavigationLink to="/trainingclasses/search" icon="search" onClick={onClick}>
          Buscar clases
        </NavigationLink>
        <NavigationLink to="/playlists/0" icon="list" onClick={onClick}>
          Colecciones
        </NavigationLink>
        <NavigationLink to="/trainingclasses/favourites" icon="favourite" onClick={onClick}>
          Favoritas
        </NavigationLink>
        <DownloadsLink>
          <NavigationLink to="/offline" icon="download" onClick={onClick}>
            Clases Offline
          </NavigationLink>
        </DownloadsLink>
        <NavigationLink to="/nutrition" icon="nutrition" onClick={onClick}>
          Nutrición
        </NavigationLink>
        <NavigationLink to="/sensei" icon="sensei" onClick={onClick}>
          Sensei
        </NavigationLink>
        <NavigationLink
          to="/notifications"
          icon={
            <NotificationsIcon
              active={location.pathname == useResolvedPath('/notifications').pathname}
            />
          }
          onClick={onClick}
        >
          Social
        </NavigationLink>
        <NavigationLink to="/profile" icon="profile" onClick={onClick}>
          Mi perfil
        </NavigationLink>
        <Divider />
        <p className="menu-tools">HERRAMIENTAS</p>
        <NavigationLink to="/bestpro/home" icon="myclasses" onClick={onClick}>
          Mis clases
        </NavigationLink>
        <NavigationLink to="/bestpro/constructor0" icon="constructor" onClick={onClick}>
          Constructor de gráficas
        </NavigationLink>
        <NavigationLink to="/bestpro/express" icon="express" onClick={onClick}>
          Mezclador de clases
        </NavigationLink>
        {user.isGymsMembership() && (
          <NavigationScheduler linkScheduler={linkScheduler} setLinkScheduler={setLinkScheduler} />
        )}
      </div>
      <div className="menu-separator">
        {!shell.electron && (
          <NavigationLink to="" onClick={onDownloadApp} icon="downloadApp" external>
            Descargar app
          </NavigationLink>
        )}
        <Divider />
        <AyudaYContacto />
        <Divider />
        <NavigationLink to="/settings" icon="preferences" onClick={onClick}>
          Preferencias
        </NavigationLink>
      </div>
    </>
  );
};

type NavigationSchedulerProps = {
  linkScheduler: string;
  setLinkScheduler: (value: string) => void;
};

const NavigationScheduler = ({ linkScheduler, setLinkScheduler }: NavigationSchedulerProps) => {
  const { state: user } = useUser();
  const { state: nav, dispatch } = useNav();
  const location = useLocation();
  const maxSchedulerRooms = user.quotas?.max_scheduler_rooms ?? 1;
  const isPathGyms = location.pathname.startsWith('/gyms');
  const lastRoomRef = useRef<HTMLDivElement>(null);
  const roomsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (nav.showRooms) {
      const listContainer = roomsListRef.current;
      const lastElement = listContainer?.lastElementChild;
      if (listContainer && lastElement && lastRoomRef.current) {
        lastRoomRef.current.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [nav.showRooms]);

  return (
    <>
      {maxSchedulerRooms <= 1 ? (
        <NavigationLink
          to="/gyms"
          icon="planificador"
          onClick={() => dispatch({ type: 'nav-close' })}
        >
          Planificador de gimnasios
        </NavigationLink>
      ) : (
        <NavigationLink
          to={linkScheduler}
          icon="planificador"
          onClick={() => {
            dispatch({ type: 'nav-show-rooms-toggle' });
          }}
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="gymsSchedulerNav">
            <span style={{ fontSize: '13px' }}>Planificador de gimnasios</span>
            <div className="gymsMenuRoomsArrow">
              <Chevron isDown={!nav.showRooms} color={isPathGyms ? '#fff' : '#aaa'} />
            </div>
          </div>
        </NavigationLink>
      )}
      {maxSchedulerRooms > 1 ? (
        <div
          ref={roomsListRef}
          className={nav.showRooms ? 'menu-rooms-active' : 'menu-rooms-unactive'}
        >
          {[...Array(maxSchedulerRooms)].map((_, room) => (
            <NavigationLink
              key={`room${room}`}
              to={`/gyms/rooms/${room + 1}`}
              onClick={() => {
                dispatch({ type: 'nav-close' });
                setLinkScheduler(`/gyms/rooms/${room + 1}`);
              }}
              onClickCloseNav={true}
            >
              <span>Sala {room + 1}</span>
            </NavigationLink>
          ))}
          <div ref={lastRoomRef}></div>
        </div>
      ) : null}
    </>
  );
};

const GymsMenu = () => {
  const { dispatch } = useNav();

  const [showRooms, setShowRooms] = useState(true);
  const { state: user } = useUser();
  const maxSchedulerRooms = user.quotas?.max_scheduler_rooms ?? 1;

  const onClick = () => dispatch({ type: 'nav-close' });

  const hideRooms = (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    setShowRooms(!showRooms);
  };

  return (
    <>
      <div className="gymsMenuRoomsHeader" onClick={hideRooms}>
        <p className="gymsMenuRoomsTitle">SALAS</p>
        <div className="gymsMenuRoomsArrow">
          {showRooms ? (
            <i className="fa fa-chevron-up"></i>
          ) : (
            <i className="fa fa-chevron-down"></i>
          )}
        </div>
      </div>
      <Divider />
      {showRooms ? (
        <>
          {[...Array(maxSchedulerRooms)].map((_, room) => (
            <NavigationLink key={`room${room}`} to={`/gyms/rooms/${room + 1}`} onClick={onClick}>
              Sala {room + 1}
            </NavigationLink>
          ))}
        </>
      ) : null}

      <div className="menu-separator">
        <div onClick={onClick}>Ayuda y contacto</div>
        <Divider />
        <NavigationLink
          to="/settings"
          icon="preferences"
          onClick={onClick}
          state={{ from: 'gyms' }}
        >
          Preferencias
        </NavigationLink>
      </div>
    </>
  );
};

export default function Navigation({ user }: { user: IUser }) {
  const { state, dispatch } = useNav();
  const refMenuOpened = useRef(null);

  useOnClickOutside(['menu', 'help-dropdown-menu'], () => dispatch({ type: 'nav-close' }));

  return (
    <div
      className={`navigation ${state.opened ? 'menu-opened' : ''}`}
      ref={refMenuOpened}
      id="menu"
    >
      <Logo />
      <CloseButton />

      <div className="menu">
        {/* <BestcyclingMenu /> */}

        {user.isSubscribed() ? null : <SubscribeButton user={user} />}

        {/* {isPathGyms || fromPathGyms ? <GymsMenu /> :  */}
        <MainMenu />
      </div>
    </div>
  );
}
