import { useEffect } from 'react';
import { useQuery } from 'jsonapi-react';
import { useDownloads } from '../downloads.context';
import { useFileManager } from './filemanager.context';

const FilemanagerItemSync = ({ file }) => {
  const { updateTrainingClass } = useDownloads();
  const [id] = file.split('_');
  const { data } = useQuery(['training_classes', id]);

  useEffect(() => {
    if (data) {
      updateTrainingClass(data);
    }
  }, [data]);

  return null;
};

export default function FileManagerSync() {
  const { state } = useFileManager();

  useEffect(() => {
    if (state.downloadsFiles?.length) {
      // console.info('updating', state.downloadsFiles);
    }
  }, [state.downloadsFiles]);

  if (!state.downloadsFiles) {
    return null;
  }

  return state.downloadsFiles.map(file => <FilemanagerItemSync key={file} file={file} />);
}
