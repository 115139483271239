import { useShellContext } from '@/contexts/shell.context';
import { ReactNode } from 'react';

export default function DownloadsLink({ children }: { children: ReactNode }) {
  const { state: shell } = useShellContext();

  if (!shell.desktop) return null;

  return <>{children}</>;
}
