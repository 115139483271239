import { useApi } from '@/api/ApiProvider';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import c from 'config';
import { tr } from 'date-fns/locale';
import { get } from 'lodash';

const useFetchLast10Trainings = (trainingClassId: string) => {
  const QUERY_KEY = 'trainings';

  const { fetchApi } = useApi();

  return useQuery<Training[]>([QUERY_KEY, trainingClassId], async () => {
    const res = await fetchApi([
      'trainings',
      {
        filter: {
          training_class_id: trainingClassId,
          limit: 10,
        },
        fields: {
          training_class: 'short_title,subtitle,category_nr,sharing_user',
          trainer: 'full_name',
        },
        include: [
          'trainer',
          'training_class',
          'training_class.sharing_user',
          'bestcycling_training',
        ],
      },
    ]);

    if (res.error) throw new Error(res.error);

    return res.data;
  });
};

const useFetchTrainings = (trainingClass?: TrainingClass) => {
  const QUERY_KEY = 'trainings';
  const { fetchApi, getNextPageParam } = useApi();

  const queryObj = (pageParam?: string) => {
    const obj: { [key: string]: any } = {
      fields: {
        training_class: 'short_title,subtitle,category_nr,official,sharing_user',
        trainer: 'full_name',
      },
      include: ['trainer', 'training_class', 'training_class.sharing_user', 'bestcycling_training'],
      cursor: pageParam,
    };

    if (trainingClass) {
      obj.filter = {
        training_class_id: trainingClass.id,
      };
    }

    return obj;
  };

  const query = useInfiniteQuery({
    queryKey: ['trainings', 'profile_trainings'],
    queryFn: async ({ pageParam }) => {
      return fetchApi(['trainings', queryObj(pageParam)]);
    },
    getNextPageParam: lastPage => getNextPageParam(lastPage),
  });

  const trainings: Training[] = query.data?.pages?.map(p => p.data).flat() ?? [];

  return { ...query, trainings };
};

export { useFetchLast10Trainings, useFetchTrainings };
