import React, { useEffect, useState } from 'react';
import iconSearch from '../../../../img/icon-explore@2x.png';
import FiltersIcon from '../Icons/FiltersIcon';
import { TrainingSortFilter } from './TrainingSortFilter';
import { SearchFilterContainer } from './SearchFilterContainer';
import style from './Filters.module.scss';
import useIsMobileScreen from '../Scheduler/hooks/useIsMobileScreen';

interface FiltersProps {
  children: React.ReactNode;
  showSearchFilter?: boolean;
  showTrainingSortFilter?: boolean;
  placeHolderSearchFilter?: string;
  setIsMobileFiltersActive?(visible: boolean): void;
}

export default function Filters({
  children,
  showSearchFilter,
  showTrainingSortFilter,
  placeHolderSearchFilter,
  setIsMobileFiltersActive,
}: FiltersProps) {
  const [visible, setVisible] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const onButtonFiltersClick = () => setVisible(!visible);

  const isMobile = useIsMobileScreen({ mobileSize: 575 });

  useEffect(() => {
    setIsMobileFiltersActive?.(isMobile && visible);
  }, [visible, isMobile]);

  return (
    <div className={style.wrapcontainer}>
      <div className={`${style.filterscontainer} ${visible ? style.mobile_visible : ''}`}>
        <button
          data-cy="toggle_filters"
          className={style.button_filters}
          onClick={onButtonFiltersClick}
        >
          <FiltersIcon />
          <p>Filtros</p>
        </button>

        <div className={style.group_left}>
          <div className={style.mobile_topbar}>
            <div className={style.mobile_topbar_back}>
              <button onClick={onButtonFiltersClick}></button>
              <span className={style.title}>Filtros</span>
            </div>
          </div>
          {children}
        </div>

        <div className={style.group_right}>
          <a
            className={style.button_search}
            onClick={() => {
              setMobileSearch(!mobileSearch);
            }}
          >
            <img src={iconSearch} />
          </a>

          {showSearchFilter && <SearchFilterContainer placeHolder={placeHolderSearchFilter} />}
          <div className={style.order}>{showTrainingSortFilter && <TrainingSortFilter />}</div>
        </div>
      </div>
      <div className={`${style.searchMobileFilter} ${mobileSearch ? style.search_visible : ''}`}>
        {showSearchFilter && <SearchFilterContainer placeHolder={placeHolderSearchFilter} />}
      </div>
    </div>
  );
}
