import Modal from '../Modal';

import styles from './SubscribeModal.module';

import SubscriptionVerification from './SubscriptionVerification';

export default function SubscribeModal({ isOpen, onClose, onSubscribe, user }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      style={{ maxWidth: '480px', textAlign: 'center' }}
    >
      {isOpen ? (
        <SubscriptionVerification user={user} onSubscribe={onSubscribe} onClose={onClose} />
      ) : null}
    </Modal>
  );
}
