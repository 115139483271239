import React from 'react';
import { useAuth } from '@/core/contexts/auth.context';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import styles from './AuthProviders.module.scss';
import googleIcon from '@/img/google-icon.png'; // Ruta del icono de Google

const CLIENT_ID = config?.GOOGLE_CLIENT_ID;

type LoginWithGoogleProps = {
  handleError: React.Dispatch<React.SetStateAction<string>>; // Tipo de la función de setError
};

const LoginWithGoogleButton: React.FC<LoginWithGoogleProps> = ({ handleError }) => {
  const { loginProvider } = useAuth();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      if (accessToken) {
        loginProvider('google', { "access_token": accessToken});
      }
    },
    onError: (error) => {
      handleError("Acción cancelada");
      console.error('Login Failed:', error);
    }
  });

  return (
    <button
      type="button"
      onClick={login}
      className={`${styles.socialButton} ${styles.btnGoogle}`} // Aplica las clases
    >
      <img src={googleIcon} alt="Google" className={styles.icon} />
      Continuar con Google
    </button>
  );
};

const LoginWithGoogle: React.FC<LoginWithGoogleProps> = (props) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <LoginWithGoogleButton {...props} />
    </GoogleOAuthProvider>
  );
};

export default LoginWithGoogle;
