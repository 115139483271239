import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './Positioned.module.scss';

type Position = {
  x: number;
  y: number;
};

type PositionedProps = {
  children: React.ReactNode;
  parentRef: any;
  veritcalMargin?: number;
};

const Positioned = (props: PositionedProps) => {
  const [position, setPosition] = useState<Position>();

  const updatePosition = () => {
    const rect = props.parentRef.current.getBoundingClientRect();
    const { x, y } = rect;

    setPosition({
      x: x,
      y: y + (props.veritcalMargin || 0),
    });
  };

  useEffect(() => {
    if (props.parentRef?.current) {
      updatePosition();
    }
  }, [props.parentRef]);

  if (!position) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: position.y,
        left: position.x,
      }}
    >
      {props.children}
    </div>,
    document.getElementById('modal-portal')!,
  );
};

export default Positioned;
