import { ConflictUserInfo } from '@/core/contexts/auth.types';
import { FieldError, useForm } from 'react-hook-form';

import React, { useState, useEffect } from 'react';
import styles from '../LoginPage/LoginForm.module.scss';
import Button from '../UI/Button';
import { FormInput } from '../FormControls';
import { useAuth } from '@/core/contexts/auth.context';
import { ErrorPopup } from '../LoginPage/LoginForm';
import eyeClose from '../../../../img/iconEye-close.svg';
import eyeOpen from '../../../../img/iconEye-open.svg';
import LoadingIndicator from '../LoadingIndicator';

const ConflictForm: React.FC = () => {
  const { state: authState, login, logout } = useAuth();
  const [error, setError] = useState('');
  const [userConflict, setUserConflict] = useState<ConflictUserInfo | null | undefined>(null);
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const ErrorPassword = ({ errors }: { errors: FieldError | undefined }) => {
    if (!errors) return null;

    return (
      <>
        {errors.type === 'minLength' && (
          <p className={styles.errorMessage}>
            {'La contraseña debe contener al menos 4 carácteres'}
          </p>
        )}
      </>
    );
  };

  useEffect(() => {
    setIsLoading(false);
    if (authState.error) {
      switch (authState.error) {
        case 'conflict':
          setUserConflict(authState.userConflict);
          break;
        case 'network':
          setError('No hay conexión a internet. Por favor, conéctate a internet para continuar.');
          break;
        case 'unauthorized':
          setError('Usuario o contraseña incorrectos. Revisa que tus datos sean correctos.');
          break;
        default:
          setError('Error desconocido');
      }
    }
  }, [authState]);

  // Actualiza el campo 'email' cuando `userConflict` cambie
  useEffect(() => {
    if (userConflict && userConflict.login) {
      setValue('email', userConflict.login); // Establece el valor del campo de formulario
    }
  }, [userConflict, setValue]);

  const onSubmit = handleSubmit(({ email, password }: { email: string; password: string }) => {
    setError('');
    setIsLoading(true);
    login(email, password);
  });
  const _onEyeButtonClick = () => {
    setShowPassword(!showPassword);
  };

  const navigateToLogin = evt => {
    evt.preventDefault();
    logout();
  };

  if (!userConflict)
    return (
      <div id="form_login" data-cy="form_login">
        <LoadingIndicator />
      </div>
    );

  return (
    <div id="form_login" data-cy="form_login">
      <div className={styles.containerLogin}>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <h1 className={styles.titleLogin}>¡Ya tienes una cuenta!</h1>
          <div className={styles.description}>
            Ya existe una cuenta en Bestcycling con tu dirección de correo electrónico. Debes
            utilizar tu contraseña para iniciar sesión.
          </div>
          <ErrorPopup errorMessage={error} />

          {userConflict?.avatar && (
            <div className={styles.avatar}>
              <img
                src={userConflict.avatar}
                alt="Avatar del usuario"
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          )}

          <div className={styles.field}>
            <FormInput
              data-cy="user_name"
              defaultValue={userConflict.full_name}
              title="Nombre"
              placeholder="Nombre"
              readOnly={true}
              disabled
            />
          </div>
          <div className={styles.field}>
            <FormInput
              data-cy="login_email"
              defaultValue={userConflict.login}
              title="Email"
              placeholder="Email"
              readOnly={true}
              disabled
              {...register('email', { required: true })}
            />
          </div>

          <div className={styles.field}>
            <FormInput
              data-cy="login_password"
              icon={showPassword ? eyeClose : eyeOpen}
              onClick={_onEyeButtonClick}
              type={showPassword ? 'text' : 'password'}
              defaultValue={''}
              title="Password"
              placeholder="Contraseña"
              error={errors.password ? 'true' : ''}
              autoFocus={true}
              autoComplete="off"
              {...register('password', { required: true, maxLength: 30, minLength: 4 })}
            />
            <ErrorPassword errors={errors.password} />
          </div>

          <div className={styles.submit}>
            <Button
              data-cy="login_submit"
              id="user_doLogin"
              className="button_web button_web_extra"
              // disabled={!formState.isValid}
              disabled={isLoading}
              type="submit"
            >
              Iniciar sesión
            </Button>
          </div>
        </form>
        <div>
          <a
            href={`${config.MAIN}/store/users/recuperapassword`}
            className="fs13"
            target="_blank"
            rel="noreferrer"
          >
            ¿Has olvidado tu contraseña?
          </a>
          <br />
          <hr className={styles.line} />
          <br />
          <a
            href="#/login"
            rel="noreferrer"
            style={{ cursor: 'pointer' }}
            className="fs13 user_goRegister"
            onClick={navigateToLogin}
          >
            ¿Quieres iniciar sesión con otra cuenta?
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConflictForm;
