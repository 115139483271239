import useDetectConnection from '@/hooks/useDetectConnection';
import styles from './OfflineModal.module.scss';
import icon from './offline-indicator-icon.svg';
import { useNav } from '@/contexts/nav.context';

export default function OfflineModal() {
  const { state: navState } = useNav();
  const connectionStatus = useDetectConnection();
  const isOffline = connectionStatus === 'offline';
  return (
    <>
      {isOffline && navState.show && (
        <div className={styles.container}>
          <img src={icon} alt="offline" />
          <p>No hay conexión a internet</p>
        </div>
      )}
    </>
  );
}
