import {
  BluetoothDeviceState,
  BluetoothDeviceTypes,
  BikeDataFeatures,
} from '@/constants/bluetooth';
import iconBluetooth from '@/img/bluetooth_icon.svg';
import iconBluetoothConnected from '@/img/bluetooth_icon_connected.svg';
import iconBluetoothConnecting from '@/img/bluetooth_icon_connecting.svg';
import iconNoBluetooth from '@/img/no-bluetooth-icon.svg';

import { useEffect, useState } from 'react';
import styles from './BluetoothListDevices.module.scss';
interface BluetoothListDevicesProps {
  devices: BTDevice[];
  selectDevice: (dev: BTDevice) => void;
  isPrefs?: boolean;
}

export default function BluetoothListDevices({
  devices,
  selectDevice,
  isPrefs,
}: BluetoothListDevicesProps) {
  const devicesSorted = devices.sort((a, b) =>
    a.state === BluetoothDeviceState.connected
      ? -1
      : b.state === BluetoothDeviceState.unknown
      ? 1
      : 0
  );
  return (
    <ul className={styles.deviceList}>
      {devicesSorted.map((d, i) => (
        <div key={d.id}>
          {!isPrefs && <span className={styles.divider} />}
          <BluetoothDeviceRow device={d} selectDevice={selectDevice} isPrefs={isPrefs} />
        </div>
      ))}
      {!isPrefs && <span className={styles.divider} />}
    </ul>
  );
}

interface BluetoothDeviceRowProps {
  device: BTDevice;
  selectDevice: (dev: BTDevice) => void;
  isPrefs?: boolean;
}
export const BluetoothDeviceRow = ({ device, selectDevice, isPrefs }: BluetoothDeviceRowProps) => {
  const deviceConnected = device.state === BluetoothDeviceState.connected;
  const deviceUnknown = device.state === BluetoothDeviceState.unknown;
  const deviceDisconnecting = device.state === BluetoothDeviceState.disconnecting;
  const isBike = device.deviceType == BluetoothDeviceTypes.Bike;
  const isConnecting = device.state === BluetoothDeviceState.connecting;
  const getData = () => {
    if (isBike) {
      const value = device.data instanceof Map && device.data.get(BikeDataFeatures.power);
      if (value) {
        return value + ' w';
      }
    } else if (device.data) {
      return device.data + ' BPM';
    }
    return '';
  };

  return (
    <li>
      <button
        className={isPrefs ? styles.deviceButtonPrefs : styles.deviceButton}
        onClick={() => selectDevice(device)}
        disabled={deviceUnknown}
      >
        <div>
          <div className={deviceConnected ? styles.deviceNameConnected : styles.deviceName}>
            {device?.name}
          </div>

          <div className={styles.status}>{`${
            deviceUnknown
              ? 'No disponible'
              : isConnecting
              ? 'Conectando...'
              : deviceDisconnecting
              ? 'Desconectando...'
              : ''
          }`}</div>
        </div>
        <div className={styles.containerData}>
          {!!device.data && (
            <div className={deviceConnected ? styles.deviceDataConnected : styles.deviceData}>
              {getData()}
            </div>
          )}
          <img
            src={
              deviceUnknown
                ? iconNoBluetooth
                : isConnecting
                ? iconBluetoothConnecting
                : deviceConnected
                ? iconBluetoothConnected
                : iconNoBluetooth
            }
            alt="bluetooth-state"
          />
        </div>
      </button>
    </li>
  );
};
