import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getRemoteConfig,
  fetchAndActivate,
  ensureInitialized,
  getBoolean as remoteBoolean,
} from 'firebase/remote-config';
import { ACCESS_TYPE, RemoteContextConfig, RemoteContextHelpers } from './remote.types';

const firebaseConfig = {
  apiKey: 'AIzaSyCWZheoGbIvvriJbmVN1A6k0KyiUcctyvg',
  authDomain: 'bestcycling-life-production.firebaseapp.com',
  databaseURL: 'https://bestcycling-life-production.firebaseio.com',
  projectId: 'bestcycling-life-production',
  storageBucket: 'bestcycling-life-production.appspot.com',
  messagingSenderId: '102581184743',
  appId: '1:102581184743:web:01e772695ec2825a57da42',
  measurementId: 'G-MC7Z8G4J9E',
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

const defaultConfig: RemoteContextConfig = {
  free_access_enabled: false,
  allow_free_sensei: false,
  allow_free_nutrition_planning: false,
  allow_free_nutrition_recipes: false,
  allow_free_connectivity: false,
  allow_free_offline: false,
  allow_trial_subscription: false,
  allow_trial_subscription_with_invite_code: true,
  allow_free_create_classes: false,
};

const defaultHelpers: RemoteContextHelpers = {
  isRestrictionsEnabled: () => false,
  isFreeAccessEnabled: () => false,
  isRestrictedSensei: () => false,
  isRestrictedNutrition: () => false,
  isRestrictedRecipes: () => false,
  isRestrictedOffline: () => false,
  isRestrictedTrialSubscription: () => false,
  isAllowInviteCodeTrial: () => false,
  isRestrictedConnectivity: () => false,
  isRestrictedCreateClasses: () => false,
};

const RemoteContext = createContext<RemoteContextHelpers>(defaultHelpers);

export const RemoteProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialized, setIintialized] = useState(false);

  const getBoolean = (data: ACCESS_TYPE) => remoteBoolean(remoteConfig, data);

  const isRestrictionsEnabled = () => {
    return getBoolean('free_access_enabled');
  };

  const isFreeAccessEnabled = () => {
    return isRestrictionsEnabled();
  };

  const isRestrictedSensei = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_sensei');
  };

  const isRestrictedNutrition = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_nutrition_planning');
  };

  const isRestrictedRecipes = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_nutrition_recipes');
  };

  const isRestrictedOffline = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_offline');
  };

  const isRestrictedTrialSubscription = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_trial_subscription');
  };

  const isAllowInviteCodeTrial = () => {
    return getBoolean('allow_trial_subscription_with_invite_code');
  };

  const isRestrictedConnectivity = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_connectivity');
  };

  const isRestrictedCreateClasses = () => {
    return isRestrictionsEnabled() && !getBoolean('allow_free_create_classes');
  };

  const initialize = async () => {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    remoteConfig.settings.fetchTimeoutMillis = 5000;
    remoteConfig.defaultConfig = { ...defaultConfig };

    const activated = await fetchAndActivate(remoteConfig).catch(() => false);

    if (activated) {
      console.info('remote config activado');
    } else {
      console.info('remote config no activado');
    }
    await ensureInitialized(remoteConfig);
    setIintialized(true);
    // setFlags({
    //   FREE_ACCESS_ENABLED: getBoolean(FREE_ACCESS_ENABLED),
    //   ALLOW_FREE_PLAY: getBoolean(ALLOW_FREE_PLAY),
    // });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <RemoteContext.Provider
      value={{
        isRestrictionsEnabled,
        isFreeAccessEnabled,
        isRestrictedSensei,
        isRestrictedNutrition,
        isRestrictedRecipes,
        isRestrictedOffline,
        isRestrictedTrialSubscription,
        isAllowInviteCodeTrial,
        isRestrictedConnectivity,
        isRestrictedCreateClasses,
      }}
    >
      {initialized ? children : null}
    </RemoteContext.Provider>
  );
};

export const useRemoteConfig = () => useContext(RemoteContext);
