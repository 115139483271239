import * as PlayerAction from '../../constants/player_action';
import { useCallback, useEffect, useRef } from 'react';

export default function useExternalPlayer(
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>,
  trainingClass: object | null,
  options: object | null,
) {
  const externalWindow = useRef<Window | null>(null);
  const currentGraphType = useRef<any | null>(null);

  const postPlayerMessage = useCallback(
    message => {
      iframeRef.current?.contentWindow?.postMessage(message, '*');
    },
    [iframeRef.current],
  );

  const postExternalMessage = useCallback(
    message => {
      externalWindow.current?.postMessage(message, '*');
    },
    [externalWindow.current],
  );

  const onExternalUnload = () => {
    externalWindow.current = null;
  };

  const onExternalLoad = (evt: Event) => {
    const extWindow = evt.currentTarget as Window;
    if (!extWindow) return;

    extWindow.onbeforeunload = onExternalUnload;

    const config =
      currentGraphType.current === null
        ? options
        : {
            ...options,
            graph: {
              ...options?.graph,
              preferred_graph_type: currentGraphType.current.type,
              preferred_graph_subtype: currentGraphType.current.subtype,
            },
          };
    postExternalMessage({ type: 'trainingClass', trainingClass, options: config });

    /**
     * Conflict between promises if externalLoaded method in player.js is executed
     * from here
     */
    // postPlayerMessage({ type: 'externalScreen', key: 'loaded' });
  };

  const unLoadExternal = (evt: Event) => {
    if (!externalWindow.current) {
      postPlayerMessage({ type: 'closeExternal' });
    }
  };

  const launchExternal = () => {
    if (externalWindow.current) {
      externalWindow.current.focus();
      return;
    }
    externalWindow.current = window.open(
      'external.html',
      '_blank',
      'width=800,height=600,location=no,menubar=no,titlebar=no,alwaysOnTop=yes',
    );

    externalWindow.current?.addEventListener('load', onExternalLoad);
    externalWindow.current?.addEventListener('unload', unLoadExternal);
  };

  const closeExternal = () => {
    externalWindow.current?.removeEventListener('load', onExternalLoad);
    externalWindow.current?.removeEventListener('unload', unLoadExternal);
    externalWindow.current?.close();
  };

  const setCurrentTime = (currentTime: number) => {
    postExternalMessage({ type: 'currentTime', payload: { currentTime } });
  };

  const setGraphType = (type: string, subtype: string) => {
    currentGraphType.current = { type, subtype };
    postExternalMessage({ type: 'graphType', payload: { type, subtype } });
  };

  const setZoomBlock = (enabled: boolean) => {
    postExternalMessage({ type: 'zoomBlock', payload: { enabled } });
  };
  const setZoom = (enabled: boolean) => {
    postExternalMessage({ type: 'zoom', payload: { enabled } });
  };

  const setHeartRate = (value: number, second: number) => {
    postExternalMessage({ type: 'heartRate', payload: { value, second } });
  };

  const setPower = (value: number) => {
    postExternalMessage({ type: 'power', payload: { value } });
  };

  const setCadence = (value: number) => {
    postExternalMessage({ type: 'cadence', payload: { value } });
  };

  const setResistance = (value: number) => {
    postExternalMessage({ type: 'resistance', payload: { value } });
  };

  const setAutoMode = (value: boolean) => {
    postExternalMessage({ type: 'autoMode', payload: { value } });
  };

  const setFtp = (value: number) => {
    postExternalMessage({ type: 'setFtp', payload: { value } });
  };

  const toogleView = () => {
    postExternalMessage({ type: 'handleExternalView' });
  };

  // Mensajes recibidos en el window principal
  const receivedMessage = (event: MessageEvent) => {
    // if (event.origin !== 'http://yourDoamin.com')
    //   //for security
    //   return;
    // use somethingYouNeed by accessing event.data
    const { data } = event;

    if (data.value == PlayerAction.SHOW_EXTERNAL) {
      launchExternal();
    }

    if (data.type == 'external' && data.value == 'setGraph') {
      const { type, subtype } = data.payload;
      setGraphType(type, subtype);
    }
    if (data.type == 'external' && data.value == 'setZoomBlock') {
      const { enabled } = data.payload;
      setZoomBlock(enabled == '1');
    }
    if (data.type == 'external' && data.value == 'setZoom') {
      const { enabled } = data.payload;
      setZoom(enabled == '1');
    }
    if (data.type == 'external' && data.value == 'setFtp') {
      const { value } = data.payload;
      setFtp(parseInt(value, 10));
    }
    if (data.type == 'external' && data.value == 'handleExternalView') {
      toogleView();
    }
  };

  const hideResume = () => {
    postExternalMessage({ type: 'hideResume' });
  };

  const toggleCardio = () => {
    postExternalMessage({ type: 'toggleCardio' });
  }

  const showCardio = () => {
    postExternalMessage({ type: 'showCardio' });
  }

  const hideCardio = () => {
    postExternalMessage({ type: 'hideCardio' });
  }

  // Listening Player messages
  useEffect(() => {
    // Escuchamos mensajes del iframe
    window.addEventListener('message', receivedMessage, false);

    // Al salir cerramos la pantalla externa
    window.onbeforeunload = () => closeExternal();

    return () => {
      // Dejamos de escuchar mensajes del iframe
      window.removeEventListener('message', receivedMessage);

      // Eliminamos al salir
      window.onbeforeunload = null;
      // ceramos pantalla externa
      closeExternal();
    };
  }, []);

  return {
    launchExternal,
    sendCurrentTimeToExternal: setCurrentTime,
    sendGraphTypeToExternal: setGraphType,
    sendZoomBlockToExternal: setZoomBlock,
    sendHeartRateToExternal: setHeartRate,
    sendCadenceToExternal: setCadence,
    sendPowerToExternal: setPower,
    sendResistanceToExternal: setResistance,
    setAutoModeToExternal: setAutoMode,
    hideExternalResume: hideResume,
    toggleExternalCardio: toggleCardio,
    showExternalCardio: showCardio,
    hideExternalCardio: hideCardio,
  };
}
