import { useNavigate } from 'react-router';
import NotFoundPage from '../NotFound';

export default function NotFound() {
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate('/playlists/0', { replace: true });
  };

  return (
    <NotFoundPage
      title="Colección no encontrada."
      description="No se puede mostrar la colección o la colección no existe."
      buttonText="Buscar colecciones"
      onBackClick={onBackClick}
    />
  );
}
