import { NameCategory } from '@/constants/analytics_events';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { toInteger } from 'lodash';

export default function useDoneClass() {
  const analytics = getAnalytics();

  const doneClass = (trainingClass, dataHash) => {
    let array = dataHash.split('seconds');

    logEvent(analytics, 'done_class_' + NameCategory[trainingClass.category], {
      content_type: trainingClass.mediaType,
      item_id: trainingClass.id,
      item_name: trainingClass.title,
      value: toInteger(array[1]?.substring(1)),
      virtual_currency_name: 'Seconds',
    });
  };
  return { doneClass };
}
