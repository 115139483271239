const messagesIntensity = [
  'Facilísima, no me he cansado nada',
  'Muy fácil, puedo con mucho más',
  'Muy fácil, puedo con mucho más',
  'Bastante fácil, he sudado un poco',
  'Bastante fácil, he sudado un poco',
  'Bien, ha sido perfecta',
  'Dura, me ha costado un poco',
  'Dura, me ha costado un poco',
  'Muy dura, casi no puedo terminar',
  'Muy dura, casi no puedo terminar',
  'Durísima, demasiado para mi',
];

const messagesGeneral = [
  'Malísima, no me ha gustado nada',
  'Mala, no me ha gustado',
  'Mala, no me ha gustado',
  'Regular, hay cosas que no me han gustado',
  'Regular, hay cosas que no me han gustado',
  'Bien, me ha gustado',
  'Muy bien, me ha gustado bastante',
  'Muy bien, me ha gustado bastante',
  'Genial, me ha gustado mucho',
  'Genial, me ha gustado mucho',
  'Buenísima, me ha encantado',
];

export { messagesIntensity, messagesGeneral };
