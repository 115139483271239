const approximationFTP = Object.freeze({
  m: {
    50: [98, 115, 141, 167, 196],
    55: [99, 127, 155, 184, 217],
    60: [108, 139, 170, 201, 237],
    65: [117, 151, 185, 219, 258],
    70: [126, 163, 199, 263, 278],
    75: [136, 175, 214, 253, 299],
    80: [145, 187, 229, 271, 319],
    85: [154, 199, 243, 288, 339],
    90: [164, 211, 258, 305, 360],
    95: [173, 233, 272, 323, 380],
    100: [182, 235, 287, 340, 401],
  },
  f: {
    50: [72, 96, 120, 143, 170],
    55: [80, 105, 132, 158, 188],
    60: [87, 115, 144, 173, 206],
    65: [95, 125, 157, 188, 244],
    70: [102, 135, 169, 203, 241],
    75: [110, 145, 182, 218, 259],
    80: [117, 155, 194, 232, 277],
    85: [125, 165, 207, 247, 295],
    90: [132, 175, 219, 262, 312],
    95: [140, 185, 232, 277, 330],
    100: [147, 195, 244, 292, 348],
  },
});

const calculatePredeterminatedFtp = (weight, fitStatus, gender = 'm') => {
  const clampWeight = w => Math.max(50, Math.min(100, w));
  const roundToNearest = (value, step) => Math.round(value / step) * step;

  const parsedWeight = weight ? clampWeight(Math.ceil(weight)) : 75;
  const fitStatusIndex = Math.max(0, (fitStatus || 1) - 1);
  const normalizedGender = gender?.toLowerCase() === 'f' ? 'f' : 'm';

  const roundedWeight = roundToNearest(parsedWeight, 5);

  return approximationFTP[normalizedGender]?.[roundedWeight]?.[fitStatusIndex] ?? 180;
};

class calculateFtp {
  static get(weight, fitStatus, gender) {
    return calculatePredeterminatedFtp(weight, fitStatus, gender);
  }

  static fromConfig(user, profile) {
    return calculateFtp.get(profile.weight, profile.fit_status, user.sex);
  }
}

export default calculateFtp;
