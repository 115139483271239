import move from '../../../../img/bestpro/move.svg';

export default function MoveIcon({ height, width }) {
  return (
    <img
      src={move}
      alt=""
      aria-hidden="true"
      style={{
        height: height,
        width: width,
        cursor: 'move',
      }}
    />
  );
}
