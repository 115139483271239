import React from 'react';
import { createRoot } from 'react-dom/client';
import 'focus-visible';

import Application from './core/Application';
import './sentry';
import './i18n';
import '../css/main.scss';
import { ShellProvider } from '@/contexts/shell.context';

import NotificationLayout from './core/NotificationLayout';
import { AuthProvider } from '@/contexts/auth.context';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <NotificationLayout>
        <ShellProvider>
          <AuthProvider>
            <Application />
          </AuthProvider>
        </ShellProvider>
      </NotificationLayout>
    </ErrorBoundary>
  </React.StrictMode>,
);
