import chevronDown from '../../../../img/chevron-down@1x.png';

import clsx from 'clsx';
import { useState } from 'react';
import styles from './ToggeableContent.module.scss';

type ToggleableContentProps = {
  header: string;
  show?: boolean;
  canToggle?: boolean;
  children?: React.ReactNode;
};

const ToggeableContent = ({
  header,
  show = false,
  canToggle = false,
  children,
}: ToggleableContentProps) => {
  const [showContent, setShowContent] = useState(show);

  const toggleContent = () => setShowContent(prev => !prev);

  return (
    <div className={styles.container}>
      {canToggle ? (
        <div className={clsx(styles.headerContainer, styles.toggeable)} onClick={toggleContent}>
          <span className={styles.header}>{header}</span>
          <img
            alt=""
            className={showContent ? styles.chevronUp : styles.chevronDown}
            height={9}
            src={chevronDown}
          />
        </div>
      ) : (
        <div className={styles.headerContainer}>
          <span className={styles.header}>{header}</span>
        </div>
      )}
      {showContent && children}
    </div>
  );
};

export default ToggeableContent;
