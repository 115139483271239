import { useRef } from 'react';
import PlaylistCard from './PlaylistCard';
import Slider from '../Slider';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import LoadingIndicator from '../LoadingIndicator';

// Usado en colecciones (colecciones favoritas y colecciones destacadas)
export default function PlaylistsSlider({
  playlists,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
}) {
  const loadMoreRef = useRef();
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !isFetchingNextPage && !!hasNextPage,
  });

  const loading = hasNextPage ? (
    <div key="loadMore" style={{ paddingTop: '2em' }} ref={loadMoreRef}>
      {isFetchingNextPage ? <LoadingIndicator /> : null}
    </div>
  ) : null;

  return (
    <Slider>
      {playlists
        .filter(item => item.has_items || item.has_children)
        .map(item => <PlaylistCard key={item.id} playlist={item} />)
        .concat(loading)}
    </Slider>
  );
}
