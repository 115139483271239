import { FtpUpdateTypes } from '@/constants/ftpAttributes';
import Modal from '../Modal';
import Button from '../UI/Button';
import styles from './ModifiedFtpModal.module.scss';

interface ModifiedFtpModalProps {
  isOpen: boolean;
  onCancel: () => void;
  type: string;
  onUpdate: () => void;
  ftms: any;
}

export default function ModifiedFtpModal({
  isOpen,
  onCancel,
  type,
  onUpdate,
  ftms,
}: ModifiedFtpModalProps) {
  const jsonFtms = JSON.parse(ftms);
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <h1 className={styles.title}>{`${
        type === FtpUpdateTypes.decrease
          ? '¿DESEAS MODIFICAR TU FTP?'
          : '¡ENHORABUENA! ¡TU FTP HA MEJORADO!'
      }`}</h1>
      <p className={styles.description}>
        {`${
          type === FtpUpdateTypes.decrease
            ? `En los últimos entrenamientos has bajado la intensidad de la clase, lo que nos hace pensar
        que tu FTP actual es demasiado alto. Si bajas el FTP, las clases serán más sencillas, y en
        el caso de que detectemos que tu FTP ha subido, te avisaremos de nuevo para que lo
        actualices.`
            : `En los últimos entrenamientos hemos detectado que tu FTP ha mejorado y ha pasado de ${jsonFtms.ftp} W a ser ${jsonFtms.class_ftp} W.`
        }`}

        <br />
        <p className={styles.description}>
          ¿Deseas actualizar tu FTP con el valor utilizado en esta última clase?
        </p>
      </p>
      <footer>
        <Button onClick={onUpdate} size="small" className={styles.button} variant="terciary">
          {type === FtpUpdateTypes.decrease ? 'SI, MEJOR' : '¡POR SUPUESTO QUE SI!'}
        </Button>
        <Button onClick={onCancel} size="small" className={styles.button} variant="terciary">
          {type === FtpUpdateTypes.decrease ? 'NO, PREFIERO DEJARLO COMO ESTÁ' : 'DE MOMENTO NO'}
        </Button>
      </footer>
    </Modal>
  );
}
