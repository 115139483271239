export default {
  type: 'playlist',
  fields: {
    id: 'number',
    content: 'string',
    depth: 'number',
    followers_count: 'number',
    has_children: 'boolean',
    has_items: 'boolean',
    image: 'string',
    item_count: 'number',
    position: 'number',
    title: 'string',
  },
};
