import Button from '../UI/Button';
import { useRemoteConfig } from '@/contexts/remote.context';

export default function SubscribeButton({ user }) {
  const { isRestrictionsEnabled, isRestrictedTrialSubscription, isAllowInviteCodeTrial } =
    useRemoteConfig();

  const onClick = () => {
    const isTrialPeriodUsed = user.used_trial_period;
    const isReferred = user.is_referred;
    const subscribeUrl =
      (isRestrictionsEnabled() && !isRestrictedTrialSubscription() && !isTrialPeriodUsed) ||
      (isReferred && !isTrialPeriodUsed && isAllowInviteCodeTrial())
        ? config.SUBSCRIBE_URL_TRIAL
        : config.SUBSCRIBE_URL;

    window.location.href = subscribeUrl;
  };

  return (
    <div className="subscribeButton">
      <Button onClick={onClick} variant="primary">
        SUSCRÍBETE
      </Button>
    </div>
  );
}
