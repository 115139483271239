import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './GradientSlider.module.scss';

/**
 * Gradient slider
 * @param  steps: number of steps
 * @param onChange: callback function, with the current value as parameter (step count or percentage)
 * @param startColor: color from left
 * @param endColor: color from right
 */
export default function GradientSlider({
  steps = 10,
  onChange = () => {},
  startColor,
  endColor,
}: {
  steps?: number;
  onChange?: (val: number) => void;
  startColor?: string;
  endColor?: string;
}) {
  const refContainer = useRef<HTMLDivElement>(null);
  const refCircle = useRef<HTMLDivElement>(null);
  const [mouseX, setMouseX] = useState(0);
  const [y, setY] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [val, setVal] = useState((steps || 100) / 2);

  useEffect(() => {
    if (!refContainer.current) return;

    setMin(refContainer.current?.getBoundingClientRect().left);
    setMax(refContainer.current?.getBoundingClientRect().right);
    setMouseX(0);
  }, [refContainer.current]);

  const mouseMove = useCallback(
    (movEv: MouseEvent) => {
      if (refContainer.current) {
        const center = (min + max) / 2;
        const x = movEv.clientX;
        if (x > max || x < min) return;

        const diff = x - center;

        // Steped movement calculation
        if (steps) {
          const long = max - min;
          if (!long) return;

          const step = long / steps;
          const diffStepped = Math.round(diff / step) * step;
          const absoluteSteps = Math.round((x - min) / step);

          setMouseX(diffStepped);
          setVal(absoluteSteps);
          return;
        }

        // Smoth movement calculation
        setMouseX(diff);
        setVal(((x - min) / (max - min)) * 100);
      }
    },
    [refContainer.current],
  );

  useEffect(() => {
    onChange(val);
  }, [val]);

  const onMouseDown = useCallback(() => {
    document.addEventListener('mousemove', mouseMove);
  }, [refContainer.current]);

  const onMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', mouseMove);
  }, [refContainer.current]);

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [refContainer.current]);

  // STYLES
  useEffect(() => {
    if (!refCircle.current || !refContainer.current) return;
    const c = refCircle.current.getBoundingClientRect();
    const b = refContainer.current.getBoundingClientRect();
    setY((b.bottom - c.bottom) / 2);
  }, [refCircle, refContainer]);
  const barStyle = {
    background: `linear-gradient( 0.25turn, ${startColor || '#FF0000'}, ${endColor || '#FF0000'} )`,
  };
  const circleStyle = {
    transform: `translateX(${mouseX}px) translateY(${y}px)`,
  };

  return (
    <>
      <div className={styles.bar} ref={refContainer} style={barStyle}>
        <div
          className={styles.circle}
          onMouseDown={onMouseDown}
          style={circleStyle}
          ref={refCircle}
        ></div>
      </div>
    </>
  );
}
