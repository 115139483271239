import { useEffect, useState } from 'react';

import clsx from 'clsx';
import styles from './FavouriteButton.module.scss';

import favImage from '../../../../img/fav-star@1x.png';
import favImageActive from '../../../../img/fav-star-active.png';
import useFetchFavourite from '@/core/hooks/apiHooks/useFetchFavourite';
import usePutFavourite from '@/core/hooks/apiHooks/usePutFavourite';
import useDebounceFunction from '@/core/hooks/useDebounceFunction';

type FavouriteButtonProps = {
  id: string;
  type: 'Nutrition::Recipe' | 'TrainingClass';
  onFavourite?(isFav: boolean): void;
};

const FavouriteButton = ({ id, type, onFavourite }: FavouriteButtonProps) => {
  const { data, isLoading, isSuccess } = useFetchFavourite({ id, type });
  const { markAsFavourite, unmarkAsFavourite } = usePutFavourite({
    id,
    type,
    invalidateStrategy: type === 'Nutrition::Recipe' ? 'never' : 'onSuccess',
  });

  const [isFav, setIsFav] = useState<boolean>();

  const classNames = clsx(styles.fav, {
    [styles.active]: isFav,
  });

  const toggleFavourite = useDebounceFunction(() => {
    const callback = isFav ? unmarkAsFavourite : markAsFavourite;
    callback();

    onFavourite?.(!isFav);
  }, 300);

  const handleClick = () => {
    setIsFav(isFav => !isFav);
    toggleFavourite();
  };

  useEffect(() => {
    if (isLoading) return;
    if (!isSuccess || !data) return;
    if (isFav !== undefined) return;

    setIsFav(data.data[0]?.active ?? false);
  }, [data, isLoading, isSuccess]);

  return (
    <button
      onClick={handleClick}
      disabled={isLoading || !isSuccess}
      className={classNames}
      title={isFav ? 'Eliminar de favoritos' : 'Añadir a favoritos'}
    >
      <div className={styles.border}>
        <img className={styles.icon} src={isFav ? favImageActive : favImage} />
      </div>
    </button>
  );
};

export default FavouriteButton;
