export const formatVelocity = (metersPerSecond: number, format: 'km/h' | 'min/km') => {
  let velocity =
    metersPerSecond != null && metersPerSecond > 0 ? metersPerSecond + ' ' + format : '-';
  if (metersPerSecond != null && metersPerSecond > 0) {
    if (format == 'km/h') {
      const formatedValues = (metersPerSecond * 3600) / 1000;
      velocity = formatedValues.toFixed(2) + ' ' + format;
    } else if (format == 'min/km') {
      const total = 1000 / (metersPerSecond * 60);
      const minutes = Math.floor(total);
      const secondsRest = Math.floor(60 * (total - minutes));

      const formattedMinutes = minutes;
      const formattedSeconds = secondsRest > 10 ? secondsRest : '0' + secondsRest;
      velocity = formattedMinutes + ':' + formattedSeconds + '' + format;
    }
  }
  return velocity;
};

export const secondsToString = (secondsNumber: number) => {
  const dateObj = new Date(secondsNumber * 1000);

  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  if (hours > 0)
    return (
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0')
    );

  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
};

export const stringToSeconds = (timeString: string, format = 2): number => {
  const splitTimeStart = timeString.split(':');

  if (splitTimeStart.length < 2) return parseInt(splitTimeStart[0], 10);

  if (splitTimeStart.length === 2 || splitTimeStart.length === format) {
    return parseInt(splitTimeStart[0], 10) * 60 + parseInt(splitTimeStart[1], 10);
  }

  return (
    parseInt(splitTimeStart[0], 10) * 3600 +
    parseInt(splitTimeStart[1], 10) * 60 +
    parseInt(splitTimeStart[2], 10)
  );
};

export const removeMilisecondsTimeString = (timeString: string) => {
  const splitTimeStart = timeString.split(':');

  if (splitTimeStart.length > 2) {
    splitTimeStart.pop();
    return splitTimeStart.join(':');
  }
  return timeString;
};

export const secondsFromString = (currentTimestring: string, containsMilliseconds?: boolean) => {
  let timestring = currentTimestring;
  const patternMinSec = /^[0-9]{1,2}:[0-9]{2}$/i;
  const patternHourMinSec = /^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/i;
  const patternHourMinSecMilis = /^[0-9]{1,2}:[0-9]{2}:[0-9]{2}:[0-9]{1,4}$/i;

  if (patternMinSec.test(timestring)) {
    timestring = '00:' + timestring;
  }

  if (patternHourMinSec.test(timestring) || patternHourMinSecMilis.test(timestring)) {
    const timeParts = timestring
      .split(':')
      .map(s => {
        return parseInt(s, 10);
      })
      .reverse();
    if (containsMilliseconds || patternHourMinSecMilis.test(timestring)) {
      timeParts.shift();
    }
    return timeParts[0] + (timeParts[1] || 0) * 60 + (timeParts[2] || 0) * 3600;
  }

  return false;
};

const findCount = (method: any, timeString: string, annotationList: any[] | null) => {
  let count = '-';
  if (annotationList != null && annotationList.length > 0) {
    annotationList.forEach(annotation => {
      const methodAnnotation =
        annotation.method != null ? annotation.method.toLowerCase().trim() : '';
      const timeStringAnnotation = annotation.timeString != null ? annotation.timeString : '';
      const countAnnotation = annotation.number != null ? annotation.number : '-';
      if (method == methodAnnotation && timeString == timeStringAnnotation) {
        count = countAnnotation;
        return count;
      }
    });
  }
  return count;
};
const roundSeconds = (seconds: number, precision: number) => {
  const rest = seconds % precision;
  let roundedSeconds = seconds;
  if (rest > 0) {
    if (rest < precision / 2) {
      roundedSeconds -= rest;
    } else {
      roundedSeconds += precision - rest;
    }
  }
  return roundedSeconds;
};

export const calculateAverage = (data: number[]): number => {
  if (!data?.length) return 0;
  return data.reduce((value, item) => value + item) / data.length;
};

export const getPower = ({ progressionWatts, ftp, ftmsValues }: {
  progressionWatts: ProgressionWats[],
  ftp: number,
  ftmsValues: any,
}) => {
  if (!ftmsValues) {
    return progressionWatts.map((row: any) => {
      const rpm = row.rpm
        .toString()
        .split('-')
        .map((part: string) => parseInt(part, 10));
      const rpmAvg = calculateAverage(rpm);
      return Math.floor(ftp * (rpmAvg / 100));
    });
  }

  const watt = ftmsValues.watt;

  if (watt?.length > 0) {
    return watt.map((e: { quantity: string }) => parseInt(e.quantity, 10));
  }

  return [];
};

export const getMovingAverage = (power: number[], window: number) => {
  const out = [];
  for (let i = window; i < power.length; i += 1) {
    const windowArea = power.slice(i - window, i);
    out.push(calculateAverage(windowArea));
  }

  if (out.length == 0) {
    out.push(calculateAverage(power));
  }

  return (out.reduce((value, avg) => value + avg) / out.length) ** 4;
};

export const getNormalizedPower = ({ progressionWatts, ftp, ftmsValues }: {
  progressionWatts: { [key: string]: any },
  ftp: number,
  ftmsValues: { [key: string]: any }
}) => {
  if (ftmsValues && ftmsValues.watt) {
    const watt = ftmsValues.watt.map((e: { quantity: string }) => parseInt(e.quantity, 10));
    const sum = watt.reduce((a: number, b: number) => a + Math.pow(b, 4), 0);
    const root = Math.pow(sum / watt.length, 1 / 4);
    return root;
  }

  if (!(progressionWatts ?? []).length) {
    return 0;
  }

  const INTERVAL = 30;
  const lastProgression = progressionWatts.findLast((e: any) => e.zone > 0);
  const trainingTime: number = getSecondsFromTimeString(lastProgression.timeString);

  const powerAtTimestamps = [];

  for (let i = 0; i < trainingTime; i += INTERVAL) {
    const currentProgresion = progressionWatts.findLast((e: any) => i >= getSecondsFromTimeString(e.timeString));
    const power = getPowerAtZone(ftp, currentProgresion.zone);
    powerAtTimestamps.push(power);
  }

  const sum = powerAtTimestamps.reduce((a, b) => a + Math.pow(b, 4), 0);
  const root = Math.pow(sum / powerAtTimestamps.length, 1 / 4);
  return root;
};

const getPowerAtZone = (ftp: number, zone: number): number => {
  const ZONE_RANGES = [
    [50, 54],
    [55, 75],
    [76, 90],
    [91, 105],
    [106, 120],
    [121, 150],
    [151, 200],
  ];
  const prevZoneIndex: number = Math.floor(zone);
  const zoneIndex: number = Math.min(Math.max(prevZoneIndex, 0), 6);

  const rest: number = zone - zoneIndex;

  const percentage: number = interpolate(ZONE_RANGES[zoneIndex][0], ZONE_RANGES[zoneIndex][1], rest);
  return (ftp * percentage / 100);
}

const interpolate = (start: number, end: number, fraction: number): number => {
  return (end - start) * fraction + start;
}

/// Returns the seconds from a time string in the format (HH:?)MM:SS:MS
const getSecondsFromTimeString = (timeStr: string): number => {
  const parts: string[] = timeStr.split(':').reverse();
  let seconds: number = 0;

  if (parts.length == 0) {
    return 0;
  }

  if (parts.length > 1) {
    seconds += parseInt(parts[1]); // seconds
  }

  if (parts.length > 2) {
    seconds += parseInt(parts[2]) * 60; // minutes
  }

  if (parts.length > 3) {
    seconds += parseInt(parts[3]) * 3600; // hours
  }

  return seconds;
}

export const getIntensityFactor = ({ progressionWatts, ftp, ftmsValues }: {
  progressionWatts: ProgressionWats[],
  ftp: number,
  ftmsValues: any,
}) => {
  const normalized = getNormalizedPower({
    ftmsValues: ftmsValues,
    progressionWatts: progressionWatts,
    ftp: ftp,
  });
  return Math.round((normalized / ftp + Number.EPSILON) * 10) / 10;
};

export const getTss = ({ progressionWatts, ftp, ftmsValues, seconds }: {
  progressionWatts: ProgressionWats[],
  ftp: number,
  ftmsValues: any,
  seconds: number,
}) => {
  const normalized = getNormalizedPower({
    ftmsValues: ftmsValues,
    progressionWatts: progressionWatts,
    ftp: ftp,
  });

  const intensity = normalized / ftp;
  return Math.round(((seconds * normalized * intensity) / (ftp * 3600)) * 100);
};

export const completeAnnotation = (
  progression: ProgressionBlock[] | null,
  trainingData:
    | {
      heart_rate: string;
      gps: string;
      ftms: string;
      annotation: string;
    }
    | undefined,
  prevAnnotation: string | undefined,
) => {
  if (!progression) return [];

  const annotationList =
    trainingData?.annotation != null ? JSON.parse(trainingData.annotation) : null;
  const previousAnnotation = prevAnnotation != null ? JSON.parse(prevAnnotation) : null;

  progression.forEach((block: ProgressionBlock, index: number) => {
    block.forEach((round: ProgressionRound, indexRound: number) => {
      round.progressions.forEach((exercise: Exercise) => {
        exercise['count'] = findCount(exercise.method, exercise.time_string, annotationList);
        exercise['previous_count'] = findCount(exercise.method, exercise.time_string, previousAnnotation);
        exercise['is_record'] = false;
       })
    })
  });
  return progression;
};
export const getPowerWithSeconds = (ftmsValues: any) => {
  const powerSeconds = new Map<number, number>();

  const watt = ftmsValues?.watt;
  console.log('watt', watt);
  if (watt != null) {
    watt.forEach((e: any) => {
      powerSeconds.set(e.second, e.quantity);
    });
  }
  return powerSeconds;
};

export const applyFactorFTP = (avg: number, seconds: number) => {
  if (seconds == 300) {
    return Math.floor(avg * 0.8);
  } // 5 min se aplica un factor de
  if (seconds == 1200) {
    return Math.floor(avg * 0.95);
  }
  // TODO: quitar
  if (seconds == 60) {
    return Math.floor(avg * 0.95);
  }
  return 0;
};

export const getMaxFtp = (power: Map<number, number>, seconds: number) => {
  const out: number[] = [];

  power.forEach((value, key) => {
    const toSeconds = key + seconds;
    const windowPower = Array.from(power.values());

    // TODO: probar más veces en bici
    const windowPowerFilter = windowPower.filter(sec => sec < key || sec >= toSeconds);
    if (windowPower.length > seconds / 10) {
      const windowArea = windowPowerFilter;
      const avg = calculateAverage(windowArea);
      out.push(applyFactorFTP(avg, seconds));
    }
  });
  if (out.length > 0) {
    return Math.max(...out);
  }
  return 0;
};

export const calculateMaxFtp = (windowSeconds: number, ftmsValues: any) => {
  const powerSeconds = new Map(getPowerWithSeconds(ftmsValues));
  return getMaxFtp(powerSeconds, windowSeconds);
};

export const getExercisesOrderedByRounds = (exercises: TrainingClassExercise[], rounds: ProgressionBlock[]) => {
  const methods = Array.from(new Set(rounds?.flat().map(round => round.progressions?.flat().map(roundExercise => roundExercise.method.toLowerCase()))))

  return exercises.sort((a: TrainingClassExercise, b: TrainingClassExercise) => {
    const aIndex = methods.indexOf(a.name);
    const bIndex = methods.indexOf(b.name);

    console.log(aIndex, bIndex);

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return -1;
    if (bIndex === -1) return 1;

    return aIndex - bIndex;
  });
}

export const getTrainingByBlock = (trainingClass?: TrainingClass): ProgressionBlock[] | null => {
    if (!trainingClass) return null;
    const isNotTraining = !trainingClass.progression || trainingClass.category_nr != 41;
    if (isNotTraining) return null;
    const progression: Progression[] = trainingClass.progression!;

    // Seconds for each progression
    for (let i = 0; i < progression.length - 1; i++) {
      progression[i].methodSeconds = getSecondsBetweenTimeStrings(
        progression[i].timeString,
        progression[i + 1].timeString,
      );
    }

    const allRoundProgression: Progression[] = groupByRound(progression);
    const roundProgression = filterColdDown(allRoundProgression);
    var allBlocks: Progression[][]  = groupByZone(roundProgression);

    const pB: ProgressionBlock[] = allBlocks.map((block, i) => {
      const rounds: ProgressionRound[] = block.reduce(
        (acc, p) => {

          let lastRound = acc[acc.length - 1];

          if (lastRound.blockNumber != '' &&  p.block != lastRound.blockNumber) {
            acc.push({ roundNumber: lastRound.roundNumber + 1, progressions: [], blockNumber: p.block, cyclingRound: false});
            lastRound = acc[acc.length - 1];
          }

          lastRound.blockNumber = p.block;
          if(!p.method.toLocaleLowerCase().includes("descanso")){
            lastRound.cyclingRound =  isCyclingRound(lastRound.progressions);
            lastRound.progressions.push(progressionToExercise(p));
          }
          return acc;
        },
        [{ roundNumber: 1, progressions: [], blockNumber: '' ,cyclingRound:false} as ProgressionRound],
      );
      return rounds.filter(r => r.progressions.length);
    });

    return pB;
}
//Convertimos la progression en ejercicio. Sino tenemos methodSeconds y tenemos nextElement, sacamos el tiempo del ejercicio de ahí
const progressionToExercise = (element: Progression, nextElement?:  Progression) : Exercise => {
  const timeString = element.timeString;
  const method = element.method.toLowerCase().trim();

  let secondInTraining = 0;
  if(element.methodSeconds != null){
    secondInTraining = element.methodSeconds;
  }else if(nextElement != null){
    const timeStringEnd = nextElement.timeString;
    const secondStart = stringToSeconds(timeString, 3);
    const secondEnd = stringToSeconds(timeStringEnd, 3);
    secondInTraining = roundSeconds(secondEnd - secondStart, 5);
  }

  const exercise:Exercise = {
    startOfInterval: element.startOfInterval,
    endOfInterval: element.endOfInterval,
    zone: element.zone,
    method: method,
    time: `${secondInTraining}`,
    count: "-",
    previous_count: "-",
    is_record: false,
    time_string: timeString,
    seconds_time: secondInTraining,
  };

  return exercise;
}
const isCyclingRound = (round: Exercise[]) => {
  const methods = round.filter(point => {
    const method = point['method'];
    return method && ['sentado', 'de pie'].includes(method.toLowerCase());
  });
  return methods.length >= round.length / 3;
};

function getSecondsBetweenTimeStrings(timeString1: string, timeString2: string) {
  return getSecondsInString(timeString2) - getSecondsInString(timeString1);
}

const getSecondsInString = (timeString: string) => {
  const roundSeconds = (seconds: number, precision: number) => {
    return Math.ceil(seconds / precision) * precision;
  };

  const arr = timeString.split(':').map(Number);
  if (arr.length == 3) {
    const [minutes, seconds] = arr;
    return roundSeconds(3600 + minutes * 60 + seconds, 5);
  } else if (arr.length == 4) {
    const [hour, minutes, seconds] = arr;
    return roundSeconds(hour * 3600 + minutes * 60 + seconds, 5);
  }

  return 0;
};

function groupByRound(progression: Progression[]): Progression[] {
  // Se agrupan las progresiones en bloques
  const newProgression: Progression[] = progression.reduce(
    (acc: Progression[], p: Progression, i: number) => {
      if (!p.block) {
        return acc;
      }

      return [...acc, p];


    },
    [] as Progression[],
  );
  return newProgression;
}

function groupByZone(array: Progression[]): Progression[][] {
  const result: Progression[][] = [];
  let currentGroup: Progression[] = [];
  let intervalStarted: Boolean = false;
  for (const item of array) {
    if (item.startOfInterval){
      intervalStarted = true;
    }

    if (item.startOfInterval || (!intervalStarted && item.zone > 3.5) || (intervalStarted && item.endOfInterval)) {
      //Si es principio de intervalo va en el grupo nuevo
      if(!item.startOfInterval ){
        currentGroup.push(item);
      }
      if (currentGroup.length > 0) {
        result.push(currentGroup);
        currentGroup = [];
      }
      if(item.startOfInterval ){
        currentGroup.push(item);
      }
    } else {
      currentGroup.push(item);
    }
    if (item.endOfInterval){
      intervalStarted = false;
    }
  }

  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  return result;
}


function filterColdDown(array: Progression[]): Progression[] {
  if (array.length === 0) return array;
  const lastBlock = array[array.length - 1].block;
  return array.filter(item => item.block !== lastBlock );
}
