import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import OfflineErrorPopup from '@/components/OfflineErrorPopup/OfflineErrorPopup';
import { useShellContext } from '@/contexts/shell.context';

type OnlineRoutesProps = {
  activeOnlineChecker?: boolean; // Keeps listening to the online status to change the route
};

export default function OnlineRoute({ activeOnlineChecker = true }: OnlineRoutesProps) {
  const location = useLocation();
  const { state: shell } = useShellContext();
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const setOnlineState = () => setOnline(true);

    if (activeOnlineChecker) {
      window.addEventListener('online', setOnlineState);
    }

    return () => {
      window.removeEventListener('online', () => setOnlineState);
    };
  }, [location, online]);

  if (shell.desktop && !online) {
    return (
      <OfflineErrorPopup
        textH="Para acceder a esta sección se requiere estar conectado a internet."
        textP=""
      />
    );
  }

  return <Outlet />;
}

// TODO: add a refresh button?
