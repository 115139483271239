import heartEmoji from '@/img/surveys/heart-emoji.png';

type HeartEmojiProps = {
  width: number;
}

const HeartEmoji = (props: HeartEmojiProps) => {
  return (
    <img width={props.width} src={heartEmoji} />
  );
}

export default HeartEmoji;