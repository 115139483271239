import styles from './LikesIcon.module.scss';

import LikeIcon from '../../../../img/shaka-like.png';
import LikeIconActive from '../../../../img/shaka-like-active.png';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/core/api/ApiProvider';
import { useState } from 'react';

export default function LikesIcon({ comment }) {
  const { mutateApi, client } = useApi();

  const [like, setLike] = useState(comment.like);
  const [likesCount, setLikesCount] = useState(comment.likes_count);
  const toggleLike = useMutation(
    () =>
      mutateApi(['comments', comment.id, comment.like ? 'unlike' : 'like'], {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['comments']);
        client.invalidateQueries(['feeds', 'feeds/notifications']);
      },
    },
  );

  const handleClick = () => {
    setLike(prev => !prev);
    setLikesCount(prev => (comment.like ? prev - 1 : prev + 1));
    toggleLike.mutate();
  };

  return (
    <button
      name="Favorito"
      className={styles.button}
      onClick={handleClick}
      disabled={toggleLike.isLoading}
    >
      <img className={styles.icon} src={`${like ? LikeIconActive : LikeIcon}`} />
      <div className={styles.count}>{likesCount}</div>
    </button>
  );
}
