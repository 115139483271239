import defaultAvatar from '@/img/profile/avatar-default-bestlife.svg';
import styles from './Avatar.module.scss';

type AvatarProps = {
  fullName: string;
  url: string;
  size: number;
  isPrimary?: boolean;
};

const Avatar = ({ fullName, url, size, isPrimary = false }: AvatarProps) => {
  let avatarSize = size || 50;
  let avatarUrl = url || defaultAvatar;

  if (avatarUrl.startsWith('/')) {
    avatarUrl = `https://www.bestcycling.es${avatarUrl}`;
  }

  return (
    <div
      className={`${styles.avatar} ${isPrimary ? styles.primary : ''}`}
      style={{ width: avatarSize, height: avatarSize }}
    >
      <img src={avatarUrl} alt={fullName} />
    </div>
  );
};

export default Avatar;
