import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styles from './SharingDialog.module.scss';
import { useApi } from '@/api/ApiProvider';
import pluralize from '../../../utils/pluralize';

const SharingCounter = ({ trainingClass }) => {
  const { fetchApi } = useApi();

  const [trainingClassId, setTrainingClassId] = useState();

  const { data, isLoading, isSuccess } = useQuery(['sharing_requests', trainingClassId], () =>
    fetchApi([
      'sharing_requests',
      {
        filter: { user_id: trainingClass?.trainer?.id, training_class_id: trainingClassId },
        include: ['target'],
      },
    ]),
  );

  useEffect(() => {
    if (!trainingClass?.id) return;

    setTrainingClassId(trainingClass?.id);
  }, [trainingClass]);

  if (isLoading) return null;

  if (isSuccess && data?.data.length > 0) {
    return (
      <span className={styles.shared}>
        Has compartido esta clase con {pluralize(data?.data?.length, 'persona', 'personas')}.
      </span>
    );
  }

  return null;
};

export default SharingCounter;
