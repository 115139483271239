import { Auth } from 'types/auth';
import { clearAuth, saveAuth, loadAuth as _loadAuth } from './auth.persistence';
import { Hash } from 'react-router';

export const AUTH_ENDPOINT = `${config?.API_V2_HOST}/oauth/auth`;

export const APP_ID = config.APP_ID;

export async function loginProviders(provider:string, data: { [key: string]: string }) : Promise<Auth> {
  const postData = { ...data, provider, client_id: APP_ID };

   return fetch(`${AUTH_ENDPOINT}/${provider}/callback`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(postData),
  }).then(async response => {
      if (response.status === 200) {
        return response.json();
      }
      if (response.status === 409) {
        throw  {
          status: response.status,
          body: await response.json(),
        }
      }else{
        throw response;
      }
    }).then(json => saveAuth(json));
}
