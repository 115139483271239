export const Category = Object.freeze({
  Cycling: 1,
  Walking: 2,
  Running: 31,
  Training: 41,
  Balance: 11,
  Mind: 21,
  VideoProgress: 51,
});
export default Category;

export const defaultCategories = [
  Category.Cycling,
  Category.Training,
  Category.Running,
  Category.Balance,
  Category.Walking,
  Category.Mind,
];

export const Colors = Object.freeze({
  [Category.Cycling]: '#ff7900',
  [Category.Walking]: '#8d88c5',
  [Category.Balance]: '#69ae00',
  [Category.Mind]: '#1d8cb9',
  [Category.Running]: '#fcd900',
  [Category.Training]: '#f13b46',
});

export const Names = Object.freeze({
  [Category.Cycling]: 'Bestcycling',
  [Category.Training]: 'Besttraining',
  [Category.Balance]: 'Bestbalance',
  [Category.Running]: 'Bestrunning',
  [Category.Walking]: 'Bestwalking',
  [Category.Mind]: 'Bestmind',
});

export const Types = Object.freeze({
  [Category.Cycling]: 'bestcycling',
  [Category.Training]: 'besttraining',
  [Category.Balance]: 'bestbalance',
  [Category.Running]: 'bestrunning',
  [Category.Walking]: 'bestwalking',
  [Category.Mind]: 'bestmind',
});

export const Logos = Object.freeze({
  [Category.Cycling]: require('../../../img/home/logo-bestcycling--blanco.svg'),
  [Category.Training]: require('../../../img/home/logo-besttraining--blanco.svg'),
  [Category.Balance]: require('../../../img/home/logo-bestbalance--blanco.svg'),
  [Category.Running]: require('../../../img/home/logo-bestrunning--blanco.svg'),
  [Category.Walking]: require('../../../img/home/logo-bestwalking--blanco.svg'),
  [Category.Mind]: require('../../../img/home/logo-bestmind--blanco.svg'),
});

export const LogosColor = Object.freeze({
  [Category.Cycling]: require('../../../img/sensei/logo-bestcycling.png'),
  [Category.Training]: require('../../../img/sensei/logo-besttraining.png'),
  [Category.Balance]: require('../../../img/sensei/logo-bestbalance.png'),
  [Category.Running]: require('../../../img/sensei/logo-bestrunning.png'),
  [Category.Walking]: require('../../../img/sensei/logo-bestwalking.png'),
  [Category.Mind]: require('../../../img/sensei/logo-bestmind.png'),
});
