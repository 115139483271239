import Modal from '.';
import Button from '../UI/Button/Button';

import styles from './AlertModal.module.scss';

type AlertModalProps = {
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  title?: string;
  image?: string;
  description: string;
  okHandle: () => void;
  cancelHandle?: () => void;
  okText: string;
  cancelText?: string;
};

export default function AlertModal({
  isOpen,
  className,
  onClose,
  title,
  image,
  description,
  okHandle,
  cancelHandle,
  okText,
  cancelText,
}: AlertModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={className ? styles[className] : styles.alert}
    >
      {image != null ? (
        <div className={styles.image}>
          {' '}
          <img src={image}></img>{' '}
        </div>
      ) : null}
      {title != null ? <div className={styles.title}>{title}</div> : <div></div>}
      <div className={styles.description}>{description}</div>
      <div className={styles.buttonActions}>
        {cancelText != null ? (
          <Button
            variant="terciary"
            size="default"
            title={cancelText}
            onClick={() => cancelHandle?.()}
          >
            {cancelText}
          </Button>
        ) : (
          <></>
        )}
        {okText != null ? (
          <Button variant="primary" size="default" title={okText} onClick={() => okHandle()}>
            {okText}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}
