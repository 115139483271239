export const RunningDefault = {
  andar: {
    avg: 2,
  },
  trotar: {
    avg: 2.3,
  },
  correr: {
    avg: 2.8,
  },
  'correr rapido': {
    avg: 3.2,
  },
  sprintar: {
    avg: 3.6,
  },
};
