import useRelatedTrainingClasses from '@/core/hooks/useRelatedTrainingClasses';
import { useMemo } from 'react';
import ToggeableContent from '../../TrainingClassDetails/ToggeableContent';
import ErrorLoadingRelatedTrainingClasses from './ErrorLoadingRelatedTrainingClasses';
import LoadingRelatedTrainingClasses from './LoadingRelatedTrainingClasses';
import RelatedTrainingClassCard from './RelatedTrainingClass/RelatedTrainingClassCard';

import styles from './RelatedTrainingClassesList.module.scss';
// import CollapsableList from '../../CollapsableList/CollapsableList';

type RelatedTrainingClassesListProps = {
  id: string | number;
};

const RelatedTrainingClassesList = (props: RelatedTrainingClassesListProps) => {
  const {
    data: relatedTrainingClasses,
    isLoading,
    isSuccess,
    isError,
    isRefetching,
    refetch,
  } = useRelatedTrainingClasses(props.id);

  if (isLoading || isRefetching) return <LoadingRelatedTrainingClasses />;
  if (isError) return <ErrorLoadingRelatedTrainingClasses onClick={refetch} />;

  if (!relatedTrainingClasses.length) return <></>;

  return (
    <div className={styles.container}>
      <ToggeableContent header="Clases relacionadas" show>
        <div className={styles.relatedTrainingClasses}>
          <ul>
            {relatedTrainingClasses.map((item, index) => (
              <li key={index}>
                <RelatedTrainingClassCard trainingClass={item} />
              </li>
            ))}
          </ul>
        </div>
      </ToggeableContent>
    </div>
  );
};

export default RelatedTrainingClassesList;
