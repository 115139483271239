import { useState } from 'react';

import styles from './ProfileConfig.module.scss';

export default function ProfileConfig2({ profile, onSubmit }) {
  const [fitStatus, setFitStatus] = useState(profile.fit_status);

  const onChange = evt => {
    setFitStatus(evt.target.value);
  };

  const onSubmitForm = evt => {
    evt.preventDefault();
    onSubmit({ profile: { fit_status: fitStatus } });
  };

  const isFormValid = fitStatus;

  return (
    <>
      <div className={styles.title}>ESTADO DE FORMA</div>
      <p className={styles.description}>
        ¿Cuál es tu estado de forma física actual? <br />
        Sé sincero y no te preocupes.
      </p>

      <div className={styles.form_input}>
        <form onSubmit={onSubmitForm} className={styles.form}>
          <div className={styles.formContainer}>
            <input
              type="radio"
              name="fit_status"
              id="buena-forma"
              value={3}
              onChange={onChange}
              checked={fitStatus == 3}
            />
            <label htmlFor="buena-forma">Estoy en muy buena forma</label>

            <input
              type="radio"
              name="fit_status"
              id="forma"
              value={2}
              onChange={onChange}
              checked={fitStatus == 2}
            />
            <label htmlFor="forma">Estoy en forma</label>
            <input
              type="radio"
              name="fit_status"
              id="no-buena-forma"
              value={1}
              onChange={onChange}
              checked={fitStatus == 1}
            />
            <label htmlFor="no-buena-forma">No estoy en muy buena forma</label>
          </div>
          <button className={styles.button} disabled={!isFormValid}>
            Continuar
          </button>
        </form>
      </div>

      {/* <a className={styles.button_ignore} onClick={onNextClick}>
        Omitir paso
      </a> */}
    </>
  );
}
