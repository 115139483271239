import React, { useEffect, useRef } from 'react';
import { Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIsVisible } from '@/hooks/useIsVisible';
import styles from './TagsList.module.scss';
import Tag from './Tag';
import clsx from 'clsx';

// COMPONENTE DE MATERIAL UI PARA CAMBIAR EL COLOR A TODO EL TOOLTIP (INCLUYENDO LA FLECHA)
const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: color,
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid ${color}`,
    },
    color,
  },
}));

interface TagProps {
  onClick?: () => void;
  isSelected?: boolean;
  variant?: 'all' | 'selected' | 'unselected' | 'rectangle' | 'normal-style';
  colorButton?: string;
  children: React.ReactNode | NonNullable<React.ReactNode>[];
  onInvisible?: () => void;
  bgColor?: string;
  closeTag?: () => void;
  plusNumber?: number;
  calculatePercent?: (value: number) => number;
  plusNumberVisible: boolean;
  plusNameTagNotVisible: UserTag[] | NutritionTag[] | TrainingGenres[];
  [key: string]: any;
}

export default function TagVisibleAware({
  onClick,
  isSelected,
  variant = 'all',
  colorButton = '',
  children,
  visible,
  setVisible,
  bgColor,
  refParent,
  closeTag,
  plusNumber,
  plusNumberVisible = false,
  plusNameTagNotVisible,
  setLastVisible,
  index,
  calculatePercent,
  ...props
}: TagProps) {
  const ref = useRef<any>();
  const invisibleClassName = clsx([styles.invisibleTags, variant && styles[variant]]);

  const isVisible = useIsVisible(ref, refParent, 1);
  const getTitleToolTip = () => {
    if (plusNameTagNotVisible) {
      return plusNameTagNotVisible
        .map((tag: any) => {
          if (variant === 'normal-style' && calculatePercent) {
            const valueCalculated = calculatePercent(tag.value);
            return `${tag.name} · ${valueCalculated}%`;
          } else {
            return tag.name;
          }
        })
        .join(' / ');
    }
    return '';
  };

  useEffect(() => {
    if (isVisible) {
      visible[index] = 1;
      setVisible([...visible]);
      setLastVisible(visible.lastIndexOf(1));
    }

    if (!isVisible) {
      visible[index] = 0;
      setVisible([...visible]);
      setLastVisible(visible.lastIndexOf(1));
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      style={{
        opacity: isVisible ? '1' : '0',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        gap: '3px',
      }}
    >
      <Tag
        variant={variant}
        isSelected={isSelected}
        onClick={onClick}
        colorButton={colorButton}
        bgColor={bgColor}
        closeTag={closeTag}
        {...props}
      >
        {children}
      </Tag>

      {plusNumberVisible && plusNumber !== 0 && (
        <Tooltip
          arrow
          placement="top"
          color="#444344"
          title={plusNameTagNotVisible && getTitleToolTip()}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#444344', // se necesita para electron
                padding: '8px',
                width: 'calc(100% * (3/4))',
                left: '20px',
                textAlign: 'center',
              },
            },
          }}
        >
          <div className={invisibleClassName}>
            <p>+{plusNumber}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
