import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Category, { Colors, Logos, Names, Types } from '@/constants/category';
import style from './TrainingCategoryCard.module.scss';

export default function TrainingCategoryCard({ categoryNr, className, onClick, ...props }) {
  const logo = Logos[categoryNr];
  const categoryName = Names[categoryNr];
  const categoryType = Types[categoryNr];
  const backgroundColor = Colors[categoryNr];

  const classNames = clsx(style.trainingCategoryCard, [className && style[className]]);
  const typeClassNames = clsx(style[categoryType], [className && style[className]]);
  const overlayClassNames = clsx(style.overlay, [className && style[className]]);

  const categoryUrl =
    categoryNr == Category.Mind
      ? `/playlists/128`
      : `/trainingclasses/category?category_nr=${categoryNr}`;

  const handleClick = evt => {
    if (!onClick) return;

    evt.preventDefault();
    onClick();
  };

  return (
    <Link to={categoryUrl} className={classNames} role="link" onClick={handleClick} {...props}>
      <div className={typeClassNames} aria-label={categoryName} lang="en">
        <div className={overlayClassNames}></div>
        <img lang="en" src={logo} alt={categoryName} />
        <div style={{ backgroundColor }} className={style.trianglecategory}></div>
      </div>
    </Link>
  );
}
