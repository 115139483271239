import TrainingClassShare, { SharingRequest } from './TrainingClassShare';

interface TrainingClassShareListProps {
  shares: SharingRequest[];
}

const TrainingClassShareList = ({ shares }: TrainingClassShareListProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gap: '1em',
        gridTemplateColumns: 'repeat( auto-fit, minmax(280px, 1fr) )',
      }}
    >
      {shares.map((share: SharingRequest, index: number) => {
        return <TrainingClassShare key={index} share={share} />;
      })}
    </div>
  );
};

export default TrainingClassShareList;
