import ANALYTICS_EVENTS from '@/constants/analytics_events';
import { getAnalytics, logEvent } from 'firebase/analytics';
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { AuthAction, AuthState, IAuthContext } from './auth.types';
import * as authService from '../services/auth.service';
import { loginProviders } from '@/core/services/auth.providers';
import sessionModel from '../models/session.model';
import { useShell } from './shell.context';

// export const useAuth = () => useContext(AuthContext);
const initialState = {
  accessToken: '',
  refreshToken: '',
  tokenType: '',
  expiresAt: null,
  isLoggedIn: false,
  isBanned: false,
  isFreshToken: false,
  isExpiredToken: false,
  error: null,
  hydrated: false,
  userConflict: null,
};

const reducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'login':
      logEvent(getAnalytics(), ANALYTICS_EVENTS.LOGIN);
      return {
        ...state,
        ...sessionModel({ ...action.payload }),
        isBanned: false,
        hydrated: true,
        error: null,
      };

    case 'login-error':
      if (action.payload.userConflict) {
        return {
          ...state,
          error: action.payload.code,
          userConflict: action.payload.userConflict,
          isBanned: false,
        };
      }
      return {
        ...state,
        error: action.payload.code,
        isBanned: false,
      };
    case 'logout':
      return { ...initialState, ...action.payload, hydrated: true, error: null };
    case 'banned':
      return { ...state, ...action.payload };
    default:
      // @ts-expect-error
      throw new Error(`auth.context reducer unknown action ${action.type}`);
  }
};

const AuthContext = createContext<IAuthContext>({
  state: initialState,
  login: () => {},
  logout: () => {},
  banned: () => {},
  loginProvider: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Loading auth from storage
  useEffect(() => {
    authService
      .loadAuth()
      .then(auth => dispatch({ type: 'login', payload: auth }))
      .catch(_ => dispatch({ type: 'logout' }));
  }, []);

  useAuthElectron(state.accessToken);

  const login = useCallback((user: string, password: string) => {
    authService
      .login(user, password)
      .then(auth => {
        dispatch({ type: 'login', payload: auth });
      })
      .catch(err => {
        console.info(err);
        if (err.message == 'Failed to fetch') {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'network',
            },
          });
        } else if (err.status == 401) {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'unauthorized',
            },
          });
        } else {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'unknown',
            },
          });
        }
      });
  }, []);

  const logout = useCallback(() => {
    authService.logout().then(() => {
      window.electronAPI?.setLogout();
      dispatch({ type: 'logout' });
      window?.caches?.delete('app');
    });
  }, []);

  const banned = useCallback(() => {
    dispatch({
      type: 'banned',
      payload: {
        isBanned: true,
      },
    });
  }, []);

  const loginProvider = useCallback((provider: string, clientId: string, tokenId: string) => {
    loginProviders(provider, clientId, tokenId)
      .then(auth => {
        dispatch({ type: 'login', payload: auth });
      })
      .catch(err => {
        console.info(err);
        if (err.message == 'Failed to fetch') {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'network',
            },
          });
        } else if (err.status == 401) {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'unauthorized',
            },
          });
        } else if (err.status == 409) {
          dispatch({
            type: 'login-error',
            payload: {
              code: err.body.error,
              userConflict: err.body.user,
            },
          });
        } else {
          dispatch({
            type: 'login-error',
            payload: {
              code: 'unknown',
            },
          });
        }
      });
  }, []);

  useEffect(() => {
    if (window.electronAPI?.handleLogout) window.electronAPI?.handleLogout(() => logout());
  }, []);

  if (!state.hydrated) return null;

  // debug
  const testConflict = () => {
    const conflict = {
      login: 'jose@axtro.es',
      full_name: 'Jose M. Carbonell',
      avatar:
        'https://bestcycling-production.s3.amazonaws.com/avatars/images/000/042/063/m_square.jpg?1641199858',
    };
    dispatch({
      type: 'login-error',
      payload: {
        code: 'conflict',
        userConflict: conflict,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{ state: state, login, logout, banned, loginProvider, testConflict }}
    >
      {state.hydrated && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

/**
 * Hook to handle the auth flow in electron
 */
const useAuthElectron = (accessToken: string | null) => {
  const { state: shell } = useShell();

  useEffect(() => {
    if (accessToken) {
      window.electronAPI?.setAuth(accessToken);
    }
  }, [shell.electron, accessToken]);
};
