import React from 'react';
import styles from './SwitchCheckbox.module.scss';

type SwitcCheckboxProps = {
  label?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  reference?: React.Ref<HTMLInputElement>;
  children?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
};

export default function SwitchCheckBox({
  label = '',
  defaultChecked = false,
  disabled = false,
  readOnly,
  reference,
  children,
  onChange,
  ...props
}: SwitcCheckboxProps) {
  return (
    <div className={styles.toggle_switch}>
      <input
        type="checkbox"
        title={label}
        className={styles.toggle_switch_input}
        checked={defaultChecked}
        ref={reference}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      <div className={styles.toggle_switch_toggle} />
      {children}
    </div>
  );
}
