import { useMemo, useState, useEffect } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import Category from '../../constants/category';

export default function MinutesSelector({ trainingClass, onChange }) {
  var minDuration = Math.ceil(trainingClass.duration_training / 60);
  var maxDuration = Math.ceil((trainingClass.max_duration_seconds ?? 0) / 60);
  var defaultDuration = Math.ceil((trainingClass.duration_seconds ?? 0) / 60);

  const [duration, setDuration] = useState(defaultDuration);

  const dataTimes = useMemo(() => {
    let index = 0;
    let data = [];
    for (let i = minDuration; i <= maxDuration; i += 1, index += 1) {
      data.push({
        id: `${i}`,
        value: `${i} min`,
      });
    }

    return data;
  }, [minDuration, maxDuration]);

  useEffect(() => {
    onChange(duration);
  }, [duration]);

  const handleChange = new_value => {
    setDuration(parseInt(new_value.id, 10));
  };

  const canBuildNumberPicker = () => {
    return (
      trainingClass.category_nr == Category.Mind &&
      defaultDuration > 0 &&
      minDuration <= defaultDuration &&
      defaultDuration <= maxDuration
    );
  };

  if (!canBuildNumberPicker() || !dataTimes.length) {
    return null;
  }

  return (
    <WheelPicker
      data={dataTimes}
      onChange={handleChange}
      height={100}
      width={150}
      titleText=""
      itemHeight={30}
      selectedID={duration}
      shadowColor="transparent"
      color="gray"
      activeColor="white"
      backgroundColor="transparent"
      focusColor="blue"
    />
  );
}
