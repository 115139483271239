import { useNavigate } from 'react-router';
import { useShell } from '@/contexts/shell.context';
import OfflineErrorPopup from '../OfflineErrorPopup/OfflineErrorPopup';

export default function NotFound() {
  const navigate = useNavigate();
  const { state: shell } = useShell();

  return (
    <>
      {shell.electron && (
        <OfflineErrorPopup
          isBig={true}
          textH={'No se ha podido encontrar la clase'}
          textP={'Descarga esta clase primero si necesitas reproducirla de manera offline.'}
          textButton={'REPOSITORIO DE CLASES OFFLINE'}
          onClick={() => navigate('/offline')}
        />
      )}
      {!shell.electron && (
        <OfflineErrorPopup
          isBig={true}
          textH={'No podemos mostrar el contenido de esta clase'}
          textP={'Comprueba tu conexión e inténtalo de nuevo.'}
          textButton={'REINTENTAR'}
          onClick={() => window.location.reload(true)}
        />
      )}
    </>
  );
}