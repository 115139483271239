import { useEffect, useState } from 'react';
import ToastDialog from '../components/ToastDialog';

export interface useServiceWorkerUpdaterResponse {
  updatePending: boolean;
  refreshing: boolean;
  dialog: React.ReactElement;
}

export default function useServiceWorkerUpdater(): useServiceWorkerUpdaterResponse {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [updatePending, setUpdatePending] = useState<boolean>(false);

  useEffect(() => {
    // Handle para controlar el cambio de service worker
    const controllerChangeHandler = (event: Event) => {
      // console.info('controller change', event.target);
      if (!refreshing) {
        setUpdatePending(true);
        // if (confirm('refresing')) {
        //   console.info('reload');
        //   window.location.reload();
        //   refreshing = true;
        // }
      }
    };

    const setupUpdater = () => {
      // detect controller change
      navigator.serviceWorker?.addEventListener('controllerchange', controllerChangeHandler);
    };

    // window.addEventListener('load', setupUpdater);
    setupUpdater();

    return () => {
      // window.removeEventListener('load', setupUpdater);
      navigator.serviceWorker?.removeEventListener('controllerchange', controllerChangeHandler);
    };
  }, []);

  const onSubmit = () => {
    setRefreshing(true);
    window.location.reload();
  };
  const onCancel = () => {
    setUpdatePending(false);
  };

  return {
    updatePending,
    refreshing,
    dialog: (
      <ToastDialog
        description="Hemos hecho algunos cambios en esta página. Para verlos es necesario refrescarla."
        isOpen={updatePending && process.env!.NODE_ENV !== 'development'}
        confirmText="Refrescar"
        onConfirm={onSubmit}
        onClose={onCancel}
      />
    ),
  };
}
