import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import PlaylistBreadcrumbs from './PlaylistBreadcrumbs';
import styles from './Playlists.module.scss';
import { useApi } from '../../api/ApiProvider';
import RoundedButton from '../UI/Button/RoundedButton';

const colors = {
  COMBINADAS: 'white',
  BESTCYCLING: '#ff7900',
  BESTRUNNING: '#fcd900',
  BESTTRAINING: '#f13b46',
  BESTBALANCE: '#69ae00',
  BESTWALKING: '#8d88c5',
  BESTMIND: '#1D8CB9',
};

export default function PlaylistHeader({ playlist }) {
  const { mutateApi, client, fetchApi } = useApi();

  const { data: favourites } = useQuery(['favourites'], () =>
    fetchApi([
      'favourites',
      {
        filter: {
          target_type: 'Playlist',
          target_id: playlist.id,
        },
      },
    ])
  );

  const toggleFavourite = useMutation(
    data =>
      mutateApi(['favourites'], data, {
        method: 'POST',
      }),
    {
      onSettled: () => {
        client.invalidateQueries(['playlists']);
        client.invalidateQueries(['favourites']);
      },
    }
  );

  if (!favourites || !favourites.data) return null;

  const isFavourite = favourites?.data?.[0]?.active;

  const title = playlist.title && playlist.title.split(' ').pop();
  const backgroundImage = colors[title]
    ? `linear-gradient(${colors[title]}, transparent)`
    : `linear-gradient(to bottom, rgba(0,0,0,0.1), #161616), url(${
        playlist?.image2 || playlist?.image
      })`;

  const { followers_count, item_count } = playlist;
  const followers = followers_count === 1 ? '1 seguidor' : `${followers_count} seguidores`;
  const items = item_count === 1 ? '1 clase' : `${item_count} clases`;

  const handleSubmit = () => {
    toggleFavourite.mutate({
      target_id: playlist.id,
      target_type: 'Playlist',
      active: !isFavourite,
    });
  };

  return (
    <div
      className={styles.header}
      style={{
        backgroundImage,
      }}
    >
      <div className={styles.header_playlist}>
        <PlaylistBreadcrumbs playlist={playlist} />
        <div>
          <h3>{playlist.title}</h3>
          <span className={styles.followers}>
            {followers} · {items}
          </span>
        </div>
        <p className={styles.description_playlist}>{playlist.content}</p>
        <button
          name="Seguir lista"
          className={clsx(isFavourite && styles.fav)}
          onClick={handleSubmit}
          disabled={toggleFavourite.isLoading}
        >
          {isFavourite ? 'Siguiendo' : 'Seguir'}
          {toggleFavourite.isLoading && '...'}
        </button>
      </div>
    </div>
  );
}
