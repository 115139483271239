import { useEffect } from 'react';

export function useOnClickOutside(
  refsOrIds: (React.RefObject<HTMLElement> | string | null)[],
  handler: (event: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (refsOrIds.some(refOrId => {
        if (!refOrId) return false; // Handle null ref safely
        if (typeof refOrId === 'string') {
          const element = document.getElementById(refOrId);
          return element?.contains(event.target as Node);
        } else {
          return refOrId.current?.contains(event.target as Node);
        }
      })) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refsOrIds, handler]);
}