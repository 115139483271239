import React from 'react';
import styles from './ObtainedPointsModal.module.scss';
import styleButton from '../../components/UI/Button/Button.module.scss';
import Modal from '../Modal';
import LifePoint from '../LifePoint/LifePoint';
import Divider from '../Navigation/Divider';

function ObtainedPointsModal({ trainingClass, isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <div className={styles.title}>¡Tus Puntos!</div>
      <p className={styles.description}>
        Cada vez que completas entrenamientos ganas puntos de las capacidades que has entrenado. Tu
        objetivo debería ser mantener todas las capacidades en equilibrio: resintencia, fuerza,
        flexibilidad y mente. Intenta combinar entrenamientos para entrenarlo todo
      </p>

      <Divider />
      {trainingClass && (
        <section className={styles.points}>
          <div className={styles.life}>
            <div className={styles.ability}>
              <LifePoint
                style={{ width: '3em', height: '3em' }}
                type="resistance"
                value={trainingClass.life_resistance_points}
                showOnEmpty={true}
              />
              <label>Resistencia</label>
            </div>
            <div className={styles.ability}>
              <LifePoint
                style={{ width: '3em', height: '3em' }}
                type="force"
                value={trainingClass.life_force_points}
                showOnEmpty={true}
              />
              <label>Fuerza</label>
            </div>
            <div className={styles.ability}>
              <LifePoint
                style={{ width: '3em', height: '3em' }}
                type="flexibility"
                value={trainingClass.life_flexibility_points}
                showOnEmpty={true}
              />
              <label>Flexibilidad</label>
            </div>
            <div className={styles.ability}>
              <LifePoint
                style={{ width: '3em', height: '3em' }}
                type="mind"
                value={trainingClass.life_mind_points}
                showOnEmpty={true}
              />
              <label>Mente</label>
            </div>
          </div>
        </section>
      )}

      <div className={styles.buttons}>
        <button onClick={onClose} className={`${styleButton.button} ${styleButton.primary}`}>
          ¡OK!
        </button>
      </div>
    </Modal>
  );
}

export default ObtainedPointsModal;
