import { DesktopModal } from './components/Modal/DesktopModal';
import UpdateModal from './components/Modal/UpdateModal';
import { ModalProvider } from './contexts/modal.context';
import { ToastProvider } from './contexts/toast.context';

/// Layout that is used for notifications (modals, toasts, etc)
export default function NotificationLayout(props: { children: React.ReactNode }) {
  return (
    <ModalProvider>
      <ToastProvider>
        <Base>{props.children}</Base>
      </ToastProvider>
    </ModalProvider>
  );
}

const Base = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {window.electronAPI && <UpdateModal />}
      {window.electronAPI && <DesktopModal />}

      {children}
    </>
  );
};
