const ANALYTICS_EVENTS = {
  FIRST_OPEN: 'first_open',
  SHARE_INVITE_CODE: 'compartir_codigo_programa_puntos',
  CONFIGURE_NUTRITION_START: 'configure_nutrition_start',
  CONFIGURE_NUTRITION_END: 'configure_nutrition_end',
  CONFIGURE_SENSEI_START: 'configure_sensei_start',
  CONFIGURE_SENSEI_END: 'configure_sensei_end',
  CONFIGURE_USER_START: 'configure_user_start',
  CONFIGURE_USER_END: 'configure_user_end',
  RECIPE_COMPLETED: 'recipe_completed',
  DONE_CLASS_BALANCE: 'done_class_balance',
  DONE_CLASS_CYCLING: 'done_class_cycling',
  DONE_CLASS_MIND: 'done_class_mind',
  DONE_CLASS_RUNNING: 'done_class_running',
  DONE_CLASS_TRAINING: 'done_class_training',
  DONE_CLASS_WALKING: 'done_class_walking',
  CLASS_SHARED: 'class_shared',
  CLASS_BC_SHARED: 'class_bc_shared',
  CLASS_BW_SHARED: 'class_bw_shared',
  CLASS_BR_SHARED: 'class_br_shared',
  CLASS_BT_SHARED: 'class_bt_shared',
  CLASS_BB_SHARED: 'class_bb_shared',
  CLASS_BM_SHARED: 'class_bm_shared',
  ECOMMERCE_PURCHASE: 'ecommerce_purchase',
  IN_APP_PURCHASE: 'in_app_purchase',
  LOGIN: 'login',
  PAYMMENT_AFTER_TRIAL: 'payment_after_trial',
  PLAY_END: 'play_end',
  PLAY_START: 'play_start',
  PURCHASE: 'purchase',
  SESSION_START: 'session_start',
  SIGN_UP: 'sign_up',
  START_TRIAL_PERIOD: 'start_trial_period',
  VIEW_DETAILS: 'view_detailsRecipe',
  WEEKEND_SENSEI_COMPLETED: 'weekend_sensei_completed',
  VIEW_AFFILIATION_CODE_PAGE: 'view_affiliation_code_page',
  VIEW_AFFILIATION_PAGE: 'view_affiliation_page',
  VIEW_COMPLETED_PROGRAM: 'view_completed_program',
  VIEW_EXPLORE: 'view_explore',
  VIEW_FAVORITE: 'view_favorite',
  VIEW_HOME: 'view_home',
  VIEW_INVITE: 'view_invite',
  VIEW_LEVEL_UP: 'view_level_up',
  VIEW_LISTS: 'view_lists',
  VIEW_MAIN_LOADED: 'view_main_loaded',
  VIEW_NUTRITION: 'view_nutrition',
  VIEW_PROFILE: 'view_profile',
  VIEW_REGISTER_COMPLETE: 'view_register_welcome',
  VIEW_SENSEI: 'view_sensei',
  VIEW_SUBSCRIBE: 'view_subscribe',
  VIEW_SUBSCRIPTION: 'view_subscription',
  VIEW_SHOPPINGLIST: 'view_shoppingList',
  COMPLETO_NPS: 'completo_nps',
  RECHAZO_NPS: 'rechazo_nps',
};

export const NameCategory = Object.freeze({
  1: 'cycling',
  41: 'training',
  31: 'running',
  2: 'walking',
  11: 'balance',
  21: 'mind',
});

export const getCategoryClassSharedEvent = (categoryNr: number) => {
  switch (categoryNr) {
    case 1:
      return ANALYTICS_EVENTS.CLASS_BC_SHARED;
    case 2:
      return ANALYTICS_EVENTS.CLASS_BW_SHARED;
    case 31:
      return ANALYTICS_EVENTS.CLASS_BR_SHARED;
    case 41:
      return ANALYTICS_EVENTS.CLASS_BT_SHARED;
    case 11:
      return ANALYTICS_EVENTS.CLASS_BB_SHARED;
    case 21:
      return ANALYTICS_EVENTS.CLASS_BM_SHARED;
    default:
      return ANALYTICS_EVENTS.CLASS_BC_SHARED;
  }
};

export default ANALYTICS_EVENTS;
