import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/app.context';
import { useRemoteConfig } from '@/contexts/remote.context';
import SubscribeModal from '@/components/SubscribeModal';

export const Restriction = {
  sensei: 'sensei',
  nutrition: 'nutrition',
  recipes: 'recipes',
  offline: 'offline',
  constructor: 'constructor',
};

export default function RestrictedRoute({ restriction }) {
  const remoteConfig = useRemoteConfig();
  const { state: user } = useUser();
  const navigate = useNavigate();

  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const openSubscribeModal = () => setSubscribeModalOpen(true);
  const closeSubscribeModal = () => setSubscribeModalOpen(false);

  const isSubscribed = user.isSubscribed();
  const restrictionsEnabled = remoteConfig.isRestrictionsEnabled();

  useEffect(() => {
    if (isSubscribed || !restrictionsEnabled) return;

    const isRestricted = [
      restriction == Restriction.sensei && remoteConfig.isRestrictedSensei(),
      restriction == Restriction.nutrition && remoteConfig.isRestrictedNutrition(),
      restriction == Restriction.recipes && remoteConfig.isRestrictedRecipes(),
      restriction == Restriction.offline && remoteConfig.isRestrictedOffline(),
      restriction == Restriction.constructor && remoteConfig.isRestrictedCreateClasses(),
    ];

    if (isRestricted.some(value => value)) {
      openSubscribeModal();
    }
  }, [restriction]);

  if (isSubscribeModalOpen) {
    return (
      <>
        <SubscribeModal
          isOpen={isSubscribeModalOpen}
          onClose={() => navigate(-1)}
          onSubscribe={closeSubscribeModal}
          user={user}
        />
      </>
    );
  }

  return <Outlet />;
}
