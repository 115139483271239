import IconCommunityRules from '@/img/iconCommunityRules';
import Modal from '../Modal';
import ModalHeader from '../Modal/ModalHeader';
import ModalBody from '../Modal/ModalBody';
import styles from './CommunityRulesPopup.module.scss';
import ModalActions from '../Modal/ModalActions';
import Button from '../UI/Button';
import useAcceptCommunityRules from './useAcceptCommunityRules';

export default function CommunityRulesPopup({
  open = false,
  onAccept,
  onCancel,
}: {
  open?: boolean;
  onAccept: () => void;
  onCancel: () => void;
}) {
  const acceptCommunityRules = useAcceptCommunityRules();

  return (
    <Modal isOpen={open} showCloseButton={true} onRequestClose={onCancel} className={styles.modal}>
      <ModalHeader>
        <div className={styles.iconTitle}>
          <IconCommunityRules size={30} fill={'#ff7900'} />
          <h2>NORMAS DE LA COMUNIDAD</h2>
        </div>
      </ModalHeader>
      <ModalBody className={styles.body}>
        <div className={styles.warning}>
          <p className={styles.text}>
            Antes de escribir un comentario es necesario que leas y aceptes las normas de la comunidad Bestcycling. 
          </p>
          <p className={styles.text}>
            Ten en cuenta que en caso de no cumplir con estas normas podrías ser expulsado de nuestra comunidad.
          </p>
        </div>
        <div className={styles.container}>
          <div className={styles.content}>
            <p className={styles.text}>
              Hola Bestcyclista! A lo largo de los años hemos creado una comunidad súper positiva en torno 
              a la práctica del deporte, y estamos agradecidos con todos vosotros porque el 99% de los 
              comentarios que recibimos son siempre positivos, constructivos y nos aportan un cariño enorme. 
              Os estamos agradecidos por ello.
            </p>

            <p className={styles.text}>
              Cada vez somos más Bestcyclistas en nuestra comunidad y es necesario que intentemos mantener 
              el ambiente positivo entre vosotros y con la familia Bestcycling. Por ello hemos decidido 
              recordaros las normas de la comunidad.
            </p>

            <p className={styles.text}>
              Los comentarios que escribes en las clases llegan directamente, y sin filtro, a los creadores 
              de las propias clases. Leemos todos los comentarios recibidos e intentamos contestar siempre 
              que nos es posible. 
            </p>

            <p className={styles.text}>
              Nos esforzamos mucho creando estas clases y nuestros instructores ponen todo su corazón para 
              intentar crear las mejores clases para que te diviertas entrenando.
            </p>

            <p className={styles.text}>
              Es por esta razón por la que te pedimos que seas cuidados@ a la hora de escribir. Estamos 
              acostumbrado a escribir comentarios en Internet sin pensar mucho en las consecuencias que puede 
              tener sobre el que los lee, y no nos gustaría que un comentario mal formulado pudiera afectar 
              negativamente a nadie dentro del equipo Bestcycling.
            </p>

            <p className={styles.text}>
              Por eso te pedimos que cumplas las siguientes normas a la hora de comunicarte con nosotr@s, 
              y con otros usuarios, dentro de la comunidad. El incumplimiento de estas normas podría suponer 
              que seas silenciad@ (y nadie leería tus comentarios) o que incluso la expulsión de Bestcycling 
              (y no podrías volver a usar la aplicación)
            </p>

            <ol className={styles.rules}>
              <li>
                <p className={styles.text}>
                  Todos los comentarios son bienvenidos, tanto positivos como críticas constructivas 
                  negativas. 
                </p>
              </li>
              <li>
                <p className={styles.text}>
                  Los comentarios negativos deben ser formulados en forma de crítica constructiva y 
                  siendo respetuosos con los instructores. Es decir, en lugar de limitarte a decir que 
                  algo no te gusta, intenta explicar las razones o aportar ideas para mejorar. De no ser 
                  así nos reservamos el derecho a eliminar comentarios que puedan afectar negativamente 
                  al equipo Bestcycling o incluso silenciar a usuarios que incumplan esta regla.
                </p>
              </li>
              <li>
                <p className={styles.text}>
                  Está completamente prohibido comentarios de odio, o dañinos en cualquier forma, 
                  contra cualquier miembro del equipo Bestcycling o contra otros usuarios, y serán 
                  razón de expulsión directa de la comunidad Bestcycling y de la aplicación Bestcycling.
                </p>
              </li>
              <li>
                <p className={styles.text}>
                  En el caso de expulsión por mal comportamiento en la comunidad, no se devolverá ningún 
                  dinero de la parte no disfrutada de la suscripción.
                </p>
              </li>
            </ol>
            <p className={styles.text}>
              Gracias por formar parte de Bestcycling y por cuidar tanto de nosotros. Nos sentimos 
              agradecidos y vamos a trabajar muy duro por seguir ayudándote a cuidar tu salud.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalActions>
        <Button
          className={styles.button}
          variant='terciary'
          onClick={() => {
            onCancel();
          }}
        >
          RECHAZAR
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            acceptCommunityRules();
            onAccept();
          }}
        >
          CONTINUAR
        </Button>
      </ModalActions>
    </Modal>
  );
}
