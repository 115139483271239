import clsx from 'clsx';
import styles from './Modal.module.scss';

type ModalHeaderProps = {
  className?: string;
  children: React.ReactNode;
};
export default function ModalHeader({ className, children }: ModalHeaderProps) {
  return <header className={clsx([styles.modalHeader, className])}>{children}</header>;
}
