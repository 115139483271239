import { useEffect } from "react";

type ExternalDimissibleBoxProps = {
  children: React.ReactNode;
  requestClose?(): void;
}

const ExternalDismissibleBox = (props: ExternalDimissibleBoxProps) => {
  const handleKeydown = (e: any) => {
    if (e.key === "Escape" && props.requestClose) {
      props.requestClose();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown)

    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);

  return (
    <>
      {
        props.children
      }
    </>
  );
}

export default ExternalDismissibleBox;