import React from 'react';
import Modal from '.';
import Button from '../UI/Button';
import { ButtonSizes } from '../UI/Button/Button';

import styles from './ConfirmModal.module.scss';

type ConfirmModalProps = {
  isOpen: boolean;
  title: string;
  ignoreLabel?: string;
  acceptLabel?: string;
  children: React.ReactNode;
  onClose(): void;
  onCancel?(): void;
  onAccept?(): void;
  className?: string;
  canSubmit?: boolean;
  buttonsSize?: ButtonSizes;
};

const ConfirmModal = React.forwardRef((props: ConfirmModalProps, ref: any) => {
  return (
    <Modal
      ref={ref}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      className={`${styles.modal} ${props.className}`}
    >
      <div className={styles.header}>{props.title}</div>
      <div className={styles.content}>{props.children}</div>
      <div className={styles.footer}>
        <Button variant="terciary" onClick={props.onCancel} size={props.buttonsSize}>
          {props.ignoreLabel || 'Cancelar'}
        </Button>
        <Button
          disabled={!props.canSubmit}
          variant="primary"
          onClick={props.onAccept}
          size={props.buttonsSize}
        >
          {props.acceptLabel || 'Aceptar'}
        </Button>
      </div>
    </Modal>
  );
});

ConfirmModal.displayName = 'ConfirmModal';

export default ConfirmModal;
