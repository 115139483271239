import styles from './AspectRatio.module.scss';

type AspectRatioProps = {
  children: React.ReactNode;
  ratio?: number;
  [key: string]: any;
};
export default function AspectRatio({ ratio, children, ...props }: AspectRatioProps) {
  return (
    <div {...props}>
      {/* @ts-expect-error */}
      <div className={styles.aspectRatioBox} style={{ paddingTop: `${ratio * 100}%` }}>
        <div className={styles.aspectRatioBoxInside}>{children}</div>
      </div>
    </div>
  );
}

// TODO: no entiendo por qué usamos ratio si es un prop que no se usa en el componente
