import useSetting from '@/components/Preferences/useSetting';
import { createContext, useReducer, useContext, useEffect, ReactNode } from 'react';

import { useShell } from '../shell.context';
import { FileManagerAction, FileManagerState, IFileManagerContext } from './filemanager.types';

const postMessage = (msg: { type: string }) => {
  window.top?.postMessage(msg, '*');
};

const initialState: FileManagerState = {
  downloadsPath: JSON.parse(localStorage.getItem('settings') ?? '{}').C1 || '',
  pathSeparator: '/', // NOT BEING USED IN ELECTRON VERSION
  downloadsStorage: 0,
  downloadsLowFreeDisk: false,
};

const FileManagerContext = createContext<IFileManagerContext>({
  state: initialState,
  pickDownloadsPath: () => {},
  removeDownloads: () => {},
  removeDownload: () => {},
  dispatch: () => {},
});

const fileManagerReducer = (state: FileManagerState, action: FileManagerAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'file-manager-state':
      return { ...state, ...payload };
    case 'set-downloads-path':
      return { ...state, downloadsPath: payload };
    default:
      return {};
  }
};

export const FileManagerProvider = ({ children }: { children: ReactNode }) => {
  const { state: shell } = useShell();
  const [state, dispatch] = useReducer(fileManagerReducer, initialState);
  const [_, setDownloadsPathPref] = useSetting('C1');

  const pickDownloadsPath = async () => {
    postMessage({ type: 'file-manager-pick-downloads-path' });

    if (shell.electron) {
      const newDownloadsPath = await window.downloadsAPI?.changeDownloadsPath();
      console.log('newDownloadsPath', newDownloadsPath);
      setDownloadsPathPref(newDownloadsPath);
      dispatch({ type: 'set-downloads-path', payload: newDownloadsPath });
    }
  };

  const removeDownloads = () => {
    postMessage({ type: 'file-manager-remove-downloads' });
    if (shell.electron) {
      window.downloadsAPI?.deleteDownloads();
    }
  };

  const removeDownload = (id: number, mediaType: mediaType) => {
    if (shell.electron) {
      window.downloadsAPI?.deleteDownload(id, mediaType);
    }
  };

  useEffect(() => {
    const receivedMessage = (event: MessageEvent) => {
      const { data } = event;
      if (!data) return;

      const { type, payload } = data;
      if (!type || !payload) return;

      if (type == '@desktop/file-manager-state') {
        dispatch({ type: 'file-manager-state', payload: payload });
      }
    };

    window.addEventListener('message', receivedMessage);
    return () => {
      window.removeEventListener('message', receivedMessage);
    };
  }, []);

  useEffect(() => {
    postMessage({ type: '@nwjs/file-manager-state' });
  }, []);

  return (
    <FileManagerContext.Provider
      value={{
        state,
        dispatch,
        pickDownloadsPath,
        removeDownloads,
        removeDownload,
      }}
    >
      {children}
    </FileManagerContext.Provider>
  );
};

export const useFileManager = () => useContext(FileManagerContext);
