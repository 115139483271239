import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api/ApiProvider';

import Page from '../components/Page';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';

import PlaylistHeader from '../components/Playlists/PlaylistHeader';
import NotFound from '../components/Playlists/NotFound';
import useDocumentTitle from '@rehooks/document-title';
import PlaylistHeaderCategory from '@/components/Playlists/PlaylistHeaderCategory';
import PlaylistChildren from './PlaylistChildren';
import PlaylistItems from '@/components/Playlists/PlaylistItems';
import { useEffect } from 'react';
import useEventPlayList from '@/hooks/useEventPlaylists';
import { FooterMin } from '@/components/Footer';
import OfflineErrorPopup from '@/components/OfflineErrorPopup/OfflineErrorPopup';

/**
 * Página de Colección
 */

const PlaylistPage = () => {
  const { list_id } = useParams();
  const { fetchApi, client } = useApi();

  const {
    data: playlist,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery(['playlists', list_id], async () => {
    const res = await fetchApi(['playlists', list_id]);
    if (!res.data) {
      console.log('not ok', res);
      throw new Error('Oh no! Error');
    }
    client.invalidateQueries(['feeds', 'feeds/notifications']);
    return res;
  });

  useDocumentTitle(playlist ? `Colección ${playlist.data.title} | Bestcycling` : 'Bestcycling');

  useEffect(() => {
    if (playlist) {
      useEventPlayList(playlist.data.category_nr);
    }
  }, [playlist]);

  if (error) {
    return (
      <Page>
        <OfflineErrorPopup onClick={() => refetch()} />
      </Page>
    );
  }

  if (isLoading || !isSuccess)
    return (
      <Page>
        <LoadingIndicator />
      </Page>
    );

  const header = playlist.data.has_children ? (
    <PlaylistHeaderCategory playlist={playlist.data} />
  ) : (
    <PlaylistHeader playlist={playlist.data} />
  );

  const content = playlist.data.has_children ? (
    <PlaylistChildren playlist={playlist.data} />
  ) : (
    <PlaylistItems playlist={playlist.data} />
  );

  return (
    <Page footer={<FooterMin />} header={header}>
      {content}
    </Page>
  );
};

export default PlaylistPage;
