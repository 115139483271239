import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './TrainingClassGenresList.module.scss';

interface GenreListProps {
  training_genres: TrainingGenres[];
}

export default function TrainingClassGenresList({ training_genres }: GenreListProps) {
  const [blocks, setBlocks] = useState<TrainingGenres[][]>([[]]);

  const calculatePercent = (value: number) => {
    let total = 0;
    training_genres.forEach((genre) => {
      total += genre.value;
    });
    const percent = Math.min((value / total) * 100);
    return Math.round(percent);
  }

  const distributeGenresAsList = (genres: TrainingGenres[]) => {
    return genres.map((genre) => [genre]);
  }

  const distributeGenresAsBlocks = (genres: TrainingGenres[], maxBlocks: number) => {
    const newBlocks = Array.from<TrainingGenres[], TrainingGenres[]>({ length: maxBlocks }, () => []);

    let currentBlock = 0;

    for (let i = 0; i < genres.length; i++) {
      newBlocks[currentBlock].push(genres[i]);

      currentBlock = (currentBlock + 1) % maxBlocks;
    }

    return newBlocks;
  };

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (!training_genres) return;

    const maxBlocks = 3;
    const sortedGenres = isMobile ? [...training_genres].sort((a, b) => b.value - a.value) : [...training_genres];

    const newBlocks = isMobile ? distributeGenresAsList(sortedGenres) : distributeGenresAsBlocks(sortedGenres, maxBlocks);

    setBlocks(newBlocks);
  }, [training_genres, isMobile]);

  return (
    <div className={styles.container}>
      <span className={clsx(styles.title, styles.blockWithBorder)}>GÉNEROS MUSICALES</span>

      {blocks.map((blockData, blockIndex) => (
        <div
          key={blockIndex}
          className={clsx(styles.blockWithBorder, styles.flexWithGap, {
            [styles.centeredBlock]: training_genres.length <= 3,
          })}
        >
          {blockData.map((genre, index) => (
            <div key={index} className={styles.column}>
              <div className={styles.flexBetween}>
                <span className={styles.genreName} title={genre.name}>{genre.name}</span>
                <span className={styles.genreValue}>{calculatePercent(genre.value) + '%'}</span>
              </div>
              <div style={{ width: '100%' }}>
                <ProgressBar
                  percent={calculatePercent(genre.value)}
                  color='#ff7900'
                  styles={{ height: '4px' }}
                />
              </div>
            </div>
          ))}
        </div>
      ))}

      {/* <span /> */}
    </div>
  );
};