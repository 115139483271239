import { Colors, Names } from '@/core/constants/category';
import styles from './RelatedTrainingClassCard.module.scss';

interface MindContentProps {
  getTitle: () => string;
  trainingClass: TrainingClass;
}

export default function MindContent({ getTitle, trainingClass }: MindContentProps) {
  return (
    <div className={styles.mind}>
      <span className={styles.title} style={{ color: Colors[21] }}>
        {Names[21]}
      </span>
      <span className={styles.classTitle}>
        {getTitle()}
      </span>
      {trainingClass.subtitle && <span className={styles.title}>{trainingClass.subtitle}</span>}
      <span>{trainingClass.trainer?.full_name}</span>
    </div>
  )
}
