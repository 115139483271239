import { useEffect, useRef, useState } from 'react';

export interface DownloaderOptions {
  status: 'initialized' | 'downloading' | 'downloaded' | 'error';
  progress: number;
}
export interface SaveOptions {
  name: string;
}

export interface DownloadOptions {
  url: string;
}

export default function useDownloadAudiovisual() {
  const [state, setState] = useState({
    status: 'initialized',
    progress: 0,
  });

  const request = useRef<XMLHttpRequest>(null);
  const link = useRef<HTMLAnchorElement>(null);

  const save = (options: SaveOptions) => {
    if (request.current && request.current.DONE) {
      const file = new Blob([request.current.response], { type: 'application/octet-stream' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = options.name;
      a.addEventListener('click', () => a.parentNode?.removeChild(a));
      document.body.appendChild(a);

      link.current = a;
      a.click();
    }
  };

  const cancel = () => {
    request.current?.abort();
    setState(prev => ({ ...prev, status: 'initialized', progress: 0 }));
  };

  const download = (options: DownloadOptions) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', options.url, true);
    xhr.setRequestHeader('Content-Disposition', 'attachment');
    xhr.responseType = 'blob';

    xhr.onabort = function (event) {
      request.current = null;
      setState(prev => ({ ...prev, progress: 0, status: 'initialized' }));
    };

    xhr.onprogress = function (event) {
      const percentComplete = (event.loaded / event.total) * 100;
      setState(prev => ({ ...prev, progress: percentComplete, status: 'downloading' }));
    };

    xhr.onload = function () {
      if (this.status == 200) {
        setState(prev => ({ ...prev, progress: 100, status: 'downloaded' }));
      } else {
        setState(prev => ({ ...prev, status: 'error' }));
      }
    };

    request.current = xhr;
    setState(prev => ({ ...prev, progress: 0, status: 'downloading' }));
    xhr.send();
  };

  useEffect(() => {
    return () => {
      if (request.current) {
        request.current.abort();
      }
      if (link.current) {
        link.current.parentNode?.removeChild(link.current);
      }
    };
  }, []);

  return { state, actions: { download, cancel, save } };
}
