import { TagCircleColor } from "@/contexts/tagscolorlist.types";

const TagsColor: TagCircleColor[] = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'blackblue',
  'violet',
  'purple',
  'pink',
  'salmon',
];

export default TagsColor;
