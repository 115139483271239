import { useCallback, useEffect, useRef, useState } from 'react';

import useDoneClass from '@/hooks/useDoneClass';
import useEventTracking from '@/hooks/useEventTracking';
import useFullscreen from '@/hooks/useFullscreen';
import * as PlayerAction from '../../constants/player_action';
import AlertModal from '../Modal/AlertModal';
import useAnnotate from './useAnnotate';
import usePostPlays from '../../hooks/apiHooks/usePostPlays';

import { usePreferences } from '@/contexts/app.context';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { useHR } from '@/contexts/heartRate.context';
import useTrainingClassNavigation from '@/hooks/useTrainingClassNavigation';
import useExternalPlayer from './useExternalPlayer';
import useWakeLock from '@/core/hooks/useWakeLock';
import useSetting from '../Preferences/useSetting';
import PlayerRef from './PlayerRef';

interface PlayerProps {
  trainingClass: any;
  mediaType: string;
  options: any;
  heartRate?: any;
}

export default function Player({ trainingClass, mediaType, options, heartRate }: PlayerProps) {
  useWakeLock();
  const { state: preferences } = usePreferences();

  const playerNavigation = useTrainingClassNavigation();
  const playerRef = useRef<HTMLIFrameElement>(null);
  const currentTimePlayer = useRef();

  const [wantClose, setWantClose] = useState(false);

  const { shouldAnnotate, setShouldAnnotate, addAnnotateProgression, annotateTrainingClass } =
    useAnnotate(trainingClass, mediaType);
  const { doneClass } = useDoneClass();
  const { playStart, playEnd } = useEventTracking();

  const { savePlay, postDeferredPlays } = usePostPlays(trainingClass, mediaType);
  useEffect(() => postDeferredPlays(), []);

  const { handleKey } = useFullscreen(false);

  const {
    launchExternal,
    sendHeartRateToExternal,
    sendCurrentTimeToExternal,
    sendCadenceToExternal,
    sendPowerToExternal,
    sendResistanceToExternal,
    setAutoModeToExternal,
    hideExternalResume,
    toggleExternalCardio,
    showExternalCardio,
    hideExternalCardio,
  } = useExternalPlayer(playerRef, trainingClass, options);

  const autoLaunchExternal =
    ['music', 'audio'].includes(trainingClass.mediaType) && preferences.externalOpen == 1;

  // Enviamos evento al iframe del player
  const postMessage = useCallback(
    (message: { type: string; [key: string]: any }) => {
      const player = playerRef.current;
      if (!player) return;

      player.contentWindow?.postMessage(message, '*');
    },
    [playerRef],
  );

  const cancelModalExit = () => {
    setWantClose(false);
    postMessage({ type: 'cancelModalExit' });
  };

  const confirmClose = () => {
    setShouldAnnotate(true);
    postMessage({ type: 'confirmClose' });
  };

  // Envía al player valor de pulso actualizado.
  const handleHeartRateEvent = ({ type, payload }: any) => {
    if (type === '@heart-rate/value' && payload.value) {
      const { value } = payload;
      // Enviamos pulsaciones al reproductor
      postMessage({ type: 'hearRate', value });
    }
  };

  const setShowMonitorCentered = useSetting('C5')[1];
  // Mensajes recibidos en el window
  const receivedMessage = (event: MessageEvent) => {
    // if (event.origin !== 'http://yourDoamin.com')
    //   //for security
    //   return;
    // use somethingYouNeed by accessing event.data

    const { data } = event;

    // Si obtenemos un mensaje de pulsómetro
    if (typeof data.type == 'string' && data.type.startsWith('@heart-rate')) {
      handleHeartRateEvent(data);
      return;
    }

    if (typeof data.type == 'string' && data.type.startsWith('@externalScreen')) {
      postMessage({ type: 'externalScreen', key: data.key });
      return;
    }

    if (data.value === PlayerAction.SHOW_MONITOR_CENTERED) {
      console.log('Setting monitor centered: ', data.payload);
      setShowMonitorCentered(data.payload);
    }

    if (data.value === PlayerAction.END || data.value === PlayerAction.CLOSE) {
      if (shouldAnnotate) {
        doneClass(trainingClass, data.hash);
        annotateTrainingClass(data.hash);
      }

      playEnd('play_end', trainingClass, data.hash, shouldAnnotate);
      playerNavigation.navigateFromPlayer(trainingClass);
    } else if (data.value == PlayerAction.SGAE) {
      savePlay();
    } else if (data.value == PlayerAction.ANNOTATE) {
      setShouldAnnotate(true);
    } else if (data.value == PlayerAction.ADD_TRAINING_DATA) {
      addAnnotateProgression(data.hash);
    } else if (data.value == PlayerAction.TOGGLE_FULLSCREEN) {
      handleKey(data.payload.key);
    } else if (data.value == PlayerAction.WANT_CLOSE) {
      setWantClose(true);
    } else if (data.value == PlayerAction.CURRENT_TIME) {
      const { second } = data.payload;
      currentTimePlayer.current = second;
      sendCurrentTimeToExternal(second);
    } else if (data.value == PlayerAction.POWER_TARGET) {
      actions.setPowerTarget(data.data);
    } else if (data.value == PlayerAction.RESISTANCE_TARGET) {
      actions.setResistanceTarget(data.data);
    } else if (data.value == PlayerAction.POWER_AUTO_MODE) {
      actions.autoMode(data.data == 1);
      setAutoModeToExternal(data.data == 1);
    } else if (data.value == PlayerAction.HIDE_RESUME) {
      // FIXME: This is not the way, but update options state causes page refresh,
      // so the video starts again. Same every options mutation.
      if (options) {
        options['audio']['resume_view'] = false;
      }
      hideExternalResume();
    } else if (data.value == PlayerAction.TOGGLE_CARDIO) {
      if (options) {
        options['cardio']['enabled'] = !options['cardio']['enabled'];
      }
      toggleExternalCardio();
    } else if (data.value == PlayerAction.SHOW_CARDIO) {
      if (options) {
        options['cardio']['enabled'] = true;
      }
      showExternalCardio();
    } else if (data.value == PlayerAction.HIDE_CARDIO) {
      if (options) {
        options['cardio']['enabled'] = false;
      }
      hideExternalCardio();
    }
  };

  // Listening Player messages
  useEffect(() => {
    // Escuchamos mensajes del iframe
    window.addEventListener('message', receivedMessage, false);

    return () => {
      // Dejamos de escuchar mensajes del iframe
      window.removeEventListener('message', receivedMessage);
    };
  }, [receivedMessage]);

  // Listening Cardio
  const {
    state: { connected: heartRateDeviceConnected, heartRate: heartRateValue },
  } = useHR();
  const { state, actions } = useBTContext();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (state.heartRateEmitter) {
      // callback que envía mensajes con pulsaciones
      const emitHeartRate = (value: any) => {
        postMessage({
          type: 'hearRate',
          value: { hearRate: value, second: currentTimePlayer.current },
        });
        sendHeartRateToExternal(value, currentTimePlayer.current!);
      };

      // Escuchamos eventos del pulsómetro
      state.heartRateEmitter.removeAllListeners('characteristicvaluechanged');
      state.heartRateEmitter.addListener('characteristicvaluechanged', emitHeartRate);
    }

    if (state.bikeDataEmitter) {
      const emitPower = (value: any) => {
        // console.log('POTENCIA ENVIADA ', value);
        postMessage({ type: 'power', value: value });
        sendPowerToExternal(value);
      };

      const emitCadence = (value: any) => {
        // console.log('CADENCIA ENVIADA ', value);
        postMessage({ type: 'cadence', value: value });
        sendCadenceToExternal(value);
      };

      const emitResistance = (value: any) => {
        // console.log('RESISTENCIA ENVIADA ', value);
        postMessage({ type: 'resistance', value: value });
        sendResistanceToExternal(value);
      };

      const emitButtonChange = (value: any) => {
        console.log('ButtonChange encotnrado ', value);
        postMessage({ type: 'buttonChange', value: value });
      };

      // Escuchamos eventos de la bici
      state.bikeDataEmitter.removeAllListeners('powerChange');
      state.bikeDataEmitter.removeAllListeners('candeceChange');
      state.bikeDataEmitter.removeAllListeners('resistanceChange');
      state.bikeDataEmitter.removeAllListeners('buttonChange');

      state.bikeDataEmitter.addListener('powerChange', emitPower);
      state.bikeDataEmitter.addListener('candeceChange', emitCadence);
      state.bikeDataEmitter.addListener('resistanceChange', emitResistance);
      state.bikeDataEmitter.addListener('buttonChange', emitButtonChange);
    }
  }, [state.bikeDataEmitter, state.heartRateEmitter]);

  useEffect(() => {
    // callback que envía mensajes con pulsaciones
    const emitHeartRate = (value: any) => {
      postMessage({
        type: 'hearRate',
        value: { hearRate: heartRateValue, second: currentTimePlayer.current },
      });
      sendHeartRateToExternal(heartRateValue, currentTimePlayer.current!);
    };
    // Escuchamos eventos del pulsómetro
    heartRate?.addListener('characteristicvaluechanged', emitHeartRate);

    return () => {
      // Dejamos de escuhar eventos del pulsómetro
      heartRate?.removeListener('characteristicvaluechanged', emitHeartRate);
    };
  }, [heartRate, postMessage]);

  useEffect(() => {
    if (heartRateDeviceConnected) {
      postMessage({
        type: 'hearRate',
        value: { hearRate: heartRateValue, second: currentTimePlayer.current },
      });
      sendHeartRateToExternal(heartRateValue, currentTimePlayer.current!);
    }
  }, [heartRateDeviceConnected, heartRateValue]);

  useEffect(() => {
    if (state.heartRate) {
      postMessage({
        type: 'hearRate',
        value: { hearRate: heartRateValue, second: currentTimePlayer.current },
      });
      sendHeartRateToExternal(heartRateValue, currentTimePlayer.current!);
    }
  }, [state.heartRate]);

  // Initialize the player
  useEffect(() => {
    const player = playerRef.current;

    // Iniciamos iframe
    if (player) {
      // callback que envía mensaje datos de clase y opciones
      const onLoad = () => {
        postMessage({ type: 'trainingClass', trainingClass, options });

        if (autoLaunchExternal) {
          launchExternal();
          // TODO: quizá esto no se debería hacer aquí
          postMessage({ type: 'externalScreen', key: 'loaded' });
        }
      };

      player.onload = onLoad;
      player.src = 'player.html';
    }
  }, [trainingClass, options, postMessage, playerRef]);

  useEffect(() => {
    playStart('play_start', trainingClass);
  }, []);

  return (
    <>
      <AlertModal
        isOpen={wantClose}
        className={'white'}
        description={'¿Estás seguro de que quieres salir de la clase?'}
        okHandle={confirmClose}
        cancelHandle={cancelModalExit}
        okText="SALIR DE LA CLASE"
        cancelText="CANCELAR"
        onClose={undefined}
        title={undefined}
        image={undefined}
      />
      <PlayerRef ref={playerRef} />
    </>
  );
}
