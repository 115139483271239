import React, { useEffect, useRef, useState } from 'react';

export const useDropdown = () => {
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const dropdownRef = useRef<any>(null);
  const parentRef = useRef<any>(null);

  const openDropdown = () => setDropdownOpened(true);
  const closeDropdown = () => setDropdownOpened(false);
  const toggleDropDown = () => setDropdownOpened(d => !d);

  const handleClickOutside = (evt: any) => {
    if (!dropdownRef.current.contains(evt.target) && !parentRef.current.contains(evt.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (!dropdownOpened) return;

    document.addEventListener('click', handleClickOutside, { capture: true });

    return () => document.removeEventListener('click', handleClickOutside, {
      capture: true,
    });
  }, [dropdownOpened]);

  return { dropdownOpened, dropdownRef, parentRef, openDropdown, closeDropdown, toggleDropDown };
};
