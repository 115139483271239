import Category from '@/constants/category';
import dayjs from 'dayjs';
import {durationTrainingRange} from '@/constants/duration_training'
/// Returns a string with the days that a scheduledTraining is applied
/// i.e.: "lunes, martes y viernes"
export const scheduledDaysString = (virtualTrainingSched: VirtualTrainingSchedule) => {
  const dayStrings = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábados', 'domingos'];
  const arrDays = [
    virtualTrainingSched.monday,
    virtualTrainingSched.tuesday,
    virtualTrainingSched.wednesday,
    virtualTrainingSched.thursday,
    virtualTrainingSched.friday,
    virtualTrainingSched.saturday,
    virtualTrainingSched.sunday,
  ];

  const res: string[] = [];
  arrDays.forEach((v, i) => {
    return v ? res.push(dayStrings[i]) : null;
  });

  let resString = '';
  resString = res.join(', ');
  resString = resString.replace(/,(?=[^,]*$)/, ' y');
  return resString.charAt(0).toUpperCase() + resString.slice(1);
};

/// Returns the string with the category string
export const trainingCategoryNrToString = (n: number) => {
  const cat = {
    1: 'Bestcycling',
    2: 'Bestwalking',
    3: 'Unknown',
    31: 'Bestrunning',
    41: 'Besttraining',
    11: 'Bestbalance',
  };

  // @ts-ignore-next-line
  return cat[n] ?? 'Unknown';
};

/// Returns the string with the level string
export const trainingLevelNrToString = (n: number) => {
  const lvlRanges = {
    0: 'Aleatorio',
    10: 'Principiante',
    20: 'Medio',
    30: 'Avanzado',
  };

  // @ts-ignore-next-line
  return lvlRanges[n] ?? 'Aleatorio';
};

export const trainingDurationMin = (n: number|undefined) => {
  // @ts-ignore-next-line
  return durationTrainingRange[n]  && durationTrainingRange[n].min ? durationTrainingRange[n].min:null;
};
export const trainingDurationMax = (n: number|undefined) => {
  // @ts-ignore-next-line
  return durationTrainingRange[n]  && durationTrainingRange[n].max ? durationTrainingRange[n].max: null;
};
const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
/// Returns a spanish formated date in DayOfWeek, dayOfMonth, Month
/// i.e.: martes 25 de Octubre
export const getDayMonthString = (dayjsDate: any) => {
  return `${daysOfWeek[dayjsDate.day()].toLowerCase()} ${dayjsDate.date()} de ${months[
    dayjsDate.month()
  ].toLocaleLowerCase()}`;
};

/// Returns a string with the most approximate time unit left
/// i.e.: "23 horas", "2 dias", "30 minutos", "30 segundos"
export const getTimeLeftAproxString = (dayjsDate: any) => {
  dayjs.locale('es');
  return dayjsDate.toNow(true);
};

export function secondsToString(seconds: number) {
  const duration = dayjs.duration(seconds, 'seconds');
  const hours = duration.hours();
  return `${hours * 60 + duration.minutes()}'`;
}

/// Check if a recurrent training class could be periodically programmed for the date selected,
/// Or an individual class overlaps one of the classes that are already scheduled
/// @tr trainingRooms
/// @data the data obtained in the form
/// @isIndividual scheduled or single training
/// @ignoreSchedId ignoredScheduleId
export const checkForSameHourTrainings = (
  tr: TrainingRoom[],
  data: any,
  isIndividual: boolean,
  ignoreSchedId?: number | string,
) => {
  const overlapingClasses: TrainingRoom[] = [];
  if (isIndividual) {
    const startDate = dayjs(`${data.date} ${data.hour}`).add(-1, 'h');
    const endDate = dayjs(`${data.date} ${data.hour}`).add(1, 'h');

    tr.forEach(trainingRoom => {
      if (
        trainingRoom.virtual_training &&
        trainingRoom.virtual_training_schedule.id != ignoreSchedId
      ) {
        const trDate = dayjs(
          new Date(trainingRoom.virtual_training.training_date).setMilliseconds(0),
        );
        if (trDate > startDate && trDate < endDate) {
          overlapingClasses.push(trainingRoom);
        }
      }
    });
  } else {
    const dayCodes = [1, 2, 3, 4, 5, 6, 0];
    const hour = Number(data.hour.split(':')[0]);
    const min = Number(data.hour.split(':')[1]);
    data.daysSelected.forEach((day: number, i: number) => {
      if (day) {
        // Day selected check interval
        const actualDayOfWeek = new Date().getDay();
        let diff = dayCodes[i] - actualDayOfWeek;
        if (dayCodes[i] == 0 && diff < 0) diff = 7 + diff;

        const startDate = dayjs(new Date().setHours(hour - 1, min))
          .add(diff, 'days')
          .add(1, 'm');

        const endDate = dayjs(new Date().setHours(hour + 1, min))
          .add(diff, 'days')
          .add(-1, 'm');

        tr.forEach(trainingRoom => {
          //
          if (trainingRoom.virtual_training_schedule.id != ignoreSchedId) {
            const trDate = dayjs(new Date(trainingRoom.virtual_training_date).setMilliseconds(0));
            if (trDate > startDate && trDate < endDate) {
              overlapingClasses.push(trainingRoom);
            }
          }
        });
      }
    });
  }

  if (overlapingClasses.length > 0) {
    let overlappingScheds = overlapingClasses.map(v => v.virtual_training_schedule);
    // @ts-ignore-next-line
    overlappingScheds = [...new Set(overlappingScheds)];
    // remove duplicate ids
    return overlappingScheds.reduce((prev: VirtualTrainingSchedule[], next) => {
      if (!prev.map || prev.map(v => v.id).indexOf(next.id) < 0) prev.push(next);
      return prev;
    }, []);
  }
  return [];
};

export const trainingTypes: { value: number; label: string; type: string }[] = [
  { value: Category.Cycling, label: 'Bestcycling', type: 'category_nr' },
  { value: Category.Walking, label: 'Bestwalking', type: 'category_nr' },
  { value: Category.Balance, label: 'Bestbalance', type: 'category_nr' },
  { value: Category.Running, label: 'Bestrunning', type: 'category_nr' },
  { value: Category.Training, label: 'Besttraining', type: 'category_nr' },
];

export const levels = [
  { value: '10', label: 'Principiante', type: 'level_nr' },
  { value: '20', label: 'Medio', type: 'level_nr' },
  { value: '30', label: 'Avanzado', type: 'level_nr' },
];

export const schedulerDurations = [
  { value: 0, label: "Clases de 30' o menos", type: 'duration' },
  { value: 1, label: "Clases de 31' a 39'", type: 'duration' },
  { value: 2, label: "Clases de 40' a 50'", type: 'duration' },
  { value: 3, label: "Clases de más de 50'", type: 'duration' },
  { value: 4, label: "Cualquier duración'", type: 'duration' },
];

/// Time expresion in 09:10 converted to local time
export const utcHourToLocalTime = (utc_time_string: string, offset_utc: number) => {
  const h = parseInt(utc_time_string.split(':')[0], 10) + offset_utc / 60;
  const m = utc_time_string.split(':')[1];
  const date = dayjs().hour(h).minute(parseInt(m));
  return date.format('HH:mm');
};

/// Transforms local hour to utc hour
export const localHourToUtcTime = (local_time_string: string) => {
  const h = local_time_string.split(':')[0];
  const m = local_time_string.split(':')[1];
  const date = dayjs().hour(parseInt(h)).minute(parseInt(m));
  return date.utc().format('HH:mm');
};

/// Returns the day of the week in locale 'es' (lunes => 0, domingo => 6)
export const dayOfWeekByDate = (date: any) => {
  const dayofweekN = date.getDay() - 1;
  return dayofweekN == -1 ? 6 : dayofweekN;
};

/// Returns the most similar trainingClass from the list selected with the parameters passed (level_nr, trainer).
export const selectSimilarTrainingClass = (
  trainingClasses: TrainingClass[],
  category_nr: number,
  level_nr: number,
  trainer_id: number | string,
) => {
  let candidates: TrainingClass[] = [];
  let max_points = 0;
  trainingClasses.forEach(trainingClass => {
    if (trainingClass.category_nr == category_nr) {
      let points = 0;
      if (trainingClass.level_nr == level_nr) points += 2;
      if (trainingClass.trainer_id == trainer_id) points += 1;

      if (points > max_points) {
        candidates = [];
        candidates.push(trainingClass);
        max_points = points;
      } else if (points === max_points) {
        candidates.push(trainingClass);
      }
    }
  });

  return candidates[Math.floor(Math.random() * candidates.length)];
};

// Returns hour from a date)
export const getHour = (date: string) => {
  return dayjs(date).format('HH:mm');
};
// PARSE QUALITIES FOR HLS {SCHEDULER AND PLAYER}
export const getQualitiesVariants = (
  media: Media[],
  quality_video: QualityVideo,
  accessToken: string,
) => {
  const variants = media.filter(e => e.type.startsWith('hls_'));
  const defaults = new Array(variants.length).fill(false);

  const { value } = quality_video;
  if (value === 'high') {
    defaults[defaults.length - 1] = true;
  } else if (value === 'low') {
    defaults[0] = true;
  } else if (value === 'medium') {
    defaults[Math.floor(defaults.length / 2 - 1)] = true;
  }

  return variants.reverse().map((v, i) => ({
    ...v,
    url: `${v.url}&access_token=${accessToken}`,
    level: i,
    preferred: defaults[i],
    label: v.type.slice(4),
  }));
};

//Devolvemos el indice del tipo de duración dado los parámetros
export const durationTrainingByRange = (minValue: (number| null), maxValue: (number | null)) => {
  for (const key in durationTrainingRange) {
    const numericKey = key as unknown as keyof typeof durationTrainingRange; // Conversión explícita
    const range = durationTrainingRange[numericKey];
    // Verificamos si los valores min y max coinciden
    if (
      (range.min === minValue || (range.min === null && minValue === null)) &&
      (range.max === maxValue || (range.max === null && maxValue === null))
    ) {
      return Number(key);
    }
  }
  return 2; //Defecto 40,50
}

export const schedulerDurationLabel = (minValue: (number| null), maxValue: (number | null)) => {
  const numberDuration = durationTrainingByRange(minValue, maxValue);
  const scheduler = schedulerDurations.find((dur) => dur.value == numberDuration);

  return scheduler?.label||"";
}
