import { BikeDataFeatures, BluetoothDeviceTypes } from '@/constants/bluetooth';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { useEffect, useState } from 'react';

export const useFtms = () => {
  const { actions } = useBTContext();
  const [features, setFeatures] = useState<any>({});
  const [levelRange, setLevelRange] = useState({});
  const bikesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.Bike);

  const forceAutoMode = () => {
    return (
      features != null &&
      features[BikeDataFeatures.powerTarget] != null &&
      features[BikeDataFeatures.powerTarget]
    );
  };

  const getFeaturesFromBike = async () => {
    const fts = await actions.getFeatures();
    const featuresBike = {};
    fts.forEach(f => {
      featuresBike[f] = true;
    });
    return featuresBike;
  };

  useEffect(() => {
    const getFeatsAndLevelRange = async () => {
      const feats = await getFeaturesFromBike();
      const lvlRange = await actions.getLevelRange();
      if (lvlRange) {
        setLevelRange(Object.fromEntries(lvlRange));
      }
      setFeatures(feats);
    };
    getFeatsAndLevelRange();
  }, []);

  return { enabled: bikesConnected.length > 0, features, levelRange, forceAutoMode };
};
