import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import FiltersContext from '../contexts/filters.context';
import ListClassesPage from './ListClassesPage';
import useDocumentTitle from '@rehooks/document-title';
import usePageName from '@/hooks/usePageName';
import useFetchUserTags from '@/core/hooks/apiHooks/useFetchUserTags';
import MyCollectionBanner from '@/components/MyCollectionBanner/MyCollectionBanner';
import { Names } from '../constants/category';

const FAVOURITES = 'favourites';
const SEARCH = 'search';
const CATEGORY = 'category';
const WEEKLY = 'weekly';

const titles: Key<string> = {
  [FAVOURITES]: 'Clases favoritas',
  [SEARCH]: 'Buscar clases',
  [CATEGORY]: 'Entrenamientos',
  [WEEKLY]: 'Clases de la semana',
};

type Section =
  | typeof FAVOURITES
  | typeof SEARCH
  | typeof CATEGORY
  | typeof WEEKLY
  | 'collection'
  | '';

type FindClassesPageProps = {
  official: boolean;
  collection?: string;
  section?: Section;
  weeklyClasses?: string;
};

const FindClassesPage = ({
  official,
  collection = '',
  section = '',
  weeklyClasses = '',
}: FindClassesPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  const { data } = useFetchUserTags();

  usePageName(section, params.category_nr);

  const isOfficial = !!collection || section === FAVOURITES ? null : official;
  const isFavourites = section === FAVOURITES;
  const isWeekly = section === WEEKLY;
  const isCategory = section === CATEGORY;

  const defaultFilters = {
    favourites: isFavourites || undefined,
    sort: isWeekly ? '-free,-published_at' : '-published_at',
    official: isOfficial,
    ...(weeklyClasses && isWeekly ? { id: weeklyClasses } : {}),
    ...params,
  };

  const finalFilters = collection ? { ...defaultFilters, tag_id: collection } : defaultFilters;

  const [filters, setFilters] = useState<any>(finalFilters);

  const collectionsNames = (data || []).map(col => col.name);

  const collectionElement = useMemo(() => {
    if (!collection) return null;
    if (!data) return null;

    const list = data.filter(col => col.id == collection);

    return list.length ? list[0] : null;
  }, [data, collection]);

  const categoryName = Names[params.category_nr as unknown as keyof typeof Names];

  const title = collectionElement
    ? collectionElement.name
    : isCategory
      ? `${titles[section]} ${categoryName}`
      : titles[section];

  useDocumentTitle(`${title} | Bestcycling`);

  // Update search params when filters change
  useEffect(() => {
    let search = {};
    [
      'category_nr',
      'level_nr',
      'year',
      'trainer_id',
      'duration',
      'training_type_nr',
      'training_materials',
      'search',
      'genres',
      'done_by_user',
    ].forEach(filter => {
      const value = filters[filter];
      if (value) {
        search = { ...search, [filter]: value };
      }
    });

    setSearchParams(search, { replace: true });
  }, [filters]);

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>
      <MyCollectionBanner collection={collectionElement} collectionsNames={collectionsNames} />
      <ListClassesPage
        section={section}
        collectionId={collection}
        filters={filters}
        params={params}
        levelSelector={isCategory}
      />
    </FiltersContext.Provider>
  );
};

export default FindClassesPage;
