import { useState, useEffect } from 'react';

import styles from './AnnotateButton.module.scss';
import AnnotateModal from './AnnotateModal';

import annotateImage from '../../../../img/icon-result.svg';
import { getItemToSave, deleteItemToSave } from '../Trainings/AnnotateFunctions';
import ResumeTraining from './ResumeTraining';
import ModifiedFtpModal from './ModifiedFtpModal';
import useSetting from '../Preferences/useSetting';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { FtpUpdateTypes } from '@/constants/ftpAttributes';
import { calculateMaxFtp } from '../Trainings/TrainingsUtils';
import { useProfile } from '@/contexts/app.context';

const AnnotateButton = ({ trainingClass }: { trainingClass: TrainingClass }) => {
  const { mutateApi, client } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTraining, setDataTraining] = useState<DataTraining>();
  const [isResumeOpen, setIsResumeOpen] = useState({
    resumeTraining: false,
    obtainedPoints: false,
  });
  const [isOpenFtpModified, setIsOpenFtpModified] = useState(false);
  const [customFtp, setCustomFtp] = useSetting('customFtp'); // TODO: type this setting
  const [updatedFtp, setUpdatedFtp] = useState(customFtp);
  const [ftpUpdateType, setFtpUpdateType] = useState(FtpUpdateTypes.decrease);
  const { state: profile } = useProfile();

  const saveProfile = useMutation(
    data =>
      mutateApi(['profile'], data, {
        method: 'PATCH',
      }),
    {
      onSuccess: () => client.invalidateQueries(['profile']),
    },
  );

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    if (dataTraining != null) {
      deleteItemToSave();
    }
    setIsModalOpen(false);
    setIsResumeOpen({ resumeTraining: false, obtainedPoints: false });
  };

  const checkChangeFtp = (training: DataTraining) => {
    const ftms = JSON.parse(training.ftms);
    if (decreaseFtp(training)) {
      const isDecrease = localStorage.getItem('decreaseFTP');
      if (isDecrease) {
        setIsOpenFtpModified(true);
        setUpdatedFtp(ftms.class_ftp);
        setFtpUpdateType(FtpUpdateTypes.decrease);
        localStorage.removeItem('decreaseFTP');
      }
      localStorage.setItem('decreaseFTP', 'true');
    } else if (!decreaseFtp(training)) {
      // AUMENTAR
      const maxFtp = calculateMaxFtp(300, ftms);
      if (maxFtp != profile!.ftp && maxFtp > profile!.ftp) {
        setUpdatedFtp(maxFtp);
        setIsOpenFtpModified(true);
        setUpdatedFtp(ftms.class_ftp);
        setFtpUpdateType(FtpUpdateTypes.increase);
        localStorage.removeItem('decreaseFTP');
      }
    }
  };

  const decreaseFtp = (training: DataTraining) => {
    if (training.ftms != null) {
      const ftms = JSON.parse(training.ftms);
      const classFtp = ftms.class_ftp;
      const ftp = ftms.ftp;
      return classFtp < ftp;
    }
    return false;
  };

  const onUpdateFTP = () => {
    setCustomFtp(updatedFtp);
    saveProfile.mutate({ ftp: updatedFtp });
    setIsOpenFtpModified(false);
  };

  useEffect(() => {
    const dataItem = getItemToSave();
    if (dataItem != null) {
      if (dataItem.ftms) checkChangeFtp(dataItem);
      setDataTraining(dataItem);
      openModal();
    }
  }, []);

  return (
    <>
      <button onClick={openModal} className={styles.fav} title={'Anotar entrenamiento'}>
        <div className={styles.border}>
          <img className={styles.icon} src={annotateImage} />
        </div>
      </button>
      {isOpenFtpModified && (
        <ModifiedFtpModal
          isOpen={isOpenFtpModified}
          onCancel={() => setIsOpenFtpModified(false)}
          type={ftpUpdateType}
          onUpdate={onUpdateFTP}
          ftms={dataTraining?.ftms}
        />
      )}
      {isModalOpen && !isOpenFtpModified && (
        <AnnotateModal
          trainingClass={trainingClass}
          isOpen={isModalOpen}
          onClose={closeModal}
          dataTraining={dataTraining}
          fromPlayer={!!dataTraining}
          goResume={() => {
            setIsResumeOpen({ resumeTraining: true, obtainedPoints: false });
            setIsModalOpen(false);
          }}
        />
      )}
      {(isResumeOpen.resumeTraining || isResumeOpen.obtainedPoints) && (
        <ResumeTraining
          isOpen={isResumeOpen.resumeTraining}
          isPointsOpen={isResumeOpen.obtainedPoints}
          isAnnotate={true}
          onClose={closeModal}
          goObtainedPoints={() => setIsResumeOpen({ resumeTraining: false, obtainedPoints: true })}
        />
      )}
    </>
  );
};

export default AnnotateButton;
