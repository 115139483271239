import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Colors, Names } from '../../constants/category';
import { usePreferences } from '@/contexts/app.context';
import { useShell } from '@/contexts/shell.context';
import Category from '@/constants/category';
import clsx from 'clsx';
import FreeIndicator from './FreeIndicator';
import GraphImage from '../TrainingClassDetails/GraphImage';
import GraphImages from '../TrainingClassDetails/GraphImages';
import LifePoint from '../LifePoint/LifePoint';
import OfflineIndicator from './OfflineIndicator';
import styles from './NewestTrainingClass.module.scss';
import TrainingClassTagsList from '../TagsList/TrainingClassTagsList';
import { GraphType } from '@/core/constants/graphFactory';

export default function NewestTrainingClass({ trainingClass, onClick, ...props }) {
  const { state: preferences } = usePreferences();
  const { state: shell, actions: shellActions } = useShell();

  const isOfficial = trainingClass.official;

  const handleClick = evt => {
    if (onClick != undefined) {
      evt.preventDefault();
      onClick(trainingClass);
    } else if (shell.nwjs) {
      evt.preventDefault();
      shellActions.toTrainingClass(trainingClass);
    }
  };

  const parsePublishedAt = ({ published_at: publishedAt }) => {
    return dayjs(publishedAt).format('DD MMM YYYY').toUpperCase();
  };

  const numberString = title => {
    return title.replace(/\D+/g, '');
  };
  const videoProgress = trainingClass.category_nr == 51;

  const whiteBackground =
    (trainingClass.category_nr == 1 || trainingClass.category_nr == 2) &&
    trainingClass.is_new &&
    !trainingClass.is_black;

  const getGraph = () => {
    const { official, graph, graph_watts, has_graph, category_nr } = trainingClass;

    if (official || category_nr !== 1) return graph;
    if (preferences.graph_type === GraphType.Watts && graph_watts) return graph_watts;
    return has_graph ? graph : graph_watts;
  };

  const cover = () => {
    let coverUrl = trainingClass.cover;

    return `url(${coverUrl})`;
  };

  const trainingClassUrl =
    trainingClass.category_nr == Category.VideoProgress
      ? `/videoprogress/${trainingClass.id}`
      : trainingClass.offline
      ? `/offline/trainingClass/${trainingClass.id}`
      : `/trainingclass/${trainingClass.id}`;

  return (
    <Link
      data-cy="new_training_class"
      to={trainingClassUrl}
      onClick={handleClick}
      role="link"
      aria-label={
        isOfficial
          ? `${trainingClass.short_title} ${trainingClass.subtitle}, ${trainingClass.trainer?.full_name}`
          : trainingClass.title
      }
      {...props}
    >
      <div className={`${styles.class} ${whiteBackground ? styles.bgWhite : ''}`}>
        <div className={styles.extra} style={{ flexDirection: !isOfficial && 'column' }}>
          {!videoProgress ? (
            <>
              {isOfficial ? (
                <h3
                  className={styles.classtitle}
                  style={{ color: Colors[trainingClass.category_nr] }}
                >
                  {Names[trainingClass.category_nr]} {numberString(trainingClass.short_title)}
                </h3>
              ) : (
                <>
                  <h3
                    className={clsx(styles.classtitle, styles.classTitleContent)}
                    title={trainingClass.title}
                  >
                    {trainingClass.title}
                  </h3>
                  <div className={styles.year}>{parsePublishedAt(trainingClass)}</div>
                </>
              )}
              {isOfficial && (
                <>
                  <div className={styles.classSubtitle}>
                    <div className={styles.classSubtitleContent}>{trainingClass.subtitle}</div>
                    <OfflineIndicator
                      trainingClass={trainingClass}
                      style={{ width: 20, margin: '0 0 0 10px' }}
                    />
                  </div>
                  <div className={styles.trainer}>
                    <h1>{(trainingClass.trainer && trainingClass.trainer.full_name) || ''}</h1>
                  </div>
                  <div className={styles.levelnr}>
                    Nivel
                    {[10, 20, 30].map(item => (
                      <div
                        key={`${trainingClass.id}-${item}`}
                        className={item <= trainingClass.level_nr ? styles.on : styles.off}
                      />
                    ))}
                  </div>
                </>
              )}

              {!isOfficial && <TrainingClassTagsList tagList={trainingClass.user_tags} />}
            </>
          ) : (
            <>
              <h3
                className={`${styles.classtitle} ${styles.videoprogress}`}
                style={{ color: Colors[trainingClass.category_nr] }}
              >
                {trainingClass.title}
              </h3>
              <div className={styles.year}>{parsePublishedAt(trainingClass)}</div>
            </>
          )}
        </div>

        {!isOfficial && (
          <div className={styles.noOfficialCoverContainer}>
            <div className={styles.noOfficalCover}>
              <GraphImage graph={getGraph()} />
            </div>
          </div>
        )}

        {isOfficial && (
          <>
            <div className={styles.cover} style={{ backgroundImage: cover() }}>
              <div className={styles.graphs}>
                <GraphImages trainingClass={trainingClass} />
              </div>
              {trainingClass.free && (
                <div style={{ flex: 1 }}>
                  <FreeIndicator trainingClass={trainingClass} />
                </div>
              )}
              <div className={styles.info}>
                <LifePoint type="resistance" value={trainingClass.life_resistance_points} />
                <LifePoint type="force" value={trainingClass.life_force_points} />
                <LifePoint type="flexibility" value={trainingClass.life_flexibility_points} />
                <LifePoint type="mind" value={trainingClass.life_mind_points} />
              </div>
            </div>

            <div
              className={styles.trianglecategory}
              style={{ backgroundColor: Colors[trainingClass.category_nr] }}
            ></div>
          </>
        )}
      </div>
    </Link>
  );
}
