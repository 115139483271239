import styles from './homeplaylist.module.scss';

import logoCycling from '../../../../img/home/logo-bestcycling--blanco.svg';
import logoTraining from '../../../../img/home/logo-besttraining--blanco.svg';
import logoRunning from '../../../../img/home/logo-bestrunning--blanco.svg';
import logoWalking from '../../../../img/home/logo-bestwalking--blanco.svg';
import logoMind from '../../../../img/home/logo-bestmind--blanco.svg';
import logoBalance from '../../../../img/home/logo-bestbalance--blanco.svg';

import logoMenshealth from '../../../../img/home/logo-menshealth--blanco.svg';
import logoWomenshealth from '../../../../img/home/logo-womenshealth--blanco.svg';
import logoRunnersworld from '../../../../img/home/logo-runnersworld--blanco.svg';

import bgCycling from '../../../../img/home/collection-bestcycling.png';
import bgTraining from '../../../../img/home/collection-besttraining.png';
import bgRunning from '../../../../img/home/collection-bestrunning.png';
import bgWalking from '../../../../img/home/collection-bestwalking.png';
import bgMind from '../../../../img/home/collection-bestmind.png';
import bgBalance from '../../../../img/home/collection-bestbalance.png';

import bgMenshealth from '../../../../img/home/collection-menshealth.png';
import bgWomenshealth from '../../../../img/home/collection-womenshealth.png';
import bgRunnersworld from '../../../../img/home/collection-runnersworld.png';
import { Link } from 'react-router-dom';

const colors = {
  combinadas: 'black',
  bestcycling: '#ff7900',
  bestrunning: '#fcd900',
  besttraining: '#f13b46',
  bestbalance: '#69ae00',
  bestwalking: '#8d88c5',
  bestmind: '#1D8CB9',
};
const bgs = {
  bestcycling: bgCycling,
  bestrunning: bgRunning,
  besttraining: bgTraining,
  bestbalance: bgBalance,
  bestwalking: bgWalking,
  bestmind: bgMind,
  menshealth: bgMenshealth,
  womenshealth: bgWomenshealth,
  runnersworld: bgRunnersworld,
};

const logos = {
  combinadas: 'white',
  bestcycling: logoCycling,
  besttraining: logoTraining,
  bestbalance: logoBalance,
  bestrunning: logoRunning,
  bestwalking: logoWalking,
  bestmind: logoMind,
  menshealth: logoMenshealth,
  womenshealth: logoWomenshealth,
  runnersworld: logoRunnersworld,
};

const alt = {
  bestcycling: 'bestcycling',
  besttraining: 'besttraining',
  bestbalance: 'bestbalance',
  bestrunning: 'bestrunning',
  bestwalking: 'bestwalking',
  bestmind: 'bestmind',
  menshealth: "Men's Health",
  womenshealth: "Women's Health",
  runnersworld: "Runner's World",
};

export default function HomePlaylist({ playlist = {}, ...props }) {
  let title = playlist && playlist.title;
  if (title.includes("Men's Health")) {
    title = 'menshealth';
  } else if (title.includes("Women's Health")) {
    title = 'womenshealth';
  } else if (title.includes("Runner's World")) {
    title = 'runnersworld';
  } else {
    title = title.split(' ').pop();
  }

  const cardBackground = bgs[title.toLowerCase()]
    ? `url(${bgs[title.toLowerCase()]})`
    : colors[title.toLowerCase()]
    ? colors[title.toLowerCase()]
    : `url(${playlist.image})`;

  return (
    <Link to={`/playlists/${playlist.id}`} role="link" {...props}>
      <div className={styles.playlist_thumbnail}>
        <div
          className={styles.playlist_category}
          style={{
            width: '100%',
            height: '100%',
            background: cardBackground,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: '50%',
          }}
        >
          <div>
            <span>Colecciones</span>
            <img lang="en" alt={alt[title.toLowerCase()]} src={logos[title.toLowerCase()]} />
          </div>
        </div>
      </div>
    </Link>
  );
}
