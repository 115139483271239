import Pause from '@/core/components/Icons/Reproductor/Pause';
import Play from '@/core/components/Icons/Reproductor/Play';
import LoadingIndicator from '@/core/components/LoadingIndicator';
import useDebounceFunction from '@/core/hooks/useDebounceFunction';
import useFullscreen from '@/core/hooks/useFullscreen';
import useMedia from '@/core/hooks/useMedia';
import { useEffect, useRef, useState } from 'react';

import styles from './CustomControls.module.scss';

const showControlsDelay = 5000;

const generateHMS = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return { hours, minutes, remainingSeconds };
}

// H:mm:ss || mm:ss
const formatSecondsToHMS = (seconds: number, targetSeconds: number) => {
  const { hours, minutes, remainingSeconds } = generateHMS(seconds);
  const { hours: targetHours, minutes: targetMinutes } = generateHMS(targetSeconds);

  const h = targetHours || hours;
  const m = targetMinutes || minutes;

  const padHours = h < 10 ? 1 : 2;
  const padMinutes = !h && m < 10 ? 1 : 2;

  const hourStr = `${h ? `${hours.toString().padStart(padHours, '0')}:` : ''}`;
  const minuteStr = `${minutes.toString().padStart(padMinutes, '0')}`;
  const secondStr = `${remainingSeconds.toString().padStart(2, '0')}`;

  return `${hourStr}${minuteStr}:${secondStr}`;
}

type CustomControlsProps = {
  totalDuration: number;
  videoContainer: HTMLDivElement;
  video: HTMLVideoElement;
}

const CustomControls = ({ totalDuration, videoContainer, video }: CustomControlsProps) => {
  const [isShowingControls, setIsShowingControls] = useState(true);

  const { toggleFullScreen } = useFullscreen(false, videoContainer);

  const progressRef = useRef<HTMLInputElement>(null);

  const showControls = () => setIsShowingControls(true);
  const hideControls = () => setIsShowingControls(false);

  const hideControlsDebounced = useDebounceFunction(hideControls, showControlsDelay);

  const updateVisualProgressBar = (value: number) => {
    const progressBar = progressRef.current;

    if (!progressBar) return;

    const min = parseInt(progressBar.min);
    const max = parseInt(progressBar.max);

    const result = (value - min) * 100 / (max - min);

    progressBar.style.backgroundSize = `${result}% 100%`;
  }

  const { currentDuration, isPlaying, isWaiting, togglePlaying, seekTo } = useMedia({
    media: video,
    onTogglePlaying: hideControlsDebounced,
    onTimeUpdate: updateVisualProgressBar,
  });

  const showAndHideControlsWithDelay = () => {
    showControls();
    hideControlsDebounced();
  }

  const toggleControls = () => {
    if (isShowingControls) {
      hideControls();
    } else {
      showAndHideControlsWithDelay();
    }
  }

  useEffect(() => {
    hideControlsDebounced();
  }, []);

  useEffect(() => {
    if (isShowingControls) {
      updateVisualProgressBar(currentDuration);
    }
  }, [isShowingControls]);

  return (
    <div className={styles.container} onMouseMove={showAndHideControlsWithDelay}>
      <div className={styles.gestureDetector} onClick={toggleControls} />
      {
        isShowingControls && (
          <>
            <div className={styles.bottomControls}>
              <button onClick={togglePlaying}>
                {
                  isPlaying ? <Pause /> : <Play />
                }
              </button>
              <div className={styles.progression}>
                <span className={styles.duration}>{formatSecondsToHMS(currentDuration, totalDuration)}</span>
                <input 
                  ref={progressRef}
                  className={styles.progressionRange} 
                  type='range'
                  min={0}
                  max={totalDuration} 
                  value={currentDuration}
                  onChange={seekTo} />
                <span className={styles.duration}>{formatSecondsToHMS(totalDuration, 0)}</span>
              </div>
              <button className={styles.fullScreenButton} onClick={toggleFullScreen}>
                <i className='fa fa-arrows-alt' />
              </button>
            </div>
            {
              isWaiting && (
                <div className={styles.buffering}>
                  <LoadingIndicator />
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default CustomControls;