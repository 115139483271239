import styles from './PlayModal.module.scss';
import iconNoBluetooth from '@/img/no-bluetooth-icon.svg';
import iconBluetoothConnected from '@/img/bluetooth_icon_connected.svg';
import Bpm from '../Preferences/Bpm';
import { BluetoothDeviceState } from '@/constants/bluetooth';

interface ConnectedDevicesTileProps {
  onClick: () => void;
  devices: BTDevice[];
}
export default function ConnectedDevicesTile({ onClick, devices }: ConnectedDevicesTileProps) {
  const someDeviceIsConnected = () => {
    return !!devices.find(d => d.state === BluetoothDeviceState.connected);
  };
  return (
    <label className={styles.bluetooth_container} onClick={onClick}>
      <div className={styles.bluetooth_label}>
        <div className={styles.span0}>Dispositivos Bluetooth</div>
        {devices.map(d => (
          <Bpm dev={d} key={d.id} />
        ))}
      </div>

      <img
        src={someDeviceIsConnected() ? iconBluetoothConnected : iconNoBluetooth}
        className={styles.iconBluetooth}
        alt=""
      />
    </label>
  );
}
