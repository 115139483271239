import { useEffect } from 'react';
import { CommentForm } from '../../Comments';
import Comments from '../../TrainingClassDetails/Comments';
import useDoneClass from '@/core/hooks/useDoneClass';
import useEventTracking from '@/core/hooks/useEventTracking';
import useAnnotate from '../../Player/useAnnotate';
import { useAppContext } from '@/core/contexts/app.context';
import { useAuth } from '@/core/contexts/auth.context';
import styles from './VideoProgress.module.scss';
import IntegratedVideo from './IntegratedVideo/IntegratedVideo';
import { automaticAnnotate, deleteItemToSave } from '../../Trainings/AnnotateFunctions';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/core/api/ApiProvider';
import useDocumentTitle from '@rehooks/document-title';

const hash = '#ended';

type VideoProgressProps = {
  trainingClass: TrainingClass;
  media: string;
};

export default function VideoProgress({ trainingClass, media }: VideoProgressProps) {
  const { state: auth } = useAuth();
  const {
    state: { user, profile },
  } = useAppContext();
  const title =
    trainingClass.official && trainingClass.subtitle
      ? `${trainingClass.short_title} / ${trainingClass.subtitle}`
      : trainingClass.short_title;
  useDocumentTitle(`${title} | Bestcycling`);

  const { client, mutateApi } = useApi();

  const { shouldAnnotate, annotateTrainingClass } = useAnnotate(
    {
      ...trainingClass,
      category: trainingClass.category_nr,
    },
    media,
  );

  const { doneClass } = useDoneClass();
  const { playStart, playEnd } = useEventTracking();

  // @ts-expect-error
  const srcVideo = `${config.API_V2_BASE}/media_assets/${trainingClass.id}/?type=${media}&access_token=${auth.accessToken}`;

  const saveTrainings = useMutation(
    data =>
      mutateApi(['trainings'], data, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['trainings']);
        client.invalidateQueries(['profile_programs']);
      },
    },
  );

  const annotate = async () => {
    if (shouldAnnotate) {
      doneClass(trainingClass, media);
      annotateTrainingClass(hash);

      const trainingAnnotate = automaticAnnotate(auth.accessToken, profile, user);

      if (trainingAnnotate) {
        // @ts-expect-error
        await saveTrainings.mutateAsync(trainingAnnotate);
        deleteItemToSave();
      }
    }
  };

  useEffect(() => {
    playStart('play_start', trainingClass);

    return () => {
      annotate();
      playEnd('play_end', trainingClass, hash, shouldAnnotate);
    };
  }, []);

  return (
    <>
      {trainingClass && (
        <div>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{trainingClass.title}</span>
          </div>

          <IntegratedVideo url={srcVideo} autoplay={true} />

          <div className={styles.commentsContainer}>
            <section className={styles.comments}>
              <h2>DEJA UN COMENTARIO</h2>
              <div className={styles.lineDivider}></div>
              <div className={styles.form}>
                <CommentForm
                  user={user!}
                  commentableId={trainingClass.id}
                  commentableType="TrainingClass"
                  isFree={trainingClass.free}
                />
              </div>
              <h3>{trainingClass.comments_count} Comentarios</h3>
              <div className={styles.lineDivider}></div>
              <Comments trainingClass={trainingClass} />
            </section>
          </div>
        </div>
      )}
    </>
  );
}
