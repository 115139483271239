import { useState } from "react";

const useShare = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [error, setError] = useState("");

  const loading = () => {
    setIsLoading(true);
    setLink("");
    setError("");
  }

  const success = (data: string) => {
    setIsLoading(false);
    setLink(data);
  }

  const failed = (data: string) => {
    setIsLoading(false);
    setError(data);
  }

  return {
    isLoading,
    link,
    error,
    loading,
    success,
    failed,
  };
}

export default useShare;