import clsx from 'clsx';
import styles from './Tag.module.scss';

export default function Tag({ children, color }) {
  const css = clsx([styles.tagContainer, color && styles[`tag-${color}`]]);

  return (
    <span className={css}>
      {children}
    </span>
  );
}
