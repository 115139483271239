import dayjs from 'dayjs';
import styles from './TrainingClassZones.module.scss';

interface TrainingClassZonesProps {
  zonesPercentages: PercentData[];
}

export default function TrainingClassZones({
  zonesPercentages,
}: TrainingClassZonesProps) {
  return (
    <div className={styles.zonesContainer}>
      <ZoneRow
        background="#F13B46"
        percent={zonesPercentages[4].percent}
        name="Máxima"
        time={zonesPercentages[4].total}
      />
      <ZoneRow
        background="#FF7900"
        percent={zonesPercentages[3].percent}
        name="Intenso"
        time={zonesPercentages[3].total}
      />
      <ZoneRow
        background="#FCD900"
        percent={zonesPercentages[2].percent}
        name="Moderado"
        time={zonesPercentages[2].total}
        colorText="#151515"
      />
      <ZoneRow
        background="#69AE00"
        percent={zonesPercentages[1].percent}
        name="Ligero"
        time={zonesPercentages[1].total}
      />
      <ZoneRow
        background="#1D8CB9"
        percent={zonesPercentages[0].percent}
        name="Muy ligero"
        time={zonesPercentages[0].total}
      />
    </div>
  );
}

interface ZoneRowProps {
  background: string;
  percent: number;
  name: string;
  time: number;
  colorText?: string;
}

const ZoneRow = ({ background, percent, name, time, colorText }: ZoneRowProps) => {
  const timeFormatted = dayjs.utc(time * 1000).format('mm:ss');
  return (
    <div className={styles.zonesRowContainer}>
      <div className={styles.percentageProgress} style={{ backgroundColor: background }} />
      <div className={styles.content} style={{ color: colorText }}>
        <p className={styles.percent}>{Math.round(percent)}%</p>
        <p className={styles.name}>{name}</p>
        <p className={styles.time}>{timeFormatted}</p>
      </div>
      <div
        className={styles.shadow}
        style={{
          width: 100 - percent + '%',
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
        }}
      />
    </div>
  );
};
