import { useDownload } from '@/contexts/downloads.context';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../api/ApiProvider';
import groupBy from '@/core/utils/groupBy';
import { getTrainingByBlock } from '@/core/components/Trainings/utils';

/**
 * Fetches a training class by id
 *
 * @param id training class id
 * @param include
 * @returns data, isLoading, isError
 */
export default function useFetchTrainingClass(id?: string, include: string[] = []) {
  const { fetchApi } = useApi();

  const download = useDownload(id!);

  const query = useQuery<TrainingClass>(
    ['training_classes', id, include],
    async () => {
      const tc = await fetchApi(['training_classes', id, { include }]);
      return tc.data;
    },
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  const progressionBlocks = useExtractProgressionBlocks(query.data);

  const data = useMemo((): TrainingClass | undefined => {
    let _data: TrainingClass | undefined = undefined;
    if (!query.data && !download) return;
    if (query.data && !download) {
      _data = query.data;
    } else if (!query.data && !query.isLoading && download) {
      _data = download.trainingClass;
    } else if (query.data && download) {
      _data = query.data;
      const { downloadedMedia } = download;
      downloadedMedia.forEach(m => {
        if (m.downloaded)
          _data = { ..._data, media: [..._data.media.filter(d => d.type != m.type), m] };
      });
    }

    if (!_data) return;
    return { ..._data, progression_blocks: progressionBlocks };
  }, [query.data, progressionBlocks]);

  return {
    ...query,
    data,
  };
}

export const useExtractProgressionBlocks = (trainingClass?: TrainingClass): ProgressionBlock[] | null => {
  const [progressionBlocks, setProgressionBlocks] = useState<ProgressionBlock[] | null>(null);

  useEffect(() => {
    const pB = getTrainingByBlock(trainingClass);
    setProgressionBlocks(pB);
  }, [trainingClass?.progression]);

  return progressionBlocks;
};
