import { useEffect, useRef } from 'react';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import LoadingIndicator from '../LoadingIndicator';
import { useShell } from '@/contexts/shell.context';
import OfflineInfiniteErrorListPopup from '../OfflineInfiniteErrorListPopup/OfflineInfiniteErrorListPopup';

/**
 * Componente para dibujar listados infinitos. Obtiene por parámetros información de una query
 * obtenida mediante useInfiniteQuery() de react-query.
 */
interface InfiniteQueryListProps {
  isSuccess: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  isError?: boolean;
  children: React.ReactNode;
  errorLoadingMessage?: string;
}

export default function InfiniteQueryList({
  isSuccess,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  isError,
  children,
  errorLoadingMessage,
}: InfiniteQueryListProps) {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const {
    state: { online },
  } = useShell();
  const onlineRef = useRef(online);

  useEffect(() => {
    onlineRef.current = online;
  }, [online]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (!onlineRef.current && !isSuccess) return;
      fetchNextPage();
    },
    enabled: !isFetchingNextPage && !!hasNextPage,
  });

  return (
    <>
      {children}
      {hasNextPage && (
        <div
          style={{
            padding: '50vh 0 4em 0',
            position: 'relative',
            bottom: '50vh',
          }}
          ref={loadMoreRef}
        >
          {isFetchingNextPage ? (
            <LoadingIndicator />
          ) : (
            isError &&
            errorLoadingMessage && (
              <OfflineInfiniteErrorListPopup
                message={errorLoadingMessage}
                onRetry={() => fetchNextPage()}
              />
            )
          )}
        </div>
      )}
    </>
  );
}
