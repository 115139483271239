export const formatVelocity = (metersPerSecond: number, format: 'km/h' | 'min/km') => {
  let velocity =
    metersPerSecond != null && metersPerSecond > 0 ? metersPerSecond + ' ' + format : '-';
  if (metersPerSecond != null && metersPerSecond > 0) {
    if (format == 'km/h') {
      const formatedValues = (metersPerSecond * 3600) / 1000;
      velocity = formatedValues.toFixed(2) + ' ' + format;
    } else if (format == 'min/km') {
      const total = 1000 / (metersPerSecond * 60);
      const minutes = Math.floor(total);
      const secondsRest = Math.floor(60 * (total - minutes));

      const formattedMinutes = minutes;
      const formattedSeconds = secondsRest > 10 ? secondsRest : '0' + secondsRest;
      velocity = formattedMinutes + ':' + formattedSeconds + '' + format;
    }
  }
  return velocity;
};

export const secondsToString = (secondsNumber: number) => {
  const dateObj = new Date(secondsNumber * 1000);

  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  if (hours > 0)
    return (
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0')
    );

  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
};

export const stringToSeconds = (timeString: string, format = 2): number => {
  const splitTimeStart = timeString.split(':');

  if (splitTimeStart.length < 2) return parseInt(splitTimeStart[0], 10);

  if (splitTimeStart.length === 2 || splitTimeStart.length === format) {
    return parseInt(splitTimeStart[0], 10) * 60 + parseInt(splitTimeStart[1], 10);
  }

  return (
    parseInt(splitTimeStart[0], 10) * 3600 +
    parseInt(splitTimeStart[1], 10) * 60 +
    parseInt(splitTimeStart[2], 10)
  );
};

export const removeMilisecondsTimeString = (timeString: string) => {
  const splitTimeStart = timeString.split(':');

  if (splitTimeStart.length > 2) {
    splitTimeStart.pop();
    return splitTimeStart.join(':');
  }
  return timeString;
};

export const secondsFromString = (currentTimestring: string, containsMilliseconds?: boolean) => {
  let timestring = currentTimestring;
  const patternMinSec = /^[0-9]{1,2}:[0-9]{2}$/i;
  const patternHourMinSec = /^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/i;
  const patternHourMinSecMilis = /^[0-9]{1,2}:[0-9]{2}:[0-9]{2}:[0-9]{1,4}$/i;

  if (patternMinSec.test(timestring)) {
    timestring = '00:' + timestring;
  }

  if (patternHourMinSec.test(timestring) || patternHourMinSecMilis.test(timestring)) {
    const timeParts = timestring
      .split(':')
      .map(s => {
        return parseInt(s, 10);
      })
      .reverse();
    if (containsMilliseconds || patternHourMinSecMilis.test(timestring)) {
      timeParts.shift();
    }
    return timeParts[0] + (timeParts[1] || 0) * 60 + (timeParts[2] || 0) * 3600;
  }

  return false;
};

const findCount = (method: any, timeString: any, annotationList: any[] | null) => {
  let count = '-';
  if (annotationList != null && annotationList.length > 0) {
    annotationList.forEach(
      (annotation: { method: string | null; timeString: null; number: null }) => {
        const methodAnnotation =
          annotation.method != null ? annotation.method.toLowerCase().trim() : '';
        const timeStringAnnotation = annotation.timeString != null ? annotation.timeString : '';
        const countAnnotation = annotation.number != null ? annotation.number : '-';
        if (method == methodAnnotation && timeString == timeStringAnnotation) {
          count = countAnnotation;
          return count;
        }
        return count;
      }
    );
  }
  return count;
};
const roundSeconds = (seconds: number, precision: number) => {
  const rest = seconds % precision;
  let roundedSeconds = seconds;
  if (rest > 0) {
    if (rest < precision / 2) {
      roundedSeconds -= rest;
    } else {
      roundedSeconds += precision - rest;
    }
  }
  return roundedSeconds;
};

export const calculateAverage = (data: number[]) => {
  if (data?.length > 0) {
    return data.reduce((value, item) => value + item) / data.length;
  }
  return 0;
};

export const getPower = ({ progressionWatts, ftp, ftmsValues }): number[] => {
  let power = [];
  if (ftmsValues != null) {
    const watt = ftmsValues.watt;
    if (watt?.length > 0) {
      power = watt.map((e: { quantity: string }) => parseInt(e.quantity, 10));
    }
  } else {
    power = progressionWatts.map(row => {
      const rpm = row.rpm
        .toString()
        .split('-')
        .map((part: string) => parseInt(part, 10));
      const rpmAvg = calculateAverage(rpm);
      return Math.floor(ftp * (rpmAvg / 100));
    });
  }
  return power;
};

export const getMovingAverage = (power: string | any[], window: number) => {
  const out = [];
  for (let i = window; i < power.length; i += 1) {
    const windowArea = power.slice(i - window, i);
    out.push(calculateAverage(windowArea));
  }

  if (out.length == 0) {
    out.push(calculateAverage(power));
  }

  return (out.reduce((value, avg) => value + avg) / out.length) ** 4;
};

export const getNormalizedPower = ({ progressionWatts, ftp, ftmsValues }) => {
  const power = getPower({ ftmsValues: ftmsValues, progressionWatts: progressionWatts, ftp: ftp });
  const movingAverage = getMovingAverage(power, 30);
  return parseInt(movingAverage ** (1 / 4), 10);
};

export const getIntensityFactor = ({ progressionWatts, ftp, ftmsValues }) => {
  const normalized = getNormalizedPower({
    ftmsValues: ftmsValues,
    progressionWatts: progressionWatts,
    ftp: ftp,
  });
  return Math.round((normalized / ftp + Number.EPSILON) * 10) / 10;
};

export const getTss = ({ progressionWatts, ftp, ftmsValues, seconds }) => {
  const normalized = getNormalizedPower({
    ftmsValues: ftmsValues,
    progressionWatts: progressionWatts,
    ftp: ftp,
  });

  const intensity = getIntensityFactor({
    ftmsValues: ftmsValues,
    progressionWatts: progressionWatts,
    ftp: ftp,
  });

  return parseInt(((seconds * normalized * intensity) / (ftp * 3600)) * 100, 10);
};


export const getPowerWithSeconds = (ftmsValues: any) => {
  const powerSeconds = new Map<number, number>();

  const watt = ftmsValues?.watt;
  console.log('watt', watt);
  if (watt != null) {
    watt.forEach((e: any) => {
      powerSeconds.set(e.second, e.quantity);
    });
  }
  return powerSeconds;
};

export const applyFactorFTP = (avg: number, seconds: number) => {
  if (seconds == 300) {
    return Math.floor(avg * 0.8);
  } // 5 min se aplica un factor de
  if (seconds == 1200) {
    return Math.floor(avg * 0.95);
  }
  // TODO: quitar
  if (seconds == 60) {
    return Math.floor(avg * 0.95);
  }
  return 0;
};

export const getMaxFtp = (power: Map<number, number>, seconds: number) => {
  const out: number[] = [];

  power.forEach((value, key) => {
    const toSeconds = key + seconds;
    const windowPower = Array.from(power.values());

    // TODO: probar más veces en bici
    const windowPowerFilter = windowPower.filter(sec => sec < key || sec >= toSeconds);
    if (windowPower.length > seconds / 10) {
      const windowArea = windowPowerFilter;
      const avg = calculateAverage(windowArea);
      out.push(applyFactorFTP(avg, seconds));
    }
  });
  if (out.length > 0) {
    return Math.max(...out);
  }
  return 0;
};

export const calculateMaxFtp = (windowSeconds: number, ftmsValues: any) => {
  const powerSeconds = new Map(getPowerWithSeconds(ftmsValues));
  return getMaxFtp(powerSeconds, windowSeconds);
};
