import Button from '../UI/Button/Button';
import React, { useRef } from 'react';
import { FormInput } from '../FormControls';
import IconPlus from '../Icons/IconPlus';
import TagsListAdded from '../TagsList/TagListAdded';
import TagsList from '../TagsList/TagsList';
import styles from './AddTags.module.scss';

export interface IAddTags {
  addedTags: [];
  noAddedTags: [];
  showTagsList: boolean;
  autoFocus: boolean;
  existsTagByName: boolean;
  addTags: (tag: UserTag) => void;
  removeAddedTag: () => void;
  onChangeFilter: (value: string) => void;
  createTag: () => void;
  onEnter: () => void;
  onInputFocus: () => void;
}

const AddTags = ({
  addedTags,
  noAddedTags,
  showTagsList = true,
  autoFocus = false,
  existsTagByName,
  removeAddedTag,
  onChangeFilter,
  addTags,
  createTag,
  onEnter = () => {},
  onInputFocus = () => {},
}: IAddTags) => {
  const inputTagRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    if (!inputTagRef.current) return;

    onChangeFilter('');
    inputTagRef.current.value = '';
    inputTagRef.current.focus();
  };

  const setAddTags = (tag: Tag) => {
    addTags(tag);
    resetInput();
  };

  const onSuggestClick = () => {
    createTag();
    resetInput();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;

    const { value } = event.target as HTMLInputElement;
    event.preventDefault();

    if (value) {
      onChangeFilter(value);

      createTag();
      resetInput();
    } else onEnter();
  };

  return (
    <>
      <div className={styles.containerTagsAdded}>
        <TagsListAdded tagList={addedTags} onSelect={removeAddedTag} />

        <div style={{ flex: '1 auto' }}>
          <FormInput
            ref={inputTagRef}
            style={{ outline: '0', width: '100%' }}
            type="text"
            autoFocus={autoFocus}
            placeholder="Escribe el nombre de una colección"
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFilter(event.target.value)
            }
            onFocus={onInputFocus}
          />
        </div>
      </div>

      {showTagsList && <TagsList tagList={noAddedTags} onSelect={setAddTags}></TagsList>}
      {existsTagByName && (
        <Button
          type="button"
          style={{ marginTop: '10px' }}
          variant="textbutton"
          onClick={onSuggestClick}
        >
          <span className={styles.createTagButton}>
            <IconPlus />
            CREAR COLECCIÓN
          </span>
          <span>&quot;{inputTagRef.current?.value}&quot;</span>
        </Button>
      )}
    </>
  );
};

export default AddTags;
