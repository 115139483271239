import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { useAppContext } from '@/contexts/app.context';
import { useShell } from '@/contexts/shell.context';
import userModel from '../../models/user.model';
import LoadingIndicator from '../LoadingIndicator';
import Button from '../UI/Button';
import { useRemoteConfig } from '@/contexts/remote.context';

interface ISubscriptionVerification {
  user: IUser;
  onSubscribe: () => void;
  onClose: () => void;
}

export default function SubscriptionVerification({
  user,
  onSubscribe,
  onClose,
}: ISubscriptionVerification) {
  const { dispatch } = useAppContext();
  const { state: shell } = useShell();
  const { isRestrictionsEnabled, isRestrictedTrialSubscription, isAllowInviteCodeTrial } =
    useRemoteConfig();
  const { fetchApi } = useApi();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isRefetched, setIsRefetched] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } = useQuery(['user-verification'], () =>
    fetchApi('user')
  );

  const isValid = data?.data && isOnline;

  const description = isValid
    ? ''
    : `Si tienes una suscripción activa, asegúrate de tener conexión a internet para que podamos
    comprobarlo y vuelve a intentarlo.`;

  const buttonText = isValid ? 'SUSCRIBETE' : 'VOLVER A INTENTAR';

  const getSubscriptionURL = () => {
    const isTrialPeriodUsed = user.used_trial_period;
    const isReferred = user.is_referred;

    return (isRestrictionsEnabled() && !isRestrictedTrialSubscription() && !isTrialPeriodUsed) ||
      (isReferred && !isTrialPeriodUsed && isAllowInviteCodeTrial())
      ? config.SUBSCRIBE_URL_TRIAL
      : config.SUBSCRIBE_URL;
  };

  const checkUser = (userCheck: IUser) => {
    if (!userCheck.isSubscribed()) return;

    onSubscribe();
    onClose();
  };

  const redirectToSubscriptionPage = () => {
    if (!navigator.onLine) return;

    const url = getSubscriptionURL();

    if (shell.nwjs) {
      const gui = window.top?.BTD?.gui;
      if (gui) {
        gui.Shell.openExternal(`${location.origin}${url}`);
      }
    } else {
      window.location.href = url;
    }
  };

  const onClick = () => {
    if (!isValid) {
      setIsRefetched(true);
      refetch();
    } else {
      redirectToSubscriptionPage();
    }

    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    if (!isSuccess) return;

    dispatch({ type: 'user', payload: data.data });

    if (isRefetched) {
      const updatedUser = userModel(data.data);
      checkUser(updatedUser);
    }
  }, [data, isSuccess]);

  return (
    <div
      style={{
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!user.isSubscribed() && (
        <h2
          style={{
            color: 'white',
            fontFamily: 'Montserrat, Helvetica, Arial, serif',
            fontSize: '21px',
            fontWeight: '700',
            lineHeight: '30px',
            textAlign: 'center',
            textTransform: 'uppercase',
            maxWidth: '90%',
            margin: '0',
          }}
        >
          Se necesita una suscripción activa
        </h2>
      )}

      {isLoading || isFetching ? (
        <div style={{ height: 100 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          <p style={{ margin: '1em 0', fontSize: '15px', lineHeight: '19px' }}>
            {`Para poder acceder a este contenido se necesita estar suscrito a Bestcycling. ${description}`}
          </p>
          <Button style={{ margin: '25px auto 0' }} onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}
