import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  BikeDataEmitter,
  BTAction,
  BTState,
  HeartRateEmitter,
  IBTContext,
} from './bluetoothConnection.types';
import { useToast } from './toast.context';
import {
  BikeDataFeatures,
  BluetoothDeviceState,
  BluetoothDeviceTypes,
  BTStatus,
  GattSpecification,
} from '@/constants/bluetooth';
import ConnectingBikeModal from '@/components/Bluetooth/ConnectingBikeModal';

const initialState: BTState = {
  status: BTStatus.unknown, // Estado actual del controlador bluetooth
  bluetoothDevices: new Map(), // Hash con la lista de dispositivos bluetooth encontrados
  heartRate: undefined,
  heartRateEmitter: new HeartRateEmitter(),
  bikeDataEmitter: new BikeDataEmitter(),
  typeBluetooth: 'unknown',
};

const reducer = (state: BTState, action: BTAction): BTState => {
  let name;
  let connected;
  let device;
  switch (action.type) {
    case 'clean_devices':
      return {
        ...state,
        bluetoothDevices: new Map(),
      };
    case 'bluetooth_state':
      return {
        ...state,
        status: action.payload,
      };

    case 'add_device':
      name = action.payload.device.name;
      connected = state.bluetoothDevices;
      device = connected.get(name);

      device = { ...device, ...action.payload.device };

      // Cambiamos al nombre que el id para dispositivos chromium cambia de conectado a no
      connected.set(name, device);

      return {
        ...state,
        bluetoothDevices: connected,
      };
    case 'remove_device':
      name = action.payload.device.name;
      connected = state.bluetoothDevices;
      // Cambiamos al nombre que el id para dispositivos chromium cambia de conectado a no
      connected.delete(name);

      return {
        ...state,
        bluetoothDevices: connected,
      };

    case 'change_status_device':
      name = action.payload.device.name;
      connected = state.bluetoothDevices;
      device = connected.get(name);

      if (device != null) {
        device.state = action.payload.device.state;
        if (device.deviceType == 'unknown' && action.payload.device.deviceType != 'unknown') {
          device.deviceType = action.payload.device.deviceType;
          device.parserType = action.payload.device.parserType;
        }
        connected.set(name, device);
      } else {
        connected.set(name, action.payload.device);
      }

      return {
        ...state,
        bluetoothDevices: connected,
      };
    case 'disconnect_device':
      return {
        ...state,
      };
    case 'update_heart_rate':
      name = action.payload.device.name;
      connected = state.bluetoothDevices;
      device = connected.get(name) || action.payload.device;
      device.data = action.payload.heartRate;
      connected.set(name, device);

      return {
        ...state,
        bluetoothDevices: connected,
      };
    case 'update_bike_data':
      name = action.payload.device.name;
      connected = state.bluetoothDevices;
      device = connected.get(name) || action.payload.device;
      device.data = action.payload.bikeData;
      connected.set(name, device);

      return {
        ...state,
        bluetoothDevices: connected,
      };
    case 'set_bluetooth_device_chromium':
      name = action.payload.device.name || '';

      // El id no coincide para los dispositivos conectados
      connected = state.bluetoothDevices;
      device = connected.get(name);

      if (device != null) {
        device.gatt = action.payload.server;
        connected.set(name, device);
      }
      return {
        ...state,
        bluetoothDevices: connected,
      };
    case 'set_type_bluetooth':
      return {
        ...state,
        typeBluetooth: action.payload.type,
      };
    case 'fake_device':
      return {
        ...state,
      };
    case 'remove_devices':
      const bluetoothDevices = state.bluetoothDevices;
      const names = action.payload.devicesNames;
      names.forEach(nameOfDevice => {
        bluetoothDevices.delete(nameOfDevice);
      });
      return {
        ...state,
        bluetoothDevices: bluetoothDevices,
      };
    default:
      return state;
  }
};

const BTContext = createContext<IBTContext>({
  state: initialState,
  actions: {
    hasNewApi: () => false,
    startScan: () => {},
    connectDevice: () => console.log('Unimplemented'),
    disconnectDevice: () => console.log('Unimplemented'),
    getHeartRateList: () => [],
    getBluetoothDevices: (deviceType?: BluetoothDeviceTypes) => [],
    getConnectedDevices: () => [],
    getNoConnectedDevices: () => [],
    getAvaiableDevices: () => [],
    getFirstDeviceConnected: () => undefined,
    getFeatures: () => Promise.resolve([]),
    getLevelRange: () => Promise.resolve(new Map()),
    isAvailableBluetooth: () => false,
    isDeviceConnected: () => true,
    isBluetoothConnected: () => false,
    showBluetoothModalOff: () => console.log('No bluetooth modal true'),
    hideBluetoothModalOff: () => console.log('No bluetooth modal false'),
    setPowerTarget: (power: number) => console.log('Unimplemented'),
    stopPowerTarget: () => console.log('Unimplemented'),
    setResistanceTarget: (power: number) => console.log('Unimplemented'),
    autoMode: (enable: boolean) => console.log('Unimplemented'),
    connectDeviceChromium: (device: BluetoothDevice) => false,
    getGattServices: () => [],
    removeNotConnectedDevices: () => console.log('remove devices'),
  },
  noBluetoothModal: false,
});
const getGattServices = () => {
  return [
    { services: [GattSpecification.heartRate.service] },
    { services: [GattSpecification.ftms.service] },
    { services: [GattSpecification.power.service] },
    { services: [GattSpecification.zycleButton.service] },
    { services: [GattSpecification.bhCustom.service] },
  ];
};
const getDeviceFromList = (state: BTState, id: string): BTDevice | undefined => {
  return state.bluetoothDevices.get(id);
};

const getDevicesFromList = (state: BTState, type: BluetoothDeviceTypes) => {
  const values = Array.from(state.bluetoothDevices.values());
  return values.filter(device => device.deviceType == type);
};

export const BTProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [noBluetoothModal, setBluetoothModal] = useState(false);
  const { showToast } = useToast();
  const btRef = useRef(true);

  let timeoutId: NodeJS.Timeout | undefined;

  // Ejecutamos el listener para obtener información sobre el estado del controlador de bluetooth y su estado
  const enableBluetootStatusListener = () => {
    if (window.bluetoothManagerAPI != null) {
      window.bluetoothManagerAPI?.handleReciveStatus((_, status: BTStatus) => {
        if (status != null) {
          dispatch({ type: 'bluetooth_state', payload: status });
        }
      });
    }
  };

  // Ejecutamos el listener para obtener el listado de dispositivos desde electron
  const enableDevicesListener = () => {
    window.bluetoothManagerAPI?.handleReciveDevices((_, device: BTDevice) => {
      const currentDev = getDeviceFromList(state, device.name);
      if (currentDev == null) {
        dispatch({ type: 'add_device', payload: { device } });
        // Si ya estaba conectado al sistema, debemos iniciar el nofify
        if (device.state == BluetoothDeviceState.connected) {
          addListenerDataDevice(device);
        }
      }
    });
  };

  // Ejecutamos el listener para obtener el cambio en los dispositivos desde electron
  const enableDeviceStatusListener = () => {
    if (window.bluetoothManagerAPI != null) {
      window.bluetoothManagerAPI?.handleReciveStatusDevices((_, device: BTDevice) => {
        if (device != null) {
          if (device.state == BluetoothDeviceState.connected) {
            const currentDev = getDeviceFromList(state, device.name);
            if (currentDev == null || currentDev?.state != BluetoothDeviceState.connected) {
              showToast('Dispositivo ' + device.name + ' conectado correctamente', 'success');
            }
          }
          dispatch({ type: 'change_status_device', payload: { device } });
          if (device.state == BluetoothDeviceState.connected && device.deviceType != 'unknown') {
            // con esto escuchamos el cambio de datos
            addListenerDataDevice(device);
          }
        }
      });
    }
  };
  // Activamos el listener para obtener los datos del dispositivo
  const addListenerDataDevice = (device: BTDevice) => {
    if (device.deviceType == BluetoothDeviceTypes.HeartRate) {
      window.bluetoothManagerAPI?.handleHeartRateData(device.id, (_, value: number) => {
        state.heartRateEmitter.emitHeartRate(value);
        dispatch({ type: 'update_heart_rate', payload: { device: device, heartRate: value } });
      });
    } else {
      window.bluetoothManagerAPI?.handleBikeData(device.id, (_, value: Map<string, any>) => {
        console.log('🚲 READ =>  device :' + device.name + ' data:', value);
        if (value.get(BikeDataFeatures.cadence) != null) {
          state.bikeDataEmitter.emitCadence(value.get(BikeDataFeatures.cadence));
        }

        if (value.get(BikeDataFeatures.power) != null) {
          state.bikeDataEmitter.emitPower(value.get(BikeDataFeatures.power));
        }
        if (value.get(BikeDataFeatures.resistance) != null) {
          state.bikeDataEmitter.emitResistance(value.get(BikeDataFeatures.resistance));
        }
        dispatch({ type: 'update_bike_data', payload: { device: device, bikeData: value } });
      });
      window.bluetoothManagerAPI?.handleButtonChange(device.id, (_, value: Map<string, any>) => {
        state.bikeDataEmitter.emitButtonChange(value);
      });
    }
  };

  const connectDevice = async (device: BTDevice) => {
    const currentDev = getDeviceFromList(state, device.name);
    // si ya lo hemos conectado antes
    if (state.typeBluetooth == 'mixed' && currentDev != null && currentDev.gatt != null) {
      connectDeviceChromium(currentDev.gatt.device);
    } else {
      if (window.bluetoothManagerAPI != null) {
        window.bluetoothManagerAPI?.connectDevice(device.id);
      }

      if (currentDev == null) {
        dispatch({ type: 'add_device', payload: { device } });
      }
    }
  };

  const getHeartRateList = () => {
    return getDevicesFromList(state, BluetoothDeviceTypes.HeartRate) || [];
  };

  const disconnectDevice = (device: BTDevice) => {
    if (state.typeBluetooth == 'mixed') {
      const currentDev = getDeviceFromList(state, device.name);
      if (currentDev != null && currentDev.gatt) {
        currentDev.gatt.disconnect();
      }
      window.bluetoothManagerAPI?.disconnectDevice(device.id);
    } else {
      window.bluetoothManagerAPI?.disconnectDevice(device.id);
    }
  };
  const cleanDevices = () => {
    state.bluetoothDevices.clear();
    // dispatch({ type: 'clean_devices' });
  };
  const startScan = () => {
    cleanDevices();
    window.bluetoothManagerAPI?.startScan();
  };

  const setPowerTarget = async (power: number) => {
    await window.bluetoothManagerAPI?.setPowerTarget(power);
  };
  const stopPowerTarget = async () => {
    await window.bluetoothManagerAPI?.stopPowerTarget();
  };
  const setResistanceTarget = async (resistance: number) => {
    console.log('ENVIAMOS A DESKTOP RESISTENCIA A CAMBIAR', resistance);
    await window.bluetoothManagerAPI?.setResistanceTarget(resistance);
  };

  const autoMode = async (enable: boolean) => {
    await window.bluetoothManagerAPI?.autoMode(enable);
  };

  const getFeatures = async () => {
    const simulated = [
      'Cadencia',
      'Distancia total',
      'Nivel de resistencia',
      'Tiempo restante',
      'Potencia',
      'Resistencia objetivo',
      'Modo automático',
      'Simulación de parámetros',
      'Tamaño de la rueda',
      'Spin down',
      'Control manual de botones',
    ];
    if (window.bluetoothManagerAPI != null) {
      const res = await window.bluetoothManagerAPI?.getFeatures();
      return res;
    }
    return [];
  };

  const getLevelRange = async () => {
    if (window.bluetoothManagerAPI != null) {
      const res = await window.bluetoothManagerAPI?.getLevelRange();
      return res;
    }
    return new Map();
  };

  const isAvailableBluetooth = () => {
    if (window.bluetoothManagerAPI != null) {
      return window.bluetoothManagerAPI?.isAvailableBluetooth();
    }
    return false;
  };

  // Obtenemos los dispositivos conectados al sistema

  const getConnectedDevices = (deviceType?: BluetoothDeviceTypes) => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    return values.filter(
      device =>
        device.state == BluetoothDeviceState.connected &&
        (deviceType == undefined || device.deviceType == deviceType)
    );
  };

  // Obtenemos los dispositivos del sistema
  const getBluetoothDevices = (deviceType?: BluetoothDeviceTypes) => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    return values.filter(device => deviceType == undefined || device.deviceType == deviceType);
  };

  const removeNotConnectedDevices = () => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    console.log('values', values);
    const namesToRemove = values
      .filter(dev => dev.state === BluetoothDeviceState.disconnected)
      .map(d => d.name);
    dispatch({ type: 'remove_devices', payload: { devicesNames: namesToRemove } });
    namesToRemove.forEach(name => window.bluetoothManagerAPI?.removeNotConnectedDevice(name));
  };
  // Obtenemos los dispositivos no conectados al sistema
  const getNoConnectedDevices = (deviceType?: BluetoothDeviceTypes) => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    return values.filter(
      device =>
        device.state != BluetoothDeviceState.connected &&
        (deviceType == undefined || device.deviceType == deviceType)
    );
  };

  const getAvaiableDevices = (deviceType?: BluetoothDeviceTypes) => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    return values.filter(
      device =>
        device.state != BluetoothDeviceState.unknown &&
        (deviceType == undefined || device.deviceType == deviceType)
    );
  };
  const hasNewApi = (): boolean => {
    return window.bluetoothManagerAPI != null;
  };

  const getFirstDeviceConnected = () => {
    const values = Array.from(state.bluetoothDevices.values()) || [];
    return values.find(device => device.state == BluetoothDeviceState.connected);
  };

  const isDeviceConnected = (dev: BTDevice) => {
    const values = Array.from(state.bluetoothDevices.values());
    const deviceToCheck = values.find(d => d.id === dev.id);
    return deviceToCheck?.state === BluetoothDeviceState.connected;
  };

  const isBluetoothConnected = () => {
    return state.status == BTStatus.poweredOn;
  };

  // Chromium updater

  const connectDeviceChromium = async (device: BluetoothDevice) => {
    //    device.gatt?.disconnect();
    clearTimeout(timeoutId);
    if (device.gatt?.connected) {
      return;
    }

    const callbackDisconnect = (server: BluetoothRemoteGATTServer) => {
      dispatch({ type: 'set_bluetooth_device_chromium', payload: { device, server } });
      window.bluetoothManagerAPI?.chromiumDeviceStatus(device.name!, 'disconnecting');
      timeoutId = setTimeout(() => {
        window.bluetoothManagerAPI?.chromiumDeviceStatus(device.name!, 'disconnected');
      }, 3000);
    };

    await window.bluetoothManagerAPI?.chromiumDeviceStatus(device.name!, 'connecting');
    device.gatt
      ?.connect()
      .then(async server => {
        if (!server) {
          showToast('Lo sentimos, este dispositivo no es compatible con la aplicación', 'error');
          return;
        }
        device.addEventListener('gattserverdisconnected', event => callbackDisconnect(server));

        // NO esta implementado en este momento en la api de chrome https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md
        device.addEventListener('advertisementreceived', event => {
          console.log('Advertisement received.', event);

          event.manufacturerData.forEach((valueDataView, key) => {
            console.log('Manufacturer', key, valueDataView);
          });
          event.serviceData.forEach((valueDataView, key) => {
            console.log('Service', key, valueDataView);
          });
        });

        dispatch({ type: 'set_bluetooth_device_chromium', payload: { device, server } });
        await window.bluetoothManagerAPI?.chromiumDeviceStatus(
          device.name!,
          server.connected ? 'connected' : 'disconnected'
        );
        device.gatt?.getPrimaryServices().then(services => {
          const uuids = services?.map(service => {
            return service.uuid;
          });
          if (uuids?.length && uuids?.length > 0) {
            const parseType = window.bluetoothManagerAPI?.discoverDeviceType(device.name!, uuids);
            if (parseType != undefined) {
              enableDataDevices(device, server, parseType, uuids);
            }
          }
        });
        // device.watchAdvertisements();
      })
      .catch(error => {
        console.error('Connection bluetooth ', error);
        window.bluetoothManagerAPI?.chromiumDeviceStatus(device.name!, 'disconnected');
      })
      .finally(() => {});
    // dispatch({ type: 'connect_device', payload: { device, server } });
    /* setHeartRateEnabled(true);
     */
  };

  const enableDataDevices = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer,
    parseType: BluetoothParserType,
    uuids: string[]
  ): void => {
    if (parseType == 'heartrate') {
      enableHeartRateListeners(device, server);
    } else if (parseType == 'ftms') {
      enableFtmsListeners(device, server, uuids);
    } else if (parseType == 'power') {
      enablePowerListeners(device, server);
    } else if (parseType == 'bhCustom') {
      enableBhCustomListeners(device, server);
      // Leemos las features para activar el start training
      getFeatures();
    }
    // TODO añadir los listerner de los tipos de dispositivos
  };

  const enablePowerListeners = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer
  ): void => {
    // Activamos la lectura de las features de la maquina
    listerReadDevice(
      device,
      server,
      GattSpecification.power.service,
      [GattSpecification.power.measurements.features],
      (uuid: string, value: Buffer | ArrayBuffer) => {
        window.bluetoothManagerAPI?.readDataFromBuffer(uuid, device.name!, value);
        // Activamos la lectura de los datos de la máquina
        listenerNotifyDevice(device, server, GattSpecification.power.service, [
          GattSpecification.power.measurements.bikeData,
        ]);
      }
    );
  };

  const enableHeartRateListeners = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer
  ): void => {
    listenerNotifyDevice(device, server, GattSpecification.heartRate.service, [
      GattSpecification.heartRate.measurements.heartRate,
    ]);
  };

  const enableBhCustomListeners = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer
  ): void => {
    // Activamos la escritura de los datos de la máquina
    listenerWriteData(
      device,
      server,
      GattSpecification.bhCustom.service,
      GattSpecification.bhCustom.measurements.tx
    );
    // Activamos la lectura de las features de la maquina
    listenerNotifyDevice(device, server, GattSpecification.bhCustom.service, [
      GattSpecification.bhCustom.measurements.rx,
    ]);
  };

  const enableFtmsListeners = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer,
    uuids: string[]
  ): void => {
    // Activamos la lectura de las features de la maquina
    listerReadDevice(
      device,
      server,
      GattSpecification.ftms.service,
      [GattSpecification.ftms.measurements.feature],
      (uuid: string, value: Buffer | ArrayBuffer) => {
        window.bluetoothManagerAPI?.readDataFromBuffer(uuid, device.name!, value);
        // Activamos la lectura de los datos de la máquina
        listenerNotifyDevice(device, server, GattSpecification.ftms.service, [
          GattSpecification.ftms.measurements.bikeData,
        ]);
      }
    );
    // leemos el nivel de resistencia
    listerReadDevice(
      device,
      server,
      GattSpecification.ftms.service,
      [GattSpecification.ftms.measurements.resitanceRange],
      (uuid: string, value: Buffer | ArrayBuffer) => {
        window.bluetoothManagerAPI?.readDataFromBuffer(uuid, device.name!, value);
        // Activamos la lectura de los datos de la máquina
      }
    );
    // Activamos la escritura de los datos de la máquina
    listenerWriteData(
      device,
      server,
      GattSpecification.ftms.service,
      GattSpecification.ftms.measurements.controlPoint
    );
    // Vemos si tiene servicio de botón de zycle
    if (uuids.find(e => e == GattSpecification.zycleButton.service)) {
      listenerNotifyDevice(device, server, GattSpecification.zycleButton.service, [
        GattSpecification.zycleButton.measurements.buttonControl,
      ]);
    }
  };

  const listerReadDevice = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer,
    service: string,
    characteristics: string[],
    callback: (uuid: string, value: Buffer | ArrayBuffer) => void
  ): void => {
    if (server == undefined) return;
    server.getPrimaryService(service).then(ser => {
      characteristics.forEach(characteristic => {
        ser.getCharacteristic(characteristic).then(async char => {
          const values = await char.readValue();
          callback(char.uuid, values.buffer);
        });
      });
    });
  };

  const listenerNotifyDevice = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer,
    service: string,
    characteristics: string[]
  ): void => {
    if (server == undefined) return;
    server.getPrimaryService(service).then(ser => {
      characteristics.forEach(characteristic => {
        ser.getCharacteristic(characteristic).then(char => {
          char.startNotifications().then(() => {
            char.addEventListener('characteristicvaluechanged', (event: any) => {
              const value = event.target.value;
              window.bluetoothManagerAPI?.readDataFromBuffer(char.uuid, device.name!, value.buffer);
            });
          });
        });
      });
    });
  };
  const listenerWriteData = (
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer,
    service: string,
    characteristic: string
  ): void => {
    window.bluetoothManagerAPI?.handleWriteData(device.name!, (_, values: Buffer) => {
      server
        .getPrimaryService(service)
        .then(ser => {
          return ser.getCharacteristic(characteristic);
        })
        .then(char => {
          const data = values;
          console.log('🚲 Write=>  service:' + service + ' char:', char, 'data:', data);
          return char.writeValue(data);
        })
        .then(() => {
          console.log('Write successful!');
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  const checkTypeBluetooth = (): typeBluetooth => {
    let type: typeBluetooth = 'unknown';
    const isAvailable = hasNewApi();
    const isChromium = !!window.chrome;
    const isAvailableNative = isAvailableBluetooth();

    // Utilizamos la api nueva pero no tiene soporte nativo
    if (isAvailable && !isAvailableNative && isChromium) {
      type = 'mixed';
    } else if (isAvailable && isAvailableNative) {
      // Api nueva con soporte nativo
      type = 'native';
    } else if (!isAvailable && isChromium) {
      // No tenemos la api nueva
      type = 'chromium';
    } else {
      type = 'no supported';
    }
    // TODO QUITAR
    // type = 'mixed';
    dispatch({ type: 'set_type_bluetooth', payload: { type: type } });
    return type;
  };

  const showBluetoothModalOff = () => setBluetoothModal(true);

  const hideBluetoothModalOff = () => setBluetoothModal(false);

  useEffect(() => {
    if (btRef.current) {
      btRef.current = false;
      // Comprobamos el tipo de conexión con el dispositivo bluetooth
      checkTypeBluetooth();
      if (window.bluetoothManagerAPI != null) {
        // Activamos listener de bluetooth
        enableBluetootStatusListener();
        // Activamos listener de dispositivos
        enableDevicesListener();
        // Activamos listener de estados  dispositivos
        enableDeviceStatusListener();

        // Forzamos estado actual de bluetooth
        window.bluetoothManagerAPI.syncStatus();
        // Lanzamos la sincronización de dispositivos
        window.bluetoothManagerAPI.syncDevices();

        window.bluetoothManagerAPI.enableAutoScan();
      }
    }
  }, []);

  return (
    <BTContext.Provider
      value={{
        state,
        actions: {
          hasNewApi,
          isAvailableBluetooth,
          startScan,
          getHeartRateList,
          getBluetoothDevices,
          connectDevice,
          disconnectDevice,
          getConnectedDevices,
          getNoConnectedDevices,
          getAvaiableDevices,
          getFeatures,
          getLevelRange,
          getFirstDeviceConnected,
          isDeviceConnected,
          isBluetoothConnected,
          showBluetoothModalOff,
          hideBluetoothModalOff,
          setPowerTarget,
          stopPowerTarget,
          setResistanceTarget,
          autoMode,
          connectDeviceChromium,
          getGattServices,
          removeNotConnectedDevices,
        },
        noBluetoothModal,
      }}
    >
      <ConnectingBikeModal />
      {children}
    </BTContext.Provider>
  );
};

export const useBTContext = () => useContext(BTContext);
