import Modal from '../Modal';
import Button from '../UI/Button';
import styles from './NoBluetoothModal.module.scss';

interface NoBlueetoothModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onRetry: () => void;
}

export default function NoBlueetoothModal({ isOpen, onCancel, onRetry }: NoBlueetoothModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal} onRequestClose={onCancel}>
      <h1 className={styles.title}> No hay conexión Bluetooth disponible en este dispositivo</h1>
      <p className={styles.description}>
        Comprueba que tu ordenador tiene conexión Bluetooth y esté activada y vuelve a intentarlo.
      </p>
      <footer className={styles.containerButtons}>
        <Button onClick={onRetry} size="small" className={styles.buttonKnowingFtp}>
          REINTENTAR
        </Button>
      </footer>
    </Modal>
  );
}
