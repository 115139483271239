import { useQuery } from 'jsonapi-react';
import { NavLink } from 'react-router-dom';
import Avatar from '../Avatar';
import { RoundedButton } from '../UI/Button';

import styles from './ProfileHeader.module.scss';
import { useState } from 'react';
import EditProfileModal from './EditProfileModal';
import InviteFriendModal from './InviteFriendModal';

const UserLevel = ({ userLevel, levels }) => {
  return <h2>{levels?.find(l => l.id == userLevel)?.name}</h2>;
};

export default function ProfileHeader({ user, profile }: { user: User; profile: Profile }) {
  const { data } = useQuery(['affiliation_levels']);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalFriend, setShowModalFriend] = useState(false);

  return (
    <>
      <EditProfileModal
        isOpen={showModalProfile}
        onRequestClose={() => setShowModalProfile(false)}
      />
      <InviteFriendModal
        isOpen={showModalFriend}
        onRequestClose={() => setShowModalFriend(false)}
      />
      <div className={styles.header}>
        <div className={styles.userInfo}>
          <aside>
            <Avatar fullName={user.full_name} url={user.avatar_url} size={200} />
          </aside>
          <div className={styles.info}>
            <h1>{user.full_name}</h1>
            <div>
              {profile.city && profile.country && (
                <p className={styles.location}>
                  {profile.city}, {profile.country}
                </p>
              )}
              <RoundedButton size="small" onClick={() => setShowModalProfile(true)}>
                EDITAR
              </RoundedButton>
            </div>
          </div>
        </div>

        <div className={styles.levelAffiliation}>
          <div>
            <strong>Nivel de socio</strong>
            <UserLevel userLevel={user.affiliation_data.level} levels={data} />
            <NavLink
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setShowModalFriend(true)}
              to={''}
            >
              <p className={styles.affiliation}>Invita a un amigo</p>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
