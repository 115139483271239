import dayjs from 'dayjs';
import LoadingIndicator from '../LoadingIndicator';

import ProfileGroupedTrainings from './ProfileGroupedTrainings';

import groupBy from '../../utils/groupBy';
import styles from './ProfileTrainings.module.scss';
import { useApi } from '../../api/ApiProvider';
import { useInfiniteQuery } from '@tanstack/react-query';
import InfiniteQueryList from '../InfiniteQueryList';
import { useFetchTrainings } from '@/core/hooks/apiHooks/useFetchTrainings';
import { tr } from 'date-fns/locale';

export default function ProfileTrainings() {
  const query = useFetchTrainings();
  const { trainings, isError, isLoading, isSuccess } = query;

  if (isError) {
    return (
      <div className={styles.noResults}>
        <p>No se ha podido obtener los entrenamientos realizados.</p>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isSuccess && !trainings.length) {
    return (
      <div className={styles.noResults}>
        <p>Todavía no has completado ningún entrenamiento.</p>
        <p>
          Cuando vayas completando entrenamientos podrás verlos aquí para llevar un control de tu
          progreso.
        </p>
      </div>
    );
  }

  const trainingsGroupedByDate = groupBy(trainings, i =>
    dayjs(i.training_date).format('YYYY/MM/DD'),
  );
  Object.keys(trainingsGroupedByDate).forEach(key => {
    trainingsGroupedByDate[key] = trainingsGroupedByDate[key].sort(
      (a, b) => dayjs(a.training_date).unix() - dayjs(b.training_date).unix(),
    );
  });

  return (
    <InfiniteQueryList {...query}>
      {Object.entries(trainingsGroupedByDate).map(([date, trainingsOnDate]) => (
        <ProfileGroupedTrainings key={date} date={date} trainings={trainingsOnDate} />
      ))}
    </InfiniteQueryList>
  );
}
