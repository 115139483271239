import { useRef } from 'react';
import Avatar from '../Avatar';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { RoundedButton } from '../UI/Button';
import styles from './AvatarUpload.module.scss';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../../core/api/ApiProvider';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760; // 10MB

interface AvatarUploadProps {
  user: User;
  maxFileSizeInBytes?: number;
}
export default function AvatarUpload({
  user,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
}: AvatarUploadProps) {
  const { mutateApi, client } = useApi();
  const fileInputField = useRef<HTMLInputElement>(null);

  const updateUser = useMutation(
    ad =>
      mutateApi(
        ['user'],
        { avatar_data: ad },
        {
          method: 'PATCH',
        },
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(['user']);
      },
      onError: error => {
        console.log(error);
      },
    },
  );

  const addNewFile = (newFiles: FileList) => {
    const file = newFiles[0];

    if (file && file.size <= maxFileSizeInBytes) {
      return file;
    }
    return null;
  };

  const fileToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSubmit = async (file: File) => {
    const base64Data = (await fileToBase64(file)) as void;

    updateUser.mutate(base64Data);
  };

  const handleButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (!fileInputField.current) return;
    fileInputField.current.click();
  };

  const handleFileUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = evt;
    const { files: newFiles } = target;

    if (!newFiles) return;

    if (newFiles.length) {
      const updatedFile = addNewFile(newFiles);

      if (updatedFile) {
        await onSubmit(updatedFile);
        target.value = '';
      }
    }
  };

  return (
    <section className={styles.avatarUpload}>
      <div className={styles.avatarContainer}>
        <div className={`${styles.avatar} ${updateUser.isLoading ? styles.loading : ''}`}>
          <Avatar fullName={user.full_name} url={user.avatar_url} size={175} />
        </div>
        {updateUser.isLoading && (
          <div className={styles.avatarLoading}>
            <LoadingIndicator />
          </div>
        )}
      </div>

      <div className={styles.avatarUploadButton}>
        <input type="file" ref={fileInputField} onChange={handleFileUpload} />
        <RoundedButton size="small" onClick={handleButtonClick} disabled={updateUser.isLoading}>
          {updateUser.isLoading ? 'Cambiando foto' : 'Cambiar Foto'}
        </RoundedButton>
      </div>
    </section>
  );
}
