import SearchContainer from './SearchContainer';

import Slider from '@/components/Slider';
import { NutritionKeys, RecomendedHeader } from '@/constants/nutrition';
import styles from './NutritionSearch.module.scss';

const defaultCategories = [
  { type: NutritionKeys.all, title: RecomendedHeader.all },
  { type: NutritionKeys.breakfast, title: RecomendedHeader.breakfast },
  { type: NutritionKeys.lunch, title: RecomendedHeader.lunch },
  { type: NutritionKeys.dinner, title: RecomendedHeader.dinner },
  { type: NutritionKeys.snack, title: RecomendedHeader.snack },
  { type: NutritionKeys.dessert, title: RecomendedHeader.dessert },
];

export default function NutritionCarousel() {
  return (
    <Slider dataCy="nutrition_categories_slider">
      {defaultCategories.map(category => (
        <SearchContainer
          key={category.type}
          cardClassName="card"
          type={category.type}
          className={styles.card}
          title={category.title}
        />
      ))}
    </Slider>
  );
}
