import CommentWithReplies from './CommentWithReplies';

// Uniq function. Error in api/v2/comments/index (forces include replies)
const uniq = (comment, index, self) => index === self.findIndex(c => c.id === comment.id);
const sortByCreatedAt = (a, b) => b.created_at - a.created_at;
const sortReplyCommentsByCreatedAt = (a, b) => a.created_at - b.created_at;

export default function CommentsList({ comments, isFreeTc }) {
  const rootComments = comments
    .filter(c => !c.parent_id)
    .filter(uniq)
    .sort(sortByCreatedAt);
  const replyComments = comments
    .filter(c => c.parent_id)
    .filter(uniq)
    .sort(sortReplyCommentsByCreatedAt);

  const commentsWithReplies = rootComments.map(comment => ({
    comment: comment,
    replies: replyComments.filter(c => c.parent_id == comment.id),
  }));

  if (!commentsWithReplies.length) {
    return <p style={{ color: '#666' }}>Todavía no hay comentarios.</p>;
  }

  return (
    <>
      {commentsWithReplies.map(commentWithReplies => {
        const { comment, replies } = commentWithReplies;
        return (
          <CommentWithReplies
            key={comment.id}
            comment={comment}
            replies={replies}
            isFreeTc={isFreeTc}
          />
        );
      })}
    </>
  );
}
