import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';

const cache: { [key: string]: any } = {};

const useFetch = <T,>(url: string) => {
  const [status, setStatus] = useState('idle');
  const [data, setData] = useState<T[]>([]);

  const {
    state: { accessToken },
  } = useAuth();
  const apiKey = accessToken;

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      if (cache[url]) {
        const cachedData = cache[url];
        setData(cachedData.data);
      } else {
        setStatus('fetching');
        try {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          });
          const res = await response.json();
          cache[url] = res; // set response in cache;
          setData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
      setStatus('fetched');
    };

    fetchData();
  }, [url]);

  return { status, data };
};

export default useFetch;
