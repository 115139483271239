import clsx from 'clsx';
import styles from './TrainingLevel.module.scss';

type TrainingLevelProps = {
  level: number;
  activeColor: 'white' | 'grey';
  size: 'small' | 'normal';
}

const TrainingLevel = ({ level, activeColor, size }: TrainingLevelProps) => {
  const trainingLevelClass = `level${level}`;

  const levelLabel = `Nivel ${Math.max(1, Math.min(3, level / 10))}`;

  return (
    <div
      className={clsx(styles.trainingLevel, styles[trainingLevelClass], styles[size], styles[activeColor])}
      aria-label={levelLabel}
    >
      <div className={styles.trainingLevelPoint}></div>
      <div className={styles.trainingLevelPoint}></div>
      <div className={styles.trainingLevelPoint}></div>
    </div>
  );
}

export default TrainingLevel;