import styles from './NotFound.module.scss';

export default function NotFound({ title, description, buttonText, onBackClick, disabled }) {
  return (
    <div className={styles.notFound}>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <button
          onClick={onBackClick}
          disabled={disabled}
          className={disabled ? styles.disabled : ''}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}
