import { Link } from 'react-router-dom';
import { useQuery } from 'jsonapi-react';
import styles from './Playlists.module';

const PlaylistBreadcrumbsComponent = ({ id, title }) => {
  const regex = RegExp(/^(Colección|Colecciones|LISTAS)\s+/);

  return (
    <Link to={`/playlists/${id}`}>
      <span>Colecciones {title.replace(regex, '')}</span>
    </Link>
  );
};

const PlaylistBreadcrumbsDefault = ({ id, categoryNr }) => {
  const isCyclingChild = categoryNr == 1 && id != 117;
  return (
    <>
      {isCyclingChild ? (
        <span className={styles.breadcrumbs}>
          <Link to={`/playlists/${117}`}>
            <span>{'Colecciones Bestcycling'}</span>
          </Link>
        </span>
      ) : (
        <span>{'Colecciones'}</span>
      )}
    </>
  );
};

export default function PlaylistBreadcrumbs({ playlist }) {
  if (!playlist || playlist.id === 128) return null;

  const { parent_id, parent_ids = [] } = playlist;

  if (!parent_id)
    return <PlaylistBreadcrumbsDefault id={playlist.id} categoryNr={playlist.category_nr} />;

  const { data, isLoading } = useQuery(parent_id && ['playlists', { filters: { id: parent_ids } }]);

  if (isLoading) return null;

  if (!data) return <PlaylistBreadcrumbsDefault />;

  const collections = data.sort((item1, item2) => {
    return parent_ids.indexOf(item1.id) - parent_ids.indexOf(item2.id);
  });

  return (
    <span className={styles.breadcrumbs}>
      {collections.map(collection => (
        <PlaylistBreadcrumbsComponent key={collection.id} {...collection} />
      ))}
    </span>
  );
}
