const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
import { v5 as uuidv5 } from 'uuid';

import dayjs from 'dayjs';

import { Category } from '../../constants/category';

const hashToParams = (hash: string) => {
  const rawParams = hash.replace(/#.*?&/, '');
  const params = JSON.parse(
    '{"' +
      decodeURI(rawParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
      '"}',
  );

  return params;
};

const getItemToSave = (): DataTraining | null => {
  const saveItem = localStorage.getItem('saveTraining');
  if (saveItem != null) {
    const annotateItem = JSON.parse(saveItem);
    let dataTraining: DataTraining = { trainingClass: annotateItem.trainingClass };

    if (annotateItem.trainingDate != null) dataTraining.trainingDate = annotateItem.trainingDate;
    if (annotateItem.annotation != null)
      dataTraining.annotation = JSON.stringify(annotateItem.annotation);

    if (annotateItem.hash != null) {
      const params: DataTraining =
        annotateItem.trainingClass.category === 51 ? null : hashToParams(annotateItem.hash);
      if (params != null) {
        dataTraining = { ...dataTraining, ...params };
      }
    }

    return dataTraining;
  }

  return null;
};

const deleteItemToSave = () => {
  localStorage.removeItem('saveTraining');
};
const setItemToSave = (data: DataTraining) => {
  localStorage.setItem('saveTraining', JSON.stringify(data));
};

const automaticAnnotate = (
  apiKey: string,
  user: IUser,
  profile: IProfile,
): TrainingToPost | null => {
  const dataItem = getItemToSave();
  if (
    dataItem != null &&
    dataItem.trainingClass != null &&
    dataItem.trainingClass.category_nr == Category.VideoProgress
  ) {
    const stringGuid = `${Date.now()}-${apiKey}-${user.id}-${profile.id}`;
    const guid = uuidv5(stringGuid, MY_NAMESPACE);
    const training = {
      guid: guid,
      training_class_id: dataItem.trainingClass.id,
      category_nr: dataItem.trainingClass.category_nr,
      delete: false,
      done: true,
      training_date: dataItem.trainingDate || dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
      kcal: null,
      seconds: dataItem.trainingClass.duration_seconds,
      mean_frequency: 0,
      short_title: dataItem.trainingClass.short_title,
      subtitle: dataItem.trainingClass.subtitle,
      trainer_name: dataItem.trainingClass.trainer?.full_name,
      trainer_id: dataItem.trainingClass.trainer?.id,
      trainer_rating: 5,
      training_class_rating: 5,
      difficulty_rating: 5,
    };
    if (dataItem.seconds != null) training.seconds = parseInt(dataItem.seconds, 10);
  }
  return null;
};

export { getItemToSave, deleteItemToSave, setItemToSave, hashToParams, automaticAnnotate };
