import Modal from '@/core/components/Modal/Modal';
import Button from '@/core/components/UI/Button';
import { useState } from 'react';
import { Cookies } from '../constants/cookies';

import styles from './CookiesConfigurationModal.module.scss';
import CookieSection from './CookieSection';

type CookiesConfigurationModalProps = {
  cookies: string[];
  isOpen: boolean;
  onClose(): void;
  onAccept(cookies: string[]): void;
  onDecline(): void;
};

/**
 * Button ids are used with tag manager for tracking
 *
 * @returns
 */
const CookiesConfigurationModal = (props: CookiesConfigurationModalProps) => {
  const [currentCookies, setCurrentCookies] = useState(props.cookies);

  const toggleCookie = (cookieKey: string) => {
    const _cookies = currentCookies.includes(cookieKey)
      ? currentCookies.filter(cookie => cookie !== cookieKey)
      : [...currentCookies, cookieKey];

    setCurrentCookies(_cookies);
  };

  const accept = () => {
    props.onAccept(currentCookies);
  };

  return (
    <Modal
      className={styles.container}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      id="cookies_configureModal"
    >
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <h4>PREFERENCIAS DE COOKIES</h4>
          <p>
            Para mejorar nuestro servicio web y hacer que tu experiencia sea lo más cómoda posible,
            utilizamos cookies que nos permiten almacenar información sobre tus preferencias y
            adaptar el funcionamiento de nuestro sitio web a tus intereses.
            <br />
            Ya que respetamos tu derecho a la privacidad, puedes configurar qué cookies nos permites
            utilizar. Haz clic en los encabezados de cada categoría para saber más y personalizar
            tus preferencias. Ten en cuenta que la exclusión de algunas de estas cookies puede
            afectar a tu experiencia de navegación.
          </p>
        </div>
        <div className={styles.content}>
          {Object.keys(Cookies.data).map((key, index) => {
            return (
              <CookieSection
                {...Cookies.data[key]}
                isFirst={index === 0}
                key={key}
                cookieKey={key}
                isActive={currentCookies.includes(key)}
                onToggle={() => toggleCookie(key)}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <Button id="COOKIE_DECLINE_BUTTON" onClick={props.onDecline} variant="terciary">
          RECHAZAR
        </Button>
        <Button id="COOKIE_SAVE_CONFIGURATION_BUTTON" onClick={accept} variant="primary">
          GUARDAR
        </Button>
      </div>
    </Modal>
  );
};

export default CookiesConfigurationModal;
