import clsx from 'clsx';

import { Footer } from '../Footer';

import styles from './Page.module.scss';

interface PageProps {
  header?: JSX.Element;
  footer?: JSX.Element;
  children: React.ReactNode;
  className?: string;
  nopadding?: boolean;
}
export default function Page({
  header = <></>,
  footer = <Footer />,
  nopadding = false,
  className = '',
  children,
}: PageProps) {
  const classes = clsx(styles.container, [
    nopadding && styles.nopadding,
    className && styles[className],
  ]);
  return (
    <div className={classes}>
      {header}
      <div className={styles.inner}>{children}</div>
      {footer}
    </div>
  );
}
