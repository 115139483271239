import { NavLink, To } from 'react-router-dom';
import styles from './Button.module.scss';

export type ButtonSizes = 'small' | 'medium' | 'large' | 'normal' | 'default';

export type ButtonProps = {
  to?: To;
  onClick?: (event: React.MouseEvent) => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'danger' | 'terciary' | 'textbutton' | 'transparent';
  size?: ButtonSizes;
  [key: string]: any;
};

export default function Button({
  to,
  onClick,
  variant = 'primary',
  size = 'default',
  leftIcon,
  rightIcon,
  children,
  variantLeftIcon,
  className,
  ...props
}: ButtonProps) {
  const classNames = `${className} ${styles.button} ${variant ? styles[variant] : ''} ${
    size ? styles[size] : ''
  }`;

  const classNameLeftIcon = `${styles.leftIcon} ${variantLeftIcon ? styles[variantLeftIcon] : ''}`;

  const content =
    leftIcon || rightIcon ? (
      <div className={styles.content}>
        {' '}
        {leftIcon && <div className={classNameLeftIcon}>{leftIcon}</div>}
        {children}
        {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
      </div>
    ) : (
      children
    );

  if (to) {
    return (
      <NavLink to={to} className={classNames} {...props}>
        {content}
      </NavLink>
    );
  }

  return (
    <button className={classNames} onClick={onClick} {...props} type={props.type ?? 'button'}>
      {content}
    </button>
  );
}
