import { useEffect } from 'react';
import { useUser } from '../contexts/app.context';
import useSetting from '@/components/Preferences/useSetting';
import { useAuth } from '../contexts/auth.context';

export const useElectronMenuBar = () => {
  if (!window.electronAPI) return;
  const { state: authState } = useAuth();
  const { state: user } = useUser();
  const [defaultRoom] = useSetting<number>('default_room');

  useEffect(() => {
    window.electronAPI?.setMenuBar?.(generateTemplate({ user, defaultGymRoom: defaultRoom, accessToken: authState?.accessToken }));

    return () => {
      window.electronAPI?.setMenuBar?.(generateTemplate({ user: undefined }));
    };
  }, [user, defaultRoom]);
};

const generateTemplate = ({
  user,
  defaultGymRoom,
  accessToken,
}: {
  user?: User;
  defaultGymRoom?: number;
  accessToken?: string;
}): MenuElectron => [
    {
      label: 'Bestcycling',
      submenu: [
        { role: 'about' },
        { type: 'separator' },
        {
          type: 'normal',
          enabled: false,
          label: `Versión: ${window.electronAPI?.appVersion ?? `?.?.?`}`,
        },
        {
          type: 'normal',
          label: 'Buscar actualizaciones...',
          customAction: 'checkForUpdates',
          visible: !!user,
        },
        { type: 'separator' },
        {
          type: 'normal',
          label: 'Preferencias',
          enabled: !!user,
          loadUrl: '/settings',
        },
        { type: 'separator' },
        { visible: user?.tag_list.includes('preview') ?? false, role: 'toggleDevTools' },
        { visible: user?.tag_list.includes('preview') ?? false, type: 'separator' },
        { role: 'quit' },
      ],
    },
    {
      label: 'Editar',
      submenu: [
        { label: 'Rehacer', role: 'redo' },
        { label: 'Deshacer', role: 'undo' },
        { type: 'separator' },
        { label: 'Cortar', role: 'cut' },
        { label: 'Copiar', role: 'copy' },
        { label: 'Pegar', role: 'paste' },
        { label: 'Borrar', role: 'delete' },
        { label: 'Seleccionar todo', role: 'selectAll' },
        { type: 'separator' },
        {
          type: 'submenu',
          label: 'Dictado por voz',
          submenu: [
            { label: 'Iniciar dictado', role: 'startSpeaking' },
            { label: 'Detener dictado', role: 'stopSpeaking' },
          ],
        },
      ],
    },
    {
      visible: !!user,
      label: 'Ir a',
      submenu: [
        {
          type: 'normal',
          label: 'Explorar',
          loadUrl: '/',
        },
        {
          type: 'normal',
          label: 'Buscar clases',
          loadUrl: '/trainingclasses/search',
        },
        {
          type: 'normal',
          label: 'Colecciones',
          loadUrl: '/playlists',
        },
        {
          type: 'normal',
          label: 'Clases favoritas',
          loadUrl: '/trainingclasses/favourites',
        },
        {
          type: 'normal',
          label: 'Clases offline',
          loadUrl: '/offline',
        },
        {
          type: 'normal',
          label: 'Nutrición',
          loadUrl: '/nutrition',
        },
        {
          type: 'normal',
          label: 'Sensei',
          loadUrl: '/sensei',
        },
        {
          type: 'normal',
          label: 'Social',
          loadUrl: '/notifications',
        },
        {
          type: 'normal',
          label: 'Perfil',
          loadUrl: '/profile',
        },
      ],
    },

    {
      visible: !!user,
      label: 'Herramientas',
      submenu: [
        {
          type: 'normal',
          label: 'Mis clases',
          loadUrl: '/bestpro/home',
        },
        {
          visible: user?.current_membership.plan === 'gimnasios',
          type: 'normal',
          label: 'Planificador',
          loadUrl: `/gyms/rooms/${defaultGymRoom}`,
        },
        {
          type: 'normal',
          label: 'Constructor de clases',
          loadUrl: '/bestpro/constructor',
        },
        {
          type: 'normal',
          label: 'Mezclador de clases',
          loadUrl: '/bestpro/express',
        },
      ],
    },
    {
      visible: !!user,
      label: 'Mi cuenta',
      submenu: [
        { type: 'normal', label: user?.full_name, enabled: false },
        {
          type: 'normal',
          label: 'Mis datos',
          openExternal: 'https://www.bestcycling.com/user',
        },
        {
          type: 'normal',
          label: 'Mis suscripción',
          loadUrl: '/subscription',
        },
        { type: 'separator' },
        {
          type: 'normal',
          label: 'Cerrar sesión',
          customAction: 'logout',
        },
      ],
    },
    {
      label: 'Ventana',
      submenu: [
        { label: 'Minimizar', role: 'minimize' },
        { label: 'Cerrar', role: 'close' },
        { type: 'separator' },
        { label: 'Traer al frente', role: 'front' },
        { label: 'Pantalla completa', role: 'togglefullscreen' },
      ],
    },
    {
      label: 'Ayuda',
      submenu: [
        {
          type: 'normal',
          label: 'Reporta un error',
          customAction: 'reportError',
        },
        {
          type: 'normal',
          label: 'Sugerencias',
          openExternal: `https://community.bestcycling.com/direct_auth?&redirect_to=categories/11/topics&access_token=${accessToken}`,
        },
        {
          type: 'normal',
          label: 'Contacta con nosotros',
          openExternal: `https://bestcycling.com/direct_auth?access_token=${accessToken}&redirect_to=contacto`,
        },
      ],
    },
  ];

type MenuItemElectron = {
  label?: string;
  submenu?: MenuItemElectron[];
  enabled?: boolean;
  visible?: boolean;
  role?:
  | 'undo'
  | 'redo'
  | 'cut'
  | 'copy'
  | 'paste'
  | 'pasteAndMatchStyle'
  | 'delete'
  | 'selectAll'
  | 'reload'
  | 'forceReload'
  | 'toggleDevTools'
  | 'resetZoom'
  | 'zoomIn'
  | 'zoomOut'
  | 'toggleSpellChecker'
  | 'togglefullscreen'
  | 'window'
  | 'minimize'
  | 'close'
  | 'help'
  | 'about'
  | 'services'
  | 'hide'
  | 'hideOthers'
  | 'unhide'
  | 'quit'
  | 'showSubstitutions'
  | 'toggleSmartQuotes'
  | 'toggleSmartDashes'
  | 'toggleTextReplacement'
  | 'startSpeaking'
  | 'stopSpeaking'
  | 'zoom'
  | 'front'
  | 'appMenu'
  | 'fileMenu'
  | 'editMenu'
  | 'viewMenu'
  | 'shareMenu'
  | 'recentDocuments'
  | 'toggleTabBar'
  | 'selectNextTab'
  | 'selectPreviousTab'
  | 'mergeAllWindows'
  | 'clearRecentDocuments'
  | 'moveTabToNewWindow'
  | 'windowMenu';
  type?: 'normal' | 'separator' | 'submenu' | 'checkbox' | 'radio';
  loadUrl?: `/${string}`;
  openExternal?: string;
  customAction?: 'logout' | 'reportError' | 'checkForUpdates';
};

type MenuElectron = MenuItemElectron[];
