import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import pluralize from '../../utils/pluralize';

import LoadingIndicator from '../LoadingIndicator';
import TrainingClassShareList from './TrainingClassShareList';

export interface TrainingClassSharesTestProps {
  trainingClass: TrainingClass;
  divider: JSX.Element;
}

const TrainingClassShares = ({ trainingClass, divider }: TrainingClassSharesTestProps) => {
  const { fetchApi } = useApi();

  const { data, isLoading, isSuccess } = useQuery(['sharing_requests', trainingClass.id], () =>
    fetchApi([
      'sharing_requests',
      {
        filter: { user_id: trainingClass?.trainer?.id, training_class_id: trainingClass.id },
        include: ['target'],
      },
    ]),
  );

  const buildContent = () => {
    if (isLoading) return <LoadingIndicator />;
    if (!isSuccess) return null;

    return <TrainingClassShareList shares={data?.data} />;
  };

  return (
    <>
      {data?.data?.length ? (
        <>
          <h2>
            Has compartido esta clase con{' '}
            {pluralize(data?.data ? data?.data?.length : 0, 'persona', 'personas')}
          </h2>
          {divider}
        </>
      ) : null}
      {buildContent()}
    </>
  );
};

export default TrainingClassShares;
