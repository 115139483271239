import { FTP_ATTRIBUTES } from '@/constants/ftpAttributes';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../../core/api/ApiProvider';
import { useState } from 'react';
import Modal from '../Modal';
import Button from '../UI/Button';
import ConfigFtpModal from './ConfigFtpModal';
import ConfigFtpRow from './ConfigFtpRow';
import styles from './ConnectingBikeModal.module.scss';
import { usePreferences } from '@/contexts/app.context';
import { FIT_STATUS, SEX_PROFILES } from '@/constants/profileAttributes';

interface NotKnowedFtpModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onBack: () => void;
  onConfirm: () => void;
}

export default function NotKnowedFtpModal({
  isOpen,
  onCancel,
  onBack,
  onConfirm,
}: NotKnowedFtpModalProps) {
  const [attributes, setAttributes] = useState({
    gender: 'm',
    weight: '-',
    fitStatus: '1',
  });
  const { mutateApi, client } = useApi();
  const { gender, weight, fitStatus } = attributes;

  const [isOpenFtpModal, setIsOpenFtpModal] = useState(false);
  const [attributeOpen, setAttributeOpen] = useState('');
  const { state: preferences } = usePreferences();

  const saveUser = useMutation(
    (data: any) =>
      mutateApi(['user'], data, {
        method: 'PATCH',
      }),
    {
      onSuccess: () => client.invalidateQueries(['user']),
    }
  );

  const saveProfile = useMutation(
    (data: any) =>
      mutateApi(['profile'], data, {
        method: 'PATCH',
      }),
    {
      onSuccess: () => client.invalidateQueries(['profile']),
    }
  );

  const onSubmit = async (data: any) => {
    await saveProfile.mutateAsync({ weight: data.weight, fit_status: data.fitStatus });
    await saveUser.mutateAsync({ sex: data.gender });
    saveProfile.mutate({ ftp: preferences.getFtp() });
    onConfirm();
  };

  return (
    <>
      <Modal isOpen={isOpen} className={styles.modalNotFtp} onRequestClose={onCancel}>
        <h1 className={styles.title}> NO TE PREOCUPES, TENEMOS UNA SOLUCIÓN </h1>
        <p className={styles.description}>
          Dado que no conoces tu FTP, es necesario que completes los siguientes datos sobre tu sexo,
          peso y estado de forma. Con ellos, realizaremos el cálculo aproximado de tus zonas de
          entrenamiento y podrás empezar a disfrutar de un entrenamiento totalmente óptimo con tu
          bicicleta. <br /> <br /> Estos datos podrás modificarlos en cualquier momento desde
          Preferencias.
        </p>
        <span className={styles.divider} />
        <ConfigFtpRow
          label="Sexo"
          value={SEX_PROFILES[gender]}
          onClick={() => {
            setAttributeOpen(FTP_ATTRIBUTES.GENDER);
            setIsOpenFtpModal(true);
          }}
        />
        <span className={styles.divider} />
        <ConfigFtpRow
          label="Peso"
          value={weight}
          onClick={() => {
            setAttributeOpen(FTP_ATTRIBUTES.WEIGHT);
            setIsOpenFtpModal(true);
          }}
        />
        <span className={styles.divider} />
        <ConfigFtpRow
          label="Estado de forma"
          value={FIT_STATUS[fitStatus]}
          onClick={() => {
            setAttributeOpen(FTP_ATTRIBUTES.FITNESS);
            setIsOpenFtpModal(true);
          }}
        />
        <span className={styles.divider} />
        <footer>
          <Button
            onClick={onBack}
            size="small"
            className={styles.button}
            variant="terciary"
            style={{ width: '50%' }}
          >
            VOLVER
          </Button>
          <Button
            onClick={() => onSubmit(attributes)}
            size="small"
            className={styles.button}
            style={{ width: '50%' }}
            disabled={weight == '-'}
          >
            CONTINUAR
          </Button>
        </footer>
      </Modal>
      {isOpenFtpModal && (
        <>
          {attributeOpen === FTP_ATTRIBUTES.GENDER && (
            <ConfigFtpModal
              isOpen={isOpenFtpModal}
              onCancel={() => setIsOpenFtpModal(false)}
              onBack={() => setIsOpenFtpModal(false)}
              onConfirm={data => {
                setAttributes({ ...attributes, gender: data });
                setIsOpenFtpModal(false);
              }}
              gender={gender}
              attributeOpen={attributeOpen}
            />
          )}
          {attributeOpen === FTP_ATTRIBUTES.WEIGHT && (
            <ConfigFtpModal
              isOpen={isOpenFtpModal}
              onCancel={() => setIsOpenFtpModal(false)}
              onBack={() => setIsOpenFtpModal(false)}
              onConfirm={data => {
                setAttributes({ ...attributes, weight: data });
                setIsOpenFtpModal(false);
              }}
              weight={weight}
              attributeOpen={attributeOpen}
            />
          )}
          {attributeOpen === FTP_ATTRIBUTES.FITNESS && (
            <ConfigFtpModal
              isOpen={isOpenFtpModal}
              onCancel={() => setIsOpenFtpModal(false)}
              onBack={() => {
                setIsOpenFtpModal(false);
              }}
              onConfirm={data => {
                setAttributes({ ...attributes, fitStatus: data });
                setIsOpenFtpModal(false);
              }}
              fitness={fitStatus}
              attributeOpen={attributeOpen}
            />
          )}
        </>
      )}
    </>
  );
}
