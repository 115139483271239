import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { BluetoothDeviceState } from '@/constants/bluetooth';
import Category from '@/constants/category';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { useShell } from '@/contexts/shell.context';
import useDocumentTitle from '@rehooks/document-title';
import { GraphType } from '@/core/constants/graphFactory';
import { useAppContext, usePreferences } from '../../contexts/app.context';
import KnowingFtpModal from '../Bluetooth/KnowingFtpModal';
import NoBlueetoothModal from '../Bluetooth/NoBluetoothModal';
import CommentForm from '../Comments/CommentForm';
import FavouriteButton from '../FavouriteButton';
import TrainingClassGenresList from '../Genres/TrainingClassGenresList';
import LifePoint from '../LifePoint/LifePoint';
import MyCollectionButton from '../MyCollectionButton/MyCollectionButton';
import SubscribeModal from '../SubscribeModal';
import TrainingClassTagsList from '../TagsList/TrainingClassTagsList';
import TrainingClassShares from '../TrainingClassShares/TrainingClassShares';
import MyTrainingClassOptions from '../TrainingClasses/MyTrainingClassOptions';
import { formatDuration, formatPublishedAt } from '../TrainingClasses/utils';
import TrainingLevel from '../TrainingLevel';
import AnnotateButton from './AnnotateButton';
import BluetoothDevicesModal from './BluetoothDevicesModal';
import Comments from './Comments';
import DownloadAudiovisual from './DownloadAudiovisual';
import DownloadButton from './DownloadButton';
import GraphImage from './GraphImage';
import GraphImages from './GraphImages';
import PlayModal from './PlayModal';
import styles from './TrainingClassDetails.module.scss';
import MyTrainingClassShareButton from './MyTrainingClassShareButton';
import ShareButton from '../ShareButton';
import ShareTrainingClassModel from '@/core/models/ShareTrainingClassModel';
import useUpdateTrainingClassLocal from './hooks/useUpdateTrainingClassLocal';
import { useDownload } from '@/core/contexts/downloads.context';
import ToggeableContent from './ToggeableContent';
import { CommentableType } from '@/core/constants/comments';
import TrainingClassMoreInfo from '../TrainingClassMoreInfo/TrainingClassMoreInfo';
import RelatedTrainingClassesList from '../TrainingClasses/RelatedTrainingClassesList/RelatedTrainingClassesList';
import Trainings from './Trainings';
import TrainingSections from './TrainingSections';
import htmlUtils from '@/core/utils/htmlUtils';

interface TrainingClassDetailsProps {
  trainingClass: TrainingClass;
}

const ImageCover = ({ trainingClass }: TrainingClassDetailsProps) => {
  const { state: preferences } = usePreferences();

  const cover = { l: trainingClass.cover, xl: trainingClass.cover_xl };

  const getGraph = () => {
    if (preferences.graph_type == GraphType.Watts)
      return trainingClass.graph_watts ? trainingClass.graph_watts : trainingClass.graph;

    return trainingClass.has_graph
      ? trainingClass.graph
      : trainingClass.graph_watts
        ? trainingClass.graph_watts
        : trainingClass.graph;
  };

  if (!trainingClass.official && trainingClass.category_nr === 1) {
    return <GraphImage graph={getGraph() || ''} title={trainingClass.title} />;
  }

  return (
    <img
      className={styles.img}
      srcSet={`
              ${cover.l} 720w,
              ${cover.xl} 1280w
            `}
      sizes="
              (max-width: 768px) 360px,
              100vw
            "
      src={trainingClass.cover!}
      alt={trainingClass.title}
    />
  );
};

interface Props {
  trainingClass: TrainingClass;
  hideMedia: boolean;
  hasMediaClass?: boolean;
  isRetriesMediaFailed?: boolean;
}

const TrainingClassDetails = ({
  trainingClass,
  hideMedia,
  hasMediaClass,
  isRetriesMediaFailed,
}: Props) => {
  const title = useMemo(() => {
    if (trainingClass.official) {
      return [trainingClass.subtitle, trainingClass.short_title].filter(Boolean).join(' / ');
    }

    return trainingClass.title;
  }, [trainingClass.title]);

  useDocumentTitle(`${title} | Bestcycling`);

  const {
    state: { user },
  } = useAppContext();
  const { state: shell } = useShell();
  const { state, actions, noBluetoothModal } = useBTContext();

  const download = useDownload(trainingClass.id);
  useUpdateTrainingClassLocal(trainingClass, download?.trainingClass);

  const [isPlayModalOpen, setPlayModalOpen] = useState(false);
  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [isOpenBluetoothModal, setIsOpenBluetoothModal] = useState<boolean>(false);
  const [isOpenKnowingFtp, setIsOpenKnowingFtp] = useState<boolean>(false);
  const [deviceSelectedChromium, setDeviceSelectedChromium] = useState<
    BluetoothDevice | undefined
  >();
  const openPlayModal = () => setPlayModalOpen(true);
  const closePlayModal = () => setPlayModalOpen(false);

  const openSubscribeModal = () => setSubscribeModalOpen(true);
  const closeSubscribeModal = () => setSubscribeModalOpen(false);

  const isMine = !trainingClass.official && trainingClass.trainer_id == user?.id;

  const isSharingUser = trainingClass.sharing_user && !trainingClass.official;
  const trainingClassTrainerName = isSharingUser
    ? trainingClass.sharing_user?.full_name
    : trainingClass.trainer?.full_name;

  const onClickDevices = () => {
    if (!user?.isSubscribed()) {
      openSubscribeModal();
      closePlayModal();
      return;
    }
    if (!actions.isBluetoothConnected() && state.typeBluetooth != 'mixed') {
      actions.showBluetoothModalOff();
      return;
    }
    onRetry();
    closePlayModal();
  };

  const onRetry = () => {
    if (state.typeBluetooth == 'native') {
      if (!actions.isBluetoothConnected()) return;
      actions.hideBluetoothModalOff();
      actions.startScan();
    } else if (state.typeBluetooth == 'mixed') {
      actions.hideBluetoothModalOff();
      const gattServices = actions.getGattServices();
      navigator.bluetooth
        .requestDevice({ filters: gattServices })
        .then(device => {
          // si intentamos conectar 2 dispositivos en el mismo request revienta, asi que cerramos para evitar eso
          console.log('SELECTED DEVICE ', device);
          try {
            actions.connectDeviceChromium(device);
            setDeviceSelectedChromium(device);
          } catch (e) {
            console.error('requestDevice ', e);
          }
        })
        .catch(e => {
          console.error('navigator.bluetooth ', e);
          actions.removeNotConnectedDevices();
        });
    }
    setIsOpenBluetoothModal(true);
  };

  const onConfirmSelection = (dev: BTDevice) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (dev.state === BluetoothDeviceState.disconnected) {
      actions.connectDevice(dev);
    } else if (
      dev.state === BluetoothDeviceState.connected ||
      dev.state === BluetoothDeviceState.connecting
    ) {
      actions.disconnectDevice(dev);
    }
  };

  const handlePlay = () => {
    if (user && user.canPlay(trainingClass)) {
      openPlayModal();
    } else {
      openSubscribeModal();
    }
  };

  const hasAudiovisual = () => {
    if (trainingClass.extra_media != null && trainingClass.extra_media.length > 0) {
      if (trainingClass?.extra_media.find(m => m.type == 'video_extra') != null) {
        return true;
      }
    }
    return false;
  };

  const materialInfo = useMemo(() => {
    if (trainingClass.materials_info != null && trainingClass.materials_info.length > 0) {
      return trainingClass.materials_info;
    }
    if (trainingClass.training_materials?.length) {
      return `Se necesita: ${trainingClass.training_materials.map(m => m.name).join(', ')}`;
    }
    return 'No se necesita material';
  }, [trainingClass.materials_info, trainingClass.training_materials]);

  const trainingType = useMemo(() => {
    const { training_type, secondary_training_type } = trainingClass;

    return [training_type, secondary_training_type].filter(t => t).join(' - ');
  }, [trainingClass.training_type, trainingClass.secondary_training_type]);

  const publishedAt = useMemo(
    () => formatPublishedAt(trainingClass.published_at),
    [trainingClass.published_at],
  );

  const duration = useMemo(
    () =>
      formatDuration(
        trainingClass.official ? trainingClass.duration_training : trainingClass.duration_seconds,
      ),
    [trainingClass.duration_training, trainingClass.duration_seconds],
  );

  useEffect(() => {
    if (deviceSelectedChromium?.gatt?.connected) {
      setIsOpenBluetoothModal(false);
      openPlayModal();
      setDeviceSelectedChromium(undefined);
      actions.removeNotConnectedDevices();
    }
  }, [deviceSelectedChromium?.gatt?.connected]);

  return (
    <div data-cy="training_class_details" className={styles.trainingClassDetails}>
      <header>
        <div className={styles.avatar}>
          <img src={trainingClass.trainer?.avatar_url} alt={trainingClass.trainer?.full_name} />
        </div>
        <div className={styles.title}>
          <h2>{title}</h2>
          <div className={styles.containerTrainerName}>
            <Link to={`/trainingclasses/search?trainer_id=${trainingClass.trainer_id}`}>
              <h3 className={styles.underlineOnHover}>{trainingClassTrainerName}</h3>
            </Link>
          </div>
        </div>
        <div className={styles.options}>
          <FavouriteButton id={trainingClass.id} type="TrainingClass" />
          <DownloadButton trainingClass={trainingClass} hasMediaClass={hasMediaClass} />
          <AnnotateButton trainingClass={trainingClass} />
          <MyCollectionButton trainingClass={trainingClass} />
          {isMine ? (
            <MyTrainingClassShareButton trainingClass={trainingClass} />
          ) : (
            <ShareButton model={ShareTrainingClassModel(trainingClass, user?.full_name || '')} />
          )}
          {hasAudiovisual() && (
            <DownloadAudiovisual trainingClass={trainingClass}></DownloadAudiovisual>
          )}
        </div>
      </header>
      <div className={styles.image}>
        <div className={styles.cover}>
          <ImageCover trainingClass={trainingClass} />
        </div>
        <div className={styles.graphs}>
          <GraphImages trainingClass={trainingClass} />
        </div>
        {trainingClass.official && (
          <div className={styles.points}>
            <LifePoint type="resistance" value={trainingClass.life_resistance_points} />
            <LifePoint type="force" value={trainingClass.life_force_points} />
            <LifePoint type="flexibility" value={trainingClass.life_flexibility_points} />
            <LifePoint type="mind" value={trainingClass.life_mind_points} />
          </div>
        )}
        {!hideMedia && (
          <div className={styles.play}>
            <button aria-label="Reproducir video" onClick={() => handlePlay()}>
              <i className="fa fa-play"></i>
            </button>
          </div>
        )}
      </div>
      {isMine && <MyTrainingClassOptions trainingClass={trainingClass} />}
      <div className={styles.meta}>
        {!isMine && trainingClass.category_nr !== 21 && (
          <div className={styles.metaSection}>
            <div className={styles.trainingLevel}>
              Nivel
              <div className={styles.trainingLevelPoints}>
                <TrainingLevel level={trainingClass.level_nr} size="normal" activeColor="grey" />
              </div>
            </div>
          </div>
        )}
        <div className={isMine ? styles.metaSectionMine : styles.metaSection}>{publishedAt}</div>
        <div className={isMine ? styles.metaSectionMine : styles.metaSection}>
          <strong>Duración:</strong> {duration}&apos;
        </div>
        {!isMine && (
          <div className={styles.metaSection}>
            <div className={styles.trainingType}>{trainingType}</div>
            <div className={styles.material}>{materialInfo}</div>
          </div>
        )}
      </div>
      {trainingClass && (
        <>
          <PlayModal
            trainingClass={trainingClass}
            isOpen={isPlayModalOpen}
            onClose={closePlayModal}
            hasMediaClass={hasMediaClass}
            isRetriesMediaFailed={isRetriesMediaFailed}
            onClickDevices={onClickDevices}
            onKnowingFtp={() => {
              setIsOpenKnowingFtp(true);
              closePlayModal();
            }}
          />
          {shell.desktop && (
            <>
              {isOpenBluetoothModal && (
                <BluetoothDevicesModal
                  isOpen={isOpenBluetoothModal}
                  btDevicesList={actions.getBluetoothDevices()}
                  selectDevice={d => onConfirmSelection(d)}
                  onCancel={() => {
                    openPlayModal();
                    setIsOpenBluetoothModal(false);
                  }}
                />
              )}
              {noBluetoothModal && (
                <NoBlueetoothModal
                  isOpen={noBluetoothModal}
                  onCancel={() => actions.hideBluetoothModalOff()}
                  onRetry={onRetry}
                />
              )}
              {isOpenKnowingFtp && (
                <KnowingFtpModal
                  isOpen={isOpenKnowingFtp}
                  onCancel={() => {
                    setIsOpenKnowingFtp(false);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <SubscribeModal
        isOpen={isSubscribeModalOpen}
        onClose={closeSubscribeModal}
        onSubscribe={() => {
          closeSubscribeModal();
          openPlayModal();
        }}
        user={user}
      />

      {trainingClass.training_genres?.length > 0 && (
        <TrainingClassGenresList training_genres={trainingClass.training_genres} />
      )}

      {trainingClass.content && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: htmlUtils.format(trainingClass.content),
          }}
        ></div>
      )}

      {trainingClass.user_tags?.length > 0 && (
        <div className={styles.tags}>
          <span className={styles.tagsText}>Colecciones:</span>
          <TrainingClassTagsList tagList={trainingClass.user_tags} />
        </div>
      )}

      {trainingClass.category_nr == Category.Training && (
        <TrainingSections trainingClass={trainingClass} />
      )}

      {trainingClass.training_class_info && trainingClass.category_nr == Category.Cycling && (
        <section>
          <ToggeableContent header="Entrenamiento" canToggle>
            <TrainingClassMoreInfo trainingClass={trainingClass} />
          </ToggeableContent>
        </section>
      )}

      <div className={styles.commentsAndTrainings}>
        {trainingClass.official && (
          <section className={styles.comments}>
            <ToggeableContent header="Deja un comentario" show>
              <div className={styles.form}>
                <CommentForm
                  user={user!}
                  commentableId={trainingClass.id}
                  commentableType={CommentableType.TrainingClass}
                  isFree={trainingClass.free}
                />
              </div>
              <h3>{trainingClass.comments_count} Comentarios</h3>
              <div className={styles.lineDivider}></div>
              <Comments trainingClass={trainingClass} />
            </ToggeableContent>
          </section>
        )}

        {isMine && (
          <section className={styles.shares}>
            <TrainingClassShares
              trainingClass={trainingClass}
              divider={<div className={styles.lineDivider}></div>}
            />
          </section>
        )}

        <div className={styles.asideContent}>
          <section className={styles.trainings}>
            <Trainings trainingClass={trainingClass} />
          </section>

          {trainingClass.official && (
            <section className={styles.relatedTrainingClasses}>
              <RelatedTrainingClassesList id={trainingClass.id} />
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainingClassDetails;
