import clsx from 'clsx';
import style from './ProgressBar.module.scss';

type ProgressBarProps = {
  percent: string | number;
  color?: string;
  text?: string;
  textColor?: string;
  showPercent?: boolean;
  striped?: boolean;
  styles?: React.CSSProperties;
};

export default function ProgressBar({
  percent,
  color = 'transparent',
  textColor = 'white',
  text = undefined,
  showPercent = false,
  striped = false,
  styles = {},
}: ProgressBarProps) {
  const classNames = clsx([
    style.progressBar,
    style[`progress${percent}`],
    striped && style.striped,
  ]);

  return (
    <div className={classNames} style={styles}>
      <div className={style.percent}>
        <div className={style.progress} style={{ backgroundColor: color }}></div>
        {text ? (
          <span style={{ color: textColor }}>{text}</span>
        ) : (
          showPercent && <span style={{ color: textColor }}>{percent}%</span>
        )}
      </div>
    </div>
  );
}
