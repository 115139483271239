import { useEffect, useState } from 'react';

import styles from './ProfileConfig.module.scss';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../core/api/ApiProvider';

export default function ProfileConfig5({ formData, onFinish }: any) {
  const { mutateApi } = useApi();
  const queryClient = useQueryClient();

  const [firstExperience, setFirstExperience] = useState(false);
  const { mutate: mutateProfile, isLoading: profileLoading } = useMutation(
    profileData =>
      mutateApi(['profile'], profileData, {
        method: 'PATCH',
        invalidate: ['profile'],
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profile']);
      },
    },
  );

  const { mutate: mutateUser, isLoading: userLoading } = useMutation(
    userData =>
      mutateApi(['user'], userData, {
        method: 'PATCH',
        invalidate: ['user'],
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user']);
      },
    },
  );

  useEffect(() => {
    setFirstExperience(true);
  }, []);

  useEffect(() => {
    mutateUser(formData.user);
    mutateProfile(formData.profile);

    //   updateUser(formData.user);
    //   updateProfile(formData.profile);
  }, []);

  const handleFinish = (evt: any) => {
    evt.preventDefault();

    onFinish(firstExperience);
  };

  return (
    <>
      <div className={styles.title}>HEMOS CREADO TU PLAN PERSONALIZADO</div>

      {profileLoading || userLoading ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <p className={styles.description}>
            Tus preferencias se han guardado correctamente. Esperamos que disfrutes con las clases
            que hemos creado para ti.
          </p>
          <button
            style={{
              marginTop: '20px',
            }}
            className={styles.button}
            onClick={handleFinish}
          >
            ¡Adelante!
          </button>
        </>
      )}
    </>
  );
}
