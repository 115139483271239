type ChevronProps = {
  size?: number;
  color?: string;
  isDown?: boolean;
};

const Chevron = ({ size = 12.38, color = '#fff', isDown = true }: ChevronProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size * 0.618}>
      <path
        d="m10.96.025-4.73 4.73L1.5.025.04 1.485l6.19 6.19 6.19-6.19z"
        style={{
          fill: color,
          transform: isDown ? 'rotate(0deg)' : 'rotate(180deg)',
          transformOrigin: 'center',
        }}
      />
    </svg>
  );
};
export default Chevron;
