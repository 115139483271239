import styles from './EmptySection.module.scss';

interface IEmptySection {
  icon: string,
  headerText: string,
  bodyText: string
}

const EmptySection = ({icon, headerText, bodyText}: IEmptySection) => {
  return (
    <div className={styles.emptySection}>
      <img className={styles.icon} width={100} src={icon}/>

      <div className={styles.content}>
        <span className={styles.sectionHeader}>{headerText}</span>
        <span className={styles.sectionBody}>{bodyText}</span>
      </div>
    </div>
  )
}

export default EmptySection;