import { useEffect, useRef, useState } from 'react';
import ButtonMoveTag from './ButtonMoveTag';
import ColorPickerModal from '../MyClasses/ColorPickerModal';
import FormInput from '../FormControls/FormInput';
import styles from './InputTag.module.scss';
import clsx from 'clsx';
import TagsColorList from '../TagsColorList/TagsColorList';
import { useDropdown } from '@/core/hooks/useDropdown';

const InputTag = ({
  tagId,
  currentName,
  currentColor,
  onUpdateName,
  onUpdateColor,
  onDelete = null,
  onBlur,
  onDragEnter = null,
  onDragStart = null,
  onDragEnd = null,
  onColorPickerColorDisplayChange = null,
  itemCurrentDrag,
}) => {
  const [isDraggable, setIsDraggable] = useState(false);

  const { dropdownOpened, dropdownRef, parentRef, openDropdown, closeDropdown } = useDropdown();

  const ref = useRef();

  const classes = clsx(styles.inputTag, {
    [styles.draggable]: itemCurrentDrag,
  });

  const classesRow = clsx(styles.content, {
    [styles.row]: !itemCurrentDrag,
    [styles.rowDraggable]: itemCurrentDrag,
  });

  const updateName = event => onUpdateName(event.target.value);

  const updateColor = color => onUpdateColor(color);

  const deleteTag = () => onDelete();

  const handleKeyDown = event => {
    if (event.key !== 'Enter') return;

    ref.current.blur();
  };

  const focusInput = () => ref.current.focus();

  const handleEnterMouse = () => setIsDraggable(true);

  const handleLeaveMouse = () => setIsDraggable(false);

  const hasDraggableMethods = !!onDragEnter || !!onDragStart || !!onDragEnd;

  useEffect(() => {
    if (onColorPickerColorDisplayChange) {
      onColorPickerColorDisplayChange(dropdownOpened);
    }
  }, [dropdownOpened]);

  return (
    <div
      className={classes}
      draggable={isDraggable}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={e => e.preventDefault()}
    >
      <div className={classesRow}>
        {!itemCurrentDrag && (
          <>
            {hasDraggableMethods && (
              <ButtonMoveTag
                handleEnterMouse={handleEnterMouse}
                handleLeaveMouse={handleLeaveMouse}
              />
            )}

            <ColorPickerModal
              ref={parentRef}
              colorSelected={currentColor}
              toggleDropdown={openDropdown}
            />

            {dropdownOpened && (
              <TagsColorList
                ref={dropdownRef}
                currentColor={currentColor}
                parentRef={parentRef}
                requestClose={closeDropdown}
                onClick={updateColor}
              />
            )}

            <FormInput
              ref={ref}
              key={`tag-input-${tagId}`}
              autoFocus={!tagId}
              value={currentName}
              style={{
                flex: '1 0 auto',
                display: 'inline-block',
                backgroundColor: 'transparent',
              }}
              type="text"
              onChange={updateName}
              onKeyDown={handleKeyDown}
              onBlur={() => onBlur(focusInput)}
              placeholder="Escribe el nombre de la colección"
            />
          </>
        )}
      </div>
      {onDelete && <button className={styles.close} onClick={deleteTag}></button>}
    </div>
  );
};

export default InputTag;
