import chevronDown from '../../../../img/chevron-down@1x.png';

import iconPerfil from '../../../../img/icon-perfil.svg';

import iconPreferencias from '../../../../img/icon-preferencias.svg';

import iconCuenta from '../../../../img/icon-cuenta.svg';

import iconSuscripcion from '../../../../img/icon-suscripcion.svg';

import iconAyuda from '../../../../img/icon-ayuda.svg';

import usePathRecovery from '@/core/hooks/usePathRecovery';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DropdownMenu.scss';

export default function DropdownMenu({ user }) {
  const [open, setOpen] = useState(false);
  const refContainer = useRef(null);

  const { savePath } = usePathRecovery();

  const toggleDropdown = () => setOpen(!open);

  useEffect(() => {
    const closeDropdown = e => {
      let curr = e.target;
      while (curr !== null) {
        if (curr === refContainer.current) {
          // Ignore the click, it's inside the container.
          return;
        }

        curr = curr.parentNode;
      }
      setOpen(false);
    };
    window.addEventListener('click', closeDropdown);
    return () => window.removeEventListener('click', closeDropdown);
  }, []);

  return (
    <div className="dropdown">
      <button
        className="profile dropdown-toggle"
        onClick={toggleDropdown}
        ref={refContainer}
        type="button"
      >
        <img className="user-image" alt={user.full_name} src={user.getAvatar()} />
        <div className="user-name lato-normal-white-14px">{user.full_name}</div>
        <img alt="" className="chevron-down" src={chevronDown} />
      </button>

      <div className={`dropdown-menu ${open ? 'show' : ''} dropdown-menu-end`}>
        {/* <div>
          <a className="dropdown-item" href="#app/profile"
            ><img className="icon" src="<%= iconPerfil %>" /><span
              >Mi Perfil</span
            ></a
          >
        </div> */}
        {/* <div>
          <a className="dropdown-item" href="/pro"><i className="fa fa-user icon"></i>Bestpro</a>
        </div> */}
        <div>
          <Link className="dropdown-item" to="/settings">
            <img className="icon" src={iconPreferencias} />
            <span>Preferencias</span>
          </Link>
        </div>
        <div className="dropdown-divider"></div>
        <div>
          <a className="dropdown-item" href="/user">
            <img className="icon" src={iconCuenta} />
            <span>Mi cuenta</span>
          </a>
        </div>
        <div>
          <Link to={'/subscription'} className="dropdown-item">
            {/* <a className="dropdown-item" href="#subscription"> */}
            <img className="icon" src={iconSuscripcion} />
            <span>Suscripción</span>
            {/* </a> */}
          </Link>
        </div>
        <div>
          <a className="dropdown-item" href="/page/ayuda" target="_blank" rel="noreferrer">
            <img className="icon" src={iconAyuda} />
            <span>Ayuda</span>
          </a>
        </div>
        <div className="dropdown-divider"></div>
        <div>
          <Link onClick={() => savePath()} to={'/logout'} className="dropdown-item">
            <i className="fa icon"></i>Cerrar sesión
          </Link>
        </div>
      </div>
    </div>
  );
}
