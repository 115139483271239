import { useEffect, useState } from 'react';

type State = 'online' | 'offline';
export default function useDetectConnection(onReconnect?: () => void) {
  /**
   * Due to the bug https://bugs.chromium.org/p/chromium/issues/detail?id=678075,
   * it's not reliable to detect if the browser is currently online or offline
   * based on `navigator.onLine`.
   * As a workaround, we always assume it's online on the first load, and change
   * the status upon `online` or `offline` events.
   * const [state, setState] = useState<State>(navigator.onLine ? 'online' : 'offline');
   */
  const [state, setState] = useState<State>('online');

  useEffect(() => {
    const setOnline = () => {
      setState('online');
      window.electronAPI?.changeConnectionStatus(true);
      onReconnect?.();
    };
    const setOffline = () => {
      setState('offline');
      window.electronAPI?.changeConnectionStatus(false);
    };
    const checkConnection = () => {
      const electronConnectionStatus = window.electronAPI?.checkConnection
        ? window.electronAPI?.checkConnection()
        : null;

      if (electronConnectionStatus == null) return;
      else if (electronConnectionStatus) setOnline();
      else setOffline();
    };

    checkConnection();
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);
    window.addEventListener('focus', checkConnection);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('focus', checkConnection);
    };
  }, []);

  return state;
}
