import iconPause from '../../../../../img/constructor/reproductor/icon-pause.svg';

export default function Pause() {
  return (
    <img
      src={iconPause}
      alt=""
      style={{ marginTop: '3px', width: '26px', height: '26px' }}
    />
  );
}
