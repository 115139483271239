import HeartEmoji from '@/img/surveys/HeartEmoji';
import { useState } from 'react';
import Button from '../../UI/Button';
import styles from './NpsSurveyCard.module.scss';
import NpsSurveyModal from './modal//NpsSurveyModal';

type NpsInitialStateProps = {
  onConfirm(): void;
  onDecline(): void;
}

const NpsInitialState = (props: NpsInitialStateProps) => {
  const [npsModal, setNpsModal] = useState(false);

  const showNpsModal = () => setNpsModal(true);
  const hideNpsModal = () => setNpsModal(false);

  return (
    <>
      <div className={styles.surveyContent}>
        <div className={styles.title}>Nos encantaría saber qué piensas de Bestcycling</div>
        <div className={styles.content}>
          <span>¿Te gustaría compartir tu opinión con nosotros?</span>
          <HeartEmoji width={50} />
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="secondary" size="default" title="NO" onClick={props.onDecline}>
          NO
        </Button>
        <Button variant="primary" size="default" title="SI" onClick={showNpsModal}>
          SI
        </Button>

        <NpsSurveyModal isOpen={npsModal} onClose={hideNpsModal} onConfirm={props.onConfirm} />
      </div>
    </>
  );
}

export default NpsInitialState;