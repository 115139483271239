import SectionTitle from '../SectionTitle';
import TrainingCategoriesCarousel from '../TrainingCategoryCard/TrainingCaregoriesCarousel';

export default function CategoriesSection({ title = 'Entrenamientos', categories }) {
  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <TrainingCategoriesCarousel categories={categories} />
    </>
  );
}
