import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import useDocumentTitle from '@rehooks/document-title';
import ConflictForm from '../components/ConflictPage/ConflictForm';

export default function ConflictPage() {
  useDocumentTitle('Acceso | Bestcycling');
  const {
    state: { isLoggedIn, userConflict },
  } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" replace={true} />;
  }

  if (!userConflict) {
    return <Navigate to="/login" replace={true} />;
  }

  return <ConflictForm />;
}
