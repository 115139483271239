import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/auth.context';
import { useShell } from '@/contexts/shell.context';
import usePathRecovery from '../hooks/usePathRecovery';

const NavigateToLogin = () => {
  const location = useLocation();
  const { state: shell } = useShell();

  const { getPath, savePath } = usePathRecovery();

  if (window.electronAPI) {
    return <Navigate to="/login" state={{ location }} replace={true} />;
  }

  if (process.env.NODE_ENV == 'production' && !shell.desktop) {
    localStorage.removeItem('session');

    if (!getPath()) {
      savePath();
    }

    window.location.href = `/session/new?urlok=${encodeURIComponent(getPath())}`;

    return null;
  }

  return <Navigate to="/login" state={{ location }} replace={true} />;
};

const NavigateToBanned = () => {
  const location = useLocation();
  return <Navigate to="/forbidden" state={{ location }} replace={true} />;
};

export default function ProtectedRoute() {
  const { state } = useAuth();
  const { isLoggedIn, isBanned } = state;

  if (isLoggedIn) {
    return isBanned ? <NavigateToBanned /> : <Outlet />;
  }

  return <NavigateToLogin />;
}
