import Category from '@/constants/category';
import { useLocation, useNavigate } from 'react-router';

export interface NavigateToPlayerOptions {
  media: { type: string };
  offline: boolean;
}

export default function useTrainingClassNavigation() {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToPlayer = (
    trainingClass: TrainingClass,
    { media, offline }: NavigateToPlayerOptions,
  ) => {
    const trainingClassPath = `/trainingclass/${trainingClass.id}`;
    const playerPath = `${trainingClassPath}/play/${media.type}`;

    let navigateTo = playerPath;

    if (offline) {
      navigateTo = `/offline${playerPath}`;
    } else if (location.pathname.startsWith('/bestpro')) {
      navigateTo = `/bestpro${playerPath}`;
    }

    navigate(navigateTo, { state: location.pathname });
  };

  const navigateFromPlayer = (trainingClass: TrainingClass) => {
    let navigateTo = `/trainingclass/${trainingClass.id}`;

    if (location.state) {
      navigateTo = location.state;
    } else if (
      trainingClass.category_nr == Category.VideoProgress ||
      trainingClass.category == Category.VideoProgress
    ) {
      // TODO: comprobar por qué los trainingClass llegan con category o category_nr
      navigateTo = '/sensei';
    } else if (location.pathname.startsWith('bestpro') && !trainingClass.official) {
      navigateTo = `/bestpro${navigateTo}`;
    } else if (!trainingClass.official) {
      navigateTo = `/bestpro${navigateTo}`;
    } else if (location.pathname.startsWith('offline')) {
      navigateTo = `/offline${navigateTo}`;
    }
    if (window.history.state?.idx === 0) {
      navigate(navigateTo, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return { navigateToPlayer, navigateFromPlayer };
}
