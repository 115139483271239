import { Colors } from '@/core/constants/category';
import styles from './TriangleClip.module.scss';

type TriangleClipProps = {
  categoryNr: number;
}

const TriangleClip = ({ categoryNr }: TriangleClipProps) => {
  return (
    <div
      className={styles.trianglecategory}
      style={{ backgroundColor: Colors[categoryNr as 1] }}
    />
  );
}

export default TriangleClip;