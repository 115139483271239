export default {
  type: 'comment',
  fields: {
    comment: 'string',
    commentable_id: 'string',
    commentable_type: 'string',
    created_at: 'date',
    id: 'string',
    like: 'boolean',
    likes_count: 'number',
    parent_id: 'number',
    replies: 'array',
    replies_count: 'number',
  },
  relationships: {
    user: {
      type: 'user',
    },
  },
};
