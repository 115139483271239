import { createContext, useReducer, useContext, useEffect, ReactNode, Dispatch } from 'react';
import { useShell } from '../shell.context';
import { IMediaSContext, MediaSAction, MediaSState } from './mediaserver.types';

const postMessage = (msg: any) => {
  window.top!.top!.postMessage(msg, '*');
};

const initialState = {
  running: false,
  root: null,
};

const MediaServerContext = createContext<IMediaSContext>({
  state: initialState,
  dispatch: () => {},
  start: () => {},
  stop: () => {},
});

const mediaServerReducer = (state: MediaSState, action: MediaSAction) => {
  const { type } = action;
  switch (type) {
    case 'media-server-state': {
      return {
        ...action.value,
      };
    }
    case 'media-server-start': {
      return {
        ...state,
        running: true,
      };
    }
    case 'media-server-stop': {
      return {
        ...state,
        running: false,
      };
    }
    default:
      throw new Error('MediaServer reducer message error');
  }
};

export const MediaServerProvider = ({ children }: { children: ReactNode }) => {
  const { state: shell } = useShell();
  const [state, dispatch] = useReducer(mediaServerReducer, initialState);

  useEffect(() => {
    const receivedMessage = (event: any) => {
      const { data } = event;
      if (!data) return;

      if (data.type == 'media-server-state') {
        dispatch(data);
      }
    };
    window.addEventListener('message', receivedMessage);
    return () => {
      window.removeEventListener('message', receivedMessage);
    };
  }, []);

  useEffect(() => {
    postMessage({ type: 'media-server-state' });
  }, []);

  const start = async () => {
    if (shell.nwjs) {
      postMessage({ type: 'media-server-start' });
    }
    if (shell.electron) {
      window.downloadsAPI?.startLocalServer();
      await new Promise(r => setTimeout(r, 600)); // Ñapa!
    }
    dispatch({ type: 'media-server-start' });
  };

  const stop = () => {
    if (shell.nwjs) {
      postMessage({ type: 'media-server-stop' });
    }
    if (shell.electron) {
      window.downloadsAPI?.stopLocalServer();
    }
    dispatch({ type: 'media-server-stop' });
  };

  return (
    <MediaServerContext.Provider value={{ state, start, stop, dispatch }}>
      {children}
    </MediaServerContext.Provider>
  );
};

// Starts offline server
export const useMediaServer = () => {
  const context = useContext(MediaServerContext);
  const { start, stop } = context;

  useEffect(() => {
    start();
    return () => stop();
  }, []);

  return context;
};
