import { Link } from 'react-router-dom';
import CategoryLogo from '../CategoryLogo';
import styles from './PlaylistCard.module';

const PlaylistItemsCount = ({ count }) => {
  if (count <= 0) return null;
  return (
    <div
      style={{
        fontFamily: 'Lato',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'italic',
        textTransform: 'none',
        marginBottom: '2px',
        letterSpacing: '0.5px',
      }}
    >
      {count} {count == 1 ? 'entrenamiento' : 'entrenamientos'}
    </div>
  );
};

export default function PlaylistCard({ playlist = [], showItems = false, onSelect, ...props }) {
  const handleClick = evt => {
    if (typeof onSelect === 'function') {
      evt.preventDefault();
      onSelect(playlist.id);
    }
  };

  return (
    <Link
      to={`/playlists/${playlist.id}`}
      className={styles.playlistCard}
      role="link"
      onClick={handleClick}
      {...props}
    >
      <div className={styles.playlistThumbnail}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${playlist.image})`,
          }}
        >
          <div className={styles.shadow}></div>
          <div className={styles.playlistCategory}>
            <CategoryLogo categoryNr={playlist.category_nr} />
          </div>
        </div>
      </div>

      <h3 className={styles.playlistTitle}>
        {showItems ? <PlaylistItemsCount count={playlist.item_count} /> : null}
        <div>{playlist.title}</div>
      </h3>
      <div className={styles.overlayCollection}></div>
    </Link>
  );
}
