import { useQuery } from 'jsonapi-react';
import ResumeModal from '../Trainings/ResumeModal';
import ObtainedPointsModal from './ObtainedPointsModal';

function ResumeTraining({ isOpen, isAnnotate, isPointsOpen, onClose, goObtainedPoints }) {
  const guid = localStorage.getItem('trainingClassGuid');
  const { data: training } = useQuery(['trainings/' + guid]);

  return (
    <>
      {training && (
        <>
          <ResumeModal
            training={training}
            isOpen={isOpen}
            isAnnotate={isAnnotate}
            onClose={onClose}
            goObtainedPoints={goObtainedPoints}
          />
          <ObtainedPointsModal
            trainingClass={training.training_class}
            isOpen={isPointsOpen}
            onClose={onClose}
          />
        </>
      )}
    </>
  );
}

export default ResumeTraining;
