import { useApi } from '@/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import { RemoteSyncablePreferences } from '@/core/features/preferences/types/preferences';

const queryKey = 'app_preferences';

const usePatchAppPreferences = () => {
  const { mutateApi } = useApi();

  return useMutation(async (data: RemoteSyncablePreferences & { updated_at?: string }) => {
    const res = await mutateApi([queryKey], data, {
      method: 'PATCH',
    });

    if (res.error) {
      throw new Error('Error sync preferences', res.error);
    }

    return res;
  });
};

export default usePatchAppPreferences;
