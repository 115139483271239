import iconDownload from '@/img/icon-download.svg';

import styles from './DownloadAudiovisual.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { useToast } from '@/contexts/toast.context';
import Modal from '../Modal';
import clsx from 'clsx';
import Button from '../UI/Button';

import ProgressBar from '../ProgressBar/ProgressBar';
import useDownloadAudiovisual from '@/core/hooks/useDownloadAudiovisual';

export default function DownloadAudiovisual({ trainingClass }: { trainingClass: TrainingClass }) {
  const { state: auth } = useAuth();

  const { showToast } = useToast();

  const { state, actions } = useDownloadAudiovisual();

  const getAudiovisual = () => {
    const media = trainingClass?.extra_media.find(m => m.type == 'video_extra');
    if (media != null) {
      return `${media?.url}&access_token=${auth.accessToken}`;
    }
    return null;
  };

  const getFileName = () => {
    return `${(trainingClass.title + ' ' + trainingClass.subtitle).trim()}.mp4`;
  };

  const handleDownload = async () => {
    const fileUrl = getAudiovisual();
    if (!fileUrl) {
      showToast('Hay un problema con esta clase, no se puede descargar el audiovisual', 'error');
      return;
    }

    actions.download({ url: fileUrl });
  };

  const handleCancel = async () => {
    actions.cancel();
  };

  useEffect(() => {
    if (state.status === 'downloaded') {
      actions.save({ name: getFileName() });
    }
  }, [state.status]);

  return (
    <>
      <button
        title={state.status == 'downloading' ? 'Descargando audivisual' : 'Descarga audiovisual'}
        className={clsx([styles.border, styles.active])}
        onClick={handleDownload}
      >
        <img className={styles.icon} src={iconDownload} />
      </button>
      <ModalDownloadWait
        isOpen={state.status == 'downloading'}
        progress={state.progress}
        onClickCancel={handleCancel}
      />
    </>
  );
}

const ModalDownloadWait = ({
  isOpen,
  progress,
  onClickCancel,
}: {
  isOpen: boolean;
  progress: number;
  onClickCancel: () => void;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.header}>Descargando clase...</div>
          <div className={styles.description}>Sé paciente, esto puede tardar unos minutos.</div>
          <div className={styles.content}>
            <ProgressBar
              color="#ff7900"
              textColor="white"
              percent={progress.toFixed(0)}
              showPercent={progress > 0}
              striped={true}
            />
          </div>
          <Button
            onClick={onClickCancel}
            variant="terciary"
            size="default"
            style={{ margin: '0 auto' }}
          >
            CANCELAR
          </Button>
        </div>
      </Modal>
    </>
  );
};

// const saveFile = async () => {
//   try {
//     const handle = await window.showSaveFilePicker({
//       suggestedName: getTitle(),
//       types: [
//         {
//           accept: { 'video/mp4': ['.mp4'] },
//         },
//       ],
//     });

//     const writable = await handle.createWritable();
//     return writable;
//   } catch (err) {
//     console.error(err.name, err.message);
//   }
//   return null;
// };
