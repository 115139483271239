import Training from './Training';

import styles from './TrainingsList.module.scss';

export default function TrainingsList({ trainings }: { trainings?: Training[] }) {
  if (!trainings?.length)
    return (
      <div className={styles.trainingsEmpty}>
        <p>Todavía no has anotado los resultados de esta clase.</p>
      </div>
    );

  return (
    <>
      {trainings?.map(training => {
        return <Training key={training.id} training={training} />;
      })}
    </>
  );
}