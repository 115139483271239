import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../api/ApiProvider';
import Page from '../components/Page';
import { HomePlaylistsSlider, PlaylistsSlider } from '../components/Playlists';
import NotFound from '../components/Playlists/NotFound';
import LoadingIndicator from '../components/LoadingIndicator';
import SectionTitle from '../components/SectionTitle';
import useDocumentTitle from '@rehooks/document-title';
import { FooterMin } from '@/components/Footer';
import MyCollectionsSection from '../components/HomePage/MyCollectionsSection';

const FavouritePlaylists = ({ onPlaylistClick }) => {
  const { fetchApi, getNextPageParam } = useApi();
  const { data, isSuccess, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['playlists', { filters: { favourite: 1 } }],
    ({ pageParam }) =>
      fetchApi(['playlists', { filters: { favourite: 1 }, cursor: pageParam, limit: 3 }]),
    {
      getNextPageParam,
    },
  );

  const playlists = data?.pages?.map(page => page.data).flat();

  return isSuccess && playlists.length ? (
    <>
      <SectionTitle>Colecciones favoritas</SectionTitle>
      <PlaylistsSlider
        playlists={playlists}
        isFetchingNextPage={isFetchingNextPage}
        isSuccess={isSuccess}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        onClick={onPlaylistClick}
      />
    </>
  ) : null;
};

const RootPlaylists = ({ onPlaylistClick }) => {
  const { fetchApi } = useApi();
  const { data, isSuccess, isLoading, isError } = useQuery(['playlists'], () =>
    fetchApi(['playlists']),
  );

  if (isError) return <NotFound />;

  if (isLoading) return <LoadingIndicator />;

  return isSuccess && data.data.length ? (
    <>
      <SectionTitle>Colecciones por actividad</SectionTitle>
      <HomePlaylistsSlider playlists={data.data} onClick={onPlaylistClick} />
    </>
  ) : null;
};

const StarredPlaylists = ({ onPlaylistClick }) => {
  const { fetchApi, getNextPageParam } = useApi();
  const { data, isSuccess, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['playlists', { filters: { starred: 1 } }],
    ({ pageParam }) =>
      fetchApi(['playlists', { filters: { starred: 1 }, cursor: pageParam, limit: 3 }]),
    {
      getNextPageParam,
    },
  );

  const playlists = data?.pages?.map(page => page.data).flat();

  return isSuccess && playlists ? (
    <>
      <SectionTitle>Colecciones destacadas</SectionTitle>
      <PlaylistsSlider
        playlists={playlists}
        isFetchingNextPage={isFetchingNextPage}
        isSuccess={isSuccess}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        onClick={onPlaylistClick}
      />
    </>
  ) : null;
};

const HearstPlaylists = ({ title, ids, onPlaylistClick }) => {
  if (!ids) return null;

  const { fetchApi } = useApi();
  const { data, isSuccess, isError } = useQuery(['playlists', { filters: { id: ids } }], () =>
    fetchApi(['playlists', { filters: { id: ids } }]),
  );

  if (isError) return null;

  return isSuccess && data.data.length ? (
    <>
      <SectionTitle>{title}</SectionTitle>
      <HomePlaylistsSlider playlists={data.data} onClick={onPlaylistClick} />
    </>
  ) : null;
};

const SpecialPlaylists = ({ onPlaylistClick }) => {
  const { fetchApi } = useApi();
  const { data, isSuccess, isError } = useQuery(['front'], () => fetchApi(['front']));

  if (isError) return null;

  if (!isSuccess) return null;

  return data?.data?.resources.map((resource, i) => {
    if (resource && resource.key == 'hearst_playlists') {
      return (
        <HearstPlaylists
          key={i}
          title={resource.title}
          ids={resource.ids}
          onPlaylistClick={onPlaylistClick}
        />
      );
    }
    return null;
  });
};

export default function PlaylistsPage() {
  useDocumentTitle('Colecciones | Bestcycling');

  const navigate = useNavigate();

  const onPlaylistClick = playlist => {
    navigate(`/playlists/${playlist.id}`);
  };

  return (
    <Page footer={<FooterMin />}>
      <FavouritePlaylists onPlaylistClick={onPlaylistClick} />
      <MyCollectionsSection />
      <SpecialPlaylists onPlaylistClick={onPlaylistClick} />
      <RootPlaylists onPlaylistClick={onPlaylistClick} />
      <StarredPlaylists onPlaylistClick={onPlaylistClick} />
    </Page>
  );
}
