import dayjs from 'dayjs';
import Training from '../Trainings/Training';
import styles from './ProfileGroupedTrainings.module.scss';

export default function ProfileGroupedTrainings({
  date,
  trainings,
}: {
  date: string;
  trainings: Training[];
}) {
  return (
    <div className={styles.groupedTrainings}>
      <h2 className={styles.title}>{dayjs(date).format('DD MMM, YYYY')}</h2>
      <div className={styles.group}>
        {trainings
          .map(training => <Training key={`${training.id}-${date}`} training={training} />)
          .reverse()}
      </div>
    </div>
  );
}
