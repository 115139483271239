import React from 'react';

import { useAppContext } from './contexts/app.context';
import { NavProvider, useNav } from './contexts/nav.context';
import Navigation from './components/Navigation';
import Topbar from './components/Topbar';
import LoadingIndicator from './components/LoadingIndicator';
import { Toasts } from './components/Modal/Toast';
import OfflineModal from './components/Modal/OfflineModal';
import { useElectronMenuBar } from './hooks/useElectronMenuBar';

const LayoutBase = ({ children }: { children: React.ReactNode }) => {
  const { state } = useAppContext();
  const { state: navState } = useNav();

  if (!state.user || !state.profile) return <LoadingIndicator />;

  return (
    <>
      {navState.show && (
        <div id="navigation">
          <Navigation user={state.user} />
        </div>
      )}

      {/* <OfflineModal />
      <DesktopModal />
      <Toasts /> */}

      <div id="main" className={!navState.show ? 'hide-navigation' : ''}>
        {navState.show && (
          <div id="header">
            <Topbar user={state.user} />
          </div>
        )}
        <OfflineModal />
        <Toasts />
        <div id="content">{children}</div>
      </div>
    </>
  );
};

const Layout = (props: { children: React.ReactNode }) => {
  useElectronMenuBar();
  return (
    <NavProvider>
      <LayoutBase {...props} />
    </NavProvider>
  );
};

export default Layout;
