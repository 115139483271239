import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { useRef, useState } from 'react';
import ConfirmModal from '../Modal/ConfirmModal';
import InputTag from '../TagsList/InputTag';

import styles from './EditMyCollectionModal.module.scss';
import { useToast } from '@/contexts/toast.context';
import clsx from 'clsx';

type EditMyCollectionModalProps = {
  isOpen: boolean;
  onClose(): void;
  collectionsNames: string[];
  collection: Tag;
};

const EditMyCollectionModal = (props: EditMyCollectionModalProps) => {
  const { showToast } = useToast();

  const { client, mutateApi } = useApi();
  const [currentName, setCurrentName] = useState(props.collection.name);
  const [currentColor, setCurrentColor] = useState(props.collection?.properties?.color);

  const clearCache = () => {
    client.invalidateQueries(['user_tags']);
    client.invalidateQueries(['my_training_classes']);
    client.invalidateQueries(['training_classes', props.collection.id]);
  };

  const modifyCollection = useMutation(
    (data: Tag) =>
      mutateApi(['user_tags', data.id], data, {
        method: 'PUT',
      }),
    {
      onSuccess: clearCache,
    },
  );

  const hasSameName = currentName === props.collection.name;

  const hasSameColor = currentColor === props.collection?.properties?.color;

  const reset = () => {
    setCurrentName(props.collection.name);
    setCurrentColor(props.collection?.properties?.color);
  };

  const onAccept = () => {
    updateCollection();
  };

  const onCancel = () => {
    reset();
    props.onClose();
  };

  const updateCurrentName = (name: string) => setCurrentName(name);

  const updateCurrentColor = (color: string) => setCurrentColor(color);

  const updateCollection = async () => {
    if (hasSameName && hasSameColor) {
      props.onClose();
      return;
    }

    if (!hasSameName && props.collectionsNames.includes(currentName)) {
      showToast(
        'No se ha podido actualizar la colección. Ya tienes una colección con ese nombre',
        'error',
      );
      return;
    }

    const collectionToMutate = {
      ...props.collection,
      name: currentName,
      properties: {
        ...props.collection,
        color: currentColor,
      },
    };

    await modifyCollection.mutateAsync(collectionToMutate);
    props.onClose();
  };

  return (
    <ConfirmModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={'Editar colección'}
      onCancel={onCancel}
      onAccept={onAccept}
      className={clsx(styles.container, styles.modal)}
      canSubmit={!!currentName.length}
    >
      <InputTag
        key={props.collection.id}
        tagId={props.collection.id}
        currentName={currentName}
        currentColor={currentColor}
        onUpdateName={updateCurrentName}
        onUpdateColor={updateCurrentColor}
        onBlur={() => {}}
        itemCurrentDrag={0}
      />
    </ConfirmModal>
  );
};

export default EditMyCollectionModal;
