import prevPageIcon from '@/img/prev-page-1@1x.png';

import nextPageIcon from '@/img/next-page-1@1x.png';

import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import styles from './Modal.module.scss';
import ExternalDismissibleBox from '../ExternalDismissibleBlox/ExternalDismissibleBox';
import { BLOCK_SCROLL } from '@/core/constants/behaviour';
import React from 'react';
import clsx from 'clsx';

type ModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void | null;
  children: React.ReactNode;
  zIndex?: number;
  containerName?: string;
  className?: string;
  showBackground?: boolean;
  [key: string]: any;

  onPrevRequest?(): void;
  onNextRequest?(): void;
  closeModalOnBackgroundClick?: boolean;
};

const Modal = React.forwardRef<any, ModalProps>(
  (
    {
      isOpen,
      onRequestClose,
      containerName,
      className,
      zIndex,
      children,
      showBackground = true,

      closeModalOnBackgroundClick = true,
      onPrevRequest,
      onNextRequest,
      ...props
    }: ModalProps,
    ref: any,
  ) => {
    if (!isOpen) return null;

    const closeModal = () => {
      if (onRequestClose && closeModalOnBackgroundClick) {
        onRequestClose();
      }
    };
    return ReactDOM.createPortal(
      <ExternalDismissibleBox requestClose={onRequestClose}>
        <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
          <div
            ref={ref}
            className={clsx(styles.container, containerName || '', 'modal', BLOCK_SCROLL)}
            style={{ zIndex: zIndex }}
          >
            <div className={styles.shadow} onClick={closeModal}></div>

            {onPrevRequest && (
              <button className={`${styles.lateralButton} ${styles.left}`} onClick={onPrevRequest}>
                <img src={prevPageIcon} />
              </button>
            )}
            <div className={clsx(styles.modal, className || '')} {...props}>
              {children}
              {onRequestClose ? (
                <button
                  title="Cerrar modal"
                  className={styles.close}
                  onClick={onRequestClose}
                  tabIndex={10}
                  type="button"
                />
              ) : (
                <button style={{ all: 'unset', position: 'absolute' }} aria-hidden={true}></button>
              )}
            </div>
            {onNextRequest && (
              <button
                className={clsx([styles.lateralButton, styles.right])}
                onClick={onNextRequest}
              >
                <img src={nextPageIcon} />
              </button>
            )}
          </div>
        </FocusTrap>
      </ExternalDismissibleBox>,
      document.getElementById('modal-portal')!,
    );
  },
);
Modal.displayName = 'Modal';

export default Modal;
