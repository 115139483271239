import { useApi } from '@/api/ApiProvider';
import { useQuery } from '@tanstack/react-query';

const useFetchTrainingClasses = ({
  filters,
  include,
  limit,
}: {
  filters: { [key: string]: any };
  include?: string[];
  limit?: number;
}) => {
  const QUERY_KEY = 'training_classes';
  const { fetchApi } = useApi();
  const config = {
    filters,
    include: include || ['trainer', 'training', 'user_tags'],
    limit: limit || 10,
  };

  return useQuery<TrainingClass[]>([QUERY_KEY, { ...filters }], async () => {
    const res = await fetchApi(['training_classes', config]);

    if (res.error) throw new Error(res.error);

    return (res.data as TrainingClass[]) || [];
  });
};

export default useFetchTrainingClasses;
