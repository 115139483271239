import { useEffect, useMemo, useRef, useState } from 'react';

import { SwitchCheckBox } from '../FormControls';
import { useDownload } from '../../contexts/downloads.context';
import Button from '../UI/Button';
import Category from '../../constants/category';
import MediaAsset from './MediaAsset';
import Modal from '../Modal';
import styles from './PlayModal.module.scss';
import useSetting from '../Preferences/useSetting';
import ProgressBar from '../ProgressBar/ProgressBar';
import { useShell } from '@/contexts/shell.context';
import ConnectedDevicesTile from './ConnectedDevicesTile';
import { useBTContext } from '@/contexts/bluetoothConnection.context';
import { BluetoothDeviceTypes } from '@/constants/bluetooth';
import { GraphType } from '@/constants/graphFactory';

export interface PlayModalProps {
  trainingClass: TrainingClass;
  isOpen: boolean;
  onClose: () => void;
  hasMediaClass?: boolean;
  isRetriesMediaFailed?: boolean;
  onClickDevices: () => void;
  onKnowingFtp: () => void;
}

export default function PlayModal({
  trainingClass,
  isOpen,
  onClose,
  hasMediaClass,
  isRetriesMediaFailed,
  onClickDevices,
  onKnowingFtp,
}: PlayModalProps) {
  const download = useDownload(trainingClass.id);
  const { state: shell } = useShell();
  const [videoHDSetting] = useSetting<boolean>('videoHD');
  const [_, setGraphType] = useSetting('graph_type');
  const { state, actions } = useBTContext();
  const bikesConnected = actions.getConnectedDevices(BluetoothDeviceTypes.Bike);

  const videoDownload = download?.downloadedMedia?.find(
    d => d.type === 'video_hd' || d.type === 'video_sd',
  );
  const canSetVideoQuality = !videoDownload;

  const [videoHD, setVideoHD] = useState<boolean>(
    videoDownload ? (videoDownload.type === 'video_hd' ? true : false) : videoHDSetting,
  );

  const [annotateResults, setAnnotateResults] = useSetting<boolean>('annotateResults');

  const { media } = trainingClass;
  const isHls = media.some(m => m.type === 'hls');
  const isMind = trainingClass.category_nr === Category.Mind;
  const isOfficial = trainingClass.official;

  useEffect(() => {
    if (bikesConnected.length > 0) {
      setGraphType(GraphType.Watts);
    }
  }, [bikesConnected.length]);

  const videoMedia = useMemo(() => {
    if (isHls && !videoDownload?.downloaded) {
      return media?.find(ma => ma.type === 'hls');
    }

    return media?.find(ma => ma.type === (videoHD ? 'video_hd' : 'video_sd'));
  }, [videoDownload, media, videoHD]);

  const musicMedia = useMemo(() => {
    return media?.find(ma => ma.type === 'music');
  }, [media]);

  const audioMedia = useMemo(() => {
    return media?.find(ma => ma.type === 'audio');
  }, [media]);

  const isVideoQualityVisible = (!isHls && !isMind) || !!videoDownload;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      {!hasMediaClass && !isRetriesMediaFailed && (
        <>
          <div className={styles.header}>Procesando audio...</div>
          <div className={styles.span0} style={{ textAlign: 'center', marginTop: '15px' }}>
            La clase estará preparada en unos segundos.
          </div>
          <div className={styles.content}>
            <ProgressBar showPercent={false} striped={true} percent={100} />
          </div>
        </>
      )}

      {isRetriesMediaFailed && (
        <>
          <div className={styles.header}>No hay clase disponible.</div>
          <div className={styles.span0} style={{ textAlign: 'center', marginTop: '15px' }}>
            Por favor, inténtalo de nuevo más tarde.
          </div>
          <Button
            variant="terciary"
            size="default"
            title="refrescar"
            style={{ margin: '0 auto', marginTop: '30px', background: '#ff7900', color: 'white' }}
            onClick={onClose}
          >
            salir
          </Button>
        </>
      )}

      {hasMediaClass && !isRetriesMediaFailed && (
        <>
          <div className={styles.title}>Reproducir clase</div>
          <div className={styles.lineDivider}></div>

          {isVideoQualityVisible && isOfficial && (
            <label className={styles.switch_container}>
              <div className={styles.switch_label}>
                <div className={styles.span0}>Calidad HD</div>
                <div className={styles.span1}>Calidad de reproducción de vídeos</div>
              </div>
              <div className={styles.switch_switch}>
                <SwitchCheckBox
                  label="Calidad HD"
                  defaultChecked={videoHD}
                  disabled={!canSetVideoQuality}
                  onChange={() => setVideoHD(!videoHD)}
                />
              </div>
            </label>
          )}

          {trainingClass.category_nr == Category.Training && (
            <label className={styles.switch_container}>
              <div className={styles.switch_label}>
                <div className={styles.span0}>Anotar resultados durante la clase</div>{' '}
              </div>

              <div className={styles.switch_switch}>
                <SwitchCheckBox
                  label="Anotar resultados durante la clase"
                  defaultChecked={annotateResults}
                  onChange={() => setAnnotateResults(!annotateResults)}
                />
              </div>
            </label>
          )}

          {shell.desktop && actions.hasNewApi() && (
            <ConnectedDevicesTile
              onClick={onClickDevices}
              devices={actions.getConnectedDevices()}
            />
          )}
          <div className={styles.medias_container}>
            {videoMedia && (
              <div style={{ flex: '.3333333333' }}>
                <MediaAsset
                  trainingClass={trainingClass}
                  media={videoMedia}
                  isMind={isMind}
                  onKnowingFtp={onKnowingFtp}
                />
              </div>
            )}

            {!isMind && musicMedia && (
              <div style={{ flex: '.3333333333' }}>
                <MediaAsset
                  trainingClass={trainingClass}
                  media={musicMedia}
                  isMind={isMind}
                  onKnowingFtp={onKnowingFtp}
                />
              </div>
            )}

            {audioMedia && (
              <div style={{ flex: '.3333333333' }}>
                <MediaAsset
                  trainingClass={trainingClass}
                  media={audioMedia}
                  isMind={isMind}
                  onKnowingFtp={onKnowingFtp}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
