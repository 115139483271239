import Modal from '../Modal';
import Button from '../UI/Button';
import styles from './ConnectingBikeModal.module.scss';

interface AllOkModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export default function AllOkModal({ isOpen, onCancel }: AllOkModalProps) {
  const onFinish = () => {
    localStorage.setItem('bikeConnected', 'true');
    onCancel();
  };
  return (
    <Modal isOpen={isOpen} className={styles.modalAllOk} onRequestClose={onCancel}>
      <h1 className={styles.title}> ¡TODO LISTO PARA EMPEZAR A PEDALEAR!</h1>
      <p className={styles.description}>
        ¡Ahora sí! Ya está todo preparado. Esperamos que disfrutes al máximo de nuestras clases y
        alcances tus objetivos mientras te diviertes con tu nueva bicicleta.
      </p>
      <footer>
        <Button onClick={onFinish} size="small" className={styles.button}>
          ¡ADELANTE!
        </Button>
      </footer>
    </Modal>
  );
}
