import { useRef, useState } from 'react';
import styles from './TrainingClassGenreTagsList.module.scss';
import TagVisibleAware from '../TagsList/TagVisibleAware';
import IconMusic from './IconMusic';

interface TrainingClassGenreTagsListProps {
  genreTagsList: TrainingGenres[];
  genreIdFiltered?: number;
}

export default function TrainingClassGenreTagsList({
  genreTagsList,
  genreIdFiltered,
}: TrainingClassGenreTagsListProps) {
  const ref = useRef(null);

  const [visibleTags, setVisibleTags] = useState(new Array(genreTagsList.length).fill(1));
  const [lastVisible, setLastVisible] = useState(visibleTags.lastIndexOf(1));

  const calculatePercent: (value: number) => number = (value: number) => {
    let total = 0;
    trainingGenresOrdered.forEach(genre => {
      total += genre.value;
    });
    const percent = Math.min((value / total) * 100);
    return Math.round(percent);
  };

  const filteredTrainingGenre = genreTagsList.find(element => element.genre_id === genreIdFiltered);
  const trainingGenresOrdered = [
    ...(filteredTrainingGenre ? [filteredTrainingGenre] : []),
    ...genreTagsList.filter(e => e.genre_id !== genreIdFiltered),
  ];

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.iconMusic}>
        <IconMusic />
      </div>
      <div className={styles.containerTagsList}>
        {trainingGenresOrdered.map((genre, index) => {
          return (
            <div key={index}>
              <TagVisibleAware
                visible={visibleTags}
                setVisible={setVisibleTags}
                refParent={ref}
                index={index}
                setLastVisible={setLastVisible}
                plusNumberVisible={index === lastVisible}
                plusNameTagNotVisible={trainingGenresOrdered.filter((_, i) => visibleTags[i] === 0)}
                plusNumber={trainingGenresOrdered.length - (index + 1)}
                variant="normal-style"
                calculatePercent={calculatePercent}
              >
                <div>{genre.name}</div>·<div>{calculatePercent(genre.value)}%</div>
              </TagVisibleAware>
            </div>
          );
        })}
      </div>
    </div>
  );
}
