import EventEmitter from 'events';

class HeartRate extends EventEmitter {
  constructor(obj) {
    super();
    this.bluetoothDevice = null;
    this.characteristic = null;
    this.currentValue = -1;
    this.currentStatus = 'Sin pulsómetro vinculado';
    this.mode = obj ? obj.mode : 'fcm';
    this.customPPM = obj ? obj.customPPM : null;
    this.fcMax = obj ? parseInt(obj.fcMax) : null;
    this.age = obj ? parseInt(obj.age) : 30;
    this.basal = obj ? parseInt(obj.basal) : 75;
    this.gender = obj ? parseInt(obj.gender) : 1;
    this.active = false;
    this.lastUpdate = new Date().getTime() / 1000;
    this.lastInterval = 0;
    this.progression = [];
    this.fcm = null;
    this.fcr = null;
    this.userHealthModel = null;
  }

  // See https://developers.google.com/web/updates/2015/07/interact-with-ble-devices-on-the-web
  init() {
    var that = this;

    if (navigator.bluetooth) {
      if (that.bluetoothDevice == null) {
        navigator.bluetooth
          .requestDevice({ filters: [{ services: ['0000180d-0000-1000-8000-00805f9b34fb'] }] })
          .then(device => {
            that.bluetoothDevice = device;
            that.doEmit('Conectando dispositivo (1 de 4)');
            device.addEventListener('gattserverdisconnected', that.onDisconnected.bind(that));
            return device.gatt.connect();
          })
          .then(server => {
            that.doEmit('Obteniendo servicio (2 de 4)');
            return server.getPrimaryService('heart_rate');
          })
          .then(services => {
            that.doEmit('Obteniendo características (3 de 4)');
            return services.getCharacteristic('heart_rate_measurement');
          })
          .then(characteristic => {
            that.active = true;
            that.doEmit(that.bluetoothDevice.name + ' conectado');
            that.emit('connected');
            that.characteristic = characteristic;
            that.activeInterval();
            characteristic.addEventListener(
              'characteristicvaluechanged',
              that.handleCharacteristicValueChanged.bind(that)
            );
            that.initModel();
            return characteristic.startNotifications();
          })
          .catch(error => {
            that.bluetoothDevice = null;
            that.currentValue = 0;
            that.active = false;
            that.userHealthModel = null;
            that.doEmit(error);
            that.doEmit('Sin pulsómetro vinculado');
          });
      } else {
        that.bluetoothDevice.gatt.disconnect();
        that.bluetoothDevice.addEventListener(
          'gattserverdisconnected',
          that.onDisconnected.bind(that)
        );
        that.bluetoothDevice.gatt
          .connect()
          .then(server => {
            that.doEmit('Obteniendo servicio (1 de 3)');
            return server.getPrimaryService('heart_rate');
          })
          .then(services => {
            that.doEmit('Obteniendo características (2 de 3)');
            return services.getCharacteristic('heart_rate_measurement');
          })
          .then(characteristic => {
            that.active = true;
            that.doEmit(that.bluetoothDevice.name + ' conectado');
            that.characteristic = characteristic;
            that.activeInterval();
            that.configure();
            characteristic.addEventListener(
              'characteristicvaluechanged',
              that.handleCharacteristicValueChanged.bind(that)
            );
            that.initModel();
            return characteristic.startNotifications();
          })
          .catch(error => {
            that.active = false;
            that.bluetoothDevice = null;
            that.currentValue = 0;
            that.userHealthModel = null;
            that.doEmit(error);
            that.doEmit('Sin pulsómetro vinculado');
            that.init();
          });
      }
    } else {
      that.doEmit('No se ha encontrado conexión Bluetooth en el sistema.');
    }
  }
  configure() {
    this.configureFcm();
    this.configureFcr();
  }

  onDisconnected() {
    this.active = false;
    this.doEmit(
      'Intentando reconectar con ' +
        (this.bluetoothDevice != null ? this.bluetoothDevice.name : 'Bluetooth')
    );
    this.init();
  }

  handleCharacteristicValueChanged(event) {
    let value = this.parseHeartRate(event.target.value);
    this.currentValue = value.heartRate;
    this.lastUpdate = new Date().getTime() / 1000;
    console.log('Pulsaciones: ', this.currentValue, this.getPercent() + '%');
    this.emit('characteristicvaluechanged', this.currentValue);
  }

  // See https://github.com/WebBluetoothCG/demos/blob/gh-pages/heart-rate-sensor/heartRateSensor.js
  parseHeartRate(value) {
    let flags = value.getUint8(0);
    let rate16Bits = flags & 0x1;
    let contactDetected = flags & 0x2;
    let contactSensorPresent = flags & 0x4;
    let energyPresent = flags & 0x8;
    let rrIntervalPresent = flags & 0x10;
    let result = {};
    let index = 1;
    if (rate16Bits) {
      result.heartRate = value.getUint16(index, true);
      index += 2;
    } else {
      result.heartRate = value.getUint8(index);
      index += 1;
    }
    if (contactSensorPresent) {
      result.contactDetected = !!contactDetected;
    }
    if (energyPresent) {
      result.energyExpended = value.getUint16(index, true);
      index += 2;
    }
    if (rrIntervalPresent) {
      let rrIntervals = [];
      for (; index + 1 < value.byteLength; index += 2) {
        rrIntervals.push(value.getUint16(index, true));
      }
      result.rrIntervals = rrIntervals;
    }
    return result;
  }

  doEmit(msg) {
    this.currentStatus = msg;
    this.emit('event');
  }
  configureFcm() {
    var fcMax = 180;

    if (this.fcMax) {
      fcMax = this.fcMax;
    } else if (this.age && this.gender) {
      if (this.gender == 1) {
        fcMax = Math.floor(209 - 0.7 * this.age);
      } else {
        fcMax = Math.floor(214 - 0.8 * this.age);
      }
    }
    this.fcm = fcMax;
  }
  configureFcr() {
    this.fcr = Math.round(this.fcm - parseInt(this.basal), 0);
  }
  getPercent() {
    if (this.customPPM) {
      return this.percentageCustomFromBPM(this.currentValue);
    } else if (this.mode != 'fcr') {
      return this.percentageMaxFromBPM(this.currentValue);
    } else {
      let fcb = this.basal;
      let fcm = this.fcm;
      return parseInt(((this.currentValue - fcb) / (fcm - fcb)) * 100);
    }
  }
  currentPercentage() {
    return this.getPercent();
  }

  // Obtenemos el porcentaje de los valores personalizados
  percentageCustomFromBPM(bpm) {
    var that = this;
    var percent = 0;
    var customPPM = this.customPPM;
    // Calculamos el porcentaje exacto que corresponde a la los latidos
    var scalePercentBPM = function (zone, bpmArg) {
      var init = i * 10 + 50; //Inicio de zona
      return (
        init + Math.round((10 * (bpmArg - customPPM[i][0])) / (customPPM[i][1] - customPPM[i][0]))
      );
    }; // Si es menor que el minimo
    if (bpm < customPPM[0][1]) {
      percent = (bpm * 50) / customPPM[0][0];
    } else if (bpm > customPPM[4][2]) {
      percent = (bpm * 100) / customPPM[4][1];
    } else {
      for (var i = 0; i < customPPM.length; i++) {
        if (bpm >= customPPM[i][0] && bpm <= customPPM[i][1]) {
          percent = scalePercentBPM(i, bpm);
          // console.log('para '+bpm+' tenemos de percent '+percent)
          break;
        }
      }
    }
    return percent;
  }
  // Obtenemos el porcentaje para el modo fcm
  percentageMaxFromBPM(bpm) {
    // Utilizamos la fcm
    return (bpm / this.fcm) * 100;
  }
  getPercentMaxMin() {
    var value = this.getPercent();

    if (this.mode == 'fcr') {
      if (value < 40) {
        value = 40;
      } else if (value > 100) {
        value = 100;
      }
    } else {
      if (value < 50) {
        value = 50;
      } else if (value > 100) {
        value = 100;
      }
    }
    return value;
  }

  getZoneMaxMin() {
    var value = this.getPercent();
    if (this.mode == 'fcr') {
      if (value < 40) {
        value = 40;
      } else if (value > 100) {
        value = 100;
      }
    } else {
      if (value < 50) {
        value = 50;
      } else if (value > 100) {
        value = 100;
      }
    }
    return parseInt((value - 50) / 10);
  }

  activeInterval() {
    var that = this;
    clearInterval(that.lastInterval);
    that.lastInterval = setInterval(function () {
      that.checkValidate();
    }, 3000);
  }

  clear() {
    var that = this;
    if (this.bluetoothDevice != null) {
      clearInterval(this.lastInterval);
      this.characteristic.removeEventListener(
        'characteristicvaluechanged',
        that.handleCharacteristicValueChanged.bind(that)
      );
      this.bluetoothDevice.removeEventListener(
        'gattserverdisconnected',
        that.onDisconnected.bind(that)
      );
      this.bluetoothDevice.gatt.disconnect();
      this.bluetoothDevice = null;
      this.currentValue = -1;
      this.currentStatus = 'Sin pulsómetro vinculado';
      this.active = false;
      this.lastUpdate = new Date().getTime() / 1000;
      this.emit('event');
    }
  }

  checkValidate() {
    var that = this;
    if (that.characteristic) {
      var current = new Date().getTime() / 1000;
      var diff = current - this.lastUpdate;
      var valueh = that.characteristic.value;
      if (valueh) {
        // Comprobamos que se ha actualizado hace poco
        if (diff > 20) {
          this.lastUpdate = current;
          this.init();
        }
      }
    }
  }

  getPercentByBmp(bmp) {
    this.currentValue = bmp;
    this.lastUpdate = new Date().getTime() / 1000;
  }
  getScaledPercent() {
    var scaledPercent = this.getScaledPercentMode();

    return scaledPercent(this.getPercent());
  }
  // Devolvemos la funcion que escala el porcentaje con el que se va a mostrar
  getScaledPercentMode() {
    var scaledPercent = d3.scaleLinear().domain([50, 100]).range([50, 100]);
    if (this.mode == 'fcr') {
      scaledPercent = d3.scaleLinear().domain([40, 50, 63, 75, 85]).range([50, 60, 70, 80, 90]);
    }
    return scaledPercent;
  }

  //Devolvemos los datos de configuración y progresion para guardarlos en el servidor

  getParsedProgression() {
    return JSON.stringify(this.getCompletedProgression());
  }

  getCompletedProgression() {
    var progression = [];
    if (this.userHealthModel) {
      progression = this.userHealthModel.getProgression();
    }

    var objectParsed = {
      fcr: this.fcr,
      fcb: this.basal,
      fcm: this.fcm,
      mode: this.mode,
      progression: progression,
    };

    return objectParsed;
  }

  unsetProgression() {
    this.progression = [];
    if (this.userHealthModel) {
      this.userHealthModel.set('progression', []);
    }
  }

  /**
   * Devuelve si la progresión tiene datos del usuario.
   * @returns {boolean} Si true, la progresión de usuario tiene datos.
   */
  hasUserData() {
    return this.progression.length > 0;
  }
  getOptions() {
    var options = {
      mode: this.mode,
      fcm: this.fcm,
      customPPM: this.customPPM,
      fcb: this.basal,
      fcr: this.fcr,
      progression: this.progression,
    };
    return options;
  }
  initModel() {
    if (BVO && BVO.HeartRate) {
      console.log('iniciamos el modelo con estos valores ');
      console.log(this.getOptions());
      this.userHealthModel = new BVO.HeartRate(this.getOptions());
    }
  }
  getModel() {
    if (this.userHealthModel !== undefined) {
      return this.userHealthModel;
    }
  }
  getProgression() {
    var progression = {};
    if (this.userHealthModel !== undefined) {
      progression = this.userHealthModel.get('progression');
    }
    return progression;
  }
}
export default HeartRate;
