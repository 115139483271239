import { useForm } from 'react-hook-form';
import Avatar from '../Avatar';
import useCreateComment from './useCreateComment';
import styles from './Form.module.scss';
import Button from '../UI/Button';
import LoadingIndicator from '../LoadingIndicator';
import { useState } from 'react';
import CommunityRulesPopup from './CommunityRulesPopup';
import { CommentableType } from '@/core/constants/comments';
import { useNavigate } from 'react-router';

interface CommentInput {
  comment: string;
}

type FormProps = {
  user: IUser;
  commentableId: number | string;
  commentableType: string;
  parentId?: string | null;
  autofocus?: boolean;
  isFree?: boolean;
  onCreate?(): void;
  onCancel?(): void;
};

const Form = ({
  user,
  commentableId,
  commentableType,
  parentId,
  autofocus,
  isFree = false,
  onCreate = () => null,
  onCancel = () => null,
}: FormProps) => {
  const isUncommentable =
    commentableType === CommentableType.TrainingClass && !user.isSubscribed() && !isFree;
  const navigate = useNavigate();

  const [acceptedCommunityRules, setAcceptedCommunityRules] = useState(user.accept_community_rules);
  const [openCommunityRulesPopup, setOpenCommunityRulesPopup] = useState(false);

  const [replyComment, createCommentMutation] = useCreateComment(
    {
      id: '' + commentableId,
      type: commentableType,
      parentId: parentId || null,
    },
    onCreate,
  );

  const { register, handleSubmit, formState, reset, getValues } = useForm<CommentInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  if (createCommentMutation.error) {
    console.info(createCommentMutation.error);
  }

  const onSubmit = async (data: CommentInput) => {
    if (!acceptedCommunityRules) {
      setOpenCommunityRulesPopup(true);
      return;
    }

    replyComment(data);
    reset();
  };

  const handleCancel = (event: React.MouseEvent<Element>) => {
    event.preventDefault();
    reset();
    onCancel();
  };

  const isReply = parentId != null;
  const isCommentEmpty = !formState.dirtyFields.comment;

  if (isReply && createCommentMutation.isLoading)
    return (
      <div className={styles.commentsLoader}>
        <LoadingIndicator />
      </div>
    );

  return (
    <>
      <div className={styles.container}>
        {isUncommentable ? (
          <div className={styles.uncommentableContainer}>
            <p className={styles.content}>
              {`Solo puedes comentar las clases gratuitas de la semana.`}
              <br />
              {`¿Quieres ver las clases
           gratuitas de esta semana?`}
            </p>
            <Button
              variant="primary"
              size="small"
              className={styles.buttonClasses}
              onClick={() => navigate('/trainingclasses/weekly')}
            >
              VER CLASES
            </Button>
          </div>
        ) : (
          <>
            <div className={styles.avatar}>
              <Avatar fullName={user.full_name} url={user.avatar_url} size={50} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <textarea
                {...register('comment', { required: true })}
                placeholder={parentId ? 'Añade tu respuesta...' : 'Añade un comentario...'}
                autoFocus={autofocus}
                className={styles.textarea}
                disabled={formState.isSubmitting}
              ></textarea>

              <div className={styles.containerButtons}>
                <Button
                  disabled={!formState.isValid || formState.isSubmitting}
                  variant="primary"
                  size="small"
                  style={{ borderRadius: '3px' }}
                  type="submit"
                >
                  Enviar
                </Button>
                <Button
                  onClick={handleCancel}
                  disabled={isCommentEmpty}
                  variant="secondary"
                  size="small"
                  style={{ borderRadius: '3px' }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
      <CommunityRulesPopup
        open={openCommunityRulesPopup}
        onAccept={() => {
          setAcceptedCommunityRules(true);
          replyComment({
            comment: getValues('comment'),
          });
          reset();
          setOpenCommunityRulesPopup(false);
        }}
        onCancel={() => {
          setOpenCommunityRulesPopup(false);
        }}
      />
    </>
  );
};

export default Form;
