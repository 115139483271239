import logoRunnersworldBlanco from '../../../img/home/logo-runnersworld--blanco.svg';

import logoMenshealthBlanco from '../../../img/home/logo-menshealth--blanco.svg';

import logoWomenshealthBlanco from '../../../img/home/logo-womenshealth--blanco.svg';

import SettingsModel from './settingsModel';
import Category from '../constants/category';
import calculateFtp from '../classes/Ftms';

const preferencesModel = ({ user, profile, preferences = null }) => {
  const settingsModel = new SettingsModel(user, profile);
  settingsModel.loadLocal();

  if (window.top.top.BTD?.mySettings) {
    // Using settings from top window
    const desktopAttributes = window.top.top.BTD?.mySettings?.getDesktopAttributes();
    settingsModel.set(desktopAttributes);
  }

  settingsModel.setup();

  if (preferences) {
    settingsModel.set(preferences);
    settingsModel.saveLocal();
  }

  const getCurrentColorZones = () => {
    const colorTheme = settingsModel.get('C19');

    return {
      Z1: settingsModel.colorThemes[colorTheme][0],
      Z2: settingsModel.colorThemes[colorTheme][1],
      Z3: settingsModel.colorThemes[colorTheme][2],
      Z4: settingsModel.colorThemes[colorTheme][3],
      Z5: settingsModel.colorThemes[colorTheme][4],
      Z6: settingsModel.colorThemes[colorTheme][5],
      Z7: settingsModel.colorThemes[colorTheme][6],
    };
  };

  const getCardioConfig = (fit_status = 1) => {
    settingsModel.updateCustomRange();

    const basal = settingsModel.getBasal();

    const optimalRange = settingsModel.optimalRange();
    const optimalMin = settingsModel.optimalMin(fit_status);
    const optimalMax = settingsModel.optimalMax(fit_status);
    const percentMin = settingsModel.optimalMinPercent(fit_status);
    const percentMax = settingsModel.optimalMaxPercent(fit_status);

    return {
      basal,
      optimalRange,
      optimalMin,
      optimalMax,
      percentMin,
      percentMax,
    };
  };

  const isHearst = () => {
    return false;
    // if (!user) return false;
    // const tag_list = user.tag_list;
    // return (
    //   tag_list.includes('menshealth') ||
    //   tag_list.includes('womenshealth') ||
    //   tag_list.includes('runnersworld')
    // );
  };

  const getLogoForCategory = category_nr => {
    if (!isHearst()) {
      return null;
    }

    if (category_nr == Category.Running) {
      return logoRunnersworldBlanco;
    }

    if (category_nr == Category.Training && user.sex == 'm') {
      return logoMenshealthBlanco;
    }

    if (category_nr == Category.Training && user.sex == 'f') {
      return logoWomenshealthBlanco;
    }

    return null;
  };

  const fit_status = profile?.fit_status;

  const getFtp = () => {
    if (profile?.ftp) {
      return profile.ftp;
    }

    if (profile?.weight != null && profile?.fit_status != null && user?.sex != null) {
      return calculateFtp.get(profile.weight, profile.fit_status, user.sex);
    }

    return 100;
  };

  const getCalculatedFtp = () => {
    if (profile?.weight != null && profile?.fit_status != null && user?.sex != null) {
      return calculateFtp.get(profile.weight, profile.fit_status, user.sex);
    }

    return 100;
  };

  const model = {
    ...settingsModel.toJSON(),
    user,
    profile,
    ...getCurrentColorZones(),
    logoGyms: settingsModel.getLogoGyms(),

    playerPreferences: settingsModel.getPlayerOptions(),
    isSetShowLogo: settingsModel.isSetShowLogo,
    getLogoForCategory,
    isHearst,
    getFtp,
    getCalculatedFtp,
    heartRate: settingsModel.heartRate(),
    isSetConfigBluetooth: settingsModel.isSetConfigBluetooth(),
    defaultRoom: settingsModel.getDefaultRoom(),

    // Cardio
    ...getCardioConfig(fit_status),
    ppm: settingsModel.getPPM(),
    wattZones: settingsModel.getWattZones(),

    hasCustomZonesSettings: settingsModel.hasCustomZonesSettings(),
  };

  return model;
};

export default preferencesModel;
