/// Shows a modal to warn the user that an update is downloading and the app will be restarted

import { useEffect, useState } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import ProgressBar from '../ProgressBar/ProgressBar';
import Button from '../UI/Button';
import Modal from './Modal';
import ModalActions from './ModalActions';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

import styles from './UpdateModal.module.scss'

export default function UpdateModal({ forceOpen = false }: { forceOpen?: boolean }) {
  const [percentage, setPercentage] = useState(0);
  const [installing, setInstalling] = useState(false);
  const [error, setError] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    if (!window.electronAPI || !window.electronAPI?.handleUpdaterEvent) return;
    window.electronAPI?.handleUpdaterEvent((_, updaterEv) => {
      switch (updaterEv.type) {
        case 'update_found':
          setVersion(updaterEv.version);
          break;
        case 'update_downloading':
          setPercentage(updaterEv.progress);
          break;
        case 'update_installing':
          setInstalling(true);
          break;
        case 'update_error':
          setError(updaterEv.error);
          break;
        case 'update_downloaded':
          console.log('Update downloaded');
          break;
        default:
          // @ts-ignore
          console.error('Unknown updater event type: ' + updaterEv.type);
      }
    });
  }, []);

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setTimeout(() => setDisabled(false), 7000);
  }, []);

  return (
    <>
      <Modal isOpen={!!version} style={{ maxWidth: '600px', gap: '15px' }} zIndex={9999}>
        <ModalHeader>
          <h2>{error ? 'Ha ocurrido un error' : `Actualizando a la versión ${version}`}</h2>
        </ModalHeader>
        <ModalBody className={styles.body}>
          {!installing && !error && (
            <>
              <p>
                Para poder seguir utilizando la aplicación de Bestcycling se necesita actualizar a
                una nueva versión. Esto tardará unos segundos. Tras este proceso, la aplicación se
                reiniciará automáticamente.
              </p>
              <div style={{ color: 'white', padding: '1em' }}>
                <ProgressBar
                  color="#ff7900"
                  percent={percentage}
                  showPercent={true}
                  striped={percentage < 10}
                />
              </div>
            </>
          )}
          {installing && !error && (
            <>
              <p>Ya falta poco, la aplicación se está instalando. En breves se reiniciará.</p>
              <LoadingIndicator />
            </>
          )}
          {error && (
            <>
              <p>
                La actualización no se ha podido completar debido a un problema. Se reintentará el
                proceso la próxima vez que se inicie la aplicación.
              </p>
              <p>
                Si el error persiste puedes ponerte en contacto con nosotros a través del correo{' '}
                <a href="mailto:info@bestcycling.es">info@bestcycling.es.</a>
              </p>
            </>
          )}
        </ModalBody>
        {!forceOpen && error && (
          <ModalActions>
            <Button onClick={() => setVersion('')} size="default">
              SALIR
            </Button>
          </ModalActions>
        )}
      </Modal>
    </>
  );
}
