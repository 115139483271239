import { queryClient, useApi } from '@/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

/**
 * Custom hook: usePostTrainingMutation
 * Post a training to the server
 * @param data TrainingToPost | {trainingToPost: TrainingToPost, trainingClass: TrainingClass} if trainingClass is provided, the update will be done optimistically
 */
const usePostTrainingMutation = () => {
  const { mutateApi, client } = useApi();

  return useMutation(
    async (
      data: TrainingToPost | { trainingToPost: TrainingToPost; trainingClass: TrainingClass },
    ) => {
      let trainingToPost: TrainingToPost;

      if ('trainingToPost' in data) {
        trainingToPost = data.trainingToPost;
      } else {
        trainingToPost = data as TrainingToPost;
      }

      const res = await mutateApi(['trainings'], trainingToPost, {
        method: 'post',
      });
      if (res.error) throw new Error(res.error);

      return res;
    },
    {
      onMutate: async mutationData => {
        if (!('trainingClass' in mutationData)) return;

        const trainingToPost = mutationData.trainingToPost;
        const trainingClass = mutationData.trainingClass;

        // OPTIMISTIC UPDATE
        const trainingsFromQueries = queryClient.getQueriesData(['trainings']);

        trainingsFromQueries.forEach((query: any) => {
          const [key, data] = query as QueryData<Training>;

          if (!data) return;

          if ('pages' in data) {
            data.pages[0]?.data.unshift([
              fromTrainingToPostToTraining(trainingToPost, trainingClass),
            ]);

            return queryClient.setQueryData(key, structuredClone(data));
          }

          if (key.includes(trainingToPost.training_class_id)) {
            return queryClient.setQueryData(key, [
              fromTrainingToPostToTraining(trainingToPost, trainingClass),
              ...data,
            ]);
          }
        });
      },
      onSuccess: () => {
        client.invalidateQueries(['trainings']);
        client.invalidateQueries(['training_classes', 'training_class']);
        client.invalidateQueries(['profile']);
      },
    },
  );
};

export default usePostTrainingMutation;

const fromTrainingToPostToTraining = (
  trainingToPost: TrainingToPost,
  trainingClass: TrainingClass,
): Training => {
  const training: Training = {
    bestcycling_training: {
      guid: trainingToPost.guid,
      has_annotation: true,
      has_gps: false,
      has_heart_rate: !!trainingToPost.heart_rate,
      has_ftms: !!trainingToPost.ftms,
      id: trainingToPost.guid,
    },
    guid: trainingToPost.guid,
    id: trainingToPost.guid,
    kcal: trainingToPost.kcal as number,
    km: null,
    location_name: null,
    mean_frequency: trainingToPost.mean_frequency,
    seconds: trainingClass.duration_seconds,
    // @ts-ignore full trainer object is not needed
    trainer: {
      full_name: trainingToPost.trainer_name!,
      id: trainingToPost.trainer_id! as string,
    },
    training_class: trainingClass,
    training_class_title_cache: trainingClass.subtitle || trainingClass.short_title || '',
    training_class_date: trainingToPost.training_date,
    training_date_timestamp: dayjs(trainingToPost.training_date).unix(),
    type_graph: trainingToPost.type_graph,
    training_date: trainingToPost.training_date,
    training_data: {
      heart_rate: trainingToPost.heart_rate!,
      gps: '',
      ftms: trainingToPost.ftms!,
      annotation: trainingToPost.annotation,
    },
  };

  return training;
};
