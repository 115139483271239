import styles from './ShareButton.module.scss';
import iconShare from '@/img/share.svg';
import { useEffect } from 'react';
import { useAnalyticsContext } from '@/contexts/analytics.context';
import ANALYTICS_EVENTS from '@/constants/analytics_events';
import { useToast } from '@/contexts/toast.context';
import useShare from '@/core/hooks/useShare';
import { getAnalytics, logEvent } from 'firebase/analytics';

type ShareButtonProps = {
  model: ShareModel;
};

const ShareButton = ({ model }: ShareButtonProps) => {
  const { showToast } = useToast();
  const analytics = getAnalytics();
  const share = useShare();

  const loadDynamicLink = () => {
    share.loading();

    const source = 'webapp';
    const medium = 'Receta';
    const campaign = 'compartirReceta';

    fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCWZheoGbIvvriJbmVN1A6k0KyiUcctyvg',
      {
        method: 'POST',
        mode: 'cors',

        body: JSON.stringify({
          suffix: {
            option: 'SHORT',
          },
          dynamicLinkInfo: {
            domainUriPrefix: 'https://bestlife.page.link',
            link: model.getLongLink(),
            iosInfo: {
              iosBundleId: 'es.bestcycling.bestlife',
              iosAppStoreId: '1275051011',
              iosMinimumVersion: '2.1.6',
            },
            androidInfo: {
              androidPackageName: 'es.bestcycling.life',
              androidMinPackageVersionCode: '167',
            },
            analyticsInfo: {
              googlePlayAnalytics: {
                utmSource: source,
                utmMedium: medium,
                utmCampaign: campaign,
              },
            },
            socialMetaTagInfo: {
              socialImageLink: `${model.getImage()}`,
              socialTitle: model.getTitle(),
              socialDescription: model.getDescription(),
            },
          },
        }),
      },
    )
      .then(response => response.json())
      .then(({ shortLink }) => {
        share.success(shortLink || model.getShortLink());
      })
      .catch(error => {
        share.failed(error);
      });
  };

  const copyRecipe = (linkProp: string) => {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = model.getMessage(linkProp);

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const success = document.execCommand('copy');
      if (success) {
        logEvent(analytics, ANALYTICS_EVENTS.SHARE_INVITE_CODE);
        model.sendFirebaseEvents();
        showToast('El enlace se ha copiado en el portapapeles', 'success');
      }
    } catch (err) {
      showToast('No se ha podido copiar el enlace', 'error');
      console.info('No se ha podido copiar el elemento en el portapapeles.');
    }

    document.body.removeChild(textArea);
  };

  useEffect(() => {
    loadDynamicLink();
  }, []);

  return (
    <button
      className={`${styles.share}`}
      disabled={share.isLoading}
      onClick={() => copyRecipe(share.link)}
    >
      <img className={styles.icon} src={iconShare} />
    </button>
  );
};

export default ShareButton;
