import { useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { v5 as uuidv5 } from 'uuid';
import { useAppContext, usePreferences } from '../../contexts/app.context';
import { useAuth } from '../../contexts/auth.context';
import { messagesIntensity, messagesGeneral } from '../../constants/annotation';
import styles from './AnnotateModal.module.scss';
import styleButtom from '../UI/Button/Button.module.scss';
import Category from '../../constants/category';
import { GraphType } from '@/core/constants/graphFactory';
import GradientSlider from '../UI/GradientSlider';
import { FormInput } from '../FormControls';
import usePostTrainingMutation from '@/core/hooks/apiHooks/usePostTraining';
import { useDownloads } from '@/core/contexts/downloads.context';

const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341'; // ??

export default function AnnotateModal({
  trainingClass,
  dataTraining,
  isOpen,
  onClose,
  fromPlayer = false,
  goResume,
}: {
  trainingClass: TrainingClass;
  dataTraining?: DataTraining;
  isOpen: boolean;
  onClose: () => void;
  fromPlayer?: boolean;
  goResume: () => void;
}) {
  const { state: preferences } = usePreferences();
  const {
    state: { accessToken },
  } = useAuth();
  const {
    state: { user, profile },
  } = useAppContext();

  const { updateTrainingClass } = useDownloads();

  const { register, handleSubmit, setValue } = useForm();

  const saveTrainings = usePostTrainingMutation();

  const [ratting, setRating] = useState(5);
  const [difficulty, setDifficulty] = useState(5);

  const [guid] = useState(
    uuidv5(`${Date.now()}-${accessToken}-${user?.id}-${profile?.id}`, MY_NAMESPACE),
  );

  const handleChangeRating = (value: number) => {
    if (value >= 1 && value <= 10) {
      setRating(value);
    }
  };

  const handleChangeDifficulty = (value: number) => {
    if (value >= 1 && value <= 10) {
      setDifficulty(value);
    }
  };

  const getTrainingClass = () => {
    if (dataTraining) return dataTraining.trainingClass;
    return trainingClass;
  };

  const onSubmit = async (data: any) => {
    const dataToSend: TrainingToPost = {
      ...formData,
      training_date: `${data.date} ${data.time}`,
      kcal: data.kcal,
      mean_frequency: data.frequency,
    };

    await saveTrainings.mutateAsync({ trainingToPost: dataToSend, trainingClass: trainingClass });

    localStorage.setItem('trainingClassUpdatedId', dataToSend.training_class_id);
    localStorage.setItem('trainingClassGuid', dataToSend.guid);

    if (dataToSend.seconds) {
      updateTrainingClass({
        ...trainingClass,
        training: {
          seconds: dataToSend.seconds,
        },
      });
    }

    if (fromPlayer) goResume();
    else onClose();
  };

  const defaultDate = useMemo(
    () =>
      (dataTraining?.trainingDate ? dayjs(dataTraining.trainingDate) : dayjs()).format(
        'YYYY-MM-DD',
      ),
    [],
  );
  const defaultTime = useMemo(
    () => (dataTraining?.trainingDate ? dayjs(dataTraining.trainingDate) : dayjs()).format('HH:mm'),
    [],
  );
  const durationClass = useMemo(
    () => parseInt(dataTraining?.seconds ?? '0', 10) || trainingClass.duration_training,
    [],
  );
  const percentClass = useMemo(() => {
    const seconds = parseInt(dataTraining?.seconds ?? '0', 10);
    const percent =
      (seconds / (trainingClass.duration_training ?? trainingClass.duration_seconds)) * 100;
    return parseFloat(percent.toFixed(2));
  }, []);

  const defaultKcal = useMemo(() => {
    const kcal =
      getTrainingClass().category_nr !== 21
        ? parseInt(
            dataTraining?.kcal ??
              Math.ceil(((trainingClass.kcal ?? 0) * percentClass) / 100).toString(),
            10,
          )
        : '';
    return kcal;
  }, []);
  const formData = useMemo(() => {
    const data: TrainingToPost = {
      guid: guid,
      training_class_id: trainingClass.id.toString(),
      category_nr: trainingClass.category_nr,
      delete: false,
      done: true,
      training_date: dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
      kcal: defaultKcal.toString(),
      seconds: durationClass,
      short_title: trainingClass.short_title,
      subtitle: trainingClass.subtitle,
      trainer_name: trainingClass.trainer?.full_name,
      trainer_id: trainingClass.trainer?.id.toString(),
      trainer_rating: 5,
      training_class_rating: ratting,
      difficulty_rating: difficulty,
      heart_rate: dataTraining?.heart_rate,
      ftms: dataTraining?.ftms,
      mean_frequency: null,
      type_graph:
        trainingClass.category_nr == Category.Cycling
          ? preferences.graph_type == GraphType.Watts
            ? 1
            : 0
          : 0,
      annotation: dataTraining?.annotation,
    };

    return data;
  }, [guid, trainingClass, dataTraining, preferences, difficulty, ratting]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      closeModalOnBackgroundClick={false}
    >
      <div className={styles.title}>Anotar resultado</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <li>
            <div className={`${styles.input_container} ${styles.configuration}`}>
              <p>Día</p>

              <label htmlFor="date">
                <FormInput
                  {...register('date')}
                  required={true}
                  type="date"
                  title="Fecha"
                  defaultValue={defaultDate}
                  onChange={(e: any) => setValue('date', e.target.value, { shouldValidate: true })}
                />
              </label>
            </div>
          </li>
          <li>
            <div className={`${styles.input_container} ${styles.configuration}`}>
              <p>Hora</p>

              <label htmlFor="time">
                <FormInput
                  {...register('time')}
                  required={true}
                  step="1"
                  type="time"
                  defaultValue={defaultTime}
                  onChange={(e: any) => setValue('time', e.target.value, { shouldValidate: true })}
                />
              </label>
            </div>
          </li>
          <li>
            <div className={`${styles.input_container} ${styles.configuration}`}>
              <p>Kcal</p>

              <label htmlFor="kcal">
                <FormInput
                  {...register('kcal')}
                  type="number"
                  defaultValue={defaultKcal}
                  onChange={(e: any) => setValue('kcal', e.target.value, { shouldValidate: true })}
                />
              </label>
            </div>
          </li>
          <li>
            <div className={`${styles.input_container} ${styles.configuration}`}>
              <p>Frecuencía media</p>

              <label htmlFor="weight">
                <FormInput
                  {...(register('frequency'), { min: 40, max: 250 })}
                  type="number"
                  defaultValue={null}
                  onChange={(e: any) =>
                    setValue('frequency', e.target.value, { shouldValidate: true })
                  }
                />
              </label>
            </div>
          </li>

          <li className={styles.slider}>
            <h1> Intensidad</h1>
            <GradientSlider
              onChange={handleChangeDifficulty}
              steps={10}
              startColor={'#609A08'}
              endColor={'#F53B3D'}
            />
            <span className={styles.sliderDescription}>{messagesIntensity[difficulty]}</span>
          </li>
          <li className={styles.slider}>
            <h1> Valoración general</h1>
            <GradientSlider
              onChange={handleChangeRating}
              steps={10}
              startColor={'#F53B3D'}
              endColor={'#609A08'}
            />
            <span className={styles.sliderDescription}>{messagesGeneral[ratting]}</span>
          </li>
        </ul>

        <button
          style={{ margin: '40px auto 0 auto', clear: 'both' }}
          type="submit"
          className={`${styleButtom.button} ${styleButtom.primary}`}
          disabled={formData.training_date == null || saveTrainings.isLoading}
        >
          ANOTAR RESULTADO
        </button>
      </form>
    </Modal>
  );
}
