import { useState } from 'react';
import styles from './ProfileConfig.module.scss';

const ACTIVITIES = [
  { id: 1, name: 'Ciclismo Indoor', groupName: 'Resistencia' },
  { id: 2, name: 'Correr', groupName: 'Resistencia' },
  { id: 3, name: 'Elíptica', groupName: 'Resistencia' },
  { id: 4, name: 'Alta Intensidad HIIT', groupName: 'Fuerza' },
  { id: 5, name: 'Tonificación', groupName: 'Fuerza' },
  { id: 6, name: 'Yoga', groupName: 'Estiramientos' },
  { id: 7, name: 'Pilates', groupName: 'Estiramientos' },
  { id: 8, name: 'Flexibilidad', groupName: 'Estiramientos' },
  { id: 9, name: 'Meditación y mindfulness', groupName: 'Mente' },
];

function Activity({ id, title, color, checked, onChange }) {
  return (
    <div key={id} className={styles.activitiesActivity}>
      <input
        id={`activity_${id}`}
        type="checkbox"
        value={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={`activity_${id}`} className={styles[color]}>
        <p>{title}</p>
      </label>
    </div>
  );
}

function ActivitiesGroup({ groupName, activities = [], color, onChange }) {
  return (
    <div className={styles.activitiesGroupContainer}>
      <h2 className={styles.activitiesGroupName}>{groupName}</h2>
      <div className={styles.activitiesGroup}>
        {ACTIVITIES.filter(a => a.groupName == groupName).map(({ id, name }) => (
          <Activity
            key={id}
            id={id}
            title={name}
            color={color}
            checked={activities.includes(id)}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}

export default function ProfileConfig4({ profile, onSubmit }) {
  const [trainingActivities, setTrainingActivities] = useState(
    profile.training_activities.map(v => +v)
  );

  const onSubmitForm = evt => {
    evt.preventDefault();
    onSubmit({ profile: { training_activities: [...trainingActivities] } });
  };

  const onChange = evt => {
    let values = [...trainingActivities];
    const targetValue = +evt.target.value;

    if (evt.target.checked) {
      values = [...values, targetValue];
    } else {
      values = values.filter(v => v != targetValue);
    }

    setTrainingActivities(values.slice().sort());
  };

  const isFormValid = trainingActivities.length > 0;

  return (
    <>
      <div className={styles.title}>ACTIVIDADES PREFERIDAS</div>

      <div className={styles.activitiesGroups}>
        <form onSubmit={onSubmitForm}>
          <ActivitiesGroup
            groupName="Resistencia"
            activities={trainingActivities}
            color="resistance"
            onChange={onChange}
          />
          <ActivitiesGroup
            groupName="Fuerza"
            activities={trainingActivities}
            color="force"
            onChange={onChange}
          />
          <ActivitiesGroup
            groupName="Estiramientos"
            activities={trainingActivities}
            color="flexibility"
            onChange={onChange}
          />
          <ActivitiesGroup
            groupName="Mente"
            activities={trainingActivities}
            color="mind"
            onChange={onChange}
          />
          <button className={styles.button} disabled={!isFormValid}>
            Continuar
          </button>
        </form>
      </div>
    </>
  );
}
