import { useShell } from '@/contexts/shell.context';
import { useEffect, useRef, useState } from 'react';

const useRegisterServicerWorkerElectron = () => {
  const workerBroadcast = window.BroadcastChannel && new BroadcastChannel('workerChannel');

  const {
    state: { electron },
  } = useShell();

  const isAlreadyInstalled = localStorage.getItem('workerInstalled') === 'true';
  const [workerInstalled, setWorkerInstalled] = useState(!electron || isAlreadyInstalled);
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(progress);
  const workerInstalledRef = useRef(workerInstalled);
  const timeout = 240000;

  useEffect(() => {
    workerInstalledRef.current = workerInstalled;
  }, [workerInstalled]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    if (electron && navigator.serviceWorker.controller?.state == 'activated') {
      console.log('Service worker already installed');
      setWorkerInstalled(true);
      localStorage.setItem('workerInstalled', 'true');
    }

    if (window.electronAPI?.notifyWorkerInstalled) {
      workerBroadcast.onmessage = event => {
        if (event.data === '@worker:user') {
          window.electronAPI?.notifyWorkerInstalled();
        }
      };
    }
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (electron && !workerInstalled) {
      workerBroadcast.onmessage = event => {
        if (event.data === '@worker:installed') {
          setWorkerInstalled(true);
          localStorage.setItem('workerInstalled', 'true');
        }

        if (event.data === '@worker:static') {
          const addedProgress = (1 / 400) * 100; // 400 files
          setProgress(progressRef.current + addedProgress);
        }
      };

      timeoutId = setTimeout(() => {
        if (!workerInstalledRef.current) setWorkerInstalled(true);
      }, timeout);
    }

    return () => {
      if (electron) clearTimeout(timeoutId);
    };
  }, []);

  return { installed: workerInstalled, progress: progress };
};

export default useRegisterServicerWorkerElectron;
