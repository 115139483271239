import SectionTitle from '../SectionTitle';
import NutritrionCarousel from '../Nutrition/NutritionCarousel';

type NutritionSectionProps = {
  title: string;
};

const NutritionSection = ({ title = 'Nutrición' }: NutritionSectionProps) => {
  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <NutritrionCarousel />
    </>
  );
};

export default NutritionSection;
