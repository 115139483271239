import { Link } from 'react-router-dom';
import styles from './SectionTitle.module.scss';
import clsx from 'clsx';

export default function SectionTitle({ to = '', children, ...props }) {
  return to ? (
    <Link className={clsx(styles.link, styles.title)} to={to} {...props}>
      {children}
    </Link>
  ) : (
    <h2 className={styles.title} {...props}>
      {children}
    </h2>
  );
}
