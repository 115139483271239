import checkColor from '@/img/bestpro/myclasses/check-color.svg';
import checkGrey from '@/img/check-grey.svg';
import { TagCircleColor } from '@/contexts/tagscolorlist.types';

import styles from './ColorCircle.module.scss';
import clsx from 'clsx';
import { requiredContrastColors } from '@/core/constants/user_tags';

interface IColorCircleProps {
  color: TagCircleColor;
  selected?: boolean;
  onClick?(): void;
  variant?: 'big' | 'small';
  children?: React.ReactNode;
}

const ColorCircle = ({
  onClick,
  children,
  variant,
  color,
  selected,
}: IColorCircleProps) => {
  const classNames = clsx(
    styles.colorContainer, 
    styles.color, 
    styles[color], 
    variant && styles[variant],
  );

  return (
    <button 
      aria-label={`Color ${color}`}
      onClick={onClick} className={classNames} >
      {selected ? <img alt="" className={styles.check} src={
        requiredContrastColors.includes(color) ?  checkGrey : checkColor } /> : ''}
      {children}
    </button>
  );
};

export default ColorCircle;