import { useState } from 'react';
import dayjs from 'dayjs';
import Avatar from '../Avatar';
import LikesIcon from './LikesIcon';
import RepliesIcon from './RepliesIcon';
import CommentForm from './CommentForm';
import SanitizedHTML from '../SanitizedHTML/SanitizedHTML';
import styles from './Comment.module.scss';

import { useUser } from '@/contexts/app.context';
import { CommentableType } from '@/core/constants/comments';
import clsx from 'clsx';
import { Comment } from '@/core/features/comments/types/comment';

type CommentProps = {
  comment: Comment;
  isFreeTc: boolean;
};

const CommentBox = ({ comment, isFreeTc }: CommentProps) => {
  const { state: user } = useUser();
  const [reply, setReply] = useState(false);
  const isUncommentable =
    comment.commentable_type == CommentableType.TrainingClass && !user.isSubscribed() && !isFreeTc;

  const isPrimary = comment.user.is_staff;

  return (
    <>
      <div
        style={{
          paddingBottom: '1em',
          marginBottom: '1em',
          borderBottom: '1px solid #222',
          display: 'flex',
        }}
      >
        <Avatar
          fullName={comment.user.full_name}
          url={comment.user.avatar_url}
          size={50}
          isPrimary={isPrimary}
        />

        <div style={{ flex: 1, marginLeft: '1em' }}>
          <div>
            <span className={clsx(styles.user_name, isPrimary && styles.primary)}>
              {comment.user?.full_name}
            </span>{' '}
            - <span className={styles.date}>{dayjs(comment.created_at).format('D MMMM YYYY')}</span>
          </div>
          <div className={styles.comments}>
            <SanitizedHTML html={comment.comment} />
          </div>
          <div className={styles.social}>
            <LikesIcon comment={comment} />
            {comment.parent_id ? null : <RepliesIcon comment={comment} />}
            <button
              onClick={() => setReply(true)}
              className={styles.reply}
              disabled={isUncommentable}
            >
              Responder
            </button>
          </div>
        </div>
      </div>

      {reply && (
        <CommentForm
          commentableId={comment.commentable_id}
          commentableType={comment.commentable_type}
          parentId={comment.id}
          user={user}
          autofocus={true}
          onCreate={() => setReply(false)}
          onCancel={() => setReply(false)}
        />
      )}
    </>
  );
};

export default CommentBox;
