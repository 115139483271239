import { useModal } from '@/contexts/modal.context';
import { useEffect } from 'react';
import Button from '../UI/Button';
import styles from '../Modal/Modal.module.scss';

import Modal from './Modal';

export const DesktopModal = () => {
  const { state: modalState, close } = useModal();

  const keyboardListener = (evt: KeyboardEvent) => {
    if (evt.key == 'Escape') {
      close();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyboardListener);
    return () => {
      window.removeEventListener('keyup', keyboardListener);
    };
  }, []);

  return (
    <Modal
      isOpen={modalState.show}
      containerName="optionHeader"
      zIndex={201}
      className={styles.desktopModal}
    >
      <div className={styles.contents}>
        {modalState.title && <h1>{modalState.title}</h1>}
        <p>{modalState.text}</p>
      </div>
      <div className={styles.actionButtons}>
        {modalState.textCancel ? (
          <Button
            variant="secondary"
            size="normal"
            onClick={() => {
              if (modalState.isElectron) window.electronAPI?.modalCancel();
              else modalState.cbCancel();
              close();
            }}
          >
            {modalState.textCancel}
          </Button>
        ) : null}
        <Button
          variant="primary"
          size="normal"
          onClick={() => {
            if (modalState.isElectron) window.electronAPI?.modalOk();
            else modalState.cbOk();
            close();
          }}
        >
          {modalState.textOk}
        </Button>
      </div>
    </Modal>
  );
};
