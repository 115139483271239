import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import CommentsList from '../Comments/CommentsList';
import InfiniteScrollList from '../InfiniteScrollList';

import styles from './Comments.module.scss';
import useComments from '../Comments/useComments';

export default function Comments({ trainingClass }) {
  const { comments, fetchNext, isLoading } = useComments({
    id: trainingClass.id,
    type: 'TrainingClass',
  });

  return (
    <InfiniteScrollList onLastReached={fetchNext}>
      {comments && <CommentsList comments={comments} isFreeTc={trainingClass.free} />}
      {isLoading && (
        <div className={styles.commentsLoader}>
          <LoadingIndicator />
        </div>
      )}
    </InfiniteScrollList>
  );
}
