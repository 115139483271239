// eslint-disable-next-line max-classes-per-file
import { BluetoothDeviceState, BluetoothDeviceTypes, BTStatus } from '@/constants/bluetooth';
import EventEmitter from 'events';

export class HeartRateEmitter extends EventEmitter {
  constructor() {
    super();
  }

  emitHeartRate(heartRate: number) {
    this.emit('characteristicvaluechanged', heartRate);
  }
}

export class BikeDataEmitter extends EventEmitter {
  constructor() {
    super();
  }

  emitPower(power: number) {
    this.emit('powerChange', power);
  }

  emitCadence(cadence: number) {
    this.emit('candeceChange', cadence);
  }

  emitResistance(resistance: number) {
    this.emit('resistanceChange', resistance);
  }

  emitButtonChange(values: any) {
    console.log('apreto el botón ');
    this.emit('buttonChange', values);
  }
}

export interface BTState {
  status: BTStatus;
  bluetoothDevices: Map<string, BTDevice>;
  heartRate?: number | undefined;
  heartRateEmitter: HeartRateEmitter;
  bikeDataEmitter: BikeDataEmitter;
  typeBluetooth: typeBluetooth;
}

export type BTAction =
  | {
      type: 'clean_devices';
    }
  | {
      type: 'bluetooth_state';
      payload: BTStatus;
    }
  | {
      type: 'add_device';
      payload: { device: BTDevice };
    }
  | {
      type: 'remove_device';
      payload: { device: BTDevice };
    }
  | {
      type: 'connect_device';
      payload: { device: BTDevice };
    }
  | {
      type: 'change_status_device';
      payload: { device: BTDevice };
    }
  | {
      type: 'reconnect_device';
      payload: { server: BluetoothRemoteGATTServer };
    }
  | {
      type: 'disconnect_device';
    }
  | {
      type: 'update_heart_rate';
      payload: { device: BTDevice; heartRate: number };
    }
  | {
      type: 'update_bike_data';
      payload: { device: BTDevice; bikeData: Map<string, any> };
    }
  | {
      type: 'set_bluetooth_device_chromium';
      payload: { device: BluetoothDevice; server: BluetoothRemoteGATTServer };
    }
  | {
      type: 'set_type_bluetooth';
      payload: { type: typeBluetooth };
    }
  | {
      type: 'fake_device';
    }
  | {
      type: 'remove_devices';
      payload: { devicesNames: string[] };
    };

export interface IBTContext {
  state: BTState;
  actions: {
    hasNewApi: () => boolean;
    connectDevice: (device: BTDevice) => void;
    getGattServices: () => BluetoothLEScanFilter[];
    disconnectDevice: (device: BTDevice) => void;
    getHeartRateList: () => BTDevice[];
    getBluetoothDevices: (deviceType?: BluetoothDeviceTypes) => BTDevice[];
    startScan: () => void;
    getConnectedDevices: (type?: BluetoothDeviceTypes) => BTDevice[];
    getNoConnectedDevices: (type?: BluetoothDeviceTypes) => BTDevice[];
    getAvaiableDevices: (type?: BluetoothDeviceTypes) => BTDevice[];
    getFeatures: () => Promise<string[]>;
    getLevelRange: () => Promise<Map<number, string> | undefined>;
    isAvailableBluetooth: () => boolean;
    getFirstDeviceConnected: () => BTDevice | undefined;
    isDeviceConnected: (dev: BTDevice) => boolean;
    isBluetoothConnected: () => boolean;
    showBluetoothModalOff: () => void;
    hideBluetoothModalOff: () => void;
    setPowerTarget: (power: number) => void;
    stopPowerTarget: () => void;
    setResistanceTarget: (resistance: number) => void;
    autoMode: (enable: boolean) => void;
    connectDeviceChromium: (device: BluetoothDevice) => void;
    removeNotConnectedDevices: () => void;
  };
  noBluetoothModal: boolean;
  // updateHeartRate: (heartRate: number) => void;
}
