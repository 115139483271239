import dayjs from 'dayjs';
import Avatar from '../Avatar';
import styles from './TrainingClassShare.module.scss';

export interface SharingRequest {
  user: User;
  target: any;
  status: 'accept' | 'reject' | 'pending';
  created_at: number;
}

interface TrainingClassShareProps {
  share: SharingRequest;
}

const TrainingClassShare = ({ share }: TrainingClassShareProps) => {
  const user = share.target;
  const status =
    share.status == 'accept' ? 'Aceptada' : share.status == 'reject' ? 'Rechazada' : 'Pendiente';

  return (
    <div className={styles.share}>
      <Avatar fullName={user.full_name} url={user.avatar_url} size={60} />

      <div className={styles.body}>
        <div className={styles.user_name}>{user?.full_name}</div>
        <div>{dayjs(share.created_at).fromNow()}</div>
        <div>{status}</div>
      </div>
    </div>
  );
};

export default TrainingClassShare;
