import prevPageIcon from '../../../../img/prev-page-1@1x.png';

import nextPageIcon from '../../../../img/next-page-1@1x.png';

import iconBurgerMenu from '../../../../img/icon-burguer-menu@1x.png';

import logoBestLife from '../../../../img/logo-bestlife@1x.png';

import { useLocation, useNavigate } from 'react-router-dom';
import { useNav } from '../../contexts/nav.context';
import { useShellContext } from '../../contexts/shell.context';
import DropdownMenu from './DropdownMenu';
import TopbarNav, { TopbarItem } from './TopbarNav';
import WebLinks from '../../constants/WebLinks';
import './Topbar.scss';
import SubscribeButton from './SubscribeButton';
import { useIsGymUser } from '@/contexts/app.context';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = (evt: React.MouseEvent) => {
    evt.preventDefault();
    navigate(-1);
  };
  return (
    <a href="#" id="top_nav_back" title="Anterior" onClick={handleBack}>
      <img className="previous-page" src={prevPageIcon} />
    </a>
  );
};

const NextButton = () => {
  const navigate = useNavigate();
  const handleNext = (evt: React.MouseEvent) => {
    evt.preventDefault();
    navigate(1);
  };
  return (
    <a href="" id="top_nav_next" title="Siguiente" onClick={handleNext}>
      <img className="next-page" src={nextPageIcon} />
    </a>
  );
};

const MobileMenu = () => {
  const { dispatch } = useNav();
  const handleMenuToggle = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: 'nav-toggle' });
  };
  return (
    <div className="menu-mobile">
      <a href="#" className="menu-mobile-open" onClick={handleMenuToggle}>
        <img className="icon-burguer-menu" src={iconBurgerMenu} />
      </a>
      <a href="#/">
        <img className="logo-bestlife" src={logoBestLife} />
      </a>
    </div>
  );
};

const webLinks = {
  life: {
    title: 'Bestcycling',
    url: '/',
    external: false,
  },
  gyms: {
    title: 'Gimnasios',
    url: '/gyms',
    external: false,
  },
  bestPro: {
    title: 'BestPro',
    url: '/bestpro',
    external: false,
  },
  shop: {
    title: 'Tienda',
    url: 'https://www.cicloindoor.com/20555/tienda-online-bestcycling',
    external: true,
  },
};

export default function Topbar({ user }: { user: User }) {
  const { state: shell } = useShellContext();
  const location = useLocation();

  // Caching available apps apps
  const apps = WebLinks.filter(link => !link.external && link.url !== '/').map(
    link => link.url.split('/')[1],
  );

  const isLinkActive = (link: { url: string }) => {
    const currentApp = location.pathname.split('/')[1];

    // Si estamos en una app miramos si el link es la app
    if (apps.includes(currentApp)) {
      return link.url.split('/')[1] === currentApp;
    }

    // Si no estamos en ninguna app por defecto / está activo
    return link.url === '/';
  };

  const isGymUser = useIsGymUser();

  return (
    <div className="secondary-menu">
      <div className="group-left">
        <MobileMenu />
        <>
          <BackButton />
          <NextButton />
        </>
        {!shell.nwjs && (
          <TopbarNav>
            {WebLinks.map((link, i) => {
              if (link.title === 'Gimnasios' && shell.electron && !isGymUser) return null;

              return (
                <TopbarItem
                  key={i}
                  to={link.url}
                  active={isLinkActive(link)}
                  external={link.external}
                >
                  {link.title}
                </TopbarItem>
              );
            })}
          </TopbarNav>
        )}
      </div>

      <div className="group-right">
        {user.isSubscribed() || <SubscribeButton user={user} />}

        <DropdownMenu user={user} />
      </div>
    </div>
  );
}
