import Button from '../../UI/Button';
import styles from './ErrorLoadingRelatedTrainingClasses.module.scss';

type ErrorLoadingRelatedTrainingClassesProps = {
  onClick(): void;
}

const ErrorLoadingRelatedTrainingClasses = (props: ErrorLoadingRelatedTrainingClassesProps) => {
  return (
    <div className={styles.container}>
      <span>Error cargando las clases relacionadas</span>
      <Button variant='primary' onClick={props.onClick}>REINTENTAR</Button>
    </div>
  );
}

export default ErrorLoadingRelatedTrainingClasses;