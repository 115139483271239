import * as d3 from 'd3';
/* eslint max-classes-per-file: ["error", 4] */
/* eslint no-use-before-define: ["error", { "classes": false }] */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["zonePercentage", "normalizationCoefficient","getYPosition","minValue"] }] */

export const GraphType = Object.freeze({
  HeartRate: 'HeartRate',
  Watts: 'Watts',
});

export const GraphPreferreded = Object.freeze({
  HeartRate: 'fc',
  Watts: 'watt',
});

export const GraphTypeNum = Object.freeze({
  HeartRate: 0,
  Watts: 1,
});
const translations = {
  long: {
    HeartRate: 'Frecuencia Cardíaca',
    Watts: 'Potencia',
  },
  short: {
    HeartRate: 'FC',
    Watts: 'Potencia',
  },
};

export const GraphTypeTranslator = Object.freeze(translations);

export class GraphFactory {
  static get(type) {
    if (GraphType.Watts == type) {
      return new WattsGraph();
    }
    if (GraphType.HeartRate == type) {
      return new HeartRateGraph();
    }
    throw Error(`Invalid type ${type} for Graph`);
  }
}

class Graph {
  constructor() {
    this.colors = [];
    this.heights = [];
  }

  getYPosition(y) {
    return y;
  }

  // Min value allowed to be stored in progression[row].zone
  get minValue() {
    return 0;
  }

  getConfiguration() {
    return {
      colors: this.colors,
      heights: this.heights,
    };
  }
}

export class HeartRateGraph extends Graph {
  constructor() {
    super();
    this.colors = [
      {
        color: '#b9b9c7',
      }, // Zone 1
      {
        color: '#278eb8',
      }, // Zone 2
      {
        color: '#79bb32',
      }, // Zone 3
      {
        color: '#fcbf2d',
      }, // Zone 4
      {
        color: '#f33745',
      }, // Zone 5
    ];
    this.heights = this.colors.map(() => 1 / this.colors.length);
  }

  configure(options) {
    if (options && options.colorZones) {
      this.colors = [
        { color: options.colorZones.zone_color_1 },
        { color: options.colorZones.zone_color_2 },
        { color: options.colorZones.zone_color_3 },
        { color: options.colorZones.zone_color_4 },
        { color: options.colorZones.zone_color_5 },
      ];
    }
  }

  // Percentage based on Zone starting from 1 to Z
  zonePercentage(zone) {
    return parseInt(zone * 10, 10) + 40;
  }

  getYLine() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var that = this;
    var domain = this.heights.map((hitem, index) => {
      var zonesUntil = that.heights.slice(0, index);
      var sum = zonesUntil.reduce((partialSum, item) => partialSum + item, 0);
      return sum;
    });
    domain = [...domain, 1].reverse();
    var range = [...Array(this.heights.length + 1).keys()].reverse();
    return d3.scaleLinear().domain(domain).range(range);
  }

  get normalizationCoefficient() {
    return 5.5 / 4.5;
  }

  // Max value allowed to be stored in progression[row].zone
  get maxValue() {
    const zonesCount = this.colors.length;
    const value = zonesCount / this.normalizationCoefficient;
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  getConfiguration() {
    return {
      // Zone starting from 1 to Z
      zonePercentage: this.zonePercentage,
      normalizationCoefficient: this.normalizationCoefficient,
      interpolation: 'd3.curveLinear',
      colors: this.colors,
      heights: this.heights,
      yLine: this.getYLine(),
      minValue: this.minValue,
      maxValue: this.maxValue,
    };
  }
}

export class WattsGraph extends Graph {
  constructor() {
    super();
    this.colors = [
      {
        color: '#1E8FBD',
      }, // Zone 1
      {
        color: '#77BD21',
      }, // Zone 2
      {
        color: '#F9D700',
      }, // Zone 3
      {
        color: '#FB8300',
      }, // Zone 4
      {
        color: '#F6333F',
      }, // Zone 5
      {
        color: '#b53a39',
      }, // Zone 6
      {
        color: '#973332',
      }, // Zone 7
    ];
    this.heights = [0.2, 0.2, 0.2, 0.2, 0.09, 0.09, 0.02];
  }

  configure(options) {
    if (options && options.colorZones) {
      this.colors = [
        { color: options.colorZones.zone_color_1 },
        { color: options.colorZones.zone_color_2 },
        { color: options.colorZones.zone_color_3 },
        { color: options.colorZones.zone_color_4 },
        { color: options.colorZones.zone_color_5 },
        { color: options.colorZones.zone_color_6 || options.colorZones.zone_color_5 },
        { color: options.colorZones.zone_color_7 || options.colorZones.zone_color_5 },
      ];
    }
  }

  // Max value allowed to be stored in progression[row].zone
  get maxValue() {
    const zonesCount = this.colors.length;
    const value = zonesCount / this.normalizationCoefficient;
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  zonePercentage(zone) {
    var interpolator = d3
      .scaleLinear()
      .range([0, 55, 76, 91, 106, 121, 150, 180])
      .domain([1, 2, 3, 4, 5, 6, 7, 8]);
    var percentage = interpolator(zone);

    if (percentage < 55) {
      return '< 55';
    }
    if (percentage > 150) {
      return '> 150';
    }

    return `${Math.floor((percentage * 100) / 100)}`;
  }

  getYLine() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var that = this;
    var domain = this.heights.map((hitem, index) => {
      var zonesUntil = that.heights.slice(0, index);
      var sum = zonesUntil.reduce((partialSum, item) => partialSum + item, 0);
      return sum;
    });
    domain = [...domain, 1].reverse();
    var range = [...Array(this.heights.length + 1).keys()].reverse();
    return d3.scaleLinear().domain(domain).range(range);
  }

  get normalizationCoefficient() {
    return 1;
  }

  getConfiguration() {
    return {
      // Percentage based on Zone starting from 1 to Z
      zonePercentage: this.zonePercentage,
      normalizationCoefficient: this.normalizationCoefficient,
      interpolation: 'd3.curveStepAfter',
      colors: this.colors,
      heights: this.heights,
      yLine: this.getYLine(),
      minValue: this.minValue,
      maxValue: this.maxValue,
    };
  }
}
