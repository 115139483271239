import Slider from '../Slider';
import MyCollectionsCard from './MyCollectionsCard';

type MyCollectionsCarouselProps = {
  data: Tag[];
};

const MyCollectionsCarousel = ({ data }: MyCollectionsCarouselProps) => {
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Slider className={''}>
        {data.map((element, index) => {
          return <MyCollectionsCard key={index} collection={element} />;
        })}
      </Slider>
    </div>
  );
};

export default MyCollectionsCarousel;
