import { Outlet } from 'react-router';
import CookiesModal from '../components/CookiesModal';
import useCookies from '../hooks/useCookies';

const CookiesGuard = () => {
  const { hasConfigured, accept, acceptAll, decline } = useCookies();

  return (
    <>
      <Outlet />
      {!hasConfigured && <CookiesModal accept={accept} acceptAll={acceptAll} decline={decline} />}
    </>
  );
};

export default CookiesGuard;
