import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollRestoration = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const prevLocation = useRef<string>();

  useLayoutEffect(() => {
    if (location.pathname !== prevLocation.current) {
      window.scrollTo(0, 0);
    }

    prevLocation.current = location.pathname;
  }, [location]);

  return <>{children}</>;
};

export default ScrollRestoration;
