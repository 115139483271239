import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ApiClient, ApiProvider as JsonApiProvider, Serializer, useClient } from 'jsonapi-react';
import * as apiSchema from './schema';
import { useAuth } from '../contexts/auth.context';
import { createContext, ReactNode, useContext } from 'react';
import usePathRecovery from '../hooks/usePathRecovery';

/*
 * Usamos `jsonapi-react` para hacer las peticiones al api así como para serializar/deserializar los datos.
 * Usamos `react-query` para cachear las peticiones.
 * TODO: Poco a poco deberíamos ir dejando de usar useQuery() de jsonapi-react y usar useQuery de react-query
 * para las peticiones.
 * Para hacer una petición en un componente deberíamos obtener la función `fetch` desde su hook y pasarsela
 * a react-query.
 * Fetch recibe los mismos parámetros que `useQuery` de `jsonapi-react`.
 *
 * Ejemplo:
 * function ComponentA(){
 *    const {fetch} = useApi()
 *    const query = useQuery(["cache-key"], () => fetch(["training_classes", {include: ["trainer"]}]))
 *    return query.isLoading ? <div>Loading</div> : <List data={query.data} />
 * }
 *
 * Para infinite scroll:
 * function ComponentB(){
 *    const {fetch, getNextPageParam} = useApi()
 *    const query = useInfiniteQuery(
 *       "cache-key",
 *       ({pageParam}) => fetch(["training_classes", include: ["trainer"]]),
 *       {
 *          getNextPageParam
 *       })
 *    return query.isLoading ? <div>Loading</div> : <List data={query.data} />
 * }
 */

function JsonApiBaseProvider({ children }: { children: ReactNode }) {
  const { state, logout, banned } = useAuth();

  const { savePath } = usePathRecovery();

  const client = new ApiClient({
    // @ts-expect-error
    url: config.API_V2_BASE,
    headers: {
      Authorization: `Bearer ${state.accessToken}`,
    },
    // @ts-expect-error
    serialize: (type: any, data: any, schema: any) => {
      return new Serializer({ schema }).serialize(type, data);
    },
    normalize: (data: any, schema: any) => {
      const { included } = data;
      const normalizedIncluded: any[] = [];

      included?.forEach((include: { type: string }) => {
        if (include.type === 'nutrition_recipe') {
          normalizedIncluded.push({ ...include, type: 'recipe' });
          normalizedIncluded.push({ ...include, type: 'nutrition_recipe' });
          return;
        }

        if (include.type === 'nutrition_recipe_food') {
          normalizedIncluded.push({ ...include, type: 'recipe_food' });
          return;
        }

        if (include.type === 'nutrition_recipe_steps') {
          normalizedIncluded.push({ ...include, type: 'recipe_step' });
          return;
        }

        if (include.type === 'training_class_exercises_media_asset') {
          normalizedIncluded.push({ ...include, type: 'media_asset' });
          return;
        }

        normalizedIncluded.push(include);
      });

      const normalizedData = {
        ...data,
        included: normalizedIncluded,
      };

      return new Serializer({ schema }).deserialize(normalizedData);
    },
    // formatError: (error: any) => false,
    // formatErrors: (error: any) => false,
    fetch: async (url, options) => {
      try {
        const res = await fetch(url, options);
        if (res.status == 401) {
          savePath();
          logout();
        }
        if (res.status == 403) banned();
        return res;
      } catch (err: any) {
        throw new Error(err);
      }
    },
    schema: apiSchema,
  });

  return <JsonApiProvider client={client}>{children}</JsonApiProvider>;
}

interface IApiContext {
  mutateApi: any;
  fetchApi: any;
  getNextPageParam: any;
  client: QueryClient;
}
const ApiContext = createContext<IApiContext>({
  mutateApi: () => {},
  fetchApi: () => {},
  getNextPageParam: () => {},
  client: new QueryClient(),
});

interface JsonApiCollectionResponse {
  data: object[];
  links?: { self: string; next: string };
}

const ApiContextProvider = ({ children }: { children: ReactNode }) => {
  const jsonapiClient = useClient();

  const fetchApi = async (queryData: any) => {
    // @ts-expect-error
    const query = jsonapiClient.getQuery(queryData);
    const res = await jsonapiClient.fetch(query.url);
    if (res.error) throw new Error('Error fetching');
    return res;
  };

  const getNextPageParam = (lastPage: JsonApiCollectionResponse): undefined | string => {
    const { data, links } = lastPage;
    if (!links) return;
    const { next, self } = links;
    const hasNextPage = data.length && next && next !== self;

    if (hasNextPage) {
      const searchParams = new URLSearchParams(next.split('?')[1]);
      const cursor = searchParams.get('cursor');
      if (cursor) return cursor;
    }

    return;
  };

  const mutateApi = async (endpoint: any, queryData: any, options = {}) => {
    const res = await jsonapiClient.mutate(endpoint, queryData, options);
    // TODO: react-query specification indicates to throw an error
    // if (res.error && res.error.name !== 'SyntaxError') {
    //   throw new Error('JsonApiClient Mutate', res.error);
    // }

    return res;
  };

  const client = useQueryClient();

  return (
    <ApiContext.Provider value={{ mutateApi, fetchApi, getNextPageParam, client }}>
      {children}
    </ApiContext.Provider>
  );
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {},
    queries: {
      networkMode: 'always', // 'online' | 'always' | 'offlineFirst';
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export default function ApiProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <JsonApiBaseProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} panelPosition="bottom" /> */}

        <ApiContextProvider>{children}</ApiContextProvider>
      </JsonApiBaseProvider>
    </QueryClientProvider>
  );
}

export const useApi = () => useContext(ApiContext);
