import { useEffect, useState } from 'react';

/**
 * Hook that allows to check if a ref is Visible in the viewport
 */
export const useIsVisible = (ref: React.MutableRefObject<any>, refParent: React.MutableRefObject<any>, threshold?: number) => {
  const [isVisible, setIsVisible] = useState(false);
  const [elem, setElem] = useState<any>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => { setIsVisible(entry.isIntersecting) }, {
      root: refParent.current,
      threshold,
    });

    if (ref.current) {
      setElem(ref.current);
      observer.observe(ref.current);
    }

    return () => {
      if (elem) observer.unobserve(elem);

      observer.disconnect();
    };
  }, [ref?.current]);

  return isVisible;
};
