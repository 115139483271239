import { useDropdown } from '@/hooks/useDropdown';
import clsx from 'clsx';
import Dropdown from '../Dropdown/Dropdown';

import styles from './MyCollectionBanner.module.scss';

import pencil from '@/img/icon-op-pencil.svg';
import trash from '@/img/bestpro/myclasses/delete-class-icon.svg';
import menu from '@/img/sensei/iconMenu.svg';
import ConfirmDialog from '../ConfirmDialog';
import { useState } from 'react';
import EditMyCollectionModal from '../EditMyCollectionModal/EditMyCollectionModal';
import { useApi } from '../../api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { DropdownItem } from '../TrainingClasses/EditableMenuItem';

type MyCollectionBannerProps = {
  collectionsNames: string[];
  collection: Tag;
};

const MyCollectionBanner = (props: MyCollectionBannerProps) => {
  const { dropdownOpened, dropdownRef, parentRef, openDropdown, closeDropdown } = useDropdown();

  const { client, mutateApi } = useApi();

  const navigate = useNavigate();

  const [showEditMyCollectionModal, setShowEditMyCollectionModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const openEditMyCollectionModal = () => setShowEditMyCollectionModal(true);
  const closeEditMyCollectionModal = () => setShowEditMyCollectionModal(false);

  const openDeleteDialog = () => setShowDeleteDialog(true);
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const clearCache = () => {
    client.invalidateQueries(['user_tags']);
    client.invalidateQueries(['training_classes', { official: false }]);
    client.invalidateQueries(['training_classes', props.collection.id]);
  };

  const deleteCollection = useMutation(
    (dataId: number) =>
      mutateApi(['user_tags', dataId], null, {
        method: 'DELETE',
      }),
    {
      onSuccess: clearCache,
    },
  );

  const confirmDeleteCollection = async () => {
    await deleteCollection.mutateAsync(props.collection.id);
    if (window.history.state?.idx === 0) {
      navigate('/', { replace: true });
    } else {
      navigate(-1);
    }
  };

  const onEditClick = () => {
    openEditMyCollectionModal();
    closeDropdown();
  };

  const onDeleteClick = () => {
    openDeleteDialog();
    closeDropdown();
  };

  const items: DropdownItem[] = [
    {
      title: 'Editar colección',
      icon: pencil,
      onClick: onEditClick,
    },
    {
      title: 'Eliminar colección',
      icon: trash,
      onClick: onDeleteClick,
    },
  ];

  if (!props.collection) return <></>;

  return (
    <section className={styles.collectionBanner}>
      <div className={clsx(styles.content, styles[props.collection?.properties?.color])}>
        <span className={styles.collectionName}>{props.collection.name}</span>
        <div className={styles.collectionOptions}>
          <button
            ref={parentRef}
            tabIndex={0}
            title="Opciones de la colección"
            onClick={openDropdown}
          >
            <img src={menu} />
          </button>
          {dropdownOpened && (
            <Dropdown
              ref={dropdownRef}
              parentRef={parentRef}
              position={'left'}
              items={items}
              requestClose={closeDropdown}
            />
          )}
        </div>
      </div>
      <EditMyCollectionModal
        isOpen={showEditMyCollectionModal}
        onClose={closeEditMyCollectionModal}
        collectionsNames={props.collectionsNames}
        collection={props.collection}
      />
      <ConfirmDialog
        isOpen={showDeleteDialog}
        onRequestClose={closeDeleteDialog}
        onConfirm={confirmDeleteCollection}
        description={`¿Estás seguro que quieres eliminar esta colección?`}
      />
    </section>
  );
};

export default MyCollectionBanner;
