import { useEffect, useState } from 'react';
import styles from './SharingDialog.module.scss';
import { useAppContext } from '@/contexts/app.context';
import SharingCounter from './SharingCounter';
import Button from '@/components/UI/Button/Button';
import Modal from '@/components/Modal/Modal';
import { FormInput } from '@/components/FormControls';
import { useApi } from '@/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@/contexts/toast.context';

const SharingDialog = ({ isOpen, trainingClass, onRequestClose }) => {
  const [recipient, setRecipient] = useState('');
  const {
    state: { user },
  } = useAppContext();

  const { mutateApi, client } = useApi();

  const { showToast } = useToast();

  const closeModal = () => {
    if (onRequestClose) onRequestClose();
  };

  const shareMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ recipient, training_class_id }) =>
      mutateApi(
        ['sharing_requests'],
        { recipient, training_class_id },
        {
          method: 'POST',
        },
      ),
    {
      onSuccess: result => {
        const { error } = result;
        if (error) {
          showToast(error, 'error');
        } else {
          showToast('Clase compartida correctamente.', 'success');

          client.invalidateQueries(['sharing_requests', trainingClass.id]);

          closeModal();
        }
      },
      onError: err => {
        console.log(err);
        showToast('Ha ocurrido un error inesperado.');
      },
    },
  );

  const confirmModal = async () => {
    if (recipient != null && recipient.length > 0) {
      shareMutation.mutate({ recipient, training_class_id: trainingClass.id });
    }
  };

  const keyboardListener = evt => {
    if (evt.key == 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyboardListener);
    return () => {
      window.removeEventListener('keyup', keyboardListener);
    };
  }, [isOpen]);

  const handleClose = evt => {
    if (evt) evt.preventDefault();
    closeModal();
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    confirmModal();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} className={styles.modal}>
      <p className={styles.title}>Compartir clase </p>
      <span className={styles.description}>
        Introduce el email del amigo con el que quieres compartir tu clase. Recuerda que debe estar
        dado de alta en Bestcycling y tener una suscripción activa.
      </span>

      <form onSubmit={handleSubmit}>
        <FormInput
          type="email"
          autoFocus={true}
          disabled={shareMutation.isLoading}
          onChange={event => setRecipient(event.target.value)}
        />
      </form>

      <SharingCounter trainingClass={trainingClass} userId={user.id} />

      <div className={styles.buttons}>
        <Button variant="terciary" onClick={closeModal} style={{ marginRight: '15px' }}>
          CANCELAR
        </Button>

        <Button
          variant="primary"
          onClick={confirmModal}
          disabled={shareMutation.isLoading || !recipient}
        >
          CONTINUAR
        </Button>
      </div>
    </Modal>
  );
};

export default SharingDialog;
