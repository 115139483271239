import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './SearchContainer.module.scss';
import { NutritionKeys } from '@/core/constants/nutrition';

const paths = {
  [NutritionKeys.all]: '/nutrition/search/all',
  [NutritionKeys.breakfast]: '/nutrition/search/breakfast',
  [NutritionKeys.lunch]: '/nutrition/search/lunch',
  [NutritionKeys.dinner]: '/nutrition/search/dinner',
  [NutritionKeys.snack]: '/nutrition/search/snack',
  [NutritionKeys.dessert]: '/nutrition/search/dessert',
};

export default function SearchContainer({
  title,
  type,
  className = '',
  cardClassName,
  onClick,
  ...props
}) {
  const handleClick = evt => {
    if (!onClick) return;
    evt.preventDefault();
    onClick();
  };

  const classes = clsx(
    styles.searchContainer,
    styles[type],
    [cardClassName && styles[cardClassName]],
    className,
  );

  return (
    <Link to={paths[type]} className={classes} onClick={handleClick} role="link" {...props}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.shadow}></div>
    </Link>
  );
}
