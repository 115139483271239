import { Colors } from '@/core/constants/category';
import TrainingLevel from '@/core/components/TrainingLevel';
import styles from './RelatedTrainingClassCard.module.scss';

interface DefaultContentProps {
  getTitle: () => string;
  trainingClass: TrainingClass;
}
export default function DefaultContent({ getTitle, trainingClass }: DefaultContentProps) {
  const categoryNr = trainingClass.category_nr;

  return (
    <>
      <span className={styles.title} style={{ color: Colors[categoryNr as 1] }}>
        {getTitle()}
      </span>
      <span className={styles.title}>{trainingClass.subtitle}</span>
      <span>{trainingClass.trainer?.full_name}</span>
      <div className={styles.secondaryContent}>
        <div className={styles.trainingLevel}>
          Nivel
          <div className={styles.trainingLevelPoints}>
            <TrainingLevel level={trainingClass.level_nr} size='small' activeColor='white' />
          </div>
        </div>
      </div>
    </>
  )
}