import clsx from 'clsx';
import styles from './Slider.module.scss';
import dismissible from './DismissibleSlide.module.scss';

type SlideProps = {
  children?: React.ReactNode;
  index: number;
  automaticSize: boolean;
}

const Slide = ({ index, children, automaticSize = true }: SlideProps) => {
  return (
    <div id={`slide-item-${index}`} className={clsx(styles.slide, dismissible.container, automaticSize && styles['automaticSize'])}>
      {children}
    </div>
  );
}

export default Slide;