import Category from '@/constants/category';
import { GraphPreferreded, GraphType } from '@/constants/graphFactory';

export const getPreferredGraph = (preferences: any, data: TrainingClass) => {
  const progressionWatts = data.category_nr === Category.Cycling ? data.progression_watts : null;
  const hasProgressionData = !!data.progression?.length;

  if (preferences.graph_type === GraphType.Watts && progressionWatts) return GraphPreferreded.Watts;
  if (preferences.graph_type === GraphType.HeartRate && hasProgressionData) return GraphPreferreded.HeartRate;

  return GraphPreferreded.Watts;
};
