import GraphImages from '../../TrainingClassDetails/GraphImages';
import GraphImage from '../../TrainingClassDetails/GraphImage';
import { usePreferences } from '../../../contexts/app.context';
import { GraphType } from '@/core/constants/graphFactory';
import style from './TrainingClass.module.scss';

export default function TrainingClassCover({ trainingClass }: { trainingClass: TrainingClass }) {
  const { state: preferences } = usePreferences();

  const getGraph = () => {
    const { official, graph, graph_watts, has_graph, category_nr } = trainingClass;

    if (official || category_nr !== 1) return graph;
    if (preferences.graph_type === GraphType.Watts && graph_watts) return graph_watts;

    return has_graph ? graph : graph_watts;
  };

  return trainingClass.official ? (
    <>
      <div
        className={style.image}
        style={{
          backgroundImage: `url(${trainingClass.cover})`,
        }}
      >
        <div className={style.white_overlay}></div>
      </div>
      <div className={style.graphs}>
        <GraphImages trainingClass={trainingClass} />
      </div>
    </>
  ) : (
    <div className={style.image}>
      <GraphImage graph={getGraph() as string} />
    </div>
  );
}
