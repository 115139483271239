import { useState } from 'react';
import SharingDialog from '../Bestpro/SharingDialog';
import styles from '../FavouriteButton/FavouriteButton.module.scss';
import iconShare from '@/img/share.svg';

type MyTrainingClassShareButtonProps = {
  trainingClass: TrainingClass;
}

const MyTrainingClassShareButton = (props: MyTrainingClassShareButtonProps) => {
  const [isSharedDialogOpen, setSharedDialogOpen] = useState(false);

  const openSharedDialogModal = () => setSharedDialogOpen(true);

  const closeSharedDialogModal = () => setSharedDialogOpen(false);

  return (
    <>
      <button onClick={openSharedDialogModal} className={styles.fav}>
        <div className={styles.border}>
          <img src={iconShare} className={styles.icon} alt={'botton para compartir'} />
        </div>
      </button>
      <SharingDialog
        isOpen={isSharedDialogOpen}
        onRequestClose={closeSharedDialogModal}
        trainingClass={props.trainingClass}
      />
    </>
  );
};

export default MyTrainingClassShareButton;
