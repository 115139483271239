import { useEffect, useRef } from 'react';

const useDebounceFunction = (func: (...args: any) => void, delay: number) => {
  const debouncedRef = useRef<NodeJS.Timeout>();

  const debouncedFunction = (...args: any) => {
    clearTimeout(debouncedRef.current);
    debouncedRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };

  useEffect(() => {
    return () => clearTimeout(debouncedRef.current);
  }, []);

  return debouncedFunction;
};

export default useDebounceFunction;
