type ArrowRightProps = {
  size?: number;
}

const ArrowRight = ({ size = 20 }: ArrowRightProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 19.315 35.16">
      <g transform="translate(367.963 241.629) rotate(180)">
        <g transform="translate(348.649 206.469)">
          <path
            d="M787.447,458.494l-17.9-17.579,17.9-17.581h0a4.667,4.667,0,0,1,0,6.686l-11.1,10.895,11.094,10.893a4.666,4.666,0,0,1,0,6.686Z"
            transform="translate(-769.543 -423.334)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default ArrowRight;