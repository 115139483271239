import { lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  Outlet,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import useDocumentTitle from '@rehooks/document-title';

import { AppProvider } from './contexts/app.context';
import { RemoteProvider } from './contexts/remote.context';
import { DownloadsProvider } from './contexts/downloads.context';
import { FeedsProvider } from './contexts/feeds.context';

import { ApiProvider } from './api';

import Layout from './Layout';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import HomePage from './pages/HomePage';
import TrainingClassPage from './pages/TrainingClassPage';
import TrainingClassPlayerPage from './pages/TrainingClassPlayerPage';
import PlaylistsPage from './pages/PlaylistsPage';
import PlaylistPage from './pages/PlaylistPage';
import ProfileConfigPage from './pages/ProfileConfigPage';
import ProfilePage from './pages/ProfilePage';
import SubscriptionPage from './pages/SubscriptionPage';
import VideoProgressPage from './pages/VideoProgressPage';

import { AnalyticsProvider } from './contexts/analytics.context';
import { ProtectedRoute, OnlineRoute } from './routes';
import LoadingIndicator from './components/LoadingIndicator';
import OfflinePage from './pages/OfflinePage';
import OfflineClassPage from './pages/OfflineClassPage';
import OfflinePlayerPage from './pages/OfflinePlayerPage';

import { HRProvider } from './contexts/heartRate.context';
import { BTProvider } from './contexts/bluetoothConnection.context';
import useRegisterServicerWorkerElectron from './hooks/useRegisterServiceWorkerElectron';
import InstallingWorkerPage from './pages/InstallingWorkerPage';
import ScrollRestoration from './ScrollRestoration';
import ForbiddenPage from './pages/ForbiddenPage';
import SearchClassesPage from './pages/SearchClassesPage';
import FavouriteClassesPage from './pages/FavouriteClassesPage';
import MyCollectionClassesPage from './pages/MyCollectionClassesPage';
import CategoryClassesPage from './pages/CategoryClassesPage';
import { ErrorScreen } from './components/ErrorBoundary/ErrorBoundary';
import WeeklyClassesPage from './pages/WeeklyClassesPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CookiesGuard from './features/cookies/guards/CookiesGuard';
import ConflictPage from './pages/ConflictPage';

const GymsRoutes = lazy(() => import('./routes/GymsRoutes'));
const SenseiRoutes = lazy(() => import('./routes/SenseiRoutes'));
const NutritionRoutes = lazy(() => import('./routes/NutritionRoutes'));
const FeedsRoutes = lazy(() => import('./routes/FeedsRoutes'));
const SettingsRoutes = lazy(() => import('./routes/SettingsRoutes'));
const BestProRoutes = lazy(() => import('./routes/BestProRoutes'));

function Main() {
  return (
    <ApiProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppProvider>
        <RemoteProvider>
          <AnalyticsProvider>
            <DownloadsProvider>
              <FeedsProvider>
                <BTProvider>
                  <HRProvider>
                    <Layout>
                      <Suspense fallback={<LoadingIndicator />}>
                        <ScrollRestoration>
                          <Routes>
                            <Route element={<CookiesGuard />}>
                              <Route path="/" element={<HomePage />} />
                              <Route path="gyms/*" element={<GymsRoutes />} />
                              <Route path="bestpro/*" element={<BestProRoutes />} />
                              <Route path="trainingclass/:id" element={<TrainingClassPage />} />
                              <Route path="videoprogress/:id" element={<VideoProgressPage />} />
                              <Route
                                path="trainingclasses/search/*"
                                element={<SearchClassesPage />}
                              />
                              <Route
                                path="trainingclasses/favourites/*"
                                element={<FavouriteClassesPage />}
                              />
                              <Route
                                path="trainingclasses/weekly/*"
                                element={<WeeklyClassesPage />}
                              />
                              <Route
                                path="trainingclasses/category/*"
                                element={<CategoryClassesPage />}
                              />
                              <Route
                                path="collection/:collection/*"
                                element={<MyCollectionClassesPage />}
                              />
                              <Route element={<OnlineRoute activeOnlineChecker={false} />}>
                                <Route
                                  path="trainingclass/:id/play/:mediaType"
                                  element={<TrainingClassPlayerPage />}
                                />
                              </Route>

                              <Route element={<OnlineRoute />}>
                                <Route path="playlists/0" element={<PlaylistsPage />} />
                                <Route path="playlists/:list_id" element={<PlaylistPage />} />
                                <Route path="playlist/:list_id" element={<PlaylistPage />} />
                              </Route>

                              <Route path="profile/config" element={<ProfileConfigPage />}>
                                <Route path=":step" element={<ProfileConfigPage />} />
                              </Route>

                              <Route element={<OnlineRoute />}>
                                <Route path="sensei/*" element={<SenseiRoutes />} />
                              </Route>

                              <Route path="profile" element={<ProfilePage />} />
                              <Route element={<OnlineRoute />}>
                                <Route path="nutrition/*" element={<NutritionRoutes />} />
                                <Route path="notifications/*" element={<FeedsRoutes />} />
                              </Route>

                              <Route path="/subscription" element={<SubscriptionPage />} />
                              <Route path="/offline" element={<Outlet />}>
                                <Route path="" element={<OfflinePage />} />
                                <Route path="trainingclass/:id" element={<OfflineClassPage />} />
                                <Route
                                  path="trainingclass/:id/play/:mediaType"
                                  element={<OfflinePlayerPage />}
                                />
                              </Route>

                              <Route path="settings/*" element={<SettingsRoutes />}></Route>
                              <Route path="logout" element={<LogoutPage />} />
                            </Route>
                          </Routes>
                        </ScrollRestoration>
                      </Suspense>
                    </Layout>
                  </HRProvider>
                </BTProvider>
              </FeedsProvider>
            </DownloadsProvider>
          </AnalyticsProvider>
        </RemoteProvider>
      </AppProvider>
    </ApiProvider>
  );
}

export default function Application() {
  useDocumentTitle('Bestcycling');
  const { installed: workerInstalled, progress } = useRegisterServicerWorkerElectron();

  const hashRouter = createHashRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorScreen cleanState={() => {}} />}>
        <Route element={<ProtectedRoute />}>
          <Route path="*" element={<Main />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="conflict" element={<ConflictPage />} />
        <Route path="forbidden" element={<ForbiddenPage />} />
      </Route>,
    ),
  );

  return (
    <>
      {!workerInstalled ? (
        <InstallingWorkerPage progress={Math.floor(progress)} />
      ) : (
        <RouterProvider router={hashRouter} />
      )}
    </>
  );
}
