import PlayIcon from '@/img/PlayIcon';
import styles from './VisualTraining.module.scss';

type VisualTrainingProps = {
  training: TrainingClassExercise;
  onPlay(): void;
};

const VisualTraining = ({ training, onPlay }: VisualTrainingProps) => {
  return (
    <div className={styles.container}>
      <button className={styles.exercise} onClick={onPlay}>
        <img className={styles.background} src={training.image!} />
        <div className={styles.playIcon}>
          <PlayIcon size={20} color={'#934850'} />
        </div>
      </button>
      <span className={styles.title}>{training.name}</span>
    </div>
  );
};

export default VisualTraining;
