interface Sex {
  m: string;
  f: string;
}

export const SEX_PROFILES: Sex = {
  m: 'Hombre',
  f: 'Mujer',
};

interface FitStatus {
  1: string;
  2: string;
  3: string;
  4: string;
}

export const FIT_STATUS: FitStatus = {
  1: 'Bajo',
  2: 'Medio',
  3: 'Alto',
  4: 'Muy alto',
};
