import React, { ErrorInfo } from 'react';
import UpdateModal from '@/components/Modal/UpdateModal';
import styles from './ErrorBoundary.module.scss';
import Button from '../UI/Button';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
    isElectronError: false,
  };

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    const isElectronMissingFunctionError = error.message
      .toLowerCase()
      .match(/.*window\$.+api.+ is not a function/);

    if (window.electronAPI?.sendReport && process.env.NODE_ENV === 'production') {
      window.electronAPI.sendReport([error.message, error.stack].join('\n'));
    }

    console.log(error, errorInfo);
    this.setState({ error, info: errorInfo, isElectronError: isElectronMissingFunctionError });
  };

  render() {
    const { hasError, isElectronError } = this.state;
    const { children } = this.props;

    return (
      <>
        {hasError && <ErrorScreen cleanState={() => this.setState({ hasError: false })} />}
        {isElectronError && <UpdateModal forceOpen />}
        {!hasError && children}
      </>
    );
  }
}

export function ErrorScreen({ cleanState }: { cleanState: () => void }) {
  return (
    <div className={styles.errorBoundary} data-cy="error_boundary">
      <h1>¡OOPS! Ha ocurrido un error</h1>
      <p>Parece que ha habido un error y no hemos podido cumplir con tu petición.</p>
      <Button
        onClick={() => {
          // Unregister service worker
          navigator.serviceWorker?.getRegistrations().then(registrations => {
            registrations.forEach(registration => {
              registration.unregister();
            });
          });
          // Clean cache to avoid .css old chunks
          window.caches?.keys().then(keyList => {
            return Promise.all(
              keyList.map(key => {
                return caches.delete(key);
              }),
            );
          });
          cleanState();
          // Reload page
          window.location.replace(process.env.NODE_ENV == 'development' ? '/app#/' : '/app/#/'); // FIXME: no sé si es la mejor manera, pero no se me ocurre como hacerlo fuera del router

          window.location.reload();
        }}
      >
        Volver a Bestcycling
      </Button>
    </div>
  );
}
