import { BluetoothDeviceState, BluetoothDeviceTypes } from '@/constants/bluetooth';
import stylesBt from './BluetoothPrefs.module.scss';
import iconBluetoothConnected from '@/img/bluetooth_icon_connected.svg';

export default function Bpm({ dev }: { dev: BTDevice | null }) {
  const isConnected = dev?.state == BluetoothDeviceState.connected;
  const isBikeRate = dev?.deviceType == BluetoothDeviceTypes.Bike;
  return (
    <div className={stylesBt.bpm}>
      <p className={stylesBt.devName}>{dev?.name}</p>
      {isConnected && <img src={iconBluetoothConnected} alt="" />}
    </div>
  );
}
