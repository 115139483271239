import IconClose from '@/img/IconClose';
import CheckNps from '@/img/surveys/CheckNps';
import clsx from 'clsx';
import styles from './NpsSurveyCard.module.scss';

type NpsConfirmedStateProps = {
  onClose(): void;
}

const NpsConfirmedState = (props: NpsConfirmedStateProps) => {
  return (
    <>
      {
        <button className={styles.closeIcon} onClick={props.onClose}>
          <IconClose size={15} />
        </button>
      }
      <div className={clsx(styles.surveyContent, styles.confirmed)}>
        <CheckNps />
        <div className={styles.title}>¡Genial!</div>
        <div className={styles.content}>
          <span>Tendremos en cuenta tus preferencias</span>
        </div>
      </div>
    </>
  )
}

export default NpsConfirmedState;