import Divider from '../Navigation/Divider';
import SectionTitle from '../SectionTitle/SectionTitle';
import styles from './Preferences.module.scss';
import { useShellContext } from '@/contexts/shell.context';
import Modal from '../Modal';
import Button from '../UI/Button';
import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '@/contexts/app.context';
import { useToast } from '@/contexts/toast.context';

export default function MiscPreferences() {
  const {
    state: { electron: isElectron },
  } = useShellContext();

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.preferences}>
      <SectionTitle>Información de la aplicación</SectionTitle>
      <section className={styles.info}>
        {isElectron && (
          <>
            <div className={styles.flex}>
              <p style={{ margin: '1em 0' }}>
                Versión de la aplicación: {window.electronAPI?.appVersion}
              </p>
              <Button
                size="small"
                onClick={() => {
                  setShowModal(true);
                }}
                variant="secondary"
              >
                Reporta un error
              </Button>
            </div>
          </>
        )}
      </section>
      <ErrorReportModal onClose={() => setShowModal(false)} isOpen={showModal} />
    </div>
  );
}

interface ErrorReportModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const ErrorReportModal = ({ isOpen, onClose }: ErrorReportModalProps) => {
  const { showToast } = useToast();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isSending, setIsSending] = useState(false);
  const {
    state: { user },
  } = useAppContext();

  const sendReport = (reportMessage: string) => {
    setIsSending(true);
    const data: { [key: string]: any } = {};
    data['Email subject'] = `WEB-ERROR-REPORT from ${user?.isGymsMembership() ? 'gym' : 'user'}: ${
      user?.full_name
    }.`;
    data['Email'] = user?.login || 'desconocido';
    data['User'] = user?.full_name || 'desconocido';
    data['Admin user'] = `https://bestcycling.com/admin/users/${user?.id}`;
    if (user?.isGymsMembership()) {
      data['Admin gym'] = `https://bestcycling.com/admin/gyms/${user?.id}`;
    }
    data['Reporte de usuario'] = reportMessage;
    data['x-api'] = 'true'; // Necessary to get 200/400 response instead of html

    fetch('https://bestcycling.com/store/go/sendcontact/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.ok) {
          showToast('Reporte enviado correctamente, muchas gracias por su colaboración', 'success');
          setTextAreaValue('');
        } else {
          console.log('Request failed');
        }
        onClose();
        setIsSending(false);
      })
      .catch(error => {
        console.log(error);
        showToast('Error al enviar el reporte', 'error');
        setIsSending(false);
      });
  };

  const onClickSendReport = () => {
    if (window.electronAPI) {
      window.electronAPI.sendReport(textAreaRef.current?.value || '');
      onClose()
    } else {
      sendReport(textAreaRef.current?.value || '');
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef.current]);

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: '800px' }} className={styles.preferences}>
      <div className={styles.container}>
        <h1 className={styles.titleModal}>REPORTAR ERROR</h1>
        <p className={styles.description}>
          Para que podamos identificar bien el problema por favor describe el error con el que te
          has encontrado y los pasos que hay que dar para poder reproducirlo. Si necesitas adjuntar
          capturas de pantalla o vídeos puedes enviar un correo a &nbsp;
          <a href="mailto:info@bestcycling.es">info@bestcycling.es</a>
        </p>
        <div>
          <textarea
            id="w3review"
            name="w3review"
            rows={10}
            ref={textAreaRef}
            onChange={e => setTextAreaValue(e.target.value)}
            value={textAreaValue}
          />
        </div>
        <div className={styles.flex}>
          <Button variant="secondary" style={{ marginBottom: '10px' }} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            style={{ marginBottom: '10px' }}
            variant="primary"
            onClick={onClickSendReport}
            disabled={textAreaValue.length < 10 || isSending}
          >
            {isSending ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
