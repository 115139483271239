import { useRef } from 'react';

function NO_OP() {}
export default function InfiniteScrollList({ children, onLastReached = NO_OP }) {
  const observer = useRef();
  const lastItemRef = node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        onLastReached();
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <>
      {children}
      {children.length > 0 && <span ref={lastItemRef}>&nbsp;</span>}
    </>
  );
}
