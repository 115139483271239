import { useContext, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useApi } from '../api/ApiProvider';
import FiltersContext from '@/contexts/filters.context';

import Page from '../components/Page';
import Filters from '../components/Filters';
import Levels from '../components/TrainingClasses/Levels';
import TrainingClassesList from '../components/TrainingClasses/TrainingClassesList';
import { useUser } from '@/contexts/app.context';
import { useRemoteConfig } from '@/contexts/remote.context';
import OfflineErrorPopup from '@/components/OfflineErrorPopup/OfflineErrorPopup';
import { FooterMin } from '@/components/Footer';
import { TrainingCategoryFilter } from '@/components/Filters/TrainingCategoryFilter';
import { TrainingYearFilter } from '@/components/Filters/TrainingYearFilter';
import { TrainingTrainerFilter } from '@/components/Filters/TrainingTrainerFilter';
import { TrainingLevelFilter } from '@/components/Filters/TrainingLevelFilter';
import { DurationFilter } from '@/core/components/Filters/DurationFilter';
import { TrainingTypeFilter } from '@/components/Filters/TrainingTypeFilter';
import { TrainingMaterialFilter } from '@/components/Filters/TrainingMaterialFilter';
import EmptySection from '@/components/EmptySection/EmptySection';
import favStarIcon from '../../../img/fav-star.svg';
import EmptyFilteredSection from '@/components/EmptyFilteredSection/EmptyFilteredSection';
import SizedLoadingIndicator from '../components/LoadingIndicator/SizedLoadingIndicator';
import InfiniteQueryList from '../components/InfiniteQueryList';
import { TrainingGenreFilter } from '../components/Filters/TrainingGenreFilter';
import TrainingDoneFilter from '../components/Filters/TrainingDoneFilter';
import mapTrainingClassesFilters from '../features/training_classes/utils/mapTrainingClassFilters';

interface ListClassesPageProps {
  params: any;
  section: string;
  collectionId?: string;
  onSelect?(id: string | number): void;
  levelSelector?: boolean;
  [key: string]: any;
}
export default function ListClassesPage({
  params,
  section,
  collectionId,
  onSelect,
  levelSelector = false,
  ...props
}: ListClassesPageProps) {
  const { state: user } = useUser();

  const { isFreeAccessEnabled } = useRemoteConfig();

  const { filters } = useContext(FiltersContext);

  const { fetchApi, getNextPageParam } = useApi();

  const [isMobileFiltersActive, setIsMobileFiltersActive] = useState(false);

  const isFavorites = section === 'favourites';

  const sort =
    !user.isSubscribed() && isFreeAccessEnabled() ? ['-free', filters.sort] : [filters.sort];

  const defaultCacheKey = [params, filters];

  const cacheKey = collectionId
    ? [collectionId, section, ...defaultCacheKey]
    : [section, ...defaultCacheKey];

  const query = useInfiniteQuery(
    ['training_classes', ...cacheKey],
    ({ pageParam }) =>
      fetchApi([
        'training_classes',
        {
          filters: mapTrainingClassesFilters(filters),
          include: [
            'trainer',
            'training',
            'training_materials',
            'user_tags',
            'sharing_user',
            'training_genres',
          ],
          fields: { training: 'seconds' },
          sort: sort,
          cursor: pageParam,
        },
      ]),
    {
      getNextPageParam,
    },
  );

  const { data, isError, isLoading } = query;

  const hasData = !!data?.pages?.length;

  const header =
    (levelSelector && !isMobileFiltersActive && <Levels params={params} />) || undefined;

  const trainingClasses = useMemo(
    () => data?.pages.map(p => p.data).flat() as TrainingClass[],
    [data],
  );

  const isDefaultFilters = useMemo(() => Object.keys(filters).length === 3, [filters]);

  return (
    <Page footer={<FooterMin />} header={header} {...props}>
      <div id="list_classes_filter">
        <Filters
          showSearchFilter
          showTrainingSortFilter
          setIsMobileFiltersActive={setIsMobileFiltersActive}
        >
          <TrainingCategoryFilter />
          <TrainingYearFilter />
          <TrainingTrainerFilter unofficialInstructorData={user} isFavorites={isFavorites} />
          <TrainingLevelFilter />
          <DurationFilter filterType="training" />
          <TrainingTypeFilter />
          <TrainingGenreFilter />
          <TrainingDoneFilter />
          {filters.category_nr == 41 && <TrainingMaterialFilter />}
        </Filters>
      </div>

      {isLoading ? (
        <SizedLoadingIndicator height={300} />
      ) : hasData ? (
        !trainingClasses?.filter(item => !!item).length ? (
          isFavorites && isDefaultFilters ? (
            <EmptySection
              icon={favStarIcon}
              headerText="Todavía no tienes clases favoritas"
              bodyText="Puedes marcar una clase como favorita desde la ficha de la clase, haciendo click en el icono correspondiente."
            />
          ) : (
            <EmptyFilteredSection text="No hay clases disponibles con los perfiles seleccionados." />
          )
        ) : (
          !isMobileFiltersActive && (
            <InfiniteQueryList
              {...query}
              errorLoadingMessage={
                'No es posible cargar más clases dado que no tienes conexión a internet. Comprueba tu conexión y vuelve a intentarlo.'
              }
            >
              <TrainingClassesList
                showTagList={!collectionId}
                data={trainingClasses}
                onSelect={onSelect}
              />
            </InfiniteQueryList>
          )
        )
      ) : isError ? (
        <OfflineErrorPopup
          textH={'Ha ocurrido un error y no se han podido cargar las clases.'}
          onClick={() => query.fetchNextPage()}
        />
      ) : null}
    </Page>
  );
}
