import { useMemo, useState } from 'react';
import { useDownload } from '../../contexts/downloads.context';
import { useShellContext } from '../../contexts/shell.context.tsx';
import downloadIcon from '../../../../img/icon-offline.svg';
import downloadIconActive from '../../../../img/icon-offline-active.svg';
import DownloadModal from './DownloadModal';
import styles from './DownloadButton.module.scss';
import SubscribeModal from '../SubscribeModal';
import { useUser } from '@/contexts/app.context';
import { useRemoteConfig } from '@/contexts/remote.context';

export default function DownloadButton({ trainingClass, hasMediaClass }) {
  const { state: shell } = useShellContext();
  const { state: user } = useUser();

  const { isRestrictedOffline } = useRemoteConfig();

  const download = useDownload(trainingClass.id);

  const [isDownloadsModalOpen, setDownloadsModalOpen] = useState(false);
  const [isSubscriptionVerificationOpen, setIsSubscriptionVerificationOpen] = useState(false);

  const openDownloadsModal = () => {
    window.top.postMessage(
      {
        type: '@downloads/show-offline',
        payload: { trainingClass },
      },
      // eslint-disable-next-line comma-dangle
      '*',
    );

    // TODO: TEMP DISABLED
    setDownloadsModalOpen(true);
  };

  const closeModal = () => setDownloadsModalOpen(false);
  const closeSubscriptionVerificationModal = () => setIsSubscriptionVerificationOpen(false);

  const openModal = () => {
    if (user.isSubscribed() || !isRestrictedOffline()) {
      openDownloadsModal();
    } else {
      setIsSubscriptionVerificationOpen(true);
    }
  };

  const isDownloaded = useMemo(() => !!download?.downloadedMedia?.length, [download]);

  if (!shell.desktop) return null;

  return (
    <>
      <button
        onClick={openModal}
        disabled={!hasMediaClass}
        className={`${styles.download} ${isDownloaded ? styles.active : ''}`}
        style={{
          cursor: `${!hasMediaClass ? 'not-allowed' : 'pointer'}`,
          opacity: `${!hasMediaClass ? 0.5 : 1}`,
        }}
        title="Descargar"
      >
        <div className={styles.border}>
          <img className={styles.icon} src={isDownloaded ? downloadIconActive : downloadIcon} />
        </div>
      </button>

      <DownloadModal
        trainingClass={trainingClass}
        isOpen={isDownloadsModalOpen}
        onClose={closeModal}
      />

      <SubscribeModal
        isOpen={isSubscriptionVerificationOpen}
        onClose={closeSubscriptionVerificationModal}
        onSubscribe={openModal}
        user={user}
      />
    </>
  );
}
