import { AsyncFilter } from './Filter';

export function TrainingTypeFilter() {
  return (
    <AsyncFilter
      filterType="training_types"
      filterKey="training_type_nr"
      label="training_type"
      tag="ENTRENAMIENTO"
    />
  );
}
