import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../../core/api/ApiProvider';
import { useState } from 'react';
import Modal from '../Modal';
import Button from '../UI/Button';
import styles from './ConnectingBikeModal.module.scss';
import useSetting from '../Preferences/useSetting';
import { FTP_ATTRIBUTES } from '@/constants/ftpAttributes';

interface ConfigFtpModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onBack: () => void;
  onConfirm: (data: any) => void;
  attributeOpen?: string;
  type?: string;
  gender?: string;
  weight?: number;
  fitness?: string;
}

export default function ConfigFtpModal({
  isOpen,
  onCancel,
  onBack,
  onConfirm,
  attributeOpen,
  gender,
  weight,
  fitness,
}: ConfigFtpModalProps) {
  const [customFtp, setCustomFtp] = useState(0);

  const getInitialState = () => {
    return attributeOpen == FTP_ATTRIBUTES.GENDER
      ? gender
      : attributeOpen == FTP_ATTRIBUTES.WEIGHT
      ? weight
      : attributeOpen == FTP_ATTRIBUTES.FITNESS
      ? fitness
      : 100;
  };
  const [attributeState, setAttributeState] = useState<string | number | undefined>(
    getInitialState(),
  );
  const [_, setFTP] = useSetting('customFtp');
  const { mutateApi, client } = useApi();

  const saveProfile = useMutation(
    (data: any) =>
      mutateApi(['profile'], data, {
        method: 'PATCH',
      }),
    {
      onSuccess: () => client.invalidateQueries(['profile']),
    },
  );

  const onSubmit = () => {
    saveProfile.mutate({ ftp: customFtp });
    setFTP(Number(customFtp));
    onConfirm(true);
  };
  return (
    <Modal isOpen={isOpen} className={styles.modalConfigFtp} onRequestClose={onCancel}>
      <h1 className={styles.title}>
        {' '}
        {attributeOpen != FTP_ATTRIBUTES.FTP ? `${attributeOpen}` : 'INTRODUCE TU FTP'}
      </h1>
      {attributeOpen && (
        <p className={styles.description}>{`Introduce tu ${attributeOpen.toLowerCase()} ${
          attributeOpen == FTP_ATTRIBUTES.WEIGHT ? 'en kg. ' : '.'
        }`}</p>
      )}
      {attributeOpen === FTP_ATTRIBUTES.GENDER ? (
        <select
          id="gender"
          defaultValue={attributeState}
          onChange={evt => setAttributeState(evt.target.value)}
        >
          <option value="m">Hombre</option>
          <option value="f">Mujer</option>
        </select>
      ) : attributeOpen === FTP_ATTRIBUTES.FITNESS ? (
        <select
          id="fitness"
          defaultValue={attributeState}
          onChange={evt => {
            setAttributeState(evt.target.value);
          }}
        >
          <option value="1">Bajo</option>
          <option value="2">Medio</option>
          <option value="3">Alto</option>
          <option value="4">Muy Alto</option>
        </select>
      ) : attributeOpen === FTP_ATTRIBUTES.WEIGHT ? (
        <input
          className={styles.input}
          type="number"
          onChange={evt => setAttributeState(Number(evt.target.value))}
          defaultValue={attributeState}
        />
      ) : (
        <input
          className={styles.input}
          type="number"
          onChange={evt => setCustomFtp(Number(evt.target.value))}
        />
      )}
      <footer>
        <Button onClick={onBack} size="small" className={styles.button} variant="terciary">
          VOLVER
        </Button>
        <Button
          onClick={
            attributeOpen == FTP_ATTRIBUTES.FTP ? () => onSubmit() : () => onConfirm(attributeState)
          }
          size="small"
          className={styles.button}
        >
          ACEPTAR
        </Button>
      </footer>
    </Modal>
  );
}
