import iconBike from './iconBike.svg';
import iconEliptica from './iconEliptica.svg';
import iconBandas from './iconBandas.svg';
import iconMancuernas from './iconMancuernas.svg';

const icons = [iconBike, iconEliptica, iconBandas, iconMancuernas];
export default function TrainingMaterial({ material, size = '100%', ...props }) {
  const { id } = material;
  const icon = icons[id - 1];

  return icon ? (
    <span {...props}>
      <img src={icon} alt={material.name} height={size} />
    </span>
  ) : null;
}
