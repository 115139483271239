import { useState } from 'react';

import styles from './ProfileConfig.module.scss';

const TRAINING_MOTIVATIONS = [
  {
    id: 'force',
    value: 1,
    title: 'Fuerza',
    description: 'Mejorar fuerza, tonificar, ganar musculatura',
  },
  {
    id: 'resistance',
    value: 2,
    title: 'Resistencia',
    description: 'Ganar resistencia, adelgazar, cuidar tu corazón',
  },
  {
    id: 'flexibility',
    value: 3,
    title: 'Flexibilidad',
    description: 'Cuidar espalda y mejorar flexibilidad',
  },
  {
    id: 'mind',
    value: 4,
    title: 'Mente',
    description: 'Mejorar concentración y paz mental',
  },
];

function TrainingMotivation({ id, value, title, description, checked = false, onChange }) {
  return (
    <div className={styles.checkbox}>
      <input id={id} type="checkbox" value={value} checked={checked} onChange={onChange} />
      <label className={styles[id]} htmlFor={id}>
        <p>{title}</p>
        <p className={styles.txt}>{description}</p>
      </label>
    </div>
  );
}

export default function ProfileConfig3({ profile, onSubmit }) {
  const [trainingMotivations, setTrainingMotivations] = useState(
    profile.training_motivations.map(v => +v)
  );

  const onSelectAll = evt => {
    if (evt.target.checked) {
      setTrainingMotivations([1, 2, 3, 4]);
    } else {
      setTrainingMotivations([]);
    }
  };

  const onChange = evt => {
    let values = [...trainingMotivations];
    const targetValue = +evt.target.value;
    if (evt.target.checked) {
      values = [...values, targetValue];
    } else {
      values = values.filter(v => v != targetValue);
    }
    setTrainingMotivations(values.slice().sort());
  };

  const onSubmitForm = evt => {
    evt.preventDefault();
    onSubmit({ profile: { training_motivations: [...trainingMotivations] } });
  };

  const isFormValid = trainingMotivations.length > 0;

  return (
    <>
      <div className={styles.title}>OBJETIVOS A MEJORAR</div>

      <div className={styles.form_input_objectives}>
        <form onSubmit={onSubmitForm}>
          <div className={styles.formContainer}>
            {TRAINING_MOTIVATIONS.map(({ id, value, title, description }, index) => (
              <TrainingMotivation
                key={index}
                id={id}
                value={value}
                title={title}
                description={description}
                checked={trainingMotivations.includes(value)}
                onChange={onChange}
              />
            ))}
            <TrainingMotivation
              id="all"
              title="TODO"
              description="Mejorar de forma equilibrada todas las capacidades"
              checked={trainingMotivations.length === TRAINING_MOTIVATIONS.length}
              onChange={onSelectAll}
            />
          </div>
          <button className={styles.button} disabled={!isFormValid}>
            Continuar
          </button>
        </form>
      </div>
      {/* <a className={styles.button_ignore} onClick={onNextClick}>
        Omitir paso
      </a> */}
    </>
  );
}
