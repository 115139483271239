import Modal from "@/core/components/Modal";
import clsx from "clsx";
import { useState } from "react";
import styles from './NpsSurveyModal.module.scss';
import NpsSurveyModalFinishedState from "./NpsSurveyModalFinishedState";
import NpsSurveyModalInitialState from "./NpsSurveyModalInitialState";

type NpsState = 'initial' | 'finished';

type NpsSurveyModalProps = {
  isOpen: boolean;
  onConfirm(): void;
  onClose(): void;
}

const NpsSurveyModal = (props: NpsSurveyModalProps) => {
  const [status, setStatus] = useState<NpsState>('initial');

  const finish = () => setStatus('finished');

  const onClose = () => {
    if (status == 'initial') props.onClose();
    else {
      props.onConfirm();
      props.onClose();
    }
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={onClose} className={clsx(styles.container, styles[status])}>
      {status === 'initial' && <NpsSurveyModalInitialState onConfirm={finish} />}
      {status === 'finished' && <NpsSurveyModalFinishedState />}
    </Modal>
  );
}

export default NpsSurveyModal;