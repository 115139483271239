import offlineIcon from '@/img/offlineIndicator-icon.svg';
import Button from '../UI/Button';
import styles from "./OfflineInfiniteErrorListPopup.module.scss";

type InfinityErrorListPopupProps = {
  message: string,
  onRetry?(): void,
}

const OfflineInfiniteErrorListPopup = (props: InfinityErrorListPopupProps) => {
  return (
    <div className={styles.container}>
      <img src={offlineIcon} width={30} alt="Icono no-conexión" />
      <span>{props.message}</span>
      <Button variant="primary" size="small" onClick={props.onRetry}>REINTENTAR</Button>
    </div>
  );
}

export default OfflineInfiniteErrorListPopup;