import { useAppContext } from '@/contexts/app.context';
import { useQuery } from 'jsonapi-react';
import Modal from '../Modal';
import Divider from '../Navigation/Divider';
import SectionSubtitle from '../SectionSubtitle';
import SectionTitle from '../SectionTitle';
import Button from '../UI/Button';
import styles from '../Preferences/Preferences.module.scss';
import dayjs from 'dayjs';
import { useEffect, useReducer, useState } from 'react';
import iconHelp from '@/img/icon-help.svg';
import { useAnalyticsContext } from '@/contexts/analytics.context';
import ANALYTICS_EVENTS from '@/constants/analytics_events';
import { useToast } from '@/contexts/toast.context';

const InviteFriendModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const { data } = useQuery(['affiliation_levels']);
  const {
    state: { user },
  } = useAppContext();

  const getNextLevel = () => {
    if (!data) return null;
    const currentLevelNumber = Number(user.affiliation_data.level);

    const nextLevel = data[currentLevelNumber] || data[data.length - 1];

    return nextLevel;
  };

  const getCurrentPoints = () => {
    return user?.affiliation_data?.points || 0;
  };

  const getMemberMonths = () => {
    const memberFromNormalized = user?.affiliation_data.member_from_normalized;
    if (!memberFromNormalized) return 0;

    const avgMonthDays = 365 / 12;
    const daysDiff = dayjs().diff(dayjs(memberFromNormalized), 'days');
    return Math.floor(daysDiff / avgMonthDays);
  };

  const getCurrentMonths = () => {
    return getMemberMonths();
  };

  const getNextLevelPoints = () => {
    return getNextLevel()?.require_points || 0;
  };

  const getNextLevelRemainingPoints = () => {
    return getNextLevelPoints() - getCurrentPoints();
  };

  const getNextLevelMonths = () => {
    return Math.floor((getNextLevel()?.require_years || 0) * 12);
  };

  const getNextLevelRemainingMonths = () => {
    return getNextLevelMonths() - getCurrentMonths();
  };

  const getProgress = () => {
    const pointsProgress = getCurrentPoints() / getNextLevelPoints();
    const timeProgress = getMemberMonths() / getNextLevelMonths();
    const levelProgress = Math.max(pointsProgress, timeProgress);

    return (levelProgress * 100).toFixed(2);
  };

  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const handleHelpClick = evt => {
    evt.preventDefault();
    setHelpModalVisible(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        maxHeight: 'calc(100vh - 40px)',
        marginTop: '20px',
        backgroundColor: '#262626',
        width: '660px',
      }}
      onRequestClose={onRequestClose}
    >
      <div className={styles.preferences} style={{ overflowY: 'scroll' }}>
        <SectionTitle style={{ marginTop: 0 }}>Información de socio</SectionTitle>
        <section className={styles.containerUser}>
          <UserLevel userLevel={user?.affiliation_data.level} levels={data} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.userObjetiveLevel}>Actual</div>
            <div className={styles.userObjetiveLevel}>Objetivo</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.userMonth}>{getMemberMonths()} meses</div>
            <div className={styles.userMonth}>{getNextLevelMonths()} meses</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.userPoints}>{getCurrentPoints()} puntos</div>
            <div className={styles.userPoints}>{getNextLevelPoints()} puntos</div>
          </div>
          <div className={styles.progressbar}>
            <div className={styles.statusbar} style={{ width: `${getProgress()}%` }}></div>
          </div>
        </section>
        <section className={styles.info}>
          <p style={{ margin: '1em 0' }}>
            Conviértete en usuario nivel {getNextLevel()?.name} consiguiendo{' '}
            {getNextLevelRemainingPoints()} puntos adicionales, o continuando suscrito de manera
            ininterrumpida {getNextLevelRemainingMonths()} meses más.
          </p>
          <p style={{ margin: '1em 0' }}>
            Los puntos se cuentan de forma anual desde el mes de inicio del último periodo de
            suscripción consecutivo.
          </p>
          <p style={{ margin: '1em 0' }}>
            Consulta los beneficios de tu nivel de socio en nuestra web haciendo{' '}
            <a
              href="https://bestcycling.com/page/beneficios"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline', fontSize: 14 }}
            >
              click aquí
            </a>
            .
          </p>
        </section>
        <SectionSubtitle>Invita a un amigo</SectionSubtitle>
        <Divider />
        <section className={styles.containerLink}>
          <InviteCode code={user?.invite_code} />
        </section>
        <section style={{ textAlign: 'center', margin: '0 auto' }}>
          <p className={styles.info} style={{ margin: '1em 0', textAlign: 'center' }}>
          Envía este enlace a tus amigos para que obtengan un descuento del 20% en una suscripción
          anual para entrenar con nosotros. Tú conseguirás 1 mes gratis por cada amigo que se
          suscriba.
          </p>
          <button onClick={handleHelpClick}>
            <img src={iconHelp}></img>
          </button>
        </section>
        <HelpModal
          isOpen={helpModalVisible}
          onRequestClose={() => {
            setHelpModalVisible(false);
          }}
        />

        <SectionSubtitle>Puntos</SectionSubtitle>
        <Divider />
        <div style={{ margin: '2em 0' }}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '1em 0' }}>
            <div style={{ textAlign: 'center' }}>
              <div className={styles.points}>{user.affiliation_data?.points || 0}</div>
              <div className={styles.txt}>Puntos de amigos</div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', margin: '1em 0' }}>
            <div style={{ textAlign: 'center' }}>
              <div className={styles.friends}>
                {user.affiliation_data?.active_referred_count || 0}
              </div>
              <div className={styles.txt}>Amigos activos</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div className={styles.friends}>
                {user.affiliation_data?.total_referred_count || 0}
              </div>
              <div className={styles.txt}>Amigos invitados</div>
            </div>
          </div>
        </div>
        <SectionSubtitle>Tu información de cliente</SectionSubtitle>
        <Divider />
        <div style={{ margin: '2em 0' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '1em 0',
              alignItems: 'center',
            }}
          >
            <div style={{ maxWidth: 300, textAlign: 'center' }}>
              <div className={styles.points}>{getMemberMonths()}</div>
              <div className={styles.txt}>Meses consecutivos suscrito</div>
            </div>
            <div
              style={{ borderRight: '1px solid rgb(38, 38, 38)', margin: '0 2em', height: '150px' }}
            ></div>
            <div style={{ maxWidth: 300, textAlign: 'center' }}>
              <div>
                <strong className={styles.month}>
                  {dayjs(user?.created_at).locale('es').format('MMMM [de] YYYY')}
                </strong>
                <br />
                <span className={styles.txt}>Fecha de alta</span>
              </div>
              <div style={{ marginTop: '2em' }}>
                <strong className={styles.month}>
                  {dayjs(user?.affiliation_data.member_from).locale('es').format('MMMM [de] YYYY')}
                </strong>
                <br />
                <span className={styles.txt}>Inicio de la suscripción</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteFriendModal;

const UserLevel = ({ userLevel, levels }) => {
  return <h2 className={styles.userLevel}>{levels?.find(l => l.id == userLevel)?.name}</h2>;
};

const HelpModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={styles.preferences}>
        <h4 className={styles.title}>¿Cómo funciona el enlace de invitar a un amigo?</h4>
        <p className={styles.description}>1. Envía tu enlace de invitar a tus amigos.</p>
        <p className={styles.description}>
          2. Tus amigos simplemente deben pinchar y seguir ese enlace.
        </p>
        <p className={styles.description}>
          3. Recibirás puntos y beneficios por los amigos que vengan a entrenar con nosotros.
        </p>
        <p className={styles.description}>
          4. Tus amigos recibirán un 20% de descuento en una suscripción anual para entrenar con nosotros.
        </p>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button style={{ margin: '0 auto' }} onClick={onRequestClose}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const InviteCode = ({ code }) => {
  const { analytics, logEvent } = useAnalyticsContext();

  const { showToast } = useToast();

  const [state, dispatch] = useReducer(
    (currentState, event) => {
      switch (event.type) {
        case 'load':
          return { isLoading: true, isError: false, isSuccess: false, error: null, data: null };
        case 'success':
          return {
            isLoading: false,
            isError: false,
            isSuccess: true,
            error: null,
            data: event.payload.data,
          };
        case 'error':
          return {
            isLoading: false,
            isError: true,
            isSuccess: false,
            error: event.payload.error,
            data: null,
          };
        default:
          return currentState;
      }
    },
    { isLoading: false, isSuccess: false, isError: false, data: null, error: null },
  );

  const { data, isLoading, isSuccess, isError } = state;

  const loadDynamicLink = async () => {
    dispatch({ type: 'load' });

    const source = 'Particular';
    const medium = 'codigo';
    const campaign = 'codigoAmigo';

    fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCWZheoGbIvvriJbmVN1A6k0KyiUcctyvg',
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          suffix: {
            option: 'SHORT',
          },
          longDynamicLink: `https://bestlife.page.link/?link=https://bestcycling.com/invites/${code}?utm_source%3D${source}%26utm_medium%3D${medium}%26utm_campaign%3D${campaign}&apn=es.bestcycling.life&amv=167&isi=1275051011&ibi=es.bestcycling.bestlife&imv=2.1.6&st=Clases+virtuales+para+entrenar+cuerpo+y+mente&sd=Accede+2+meses+gratis+a+todo+nuestro+contenido+de+actividades+y+nutrici%C3%B3n.&si=https://bestcycling-marketing.s3.eu-west-1.amazonaws.com/affiliation/invitation.png&utm_campaign=codigoAmigo&utm_medium=codigo&utm_source=Particular`,
        }),
        // body: JSON.stringify({
        //   suffix: {
        //     option: 'SHORT',
        //   },
        //   dynamicLinkInfo: {
        //     domainUriPrefix: 'https://bestlife.page.link',
        //     link: `https://bestcycling.com/invites/${inviteCode}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`,
        //     iosInfo: {
        //       iosBundleId: 'es.bestcycling.bestlife',
        //       iosAppStoreId: '1275051011',
        //       iosMinimumVersion: '2.1.6',
        //     },
        //     androidInfo: {
        //       androidPackageName: 'es.bestcycling.life',
        //       androidMinPackageVersionCode: '167',
        //     },
        //     analyticsInfo: {
        //       googlePlayAnalytics: {
        //         utmSource: source,
        //         utmMedium: medium,
        //         utmCampaign: campaign,
        //       },
        //     },
        //     socialMetaTagInfo: {
        //       socialImageLink:
        //         'https://bestcycling-marketing.s3.eu-west-1.amazonaws.com/affiliation/invitation.png',
        //       socialTitle: 'Clases virtuales para entrenar cuerpo y mente',
        //       socialDescription:
        //         'Accede 2 meses gratis a todo nuestro contenido de actividades y nutrición.',
        //     },
        //   },
        // }),
      },
    )
      .then(response => response.json())
      .then(({ shortLink, error }) => {
        if (shortLink) {
          dispatch({ type: 'success', payload: { data: shortLink } });
        } else {
          dispatch({ type: 'error', payload: { error: error } });
        }
      })
      .catch(error => {
        dispatch({ type: 'error', payload: { error: error } });
      });
  };

  useEffect(() => {
    loadDynamicLink();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>No se ha podido obtener el enlace de invitación.</div>;
  }
  if (isSuccess) {
    return (
      <input
        className={styles.linkAffiliate}
        type="text"
        readOnly={true}
        value={data}
        onClick={evt => {
          const link = evt.target.value;
          const text = `Te paso esta aplicación para que puedas entrenar 5 clases gratis diferentes cada semana. Son entrenamientos virtuales de ciclo indoor, running, tonificación, elíptica, yoga, pilates y un programa de nutrición personalizado. Además si te gusta el contenido tienes un 20% de descuento para tu primera suscripción anual.\nDescarga la app aquí: ${link}`;
          const textArea = document.createElement('textarea');
          textArea.style.position = 'fixed';
          textArea.style.top = 0;
          textArea.style.left = 0;
          textArea.style.width = '2em';
          textArea.style.height = '2em';
          textArea.style.padding = 0;
          textArea.style.border = 'none';
          textArea.style.outline = 'none';
          textArea.style.boxShadow = 'none';
          textArea.style.background = 'transparent';
          textArea.value = text;

          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            const success = document.execCommand('copy');
            if (success) {
              logEvent(analytics, ANALYTICS_EVENTS.SHARE_INVITE_CODE);
              showToast('El enlace se ha copiado en el portapapeles', 'success');
            }
          } catch (err) {
            console.info('No se ha podido copiar el elemento en el portapapeles.');
          }
          document.body.removeChild(textArea);
        }}
      />
    );
  }
  return null;
};
