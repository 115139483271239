import { useQuery } from '@tanstack/react-query';

import LoadingIndicator from '../LoadingIndicator';
import SectionTitle from '../SectionTitle';
import TrainingClassesCarousel from '../TrainingClasses/TrainingClassesCarousel';
import ArrowRight from '../Icons/ArrowRight';

import styles from './MyTrainingClassesSection.module.scss';

import useFetchTrainingClasses from '@/core/hooks/apiHooks/useFetchTrainingClasses';

interface MyTrainingClassesSectionProps {
  title: string;
}

export default function MyTrainingClassesSection({ title }: MyTrainingClassesSectionProps) {
  const config = {
    include: ['trainer', 'training', 'user_tags'],
    filters: { category_nr: '1,2', official: false },
    limit: 10,
  };

  const { data, isSuccess, isLoading } = useFetchTrainingClasses(config);

  return (
    <>
      <SectionTitle to={'bestpro/home'}>
        <div className={styles.title}>
          {title}
          <ArrowRight size={10} />
        </div>
      </SectionTitle>
      {isLoading && (
        <div style={{ height: 250 }}>
          <LoadingIndicator />
        </div>
      )}
      {isSuccess && (
        <TrainingClassesCarousel dataCy="my_training_classes_slider" trainingClasses={data} />
      )}
    </>
  );
}
