import LoadingIndicator from '@/components/LoadingIndicator';
import { useInfiniteQuery } from '@tanstack/react-query';
import ListTrainingClasses from '@/components/TrainingClasses/TrainingClassesList';
import { useApi } from '../../api/ApiProvider';
import InfiniteQueryList from '../InfiniteQueryList';
import Category from '@/core/constants/category';

const PlaylistItems = ({ playlist, onSelect }) => {
  const { fetchApi, getNextPageParam } = useApi();

  const orderMind = playlist.id == 130 || playlist.id == 131 || playlist.id == 268;

  const { data, isLoading, isSuccess, isError, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ['playlist_items', { filters: { playlist_id: playlist.id } }],
      ({ pageParam }) =>
        fetchApi([
          'playlist_items',
          {
            include: [
              'training_class',
              'trainer',
              'training',
              'sharing_user',
              'training_class.user_tags',
              ...(playlist.category_nr === Category.Training
                ? ['training_class.training_materials']
                : []),
            ],
            fields: { training: 'seconds' },
            sort: orderMind ? 'position' : null,
            filters: { playlist_id: playlist.id },
            cursor: pageParam,
          },
        ]),
      {
        getNextPageParam,
      },
    );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {
        <InfiniteQueryList
          isFetchingNextPage={isFetchingNextPage}
          isSuccess={isSuccess}
          isError={isError}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          errorLoadingMessage="No es posible cargar más clases dado que no tienes conexión a internet. Comprueba tu conexión y vuelve a intentarlo."
        >
          <ListTrainingClasses
            data={data.pages
              .map(page => page.data)
              .flat()
              .map(item => item.training_class)}
            onSelect={onSelect}
            style={{
              height: '100%',
              position: 'relative',
            }}
          />
        </InfiniteQueryList>
      }
    </>
  );
};

export default PlaylistItems;
