import HomePlaylist from './HomePlaylist';
import PlaylistCard from './PlaylistCard';
import Slider from '@/components/Slider';
import { useRef } from 'react';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import LoadingIndicator from '../LoadingIndicator';

// Carrusel de playlists en la home y en la página de Colecciones (Colecciones por actividad)
export default function HomePlaylistsSlider({
  playlists,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
}) {
  const loadMoreRef = useRef();
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !isFetchingNextPage && !!hasNextPage,
  });

  const loading = hasNextPage ? (
    <div key="loadMore" style={{ padding: '4em' }} ref={loadMoreRef}>
      {isFetchingNextPage ? <LoadingIndicator /> : null}
    </div>
  ) : null;

  return (
    <Slider dataCy="playlists_slider">
      {playlists
        .filter(item => item.has_items || item.has_children)
        .map(item =>
          item.depth == 0 && item.has_children ? (
            <HomePlaylist key={item.id} playlist={item} />
          ) : (
            <PlaylistCard key={item.id} playlist={item} showItems={true} />
          ),
        )
        .concat(loading)}
    </Slider>
  );
}
