import { useRef, useState } from 'react';
import PivotTag from './PivotTag';
import styles from './TagsList.module.scss';
import TagVisibleAware from './TagVisibleAware';

export interface TrainingClassTagsListOptions {
  tagList?: Tag[];
}

export default function TrainingClassTagsList({ tagList = [] }: TrainingClassTagsListOptions) {
  const ref = useRef(null);

  const [visibleTags, setVisibleTags] = useState(new Array(tagList.length).fill(1));

  const [lastVisible, setLastVisible] = useState(visibleTags.lastIndexOf(1));

  return (
    <div ref={ref} className={styles.containerTagsList}>
      {
        tagList.length ?
          tagList.map((element, index) => {
            return (
              <div className={styles.Tag} key={index}>
                <TagVisibleAware
                  bgColor={element?.properties?.color ?? ''}
                  visible={visibleTags}
                  setVisible={setVisibleTags}
                  refParent={ref}
                  index={index}
                  setLastVisible={setLastVisible}
                  plusNumberVisible={index === lastVisible}
                  plusNameTagNotVisible={tagList.filter((_, i) => visibleTags[i] === 0)}
                  plusNumber={tagList.length - (index + 1)}
                >
                  {element.name}
                </TagVisibleAware>
              </div>
            )
          })
        : (
          <div className={styles.Tag}>
            <PivotTag />
          </div>
        )
      }
    </div>
  );
}
