import { useApi } from '@/api/ApiProvider';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

type usePatchProfileProps = {
  invalidateStrategy: 'onSuccess' | 'onDispose';
};

const usePatchProfile = (props: usePatchProfileProps = { invalidateStrategy: 'onSuccess' }) => {
  const { client, mutateApi } = useApi();

  const saveProfile = useMutation(
    async (data: any) => {
      const res = await mutateApi(['profile'], data, {
        method: 'PATCH',
      });

      if (res.error) {
        throw new Error('Error saving profile data', res.error);
      }

      return res;
    },
    {
      onSuccess: (_: any) => {
        if (props.invalidateStrategy === 'onSuccess') {
          client.invalidateQueries(['profile']);
        }
      },
      onMutate: async config => {
        await client.cancelQueries(['profile']);

        const previousConfig = client.getQueryData(['profile']);

        client.setQueryData(['profile'], (old: any) => {
          if (!old) return old;

          return {
            ...old,
            ...config,
          };
        });

        return { previousConfig };
      },
      onError: (err, _, context) => {
        client.setQueryData(['profile'], context?.previousConfig);
        console.error('Error updating profile', err);
      },
    },
  );

  const updateProfile = (data: any) => {
    saveProfile.mutate(data);
  };

  useEffect(() => {
    return () => {
      if (props.invalidateStrategy === 'onDispose') {
        client.invalidateQueries(['profile']);
      }
    };
  }, []);

  return {
    updateProfile,
  };
};

export default usePatchProfile;
