import { useNav } from '@/contexts/nav.context';
import { MouseEvent, useState } from 'react';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationLinkIcon = ({ icon }: { icon: string }) => {
  return <div className={`menu-item-icon ${icon}`}></div>;
};

type props = {
  to: string;
  icon?: string | ReactNode;
  variant?: 'main' | 'primary' | 'secondary';
  external?: boolean;
  children: ReactNode;
  onClick?: (evt: MouseEvent<HTMLElement>) => void;
  onClickCloseNav?: boolean;
  [props: string]: any;
};

export default function NavigationLink({
  to,
  icon = ' ',
  variant = 'primary',
  external = false,
  onClick = () => {},
  onClickCloseNav = false,
  children,
  ...props
}: props) {
  const location = useLocation();
  const { dispatch: dispatchNav } = useNav();

  const [parent, section, subsection] = to.split('/').slice(1, 4);
  const [parentLoc, sectionLoc, subsectionLoc] = location.pathname.split('/').slice(1, 4);

  const isDefaultRouteActive = location.pathname == to;
  let isRouteActive = false;

  if (parent) {
    isRouteActive = parentLoc === parent || (parent === 'playlists' && parentLoc === 'collection');
  }

  if (section && parent !== 'playlists') {
    isRouteActive = isRouteActive && sectionLoc === section;
  }

  if (subsection) {
    isRouteActive = isRouteActive && subsectionLoc === subsection;
  }

  const className = `menu-item ${variant == 'main' ? 'menu-main' : 'menu-gyms'} ${
    isDefaultRouteActive || isRouteActive ? 'menu-item-current' : ''
  }`;

  if (external) {
    return (
      <a onClick={onClick} href={to} className={className} {...props}>
        <div className="menu-item-inner">
          {icon ? (
            typeof icon === 'object' ? (
              icon
            ) : (
              <NavigationLinkIcon icon={icon as string} />
            )
          ) : null}
          <div className="menu-item-text lato-normal-pink-swan-14px">{children}</div>
        </div>
      </a>
    );
  }
  return (
    <Link
      to={to}
      className={className}
      {...props}
      onClick={e => {
        onClick(e);
      }}
    >
      <div className="menu-item-inner">
        {icon ? (
          typeof icon === 'object' ? (
            icon
          ) : (
            <NavigationLinkIcon icon={icon as string} />
          )
        ) : null}
        <div className="menu-item-text lato-normal-pink-swan-14px">{children}</div>
      </div>
    </Link>
  );
}
