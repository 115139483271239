export default function FiltersIcon() {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="20.693"
      height="19.001"
      viewBox="0 0 20.693 19.001"
    >
      <g transform="translate(0.25 0.25)">
        <path
          d="M83.67,82.026H71.64a2.481,2.481,0,0,0-4.7,0H65.057a.79.79,0,0,0,0,1.58h1.878a2.481,2.481,0,0,0,4.7,0H83.67a.79.79,0,1,0,0-1.58ZM69.287,83.718a.9.9,0,1,1,.9-.9A.9.9,0,0,1,69.287,83.718Z"
          transform="translate(-64.267 -80.334)"
          fill="#ff7900"
          stroke="#151515"
          strokeWidth="0.5"
        />
        <path
          d="M83.67,210.559H81.792a2.481,2.481,0,0,0-4.7,0H65.057a.79.79,0,0,0,0,1.58H77.088a2.481,2.481,0,0,0,4.7,0H83.67a.79.79,0,1,0,0-1.58Zm-4.23,1.692a.9.9,0,1,1,.9-.9A.9.9,0,0,1,79.44,212.251Z"
          transform="translate(-64.267 -202.098)"
          fill="#ff7900"
          stroke="#151515"
          strokeWidth="0.5"
        />
        <path
          d="M83.67,339.092H75.024a2.481,2.481,0,0,0-4.7,0H65.057a.79.79,0,0,0,0,1.58h5.262a2.481,2.481,0,0,0,4.7,0H83.67a.79.79,0,1,0,0-1.58Zm-11,1.692a.9.9,0,1,1,.9-.9A.9.9,0,0,1,72.671,340.784Z"
          transform="translate(-64.267 -323.863)"
          fill="#ff7900"
          stroke="#151515"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}
