import { useShellContext } from '../../contexts/shell.context';
import styles from './Footer.module.scss';

export default function FooterMin() {
  const { state: shell } = useShellContext();

  if (shell.desktop) return null;

  return (
    <footer className={styles.container + ' ' + styles.min} id="footer">
      <div className={styles.footerMenu}>
        <ul className={styles.inline}>
          <li>
            <a href="/pages/condiciones-de-uso">Condiciones</a>
          </li>
          <li>
            <a href="/pages/politica-de-privacidad">Política de privacidad</a>
          </li>
          <li>
            <a href="/pages/aviso-legal"> Aviso Legal</a>
          </li>
        </ul>
      </div>
      <div className={styles.legal}>
        <span>© Bestcycling S.L. All Rights Reserved</span>
        <span>SGAE. Nº de licencia: SGAERRDD/1/103/0113</span>
      </div>
    </footer>
  );
}
