import styles from './InstallingWorkerPage.module.scss';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

export default function InstallingWorkerPage({ progress }: { progress: number }) {
  return (
    <div className={styles.page}>
      <div className={styles.messages}>
        Se están descargando los archivos necesarios para el funcionamiento de la aplicación.
      </div>
      <div className={styles.progress}>
        <ProgressBar
          percent={progress}
          color="var(--orange)"
          textColor="#fff"
          showPercent
          striped
          styles={{ width: '60%', left: '20%' }}
        />
      </div>
    </div>
  );
}
