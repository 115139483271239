export default {
  type: 'training_class',
  fields: {
    category_nr: 'number',
    comments_count: 'number',
    content: 'string',
    cover: 'string',
    delay_seconds: 'number',
    duration_seconds: 'number',
    duration_training: 'number',
    free: 'boolean',
    graph: 'string',
    graph_watts: 'string',
    has_background: 'number',
    is_black: 'number',
    is_new: 'number',
    kcal: 'number',
    level_nr: 'number',
    life_flexibility_points: 'number',
    life_force_points: 'number',
    life_mind_points: 'number',
    life_resistance_points: 'number',
    materials_info: '',
    max_duration_seconds: 'number',
    media: 'object',
    official: 'boolean',
    progression: 'array',
    progression_watts: 'array',
    published_at: 'date',
    published_at_timestamp: 'number',
    secondary_training_type: 'string',
    secondary_training_type_nr: 'number',
    sharing_requests_count: 'number',
    short_title: 'string',
    subtitle: 'string',
    title: 'string',
    trainer_id: 'number',
    training_type: 'string',
    training_type_nr: 'number',
  },
  relationships: {
    trainer: {
      type: 'trainer',
    },
    sharing_user: {
      type: 'sharing_user',
    },
    favourite: {
      type: 'favourite',
    },
    training: {
      type: 'training',
    },
    training_materials: {
      type: 'training_material',
    },
    user_tags: {
      type: 'user_tags',
    },
  },
};
