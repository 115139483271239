import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/api/ApiProvider';

type QueryType = 'Nutrition::Recipe' | 'TrainingClass' | 'Playlist';

const queryKeyByType: { [key in QueryType]: string[] } = {
  'Nutrition::Recipe': ['nutrition_recipes'],
  TrainingClass: ['training_classes'],
  Playlist: ['playlists'],
};

type FavouriteResource = {
  id: string;
  active: boolean;
};

type FavouriteQuery = {
  data: FavouriteResource[];
};

type Mutation = {
  target_id: string;
  target_type: string;
  active: boolean;
};

type usePutFavouriteProps = {
  id: string;
  type: QueryType;
  invalidateStrategy: 'onSuccess' | 'never';
};

/**
 * Hook for adding/removing a resource from favourites. It also supports favourite optimistic update
 *
 * @param id      resource target id
 * @param type    resource target type
 *
 * @returns {api} adds and removes favourite methods
 */
const usePutFavourite = (props: usePutFavouriteProps) => {
  const { client, mutateApi } = useApi();

  const mutationKey = ['favourites', props.id, props.type];
  const relationKey = queryKeyByType[props.type];

  const toggleFavourite = useMutation(
    (data: Mutation) => {
      const res = mutateApi(['favourites'], data, {
        method: 'POST',
      });

      if (res.error) {
        throw new Error(
          `Error updating favourite resource. id: ${props.id}; type ${props.type};`,
          res.error,
        );
      }

      return res;
    },
    {
      onMutate: async data => {
        await client.cancelQueries({ queryKey: mutationKey });

        const previous = client.getQueryData(mutationKey);

        client.setQueryData(mutationKey, (old: FavouriteQuery | undefined) => {
          if (!old) return old;

          return {
            ...old,
            data: old.data.map((resource: FavouriteResource) => {
              return {
                ...resource,
                active: data.active,
              };
            }),
          };
        });

        return { previous };
      },
      onSuccess: () => {
        client.invalidateQueries(mutationKey);

        if (props.invalidateStrategy === 'onSuccess') {
          client.invalidateQueries(relationKey);
        }
      },
      onError: (_, __, context) => {
        client.setQueryData(mutationKey, context?.previous);
      },
    },
  );

  const markAsFavourite = () => {
    toggleFavourite.mutate({
      target_id: props.id,
      target_type: props.type,
      active: true,
    });
  };

  const unmarkAsFavourite = () => {
    toggleFavourite.mutate({
      target_id: props.id,
      target_type: props.type,
      active: false,
    });
  };

  return {
    markAsFavourite,
    unmarkAsFavourite,
  };
};

export default usePutFavourite;
