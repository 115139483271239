import { SyncableSettings } from '@/core/features/preferences/constants/settings';
import useDebounceFunction from '@/core/hooks/useDebounceFunction';
import { usePreferences } from '../../contexts/app.context';

export default function useSetting<T>(setting: string): [T, (v: T) => void] {
  const { state, savePreferences, updateAppPreferences } = usePreferences();

  const update = useDebounceFunction((preferences: any) => {
    updateAppPreferences(preferences);
  }, 600);

  return [
    state[setting],
    (v: unknown) => {
      const preferences = { [setting]: v };
      savePreferences(preferences);

      if (SyncableSettings.includes(setting)) {
        update(preferences);
      }
    },
  ];
}
