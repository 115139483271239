import { useApi } from '@/api/ApiProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type tcQueryData = QueryData<TrainingClass, 'training_classes'>;

interface mutationData {
  training_class_ids: string[];
  user_tags: UserTag[];
}

const usePutUserTaggings = () => {
  const { mutateApi, client } = useApi();
  const queryClient = useQueryClient();

  return useMutation<null, ErrorConstructor, mutationData>(
    async data => {
      const payload = {
        training_classes: data.training_class_ids.map(id => ({ id })),
        user_tags: data.user_tags.map(ut => ({ id: ut.id })),
      };

      const res = await mutateApi(['user_taggings', 'bulk_update'], payload, {
        method: 'PATCH',
      });

      if (res.error && res.error.name !== 'SyntaxError') {
        throw new Error(res.error);
      }

      return res;
    },
    {
      onMutate: async mutationData => {
        // @ts-ignore es el tipo que me llega
        const queryData: tcQueryData[] = queryClient.getQueriesData(['training_classes']);

        queryData.forEach((tc: tcQueryData) => {
          const [key, value] = tc;

          let newValue: TrainingClass[] | TrainingClass | InfiniteQueryResult<TrainingClass>;

          if (!value) return;

          if (Array.isArray(value)) {
            // TrainingClass[]
            newValue = value as TrainingClass[];
            newValue = value.map(trainingClass => {
              return updateTcTags(mutationData, trainingClass);
            });
          } else if ('pages' in value) {
            // InfiniteQuery
            newValue = value as InfiniteQueryResult<TrainingClass>;

            newValue.pages = newValue.pages.map(page => {
              return {
                ...page,
                data: page.data.map(trainingClass => {
                  return updateTcTags(mutationData, trainingClass);
                }),
              };
            });
          } else {
            // TrainingClass
            newValue = value as TrainingClass;
            newValue = updateTcTags(mutationData, newValue);
          }
          queryClient.setQueryData(key, newValue);
          queryClient.invalidateQueries(key);
        });
      },
    },
  );
};

export default usePutUserTaggings;

const hasTrainingClass = (mutationData: mutationData, tc: TrainingClass) => {
  return mutationData.training_class_ids.includes(tc.id);
};

const updateTcTags = (mutationData: mutationData, tc: TrainingClass) => {
  if (!hasTrainingClass(mutationData, tc)) return tc;
  tc = {
    ...tc,
    user_tags: mutationData.user_tags,
  };
  return tc;
};
