type Props = {
  className?: string;
  size?: number | string;
};
const TriangleSch = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 26.5 53"
    {...props}
  >
    <path fill="currentColor" d="m0 0 26.5 26.5L0 53V0z" />
  </svg>
);
export default TriangleSch;
