
import React, { useRef } from 'react';

import '../../components/Topbar/DropdownMenu';
import ColorCircle from '../TagsList/ColorCircle';

import styles from './ColorPickerModal.module.scss';
import chevronColor from '@/img/chevron-down@1x.png';
import chevronGrey from '@/img/chevron-grey.png';
import { TagCircleColor } from '@/contexts/tagscolorlist.types';
import { requiredContrastColors } from '@/core/constants/user_tags';

type ColorPickerModalProps = {
  colorSelected: TagCircleColor,
  toggleDropdown(ref: any, color: string): void
}

const ColorPickerModal = React.forwardRef(({ colorSelected, toggleDropdown }: ColorPickerModalProps, ref: any) => {
  const refContainer = useRef(null);

  return (
    <div ref={ref} className="dropdown" style={{ display: 'inline-block' }}>
      <div
        ref={refContainer}
        onClick={() => toggleDropdown(refContainer, colorSelected)}
        className={`options dropdown-toggle ${styles.transparent}`}
      >
        <ColorCircle color={colorSelected} variant="big">
          <img alt="" className={styles.chevronDown} src={
            requiredContrastColors.includes(colorSelected) ?  chevronGrey : chevronColor
          } />
        </ColorCircle>
      </div>
    </div>
  );
});

ColorPickerModal.displayName = "ColorPickerModal";

export default ColorPickerModal;