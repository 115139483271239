import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import styles from './TrainingClass/TrainingClass.module.scss';

import Button from '@/components/UI/Button/Button';
import Pencil from '@/img/constructor/icon-pencil.svg';

import ConfirmDialog from '@/components/ConfirmDialog';

import DownloadMusic from '../TrainingClassDetails/DownloadMusic';
import DeleteIcon from '@/components/Icons/DeleteIcon';

import useWindowSize from '@/hooks/useWindowSize';
import { useShell } from '@/contexts/shell.context';
import useBulkDeleteMutation from '../Bestpro/MyClasses/hooks/useBulkDeleteMutation';

export default function MyTrainingClassOptions({
  trainingClass,
}: {
  trainingClass: TrainingClass;
}) {
  const navigate = useNavigate();

  const {
    actions: { removeMyOfflineTrainingClass },
  } = useShell();

  const size = useWindowSize();

  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);

  const openRemoveModal = () => setRemoveDialogOpen(true);
  const closeRemoveModal = () => {
    setRemoveDialogOpen(false);
  };
  const trainingClassUrlEdit = `/bestpro/constructor/${trainingClass.id}`;

  const deleteClass = useBulkDeleteMutation();

  const onDeleteClass = (tc: TrainingClass) => {
    // @ts-ignore
    deleteClass.mutate([tc.id]);

    removeMyOfflineTrainingClass(tc);
    closeRemoveModal();
    navigate(-1);
  };

  const openEditClass = () => {
    navigate(trainingClassUrlEdit);
  };

  return (
    <div className={styles.optionsContainer}>
      <ConfirmDialog
        isOpen={isRemoveDialogOpen}
        onRequestClose={closeRemoveModal}
        onConfirm={() => {
          onDeleteClass(trainingClass);
        }}
        description={`¿Estás seguro que deseas eliminar la clase ${trainingClass.title}?`}
        className={undefined}
        confirmText={undefined}
      />
      <div className={styles.optionLeft}>
        <Button
          variant="primary"
          size="default"
          title="CANCELAR"
          style={{ marginRight: '10px', marginLeft: '0', float: 'left' }}
          leftIcon={
            <img
              src={Pencil}
              alt=""
              height="18px"
              style={{ marginRight: '5px', height: '17px', marginBottom: '-3px' }}
            />
          }
          onClick={openEditClass}
        >
          EDITAR
        </Button>
        <DownloadMusic trainingClass={trainingClass} />
      </div>
      <div>
        <Button
          variant="danger"
          size="default"
          title="ELIMINAR"
          leftIcon={<DeleteIcon></DeleteIcon>}
          onClick={openRemoveModal}
        >
          ELIMINAR
        </Button>
      </div>
    </div>
  );
}

type FetchedData =
  | {
      pages: {
        data: TrainingClass[];
      }[];
    }
  | { data: TrainingClass[] };
