import styles from './EmptyFilteredSection.module.scss';

interface IEmptyFilteredSection {
  text: string
}

const EmptyFilteredSection = ({ text }: IEmptyFilteredSection) => {
  return (
    <div className={styles.emptyText}>
      {
        text
      }
    </div>
  )
}

export default EmptyFilteredSection;