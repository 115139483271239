import dayjs from 'dayjs';
import { clearAuth, saveAuth, loadAuth as _loadAuth } from './auth.persistence';

export const AUTH_ENDPOINT = `${config?.API_V2_HOST}/oauth/token`;

export const APP_ID = config.APP_ID;

function _isExpiredToken({ expiresAt }) {
  const now = dayjs();

  return now.isAfter(dayjs(expiresAt));
}

function _isFreshToken({ expiresAt }) {
  const now = dayjs();
  return dayjs(expiresAt).diff(now, 'days') > 10;
}

// loadAuth
// Si tenemos auth fresco lo enviamos,
// si no es fresco intentamos refrescar, si ok => sustituimos y deveolvemos auth, si falla devolvemos auth
// Si está caducado: refrescamos o logout
export async function loadAuth() {
  const auth = await _loadAuth();

  if (_isFreshToken(auth)) {
    // Fresh
    return auth;
  } else if (_isExpiredToken(auth)) {
    // Expired
    return refresh(auth.refreshToken);
  } else if (auth.refreshToken) {
    // Staled
    return refresh(auth.refreshToken)
      .then(refreshed => refreshed)
      .catch(_ => auth);
  }
  // default
  return auth;
}

export async function login(user, password) {
  return fetch(AUTH_ENDPOINT, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: APP_ID,
      username: user,
      password: password,
      grant_type: 'password',
    }),
  })
    .then(async response => {
      if (response.status === 200) {
        return response.json();
      }
      throw response;
    })
    .then(json => saveAuth(json));
}

export async function refresh(refreshToken) {
  return fetch(AUTH_ENDPOINT, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: APP_ID,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    }),
  })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }

      throw response;
    })
    .then(json => saveAuth(json));
}

export async function logout() {
  const logoutFnc = () =>
    process.env.NODE_ENV == 'production' && !window.electronAPI
      ? fetch('/session', {
          method: 'DELETE',
          body: JSON.stringify({
            _method: 'delete',
          }),
        }).catch(console.info)
      : Promise.resolve();

  return logoutFnc().then(clearAuth);
}
